$col: 5;
$row: 7;
$pathIcon: '../svg/vector_spritesheet.svg';

.w-input-element:not([type=radio]):not([type=checkbox]),
input[type=email].w-input-element,
input[type=password].w-input-element,
input[type=text].w-input-element,
textarea.w-input-element {
    font-family: $primaryFont !important;
}

.w-has-error:not(.w-dropdown) .w-input-element:not([type=radio]):not([type=checkbox]),
.w-has-error:not(.w-dropdown) input[type=email].w-input-element,
.w-has-error:not(.w-dropdown) input[type=password].w-input-element,
.w-has-error:not(.w-dropdown) input[type=text].w-input-element,
.w-has-error:not(.w-dropdown) textarea.w-input-element {
    border-color: #D9000C !important;
    box-shadow: initial !important;
}

.btnReturnLostPwd.loading {
    background: white url('../svg/three_dots_black.svg') no-repeat center;
    background-size: auto 22%;
    color: transparent !important;
}

.w-dropdown.w-has-error {
    border-color: #D9000C !important;
    box-shadow: initial !important;
}

.w-input-note.w-input-error {
    color: #D9000C !important;
}

.w-address-input,
.w-text-input {
    .autocomplete-results {
        position: absolute;
        z-index: 1;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: white;
        box-shadow: inset rgba(0, 0, 0, 0.16) 0px 1px 4px;

        li {
            padding: .5rem (12 / 14 * 1em);
            font-size: 14 / 16 * 1rem;
            cursor: pointer;

            &:hover {
                background-color: #f5f5f5;
            }
        }
    }
}

.cache {
    display: none !important;
}

.f_left {
    float: left;
}

.f_right {
    float: right;
}

.c_both {
    clear: both;
}

:focus {
    outline: none;
}

table {
    border-collapse: collapse;
}

.clearfix:after {
    display: block;
    clear: both;
    height: 0;
    line-height: 0;
    visibility: hidden;
    content: '';
}

.input_container {
    position: relative;

    &.newsSuppr {
        width: 400px;

        input {
            width: 100%;
            height: 100%;
        }
    }
}

body {
    min-width: 1007px;

    // Sans bandeauheader
    section#site_global_wrap {
        padding-top: 210px;
    }

    // Class used when lightbox is opened, to prevent scroll in page
    &.noScroll {
        max-width: 100vw;
        max-height: 100vh;
        overflow: hidden;
    }

    // Homepage sans bandeauheader
    &.category section#site_global_wrap,
    &.homepage section#site_global_wrap {
        padding-top: 160px;
    }

    // Etape 1 du panier sans bandeauheader
    &.cart.step_1 section#site_global_wrap {
        padding-top: 135px;
    }

    &.in_tunnel section#site_global_wrap {
        padding-top: 120px;
    }

    &.hasBandeauHeader {

        &.offres_mars_2024,
        &.offres_avril_2024,
        &.catalogue-collection-2025 {
            .main_wrapper {
                max-width: 1690px !important;
                width: 98% !important;
            }
        }

        // Category avec bandeauheader
        &.category section#site_global_wrap {
            padding-top: 230px;
        }

        &.homepage section#site_global_wrap {
            padding-top: 185px;
        }

        // Homepage, Garantie, wishlist, customer, newsletter, page produit avec bandeauheader
        &.garantie section#site_global_wrap,
        &.wishlist section#site_global_wrap,
        &.customer section#site_global_wrap,
        &.newsletter section#site_global_wrap,
        &.product_page section#site_global_wrap {
            padding-top: 240px;
        }

        // Etape 1 du panier avec bandeauheader
        &.cart.step_1 section#site_global_wrap {
            padding-top: 168px;
        }

        &.in_tunnel section#site_global_wrap {
            padding-top: 0;
        }

        .breadcrumbs_wrapper {
            bottom: -80px;
        }

        #wrapper_content_sat {
            margin-top: 20px;
        }

        #filter_sticky_search {
            top: 168px;
        }

        #results_header {
            padding: 40px 40px 0;
        }
    }

    &.sticky_h {
        #filter_sticky_search {
            top: 135px;
        }
    }

    &:not(.home) #site_global_wrap>div,
    &:not(.category) #site_global_wrap>div {
        margin-top: 2rem;

    }

    &.catalogue #site_global_wrap>div h1,
    &.offres_mars_2023 #site_global_wrap>div #catalogue_title,
    &.conseil_entretien #site_global_wrap>div h1,
    &.espace_presse #site_global_wrap>div h1 {
        margin-bottom: 43px !important;
        position: relative;
        text-transform: none;

        &::after {
            position: absolute;
            content: "";
            width: 46px;
            height: 3px;
            background-color: #000;
            bottom: -20px;
            left: 0;
        }
    }


    &.espace_presse #site_global_wrap>div h1 {
        max-width: 1360px;
        margin: 0 auto;
    }
}

/******************************************************************************/
/* HOME */
/******************************************************************************/
#main_slider_home {
    width: 100%;
}

.main-slider-next,
.main-slider-prev {
    position: absolute;
    top: 50%;
    margin: -1.5rem 0 0;
    z-index: 60;
}

.main-slider-next {
    right: 0;
}

.main-slider-prev {
    left: 0;
}

.main-slider-next a,
.main-slider-prev a {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
}

.nav-fillpath div {
    width: 100px;
    height: 100px;
}

.nav-fillpath .icon-wrap {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}

.nav-fillpath .icon-wrap::after,
.nav-fillpath .icon-wrap::before,
.nav-fillpath div::after,
.nav-fillpath div::before {
    position: absolute;
    left: 50%;
    width: 3px;
    height: 50%;
    background: $txtBlack;
    content: '';
    transition: transform 0.3s;
    backface-visibility: hidden;
}

.nav-fillpath .icon-wrap::after,
.nav-fillpath .icon-wrap::before {
    z-index: 100;
    height: 0;
    background: $txtBlack;
    transition: height 0.3s, transform 0.3s;
}

.nav-fillpath .icon-wrap::before,
.nav-fillpath div::before {
    top: 50%;
    transform: translateX(-50%) rotate(-135deg);
    transform-origin: 50% 0;
}

.nav-fillpath div.next .icon-wrap::before,
.nav-fillpath div.next::before {
    transform: translateX(-50%) rotate(135deg);
    transform-origin: 50% 0;
}

.nav-fillpath .icon-wrap::after,
.nav-fillpath div::after {
    top: 50%;
    transform: translateX(-50%) rotate(-45deg);
    transform-origin: 0 0;
}

.nav-fillpath div.next .icon-wrap::after,
.nav-fillpath div.next::after {
    transform: translateX(-50%) rotate(45deg);
    transform-origin: 100% 0;
}

.nav-fillpath a {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 50%;
    margin: 0;
    color: $white;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 600;
    font-size: 2em;
    opacity: 0;
    transition: transform 0.3s, opacity 0.3s;
    padding: 20px 0 0;
    box-sizing: border-box;
}

.nav-fillpath div.prev a {
    left: 0;
    padding: 23px 0 0 100px;
    transform: translateY(-50%) translateX(-50%);
}

.nav-fillpath div.next a {
    right: 0;
    text-align: right;
    transform: translateY(-50%) translateX(0);
}

.nav-fillpath div:hover .icon-wrap::after,
.nav-fillpath div:hover .icon-wrap::before {
    height: 50%;
}

.nav-fillpath div:hover .icon-wrap::before,
.nav-fillpath div:hover::before {
    transform: translateX(-50%) rotate(-125deg);
}

.nav-fillpath div.next:hover .icon-wrap::before,
.nav-fillpath div.next:hover::before {
    transform: translateX(-50%) rotate(125deg);
}

.nav-fillpath div:hover .icon-wrap::after,
.nav-fillpath div:hover::after {
    transform: translateX(-50%) rotate(-55deg);
}

.nav-fillpath div.next:hover .icon-wrap::after,
.nav-fillpath div.next:hover::after {
    transform: translateX(-50%) rotate(55deg);
}

.nav-fillpath div:hover a {
    opacity: 1;
    transform: translateY(-50%) translateX(0);
}

.nav-fillpath div.next:hover a {
    opacity: 1;
    transform: translateY(-50%) translateX(-100%);
}

.infos_hm_slider.info_seul_fr.site_4 .info_slider_rgt.num_00 .slider_product_name {
    margin: 0 0 5px;
}

#content_wrapper .last_products {
    width: 100%;
    position: relative;
}

.eclat_gondole_home {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    text-align: right;
}

.eclat_gondole_home img {
    height: auto;

    @media screen and (max-width: 101.5rem) {
        width: 64%;
    }
}

.eclat_gondole_home_left {
    @media screen and (max-width: 101.5rem) {
        left: -15px;
    }

    img {
        @media screen and (max-width: 101.5rem) {
            width: 65%;
        }
    }
}

#slider_coup_de_coeur_next,
#slider_coup_de_coeur_prev {
    width: 45px;
    height: 47px;
    position: absolute;
    top: 50%;
    margin: -23px 0 0;
    z-index: 60;
    background: rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

#slider_coup_de_coeur_prev {
    left: 0;
}

#slider_coup_de_coeur_next {
    right: 0;
}

#slider_coup_de_coeur_next a,
#slider_coup_de_coeur_prev a {
    display: block;
    width: 100%;
    height: 100%;
}

#slider_coup_de_coeur_prev a {
    background: transparent url("../img/layout.png") no-repeat -53px -231px;
}

#slider_coup_de_coeur_next a {
    background: transparent url("../img/layout.png") no-repeat -53px -183px;
}

.last_products .encarts_fr,
.last_products .right_part {
    width: 50%;
    float: left;
    position: relative;
}

.last_products .right_part a {
    text-decoration: none;
}

.last_products .right_part .bloc_catalogue,
.last_products .right_part .bloc_premium,
.last_products .right_part .composez_salon {
    display: block;
    width: 100%;
    height: 50%;
    position: relative;
}

.last_products .right_part .composez_salon {
    background: transparent url("../img/home_legacy/visu_composez_salon.jpg") no-repeat right center;
    background-size: cover;
}

.last_products .encarts_fr .composez_salon {
    background: transparent url("../img/home_legacy/encart_composez_salon.jpg") no-repeat right center;
    background-size: cover;
}

.last_products .right_part .bloc_premium {
    background: $black url("../img/home_legacy/visu_bloc_premium.jpg") no-repeat right center;
    background-size: cover;
}

.last_products .right_part .bloc_premium p {
    color: $white;
}

.last_products .right_part .bloc_catalogue .last_pcd_holder {
    width: 100%;
    height: 100%;
    background: transparent url("../img/home_legacy/test_catalogue.jpg") no-repeat right center;
    background-size: cover;
}

.last_products .encarts_fr .composez_tonnelle,
.last_products .right_part .bloc_catalogue.tonnelle .last_pcd_holder {
    width: 100%;
    height: 100%;
    background: transparent url("../img/home_legacy/composez_tonnelle.jpg") no-repeat right center;
    background-size: cover;
}

.last_products .encarts_fr .composez_salon,
.last_products .encarts_fr .composez_tonnelle {
    position: relative;
    width: 100%;
    height: auto;
}

.last_products .encarts_fr .img_hide {
    visibility: hidden;
    width: 100%;
}

.last_products .encarts_fr h3 {
    top: 20%;
    font-size: 35px;
    line-height: 30px;
    font-weight: 700;
    color: #515151;
    text-transform: uppercase;
}

.last_products .encarts_fr p {
    top: 48%;
    width: 205px;
    font-size: 12px;
    color: #515151;
}

.last_products .right_part h3 {
    font-size: 35px;
    line-height: 30px;
    font-weight: 700;
    color: #515151;
    padding: 8% 0 0 5%;
    text-transform: uppercase;
}

.last_products .right_part .bloc_catalogue h3 {
    color: $white;
}

.last_products .right_part .bloc_catalogue.tonnelle h3 {
    color: #515151;
}

.last_products .encarts_fr h3 span,
.last_products .right_part h3 span {
    display: block;
    font-size: 29px;
}

.last_products .right_part p {
    width: 205px;
    font-size: 12px;
    color: #515151;
    padding: 10px 0 15px 5%;
}

.last_products .right_part .bloc_catalogue p {
    color: $white;
}

.last_products .right_part .bloc_catalogue.tonnelle p {
    color: #515151;
}

.catalogue_descrip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/******************************************************************************/
/* BOUTIQUES */
/******************************************************************************/

.super_boutik {
    .main_wrapper {
        width: 100%;
        max-width: 100%
    }

    .boutique {
        .bandeau_categ {
            position: relative;

            img {
                width: 100%;
            }

            h1 {
                color: #ffffff;
                text-align: center;
                text-transform: uppercase;
                font-size: 4.8vw;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .boutique_content {
            width: 85%;
            margin: auto;

            .boutique_content_inside {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 100%;
                }

                .item_look_seperator {
                    border-left: 2px solid #dadada;
                    height: 6rem;
                    margin-left: 6rem;
                    margin-right: 4rem;
                }

                .item_look {
                    .item_look_contents {
                        position: relative;

                        a.tag {
                            position: absolute;
                            width: 4rem;
                            height: 4rem;
                            cursor: default;

                            &:hover {
                                &:after {
                                    content: '';
                                    background: url('../img/puce_moins.png') no-repeat;
                                    opacity: 1;
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            &:after {
                                content: '';
                                background: url('../img/puce_plus.png') no-repeat;
                                opacity: 1;
                                position: absolute;
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }

                .item_product_look {
                    img {
                        margin: 4rem 0;
                    }

                    .product_look_title {
                        font-weight: 600;
                        font-size: 0.9rem;
                    }

                    .product_look_price {
                        sup {
                            font-weight: 600;
                        }
                    }
                }
            }

            .boutiques_texte_content {
                text-align: center;

                p.boutiques_texte {
                    width: 100%;
                    max-width: 900px;
                    font-size: .8rem;
                    line-height: 17px;
                    text-align: center;
                    color: $primaryColor;
                    margin: 0 auto;
                    padding: 3rem 0;
                }

                h2,
                .h2Title {
                    font-size: 1.9rem;
                    font-weight: 600;
                    padding-bottom: 3rem;
                    letter-spacing: 1px;
                }
            }

            .product_highlights_wrapper {
                position: relative;
                margin: auto;

                .swiper-container {
                    width: 94%;
                    height: 100%;
                }

                .swiper-slide {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    -webkit-align-items: center;
                    align-items: center;

                    &:after {
                        content: '';
                        right: 0;
                        width: 0;
                        height: 19%;
                        position: absolute;
                        border: 1px solid#dadada;
                        top: 37%;
                    }

                    &:last-child::after {
                        content: none;
                    }
                }

                .item {
                    .tg_img_container {
                        .tg_img_content {
                            margin: 4%;
                            position: relative;
                        }
                    }

                    .wrapper_infos {
                        .title {
                            font-size: 0.9rem;
                            text-align: left;
                        }
                    }

                    .rollover {
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        transition: opacity .2s linear;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                .control {
                    position: absolute;
                    top: 40%;
                    width: 18px;
                    height: 20px;
                    background-color: rgba(0, 0, 0, 0);
                    box-sizing: content-box;
                    cursor: pointer;
                    z-index: 3;

                    &.swiper-button-disabled {
                        opacity: 0.3;
                    }

                    &:before {
                        display: block;
                        width: 100%;
                        height: 100%;
                        background-size: 55%;
                        background-repeat: no-repeat;
                        content: '';
                    }
                }

                .swiper-nav-prev {
                    left: 0;

                    &:before {
                        background-image: url(../img/arr_prev_boutique.png);
                    }
                }

                .swiper-nav-next {
                    right: 0;

                    &:before {
                        background-image: url(../img/arr_next_boutique.png);
                    }
                }
            }

            .wrapper_category {
                display: flex;
                flex-direction: column;
                padding-top: 6rem;

                .wrapper_category_1 {
                    display: flex;
                    justify-content: space-around;

                    .column_1 {
                        padding-right: 2.5rem;
                    }

                    .column_2 {
                        padding-left: 2.5rem;
                    }
                }

                .wrapper_category_2 {
                    display: flex;
                    justify-content: space-around;
                    padding: 2rem 0;

                    .btn_buy {
                        color: #FFF;
                        width: 116px;
                        height: 37px;

                        span {
                            line-height: 50px;
                        }

                        &:after {
                            line-height: 32px;
                        }
                    }
                }
            }
        }
    }
}


#content_wrapper.boutique {
    max-width: 1920px;
    min-width: 990px;
    margin: 0 auto;
}

.premium #content_wrapper.boutique {
    width: 100%;
    min-width: 990px;
    max-width: 1920px;
    padding: 1% 1% 3%;
    background: $black url("../img/bg_premium.jpg") no-repeat fixed center top;
    background-size: 100% auto;
    box-sizing: border-box;
}

#texte_super_home {
    width: 100%;
    position: relative;
}

.txt_super_home {
    h1 {
        position: relative;
        display: inline-block;
        margin: 0 0 2.5rem;
        font-weight: normal;
        text-transform: uppercase;
        text-align: center;
        font-size: 5.5rem;
        line-height: 5.5rem;
        color: $txtBlack;


        &::before {
            display: block;
            position: absolute;
            bottom: 3px;
            left: 0;
            width: 100%;
            height: 6px;
            background-color: $green;
        }

        @media screen and (max-width: 101.5rem) {
            margin: 0 0 1.2rem;
        }
    }

    a {
        color: $primaryColor;

        &:hover {
            text-decoration: underline;
            color: #aaaaaa;
        }
    }

    .sep {
        display: block;
        width: 45px;
        height: 1px;
        border: none;
        background: none;
        margin: 70px 0 5px;

        @media screen and (max-width: 101.5rem) {
            margin: 1.375rem 0 5px;
        }
    }

    ul {
        display: flex;
        justify-content: space-between;

        li {
            list-style-position: inside;
            list-style-type: none;
            line-height: 1.375rem;
            padding: 0 1.5rem;

            a,
            a::before,
            a::after {
                box-sizing: border-box;
            }

            a {
                color: $secondaryColor;
                font-size: 1.2rem;
                font-weight: 600;
                line-height: 1.8;
                position: relative;
                display: inline-block;

                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    overflow: hidden;
                    max-width: 0;
                    color: $primaryColor;
                    font-size: 1.2rem;
                    text-decoration: underline;
                    content: attr(data-hover);
                    transition: max-width 0.3s;
                    white-space: nowrap;
                }

                &:hover {
                    text-decoration: none;
                }

                &:focus::before,
                &:hover::before {
                    max-width: 100%;
                }

                span {
                    display: none;
                    width: 1rem;
                    height: 1rem;
                    line-height: 17px;
                    text-align: center;
                    border: 2px solid $green;
                    color: $green;
                    border-radius: 100%;
                    font-size: 17px;
                    vertical-align: 0;
                    padding-bottom: 1px;
                    margin-right: 0.5rem;
                    padding-left: 0.1rem;
                }
            }
        }
    }
}

#texte_super_home .txt_super_home {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-bottom: 3rem;
}

#content_wrapper.boutique.series {
    display: flex;
    justify-content: space-around;
    max-width: 1600px;
}

.boutique.series .series_col {
    width: 50%;
}

.boutique.series .txt_super_home {
    float: left;
    width: 100%;
    padding-bottom: 6.25%;
}

.boutique.series .serie_lnk {
    display: block;
    clear: both;
    float: left;
    width: 88%;
    padding-bottom: 18.5%;
}

.boutique.series .serie_lnk:nth-of-type(even) {
    float: right;
}

.boutique.series .serie_lnk>img {
    vertical-align: top;
    width: 100%;
    height: auto;
}

.boutique.series .menu_right {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 94px;
    border-right: 7px solid $txtBlack;
}

.boutique.series .menu_right>li {
    line-height: 23px;
    text-align: right;
    margin-bottom: 1rem;
}

.boutique.series .menu_right>li:last-child {
    margin-bottom: 0;
}

.boutique.series .menu_right a {
    display: block;
    position: relative;
    padding: 0 14px;
    font-size: .75rem;
    color: #777;
    text-transform: uppercase;
    overflow: hidden;
}

.boutique.series .menu_right a:before {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    background-color: $txtBlack;
    z-index: -1;
    transition: width 0.3s ease-in-out;
    content: '';
}

.boutique.series .menu_right a:hover:before {
    width: 100%;
}

.boutique.series .menu_right a:after {
    display: block;
    position: absolute;
    top: 0;
    right: 14px;
    width: 0;
    height: 100%;
    color: $white;
    white-space: nowrap;
    transition: width 0.3s ease-in-out;
    content: attr(data-hover);
}

.boutique.series .menu_right a:hover:after {
    width: calc(100% - 28px);
}

#texte_super_home .catalogue {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #6a6a6a;
    text-decoration: underline;
    font-size: 13px;
    font-weight: 700;
}

#texte_super_home .catalogue:hover {
    text-decoration: none;
}

#texte_super_home .catalogue span {
    position: absolute;
    left: -20px;
}

#content_wrapper.boutique .last_products {
    width: 100%;
    min-width: 990px;
    min-height: 550px;
    padding: 1% 0 3.5%;
}

#content_wrapper.boutique .last_products .wrapper a {
    display: inline-block;
    width: 23%;
    height: auto;
    margin: 0 0 0 1%;
    position: relative;
}

#content_wrapper.boutique .last_products .wrapper a:first-child {
    display: block;
    float: left;
    width: 49%;
    height: auto;
    margin: 0 0 0 1%;
}

#content_wrapper.boutique .last_products .visu {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

#content_wrapper.boutique .last_products .visu img {
    vertical-align: top;
    width: 100%;
    height: auto;
}

#content_wrapper.boutique .last_products .visu .rollover,
.last_products .visu .rollover {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    transition: all 0.3s ease;
}

#content_wrapper.boutique .last_products .visu:hover .rollover {
    opacity: 1;
}

#content_wrapper.boutique .last_products h3 {
    display: none;
}

#content_wrapper.boutique .last_products .title {
    float: left;
    width: 60%;
}

#content_wrapper.boutique .last_products .title h2 {
    font-size: 13px;
    color: #282828;
    text-transform: uppercase;
    padding: 5px 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#content_wrapper.boutique .last_products .title .subtitle {
    font-size: 12px;
    color: #949494;
    text-transform: none;
    padding: 0 0 7px;
}

#content_wrapper.boutique .last_products .price {
    float: right;
    font-size: 1.5rem;
    color: $txtBlack;
}

#content_wrapper.boutique .last_products .price sup {
    font-size: 10px;
}

#content_wrapper.boutique .last_products .price sup.chf {
    vertical-align: baseline;
}

#content_wrapper.boutique .last_products .price span {
    font-size: 10px;
    margin: 0 0 0 -4px;
}

#content_wrapper.boutique .last_products .price span.chf {
    margin: 0;
}

#content_wrapper.boutique .last_products .market_price {
    float: right;
    font-size: 1rem;
    color: $txtBlack;
    text-decoration: line-through;
    margin: 5px 8px 0 0;
}

#content_wrapper.boutique .last_products .market_price sup {
    font-size: 8px;
}

#content_wrapper.boutique .last_products .market_price span {
    font-size: 8px;
    margin: 0 0 0 -3px;
}

.wrapper_txt_premium {
    display: inline-block;
    vertical-align: top;
    width: 49.5%;
    border: 1px solid #434452;
    box-sizing: border-box;
    font-size: .75rem;
    color: $white;
}

.wrapper_txt_premium a {
    color: inherit;
}

.wrapper_txt_premium .cadre {
    width: 90%;
    margin: 5.8% auto;
    border: 1px solid #90919d;
    box-sizing: border-box;
}

.wrapper_txt_premium .logo {
    width: 40%;
    text-align: center;
}

.wrapper_txt_premium .logo img {
    padding: 76px 0 0;
}

.pt .wrapper_txt_premium .logo img {
    padding: 37px 0 0;
}

.wrapper_txt_premium p {
    width: 52%;
    padding: 4%;
    text-align: justify;
    line-height: 18px;
}

.wrapper_txt_premium p .catalogue {
    position: relative;
    color: $white;
    text-decoration: underline;
    display: block;
    margin: 5px 0 0 20px;
    font-weight: 700;
    font-size: 12px;
}

.wrapper_txt_premium p .catalogue span {
    position: absolute;
    left: -20px;
}

.visu_category_premium {
    display: inline-block;
    position: relative;
    float: left;
    vertical-align: top;
    width: 49.5%;
    margin-bottom: 1vw;
    overflow: hidden;
}

.visu_category_premium.cat_107,
.visu_category_premium.cat_110,
.visu_category_premium.cat_115,
.visu_category_premium.cat_118,
.visu_category_premium.cat_54,
.visu_category_premium.cat_55 {
    float: right;
}

.visu_category_premium.cat_109,
.visu_category_premium.cat_119,
.visu_category_premium.cat_58 {
    clear: both;
    float: none;
    width: 100%;
}

.visu_category_premium img {
    vertical-align: top;
    width: 100%;
    height: auto;
}

.visu_category_premium span {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    color: $white;
    padding: 30px;
    font-weight: 700;
    transition: padding 0.2s ease;
}

.visu_category_premium:hover span {
    padding: 40px 30px;
}

.visu_category_premium span img {
    vertical-align: middle;
    width: auto;
    margin-right: 10px;
}

/******************************************************************************/
/* RAYON */
/******************************************************************************/
#nos_rayons {
    display: none;
}

#main_wrapper {
    width: 100%;
    padding: 137px 0 0;
}

.premium #main_wrapper {
    background: url("../img/borne/bg_produit_repeat.gif") repeat-x left top;
}

.premium.rayon #main_wrapper {
    background: $black;
}

.bank #main_wrapper {
    width: 100%;
    padding: 37px 0 0;
}

.border_premium {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.premium .border_premium {
    width: 100%;
    border-bottom: 12px solid $black;
    background: #f5f5f5;
    box-sizing: border-box;
}

.wrapper_bandeau {
    width: 100%;
    height: 150px;
    position: relative;
}

.premium .wrapper_bandeau {
    max-width: 1600px;
    height: 286px;
    margin: 37px auto 0;
}

.premium .wrapper_bandeau.sscat {
    height: 150px;
}

.wrapper_txt_bandeau {
    width: 100%;
    max-width: 1600px;
    height: 100%;
    position: relative;
    margin: 0 auto;
}

.wrapper_bandeau .txt_bandeau {
    position: absolute;
    z-index: 2;
    top: 0;
    left: -300px;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    color: $black;
    padding: 0 0 0 330px;
    width: 470px;
}

.premium .wrapper_bandeau .txt_bandeau {
    left: 130px;
    background: none;
    color: $white;
}

.premium .wrapper_bandeau.sscat .txt_bandeau {
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    color: $white;
    padding: 0 0 0 30px;
}

.premium .wrapper_bandeau .txt_bandeau a {
    color: $white;
    text-decoration: underline;
}

.premium .wrapper_bandeau .txt_bandeau a:hover {
    text-decoration: none;
}

.wrapper_bandeau .txt_bandeau h1 {
    line-height: 150px;
    font-size: 42px;
    text-transform: uppercase;
    font-weight: 300;
}

.premium .wrapper_bandeau .txt_bandeau h1 {
    line-height: inherit;
    padding: 15% 0 0;
}

.premium .wrapper_bandeau.sscat .txt_bandeau h1 {
    padding: 10% 0 0;
}

.wrapper_bandeau .img_bandeau {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100% auto;
    background-position: center top;
}

#bloc_nos_produits {
    position: relative;
    clear: both;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: .02rem;
    text-transform: uppercase;
    color: $txtBlack;
    line-height: 28px;
    height: 70px;
}

#left_menu_cat {
    overflow: hidden;
    padding: 0 0 25px;
}

.bloc_ss_cat {
    overflow: hidden;
    padding: 0 0 10px;
}

.btn_expand,
.btn_expand_off {
    float: left;
    cursor: pointer;
    height: 14px;
    margin-right: 4px;
    margin-top: 1px;
    padding-top: 0;
    width: 14px;
    background: none;
}

.bloc_cat .btn_expand,
.bloc_cat .btn_expand_off {
    display: none;
}

.btn_select div:first-child,
.btn_select_off div:first-child {
    background: url("../img/checkboxes.gif") no-repeat 0 bottom;
    float: left;
    height: 10px;
    margin-right: .75rem;
    margin-top: 2px;
    padding-top: 0;
    width: 10px;
    cursor: pointer;
}

.btn_expand_off,
.btn_select_off div:first-child {
    background-position: 0 top;
}

.bloc_obj .btn_select,
.bloc_obj .btn_select_off {
    background: none;
    width: 10px;
    margin: 0;
    line-height: 12px;
}

.select_recherche {
    border-bottom: 1px solid #ccc;
    font-weight: 700;
    font-size: 9px;
    height: 23px;
    line-height: 23px;
    padding: 0;
    clear: both;
    color: #171901;
    text-transform: uppercase;
}

.collapsible .select_recherche {
    background: transparent url("../img/recherche_select.png") no-repeat right 5px;
    cursor: pointer;
}

.collapsible .select_recherche.collapsible-closed {
    background: transparent url("../img/recherche_select_close.png") no-repeat right 5px;
}

#slider_prix div#prix_min {
    width: 78px;
    font-family: arial;
    font-size: .75rem;
    color: #909090;
    border: 1px solid #e6e6e6;
    margin-right: 5px;
    background: $white;
}

#slider_prix div#prix_max {
    text-align: left;
    width: 78px;
    font-family: arial;
    font-size: .75rem;
    color: $black;
    border: 1px solid #e6e6e6;
    color: #909090;
    margin-left: 5px;
    margin-right: 3px;
    background: $white;
}

#slider_prix div#prix_max span,
#slider_prix div#prix_min span {
    float: right;
    margin: 3px 6px 0 0;
}

.ui-slider {
    position: relative;
    text-align: left;
    z-index: 0;
}

.ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 14px;
    height: 14px;
    cursor: pointer;
}

.ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: 0.7em;
    display: block;
    border: 0;
}

.ui-slider-horizontal {
    height: 3px;
}

#satisfactionForm .ui-slider-horizontal .ui-slider-handle {
    margin-top: auto;
    top: -3px;
    background: none;
}

.ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 1px;
    margin: 0;
}

.ui-slider-horizontal .ui-slider-range-min {
    left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
    right: 0;
}

.ui-slider-vertical {
    width: 0.8em;
    height: 100px;
}

.ui-slider-vertical .ui-slider-handle {
    left: -.3em;
    margin-left: 0;
    margin-bottom: -.6em;
}

.ui-slider-vertical .ui-slider-range {
    left: 0;
    width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
    bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
    top: 0;
}

.ui-widget-header {
    background-color: $black;
}

#slider_prix div#btn_prix a {
    background: $green;
    color: $white;
    border: 1px solid #e6e6e6;
    display: block;
    font-size: 9px;
    width: 20px;
    height: 18px;
    text-align: center;
    line-height: 18px;
}

.prix_barre_rayon {
    background: url("../img/ligne_barre.gif") repeat-x scroll left 60% transparent;
    margin: 0 0 0 10px;
}

.cadre1_horiz .eclat_horiz {
    position: absolute;
    top: -30px;
    right: 10px;
    z-index: 15;
}

.cadre1_horiz .hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.cadre1_horiz .hover div {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    transition: all 0.3s ease;
}

.cadre1_horiz .hover:hover div {
    opacity: 1;
}

.wrapper_description {
    position: relative;
}

.cadre1_horiz .texte_produit_horiz {
    float: left;
}

.cadre1_horiz .texte_produit_horiz h2 {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 14px;
    color: #282828;
}

.cadre1_horiz .texte_produit_horiz h3 {
    font-size: 12px;
    color: #949494;
    text-transform: uppercase;
}

.cadre1_horiz .conteneur_prix {
    float: right;
    color: $txtBlack;
    font-size: 1.5rem;
}

.cadre1_horiz .conteneur_prix sup {
    font-size: 12px;
}

.cadre1_horiz .conteneur_prix sup.chf {
    vertical-align: baseline;
}

.cadre1_horiz .conteneur_prix span {
    font-size: 12px;
    margin: 0 0 0 -5px;
}

.cadre1_horiz .conteneur_prix span.chf {
    margin: 0;
}

.cadre1_horiz .conteneur_prix div:last-child:not(:nth-child(1)) {
    display: block;
    font-size: 13px;
    text-align: right;
    margin-top: 7px;
}

/******************************************************************************/
/* FICHE PRODUIT */
/******************************************************************************/
.general_main_wrapper {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
}

#content_page_product {
    position: relative;
    top: 0;
}

.fond_middle {
    width: 73.15%;
    max-width: 1200px;
    position: relative;
    float: left;
    margin: 0 0 0 1.5%;
    padding: 37px 0 0;
}

.eclatProd,
.eclat_epuise,
.eclat_nouveaute {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
}

#associations_by_product_slider {

    .eclatProd,
    .eclat_epuise,
    .eclat_nouveaute {
        max-width: 55%;
    }
}

.eclat_epuise {
    z-index: 3;
}

.product_navigation {
    display: none;
}

.row_look .pdt_icon_btm {
    bottom: 0;
}

.row_look .pdt_icon_btm img {
    width: 50px;
}

span.ico_selection_holder {
    float: right;
}

.garanti_holder {
    display: inline-block;
    padding: 0 12px;
}

.ecalt_rect_place img,
span.ico_selection_holder img {
    width: auto;
}

.eclat_vu_tv {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

#content_page_product .eclatProd {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

#zoom_div {
    width: 100%;
    height: auto;
    background: transparent url("../img/ajaxLoader.gif") center center no-repeat;
    overflow: hidden;
}


#zoom_div {
    position: relative;
}

.altimg {
    position: absolute;
    bottom: 2.5%;
    left: 5.6%;
    width: 13.25%;
    height: auto;
    border: 1px solid rgba(0, 0, 0, 0);
    cursor: pointer;
}

.look_scroll {
    position: absolute;
    bottom: 5.25%;
    left: 50%;
    transform: translateX(-50%);
    width: 11.625%;
    height: auto;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s linear;
}

.look_scroll:hover {
    background-color: rgba(255, 255, 255, 0.125);
}

#div_btn {
    width: 28px;
    height: 28px;
    position: absolute;
    bottom: 8px;
    left: 8px;
    z-index: 1;
    border: 1px solid $black;
}

span.delivery_status_title {
    font-size: .75rem;
    color: $black;
    font-weight: 700;
}

#autres_vues {
    border: 1px solid #f0f0f0;
    border-top: none;
    padding: 15px 0;
}

#product_other_views {
    width: 100%;
    height: auto;
    position: relative;
    margin: 0 auto;
}

#product_other_views #content_img_visu_slider {
    width: 100%;
    height: 100%;
    position: relative;
}

#product_other_views img {
    width: 100%;
    height: 100%;
}

#product_other_views .bx-prev {
    background: url("../img/visu_zoom_prev.jpg") no-repeat 0 0;
    left: -13px;
}

#product_other_views .bx-prev:hover {
    background: url("../img/visu_zoom_prev_hover.jpg") no-repeat 0 0;
}

#product_other_views .bx-next {
    background: url("../img/visu_zoom_next.jpg") no-repeat 0 0;
    right: -37px;
}

#product_other_views .bx-next:hover {
    background: url("../img/visu_zoom_next_hover.jpg") no-repeat 0 0;
}

.social_network {
    line-height: 1.5rem;
}

.social_network .link_fb,
.social_network .link_send {
    padding: 0 0 0 8px;
    cursor: pointer;
}

.autre_pdt_container {
    width: 100%;
    margin-top: 60px;

    .middle_bottom {
        position: relative;

        .prev_assocs_slider,
        .next_assocs_slider {
            display: block;
            position: absolute;
            top: calc(50% - 46px);
            width: 32px;
            height: 32px;
            background: transparent url(../img/swiper-arrow.png) center no-repeat;
            cursor: pointer;
            opacity: 1;
            z-index: 5;
            transition: right 0.35s ease-out, left 0.35s ease-out;

            &.swiper-button-disabled {
                opacity: 0;
            }
        }

        .prev_assocs_slider {
            right: auto;
            left: -7px;
        }

        .next_assocs_slider {
            left: auto;
            right: -7px;
            transform-origin: 50%;
            transform: rotate3d(0, 0, 1, 180deg);
        }
    }
}

.wrapper_gamme {
    position: relative;
}

.new_gamme {
    float: left;
    width: 270px;
    height: 280px;
    background: $white;
    position: relative;
}

.new_gamme .bloc_left {
    width: auto;
    background: none;
    position: relative;
}

.bloc_left .img_gamme {
    height: 193px;
    overflow: hidden;
    width: 270px;
    background: url("../img/ajaxLoader.gif") center center no-repeat;
}

span.autre_thumb_holder {
    width: 270px;
    display: block;
    height: 193px;
}

span.autre_thumb_holder div {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    color: #666;
}

.new_gamme:hover span.autre_thumb_holder div {
    display: block;
}

.prix_site_gamme_assoc,
.product_saleprice {
    font-size: 30px;
    color: #757575;
    float: left;
    white-space: nowrap;
}

.prix_site_gamme_assoc {
    line-height: 34px !important;
}

.prix_site_gamme_assoc sup {
    font-size: 14px;
}

.prix_site_gamme_assoc span {
    font-size: 14px;
    margin: 0 0 0 -10px;
}

.prix_site_gamme_assoc sup.chf {
    vertical-align: baseline;
}

.prix_site_gamme_assoc span.chf {
    margin: 0;
}

.gamme_detail_block {
    padding: 8px 2px 0;
}

#wrapper_prod_tarif {
    float: right;
    width: 21%;
    padding: 0 35px 0 0;
    position: relative;
    top: 38px;
    right: 0;
}

#wrapper_prod_tarif .m-premium,
.sep_premium {
    display: none;
}

.premium .sep_premium {
    display: block;
    width: 45px;
    height: 1px;
    border-top: 1px solid $white;
    background: $white;
    margin: 0 0 12px;
    box-shadow: 2px 2px 0 $black;
}

.premium #wrapper_prod_tarif .m-premium {
    display: block;
    margin: 0 0 20px -10px;
    background: $black;
    padding: 10px;
    text-indent: 0;
}

.premium .premium_title {
    width: 100%;
    margin: 0 0 0 -35px;
    padding: 0 35px 35px;
}

#wrapper_prod_tarif h1 {
    font-size: 37px;
    text-transform: uppercase;
    color: #636363;
    font-weight: 300;
    line-height: 38px;
}

.premium #wrapper_prod_tarif h1 {
    color: $white;
    text-shadow: 1px -1px 0 $black;
}

#wrapper_prod_tarif h2 {
    font-size: 12px;
    text-transform: uppercase;
    color: #404040;
    line-height: 20px;
}

.premium #wrapper_prod_tarif h2 {
    color: $white;
}

.ico_selection_2014 {
    float: right;
}

#eclat_vu_tv_fiche {
    position: absolute;
    top: 3px;
    right: 3px;
}

.content_prix_product {
    font-weight: normal;
    font-size: 40px;
    color: #282828;
    position: relative;
    float: left;
    padding: 0 5px 0 0;
    line-height: 40px;
}

.content_prix_product sup {
    font-size: 18px;
    vertical-align: super;
}

.content_prix_product sup.chf {
    vertical-align: baseline;
}

.content_prix_product.prix_marche sup {
    font-size: 15px;
}

.content_prix_product span {
    font-size: 18px;
    margin: 0 0 0 -10px;
}

.content_prix_product span.chf {
    margin: 0;
}

.prix_marche {
    font-size: 1.375rem;
    color: #817f7f;
    margin: 0 0 0 18px;
    background: url("../img/back_prix_barre.jpg") repeat-x center center;
    min-width: 0;
    width: auto;
    float: left;
}

#product_selects .left_element_prod {
    width: 65px;
    color: #757575;
    letter-spacing: 0.04em;
    font-size: .75rem;
}

.pt #product_selects .left_element_prod {
    width: 100px;
}

#ligne_couleur .left_element_prod,
#ligne_qte .left_element_prod,
#ligne_taille .left_element_prod {
    float: left;
}

#couleurProd_container,
#tailleUnique {
    border: 1px solid #ecece8;
    text-transform: uppercase;
    color: $black;
    font-size: 12px;
    padding-right: 5px;
    float: left;
    width: 125px;
    text-align: right;
}

.picto_couleur {
    display: inline-block;
    width: 19px;
    height: 19px;
    overflow: hidden;
    border: 1px solid #ddd;
    margin: 0 2px 0 0;
}

.picto_couleur img {
    vertical-align: top;
    width: 19px;
    height: 19px;
}

.picto_couleur.actif {
    border: 1px solid $green;
    width: 23px;
    height: 23px;
    vertical-align: -2px;
    margin: 0 2px 0 -2px;
}

.picto_couleur.actif img {
    width: 23px;
    height: 23px;
}

#add_alert {
    margin: 25px 0 0;
    clear: both;
}

#add_alert #bloc_btn_alert_active,
#add_basket #bloc_btn_active {
    text-align: left;
}

#add_alert #bloc_btn_alert_active .btn_violet_big,
#add_basket #bloc_btn_active .btn_violet_big {
    width: 100%;
    margin: 0 auto;
    height: 48px !important;
    line-height: 48px !important;
    background: #171717;
    color: $white;
    text-align: center;
    font-size: 10px;
    float: none;
    padding: 0;
    border: 0;
    letter-spacing: 0.18em;
    font-weight: 700;
}

#add_alert.btn_preco,
#add_basket.btn_preco {
    position: relative;
}

#add_alert.btn_preco #bloc_btn_alert_active .btn_violet_big,
#add_basket.btn_preco #bloc_btn_active .btn_violet_big {
    background: #171717 url("../img/ico_btn_preco.gif") no-repeat 80% center;
}

#add_alert .roll_preco,
#add_basket .roll_preco {
    display: none;
    position: absolute;
    width: 100%;
    padding: 12px 0 12px 40px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 18px;
    color: #9b9797;
    background: #fbfbfb;
    border: 1px solid #ff6565;
}

#add_alert.btn_preco:hover .roll_preco,
#add_basket.btn_preco:hover .roll_preco {
    display: block;
}

#bloc_btn_alert_loader,
#bloc_btn_loader,
#bloc_btn_loader_look {
    color: #8e9f1a;
    font-size: 12px;
    width: 100%;
    height: 48px;
    line-height: 48px;
    text-align: center;
}

#bloc_btn_alert_loader img,
#bloc_btn_loader img,
#bloc_btn_loader_look img {
    vertical-align: middle;
}

#add_alert #bloc_btn_alert_active #btn_add_alert.btn_violet_big.btn_alerte_stock {
    background: url("../img/icon_alerte_pdt.png") 15px 14px no-repeat #171717;
    padding-left: 20px;
    white-space: normal;
    line-height: 14px !important;
}

.pdv_fiche {
    display: block;
    width: 100%;
    height: 48px;
    padding: 9px 0 0;
    border: 2px solid $green;
    color: $green;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    text-decoration: none;
    box-sizing: border-box;
    text-align: center;
    margin: 9px 0 0;
    transition: all 0.5s ease;
}

.pdv_fiche:hover {
    background: $green;
    color: $black;
}

.pdv_fiche span {
    display: block;
    float: left;
    margin: 2px -50px 0 44px;
    transition: all 0.2s ease;
}

.pt .pdv_fiche span {
    margin: 2px -50px 0 25px;
}

.pdv_fiche:hover .h-pdv {
    background-position: -99px -262px;
}

/******************************************************************************/
/* LOOKBOOK */
/******************************************************************************/
.wrapper_total_look {
    width: 100%;
    border: 1px solid #e9e8e5;
    box-sizing: border-box;
    margin: 20px 0 12px;
}

.wrapper_total_look .title {
    font-size: .75rem;
    color: #171717;
    text-transform: uppercase;
    padding: 30px 0 20px 20px;
    font-weight: 700;
}

.wrapper_total_look .price_total.pricetag {
    padding: 0 20px;
}

.wrapper_total_look #tease_3xcb {
    margin: 20px 0 30px 20px;
    padding: 0;
}

.slide_look_detail {
    padding: 20px 0 0;
    text-align: left;
    display: flex;
}

.slide_look_detail .intro,
.slide_look_detail h3 {
    font-size: 31px;
    color: #636363;
    text-transform: uppercase;
    font-weight: 100;
}

.slide_look_detail .intro {
    margin: -5px 0 17px;

    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.0075em;
}

.row_look {
    display: block;
    width: 23%;
    min-height: 660px;
    box-sizing: initial;
    border-left: 1px solid #f3f2f2;
    position: relative;
    text-decoration: none;

    &:not(:last-child) {
        margin: 0 0 30px;
    }
}

.row_look.num_0,
.row_look.num_4,
.row_look.num_8 {
    border-left: none;
}

.row_look .wrapper_img {
    width: 100%;
    position: relative;
    text-align: center;
}

.row_look .look_infos,
.row_look .visu_look {
    width: 90%;
    margin: 0 auto;
}

.row_look .look_infos {
    width: 80%;
    margin: 15px auto 0;
}

.row_look h2 {
    font-size: .75rem;
    color: #282828;
    text-transform: uppercase;
}

.row_look .wrapper_price {
    margin: 0 0 10px;
}

.row_look .price {
    font-size: 30px;
    color: #757575;
    margin: 0 8px 0 0;
}

.row_look .price sup {
    font-size: 14px;
    vertical-align: 14px;
}

.row_look .price span {
    font-size: 14px;
    margin: 0 0 0 -5px;
}

.row_look .price sup.chf {
    vertical-align: baseline;
}

.row_look .price span.chf {
    margin: 0;
}

.row_look .stroke_price {
    margin-top: 17px;
    background: -webkit-linear-gradient(top, #333 0%, #333 100%) center center repeat-x;
    background: linear-gradient(to bottom, #333 0%, #333 100%) center center repeat-x;
    background-size: 1px 1px;
    color: #333;
}

.wrapper_price .stroke_price {
    background: -webkit-linear-gradient(top, #333 0%, #333 100%) center center repeat-x;
    background: linear-gradient(to bottom, #333 0%, #333 100%) center center repeat-x;
    background-size: 1px 1px;
    color: #333;
    display: inline;
}

.wrapper_price .stroke_price sup.chf {
    vertical-align: baseline;
}

.row_look .stroke_price sup.chf {
    vertical-align: baseline;
}

.row_look .product_ecoparticipation {
    font-size: 9px;
    color: #c2cf13;
    margin: 2px 0 0;
    line-height: 12px;
}

.row_look .product_ecoparticipation span {
    display: block;
    color: $black;
}

.row_look .row_color_qte {
    display: block;
    clear: both;
    margin: 0 0 8px;
}

.row_look .row_color_qte label {
    display: block;
    width: 64px;
    line-height: 25px;
    font-size: .75rem;
    color: #757575;
    text-transform: uppercase;
}

.pt .row_look .row_color_qte label {
    width: 78px;
}

.row_look .select_unique {
    line-height: 25px;
}

.look_description,
.row_look .itm_description {
    margin: 0;
    font-size: 12px;
    text-transform: none;
}

.look_description .title,
.row_look .itm_description .title {
    color: #171717;
}

.look_description .title {
    margin: 1rem 0 10px;

    text-transform: uppercase;
}

/******************************************************************************/
/* COPYRIGHT */
/******************************************************************************/
#copyright {
    position: relative;
    width: 100%;

    .backToTop {
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        bottom: 3.5rem;
        right: 1rem;
        font-size: 15px;
        color: $txtBlack;
        cursor: pointer;
        opacity: 0;
        transition: opacity .3s ease;
        z-index: -1;

        span {
            letter-spacing: -.01rem;
            line-height: .5;
        }

        &:before {
            content: '';
            display: flex;
            justify-content: center;
            align-items: center;
            width: 65px;
            height: 65px;
            background-color: $txtBlack;
            border-radius: 50%;
        }

        &:after {
            content: '';
            position: absolute;
            top: 25px;
            border: solid white;
            border-width: 0 1px 1px 0;
            display: block;
            padding: 10px;
            transform: rotate(-135deg);
        }

        &.show {
            z-index: 4; // due to products in list page
            opacity: 1;
        }
    }
}

body.product_page {
    #copyright .backToTop {
        bottom: 4rem;

        &.absolutePos {
            top: -11.25rem;
        }
    }

    &.isBatchProduct {

        #banner_flowbox_title,
        #flowbox-container {
            display: none;
        }
    }
}

#copyright_content {
    width: 100%;
}

.reassurance_holder {
    background: $white;
    padding: 28px 0;
    width: 100%;
    margin: 0 auto;
}

.reassurance_holder .wrapper {
    width: 100%;
    max-width: 1600px;
    min-width: 990px;
    margin: 0 auto;
}

.pt #copyright .bloc {
    width: 33%;
}

#copyright .reassurance_holder .bloc.last {
    padding-right: 0;
    float: right;
    background: none;
}

.pt #copyright .reassurance_holder .bloc.service_client {
    background: none;
}

#copyright .reassurance_holder .bloc .ico {
    margin: 0 8px 0 0;
    vertical-align: top;
}

#copyright .reassurance_holder .bloc .c-cards {
    display: block;
}

.pt #copyright .reassurance_holder .bloc .c-cards {
    width: 100px;
    height: 39px;
    background: transparent url("../img/c_cards_pt.png") no-repeat right top;
}

#copyright .footer_bloc_media .txt,
#copyright .reassurance_holder .bloc .txt {
    display: inline-block;
    text-align: left;
    vertical-align: top;
}

#copyright .footer_bloc_media .title,
#copyright .reassurance_holder .bloc .title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;

    color: #3f3f3f;
    margin: 0 0 1px;
}

#copyright .footer_bloc_media .txt a,
#copyright .reassurance_holder .bloc a {
    text-decoration: none;
    color: #666;
    font-size: .75rem;
}

#copyright .footer_bloc_media .txt a:hover,
#copyright .reassurance_holder .bloc a:hover {
    color: $black;
}

#copyright .footer_bloc_media .txt p,
#copyright .reassurance_holder .bloc p {
    font-size: .75rem;
    line-height: 13px;
}

#copyright .reassurance_holder .bloc.service_client span {
    text-decoration: underline;
    font-size: 9px;

}

#copyright .reassurance_holder .bloc.service_client span.nous_contacter {
    text-transform: uppercase;
    font-size: .75rem;
    display: block;
}

span.attention_paiement_virement {
    font-size: 7px;

    float: left;
}

.media_descrip {
    display: inline-block;
    margin: 0 0 0 10px;
}

.c-logo-premium {
    margin: -4px 0 5px;
}

.footer_bloc_media {
    width: 100%;
    background: #f9f9f9;
    padding: 32px 0;
    border-bottom: 1px solid $white;
}

.footer_bloc_media ul {
    width: 100%;
    min-width: 990px;
    max-width: 1600px;
    margin: 0 auto;
    text-align: center;
}

.footer_bloc_media ul li {
    display: inline-block;
    list-style: none;
    text-align: left;
    width: 25%;
}

.footer_bloc_media ul li.holder_second {
    width: 28%;
}

.footer_bloc_media ul li.holder_third {
    width: 22%;
}

.footer_bloc_media ul li.holder_fourth {
    width: auto;
}

.footer_bloc_media ul li a {
    text-decoration: none;
}

.footer_bloc_media ul li a:hover .media_lien {
    color: $black;
    background: url("../img/arrow_black.gif") top right no-repeat;
}

.footer_bloc_media .ico,
.footer_misc .ico {
    vertical-align: top;
}

.media_lien {
    background: url("../img/arrow_green.gif") top right no-repeat;
    padding-right: 9px;
    text-transform: uppercase;
    line-height: 14px;
    color: $green;
    font-size: .75rem;
}

p.media_description {
    font-size: 12px;
    font-weight: 400;
    color: #9e9e9e;
    line-height: 13px;
    margin: 0 0 4px;
}

.media_lien:hover {
    color: $black;
}

.footer_misc {
    padding: 25px 0;
    text-align: left;
}

.footer_misc ul {
    width: 100%;
    min-width: 990px;
    max-width: 101.5rem;
    margin: 0 auto;
}

.footer_misc ul li {
    list-style: none;
    float: left;
    width: 25%;
}

.pt .footer_misc ul li {
    list-style: none;
    float: left;
    width: 40%;
}

.pt .footer_misc ul li.holder_first {
    width: 40%;
}

.pt .footer_misc ul li.holder_fourth {
    width: 20%;
}

.footer_misc ul li a {
    text-decoration: none;
}

.footer_misc ul li.footer_blog_post_recent {
    width: 33%;
}

.footer_misc ul li.footer_blog_post_recent a {
    color: #949494 !important;
}

.c-reseaux {
    vertical-align: top;
}

.chrome .evaluation {
    margin: 2px 0 0;
}

.evaluation a {
    text-decoration: none;
    display: block;
}

#copyright .credits {
    font-size: 10px;
    color: #919191;
    padding: 20px 0;
    width: 100%;
    background: url("../img/footer_credit_bg.jpg") repeat-x left top;
    line-height: 18px;
    font-size: .75rem;
    text-align: center;
}

#copyright .credits a {
    color: #919191;
    font-size: 10px;
}

#copyright .credits a:hover {
    color: $black;
}

#copyright .credits a {

    font-size: .75rem;
    color: #4f4f4f;
    text-decoration: none;
}

.footer_legal {
    display: block;
    width: 100%;
    line-height: 18px;
    padding: 0 0 15px;

    font-size: 10px;
    color: #919191;
    text-align: center;
}

#copyright .mention_legal {
    font-style: italic;
    text-align: left;
}

#copyright .mention_wrapper {
    width: 960px;
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 60px;
}

#copyright .mention_legal.home {
    margin: 30px auto 20px;
}

.footer_blog span.icon_holder {
    background-position: -85px 0;
    margin-top: 1px;
}

.footer_blog {
    width: 203px;
}

.footer_blog .media_descrip {
    margin-left: 25px;
}

p.blog_article_title {
    color: #838383;
    font-size: 12px;
}

p.blog_article_title span {
    color: #c4d600;
    font-size: 12px;
}

.article_blog {
    padding-bottom: 5px;
}

.article_blog .media_description {
    font-size: .75rem;
    color: #b5b5b5;
}

.footer_misc ul li.btm_menu_holder {
    margin-right: 0;
    width: 330px;
    float: right;
}

span.footer_reserved a {
    text-decoration: none;
}

span.footer_reserved a:hover {
    text-decoration: underline !important;
}

#popup_batchnbr_img,
#popup_garantie_img {
    display: none;
    width: 397px;
    height: 174px;
    left: 50%;
    margin: -70px 0 0 -198px;
}

#popup_batchnbr_img {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    background-color: $white;
    padding: 1em;
    margin: 0;
}

#popup_batchnbr_img p {
    width: 405px;
    text-align: justify;
    color: #444;
}

#popup_batchnbr_img span,
#popup_garantie_img span {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
}

.video_wrapper {
    position: relative;
    width: 81%;
    margin: 45px auto 0;
    cursor: pointer;
}

.custom_play_controls {
    background: url("../img/play_button.png") no-repeat scroll center center;
    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
    cursor: pointer;
}

.info_slider_rgt {
    margin-left: 65px;
    padding-top: 30px;
}

.info_slider_rgt.num_00 {
    margin-left: 83px;
    padding-top: 30px;
}

.infos_hm_slider.slider_info_decouvert {
    top: 0;
    height: 100%;
    padding: 0 55px;
    left: 0;
}

.infos_hm_slider.slider_info_decouvert p.slider_titre {
    text-transform: uppercase;
    color: #c1cd01;
    font-weight: 700;
    font-size: 52px;
    margin-bottom: 0;
    line-height: 66px;
    padding-top: 5%;
}

.infos_hm_slider.slider_info_decouvert p.slider_titre span {
    font-size: 101px;
    display: block;
    color: #ccd547;
    margin-bottom: 8px;
}

.infos_hm_slider.slider_info_decouvert p.slider_sous_selection {
    color: #6b6b6b;
    font-size: 23px;
    text-transform: uppercase;
    line-height: 30px;
    font-weight: 800;
}

.infos_hm_slider.slider_info_decouvert p.slider_sous_date {
    font-size: 18px;
    color: $txtBlack;
    text-transform: uppercase;
    padding-top: 25%;
    margin-bottom: 0;
}

span.slider_btn_promo {
    color: #363635;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 15px;
    line-height: 50px;
    display: block;
    border: 2px solid $green;
    text-align: center;
    margin-top: 35px;
}

.infos_hm_slider.slider_info_decouvert .btn_buy {
    line-height: 50px;
    height: 50px;
    width: 50%;
    font-size: 15px;
    margin-top: 25px;
}

.slider_code_promo_1 {
    border: 1px solid #ebebeb;
    text-transform: uppercase;
    height: 49px;
    line-height: 49px;
}

.slider_code_promo_4 {
    border: 1px solid #ebebeb;
    text-transform: uppercase;
    height: 49px;
    line-height: 49px;
    width: 444px;
}

.txt_code_promo {
    display: inline-block;
    padding: 0 10px;
    width: 272px;
    text-align: center;
    font-size: 1.375rem;
    color: #6b6b6b;
}

.code_promo_selectable {
    display: inline-block;
    padding: 0 30px;
    background: #ebebeb;
    color: $black;
    font-weight: bold;
    width: 92px;
    text-align: center;
    font-size: 23px;
}

.slider_info_top {
    height: 61px;
    line-height: 60px;
    background: rgba(255, 255, 255, 0.9);
    text-align: center;
    width: 100%;
    position: absolute;
}

.slider_fdp_top {
    display: inline-block;
    font-size: 33px;
    color: #becb1c;
    text-transform: uppercase;
    font-weight: bold;
}

.slider_fdp_top span {
    color: #333333;
    font-size: 1.375rem;
    vertical-align: 4px;
    font-weight: normal !important;
}

.slider_fdp_code_top {
    background: $white;
    display: inline-block;
    padding: 3px 10px;
    vertical-align: top;
    line-height: 30px;
    height: 31px;
    margin-top: .75rem;
    font-size: 12px;
    text-transform: uppercase;
    width: 208px;
    margin-left: 7px;
}

.slider_fdp_txt {
    display: inline-block;
    color: #797979;
    vertical-align: 4px;
}

.slider_fdp_selectable {
    display: inline-block;
    color: #333333;
    font-size: 1.375rem;
    font-weight: bold;
}

.infos_hm_slider.info_seul_fr.site_1 .item_1 p {
    margin: 0;
    display: inline-block;
}

.prix_span {
    color: #333;
    display: inline-block;
    font-size: 30px;
    padding-left: 10px;
}

.prix_span sup {
    font-size: 14px;
    vertical-align: 14px;
}

.prix_span .cents {
    font-size: 14px;
    margin-left: -4px;
}

.infos_hm_slider.info_seul_fr.site_1 .item_1 .slider_pdt_sub_name {
    font-size: 20px;
    color: #909090;
}

.infos_hm_slider.info_seul_fr.site_1 .item_2 .prix_span {
    display: none;
}

.infos_hm_slider.info_seul_fr.site_1 .item_2 p {
    margin-bottom: 0;
}

.infos_hm_slider.info_seul_fr.site_1 .item_1 .btn_buy {
    margin-left: 92px;
    margin-top: 10px;
}

.infos_hm_slider.info_seul_fr.site_1 .item_2 .btn_buy {
    margin-left: 1.375rem;
    margin-top: 33px;
}

.infos_hm_slider.info_seul_fr.site_1 .item_3 .btn_buy {
    margin-left: 1.375rem;
    margin-top: 10px;
}

.infos_hm_slider.info_seul_fr.site_1 .item_4 .btn_buy {
    margin-left: 92px;
    margin-top: 10px;
}

.infos_hm_slider.info_seul_fr.site_1 .item_5 .btn_buy {
    margin-left: 1.375rem;
    margin-top: 33px;
}

.infos_hm_slider.info_seul_fr.site_1 .item_6 .btn_buy {
    margin-left: 1.375rem;
    margin-top: 10px;
}

.mozaic {
    list-style-type: none;
    padding: 3.2% 3.2% 0;
    margin: 0;
}

.mozaic:after {
    display: block;
    clear: both;
    content: '';
}

.mozaic .itm_thumb {
    position: relative;
    float: left;
    width: 30.75%;
    margin: 0 3.1% 3.1% 0;
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.16s linear;
}

.mozaic .itm_thumb.closing {
    transform: scale(0);
}

.mozaic .itm_thumb:nth-child(3n) {
    margin-right: 0;
}

.mozaic .itm_thumb:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid #f3f3f3;
    transition: background-color 0.2s linear, border-color 0.2s linear;
    content: '';
}

.mozaic .itm_thumb:hover:after {
    background-color: rgba(255, 255, 255, 0.6);
    border-color: #e5e5e5;
}

.mozaic .itm_thumb .thumb_del {
    position: absolute;
    top: 0;
    right: 0;
    width: 14px;
    height: 14px;
    background: url("../img/close_btn_mini.png") center center no-repeat;
    background-size: 14px 14px;
    border: 2px solid #dbdbdb;
    border-radius: 50%;
    margin-top: -7px;
    margin-right: -7px;
    box-sizing: border-box;
    cursor: pointer;
    z-index: 10;
    transition: border-color 0.2s linear;
    appearance: none;
}

html.ff .mozaic .itm_thumb .thumb_del {
    background-position: -2px -2px;
}

.mozaic .itm_thumb:hover .thumb_del {
    border-color: $green;
}

.mozaic .itm_thumb .price_tag {
    display: none;
    position: absolute;
    background-color: $white;
    padding: 9px 9px .75rem .75rem;
    border: 2px solid #c4d600;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);

    font-weight: 700;
    font-size: 17px;
    color: #282828;
    z-index: 10;
    transform: translate(-115%, -50%);
}

.mozaic .itm_thumb .price_tag:after {
    display: block;
    position: absolute;
    top: 50%;
    left: 100%;
    width: 7px;
    height: .75rem;
    background-image: url("../img/bubble_tip.png");
    transform: translateY(-50%);
    content: '';
}

.mozaic .itm_thumb:hover .price_tag {
    display: inline-block;
}

.mozaic .itm_thumb .price_tag sup {
    position: relative;
    top: -2px;
    font-size: 9px;
}

.mozaic .itm_thumb .price_tag .cents {
    margin-left: -7px;
    font-size: 9px;
}

.mozaic .itm_thumb .thumb_del:hover+.price_tag {
    display: none;
}

.mozaic .itm_thumb .thumb_img {
    vertical-align: top;
    width: 100%;
    height: auto;
}

@media screen and (max-width: 1094px) {
    #slider_prix div#btn_prix a {
        width: 40px;
    }
}

/******************************************************************************/
/* RESOLUTION 1600 - 1024 */
/******************************************************************************/
@media screen and (max-width: 1600px) {
    #menu_wrapper>ul>li>a {
        padding: 0 40px;
    }

    .wrapper_txt_premium .cadre {
        border: none;
        padding: 3.4% 0;
        margin: 0 auto;
    }

    .wrapper_txt_premium .logo {
        width: 100%;
        display: block;
    }

    .wrapper_txt_premium .logo img {
        padding: 0 0 2%;
        width: 162px;
        height: auto;
    }

    .wrapper_txt_premium p {
        width: 95%;
        display: block;
        float: none;
        padding: 0;
        margin: 0 auto;
    }

    .wrapper_txt_premium p .catalogue {
        display: block;
        margin: 0 0 0 20px;
    }
}

@media screen and (max-width: 1280px) {
    .infos_hm_slider.slider_info_decouvert p.slider_sous_date {
        padding: 10% 0 0;
    }

    .infos_hm_slider.slider_info_decouvert p.slider_titre {
        font-size: 40px;
        line-height: 50px;
    }

    .infos_hm_slider.slider_info_decouvert p.slider_titre span {
        font-size: 67px;
    }

    #menu_wrapper>ul>li>a {
        padding: 0 21px;
    }

    .pt #menu_wrapper>ul>li>a {
        padding: 0 30px;
    }

    .last_products .right_part h3 {
        font-size: 27px;
        padding: 3% 0 0 3%;
    }

    .last_products .right_part h3 img {
        width: 140px;
    }

    .last_products .right_part h3 span {
        font-size: 1.375rem;
    }

    .last_products .right_part p {
        padding: 10px 0 15px 3%;
        font-size: 10px;
        width: 170px;
    }

    .last_products .right_part .btn_buy {
        margin: 0 0 0 3%;
    }

    .last_products .encarts_fr h3 {
        top: 4%;
        left: 3%;
        font-size: 27px;
        line-height: 25px;
    }

    .last_products .encarts_fr h3 span {
        font-size: 20px;
    }

    .last_products .encarts_fr p {
        width: 165px;
        top: 42%;
        left: 3%;
        font-size: 10px;
    }

    .last_products .encarts_fr .btn_buy {
        left: 3%;
    }

    .wrapper_txt_premium .cadre {
        width: 100%;
        border: none;
        padding: 3.4% 2.9%;
        margin: 0;
    }

    .wrapper_txt_premium .logo {
        width: 100%;
        display: block;
    }

    .wrapper_txt_premium .logo img {
        padding: 1% 0;
        width: 130px;
        height: auto;
    }

    .wrapper_txt_premium p {
        width: 95%;
        display: block;
        float: none;
        padding: 0;
        margin: 0 auto;
    }

    .wrapper_txt_premium p .catalogue {
        display: inline;
        margin: 0 0 0 40px;
    }

    #wrapper_prod_tarif {
        width: 28%;
        padding: 0 20px;
    }

    #wrapper_prod_tarif h1 {
        font-size: 30px;
    }

    .fond_middle {
        width: 66%;
    }

    .footer_bloc_media ul li.holder_first {
        width: 23%;
        padding: 0 0 0 30px;
    }

    .row_look {
        width: 33%;
    }

    .row_look .pdt_icon_btm img {
        width: 30px;
    }
}

@media screen and (max-width: 1200px) {
    #menu_wrapper>ul>li>a {
        padding: 0 21px;
    }

    .pt #menu_wrapper>ul>li>a {
        padding: 0 17px;
    }

    .fond_middle {
        width: 65%;
    }

    .wrapper_txt_premium .cadre {
        border: none;
        padding: 3.4% 2.9%;
        margin: 0;
    }

    .wrapper_txt_premium .logo {
        width: auto;
        display: inline;
    }

    .wrapper_txt_premium .logo img {
        padding: 5% 12% 0 0;
        width: 100px;
        height: auto;
    }

    .wrapper_txt_premium p {
        width: auto;
        display: inline;
        float: none;
        padding: 0 1% 0 0;
    }

    .wrapper_txt_premium p .catalogue {
        display: inline;
        margin: 0 0 0 30px;
    }

    .slider_solde_01 .date_title {
        font-size: 12px !important;
    }
}

.bloc_alerte_intemperie {
    padding: 0 10px 15px;
    color: #ff0000;
}

.bloc_alerte_intemperie_compte {
    padding: 15px 0 20px;
    color: #ff0000;
    font-size: 13px;
}

.pdt_premium .icon_arrow {
    width: 15px;
    height: 15px;
    display: inline-block;
    background: url("../img/premium_btn_ico_noir.png") top left no-repeat;
    margin-right: 4px;
    vertical-align: -3px;
}

.slider_solde_01 {
    background: none repeat scroll 0 0;
    min-height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    padding-right: 0;
    text-align: center;
}

.slider_solde_01 .tagline_1 {
    color: #00616c;

    font-weight: 700;
    letter-spacing: -1.5px;
    line-height: 2.7vw;
    padding-bottom: 0;
    padding-top: 15%;
    text-transform: uppercase;
    font-size: 3.23vw;
}

.slider_solde_01 .tagline_5 {
    color: rgb(72, 72, 72);

    font-size: 11.5rem;
    font-weight: 100;
    letter-spacing: -1.5px;
    line-height: 53px;
    padding-bottom: 0;
    padding-top: 40px;
    text-transform: uppercase;
}

.slider_solde_01 .tagline_4 {
    color: #00616c;
    font-size: 3.28vw;
    font-weight: 300;
    line-height: 1.5;
}

.slider_solde_01 .tagline_2 {

    font-size: 1.375rem;
    font-weight: 400;
    text-transform: uppercase;
    color: #333333;
    padding-top: 3px;
    padding-top: 0.3em;
    padding-left: 10px;
}

.slider_solde_01 .tagline_3 {

    font-size: 3.23vw;
    font-weight: 700;
    color: #00616c;
    padding-top: 0;
    padding-bottom: 1.5rem;
    padding-left: 10px;
}

.slider_solde_01 p.tagline_3:before {
    content: '';
    width: 5%;
    height: 2px;
    background: #00616c;
    position: absolute;
    left: 34.5%;
    bottom: 58%;
}

.slider_solde_01 p.tagline_3:after {
    content: '';
    width: 5%;
    height: 2px;
    background: #00616c;
    position: absolute;
    right: 33.9%;
    bottom: 58%;
}

.slider_solde_01 p.tagline_3 {
    position: relative;
}

.slider_solde_01 .date_title {

    font-weight: 700;
    font-size: 1rem;
    color: rgb(86, 81, 81);
    padding-top: 3vw;
    padding-left: 67px;
}

@media screen and (max-width: 1700px) {
    .slider_solde_01 {
        right: 0;
        top: 0;
    }
}

.slider_solde_01 img {
    max-width: 100%;
    display: block;
    height: auto;
    margin: 0 auto;
}

.slider_soldes_btns {
    height: 81px;
    padding-top: 27vw;
    box-sizing: border-box;
    padding-left: 10px;
}

.slider_soldes_btns a.btn_buy {
    border: 2px solid #00616c;
    box-sizing: border-box;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    height: 9.3vh;
    line-height: 9vh;
    text-decoration: none;
    text-transform: uppercase;
    width: 29vw;
    background: $white;
    float: none;
}

.slider_soldes_btns a.btn_buy::before {
    left: -5px;
    padding-right: 5px;
    color: $white;
    background: #00616c;
}

.slider_soldes_btns a i {
    width: 10px;
    height: 12px;
    background: url("../img/home_legacy/slider_btn_fleche.png") top left no-repeat;
    right: .75rem;
    top: 13px;
    position: absolute;
}

.slider_soldes_btns a:hover i {
    background-position: 0 -12px;
}

.fr.site_1 .bandeau_solde_top {
    height: 41px;
    background: #b3d101;
    display: block;
}

.bandeau_solde_top {
    display: none;
}

.bandeau_solde_top img {
    display: block;
    margin: 0 auto;
    padding-top: 1px;
}

.soldes2016mentions {
    line-height: 14px;
}

.infos_hm_slider.info_seul_fr.site_4 {
    padding: 0;
    min-height: 0;
}

.infos_hm_slider.info_seul_fr.site_4 .info_slider_rgt {
    padding: 30px 25px 28px 39px;
    margin: 0;
}

.tunel_txt_ecoparticipation {
    font-size: 10px;
    color: #999;
}

.concours_cont {
    width: 78.5%;
    position: absolute;
    left: 11%;
    top: 19%;
}

.concours_cont img {
    width: 100%;
    height: auto;
    vertical-align: bottom;
}

.concours_text {
    width: 64.5%;
    background: $white;
    box-sizing: border-box;
    padding: 1.5rem 38px;
    opacity: 0.9;
    position: absolute;
    right: 0;
    bottom: 0;
}

.concours_text p {
    float: left;
    font-size: 28px;
    color: #6a645e;
    text-transform: uppercase;
    margin-top: 10px;
    font-weight: 300;
}

.concours_text .green {
    color: #becc27;
    font-size: 30px;
    font-weight: 700;
}

.concours_text .btn_buy {
    float: right;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 700;
    width: 176px;
    height: 60px;
    line-height: 17px;
    padding: 10px 0;
    box-sizing: border-box;
}

.concours_text .btn_buy>span {
    height: 60px;
}

.concours_text .btn_buy::before {
    padding: 10px 0;
}

.sitemap_section {
    width: 48%;
}

#middle_box_site_map.middle_box {
    width: 100%;
    column-count: 2;
}

@media screen and (min-width: 1920px) {
    .slider_solde_01 .date_title {
        font-size: 1rem;
        padding-top: 58px;
    }

    .slider_solde_01 .tagline_1 {
        font-size: 62px;
    }

    .slider_solde_01 .tagline_2 {
        font-size: 1.375rem;
    }

    .slider_solde_01 .tagline_3 {
        font-size: 17px;
    }

    #content_wrapper .last_products {
        font-size: 62px;
    }

    .slider_solde_01 .tagline_4 {
        font-size: 64px;
    }

    .slider_soldes_btns a.btn_buy {
        width: 608px;
    }

    #content_wrapper .last_products {
        max-width: 1920px;
    }

    .last_products .right_part .bloc_catalogue,
    .last_products .right_part .bloc_premium,
    .last_products .right_part .composez_salon {
        height: 318px;
        width: 960px;
    }

    .slider_soldes_btns {
        padding-top: 506px;
    }

    .slider_soldes_btns a.btn_buy {
        font-size: 25px;
    }
}

@media screen and (max-width: 1900px) {
    .boutique.series .menu_right {
        display: none;
    }
}

@media screen and (max-width: 1860px) {
    .concours_text {
        padding: 1.5rem;
    }

    .concours_text p {
        font-size: 1.5rem;
    }

    .concours_text strong {
        font-size: 26px;
    }

    .slider_solde_01 .tagline_5 {
        font-size: 6.4vw;
    }

    .slider_solde_01 .tagline_4 {
        font-size: 2.7vw;
    }
}

@media screen and (max-width: 1730px) {
    .concours_text p {
        font-size: 1.375rem;
    }
}

@media screen and (max-width: 1652px) {
    .concours_text {
        padding: 10px 14px;
    }

    .concours_text p {
        font-size: 20px;
    }

    .concours_text .green {
        font-size: 1.375rem;
    }

    .concours_text .btn_buy {
        font-size: 12px;
        height: 50px;
        width: 140px;
        line-height: 12px;
    }

    .concours_text .btn_buy>span {
        height: 50px;
    }

    .slider_solde_01 .tagline_2 {
        font-size: 1.5vw;
    }

    .slider_solde_01 .date_title {
        font-size: 15px;
        padding-left: 9px;
    }
}

@media screen and (max-width: 1335px) {
    .concours_text .btn_buy {
        font-size: .75rem;
        height: 45px;
        width: 130px;
        line-height: .75rem;
    }

    .concours_text .btn_buy>span {
        height: 45px;
    }

    .concours_text .green {
        font-size: 18px;
    }

    .concours_text p {
        font-size: 1rem;
    }

    .slider_solde_01 .tagline_1 {
        line-height: 2.4vw;
        padding-bottom: 14px;
        padding-top: 20px;
    }

    .slider_solde_01 .tagline_2 {
        font-size: 1.25vw;
    }
}

@media screen and (max-width: 1130px) {
    .concours_text p {
        font-size: 14px;
        margin-top: 12px;
    }

    .concours_text .green {
        font-size: 15px;
    }

    .slider_solde_01 .tagline_1 {
        line-height: 2.4vw;
        padding-bottom: 14px;
        padding-top: 20px;
    }

    .slider_solde_01 .tagline_2 {
        font-size: 1.25vw;
    }

    .slider_solde_01 .tagline_2 {
        font-size: 1.25vw;
    }
}

@media screen and (max-width: 990px) {
    .slider_solde_01 .tagline_1 {
        padding-bottom: 0;
        font-size: 32px;
        line-height: 26px;
    }

    .slider_solde_01 .tagline_3 {
        font-size: 32px;
    }

    .slider_solde_01 .tagline_4 {
        font-size: 33px;
    }

    .slider_soldes_btns a.btn_buy {
        width: 314px;
        font-size: 13px;
    }

    .slider_soldes_btns {
        padding-top: 281px;
    }
}

#contact_msg_text_vide {
    color: rgb(255, 0, 0);
    margin-left: 33px;
    padding-top: 5px;
}

.loader_reponse_contact {
    height: 25px;
    margin-left: 660px;
    position: absolute;
    width: 25px;
}

.annuler_reponse.envoyer_reponse.f_right.active {
    cursor: default;
    pointer-events: none;
    color: #ccc;
}

.formItem_container .detail {
    float: left;
    width: 100%;
    padding-top: 5px;
}

/******************************************************************************/
/* ACHAT EXPRESS */
/******************************************************************************/
#lightbox_achat_express,
.lightbox_achat_express {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 990px;
    min-height: 648px;
    overflow: hidden;
    background: $white;
    z-index: 10000;
}

@media screen and (max-width: 101.5rem) {
    .slider_soldes_btns a.btn_buy {
        margin: 0 0 0 5px;
    }
}

#site_global_wrap .erratum_txt {
    margin: 13px 0 20px;
}

.bandeau .main_wrapper {
    margin-top: 1rem;
}

.bandeau .page_produit.config {
    margin: 25px auto 30px;
}

.bandeau .bandeau_link img {
    border-top: 1px solid #307e3f;
    border-bottom: 1px solid #307e3f;
}

body.es {
    a.bandeau_link {
        display: none !important;

        img {
            display: none !important;
        }
    }
}

.erratum_pdf {
    line-height: 3;

    font-size: 13px;
    color: $black;

    a {
        text-decoration: underline !important;
        color: $green;

        &:hover {
            text-decoration: none !important;
        }
    }
}

/******************************************/

/* champ pays */

/*****************************************/

.wrapper_mes_adresses .input_container input,
.wrapper_mes_adresses .input_container select,
.wrapper_mes_adresses .input_container textarea {
    &#pays {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px;
        color: #414243;

        padding-left: 5px;
        text-indent: 8px;

        &::-webkit-input-placeholder {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 12px;
            color: #414243;
            padding: 5px;
        }

        &::-moz-placeholder {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 12px;
            color: #414243;
            padding: 5px;
        }

        &:-ms-input-placeholder {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 12px;
            color: #414243;
            padding: 5px;
        }

        &:-moz-placeholder {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 12px;
            color: #414243;
            padding: 5px;
        }

        option {
            padding: 5px;
        }
    }
}

[v-cloak] {
    display: none;
}



.product_highlights_wrapper {
    h2 {
        text-align: center;
        padding-top: 1rem;
    }
}

#searchComponent {
    #breadcrumbs {
        display: none;
    }

    .nav_right {
        display: none; // WP-22759 hide top_pager pagination on search page

        .pager_wrapper.top_pager {
            width: auto;
            text-align: right;
            display: inline-flex;
            align-items: center;
            justify-content: flex-end;

            .see_all_product {
                margin-right: 0;
                font-size: 13px;
            }
        }

        .pagination {
            padding-bottom: 0;
        }
    }

    #choix_recherche {
        .menuitem {
            margin: 1rem 0;
            position: relative;

            input {
                height: auto;
                position: absolute;
            }

            &.actif {
                font-weight: bold;
            }
        }
    }

    #block_selected_filter {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        div+div {
            margin-left: 1rem;
        }
    }
}

.bot_pager {
    display: flex;
    justify-content: flex-end;

    .pagination {
        min-width: 125px;
    }
}

.top_pager {
    cursor: pointer;

    .pagination {
        cursor: pointer;
    }
}

.category {
    #choix_recherche .bloc_sub_categ {
        .sub_categ {
            display: block;
        }
    }
}

// SITE CH

.site_3 {
    #content_wrapper.boutique {
        max-width: 1580px;
        justify-content: space-between;
    }
}

.product_page {
    #bloc_add_basket {
        .cache {
            display: none !important;
        }

        .button.loader {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            line-height: 50px;
            background-color: #2b2b2b;

            font-size: 15px;
            color: #fff;
            text-transform: uppercase;

            span {
                text-transform: uppercase;
            }

            &.loading {
                display: flex !important;
            }
        }

        #btn_add_cart {
            transition: all .1s ease-in-out;

            &:disabled {
                transition: all .1s ease-in-out;
                background-color: transparent;
                color: $txtBlack;
                border: 1px solid $txtBlack;
                pointer-events: none;
                cursor: not-allowed;
                opacity: 0.4;

                @media screen and (max-width: 1030px) {
                    margin-top: 15px;
                }
            }
        }
    }

    #bloc_add_alert {
        #btn_add_alert {
            font-family: $primaryFont;
            font-weight: 400;
            font-size: 17px;
            line-height: 20px;
            color: $white;
            letter-spacing: 0;
            text-align: center;
            text-transform: uppercase;
        }
    }

    #btns_popup_ajout_panier {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btn_container {
            width: 46%;
            position: relative;

            .pop_valid {
                width: 100%;
                margin-left: 0;
            }

            &.loading {
                .loader {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    opacity: 1;
                    z-index: 3;
                    background-color: #2b2b2b;

                    span {
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    &.de #bloc_add_basket #btn_add_cart {
        font-size: 13px;
    }

    &.de {
        #bloc_add_alert {
            #btn_add_alert {
                font-size: 13px;

                @media only screen and (max-width: 1160px) {
                    font-size: 11px;
                }
            }
        }

        .product_info {
            .itm_form_group {
                .form_submit {
                    button.find_retailer {
                        span {
                            font-size: 13px;

                            @media only screen and (max-width: 1160px) {
                                font-size: 11px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.form_submit,
.input_container,
.btn_container {
    position: relative;
}

.info_cta {
    margin-top: 1rem;
}

.btn_box {
    position: relative;

    .loader {
        position: absolute;
        width: 100%;
        height: 45px;
        background: $btnBgColor;
        cursor: default;
        background: url(../svg/three_dots.svg) $btnBgColor no-repeat center center !important;
        background-size: 15% !important;
        top: 0;
        left: 0;
        right: 0;

        span {
            font-size: 0px;
        }
    }
}

body.footer_contact.bg_sofa.sc-show {
    background: none;
}

/* Swiper filters */
#catFiltersSwiper {
    overflow: unset !important;
    margin: 0;

    .swiper-slide {
        width: fit-content;
        flex-shrink: unset;
    }
}

.swiper-filters-button {
    visibility: hidden;
}

#content_obj.filters-have-swiper {
    overflow: hidden;

    .nav_left fieldset+fieldset {
        margin: 0;
        padding-right: 0;
    }

    .swiper-filters-button {
        visibility: visible;
        top: 0;
        width: 16px;
        height: 16px;
        margin: 0;
        background-color: white;
        background-size: auto;

        &.swiper-button-next {
            right: -.5rem;
            background-image: url(../img/arr_next_boutique.png);
        }

        &.swiper-button-prev {
            left: 0;
            background-image: url(../img/arr_prev_boutique.png);
        }
    }
}

// Search wrapper style
.super_boutik .dynasearchwrapper {
    max-width: 1600px;
    width: 98%;
    margin: 0 auto;
}

//FLOWBOX TITLE
#banner_flowbox_title {
    text-align: center;
    padding: 25px 0 0;

    .bzfy-feed-banner {
        display: none;
    }

    p {
        font-family: $primaryFont;

        &.banner_h1 {
            font-weight: 700;
            font-size: 25px;
            line-height: 30px;
            margin-bottom: 10px;
        }

        &.banner_h2 {
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 16px;
        }

        strong {
            font-family: $primaryFont;
            font-weight: 700;
        }
    }
}

#bzfy-feed-gate {
    #bz-gate-content {
        .bzfy-gate-product-name {
            font-weight: 400;
        }

        .bzfy-gate-product-price {
            font-weight: bold;
            font-size: 1rem;
        }
    }

    .bzfy-gate--report-view__button {
        padding: 0 1.5rem;
        text-align: center;
        text-indent: 0;
    }
}

body.product_page {
    #flowbox-container {
        .bzfy-feed-banner {
            display: none;
        }

        .bzfy-c-carousel-slider-wrapper__inner {
            display: block !important;
        }
    }
}

#banner_algolia_search {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1680px;
    width: 98%;
    margin: 0 auto;

    #banner_algolia_search_img {
        width: 100%;
        height: auto;
    }
}

.category {
    .pricetag+div:not(.pricetag).old-price {

        position: absolute;
        top: 100%;
        right: -1px;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        width: max-content;
        font-size: 11px;
        font-weight: 400;
        letter-spacing: 0;
        white-space: nowrap;
        color: #8a8279;

        &::first-letter {
            text-transform: uppercase;
        }

        .price {
            display: block !important;
        }

        .cents {
            font-size: 9px;
            margin-left: 0;
        }
    }
}