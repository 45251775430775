.bank_left_side {
    width: calc(100% - 400px);
    max-width: 929px;
    margin-top: 76px;
}

/**
 * Payment type buttons
 */
.wrapper_payment_mode {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 14px;
    margin-bottom: 20px;

    .selectPaymentModeTitle {
        width: 100%;
        font-size: 17px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .payment_mode_btn {
        position: relative;
        width: 18%;
        min-width: 174px;
        height: 100px;
        border: 1px solid grey;
        box-sizing: border-box;
        cursor: pointer;
        transition: all .3s ease-in-out;

        &:hover,
        &.actif {
            background-color: #2b2b2b;
            border: 1px solid #2b2b2b;

            span {
                color: white;
            }
        }

        span {
            position: absolute;
            bottom: 22px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            font-size: 17px;
            text-align: center;
            white-space: nowrap;

            &:before {
                content: '';
                display: block;
                width: 33px;
                height: 33px;
                margin-bottom: 8px;
            }
        }

        &[data-payment-mode="hosted_hipay"] {
            span:before {
                background: transparent url(../svg/credit-card.svg) no-repeat center;
            }

            &:hover span:before,
            &.actif span:before {
                background: transparent url(../svg/credit-card-w.svg) no-repeat center;
            }
        }

        &[data-payment-mode="paypal_hipay"] {
            span:before {
                width: 69px;
                height: 17px;
                background: transparent url(../svg/paypal.svg) no-repeat center;
                margin-bottom: 14px;
            }

            &:hover span:before,
            &.actif span:before {
                filter: brightness(0) invert(1);
            }
        }

        &[data-payment-mode="applepay_hipay"] {
            span:before {
                width: 56px;
                background: transparent url(../svg/apple-pay.svg) no-repeat center;
            }

            &:hover span:before,
            &.actif span:before {
                filter: brightness(0) invert(1);
            }
        }

        &[data-payment-mode="alma_inpage_payment_2"], &[data-payment-mode="alma_inpage_payment_3"] {
            span:before {
                width: 47px;
                height: 13px;
                background: transparent url(../svg/alma.svg) no-repeat center;
                margin-bottom: 16px;
            }

            &:hover span:before,
            &.actif span:before {
                filter: brightness(0) invert(1);
            }
        }

        &[data-payment-mode="payment_virement"] {
            span {
                bottom: 16px;
                line-height: 1.8;

                &:before {
                    display: none;
                }
            }
        }
    }
}

/*
 * _app override 🤬
 */
.wrapper_basket_content .bank_left_side .wrapper_bloc_banque {
    margin: 0;
    padding: 0;
}

.wrapper_bloc_banque {
    display: none;

    .radio {
        display: none;
    }

    &.actif {
        display: block !important;
    }

    .bank_title {
        font-size: 17px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 19px;
    }

    .bank_subtitle,
    .cards-wrapper,
    .bank_confiance {
        display: none;
    }

    &.hosted_hipay {
        .bank_subtitle {
            display: block;
            font-size: 13px;

            span,
            strong {
                display: none;
            }

            span:first-of-type {
                display: flex;
                align-items: center;
                font-size: 13px;
                color: #2b2b2b;

                &:after {
                    content: '';
                    display: block;
                    width: 15px;
                    height: 15px;
                    @include maskIconPath('../svg/spritesheet.svg');
                    @include maskIconCoord(25, 6, 6);
                    background-color: #2b2b2b;
                    margin-left: .3rem;
                }
            }
        }
    }

    .total_transaction {
        display: none;
    }

    .alias_list  {
        li {
            margin-top: 0;

            form .card_numbers {
                display: flex;
                justify-content: flex-start;
                align-items: baseline;
                flex: initial;
                width: 31%;
                padding: 0 0 0 7%;

                @media screen and(max-width: 1200px) {
                    width: 34%;
                    padding: 0 0 0 4%;
                }

                span:first-child {
                    display: block;
                    margin-right: 5px;
                }
            }
        }

        form {
            justify-content: flex-start;

            .card_name,
            .btnAliasWrapper {
                flex: initial;
            }

            .card_name {
                width: 26%;

                span {
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 14em;
                }
            }
        }
    }

    &.applepay_hipay {
        .validateButton {
            &.button {
                padding: 20px;
            }
        }
    }
}

/**
 * Hipay aliases
 */
.alias_list li,
#addAliasListElem {
    position: relative;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #D9D9D9;
    margin: 0 0 15px 8px;
    padding: 10px 19px 10px 40px;
    font-family: $primaryFont;
    font-size: 12px;
    letter-spacing: .05em;
    text-transform: uppercase;
    cursor: pointer;

    @media screen and(max-width: 1200px) {
        padding: 10px 19px 10px 30px;
        font-size: 11px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -8px;
        transform: translateY(-50%);
        width: 14px;
        height: 14px;
        border: 1px solid #2B2B2B;
        background-color: #2B2B2B;
        box-shadow: inset 0 0 0 10px white;
        border-radius: 50%;
    }

    &:hover,
    &.selected {
        border-color: #2B2B2B;

        &:before {
            box-shadow: inset 0 0 0 2px white;
        }
    }
}

#addAliasListElem {
    padding: 32px 32px;
    margin: 0 0 25px 8px;
    font-size: 13px;
}

.alias_list form {
    position: relative;

    .card_expiration {
        display: block !important;
    }

    .btnAliasWrapper {
        .use_card {
            display: none;
        }

        button.delete {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 15px;
            height: 15px;
            appearance: none;
            background: white;
            font-size: 0;

            span:before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                @include maskIconPath('../svg/spritesheet.svg');
                @include maskIconCoord(11, 6, 6);
                background-color: #2B2B2B;
            }
        }
    }
}

.wrapperSelectAlias {
    position: relative;
    width: fit-content;
    margin-left: 7px;

    &.hide {
        display: none;
    }

    .loader {
        position: absolute;
        z-index: -1;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid #2B2B2B;
        background: white url(../svg/three_dots_black.svg) no-repeat center;
        background-size: auto 22%;
    }
}

#selectAliasBtn {
    width: 265px;
    height: 60px;
    font-size: 17px;
    background-color: #ccc;
    border: 1px solid #ccc;
    cursor: default;
    transition: all .3s ease-in-out;

    &.disabled:hover {
        background-color: #ccc;
        color: white;
    }

    &.disabled.enabled {
        background-color: #2B2B2B;
        border-color: #2B2B2B;
        color: white;
        cursor: pointer;

        &:hover {
            background-color: white;
            color: #2B2B2B;
        }
    }

    &.enabled {
        background-color: #2B2B2B;
        border-color: #2B2B2B;
        color: white;
        cursor: pointer;

        &:hover {
            background-color: white;
            color: #2B2B2B;
        }
    }

    &.loading + .loader {
        z-index: 1;
        opacity: 1;
    }
}

/**
 * Hipay CB form
 */
form#hipay-3-form {
    margin-top: 10px;
    padding: 0;

    .form_line.saveAlias,
    .form_line.is_default {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        input[type="checkbox"] {
            display: none;

            + label {
                position: relative;
                font-family: $secondaryFont;
                font-size: 15px;
                font-weight: 300;
                color: #2B2B2B;

                &:before {
                    content: '';
                    width: 26px;
                    height: 15px;
                    background-color: #CFCFCF;
                    border: 0;
                    border-radius: 48px;
                    box-shadow: none;
                    transition: none;
                    margin-right: 1em;
                }

                &:after {
                    content: '';
                    width: 13px;
                    height: 13px;
                    background-color: white;
                    border-radius: 50%;
                    position: absolute;
                    top: 1px;
                    left: 1px;
                }
            }

            &:checked + label {
                &:before {
                    background-color: #2B2B2B;
                }

                &:after {
                    left: 12px;
                }
            }
        }
    }

    .form_line.is_default {
        margin-bottom: 1rem;
    }

    #hipay-3-submit-button {
        width: 265px;
        height: 50px;
        background-color: #2B2B2B;
        border: 1px solid #2B2B2B;
        font-family: $primaryFont;
        font-size: 17px;
        transition: background-color .3s ease-in-out, color .3s ease-in-out;

        &:hover {
            background-color: white;
            color: #2B2B2B;
        }

        &:disabled {
            background-color: #ccc;
            border-color: #ccc;

            &:hover {
                color: white;
            }
        }

        &.loading + .hipay-loader {
            display: block;
        }
    }

    .hipay-loader {
        width: 265px;
        height: 50px;
        background: white url(../svg/three_dots_black.svg) no-repeat center;
        background-size: auto 22%;
        border: 1px solid #2B2B2B;
        box-sizing: border-box;
        cursor: default;
    }
}

body.de {
    form#hipay-3-form #hipay-3-submit-button,
    form#hipay-3-form .hipay-loader {
        width: 300px;
    }
}

#paiement_paypal {
    position: relative;

    .title_wrapper {
        width: 100%;
    }

    .bank_subtitle {
        display: block;

        .paypalButton {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            height: 50px;
            padding: 0 3rem;
            margin: 0 auto;
            border: 1px solid #2B2B2B;
            box-sizing: border-box;
            background-color: #2B2B2B;
            font-family: $primaryFont;
            font-size: 17px;
            font-weight: 400;
            text-transform: uppercase;
            color: white;
            transition: background-color .3s ease-in-out, color .3s ease-in-out;
            cursor: pointer;

            &:hover {
                background: white;
                color: #2B2B2B;
            }

            &.loading {
                background: white url(../svg/three_dots_black.svg) no-repeat center;
                background-size: auto 22%;
                color: transparent;
            }
        }

        span {
            display: none;
        }
    }
    #inputPAYPAL {
        display: none;
    }
}

.wrapper_basket_content .bank_left_side .paypal .bloc_bank_contents {
    flex-wrap: wrap;
}

#bloc_virement {
    .bank_choix_paiement_logo,
    .bloc_section_virement_bancaire.first_block {
        display: none;
    }

    #wrapper_virement {
        width: 100%;
        font-family: $primaryFont;
        font-size: 15px;
    }

    .montant_trans {
        margin: .5rem 0 2rem;

        .unit {
            font-size: 18px;
        }

        .currency,
        .cents {
            font-size: 9px;
        }

        .cents {
            margin-left: -.2rem;
        }
    }

    #bt_valider {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content_bloc_paiement {
        display: flex !important;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0;
        background-color: white;
    }

    .bank_choix_btn_virement {
        width: auto;
        height: 50px;
        padding: 0 3rem;
        font-family: $primaryFont;
        font-size: 17px;
        text-transform: uppercase;
        background-color: #2B2B2B;
        color: white;
        border: 1px solid #2B2B2B;
        margin: 0 auto;
        transition: background-color .3s ease-in-out, color .3s ease-in-out;
        cursor: pointer;

        &:hover {
            background-color: white;
            color: #2B2B2B;
        }

        &.loading {
            background: white url(../svg/three_dots_black.svg) no-repeat center;
            background-size: auto 22%;
            color: transparent;
        }
    }
}

/*
 * Checkout right side*/
body.checkout {
    #cart_total {
        .wrapper_payment {
            order: 13;
                background-color: #fff;
                margin: 24px -35px -65px;
                padding: 15px 0;
        }
    }

    .payment_addr_wrapper {
        position: relative;
        padding: 15px 0;
        border-bottom: 1px solid #999999;

        &:first-child {
            border-top: 1px solid #999999;
        }

        .title {
            font-size: 13px;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 7px;
        }

        .address {
            font-size: 13px;
            line-height: 1.2;
        }

        a.address_mod {
            position: absolute;
            bottom: 15px;
            right: 0;
            font-size: 13px;
            font-weight: 500;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

/*
 * Order Fail messages
 */
.banque_erreur_txt {
    width: 100%;
    padding: 20px;
    border: 1px solid #999999;
    box-sizing: border-box;
    margin-bottom: 20px;


    p {
        display: none;
    }

    .hipayErrorTitle {
        display: block;
        font-size: 17px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .errorBank {
        display: block;
        font-size: 13px;
    }
}

/*
 * Paiement confirmation
 */
body.bankThanks {
    #site_global_wrap {
        min-height: calc(100vh - 120px);
    }

    .tunnel_track {
        display: none;
    }

    .thanksWrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
        width: 98%;
        max-width: 1024px;
        margin: -38px auto 0;
        padding-bottom: 430px;

        @media screen and(max-width: 1440px) {
            margin: 30px auto 0;
        }
    }

    #around_me_map {
        width: 100%;

        .info-content {
            font-size: 13px;
            padding: .5rem 3rem .5rem .5rem;
            font-family: $primaryFont;

            .titleAddress {
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                margin-bottom: .4rem;
            }

            .blank_line {
                display: block !important;
            }
        }

        div[pane_id="floatPane"] {
            span {
                display: none !important;
            }
        }
    }
}

/*
 * app override 🤬
 */
.thanksWrapper {
    .bankThanksTop {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 49%;
        margin: 0;

        h1 {
            order: 1;
            position: relative;
            display: initial;
            width: 100%;
            font-size: 30px;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 26px;
            color: black;
            letter-spacing: initial;

            &:before {
                display: none;
            }

            span {
                display: block !important;
                font-size: 17px;
                font-weight: 400;
                margin-top: 19px;

                & + span {
                    font-size: 13px;
                    text-transform: initial;
                    margin-top: 9px;
                }
            }
        }

        .thanksRight {
            order: 2;
        }

        .back_to_home {
            order: 3;
            width: 100%;
        }

        a.return_navigation {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 13px;
            font-weight: 500;
            text-decoration: underline;
            padding-left: 20px;

            &:hover {
                text-decoration: none;
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                width: 16px;
                height: 16px;
                @include maskIconPath('../svg/spritesheet.svg');
                @include maskIconCoord(14, 6, 6);
                transform: translateY(-50%) rotate(180deg);
                background-color: #2B2B2B;
            }
        }

        #around_me_map {
            position: absolute;
            top: 110%;
            left: 0;
            order: 3;
            width: 204%;
            height: 380px;
        }
    }

    .thanksRecap {
        width: 49%;
        margin: 0;

        .thanksLeft,
        .thanksTrust,
        .bankThanksBtn {
            display: none;
        }
    }

    .thanksRight {
        width: 100% !important;
        height: 100%;
        text-align: left;
    }

    .thanksRightWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        background-color: #F4F4F4;
        padding: 0 30px;
        box-sizing: border-box;

        p {
            font-size: 13px;
            margin-bottom: 10px;

            label {
                font-weight: 600;
            }

            &.trader_name,
            &.trader_address,
            &.authorization_id {
                display: none;
            }

            &.main_ref_transaction {
                margin-bottom: -8px;
            }

            &.txt_thanks_transaction {
                text-transform: initial !important;
            }
        }
    }
}

/*
 * Firefox specific styles
 */
.ff {
    .thanksWrapper .thanksRightWrapper p.main_ref_transaction {
        margin-bottom: .8rem;
    }
}

body.thanks_create_account {
    .thanksWrapper {
        padding-bottom: 470px;
    }

    .thanksRight {
        height: initial;
        margin-bottom: 20px;
    }

    .thanksLeft {
        display: block !important;
        width: 100% !important;
        height: 100%;
    }

    .thanksWrapper {
        .thanksRightWrapper {
            padding: 40px 30px;
        }
    }

    .thanksLeftWrapper {
        display: flex;
        flex-direction: column;
        height: 100%;

        .seeOrderEvo {
            display: none;
        }

        h2 {
            order: 1;
            font-size: 30px;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 23px;
        }

        .thanks_create_intro {
            order: 2;
            font-size: 13px;
            margin-bottom: 13px;
        }

        #clientForm {
            order: 3;
        }

        #deny-optin {
            order: 5;
        }
    }

    .w-input-element:not([type=radio]):not([type=checkbox]),
    input[type=email].w-input-element {
        height: 52px;
        padding: 0 .875rem;
    }

    .optin-consent-line {
        width: auto;
    }

    #deny-optin {
        display: block !important;
    }

    .nbDenyOptin,
    .nbDenyOptinSimple {
        display: none !important;
    }

    #deny-optin .nbThanksDenyOptin {
        display: block !important;
        font-size: 12px;
        color: #636363;
        margin: 20px 0 10px;
    }

    .w-password-input~.w-class-pwd-creation {
        gap: 5px;
    }

    .w-class-pwd-creation {
        padding-top: 10px;
    }

    .bankThanksBtn {
        display: block !important;
        order: 4;
        width: 330px;
        height: 50px;
        margin-top: 20px;
        font-size: 17px;
        text-transform: uppercase;
        background-color: #2B2B2B;
        border: 1px solid #2B2B2B;
        color: white;
        transition: background-color .3s ease-in-out, color .3s ease-in-out;

        &:hover {
            background-color: white;
            color: #2B2B2B;
        }

        &.loading {
            background: white url(../svg/three_dots_black.svg) no-repeat center;
            background-size: auto 22%;
            font-size: 0;
        }
    }

    .thanksWrapper .bankThanksTop #around_me_map {
        top: 105%;
    }
}

body.thanks_virement {
    .thanksWrapper {
        padding-bottom: 480px !important;
    }
}

.tunel_virement_detail {
    .content_bloc_paiement {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
        background-color: #F4F4F4;
        padding: 40px 30px;
        box-sizing: border-box;
        font-size: 13px;
    }

    .montant_trans span {
        font-weight: 600;
    }

    .first_block {
        margin-bottom: 10px;
    }

    table {
        th,
        td {
            vertical-align: top;
        }

        td {
            padding-left: 1rem;
        }

        &.virement_details_adresse {
            tr:first-child {
                td {
                    padding-bottom: 10px;
                }
            }
        }

        &.tab_virement_detail_compte {
            td {
                padding-left: 0;
            }
        }
    }

    .account_infos {
        margin-bottom: 10px;

        .virement_sub_title {
            padding-bottom: 0 !important;
            margin: 10px 0 .8rem !important;
        }
    }
}