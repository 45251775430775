body.concours_avril_2022 {
    .breadcrumbs_wrapper {
        display: none;
    }
}
.jeuConcoursWrapper {
    width: 100%;
    max-width: 1452px;
    margin: 0 auto;

    /* IE11 */
    select::-ms-expand {
        display: none;
    }

    input[type=checkbox]+label:before {
        display: none;
    }

    input[type="text"]:not(#know_precision),
    input[type="email"] {
        &::placeholder {
            opacity: 0;

            @media screen and(max-width: 640px) {
                opacity: .8;
            }
        }
    }

    .labelPlaceholder {
        @media screen and(max-width: 640px) {
            display: none;
        }
    }

    /**
     * TOP BANNER
     */
    .jeuConcoursBanner {
        position: relative;

        img {
            width: 100%;
            height: auto;
            vertical-align: bottom;
        }

        div {
            position: absolute;
            bottom: 0;
            right: 4%;
            background-color: white;
            padding: 1.5rem 3.5rem 0;
        }

        h1,
        p {
            font-family: $eymenpro;
            font-weight: $light;
        }

        h1 {
            font-size: 48px;
            color: #ad9065;
            text-align: center;
        }

        p {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 450px;
            padding: 3rem 0;
            font-size: 32px;
            color: white;
            background-color: #0f6377;

            span {
                font-size: 57px;
                font-weight: 500;

                & + span {
                    font-family: $primaryFont;
                    font-size: 24px;
                }
            }

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 94%;
                height: 90%;
                border: 1px solid #6fa1ad;
            }
        }
    }

    /**
     * CONCOURS CONTENT
     */
    .concoursWrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 98%;
        max-width: 1250px;
        margin: 30px 0 0 6%;

        @media screen and(max-width: 1452px) {
            margin: 30px auto 0 auto;
        }

        .jeu_enquete_content_texte {
            width: 57%;
            max-width: 710px;
            font-size: 13px;
        }

        h2 {
            width: 100%;
            font-size: 40px;
            font-weight: 400;
            letter-spacing: .1rem;
            margin-bottom: 36px;
        }

        .notaBene {
            font-size: 12px;
            margin: 2rem 0 1rem;
        }

        .formRow {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 1rem;

            &.infos {
                margin-bottom: 0;

                &.know-us-opts {
                    justify-content: space-between;
                    flex-wrap: wrap;

                    .inputWrapper {
                        width: 20%;
                    }
                }
            }

            label {
                width: 154px;

                &.mail {
                    width: 80px;
                    margin-left: 2rem;
                }

                &.labelErr {
                    color: red;
                }
            }

            &.reglement {
                label {
                    width: auto;
                    font-weight: normal;
                    line-height: 35px;
                    position: relative;
                    top: 3px;
                }

                input[type="checkbox"],
                input[type="radio"] {
                    &+label {
                        align-items: flex-start;
                        line-height: 1.3;
                        color: #a0a0a0;

                        &:before {
                            flex: none;
                            border: 2px solid #cacaca;
                            background-color: $secondaryColor;
                        }
                    }
                }
                input[type=radio]+label:before {
                    border-radius: 0;
                    width: 15px;
                    height: 15px;
                }

                input[type=checkbox]:checked + label {
                    &:before {
                        border: 2px solid #cacaca;
                        background-color: $secondaryColor;
                    }
                }
            }

            &.infos .inputWrapper {
                &.specify-field {
                    display: none;
                    width: 80%;
                }
            }

            .inputWrapper {
                position: relative;
                width: calc(100% - 154px);
                height: 36px;

                &.selects {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .styled-select {
                        position: relative;
                        width: 22%;
                        height: 100%;
                        border: 1px solid #cacaca;
                        margin-right: 1rem;

                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            right: 36px;
                            width: 1px;
                            height: 100%;
                            background: #cacaca;
                        }

                        &:before {
                            content: '';
                            position: absolute;
                            top: 37%;
                            right: 12%;
                            width: 10px;
                            height: 10px;
                            background: transparent url(../img/select_arrow.gif) no-repeat 88% center;
                            pointer-events: none;
                        }
                    }

                    .styled-select select {
                        background: transparent;
                        appearance: none;
                        border: none;
                        height: 100%;
                        padding: 0 0 0 .5rem;
                        width: 100%;
                        text-indent: 0;

                        &.jc_error {
                            background: #fffada;
                        }
                    }

                }

                .mailWrapper {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    width: 60%;
                }

                &.telAndMail {
                    display: flex;
                    justify-content: space-between;

                    .intl-tel-input {
                        width: 40%;
                    }
                }

                &.mail {
                    width: 62%;
                    max-width: 212px;
                }

                input[type="text"],
                input[type="email"] {
                    width: 100%;
                    height: 100%;
                    background: white;
                    border: 1px solid #cacaca;
                    text-indent: .5em;

                    &.jc_error {
                        @media screen and(max-width: 640px) {
                            border-color: red;
                        }
                    }
                }

                input[type="email"] {
                    border: 1px solid #cacaca;
                    box-sizing: border-box;
                }
            }

            .checkbox_container {
                margin-right: 2rem;

                .inputErr + label {
                    color: #000;

                    @media screen and(max-width: 640px) {
                        color: red;
                    }
                }
            }

            input[type="radio"],
            input[type="checkbox"] {
                display: none;

                &+label {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: auto;

                    &:before {
                        content: '';
                        display: block;
                        width: 15px;
                        height: 15px;
                        border: 2px solid $secondaryColor;
                        background-color: #858487;
                        box-shadow: inset 0 0 0 15px #fff;
                        margin-right: .5rem;
                    }
                }

                &:checked+label:before,
                &+label:hover:before {
                    box-shadow: inset 0 0 0 2px #fff;
                }
            }

            input[type="radio"] {
                &+label {
                    &:before {
                        border-radius: 50%;
                    }
                }
            }

            &.theBrand,
            &.know-us,
            &.infos {
                label {
                    width: 325px;
                }

                .inputWrapper {
                    width: 150px;
                    line-height: 35px;

                    span.radio {
                        vertical-align: -.3rem;
                        float: none;
                        display: inline-block;
                        margin-right: .5rem;
                    }
                }
            }

            &.infos {
                label {
                    width: 154px;

                    &.by_sms {
                        margin-left: 0;
                    }
                }

                .inputWrapper {
                    width: auto;

                    label {
                        width: auto;
                        margin-right: 2rem;
                    }
                }
            }
        }

        .wish_infos {
            line-height: 18px;
            font-weight: bold;
            letter-spacing: -.02rem;
            margin: 2em 0 1em;
        }

        .button_participer {
            margin-top: 2rem;

            .button_valider {
                background-color: #ad9065;
                color: white;
            }

            .button {
                font-size: 18px;
                font-weight: 600;
                padding: 0 2em;
            }
        }

        .jeuenquete_liens {
            margin-top: .8rem;

            .infos-text {
                color: #a0a0a0;
                margin-bottom: 1.2rem;
            }

            a {
                text-decoration: underline;
                color: #636260;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .winnersList {
            ul {
                -webkit-column-count: 3;
                -moz-column-count: 3;
                column-count: 3;
            }
        }
    }

    .rightsidebar {
        width: 36%;
        max-width: 450px;

        @media screen and(max-width: 640px) {
            max-width: 580px;
        }

        a {
            display: block;
            width: 100%;

            img {
                width: 100%;
                height: auto;
                vertical-align: top;
            }

            &:first-child {
                margin-bottom: 20px;
            }
        }
    }

    #msg_erreur_formulaire,
    #msg_exist_email {
        color: red;
        font-size: 14px;
        margin: 1.5rem 0 1rem;
        display: none;
    }

    #msg_exist_email {
        margin: .5rem 0 1rem;
    }

    .confirmationWrapper {
        p {
            font-size: 17px;
            line-height: 1.1;
            color: #636260;
            margin-top: 2rem;
        }

        ul {
            margin-top: 2rem;

            li {
                list-style-type: none;
                line-height: 1.2;
            }
        }

        .reseaux {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 2.5rem 0;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 3.8rem;
                height: 3.8rem;

                &+a {
                    margin-left: 1.5rem;
                }

                &:after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    @include bgIconPath('../svg/social_media.svg');
                    opacity: .6;
                }

                &.facebook:after {
                    @include bgIconCoord(1, 5, 5);
                }

                &.insta:after {
                    @include bgIconCoord(2, 5, 5);
                }

                &.youTube:after {
                    @include bgIconCoord(3, 5, 4.2);
                }

                &.pinterest:after {
                    @include bgIconCoord(5, 5, 4.2);
                }

                &.blog:after {
                    @include bgIconCoord(4, 5, 4.2);
                }
            }
        }

        .button {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 3em;
            padding: 0 2em;
            background-color: #ad9065;
            color: white;
            font-size: 18px;
            letter-spacing: .1rem;
            font-weight: 600;
        }
    }
}