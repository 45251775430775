.page_produit .main_content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;


    #addToWishlistButtonContainer {
        display: block;
        position: absolute;
        top: clamp(10px, fs-vw(20, 1920), 20px);
        right: clamp(10px, fs-vw(20, 1920), 20px);
        transform: translate(10px, -10px); // = the padding of the wishlist button
        width: auto;
        z-index: 2;

        #addToWishlistButton {
            display: flex;
            padding: 10px;
            cursor: pointer;

            &::before {
                content: '';
                width: 30px;
                height: 30px;
                background-image: url(../svg/wishlist_new.svg);
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;
                display: inline-block;
            }

            &.existToWishlistButton {
                &::before {
                    background-image: url(../svg/wishlist_new_rempli.svg);
                    background-size: 104%;
                }
            }

            &:hover {
                &::before {
                    background-image: url(../svg/wishlist_new_rempli.svg);
                    background-size: 104%;
                }
            }
        }
    }

    .netreviews_stars {
        display: flex;
        align-items: center;
        margin: 0;
        width: 100%;

        &:has(.netreviews_review_rate_and_stars)+.price_alma_wrapper .item_price {
            padding-top: 0;
        }

        .NETREVIEWS_PRODUCT_STARS {

            // If there are reviews, show custom CTA
            &:has(*) {
                &+.see_more_reviews {
                    display: block;
                }
            }
        }

        .see_more_reviews {
            display: none;
            position: absolute;
            left: 135px;
            font-size: 11px;
            text-transform: uppercase;
            text-decoration: underline;
            text-underline-offset: 4px;
            background-color: $white;
            padding: 5px;
            z-index: 3;
            cursor: pointer;
        }
    }

    .NETREVIEWS_PRODUCT_STARS {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .netreviews_review_rate_and_stars {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        top: auto;
        height: 40px;
        min-height: 35px;
        cursor: pointer;

        div {
            font-size: 20px;
            white-space: nowrap;

            .nr-icon {
                color: $supernova !important;

                &[style*="width:12%;"] {
                    width: 9.5% !important;
                }

                &[style*="width:10%;"] {
                    width: 8.5% !important;
                }

                &:before {
                    content: "\2606";
                }
            }

            &+div {
                .nr-icon {
                    &:before {
                        content: "\2605";
                    }
                }
            }
        }

        div:not(.netreviews_reviews_rate) {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            letter-spacing: 3px;
        }

        .netreviews_reviews_rate {
            font-size: 11px;
            left: 103px;
        }

        // target only safari
        @media not all and (min-resolution:.001dpcm) {
            @supports (-webkit-appearance:none) {
                .netreviews_reviews_rate {
                    left: 120px;
                }
            }
        }
    }
}

#btn_retour_product {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.65px;
    text-transform: uppercase;
    color: $cmsPageTitle;
    margin-bottom: clamp(10px, fs-vw(20, 1920), 20px);

    &:before {
        content: '';
        background-image: url(../svg/arrow_background.svg);
        background-position: center;
        background-size: 100%;
        width: 20px;
        height: 20px;
        display: block;
        margin-right: 10px;
    }
}


/* Product Left part ***********************************************/
/******************************************************************/

.product_main_wrapper {
    max-width: 917px;
    width: 54.3%;

    .cms_page_product {
        margin-top: clamp(20px, fs-vw(30, 1920), 30px);
    }
}

.itm_views {
    display: flex;
    align-items: flex-start;
}

#visual_scroller_wrapper {
    position: relative;
    max-width: 180px;
    width: 18.32%;
    margin-right: clamp(20px, fs-vw(60, 1920), 60px);

    &:not(:has(.swiper-container-initialized)) {
        max-height: 75vh;
        overflow: hidden;
    }

    .swiper-container:not(.swiper-container-initialized) {

        .thumbNext,
        .thumbPrev {
            opacity: 0;
        }
    }

    .thumbNext,
    .thumbPrev {
        position: absolute;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 22px;
        font-size: 0;
        z-index: 2;
        transition: opacity .3s ease;
        cursor: pointer;

        &.swiper-button-disabled {
            opacity: 0;
            z-index: -1;
        }

        &::after {
            content: "";
            display: block;
            width: 18px;
            height: 18px;
            background-image: url('../svg/arrow.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            z-index: 2;
        }

        &::before {
            content: "";
            width: 100%;
            height: 28px;
            background: $white;
            position: absolute;
            z-index: 1;
        }
    }

    .thumbPrev {
        top: calc(20px + #{clamp(10px,1.04167vw,20px)});
        left: 0;
        align-items: flex-start;

        &::after {
            transform: rotate(180deg);
        }
    }

    .thumbNext {
        bottom: 0;
        left: 0;
        align-items: flex-end;
    }
}

#visual_scroller {
    height: 100%;

    &:not(.swiper-container-initialized) .swiper-wrapper {
        display: flex;
        flex-direction: column;

        .swiper-slide {
            margin-bottom: 10px;
        }
    }

    .swiper-slide {
        cursor: pointer;
        height: auto;

        &::before {
            content: "";
            display: block;
            width: 100%;
            padding-top: 100%; // ratio of a square == images are squared
        }

        &.slide_video {
            &:after {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: url(../img/video_btn_overlay.png);
                background-size: cover;
                content: '';

                [lang='es'] & {
                    background-image: url(../img/video_btn_overlay_es.png);
                }
            }
        }

        &.swiper-slide-thumb-active {
            img {
                border: 1px solid $primaryColor;
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            border: 1px solid $grey16;
            box-sizing: border-box;
        }
    }
}

.content_img_visu {
    cursor: pointer;

    img {
        width: 100%;
        height: auto;
    }
}

#jqzoom {
    position: relative;
    max-width: 677px;
    width: 74%;
    margin-top: 10px;

    .swiper-slide {
        &::before {
            content: "";
            display: block;
            width: 100%;
            padding-top: 100%;
        }
    }

    .img_visu {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        vertical-align: top;
        cursor: url('../svg/loupe_black.svg'), auto;
    }

    .slide_video {
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            position: relative;
            z-index: 3;
            content: "";
            display: block;
            width: 100%;
            padding-top: calc(677 / 677 * 100%);
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }
    }
}

.picturesInfos {
    position: absolute;
    top: 101%;
    left: 0;
    font-size: 10px;
    color: $grey3;
}

.pdt_icon_btm {
    position: absolute;
    z-index: 1;
    bottom: 12px;
    right: 0;
}

.eclat_product_last_fiche_produit {
    position: absolute;
    left: 0;
    top: 0;
}

.itm_highlights {
    margin-top: 60px;

    .collapsed {
        .collapsible {
            display: none;
        }
    }

    .flex_container {
        display: flex;
        justify-content: space-between;
    }

    .specs {
        max-width: 500px;
        width: 54.5%;

        &.bundle {
            width: 50%;
        }

        .pdf_download {
            display: inline-block;
            width: 100%;
            margin: 20px 0 0;

            p {
                font-size: 14px;
                font-weight: 600;
                line-height: 1;
                text-transform: uppercase;
                margin-bottom: 1.2rem;
            }

            .download-icon {
                background: url('../img/download.png') no-repeat 50% 50%;
                width: 20px;
                height: 20px;
                display: inline-block;
                margin-left: 12px;
                transform: translateY(5px);
            }
        }
    }

    .displayed_notice_container {
        display: flex;
        flex-direction: column;
        max-width: 382px;
        width: 41.7%;
        padding: clamp(10px, fs-vw(15, 1920), 15px) clamp(10px, fs-vw(15, 1920), 15px) 0 0;

        .attributes_img {
            a:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }

        img {
            max-width: 100%;
            transition: transform .3s ease;
        }
    }

    .notice_image {
        cursor: url('../svg/loupe_black.svg'), auto;
    }
}

.desc_pannel {
    &.collapsed {
        .title_container {
            &::after {
                transform: rotate(0);
            }
        }
    }

    // Item description
    &#descrPanel {
        .item_refext {
            color: $primaryColor;
            margin-top: clamp(10px, fs-vw(20, 1920), 20px);
        }
    }

    .title_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 2px 15px 0;
        cursor: pointer;

        &:not(.bundle) {
            border-bottom: 1px solid $grey16;
        }

        &.bundle {
            width: 95%; // so it aligns with its parent title
            margin: 10px 20px 0;
        }

        &::after {
            content: "";
            display: block;
            width: 15px;
            height: 15px;
            background-image: url('../svg/arrow.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            box-sizing: content-box;
            transform: rotate(-180deg);
            transition: transform .3s ease;
        }
    }

    .title {
        font-size: 17px;
        line-height: 1;
        font-weight: 700;

        &:not(.bundle) {
            text-transform: uppercase;
        }

        &.bundle {
            font-size: clamp(14px, fs-vw(17, 1920), 17px);
            color: $middleGrey;
        }
    }

    .caracs_container {
        &.bundle .specs {
            margin-left: 20px;
        }
    }
}

// Attribtues and Caracs icons
.ico_container {
    display: flex;
    gap: 13px;
    margin-top: 10px;

    a {
        display: block;
    }
}

.descriptionTxt {
    position: relative;
    overflow: hidden;

    .descriptionTxt__styled {
        font-weight: 300;
        font-size: 13px;
        line-height: 16px;
        text-align: justify;
        color: $black;
        padding: 0.6rem 0;

        a {
            text-decoration: underline;
        }
    }

    p {
        font-weight: 300;
        font-size: 13px;
        line-height: 16px;
        text-align: justify;
        color: $black;
        padding: 0.6rem 0;
    }

    strong {
        font-weight: 600;
    }
}

.lineCarac {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: $primaryFont;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: $grey3;
    width: 100%;
    padding: clamp(9px, fs-vw(9, 1920), 9px) 0;
    border-top: 1px solid $grey16;

    &:first-child {
        border-top: none;
        padding-top: clamp(10px, fs-vw(15, 1920), 15px);
    }

    label {
        font-weight: 600;
        width: 35%;

        @media screen and (max-width: 1366px) {
            width: 40%;
        }
    }

    p {
        margin-left: 0.4rem;
        width: 65%;

        @media screen and (max-width: 1366px) {
            width: 60%;
        }
    }
}

/* Product right part ****************************************/
/************************************************************/
.no_available_see_in_store {
    display: none;
}


.product_info_wrapper {
    position: sticky;
    max-width: 712px;
    width: 42.1%;
    top: clamp(-186px, fs-vw(-186, 1920), -100px);
    margin: 13px 0 0 16px;

    .eco_diff {
        font-size: 13px;
    }

}

.bundle_content {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: clamp(10px, fs-vw(20, 1920), 20px);
    box-sizing: border-box;
}

.batchProdWrapper {
    width: 45%;
    box-sizing: border-box;
    padding-bottom: 20px;

    &:nth-child(even) {
        padding-left: 20px;
    }

    a {
        display: flex;
    }

    @media screen and (max-width: 1300px) {
        width: 100%;

        &:nth-child(even) {
            padding-left: 0;
        }
    }
}

$batchprodImg : 29%;

.batchProdImg {
    max-width: 118px;
    width: $batchprodImg;

    img {
        width: 100%;
    }
}

.batchProdInfos {
    max-width: calc(100% - #{$batchprodImg}); // calc(100% - .batchProdImg)
    padding-left: clamp(10px, fs-vw(20, 1920), 20px);
    box-sizing: border-box;
}

.batchProdTitle {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    line-height: normal;
}

.batchProdSubtitle {
    max-width: 100%;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 3px;

}

.batchProdRef,
.batchProdQte {
    font-size: 13px;
    line-height: normal;
}

.batchProdRef {
    margin-bottom: 4px;
}

// End Bundles

#itm_name {
    font-size: clamp(20px, fs-vw(28, 1920), 28px);
    font-weight: 600;
}

#itm_feat {
    max-width: 80%;
    font-size: 15px;
    font-weight: 400;
    margin-top: 5px;
}

// When product is not batch (not "bundle") --> make product infos enough high so the cross sell will appear after scrolling
body:not(.isBatchProduct) .product_info {
    min-height: 74vh;
}

.product_info {

    .subtitle_container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }


    .item_price {
        padding: 10px 0 clamp(20px, fs-vw(30, 1920), 30px);
    }

    .product_ecoparticipation {
        letter-spacing: unset;
        font-size: unset;
        padding-top: 0;
        padding-bottom: clamp(20px, fs-vw(30, 1920), 30px);

        span {
            font-size: 9px;
            font-weight: 300;

            &:first-of-type {
                font-weight: 400;
                color: $cmsPageTitle;
            }

            &:last-of-type {
                font-weight: 400;
                color: $cmsPageTitle;
            }
        }
    }

    // If has ecopart, no need for padding on price
    .item_price_wrapper:has(.product_ecoparticipation) {
        .item_price {
            padding-bottom: 0;
        }
    }

}

.item_price_wrapper {
    position: relative;

    .discounted_price {
        color: $shiraz;
    }

    .old-price {
        position: absolute;
        bottom: 0.78125vw;
        left: 0;

        .cents {
            font-size: 9px;
            margin-left: 0;
        }
    }
}

.item_refext {
    font-size: 12px;
    color: $cmsPageTitle;

    .ref_lbl {
        font-weight: 600;
    }
}

#alma-widget {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    width: auto;
    height: auto;
    margin: 14px 0 0 0;

    svg {
        path {
            fill: #fa5022;
        }
    }

    .alma-payment-plans-active-option {
        background-color: #fa5022;
        color: #fff;
    }

    .alma-payment-plans-container {
        width: auto;
        display: inline-flex;
        padding: 12px 12px 9px 12px;

        .alma-payment-plans-payment-info {
            font-family: $primaryFont;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.05em;
            margin-top: 6px;
        }
    }
}

/* popup alma */
.ReactModalPortal {
    [data-testid='modal-close-button'] {
        height: auto;
    }

    .alma-eligibility-modal-active-option {
        text-indent: 0;
    }
}

.hide_link_more_info {
    display: none;
}

.payment_secure {
    width: 32px;
    height: 38px !important;
}

/* end popup alma */


.user_product_choice {
    display: block;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;

    &[for="qteProd"] {
        margin-bottom: 10px;
    }

    .chosen_color {
        text-transform: initial;
        font-weight: 400;
    }
}

// Common user choices
#product_selects {
    width: 100%;
}

.ligne_form {
    .list_container {
        position: relative;

        .choices_list_btn {
            position: absolute;
            top: 0;
            display: flex;
            align-items: center;
            width: 25px;
            height: 100%;
            background: none;
            background-color: $white;
            margin-top: unset;
            z-index: 2;
            cursor: pointer;

            &.swiper-button-disabled {
                z-index: -1;
                opacity: 0;

                &::before {
                    opacity: 0;
                }
            }

            &::before {
                content: "";
                display: block;
                width: 15px;
                height: 15px;
                background-image: url('../svg/arrow.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
        }

        .choices_list_prev {
            left: 0;
            right: unset;
            justify-content: flex-start;

            &::before {
                transform: rotate(90deg);
            }
        }

        .choices_list_next {
            right: 0;
            left: unset;
            justify-content: flex-end;

            &::before {
                transform: rotate(-90deg);
            }
        }
    }

    .choices_list {
        max-width: 100%;
        width: 100%;

        &:not(.swiper-initialized) {
            .form_itm:not(:last-of-type) {
                margin-right: 10px;
            }
        }
    }
}

// User size choice
#ligne_pointure {
    margin-bottom: clamp(10px, fs-vw(20, 1920), 20px);

    .form_itm {
        min-width: clamp(100px, fs-vw(150, 1920), 150px);
        width: fit-content;
        font-size: 13px;
        font-weight: 400;
        line-height: 1;
        text-align: center;
        transition: all .3s ease;
        cursor: pointer;
        box-sizing: border-box;

        input {
            display: none;
        }

        label {
            display: block;
            cursor: pointer;
            background-color: $white;
            padding: 12px 10px;
            border: 1px solid $cmsPageTitle;
        }

        &:hover,
        &.checked {
            label {
                background-color: $cmsPageTitle;
                color: $white;
            }
        }
    }

    // TEMPORARY FOR INTEGRATION PURPOSE
    .swiper-wrapper {
        align-items: center;
    }

    .form_itm {
        height: 40px;
    }
}

// User color choice
#ligne_couleur {
    margin-bottom: clamp(10px, fs-vw(20, 1920), 20px);

    .form_itm {
        max-width: 70px;
        position: relative;
        padding-bottom: 8px;
        cursor: pointer;

        &:hover {
            label::after {
                background-color: $grey11;
            }
        }

        &.checked {
            label::after {
                background-color: $cmsPageTitle;
            }
        }

        input {
            display: none;

            &:checked+label:after {
                background-color: $cmsPageTitle;
            }
        }

        label {
            display: block;
            max-width: 70px;
            height: 70px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            cursor: pointer;

            &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                content: "";
                display: block;
                width: 70px;
                height: 2px;
                transition: all .3s ease;
            }
        }
    }
}

.group_container {
    display: flex;
    justify-content: space-between;

    select,
    input,
    textarea,
    button {
        text-indent: unset;
    }
}

#ligne_qte {
    max-width: 152px;
    width: 21.4%;

    // TEMPORARY FOR INTEGRATION PURPOSE
    overflow: hidden;

    &.no_stock {
        .holder_qte_selector {
            opacity: .4;
            pointer-events: none;
        }
    }
}

.stock_delivery {
    flex-wrap: wrap;
    font-size: 13px;
    font-family: $primaryFont;
    margin-bottom: 10px;
}

.stock_info {
    display: flex;
    align-items: center;
    font-weight: 700;
    white-space: nowrap;
    margin-right: 3px;

    &.yes {
        &::before {
            background-color: $green;
        }
    }

    &.no {
        &::before {
            background-color: $red;
        }
    }

    &::before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 4px;
    }
}

.group_addcart {
    max-width: 530px;
    width: 74.5%;

    .button.fwd {
        font-family: $primaryFont;
        font-size: clamp(15px, 0.893vw, 17px);
        line-height: 20px;
        transition: background-color .3s ease;

        &:hover {
            background-color: rgba($primaryColor, .7);
        }
    }
}

.fpLoaderBtn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

#bloc_dispo_mag {
    margin-top: clamp(10px, fs-vw(20, 1920), 20px);
}

.find_retailer {
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    line-height: 1;
    font-family: $primaryFont;
    text-transform: uppercase;
    text-decoration: underline;
    text-underline-offset: 4px;
    transition: all .3s ease;
    cursor: pointer;

    &:hover {
        text-decoration-color: $green;
    }

    &::before {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background-image: url('../svg/distributeur_green.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin-right: 10px;
    }
}

// No stock
#bloc_add_basket,
#bloc_add_alert {
    position: relative;

    .loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: -1;
        background-color: $primaryColor;
        background-image: url('../svg/three_dots.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12%;

        &.loading {
            opacity: 1;
            z-index: 2;
        }

        span {
            display: none;
            font-size: 0;
        }
    }
}

// Product réassurance
#product_addcart {
    .wrapper_delivery {
        display: flex;
        width: 100%;
        margin-top: 50px;
    }
}

.delivery_status_block {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 33.33% !important;
    padding: 0 10px;

    .delivery_details {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .title {
        font-size: clamp(11px, fs-vw(14, 1920), 14px);
        font-weight: 600;
        text-transform: uppercase;
        border-bottom: 1px solid transparent;
        transition: border .3s ease;
    }

    .subtitle {
        font-size: 12px;
        font-weight: 400;
        margin-top: 3px;
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            .title {
                border-bottom: 1px solid black;
            }
        }
    }

    &:nth-child(odd) {
        &::after {
            position: absolute;
            top: 50%;
            right: -15px;
            content: "";
            display: block;
            width: 1px;
            height: 50%;
            background-color: $grey16;
            transform: translateY(-50%);
        }

        @media screen and (max-width: 1024px) {
            &::after {
                right: -6px;
            }
        }
    }

    img {
        max-width: clamp(40px, fs-vw(50, 1920), 50px);
    }
}

// QTE SELECTOR

.holder_qte_selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $primaryColor;
    height: 50px;
    box-sizing: border-box;

    @media screen and (max-width: 1030px) {
        margin-top: 25px;
    }

    /** Sélécteur Changement Quantité **/
    .qte_selector {
        cursor: pointer;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: clamp(20px, 2.1vw, 40px);
        height: clamp(20px, 2.1vw, 40px);
        font-size: clamp(15px, fs-vw(23, 1920), 23px);
        padding: 0 clamp(4px, fs-vw(10, 1920), 10px);
        color: $primaryColor;
        background-color: transparent;

        &:not(.selector_disabled) {
            cursor: pointer;

            &:hover {
                background: $grey16;
            }
        }

        &.selector_disabled {
            opacity: .2;
        }

        &.qte_plus {
            margin-right: 5px;
        }

        &.qte_minus {
            margin-left: 5px;
        }

        span {
            display: none;
        }
    }
}


.qte_selected {
    display: flex;
    justify-content: center;
    font-family: $primaryFont;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    color: $primaryColor;
    margin: 0;
    appearance: none;
    background: none;
    width: clamp(33px, fs-vw(52, 1920), 52px);
    height: 100%;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        display: none;
    }
}

/* Associations ***************************************************************/
.cross_sell_title {
    font-size: 25px;
    line-height: 1.2;
    font-weight: 600;
    color: $primaryColor;
    text-transform: uppercase;
    padding: 15px 2px 15px 0;
    margin-bottom: clamp(10px, 1.04167vw, 20px);

    strong,
    .bold {
        font-weight: 700;
    }
}


// Associations right part
.product_associations_wrapper {
    margin-top: clamp(15px, fs-vw(30, 1920), 30px);

    &#gondole_derniers_articles_vus {
        .middle_bottom {
            position: relative;
            padding: 0 25px;
        }
    }

    &.associations_by_produit {
        margin-top: clamp(15px, fs-vw(35, 1920), 35px);
    }

    &.you_may_like_container {
        .wrap_gondole {
            padding: 0 25px;
        }
    }

    .wrap_title_gondole {
        margin-bottom: clamp(10px, fs-vw(20, 1920), 20px);
    }

    .title_gondole {
        font-size: 17px;
        font-weight: 700;
        text-transform: uppercase;
        word-wrap: break-word;
        padding-bottom: clamp(5px, fs-vw(10, 1920), 10px);
        border-bottom: 1px solid $grey16;
    }

    .wrap_gondole {
        position: relative;
    }

    .assoc_prev,
    .assoc_next {
        position: absolute;
        top: 0;
        width: 25px;
        height: 100%;
        background: none;
        background-color: $white;
        margin-top: unset;
        z-index: 2;
        cursor: pointer;

        &.swiper-button-disabled {
            z-index: -1;
            opacity: 0;

            &::before {
                opacity: 0;
            }
        }

        &::before {
            position: absolute;
            top: 32%;
            content: "";
            display: block;
            width: 15px;
            height: 15px;
            background-image: url('../svg/arrow.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            margin-top: 20px;
        }
    }

    .assoc_prev {
        left: 0;
        right: unset;
        justify-content: flex-start;

        &::before {
            left: 0;
            transform: rotate(90deg);
        }
    }

    .assoc_next {
        right: 0;
        left: unset;
        justify-content: flex-end;

        &::before {
            right: 0;
            transform: rotate(-90deg);
        }
    }

    .old-price {

        .cents {
            font-size: 9px;
            margin-left: 0;
        }
    }
}

#associations_by_product_slider {
    &:not(.swiper-container-horizontal) {
        .swiper-slide {
            width: 33.33%;
            margin-right: 15px;
        }
    }

    &.swiper-container-horizontal {
        padding: 0 25px;
    }

    .addToWishlistButton {
        display: none;
    }

    .swiper-slide {
        max-width: 210px;
    }
}


.associations {

    &.visited_pdt_holder,
    &.you_may_like {
        &:not(.swiper-container-initialized) {
            .swiper-slide {
                max-width: calc(100% / 5);
                margin-right: 45px;
            }
        }
    }

    &.you_may_like {
        .wrap_rolloverproduit .form_submit {
            display: none;
        }

        .eclat_rect {
            display: none;
        }
    }

    &.associations_by_product {
        &:not(.swiper-container-initialized) {
            .swiper-slide {
                max-width: calc(100% / 3);
                margin-right: 15px;

                @media screen and (max-width: 1300px) {
                    max-width: calc(100% / 2);
                }
            }
        }

        .eclat_rond {
            height: clamp(20px, 1.2vw, 38px);
        }
    }

    &.visited_pdt_holder {
        .item_ratings {
            display: none;
        }
    }

    .swiper-slide {
        height: auto;
    }

    .wrapperProdVertical {
        position: relative;
        height: 100%;
        margin-bottom: 0;
        box-sizing: border-box;

        .block_lnk {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .fp_img_container {
            position: relative;
        }

        .item_name {
            margin-top: 10px;
            margin-bottom: 2px;
        }

        .item_feat {
            font-size: 12px;
            color: $cmsPageTitle;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .wrapper_price {
            flex-wrap: wrap;
        }

        .wrapper_price,
        .price {
            display: flex;
            align-items: baseline;

            .pricetag {
                font-size: clamp(22pxpx, fs-vw(30, 1920), 25px);

                &+.pricetag {
                    font-size: clamp(16px, fs-vw(20, 1920), 18px);
                }

                .cents {
                    background-color: transparent;
                }
            }
        }
    }

    .wrapper_description {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .eclat_rond {
        position: absolute;
        top: 0;
        left: 0;
        height: clamp(25px, 1.5vw, 38px);
        z-index: 2;

        img {
            height: 100%;
            width: auto;
        }
    }

    .achat_express {
        .ligne_form {
            display: none;
        }
    }

    .addToWishlistButton {
        display: block;
        position: absolute;
        top: 18px;
        right: 18px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        font-size: 0;
        z-index: 2;

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            background-image: url(../svg/wishlist_new.svg);
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            display: inline-block;
        }

        &.in_wishlist,
        &.existToWishlistButton,
        &:hover {
            &::before {
                background-image: url(../svg/wishlist_new_rempli.svg);
            }
        }
    }

    // Special intégration only on FP for now
    .wrap_rolloverproduit {

        .w-submit-button:not(.w-loader):focus,
        button.w-submit-button:not(.w-loader):focus {
            background-color: $grey3;
        }

        .form_submit {
            position: absolute;
            bottom: 10px;
            right: 8px;
            width: fit-content;

            // When loading, hide form_submit and display loader
            &.loading {
                .button:not(.loader) {
                    opacity: 0;
                }

                .loader {
                    display: block;
                }
            }

            button {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                font-size: 0;

                span {
                    display: none;
                    font-size: 0;
                }

                &:not(.loader) {
                    &:hover {
                        background-color: rgba(29, 29, 27, .7) !important;
                        border: 1px solid transparent;
                    }

                    &::after {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        content: "+";
                        display: block;
                        font-size: 20px;
                        color: $white;
                    }
                }
            }

            .loader {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;
                background-color: rgba(29, 29, 27, .7);

                &::after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    mask-image: url(../svg/loader.svg);
                    background-color: $white;
                    mask-repeat: no-repeat;
                    mask-position: center;
                    mask-size: 70%;
                    border-radius: 50%;
                }
            }
        }
    }
}

.product_page {
    #appContainer #pdvBox.modalbox.deployed {
        z-index: 900;
    }

    .lightbox#abox {
        z-index: 950;
        top: 52%;
        box-shadow: 0 0 20px $darkgray2;
    }

    .product_info_wrapper {
        #product_addcart {
            .wrapper_delivery {
                .delivery_status_block {

                    // override rule for page panier
                    &:nth-child(odd) {
                        &::after {
                            display: none;
                        }

                        @media screen and (max-width: 1024px) {
                            &::after {
                                right: unset;
                            }
                        }
                    }

                    // New rule for FP
                    &:not(:last-child) {
                        &::after {
                            position: absolute;
                            top: 50%;
                            right: -8px;
                            content: "";
                            display: block;
                            width: 1px;
                            height: 50%;
                            background-color: $grey16;
                            transform: translateY(-50%);
                        }

                        @media screen and (max-width: 1300px) {
                            &::after {
                                right: 0px;
                            }
                        }
                    }
                }
            }
        }
    }

    #appContainer {

        #banner_flowbox_title,
        #flowboxbtn_voirplus {
            display: none;
        }
    }
}

// netreviews
#w-netreviews-comments {
    margin: 50px auto 0;

    .nrc_customizable_wrapper {
        display: grid;
        grid-template-columns: 25.5% 74.4%;

        width: 98%;
        max-width: 1680px;
        margin: 0 auto;
    }

    .main_title_nrc {
        grid-column: 1 / span 2;
        width: 100%;
        font-size: 17px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 15px 2px 15px 0;
        border-bottom: 1px solid #d9d9d9;
        margin-bottom: clamp(10px, 1.04167vw, 20px);
    }

    .nrc_mark {
        padding-right: 5em;
        border-right: 3px solid #F9F9F9;

        .title_mark {
            color: $grey3;
            font-size: 17px;
            font-weight: 600;
            letter-spacing: 0.85px;
            margin-bottom: 17px;
        }

        .nrc_average_rating {
            display: flex;
            align-items: flex-end;
            margin-bottom: 45px;

            .nr_rate {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-right: .3em;
                font-size: 1.5em;
                color: #FCC740;
            }

            .star_empty,
            .star_full,
            .star_mid_full {
                display: block;
                width: 20px;
                height: 20px;
                margin-right: .2em;
            }

            .star_empty {
                background: transparent url(../svg/empty_star.svg) no-repeat center;
                background-size: cover;
            }

            .star_full {
                background: transparent url(../svg/full_star.svg) no-repeat center;
                background-size: cover;
            }

            .star_mid_full {
                background: transparent url(../svg/half_star.svg) no-repeat center;
                background-size: cover;
            }


            .nrc_average_mark {
                font-size: 13px;
                font-weight: 600;
            }

            .total_reviews {
                font-size: 12px;
                font-weight: 400;
                letter-spacing: 0.6px;
                margin-left: auto;
                cursor: pointer;
            }
        }

        .nrc_chart {
            width: 100%;
            display: flex;
            flex-direction: column-reverse;
            row-gap: 21px;

            .nrc_chart_single {
                display: grid;
                align-items: center;
                grid-template-columns: auto 1fr auto;
                cursor: pointer;

                &[data-count="0"] {
                    cursor: default;
                }

                .nrc_chart_value {
                    text-align: start;
                    font-weight: 500;
                    margin-right: 15px;
                    display: flex;
                    justify-content: flex-end;

                    &::after {
                        // display a star icon after the number
                        content: '';
                        display: inline-block;
                        width: 2ex;
                        height: 2ex;
                        // ex is a unit that is the height of the lowercase 'x' in the current font
                        background: url(../svg/full_star.svg) no-repeat center;
                        background-size: contain;
                        margin-left: .2em;
                    }
                }

                progress {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    border: none;
                    height: 10px;
                    border-radius: 20px;
                    background-color: #F9F9F9;
                    width: 100%;

                    &::-webkit-progress-bar,
                    &::-moz-progress-bar {
                        background-color: #F9F9F9;
                        border-radius: 20px;
                    }
                }

                progress::-webkit-progress-bar {
                    background-color: #F9F9F9;
                    border-radius: 20px;
                }

                ::-webkit-progress-value {
                    background-color: $green;
                    border-radius: 20px;
                }

                progress::-moz-progress-bar {
                    background-color: $green;
                    border-radius: 20px;
                }

                .nrc_chart_number {
                    text-align: center;
                    font-weight: 500;
                    width: 4ch;
                }
            }
        }
    }

    #nrc_comments {
        position: relative;
        z-index: 9;

        .title_mark {
            margin-bottom: 20px;
            margin-left: 64px;
            font-size: 17px;
            font-weight: 600;
            letter-spacing: 0.85px;
        }

        .nrc_content_comment {
            background-color: #F9F9F9;
            height: 270px;
            padding: 26px 32px 16px;
            box-sizing: border-box;
        }

        .swiperNetreviews {
            &:after {
                content: '';
                position: absolute;
                z-index: 1;
                top: 0;
                right: -16px;
                display: block;
                width: 64px;
                height: 270px;
                background: white;
                opacity: 1;
                filter: blur(8px);
                transition: opacity .2s ease;
            }

            // if only one comment...
            &:has(.swiper-slide:only-child) {
                margin-left: 64px;
                margin-right: 64px;

                &:after {
                    display: none;
                }
            }

            // if .nextSlideFp is disabled
            body:has(.nextSlideFp[aria-disabled="true"]) & {
                &:after {
                    opacity: 0;
                }
            }

            .name_date {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: clamp(14px, 0.833vw, 16px);
                font-weight: 500;
                margin-bottom: .3em;

                // first name of the customer
                p:first-of-type {
                    &:first-letter {
                        text-transform: uppercase;
                    }

                    text-transform: lowercase;
                }
            }

            .nrc_comment {
                font-size: 12px;
                line-height: 1.4;
                letter-spacing: 0.05em;
                margin-top: 2em;

                &:first-letter {
                    text-transform: uppercase;
                }

                .nrc_comment_see_more {
                    display: inline-block;
                    cursor: pointer;
                    text-decoration: underline;

                    &::first-letter {
                        text-transform: uppercase;
                    }
                }

                .nrc_comment_full {
                    display: none;
                }
            }

            .nrc_comment_legal {
                position: absolute;
                bottom: 1em;
                width: 88%;
            }

            .nrc_comment_disclaimer,
            .nrc_comment_order_date {
                font-size: 11px;
                line-height: 1.5;
                position: absolute;

                &.nrc_comment_disclaimer {
                    bottom: calc(24px + 1.5em);
                }

                &.nrc_comment_order_date {
                    bottom: 24px;
                }
            }
        }

        .prevSlideFp,
        .nextSlideFp {
            position: absolute;
            height: 100%;
            width: 25px;
            top: 0;
            z-index: 10;
            background: transparent url(../svg/arrow_adv.svg) no-repeat right;
            background-size: 8px;
            cursor: pointer;
            opacity: 1;
            transition: opacity .2s ease;

            &::before {
                display: none;
            }

            &[aria-disabled="true"] {
                opacity: 0;
                pointer-events: none;
            }
        }

        .prevSlideFp {
            left: 35px;
            transform: scaleX(-1);
        }

        .nextSlideFp {
            right: 9px;
        }
    }

    .swiper-scrollbar {
        height: 3px;
        background-color: #F9F9F9;
        border-radius: 0;
        margin-left: 64px;
        margin-right: 64px;
        margin-top: 20px;
        transition: width .2s ease;

        .swiper-pagination-progressbar-fill {
            background-color: $grey3;
        }
    }

    .nrc_rate {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: .3em;
        font-size: 1.5em;
        color: #FCC740;
    }

    .star_empty,
    .star_full,
    .star_mid_full {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: .2em;
    }

    .star_empty {
        background: transparent url(../svg/empty_star.svg) no-repeat center;
        background-size: cover;
    }

    .star_full {
        background: transparent url(../svg/full_star.svg) no-repeat center;
        background-size: cover;
    }

    .star_mid_full {
        background: transparent url(../svg/half_star.svg) no-repeat center;
        background-size: cover;
    }
}