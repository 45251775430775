.product_page #pdvBox.modalbox .liste_recherche_distributeur .elem_list_contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 19px 0;
    width: 100%;
}

.helloCustomer {
    font-size: 15px;
    font-weight: 400;
    text-transform: initial;
}

strong.teamSign {
    color: $secondaryColor;
}

.product_page {
    #pdvBox.modalbox {
        &.showForm {
            max-width: 800px;
            padding: 2rem 0rem;

            .w-contact-form {
                padding-bottom: 0;

                .w-input {
                    .w-input-element {
                        &.inputErr {
                            border: 1px solid $errorRed;
                        }
                    }
                }
            }
        }
    }

    #content_pdvBox_new {
        width: 90%;
        margin: 0 auto;
        height: 100%;
        padding-right: 15px;
        display: block;

        .popup_title {
            padding: 0;
            width: 100%;
        }

        #message {
            font-size: 15px;
            line-height: 1.2;
            color: #252525;
            margin: 0 0 1rem;

            span#nom_magasin {
                text-transform: uppercase;
            }

            #nom_product {
                font-weight: 200;
            }

            #nom_magasin,
            #nom_product {
                color: $secondaryColor;
            }
        }

        .formulaire {

            .intl-tel-input {
                margin-bottom: .5%;

                .selected-flag {
                    position: relative;
                    padding-left: 10px;
                    width: 48px;

                    .iti-arrow {
                        right: 3px;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-top: 6px solid #555;
                    }
                }

                &.allow-dropdown {
                    input[type=tel] {
                        padding-left: 65px;
                    }
                }
            }

            .w-text-input {
                margin-bottom: .8rem;

                ::placeholder {
                    text-transform: initial;
                    font-style: initial !important;
                    font-size: 14px !important;
                }
            }

            textarea.w-input-element {
                margin-bottom: 8px;
                color: #000;
                height: 220px;
            }

            .w-input-element:not([type=radio]):not([type=checkbox]),
            input[type=email].w-input-element,
            input[type=password].w-input-element,
            input[type=text].w-input-element,
            textarea.w-input-element {
                border-radius: 0 !important;
                text-transform: none;
            }

            .container_optin_offre {
                margin: .5rem 0 1.5rem;
                font-size: 13px;

                .choose {
                    display: flex;
                    margin-top: .5rem;

                    #inputoptin_n,
                    #inputoptin_partners_n,
                    #inputcontact_by:nth-of-type(2) {
                        margin-left: 1rem;
                    }

                    label {
                        line-height: 1.7;
                    }

                    &.contact_bloc {
                        margin: 1rem 0;
                    }

                    .w-input-label {
                        color: $grey3;
                    }
                }

                .input_tel_wording {
                    font-size: 13px;
                }

                .optin_offre {
                    margin-top: .5rem;
                }
            }

            .radio {
                background-image: none;
                width: 18px;
                height: 18px;
                border: 1px solid #b1b1b1;
                border-radius: 50%;
                padding: 0;
                display: inline-block;
                position: relative;

                &:after {
                    content: "";
                    width: 14px;
                    height: 14px;
                    background-color: $secondaryColor;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    border-radius: 50%;
                    margin: auto;
                    opacity: 0;
                    transition: opacity .3s ease-in-out;
                }

                &.selected {
                    &:after {
                        opacity: 1;
                    }
                }
            }

            .info_site_choice {
                margin-top: 10px;
                margin-bottom: 20px;
            }

            .wrapper_optin {
                &:after {
                    content: "";
                    display: table;
                    clear: both;
                }

                label {
                    font-size: 14px;
                    line-height: 1;
                    margin-left: 5px;
                    margin-right: 25px;
                }
            }
        }

        .mentions {
            margin-top: 1.5rem;

            p {
                color: $mediundarkgray1;
                font-size: 12px;

                &+p {
                    padding-top: .5rem;
                }
            }

            a {
                text-decoration: underline;
            }
        }

        input {
            &::placeholder {
                visibility: hidden;
            }
        }
    }

}

#content_pdvBox .liste_recherche_distributeur {
    .nom_distributeur {
        color: $blue !important;
        margin-bottom: 1px !important;
    }

    .infosMag {
        width: 40%;

        .rue_distributeur,
        .ville_distributeur,
        .pays_distributeur,
        .num_telephone {
            text-transform: lowercase;
            font-size: 12px;
            line-height: 1.2;
            color: $black;

            &:first-letter {
                text-transform: uppercase;
            }
        }

        .horaire_today {
            font-size: 12px;

            .store_open,
            .store_close {
                margin-left: 0;
                text-align: left;
            }
        }
    }

    .resaMag {
        width: auto;
        padding: 0 1rem;
        min-width: 189px;

        .dispoInShop {
            width: 100%;
            display: flex;
            align-items: center;

            .inShop {
                width: 18px;
                height: 12px;
                background: transparent url(../img/mail.png) no-repeat bottom;
                background-size: contain;
            }

            .availableTxt {
                width: auto;
                font-size: 13px;
                color: #414243;
                border-bottom: 1px solid $tableGrey;
                margin: 0 0 0 0.7rem;
                display: inline-block;
            }
        }
    }
}

body.store_locator {
    .notSL {
        display: none !important;
    }
}

body.product_page {
    .notFP {
        display: none !important;
    }
}

/***** Product stock infos msg *****/
.elem_liste_distributeur .stock_status {
    font-family: $primaryFont;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: $black;
    width: 30%;

    &.in_stock,
    &.to_be_confirmed_stock,
    &.can_be_ordered_stock {
        color: $black;
    }

    &.limited_stock {
        color: $orange;
    }

    &.in_stock {
        font-weight: 600;
        color: $validGreen;
    }

    &.not_in_stock {
        font-weight: 600;
        color: $errorRed;
    }

    &.unknow_stock {
        display: none;
    }
}