/******************************************************************************/
/* lightbox */
/******************************************************************************/
.firefox {
    .lightbox.sidebox * {
        scrollbar-color: $primaryColor $grey16;
        scrollbar-width: thin;
    }
}
.lightbox {
    display: none;
    position: fixed;
    top: 15vh;
    right: 0;
    left: 0;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
    transform: translateZ(0);
    width: 50vw;
    background-color: $white;
    padding: 45px;
    margin: 0 auto;
    z-index: 110;

    &.actif {
        display: block;
    }

    &.sidebox * {
        box-sizing: border-box;

        /* Scrollbar webkit */
        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background-color: $grey16;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $primaryColor;

            &:hover {
                background-color: $primaryColor;
            }
        }

        &::-moz-scrollbar-button,
        &::-webkit-scrollbar-button {
            width: 0px;
        }

        &::-moz-scrollbar-button:decrement,
        &::-moz-scrollbar-button:increment,
        &::-webkit-scrollbar-button:decrement,
        &::-webkit-scrollbar-button:increment {
            width: 0px;
        }
    }

    &.sidebox,
    &#pdvBox,
    &#sendwishlistbox,
    &.sidebox#selection-offer {
        display: block;
        position: fixed;
        top: 0;
        right: -625px;
        bottom: 0;
        left: unset;
        width: 625px;
        padding: 30px;
        box-sizing: border-box;
        transition: right 0.3s ease-in-out;
        box-shadow: none;
        z-index: 9999;

        &.actif,
        &.deployed {
            right: 0;
        }

        .popup_title {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $primaryFont;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
            letter-spacing: normal;
            color: $grey3;
            border: none;
            height: auto;
            padding-right: 45px;
            margin-bottom: 30px;
        }

        .close_pop,
        .btn_fermer {
            position: absolute;
            top: 20px;
            right: 27px;
            height: 16px;
            width: 16px;
            font-size: 0;
            cursor: pointer;
            background: url(../svg/lightbox_close.svg) center no-repeat;

            &::before {
                display: none;
            }
        }
    }

    &#pdvBox {
        #content_pdvBox {
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .store_locator {
                position: relative;
                flex-grow: 1;

                .wrapper_retailers_map {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: unset;
                    padding-right: 30px;
                }
            }

            .storeloc_form {
                width: 100%;
                height: 49px;
                margin-bottom: 10px
            }
        }
    }

    &#alertboxlogin {
        background: $white;
        width: 496px;
        height: 100vh;
        top: 0;
        bottom: 0;
        right: -496px;
        transition: right .3s ease-in-out;
        margin: auto;
        position: fixed;
        z-index: 10000;

        &.active {
            right: 0;
        }

        .txt_alert {
            font-size: 20px;
            font-weight: 500;
            text-transform: uppercase;
            color: $grey3;
            text-align: center;
            margin-bottom: 20px;

            span {
                display: block;
                font-size: 13px;
                font-weight: 400;
                padding-top: 15px;
                text-transform: none;
            }
        }

        .wrapper_btn {
            text-align: center;
            box-sizing: border-box;

            .alert_btn {
                width: 244px;
                height: 50px;
                font-size: 17px;
                font-weight: 400;
                text-transform: uppercase;
                text-align: center;
                line-height: 50px;
                color: #fff;
                background: $grey3;
                display: inline-block;
                text-decoration: none;
                box-sizing: border-box;
                cursor: pointer;
            }
        }
    }

    &#sendwishlistbox.sidebox {
        margin-left: 0;

        &.send_wishlist_success {
            width: 451px;
            transition: width right .3s ease-in-out;
        }

        .popup_title {
            flex-direction: column;
            text-indent: 0px;
            margin-bottom: 20px;

            .title {
                margin-bottom: 10px;
            }
        }

        #sendwishlist {
            width: 100%;

            // Hide form mentions on success
            &.send_wishlist_success {

                .subtitle,
                .w-form-line.w-submit {
                    display: none;
                }
            }

            &.send_wishlist_success,
            &.send_wishlist_error {
                &:has(+.mentions) {
                    &+.mentions {
                        display: none;
                    }
                }

                #sendfriend_alert_mail {
                    font-family: $primaryFont;
                }

                .popup_title {
                    padding-right: 0;

                    .title {
                        margin: 0;
                    }
                }
            }

            &.send_wishlist_error {
                .w-form-line.w-submit {
                    margin: 0 auto;
                }
            }
        }

        .w-input-note {
            font-family: $primaryFont;
            color: $grey3;
            position: static;

            &.w-input-error {
                color: $errorRed;
            }
        }

        #sendfriend_alert_mail {
            &.success {
                position: relative;
                padding-top: 35px;

                &::before {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    content: "";
                    display: block;
                    width: 25px;
                    height: 25px;
                    background-image: url(../svg/confirmation.svg);
                }

                // Hide customer adress sent on success (override app behavior)
                .sendfriend_alert_mail_msg {
                    font-weight: 400 !important;
                    font-size: 13px !important;
                    font-family: $primaryFont !important;
                    color: $grey3 !important;
                    margin: 0 !important;

                    &+p {
                        display: none;
                    }
                }
            }
        }

        .newsletter_optin_line {
            margin-bottom: 20px;
        }

    }

    &.sidebox#selection-offer {
        height: 100vh;

        .selection-offer-header p span#nb_prod_selection_offerte {
            &.highlight {
                color: $primaryColor;
            }
        }

        .selection-offer-title {
            color: $grey3;
            font-size: 20px;
            font-weight: $medium;
            text-transform: uppercase;
            margin: 0 auto 10px;
        }

        .selection-offer-content {
            overflow: hidden;
            width: 100%;
            margin-top: 0;
            padding-top: clamp(20px, fs-vw(30, 428), 30px);
            box-sizing: border-box;
            max-height: unset;
        }

        #wrap_lion {
            width: 100% !important;
            flex-direction: column;
            flex-wrap: nowrap;
            max-height: 63vh;
            padding-right: 3px;
            overflow-x: hidden;
            overflow-y: auto;
            padding-top: 10px;

            @media screen and (max-height: 630px) {
                max-height: 58vh;
            }

            .js-form-selection-offer {
                display: flex;
                align-items: center;
                margin-left: 15px;
                min-height: unset;
                width: 97%;
                padding: 10px 10px 10px 15px;

                &:before {
                    left: 0;
                    top: 50%;
                    border-radius: 0;
                    transform: translate(-50%, -50%);
                }

                img.visuOfferedProd {
                    width: 110px;
                    height: 110px;
                    object-fit: cover;
                }

                p.titleOfferedProd {
                    margin-left: .938rem;
                }
            }
        }

        .priceOfferedProd {
            display: flex;
            align-items: flex-end;

            .crossedPrice {
                display: flex;
                margin-left: 5px;

                sup {
                    order: 3;
                    vertical-align: baseline;
                    font-size: inherit;
                }
            }
        }

        .priceOfferedProd,
        .color_list,
        .wrapper_product_color,
        .wrapper_product_size {
            font-size: 13px;
        }

        .priceOfferedProd,
        .wrapper_product_size {
            margin: 0 0 .625rem .938rem;
        }

        .wrapper_product_color {
            margin: 0 0 0 .938rem;
        }

        .selec_offer_label {
            font-weight: 600;
        }

        .chosen_color {
            font-weight: 400;
        }

        #wrap_lion {
            .color_list { // selector used to override app style
                flex-direction: column;
                align-items: unset;
                justify-content: unset;
                height: unset;
            }

            .js-form-selection-offer .color_list .swiper-wrapper .form_itm.color {
                width: 50px;
                padding-bottom: 5px;

                &:hover {
                    border-color: inherit;
                    box-shadow: unset;

                    input[type=radio]+label {
                        &::after {
                            opacity: .6;
                        }
                    }
                }


                input[type=radio] {
                    &:checked+label {
                        box-shadow: unset;

                        &::after {
                            opacity: 1;
                        }
                    }

                    &+label {
                        position: relative;
                        width: 50px;
                        height: 50px;
                        border-radius: 0;
                        border: unset;
                        box-shadow: unset;

                        &::after {
                            position: absolute;
                            bottom: -5px;
                            content: "";
                            width: 100%;
                            height: 2px;
                            opacity: 0;
                            background-color: $cmsPageTitle;
                        }
                    }
                }
            }
        }

        .selec_offer_free_total {
            position: absolute;
            bottom: 10px;
            right: 10px;
            font-size: clamp(11px, fs-vw(13, 1920), 13px);
            text-transform: uppercase;
            font-weight: 600;
        }
    }

    @media screen and (max-width: 1280px) {
        padding: 20px;
    }

    .close_pop {
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 0;
        cursor: pointer;
        &:before {
            content: '';
            width: 16px;
            height: 16px;
            position: absolute;
            top: 17px;
            right: 19px;
            background: url("../img/wishlist_btn.png") no-repeat center;
            margin: 0;
            z-index: 3;
        }
    }

    .popup_title {
        text-align: center;
        font-size: 1.85rem;
    }

    .txt_alert {
        margin-bottom: 0.75em;
        font-weight: 400;
        color: #53514F;
    }

    &#zoomBox {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        backface-visibility: hidden;
        max-width: 1081px;
        width: 90%;
        max-height: 100vh;
        max-height: 100dvh;
        background-color: $white;
        padding: 30px 0;

        #zoom_box_wrapper {
            display: flex;
        }

        .zoomMainVisu_container {
            position: relative;
            max-width: 760px;
            padding: 0 58px;
            box-sizing: content-box;

            img {
                width: 100%;
                height: auto;
            }
        }


        .zoomProdPrev,
        .zoomProdNext {
            position: absolute;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 58px;
            height: 100%;
            font-size: 0;
            background-color: $white;
            z-index: 2;
            transition: opacity .3s ease;
            cursor: pointer;


            &.swiper-button-disabled {
                opacity: 0;
            }

            &::after {
                content: "";
                display: block;
                width: 30px;
                height: 30px;
                background-image: url('../svg/arrow.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
        }

        .zoomProdPrev {
            left: 0;

            &::after {
                transform: rotate(90deg);
            }
        }
        .zoomProdNext {
            right: 0;

            &::after {
                transform: rotate(-90deg);
            }
        }

        .thumbsZoomContainer {
            position: relative;
            max-width: 180px;
            width: 16.7%;
            padding-left: 25px;
        }

        .zoomThumbs {
            &:not(.swiper-container-initialized) {
                .zoomThumbsNext,
                .zoomThumbsPrev {
                    opacity: 0;
                }
            }

            .swiper-slide {
                height: auto;
            }

            .swiper-slide-thumb-active {
                img {
                    cursor: pointer;
                    border: 1px solid $primaryColor;
                }
            }

            img {
                cursor: pointer;
                border: 1px solid $grey16;
            }
        }

        .zoomThumbsNext,
        .zoomThumbsPrev {
            position: absolute;
            left: 0;
            display: flex;
            justify-content: center;
            width: 100%;
            height: 28px;
            font-size: 0;
            background-color: $white;
            z-index: 2;
            transition: opacity .3s ease;
            cursor: pointer;

            &.swiper-button-disabled {
                opacity: 0;
                z-index: -1;
            }

            &::after {
                content: "";
                display: block;
                width: 18px;
                height: 18px;
                background-image: url('../svg/arrow.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                margin-left: 15px;
            }
        }

        .zoomThumbsNext {
            bottom: 0;
        }
        .zoomThumbsPrev {
            top: 0;

            &::after {
                transform: rotate(180deg);
            }
        }

        .zoom_disclaimer {
            position: absolute;
            top: 102%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 11px;
        }
    }

    &#abox {
        top: 50%;
        transform: translateY(-50%);
        width: 400px;
        z-index: 501;

        .box_wishlist {
            width: 400px;
            font-size: 1em;

            .light_title {
                margin-bottom: 15px;
                text-align: center;
                font-size: 1.4em;
            }

            p {
                text-align: center;
                font-size: 0.8em;
            }

            .wrapper_btn {
                padding-top: 30px;
                text-align: center;

                .button {
                    width: 30%;
                    margin: 0 5%;
                }
            }
        }
    }

    &.add_to_cart {
        padding: 0;
        width: 650px;

        .popup_title {
            position: absolute;
            top: 72px;
            left: 50%;
            transform: translateX(-50%);
        }

        .modal_contents {
            display: flex;
            align-items: stretch;

            .modal_itm_visu {
                width: 270px;
                background-color: #EFEFED;
                padding: 70px 20px 60px 50px;

                img {
                    vertical-align: top;
                    width: 100%;
                    height: auto;
                    margin-bottom: 30px;
                }
            }

            .modal_itm_info {
                display: flex;
                flex-direction: column;
                width: 270px;
                padding: 155px 50px 60px 20px;

                .add_to_cart_name {
                    padding: 0 0 0 50px;
                    font-size: 1.285rem;
                    color: $txtBlack;
                }

                .itm_infos {
                    padding: 0 80px 0 50px;
                    margin-bottom: auto;

                    .add_to_cart_price {
                        margin: 16px 0;
                        font-size: 1.47285rem;
                        color: #828282;
                    }

                    .add_to_cart_color,
                    .add_to_cart_size {
                        line-height: 1.1335;
                        font-size: 1.10285rem;
                        color: $txtBlack;
                    }
                }
            }
        }
    }

    &#sendfriendbox {
        width: 520px;

        .msg_poperror {
            text-align: center;
            margin: 15px auto 10px;
            color: $txtBlack;
        }
    }

    &#sendfriendbox,
    &#sendwishlistbox {
        form {
            width: 520px;

            .popup_title {
                margin-bottom: 40px;
            }

            .inputFormSite {
                appearance: none;
                width: 100%;
                height: 100%;
                resize: none;
            }

            .plusieur_add {
                margin: 3px;
                color: $txtBlack;
            }

            input[type=checkbox] {
                display: none;

                &+label {
                    color: $txtBlack;
                    cursor: pointer;
                    user-select: none;

                    span {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    &:before {
                        display: inline-block;
                        vertical-align: middle;
                        width: 14px;
                        height: 14px;
                        background-color: rgba($txtBlack, 0);
                        background-clip: content-box;
                        padding: 6px;
                        border: 1px solid #E4E4E4;
                        box-sizing: border-box;
                        transition-property: background-color, padding;
                        transition-duration: 0.1s;
                        transition-timing-function: ease-out;
                        content: '';
                    }
                }

                &:checked+label:before {
                    background-color: rgba($txtBlack, 1);
                    padding: 1px;
                }
            }

            .form_submit {
                position: relative;
                width: 265px;
                margin: 20px auto 0;
            }
        }

        .sendfriend_success {
            opacity: 0;
            line-height: 2.35;
            font-size: 1.285rem;
            color: $green;
            padding: 0 2.5em;
            text-align: center;

            &.appear {
                opacity: 1;
            }
        }
    }

    &#lightbox_achat_express {
        padding: 0;
        width: 800px;

        .close_pop {
            z-index: 10;
        }

        .prod_top_wrapper {
            padding: 0;
            align-items: center;
        }

        .product_main_wrapper {
            .main_vis {
                background-color: #efefed;
                width: 50%;
                height: 485px;
                margin: 0 10px;
                padding: 40px 20px;
                box-sizing: border-box;

                .swiper-slide {
                    background-position: center;
                    background-size: cover;
                    width: 405px;
                    height: 405px;

                    img {
                        display: none;
                    }
                }
            }

            .product_info_wrapper {
                width: 50%;
                margin: 0;
                height: 485px;
                background: $white;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                #stock_no_ok {
                    font-size: 18px;
                    text-align: center;
                }

                .product_info .item_price_wrapper {
                    margin-bottom: 0;
                }
            }

            .product_info {
                padding: 0;
                margin: 0;

                .item_name {
                    transform: none;

                    span {
                        font-size: 1.6rem;
                        margin: 5px 0;
                    }

                    &:after {
                        display: none;
                    }

                    small {
                        font-size: 1rem;
                    }
                }

                .item_price {
                    margin: 35px auto 30px;
                }
            }
        }
    }

    &#pdvBox,
    &#pdvStoreBox {

        &.thanks {
            width: 451px;
        }

        .popup_title,
        .contact_store_title {
            font-weight: $medium;
            font-size: 20px;
            line-height: 30px;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 30px;
        }

        .contact_mail_sent {
            display: block;
            text-align: center;
            font-size: 13px;

            &.message_top {
                position: relative;
                padding-top: 35px;

                &::before {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    content: "";
                    display: block;
                    width: 25px;
                    height: 25px;
                    background-image: url(../svg/confirmation.svg);
                }
            }
        }

        textarea {
            padding: 1.5em .85714em 1em;

            &#clientMessage {
                min-height: fs-vw(220, 1920);

                &+.placeholderTxt {
                    top: 2em;

                    &.inUse {
                        top: 1.2em;
                    }
                }
            }
        }

        .placeholderTxt {
            position: absolute;
            top: 50%;
            left: 1.2em;
            transform: translateY(-50%);
            transition: all .3s ease-in-out;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: $grey87;

            &.inUse {
                font-size: 12px;
                top: 30%;
                font-size: 10px;
            }
        }

        .intl-tel-input {
            &+.placeholderTxt {
                left: 12%;
            }
        }


        .w-contact-form .btn_container {
            width: 244px;
            height: 50px;
            background-color: $grey3;
            text-transform: uppercase;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin: 20px auto 30px;
            transition: background-color .1s ease;

            &:hover {
                background-color: rgba($primaryColor, .7);
            }

            .btn_form_contact {
                background-color: transparent;
                font-family: $primaryFont;
                font-size: 17px;
                font-weight: 400;
                text-transform: uppercase;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                color: $white;
            }

            .loader {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                z-index: -1;
                background-color: $primaryColor;
                background-image: url('../svg/three_dots.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: 12%;

                &.loading {
                    opacity: 1;
                    z-index: 2;
                }

                span {
                    display: none;
                    font-size: 0;
                }
            }
        }

    }

    &#pdvBox {
        input[type="text"]:not(#search_cp),
        input[type="email"],
        input[type="tel"] {
            padding-top: 1rem;
        }

        .steps {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: stretch;
            height: 165px;

            .num_step {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
                line-height: 1;
                font-size: 1.2rem;
                color: #8b8b8b;

                span {
                    margin-bottom: 7px;
                }

                &:before {
                    display: block;
                    margin-bottom: 5px;
                    font-size: 2.65em;
                    color: #474747;
                    content: attr(data-step);
                }

                &:after {
                    display: block;
                    width: 1px;
                    height: 7px;
                    content: '';
                }

                &.actif {
                    color: #221E1F;

                    &:after {
                        background-color: $black;
                    }
                }
            }
        }

        .step {
            display: none;
            min-width: 624px;

            .infos {
                .map {
                    width: 624px;
                    height: 255px;
                }

                .form_submit {
                    position: relative;
                    float: right;

                    .button.secondary.dark {
                        width: auto;
                        line-height: 2.35;
                        font-size: 1.285rem;
                        color: $black;
                        padding: 0 2.5em;
                    }

                    .button.loader {
                        line-height: 2.35;
                        background-color: $white;

                        &:before {
                            background-image: url("../svg/loader_grey.svg");
                        }
                    }
                }
            }

            &#step1 {
                display: block;

                .infos {
                    display: flex;

                    .bookinstore_container_img {
                        width: 200px;
                        margin-right: 60px;

                        img {
                            vertical-align: top;
                            width: 100%;
                            height: auto;
                        }
                    }

                    .product_name {
                        font-size: 1.21285rem;
                        color: #221E1F;
                    }

                    .price {
                        font-size: 1.7rem;
                        color: #221E1F;
                    }

                    .form_submit {
                        margin-top: 105px;
                    }
                }
            }

            &#step2 .form_submit {
                margin-top: 20px;
            }

            &#step3 {
                .infos {
                    display: flex;

                    .bookinstore_container_img {
                        width: 200px;
                        margin-right: 90px;
                    }

                    .bookinstore_form_client {
                        width: 305px;

                        #form_infos_bookinstore {
                            margin-bottom: 30px;
                        }

                        .input_container {
                            margin-bottom: 17px;
                        }

                        .mandatory_notice {
                            margin-bottom: 23px;
                            font-style: italic;
                            font-size: 0.95rem;
                            color: #757575;
                        }

                        .nb {
                            line-height: 1.6;
                            margin-bottom: 12px;
                            font-size: 1.05rem;
                            color: $black;
                        }
                    }
                }
            }
        }

        textarea {
            font-family: $primaryFont;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: $grey3;
        }
    }

    &#pdvStoreBox {

        *{
            font-family: $primaryFont;
        }

        #content_pdvBox_new {

            .formulaire {
                max-height: 87vh;
                overflow: auto;
                padding-right: 15px;
            }
        }

        .w-contact-form {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
            padding-bottom: 0;

            .w-text-input {
                margin-bottom: 15px;
            }

            .w-input-element{
                border-color: $grey3;
                border-radius: 0;
            }


            textarea {
                font-family: $primaryFont;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                color: $grey3;
            }

            input[type="text"],
            input[type="email"],
            input[type="tel"] {
                padding-top: 1rem;
            }

            .intl-tel-input {

                .selected-flag{
                    position: relative;
                    padding-left: 10px;

                    &:after{
                        content:'';
                        border-right: 1px solid #595959;
                        width: 1px;
                        height: 32px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: -4px;

                    }
                }
            }

            .container_optin_offre {
                margin-top: 20px;

                p {
                    font-size: 13px;
                    color: $grey3;
                }

                .choose {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    .radio{ display: none; }
                }
            }

            .intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type="tel"] {
                padding-left: 5em;
            }

            .input_container{
                width: 100%;
                display: block;
            }
        }

        .mentions {
            font-size: 12px;
            line-height: 1.5;

            a {
                color: $primaryColor;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }

            .champ_obli {
                margin-bottom: 1rem;
            }
        }
    }

    &#exit_overlay_OVI {
        width: 816px;
        height: 574px;
        position: fixed;
        display: flex;
        padding: 0;
        z-index: 511;

        .block_text {
            margin: 3rem 2rem 2.3rem;
            width: 44%;
        }

        .block_image {
            width: 50%;

            img {
                min-width: 100%;
                height: 100%;
            }
        }

        .top {
            position: absolute;
            top: 10px;
            right: 10px;

            .close {
                position: absolute;
                top: 0;
                right: 0;

                &::before {
                    content: url("../img/close.png");
                    width: auto;
                    height: auto;
                    cursor: pointer;
                }
            }
        }

        .pop_contents {
            text-align: center;

            .pop_title {

                padding-bottom: 35px;
                font-size: 1.71em;
                color: $green;
                text-transform: uppercase;
            }

            .pop_contenu {
                margin: 0 1rem 1.5rem;

                h3,
                .h3Title {

                    font-size: 1.125rem;
                    text-transform: uppercase;
                }

                span {

                    color: $textGrey;
                    font-size: 1.125rem;
                }
            }

            ul {
                margin: 1.5rem .1rem 1.5rem 1.9rem;
                list-style: none;
                text-align: justify;

                li {
                    font-size: 0.9375rem;
                    line-height: 1.3em;
                    color: $textGrey;
                    padding-bottom: 1rem;
                    text-align: left;

                    &::before {
                        content: "•";
                        color: $green;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                        font-size: 1.5rem;
                    }

                    strong {
                        font-weight: bolder;
                    }

                    span {
                        font-size: inherit;
                    }
                }
            }

            form {
                position: relative;

                .input_container {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    input[type="email"] {
                        font-size: .9375rem;
                        border: 1px solid #ccc;
                        border-radius: 0;
                        box-sizing: border-box;
                        font-size: .9375rem;
                        background-color: #fff;
                        width: calc(100% - 3em);

                        &::placeholder {
                            font-style: initial;
                            text-indent: 1em;
                            color: #bbbbbb;
                        }
                    }

                    .btn_container {
                        width: 3em;
                        height: 3em;

                        button {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    p.error {
                        width: 100%;
                        text-align: left;
                        color: red;
                        font-size: 12px;
                        margin: 0.5rem 0 0 1rem;
                    }
                }

                .w-form-line-container {

                    .w-form-line {
                        &:nth-of-type(1) {
                            margin: 0 auto 0 1.5rem;
                            width: 17rem;
                            height: 36px;

                            .w-input-container {
                                height: 100%;

                                input {
                                    border: 1px solid $fadeGrey;
                                    border-radius: 0;
                                    max-height: 36px;
                                    font-size: 0.9375rem;
                                }
                            }
                        }

                        p.w-input-error {
                            margin-left: 1.5rem;
                        }
                    }



                    .error {
                        color: $errorRed;
                    }

                    .w-submit {
                        position: absolute;
                        width: 38px;
                        height: 36px;
                        top: -.05rem;
                        right: 1rem;

                        button {
                            height: 100%;

                            span {
                                display: block;
                                font-size: .8rem;
                                font-weight: bolder;
                                margin-top: -.06rem;
                            }
                        }

                        .w-loader {
                            span {
                                display: none;
                            }
                        }
                    }

                }

                .pop_txt_listing {
                    padding-top: 0;
                    position: unset;
                    padding-left: 0;

                }

                .unsubscribe {
                    text-decoration: underline;
                }


            }
        }

        #conf_inscription_news {
            margin-top: 8rem;
            background-color: unset;
            text-align: left;
            padding: 0;
            width: 100%;

            strong {
                font-weight: bolder;
            }

            .main_msg {
                text-transform: uppercase;

                padding-bottom: 25px;
                font-size: 1.75rem;
                color: #cedb16;
            }

            .overlay_msg_attention {

                font-size: 0.9375rem;
            }

            .w-submit {
                height: 30px;
                width: 100%;
                margin: 3rem 0;

                button {
                    background-color: transparent;
                    border: none;
                }

                span.w-submit-button {
                    display: block;
                    overflow: hidden;

                    span {

                        font-size: 1.125rem;
                        display: block;
                        margin-left: -2rem;
                        margin-top: -.1rem;
                        text-transform: none;
                    }
                }
            }
        }
    }

    &#popup_numero_retour {
        width: 20%;

        #num_retourDIV {
            margin: 1rem 0;
        }

        #liste_motif_retourDiv {
            margin-top: 1rem;
        }
    }

    &#desc_pic_zoom {
        padding: clamp(20px, fs-vw(25, 1920), 25px);
        top: 50%;
        left: 50%;
        bottom: unset;
        right: unset;
        margin: 0;
        transform: translate(-50%, -50%);

        img {
            max-height: 86vh;
        }

        .zoom_disclaimer {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 11px;
        }
    }
}

.js-closeLightboxNewsletter {
    display: none;
}

#modbox_error {
    text-align: center;
    font-weight: 400;
    font-size: 1.4rem;
    color: #53514F;

    .light_title {
        margin-bottom: 15px;
        text-align: center;
        font-size: 1.4em;
    }

    #message_warning {
        margin-bottom: 0.75em;
        font-size: 0.8em;
    }
}

.shad {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .45);
    overflow-y: auto;

    &.actif {
        display: block;
    }
}

#shad_abox {
    z-index: 115;
}

#shad_link {
    display: block !important;
    width: 70%;
    opacity: 0;
    z-index: -10;
    transition: opacity 0.2s linear, z-index 0s linear 0.2s;

    &.actif {
        opacity: 0.45;
        z-index: 10;
        transition: opacity 0.2s linear 0.35s, z-index 0.001s linear;
    }

    @media screen and (max-width: 1280px) {
        width: 55%;
    }
}

#shad_menu {
    display: block !important;
    opacity: 0;
    z-index: -10;
    margin-top: 115px;
    transition: margin-top 0.35s ease-out, opacity 0.35s linear, z-index 0s linear 0.35s;

    &.sticky {
        margin-top: 120px;
    }

    &.actif {
        opacity: 0.45;
        z-index: 5;
        transition: margin-top 0.35s ease-out 0.2s, opacity 0.35s linear 0.2s, z-index 0s linear;
        cursor: pointer;
    }
}

body.search_in {
    #shad_menu.search_tophome{
        opacity: 0.45;
        z-index: 5;
        transition: margin-top 0.35s ease-out 0.2s, opacity 0.35s linear 0.2s, z-index 0s linear;
    }
}

#delay_popup {
    width: 655px !important;

    .ctn_wrap {
        position: relative;
        height: 100%;
        width: 100%;

        .btn_retour {
            position: absolute;
            top: -26px;
            right: -28px;
            background: url(../img/close_btn.jpg);
            width: 22px;
            height: 22px;
            padding: 0;

            @media all and (max-width: 1280px) {
                top: -14px;
                right: -14px;
            }
        }
    }
}

.site_3 .lightbox#exit_overlay_OVI .pop_contents form {
    .input_container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
    }

    .btn_container {
        margin-left: 0.2rem;

        button {
            color: #fff;
            background-color: #cedb16;
            border-color: #fff;
            border-radius: 2px;
        }
    }
}

#cookiesLightbox {
    z-index: 512;

    ul {
        li {
            cursor: default;
            &#cookiePref01 {
                display: none;
            }

            &:first-child {
                .h4style {
                    color: #a9a9a9;
                }
            }

            .h4style {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-weight: 700;
                text-transform: uppercase;
                color: #000;
            }
        }
    }

    #texte_question_2 {
        p {
            display: none;
        }
        ul p {
            display: block;
        }
        span {
            text-decoration: underline;
        }
    }

    .h3style {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    .w-checkbox-input {
        width: fit-content;
    }

    .w-submit-button {
        width: 100%;
        text-indent: 0;

        span {
            font-weight: 600;
            letter-spacing: .5px;
        }
    }

    #js_cookie_refuse {
        color: black;

        &:not(:hover),
        &+.w-loader {
            background-color: #fff;
        }

        &:hover {
            background-color: #cedb16;
            color: #fff;
        }

        &+.w-loader span:before {
            background-color: #cedb16;
        }
    }

    #js_cookie_accept {

        &:not(:hover),
        &+.w-loader {
            background-color: #cedb16;
        }

        &:hover {
            background-color: #fff;
            color: black;
        }

        &+.w-loader span:before {
            background-color: #fff;
        }
    }

    #js_cookie_refuse,
    #js_cookie_accept {
        border: 2px solid #cedb16;


        &+.w-loader {
            background-image: unset;
            border: 2px solid #cedb16;

            span {
                display: inline-grid;
                font-size: 0;
                margin-top: 1rem;

                &:before {
                    content: "";
                    mask-image: url(../svg/three_dots.svg);
                    mask-repeat: no-repeat;
                    mask-size: 75%;
                    width: 3.5rem;
                    height: 1rem;
                    mask-position: center;
                }
            }
        }
    }
}

body.fr {
    #cookiesLightbox li#cookiePref01 {
        display: none;
    }

    #texte_question_2 span {
        text-decoration: underline;
    }
}

#lightbox_achat_express {
    #visual_scroller_wrapper .fp_img_content {
        iframe {
            width: 100% !important;
        }
    }
}
#shad {
    &.forfilter {
        z-index: 25;
    }
}

#nrc_comment.lightbox {
    .nrc_content_comment {
        .name_date,
        .nrc_comment,
        .nrc_comment_disclaimer,
        .nrc_comment_order_date {
            line-height: 20px;
        }

        .nrc_comment {
            margin-bottom: 2rem;
        }

        .name_date {
            font-weight: bold;
        }

        .nrc_rate {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 12px 0.2em 12px 0;
            font-size: 1.5em;
        }
    }

    .close {
        width: 24px;
        height: 24px;
        appearance: none;
        background: transparent;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        position: absolute;
        top: 2rem;
        right: 1.5rem;
        font-size: 3rem;
        font-weight: 400;
        line-height: 24px;
        justify-content: center;
        cursor: pointer;

        span {
            display: block;
            height: fit-content;
            width: min-content;
            line-height: inherit;
            text-indent: 0;
        }
    }
}