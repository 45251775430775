$headerHeight : 135px;
$headerHeight_category : 190px;


.backToTopAlgolia {
    display: none;
}

#algolia_shade {
    z-index: -1;
    display: block;
    height: 0;
    opacity: 0;
    background-color: #000;
    transition: opacity .3s ease-in-out;
    cursor: pointer;

    &.displayed {
        z-index: 19;
        height: 100%;
        opacity: .3;
    }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

body.algolia_displayed {
    #site_global_wrap,
    #copyright {
        display: none;
    }

    &:has(#mention_legales_pop_up[style="display: block; z-index: 10000;"]) {
        .algolia_footer {
            #mention_legales_pop_up {
                display: block!important;
                z-index: 10000;
                top: 10%;
            }
        }

        #shad {
            display: none!important;
        }

        &:has(#shad[style="opacity: 0.6; display: block; cursor: pointer;"]) {
            .algolia_footer {
                & + #algolia_shade {
                    z-index: 19;
                    height: 100%;
                    opacity: .3;
                }
            }
        }
    }
}

.category .ais-InstantSearch #filter_sticky_search,
.category .ais-InstantSearch {
    top: $headerHeight_category;
}
.category .ais-InstantSearch #filter_sticky_search {
    height: calc(100vh - #{$headerHeight_category});
}


.aa-Panel {
    z-index: 150;
    position: fixed !important;
    top: 92px !important;
    border-radius: 0 0 10px 10px !important;
    border-top: 1px solid #F2F2F0;
    margin: 0 !important;
    background-color: $white;

    .aa-ItemIcon,
    .aa-ItemActions {
        display: none;
    }

    .aa-List {
        width: 100%;
    }

    .aa-Panel--scrollable {
        padding: 22px 0;
    }

    .aa-Item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: unset;
        font-size: 13px;
        font-weight: 700;
        line-height: 20px;
        color: $black;
        box-sizing: border-box;

        &[aria-selected="true"] {
            background-color: $lightGreyBorder;
        }

        &[aria-selected="false"] {
            background-color: unset;
        }

        mark {
            font-weight: 400;
            background: none;
            color: $cmsPageTitle;
        }
    }

    .aa-ItemContentTitle {
        display: inline-block;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    .aa-ItemLink {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: $cmsPageTitle;
        padding: 11px 31px;

        .aa-ItemLink_nbSug {
            font-weight: 700;
        }
    }
}
.product_page {
    &.algolia_on {
        .breadcrumbs_wrapper {
        display: none;
        }
    }
}


.ais-InstantSearch {
    display: flex;
    position: absolute;
    top: $headerHeight;
    left: 0;
    width: 100%;
    background-color: $white;
    opacity: 0;
    box-sizing: border-box;
    z-index: -1;
    transition: opacity .3s ease;

    &.displayed {
        display: flex;
        flex-direction: column;
        opacity: 1;
        z-index: 10;
    }

    .gondoleTitle,
    .no_res_best_sales_list,
    #noresult,
    #pagination {
        width: 98%;
        max-width: 1680px;
        margin: 0 auto;
    }

    #hits {
        max-width: 1920px;
        width: 100%;
        margin: 0 auto;
    }

    /* Search page header section */
    #results_header {
        padding: 20px 40px 0;

        #stats {
            .ais-Stats-text {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .result_query,
        .result_count {
            position: relative;
            display: flex;
        }

        .result_query {
            align-items: flex-start;
            font-size: clamp(18px, fs-vw(20, 1920), 19px);
            font-weight: 800;
            line-height: 27px;
            margin-right: 8px;

            &::before {
                content: "“";
                display: block;
            }

            &::after {
                content: "”";
                display: block;
            }
        }

        .result_count {
            align-items: center;
            font-size: 13px;
            font-weight: 400;
            line-height: 19px;

            &::before {
                content: "(";
                display: block;
            }

            &::after {
                content: ")";
                display: block;
            }
        }
    }
    /* End Search page header section ***********************/



    /* Search page suggestions ***********************/
    // Bloc suggestions
    .bloc_suggestions_container {
        display: none;// TEMPORARY DUE TO AUTOCOMPLETE ROLLBACK ON SEARCHBOX
        position: relative;
        max-width: 1024px;
        width: fit-content;
        padding: 0 25px;
        margin: clamp(16px, fs-vw(20,1920), 20px) auto 0;
        box-sizing: border-box;

        // Case when we have less than 5 suggestions
        &.no_swiper {
            .suggestions_arrow {
                display: none;
            }

            .swiper-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;

                .swiper-slide:not(:last-of-type) {
                    margin-right: 10px;
                }
            }
        }

        .list_suggestions {
            position: relative;
            flex-wrap: unset;
            margin: 0;

            &:not(.swiper-initialized) {
                .ais-Hits-item {
                    width: fit-content;
                }
            }
        }

        .suggestions_arrow {
            position: absolute;
            top: 56%;
            transform: translateY(-50%);
            cursor: pointer;
            border-color: $primaryColor;
            border-style: solid;
            width: 1vmin;
            height: 1vmin;
            transition: all .3s ease;

            &.suggestions_prev {
                left: 0;
                transform: rotate(-45deg) translate(100%, 0);
                border-width: 0.3vmin 0 0 0.3vmin;
            }

            &.suggestions_next {
                right: 0;
                transform: rotate(45deg) translate(-100%, 0);
                border-width: 0.3vmin 0.3vmin 0 0;
            }

            &.swiper-button-disabled {
                pointer-events: none;
                border-color: $lightGreyBorder;
            }
        }

        .aa-ItemLink {
            cursor: pointer;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            width: fit-content !important;
            margin: 0;
            padding: 6px 20px;
            border-radius: 5px;
            border: 1px solid $lightGrey3;
            background-color: $white;
            box-shadow: none;
            transition: all .3s ease-in-out;
            display: inline-block;
            white-space: nowrap;
            font-weight: 600;

            &.selected,
            &:hover {
                background-color: $lightGreyBorder;
                border-color: $lightGreyBorder;
            }

            &::first-letter {
                text-transform: uppercase;
            }
        }


        .ais-Pagination-item {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            // Page indicator
            &--page {
                display: none;
            }

            // Common
            &--previousPage,
            &--nextPage {
                width: 25px;
                height: 100%;
            }

            // Prev page
            &--previousPage {
                left: 0;

                .ais-Pagination-link {
                    &:before {
                        left: 0;
                        @include bgIconCoord(20, 6, 6);
                    }
                }
            }

            // Next page
            &--nextPage {
                right: 0;

                .ais-Pagination-link {
                    &:before {
                        right: 0;
                        @include bgIconCoord(21, 6, 6);
                    }
                }
            }

            &+.ais-Pagination-item {
                margin: 0;
            }
        }
    }
    /* End Search page suggestions **********************/

    /* LoadMore button */
    .ais-InfiniteHits-loadMore {
        display: block;
        width: 177px;
        height: 45px;
        font-family: $primaryFont;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        text-indent: 0;
        text-transform: uppercase;
        color: $white;
        cursor: pointer;
        border-radius: 0;
        border: 1px solid $grey3;
        background-color: $grey3;
        padding: 0;
        margin: 0 auto 20px;
        transition: all ease-in-out .5s;

        &:hover {
            background-color: $white;
            color: $primaryColor;
        }

        &.loading {
            position: relative;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                width: 100%;
                height: 100%;
                background-color: $primaryColor;
                background-image: url('../svg/three_dots.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 20%;
            }
        }

        &--disabled {
            display: none;
        }
    }
    /* End LoadMore button *****************/

    #holder_filters_sections {
        max-width: 1920px;
        margin: 0 auto;
        font-family: $primaryFont;
        font-style: normal;
        font-weight: 400;
        padding: 20px 40px 12px;
        box-sizing: border-box;
    }

    #range-slider {
        width: 90%;
        margin: 0 auto;

        .ais-RangeSlider {
            position: relative;
            width: 90%;
            margin: 2rem auto;

            .rheostat {
                overflow: visible;
                margin-top: 40px;
                margin-bottom: 40px;
            }

            .rheostat-progress {
                position: absolute;
                top: 1px;
                height: 3px;
                background-color: $primaryColor;
            }

            .rheostat-background {
                position: relative;
                height: 3px;
                width: 100%;
                background-color: $lightGrey3;
            }

            .rheostat-handle {
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background-color: $primaryColor;
                transform: translate(-50%, -50%);
                cursor: pointer;

                .rheostat-tooltip {
                    position: absolute;
                    bottom: -2rem;
                    border: 1px solid #000;
                    border-radius: 12%;
                    padding: 0.1rem;
                    background-color: #000;
                    color: #fff;
                    cursor: pointer;
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    font-family: 'Fira Sans',
                    sans-serif;
                    font-weight: 400;
                    transform: translateX(-37%);

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 100%;
                        left: 50%;
                        transform: translate(-50%, 0);
                        height: 0;
                        width: 0;
                        border-color: transparent;
                        border-bottom-color: transparent;
                        border-style: solid;
                        border-width: 5px;
                        border-bottom-color: inherit;
                    }
                }
            }
        }
    }
    #filter_sticky_search {
        width: 450px;
        height: calc(100vh - #{$headerHeight});
        position: fixed;
        right: 0;
        top: $headerHeight;
        background-color: $white;
        padding: 27px 0 29px;
        box-sizing: border-box;
        display: block;
        transform: translateX(100%);
        opacity: 0;
        transition: opacity,
        transform ease-in-out .35s;
        z-index: -1;

        &.displayed {
            transform: translateX(0%);
            opacity: 1;
            z-index: 30;
        }
    }
    .filter_title {
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 86px;
        padding: 0 24px 0 30px;
        border-bottom: 1px solid $white1;

        span {
            font-family: $eymenpro;
            font-style: normal;
            font-weight: 300;
            font-size: 36px;
            line-height: 45px;
            color: $cmsPageTitle;
            position: relative;
        }

        .close_filter {
            display: inline-block;
            width: 13px;
            height: 13px;
            background-image: url(../svg/spritesheet.svg);
            background-repeat: no-repeat;
            background-size: 600% auto;
            background-position: 80% 20%;
            margin-right: 6px;
            position: relative;
            top: -10px;
            cursor: pointer;
        }
    }

    .filter_wrapper_container {
        width: calc(100% - 28px);
        height: calc(100vh - 478px);
        overflow: auto;
        overflow-y: scroll;
        scrollbar-color: $green transparent;
        scrollbar-width: thin;
        padding: 0 40px 0 29px;
        box-sizing: border-box;

        /* Scrollbar webkit */
        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: $green;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: $green;
        }

        /* Scrollbar webkit end */

        .filters_dropdown_bloc.body_visible {
            .trigger_dropdown {
                &:after {
                    content: '-';
                }
            }
        }

        .trigger_dropdown {
            position: relative;
            display: block;
            width: 100%;
            font-family: $primaryFont;
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 30px;
            letter-spacing: 0.1em;
            text-transform: inherit;
            color: $black;
            padding: 19px 0 9px;
            border-bottom: 1px solid $grey16;
            cursor: pointer;

            &:after {
                content: '+';
                display: block;
                position: absolute;
                bottom: 0;
                top: 0;
                right: 0;
                margin: auto;
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 68px;
                text-align: center;
                letter-spacing: 0.1em;
                color: $black;
                background-image: inherit;
                width: 18px;
                height: 100%;
            }
        }

        .selected_options {
            display: none;
            width: 100%;
            padding: 0;
            margin: 0;
            border-bottom: none;

            &.for_range_slider {
                margin-top: 0;
            }

            &.colorOptions {
                .ais-RefinementList {
                    padding: 13.5px 0 13.5px 13.5px;
                }

                .ais-RefinementList-list {
                    display: flex;
                    flex-wrap: wrap;

                    .ais-RefinementList-item {
                        width: 50%;
                        margin: 5px 0;
                    }
                }
            }

            &.body_visible {
                .toggleFilter {
                    &::after {
                        content: "-";
                    }
                }
            }

            .ais-RefinementList {
                padding: 13.5px 50px;
            }
        }

        .os-scrollbar-vertical {
            right: 26px;
            background: $grey16;
            border-radius: 4px;
            width: 4px;

            .os-scrollbar-track {
                .os-scrollbar-handle {
                    background: $green !important;
                    border-radius: 4px;
                    width: 4px;
                    right: -2px;
                }
            }
        }


        .ais-RefinementList-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 23px;
            margin-bottom: 5px;

            >div {
                width: 100%;
            }

            .form_itm {
                display: flex;
                align-items: center;
                &:not(.color) {
                    &::before {
                        content: '';
                        min-width: 15px;
                        min-height: 15px;
                        max-width: 15px;
                        max-height: 15px;
                        border: 1px solid $cmsPageTitle;
                        border-radius: 0;
                        margin-right: 10px;
                    }
                }

                &.color {
                    position: relative;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    cursor: pointer;
                    box-sizing: border-box;

                    &[data-color="blanc"],
                    &[data-color="multicolore"] {
                        border: 1px solid rgba($primaryColor, .5);
                    }

                    label {
                        position: absolute;
                        display: flex;
                        left: 40px;
                        font-size: 14px;
                        white-space: nowrap;
                        letter-spacing: .05rem;

                        .color_name {
                            display: inline-block;

                            &::first-letter {
                                text-transform: uppercase;
                            }
                        }
                    }

                    &:hover,
                    &.checked {
                        font-weight: bold;
                        width: 24px;
                        height: 24px;
                        border: 2px solid $primaryColor;
                        box-shadow: inset 0 0 0 2px $white;
                    }
                }
            }
            .filter_label {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: $darkJungle;
                display: flex;
                align-items: center;
            }

            &:hover,
            &.ais-RefinementList-item--selected {
                .form_itm {
                    font-weight: bold;

                    &:before {
                        background-image: url(../svg/spritesheet.svg);
                        background-repeat: no-repeat;
                        background-size: 650% auto;
                        background-position: 0% 40%;
                    }
                }
            }

            .filter_label {
                display: flex;
                cursor: pointer;
            }

            .available_count {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-left: 10px;
                font-weight: bold;
                font-size: 12px;

                &::before {
                    content: "(";
                }

                &::after {
                    content: ")";
                }

                &::before,
                &::after {
                    display: block;
                }
            }
        }
    }

    .holder_submits {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 85px 24px 0 30px;

        text-align: center;

        >button.primary,
        .initFilters {
            width: 177px;
            height: 45px;
            border-radius: 0;
            text-align: center;

            span {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                text-transform: uppercase;
            }

        }

        >button.primary {
            border: 1px solid $grey3;
            background-color: $grey3;
            transition: all ease-in-out .5s;

            span {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                text-transform: uppercase;
                color: $white;
                cursor: pointer;
                padding: 0;
                transition: all ease-in-out .5s;
            }

            &:hover {
                background-color: $white;

                span {
                    color: $primaryColor;
                }
            }
        }

        .initFilters {
            margin-left: 15px;

            button {
                border: 1px solid $grey3;
                background-color: $white;
                color: $grey3;
                width: 100%;
                text-indent: unset;
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                text-transform: uppercase;
                transition: all ease-in-out .5s;
                cursor: pointer;

                &:hover {
                    background-color: $grey3;
                    color: $white;
                }
            }
        }
    }


    /* Sortby section */
    .sort-by_container {
        .result_count {
            font-weight: 600;
            text-transform: none;
            font-size: 13px;
            line-height: 21px;
            text-align: center;
            display: inline-block;
            width: auto;
            margin-right: 10px;
        }
    }
    #sort-by {
        flex-direction: row-reverse;
    }
    .ais-SortBy {
        position: relative;
        margin-left: 4px;

        .ais-SortBy-select {
            height: auto;
            text-indent: 0;
            background-color: transparent;
            font-size: 12px;
            line-height: 18px;
            color: $black;
            cursor: pointer;
            padding-right: 25px;
        }

        &:after {
            content: '';
            width: 10px;
            height: 6px;
            background: url(../svg/ddown.svg) center left no-repeat;
            background-size: cover;
            position: absolute;
            right: 8px;
            top: 6px;
            cursor: pointer;
            z-index: -1;
        }
    }
    /* End Sortby section ***********************/

    #trigger_filtre {
        display: flex;
        max-width: unset;
        flex-wrap: wrap;
        width: auto;
        margin: 0 0 0px;
        border-top: 0;
        .for_displayFilters {
            order:3
        }

        .priceFilters {
            order: 2;
        }

        .ais-DynamicWidgets {
            display: flex;
            order: 1;
        }
        .ais-DynamicWidgets-widget {
            display: inline-block;
            width: auto;


            .ais-Panel {
                margin-right: 10px;
                margin-bottom: 10px;
            }
            .filtre_elem {
                display: block;
                font-size: clamp(10px, fs-vw(12, 1920), 12px);
                line-height: 40px;
                text-align: center;
                text-transform: none;
                height: 40px;
                color: $darkJungle;
                background-color: $lightGrey3;
                transition: all ease-in-out 0.5s;
                border-radius: 5px;
                cursor: pointer;
                padding: 0 16px;
                width: max-content;

                @media only screen and (max-width: 1360px) {
                    padding: 0 10px;
                }

                @media only screen and (max-width: 1240px) {
                    padding: 0 8px;
                }
            }

            &:hover .filtre_elem {
                background-color: $lightGreyBorder;
            }

            &::after {
                display: none;
            }

            &.for_displayFilters {
                .ico {
                    display: inline-block;
                    width: 19px;
                    height: 19px;
                    background-image: url(../svg/spritesheet.svg);
                    background-repeat: no-repeat;
                    background-size: 600% auto;
                    background-position: 60% 20%;
                    margin-right: 6px;
                    position: relative;
                    top: 3px;
                }
            }
        }

        .ais-Panel--noRefinement {
            display: none;
        }
    }


    /* Products / results section */
    .ais-InfiniteHits-list {
        display: flex;
        flex-wrap: wrap;
        max-width: 1920px;
        width: 100%;
        padding: 0 35px;
        box-sizing: border-box;
        margin: 0 auto;
    }
    .ais-Hits-item {
        max-width: 308px;
    }
    .ais-Hits-item:not(.suggestion),
    .ais-InfiniteHits-item {
        position: relative;
        width: 25%;
        border: 1px solid $white;
        padding: 8px;
        margin-bottom: 45px;
        box-sizing: border-box;

        &:not(.img_push):hover {
            border-color: $lightGreyBorder;

            .couleur_produit,
            .couleur_produit_search {
                opacity: 1;
            }
        }

        .ill_img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            img {
                opacity: 1;
            }
        }

        // Lazyload
        &:hover {
            .item .ill_img+.ill_img {
                opacity: 1;

                img {
                    opacity: 1;
                }
            }
        }
        .item .ill_img+.ill_img {
            opacity: 0;

            img {
                opacity: 0;
                transition: opacity .3s ease-in-out;
            }
        }

        @media only screen and (max-width: 1060px) {
            width: 50%;
        }

        // Category page product video
        .wrap_video {
            position: relative;
            display: block;
            margin-bottom: 15px;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            overflow: hidden;

            iframe {
                position: absolute;
                z-index: 0;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                border: 0;
            }
        }

        .rolloverimgContainer,
        .rayon_img_container {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
            margin-bottom: 21px;
            width: 100%;
            max-width: 440px;
            padding-top: calc(440 / 440 * 100%);

            .overlay {
                display: none;
            }

            .rayon_img_content {
                position: relative;
                display: block;
            }

            .mark {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;

                img {
                    width: auto;
                    height: auto;
                }
            }

            .eclat_horiz {
                position: absolute;
                bottom: 13px;
                right: 11px;
                z-index: 2;

                img {
                    width: auto;
                    height: auto;
                }
            }

            .couleur_produit,
            .couleur_produit_search {
                display: flex;
                align-items: center;
                position: absolute;
                bottom: 13px;
                left: 15px;
                opacity: 0;
                transition: opacity 0.5s ease-in-out;
                z-index: 3;

                .img_couleur_horiz {
                    display: inline-block;
                    width: 39px;
                    height: 39px;
                    margin-right: 6px;
                    border: 1px solid #e7e7e5;
                    background: $lightGreyBorder;
                    background-size: cover;
                    position: relative;

                    &:hover {
                        &:after {
                            position: absolute;
                            bottom: -5px;
                            left: 0;
                            content: "";
                            display: block;
                            width: 100%;
                            height: 2px;
                            background-color: $cmsPageTitle;
                            transition: all .3s ease;
                       }
                    }
                }

                .nb_products_left {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13px;
                    color: $darkJungle;
                    line-height: 16px;
                    white-space: nowrap;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-transform: uppercase;
                    background: $white;

                    border: 1px solid $lightGreyBorder;
                    padding: 3px;
                    min-width: 35px;
                    height: 35px;
                    // 35 + 3px padding + 1px border = 39px of the .img_couleur_horiz
                }
            }
        }

        /* recherche */
        .block_lnk {
            position: relative;

            .cover_type_img {
                margin-bottom: 21px;
                position: relative;
                overflow: hidden;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            // Cacher l'éclat "Nouveauté" si l'eclat "Out Of Stock" est présent
            .stock_depleted~.seen_on_tv {
                display: none;
            }

            &:hover {
                .couleur_produit_search {
                    opacity: 1;
                }

                .rolloverimgContainer {
                    >img {
                        z-index: -1;

                        &+img {
                            opacity: 1;
                            transition: opacity 0.5s ease-in-out;
                            z-index: 1;
                        }
                    }
                }
            }
        }

        .iframe_container {
            position: relative;
            height: 0;
            padding-bottom: 100%;
            margin-bottom: 21px;

            iframe,
            .link_product_iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
                border: 0;
                z-index: -1;
            }
        }

        .wrapper_description {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .name {

                .item_name {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 18px;
                    color: $darkJungle;
                    display: block;
                    margin-bottom: 4px;
                }

                .item_feat {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 15px;
                    color: $darkJungle;
                    display: inline-block;
                    margin-bottom: 4px;
                }
            }

            .wrapper_price,
            .price {
                text-align: right;
                display: block;

                .pricetag {
                    font-size: 24px;
                    font-family: $primaryFont;
                    text-align: right;
                    display: block;
                    width: auto;
                    color: $redPromoPrice;

                    &+.pricetag {
                        font-size: 16px;
                        color: $monsoon;
                        display: inline-block;

                        &:after {
                            content: '';
                            height: 1px;
                            background-color: $monsoon;
                            width: calc(100% - 11px);
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: 11px;
                            margin: auto;
                        }

                        span {
                            &.cents {
                                text-decoration: none;
                                display: inline-block;
                            }
                        }
                    }

                    &:only-child {
                        color: $grey3;
                    }
                }
            }
        }

        .item_ratings {
            .netreviews_reviews_rate {
                color: $jauneAvisV;
                font-size: 17px;

                &+.netreviews_reviews_rate {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 21px;
                    color: $darkJungle;
                }
            }
        }

        /*PUSH*/
        &.img_push {
            .block_lnk {
                display: block;
                width: 100%;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        /* Achat express */
        .bloc_add_color {
            display: none !important;
        }

        .ligne_form {
            &.productSizeFieldset {
                display: none !important;
            }
        }

        .wrap_rolloverproduit {
            width: 100%;

            .wrap_rolloverproduit_container {
                form {
                    .rollover_left {
                        .addToWishlistButton {
                            position: absolute;
                            top: 22px;
                            right: 21px;
                            z-index: 3;
                            display: block;

                            span {
                                display: none;
                            }

                            &::before {
                                content: '';
                                width: 20px;
                                height: 20px;
                                background-image: url(../svg/spritesheet.svg);
                                background-size: 600% auto;
                                background-position: 40% 0;
                                display: inline-block;
                            }

                            &.in_wishlist,
                            &.existToWishlistButton {
                                &::before {
                                    background-position: 40% 40%;
                                }
                            }

                            &:hover {
                                &::before {
                                    background-position: 40% 40%;
                                }
                            }
                        }

                        .productColorFieldset {
                            display: none;
                        }
                    }
                }
            }
        }
    }


    #noresult {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .no_results {
        .no_result_txt {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 25px;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 40px;

            >p {
                font-size: 13px;
                font-weight: 400;
                text-transform: initial;
                margin-top: 20px;
            }
        }
    }
}

// No results page : visited articles swiper
#algolia_visited_articles {
    max-width: 1680px;
    width: 98%;
    margin: 0 auto 45px;

    &:not(.swiper-container-horizontal) {
        .swiper-slider {
            margin-right: 29px;
        }
    }

    .swiper-container {
        max-width: 100%;

        .swiper-slide {
            max-width: 308px;
            width: calc(100% / 5);
        }
    }
}

.container_algolia_categ_module {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 98%;
    max-width: 1284px;
    margin: 0 auto;

    a {
        position: relative;
        display: block;
        width: 31.2%;
        max-width: 400px;
        margin-bottom: 2rem;
    }

    img {
        width: 100%;
        height: auto;
        vertical-align: top;
    }
}
.container_inject_gondole {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 1680px;
    width: 100%;
    box-sizing: border-box;

    .ais-Hits--empty,
    .no_swiper {
        display: none;
    }

    .swiper-wrapper {
        max-width: 1680px;
        margin: 0 auto;
        box-sizing: border-box;
    }
}
.noresult_gondole_title {
    width: 100%;
    font-size: clamp(15px, fs-vw(18,1920), 18px);
    font-weight: 800;
    line-height: 44px;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 27px;
    border-bottom: 1px solid $grey3;
    order: -1;

    > span {
        font-weight: 400;
    }
}



#form_recherche {
    .ais-SearchBox-submit,
    .ais-SearchBox-reset {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background: transparent;
        text-indent: unset;
        line-height: 1;
        width: 25px;
        height: 25px;
        background: $white;
        box-sizing: content-box;
        padding-right: 2%;

        svg {
            text-indent: unset;
            height: unset;
            line-height: 1;
        }

    }

    .aa-ClearIcon {
        color: $cmsPageTitle;
    }

    .ais-SearchBox-submit {
        &::before {
            content: "";
            display: block;
            background-size: contain;
            background-image: url('../svg/loupe_search.svg');
            width: 25px;
            height: 25px;
            background-repeat: no-repeat;
            background-position: center center;
        }

        svg {
            display: none;
        }

        label {
            display: none;
        }
    }

    .ais-SearchBox-reset {
        svg {
            width: 10px;
            height: 10px;
        }
    }

    .ais-SearchBox-input {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(180deg, rgba(251, 251, 251, 0) 0%, rgba(251, 251, 251, 0.16) 100%);
        padding: 0;
        opacity: 1;
        color: $primaryColor;
        transition: all 0.3s ease-in-out;
        border: none;
        line-height: 2.1;
        letter-spacing: 0.05em;
        text-indent: 0em !important;
        border-bottom: solid 1px #8A8279;
        font-family: $primaryFont;

        &::-ms-clear {
            display: none;
        }

        &::placeholder {
            font-size: 0.9rem;
            font-style: normal;
            letter-spacing: 0.05em;
            padding-left: 0rem !important;
            line-height: 2.1;
            text-indent: 0em !important;
            text-align: left !important;
            color: #CBCACA;

            @media screen and (max-width: 1190px) {
                padding-left: 0;
                font-size: 0.7rem;
                text-indent: 0em;
            }

            @-moz-document url-prefix() {
                line-height: 3.4;
            }
        }
    }
}