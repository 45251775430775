$black: #000000;
$white: #ffffff;
$txtBlack: #1d1d1b;
$green: #c4d600;
$errorRed: #AC1623;
$validGreen: #63AB07;
$textGrey: #515151;
$fadeGreen: #f3f5ce;
$fadeGrey: #cccccc;
$lightBlack: #1d1d1b;
$darkenGrey: #525051;
$tableGrey: #ededed;
$textlightGrey: #666666;
$darkGrey: #373737;
$midGrey: #777777;

$blue: #29c1cc;
$orange: #ff9933;
$primaryColor: #1d1d1b;
$secondaryColor: #c4d600;
$btnBgColor: #171717;
$lightBlue: #90c5c5;

// Colour-coding
$backgroundGrey: #d8d8d8;
$footerGrey: #f7f7f7;
$emailGreen: #ccd500;
$submitGreen: #eaed9d;
$contactGrey: #a8a8a8;
$geoTurquoise: #2dc7c8;
$grey: #808080;
$grey2: #282828;
$grey3: #2b2b2b;
$grey4: #949494;
$grey5: #b5b5b5;
$grey6: #ececec;
$grey7: #8f8f8f;
$grey8: #999999;
$grey9: #535353;
$grey10: #BBBBBB;
$grey11: #DEDEDE;
$grey12: #A3A3A3;
$grey13: #9b9797;
$grey14: #989898;
$grey15: #AFAFAF;
$grey16: #D9D9D9;
$grey95: #f2f2f2;
$gray63: #a1a1a1;
$gray16: #818181;
$gray70: #b3b3b3;
$gray91: #e8e8e8;
$gray92: #ebebeb;
$gray93: #ededed;
$gray88: #e0e0e0;
$gray82: #d1d1d1;
$grey86: #dbdbdb;
$grey87: #868686;
$grey94: #877e77;

$blue2: #2dc6c6;
$yellow2: #c0cb23;
$yellow3: #c5d31c;
$lightGrey: #FBFBFB;
$lightGrey2: #FF6565;
$lightGrey3: #F5F5F5;
$lightGrey4: #E8E8E8;
$xmasgreen: #0F5652;
$lightgrey: #eee;
$confetti: #e2e759;
$red: red;
$yellowgreen: #9acd32;
$tomato: #ff6347;
$white1: #f4f4f4;
$lightcharcoal: #898989;
$carnelain: #ac1717;
$nero: #252525;
$eveningprime: #cfdc1d;
$zambezi: #5f5f5f;
$eclipse: #3a3a3a;
$whitesmoke: #efefef;
$nobel: #9d9d9d;
$darkgray: #3c3c3c;
$discreetgrey: #909090;
$pantone: #171901;
$gainsboro: #dcdcdc;
$greenyellow: #b5cf23;
$greendark: #1d1f08;
$lightshadegrey: #e6e6e6;
$darkgray1: #4d4d4d;
$darkgray2: #111;
$balticsea: #414243;
$pigmentgreen: #17ac50;
$judgegray: #53514f;
$mediumdarkgrey: #404040;
$eerieblack: #1f1f1f;
$guyabano: #f8f8f8;
$mediundarkgray1: #636363;
$applegreen: #c0cc18;
$mediumpink: #f16b78;
$gainsboro2: #e2e2e2;
$lightblue1: #cdd5dd;
$paleBlue1: #5f9fd6;
$paleBlue2: #2996a7;
$goldBrown1: #b98309;
$paleGreen1: #9aae78;
$violet1: #8a809d;
$pink1: #d74b76;
$blueGrey1: #7493a3;
$orange1: #cd6147;
$red1: #e40138;

// v2
$cmsPageTitle: #8A8279;
$shiraz: #A50034;
$mustardgreen: #c0cc1c;
$monsoon: #888888;
$supernova: #FFCD00;
$darkJungle: #1B1C1E;
$lightGreyBorder: #E7E7E5;
$redPromoPrice: #9E1C29;
$middleGrey: #8C857B;
$jauneAvisV: #FCC740;
$leadbelcherMetal: #888c8d;
$pompelmo: #ff6d6d;
$orangeRG: #d25917;
$almaOrange: #FA5022;
$gainsboros: #DADADA;
$monsoon: #888888;

$pwError: #D9000C;
$pwValid: #04AC6C;