@charset "utf-8";

.brandMenuMobile {
    display: none;
}

.middle_box strong {
    font-weight: bold;
}

/* TITLES */
.wrapper_content_sat {
    h1.title {
        padding-bottom: 0;
        position: relative;
        height: 42px;
        line-height: 42px;
        margin-bottom: 0;
        font-size: 30px;
        font-weight: 600;
        width: fit-content;

        &:after {
            display: block;
            position: absolute;
            top: 110%;
            left: 0;
            width: 100%;
            height: 6px;
            background-color: $green;
            content: '';
        }
    }

    h2 {
        font-size: 14px;
        color: $green;
        font-weight: 700;

        &:first-child {
            margin-bottom: 0;
            padding: 30px 0 10px;
        }
    }

    h3,
    .h3Tile,
    h4,
    .h4Tile {
        font-size: inherit;
        font-weight: 700;
    }

    .content_sat {
        margin-bottom: 50px;

        .row {
            .content {
                margin: 0;
                text-align: justify;
                font-family: Arial, sans-serif;
                font-weight: 400;
                font-size: 12px;
                color: #666;

                ul:not(.rassurance) {
                    li:not(.bloc) {
                        list-style: disc;
                        margin-left: 25px;
                    }
                }
            }
        }
    }
}

#middle_box_content {
    width: 100%;
    margin: 0;
}

#middle_box_content .h3Title {
    font-weight: normal;
    margin: 0;
    text-transform: uppercase;
    font-size: 1.54em;
}

#middle_box_content p {
    line-height: 22px;
}

#content_wrapper {
    width: 100%;
    height: 100%;
}

.btn_rap_prod a {
    position: relative;
}

.btn_rap_prod a:before {
    background: url("../img/fleche_link.jpg") no-repeat right top;
    position: absolute;
    content: '';
    width: 8px;
    height: 10px;
    bottom: 3px;
    right: 0;
}

.product_recall img {
    width: 44%;
}

.bloc_magasin {
    position: relative;
}

#subscriptionForm {
    .w-form-line .w-input-container .w-input {
        .w-input-label {
            .multiline {
                text-align: left;
                width: 100%;
            }
        }
    }
}

/**
 * #MYHESPERIDE HOME
 */
#hashtag_hesperide {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    width: 100%;
    max-width: 1690px;
    min-width: 990px;
    margin-bottom: 2.9rem;

    .block_content {
        margin: 0 .3rem;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &.bloc_resultat_filtre {
            width: 18%;
        }

        &.text {
            width: 287px;
            height: 259px;
            background-color: $white;
            border: 2rem solid $fadeGreen;
            text-align: center;
            color: $textGrey;
            position: relative;

            h3,
            .h3title {
                text-transform: uppercase;
                font-weight: 800;
                font-size: 1.34rem;
                padding: 2.3rem 10px 1rem;

            }

            span {
                font-size: 0.9rem;
                line-height: 1.5;
                padding: 0 5px;

                span {
                    padding: 0 2px;
                }
            }
        }

        .btn_buy {
            top: 79%;
            height: 38px;

            >span {
                line-height: 37px;
                font-size: 13px;
                padding: 0 25px;
                text-align: left;

                @media screen and (max-width:1350px) {
                    text-align: center;
                    padding: 0;
                }
            }

            @media screen and (max-width:1350px) {
                position: inherit;
                top: 7%;
                width: 50%;
                margin: 0.5rem 0 1.5rem;
            }
        }
    }
}

.first_title_mat {
    text-indent: 3rem;
}

@media only screen and (max-width: 1024px) {
    .sticky {
        position: relative;
    }

    /* safari only */
    &:not(:root:root) {
        .lamarque.autre_histoire {
            height: auto;
        }
    }
}

.misc_cookiesinfo,
.order_cgv,
.footer_legalmentions {
    h1.title {
        padding-bottom: 0;
        font-size: 25px;
    }

    h2,
    .h2Title {
        font-size: 14px;
        color: $green;
        font-weight: 700;

    }

    h3,
    h4,
    .h4Title,
    .h3Tile {
        font-size: inherit;
        font-weight: 700;
    }

    .content_sat {
        margin-bottom: 50px;
    }
}


.footer_legalmentions {
    .row .content img {
        width: 90px !important;
        height: auto !important;
        vertical-align: text-bottom !important;
    }
}

.order_cgv {
    h2 {
        margin-bottom: 20px;
    }

    h3,
    .h3Title,
    .h4Title,
    h4 {
        text-decoration: underline;
        margin: 14px 0;
        font-weight: bold;
    }

    #bloc_6 ul:nth-of-type(1) li {
        margin-left: 7px;
    }

    #bloc_15 {
        div:last-child {
            display: flex;
            border-top: 1px solid #000000;
            width: 980px;
            height: 36px;
            margin: 0 auto;
            align-items: flex-end;
            font-size: 11px;
        }

        ul li {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}

.misc_cookiesinfo {
    .content {
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 40px;
        font-family: Arial;
        color: #666666;
    }

    .row:not(#row_bloc_0) {
        h2 {
            margin-bottom: 20px;
        }
    }

    #bloc_3 ul li {
        margin-bottom: 10px;
    }

    ul {
        padding: 15px 0;

    }
}

.order_cgv .content:not(#bloc_6) ul,
.order_cgv #bloc_6 ul:last-child {
    padding: 0;

    li {
        margin-left: 7px;
        list-style-type: none;

        &:before {
            content: '-';
            margin-right: 7px;
        }
    }
}

/*---Reglement jeu concour---*/
.satellite_reg_jeuconcours {
    .middle_box {
        max-width: 990px;
    }

    .main_title {
        margin: 3rem 0 0;
    }

    a {
        color: $green;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    p {
        line-height: 1.3;
    }

    #protectiondonnees ul {
        margin: 2rem 0 2rem 3rem;
        list-style: square;
    }
}

// Page suivi commande
.satellite_suivi_commande {

    #site_global_wrap {
        padding-top: 190px;
    }

    #breadcrumbs {
        display: none;
    }

    .return_container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 98%;
        max-width: 1680px;
        height: 31px;
        margin: 0 auto;

        a.btn_retour {
            position: initial;
            width: fit-content;
            background: transparent;
            font-size: clamp(12px, 0.677vw, 13px);
            font-weight: 500;
            color: $primaryColor;
            letter-spacing: .05em;
            text-transform: initial;
            text-decoration: underline;
            line-height: 30px;
            transition: all 0.5s ease-out;

            &:hover {
                color: $primaryColor;
                opacity: .8;
            }
        }
    }

    .satellite_content {
        max-width: 1440px;
        width: 100%;
        display: block;
        margin: auto;
    }

    .holder_shipup h1 {
        font-size: clamp(16px, 1.2vw, 22px);
        text-align: center;
    }

    button.shipup-tracker-search-submit {
        text-indent: initial;
    }

    .footer_rassu_wrapper {
        padding: 30px 1rem 60px;
    }

    button.shipup-event-modal-button,
    button.shipup-navbar-button,
    button.shipup-tracker-search-submit {
        text-indent: initial;
        height: initial;
    }

    .shipup-text-field-container input {
        text-indent: initial;
        height: initial;
        line-height: initial;
    }
}