// Style for lazyload.js on _app

.ill_img {
    position: relative;
    width: 100%;
    display: block;
    opacity: 0;
    transition: opacity 0.2s linear;

    &.imgLoaded {
        opacity: 1;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
    }
}