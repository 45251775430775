@import '../../_app/_src/scss/desktop/mixins/typeFace';

/******************************************************************************/
/* FONT WEIGHTS */
/******************************************************************************/
$thin: 100;
$extralight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800; //HOME

$firasans: (
    normal: (light, regular, medium, semibold, bold)
);

$eymenpro: (
    normal: (light)
);

@include typeFace('firasans', $firasans);
@include typeFace('eymenpro', $eymenpro);

$primaryFont: 'firasans', sans-serif;
$secondaryFont: 'firasans', sans-serif;
$secondaryFontRegular: 'firasans', sans-serif;
$secondaryFontBold: 'firasans', sans-serif;
$secondaryFontSemiBold: 'firasans', sans-serif;
$secondaryFontExtraBold: 'firasans', sans-serif;
$eymenpro: 'eymenpro', sans-serif;