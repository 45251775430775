$fs_title : 1.4rem;
$fs_txt : 1rem;

#configurator_modal.modal {
    display: none;
    box-sizing: border-box;
    max-width: 40vw;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    max-height: 90vh;

    .modal-title {
        font-size: 1.85rem;
        font-family: $secondaryFontSemiBold;
        text-align: center;
        margin-bottom: 1rem;
    }

    // modal disclaimers
    .modal_disclaimer {
        text-align: center;
    }

    //

    // QR CODE MODAL SPE STYLES :
    .qrcode {
        text-align: center;
    }

    //

    // ADD TO BASKET MODAL
    .container_submits {
        display: flex;
        justify-content: center;
        column-gap: 1rem;
        margin-top: 1rem;

        >.form_submit {
            width: 50%;
        }
    }

    .holder_cta_modify {
        text-align: center;
    }

    .cta_modify {
        margin-top: 1rem;
        text-transform: uppercase;
        text-decoration: underline;
        display: inline-block;
        cursor: pointer;
    }

    //

    .close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        width: auto;
        height: auto;
        text-indent: unset;
        line-height: 1;

        &::before {
            content: url("../svg/cross.svg");
            display: block;
            width: 1rem;
            height: 1rem;
            cursor: pointer;
            background-color: $white;
        }
    }

    #modal_addToWishlistButton {
        min-height: 50px;
        border: 2px solid $primaryColor;
        font-size: .75rem;
    }

    .summary_list_element {
        display: flex;
        align-items: center;

        // .section_qte,
        .section_image {
            width: 20%;
        }


        .section_image {
            img {
                max-width: 100%;
            }
        }

        .section_detail {
            width: 65%;
            padding: 0 1rem;
            line-height: 1.5;
            display: flex;
            flex-direction: column;

            .detail_title {
                order: 1;
                font-size: 1.125rem;

                &::first-letter {
                    text-transform: uppercase;
                }
            }

            .detail_qte,
            .detail_ref {
                font-size: .8125rem;
            }

            .detail_qte {
                order: 2;
            }

            .detail_ref {
                order: 3;

                &.is_auto_added {
                    color: $errorRed;
                    line-height: 1.2;

                    .auto_added_label {
                        font-size: .8125rem;
                    }
                }
            }
        }

    }

    .holder_summary_top {
        display: flex;
        align-items: center;
        padding: 2rem;
        background-color: $tableGrey;

        .top_product_img_holder {
            max-width: 30%;
            margin-right: 2rem;

            img {
                max-width: 100%;
            }
        }

        .top_product_details_holder {
            width: auto;

            >span {
                display: block;
                margin-top: 1rem;
                font-size: 1.75rem;
                font-weight: 500;
            }
        }
    }

    .list_elements_title {
        margin: 1rem 0;
        font-size: 1.25rem;
        text-transform: uppercase;
        font-weight: 400;
        text-align: center;
    }

    .holder_summary_details {
        max-height: 32vh;
        overflow-y: auto;
    }
}

@media screen and (max-width: 1550px) {
    #configurator_modal.modal {
        max-width: 50vw;

        .modal-title {
            font-size: 1.25rem;
        }

        .holder_summary_top .top_product_details_holder h3 {
            font-size: 1.125rem;
        }

        .detail_title {
            font-size: $fs_txt;
        }
    }
}

@media screen and (max-width: 1150px) {
    #configurator_modal.modal {
        max-width: 60vw;

        .holder_summary_top {
            padding: 1rem;
        }
    }
}

@media screen and (max-width: 1024px) {
    #configurator_modal.modal {
        .holder_summary_top {
            padding: .75rem;
        }

        .holder_summary_details {
            max-height: 25vh;
        }
    }
}

@media screen and (max-height: 800px) {
    #configurator_modal.modal {
        .summary_list_element {
            .section_image {
                width: 18%;
            }
        }

        .holder_summary_top {
            padding: 1rem;
        }

        .holder_summary_details {
            max-height: 38vh;
        }
    }
}

@media screen and (max-height: 700px) {
    #configurator_modal.modal {
        .holder_summary_top {
            padding: 1rem;
        }

        .holder_summary_details {
            max-height: 25vh;
        }
    }
}

.inspi_configurator {

    // Sendfriendbox
    #sendfriendbox {
        padding: 2rem;

        #sendfriend_prodinfos {
            display: none;
        }

        .close {
            position: absolute;
            top: 2rem;
            right: 2rem;
            width: auto;
            height: auto;
            text-indent: unset;
            line-height: 1;
            cursor: pointer;
            z-index: 2;

            &::before {
                content: url("../svg/cross.svg");
                display: block;
                width: 1rem;
                height: 1rem;
                cursor: pointer;
                background-color: $white;
            }
        }

        .popup_title {
            height: 50px;
        }

        .w-share-form,
        #content_sendfriendbox {
            padding: 0;
        }

        .formContent {
            max-height: 70vh;
        }
    }
}

.inspi3d_content_wrapper {
    position: relative;
    font-family: $primaryFont;
    margin-top: 3vh;
    margin-bottom: 5rem;

    button {
        text-indent: unset;
    }

    .form-load-config {
        display: none;
    }

    .transparent {
        opacity: 0;
    }

    .configurator_pinpoint {
        content: "";
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: $green;
        position: relative;
        margin: 0 .5rem;

        &::after {
            content: "+";
            display: block;
            font-size: 16px;
            font-family: $secondaryFontExtraBold;
            color: $white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    /* CONTAINERS ****************/
    /****************************/

    #container-configurator {
        max-width: 100%;
        display: flex;
        justify-content: space-between;

        >* {
            max-width: 100%;
        }
    }

    .container_left {
        width: 63%;
    }

    .container_right {
        width: 35%;
        position: relative;
    }


    *,
    ::before,
    ::after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    .title {
        position: absolute;
        bottom: 100%;
        left: 0;
        max-width: 100%;
        background-color: white;
        font-size: $fs_title;
        z-index: 1;
        visibility: hidden;
        margin-bottom: 1rem;
        font-family: $secondaryFontExtraBold;
    }

    .hide {
        display: none;
    }

    #viewer-container {
        position: relative;
        margin: 0;
        width: 100%;
        height: 75vh;

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 2rem;
            left: 50%;
            transform: translateX(-50%);
            width: 32px;
            height: 32px;
            z-index: 1;
            opacity: .4;
            background-image: url('../img/360_arrows.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

    }

    /* EXTERNAL BUTTONS */

    #button-list-AR {
        position: relative;
        display: inline-block;
        font-family: $primaryFont;

        &:hover {
            .tooltip_ar {
                opacity: 1;
            }
        }

        .tooltip_ar {
            bottom: 125%;
        }

        .btn_buy {
            visibility: hidden;
            position: relative;
            top: 0;
            left: 0;
            transform: none;
            padding: 0 1rem;
            font-weight: 700;
        }
    }

    #button-list-configurator {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 11;
        background-color: $white;
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 10px 10px 0 0;
    }

    #button-list {
        position: absolute;
        margin-left: 2%;
        width: 50%;
        bottom: 0;
        background-color: transparent;
    }

    .button-interface {
        cursor: pointer;
        width: 52px !important;
        height: 52px !important;
        background-color: $tableGrey;
        margin: 0;
        padding: 0;
        border: none;
        z-index: 2;
        opacity: 0.4;
        background-repeat: no-repeat;
        background-size: cover;
        visibility: hidden;
        vertical-align: bottom;
        position: relative;
        font-family: $primaryFont;

        &:hover {
            opacity: 1;

            &>.configurator_btn_tooltip {
                opacity: 1;

            }
        }
    }

    .configurator_btn_tooltip {
        position: absolute;
        display: inline-block;
        bottom: 110%;
        left: 10%;
        padding: 10px;
        background-color: #2b2b2b;
        color: white;
        transition: all .2s ease;
        opacity: 0;
        min-width: 100px;
        white-space: nowrap;
        font-size: .875rem;
        line-height: 1;
    }

    .button-interface::-moz-focus-inner {
        border: 0;
    }

    .button-interface:focus {
        outline: none;
    }

    #button-list-website {
        display: none;
        justify-content: right;
        position: absolute;
        width: 50%;
        right: 5px;
        bottom: 0;
        background-color: transparent;
    }

    .button-interface-website {
        margin: 0 2px;
        visibility: hidden;
    }

    .export,
    .config-id {
        padding: 5px;
        text-indent: 0;
        min-width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .openclose {
        background-image: url('../img/inspi_configurator/lames_open.png');
        background-repeat: no-repeat;
        background-position: center;

        &[data-current-state="open"] {
            background-image: url('../img/inspi_configurator/lames_closed.png');
        }

        &[data-current-state="closed"] {
            background-image: url('../img/inspi_configurator/lames_open.png');
        }
    }

    .screenshot {
        background-image: url('../img/inspi_configurator/cta_screenshot.png');
        background-repeat: no-repeat;
        background-position: center;
    }

    .reset {
        background-image: url('../img/inspi_configurator/cta_reset.png');
        background-repeat: no-repeat;
        background-position: center;
    }

    .dimension {
        // SW-10229 - customer request to hide this pictogram
        display: none;
        background-image: url('../img/inspi_configurator/cta_dimension.png');
        background-repeat: no-repeat;
        background-position: center;
    }

    .share {
        background-image: url('../img/inspi_configurator/cta_share.png');
        background-repeat: no-repeat;
        background-position: center;
    }

    /* === MENU === */

    .holder_preconfig_menu {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 75vh;
        padding: 2rem;
        background-color: $tableGrey;
        z-index: 2;
        display: flex;
        flex-direction: column;

        &.transparent {
            z-index: -1;
        }
    }

    .holder_menus {
        padding: 2rem;
        background-color: $tableGrey;
        max-width: 100%;
        height: 75vh;
        opacity: 1;
        position: relative;
        display: flex;
        flex-direction: column;

        .container_disclaimer {
            position: absolute;
            width: 100%;
            height: 100%;
            padding: 2rem;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: $tableGrey;
            z-index: 3;

            // The disclaimer must only be present on page load so when transparent class is added, disable clicks too
            &.transparent {
                pointer-events: none;
            }

            .disclaimer_title,
            .disclaimer_subtitle {
                text-align: center;
            }

            .disclaimer_title {
                padding-bottom: 5%;
            }

            .disclaimer_subtitle {
                padding-bottom: 15%;
            }

            .configurator_ar_cta {
                padding: 5px 10px;
                border: 2px solid $green;
                line-height: 40px;
                text-transform: uppercase;
                font-weight: 500;
                margin: .5rem;
            }

            .disclaimer_b {
                font-weight: 500;
                display: flex;
                align-items: center;

                &::before {
                    content: "•";
                    display: block;
                    margin-right: .5rem;
                }
            }

        }

        .cta_understood {
            display: block;
            align-self: flex-end;
            text-transform: uppercase;
            font-size: .75rem;
            text-decoration: underline;
            cursor: pointer;
            font-weight: 500;
            margin-top: 8%;
            padding: 1rem 0 1rem 1rem;
        }

        // Flex grow to fix submits on the bottom of the menu while second menu isn't displayed yet
        .container_menu:not(.second_menu_active) {
            flex-grow: 1;
        }
    }

    .menu-second {
        order: 2;
        height: 74%;
        margin-bottom: 1rem;
        transition: opacity .2s ease;
    }

    .menu-second .menu-content {
        height: 93%;
    }

    .menu_title {
        text-transform: uppercase;
        font-size: $fs_txt;
        font-weight: 500;
        margin-bottom: 1rem;
        text-align: center;
        font-family: $secondaryFontSemiBold;


    }

    .menu::-webkit-scrollbar,
    .menu-second .menu-content::-webkit-scrollbar {
        width: 8px;
        background-color: #fff;
    }

    .menu::-webkit-scrollbar-thumb,
    .menu-second .menu-content::-webkit-scrollbar-thumb {
        background-color: #c6c6c6;
    }

    .container-menu {
        width: 100%;
        height: 80px;
        line-height: 80px;
        order: 1;
    }

    .container-menu:hover {
        background-color: rgba(220, 220, 220, 0.4);
    }

    .container_totals {
        order: 3;

        .configurator_total {
            text-align: center;
            font-size: 1.6vw;
            font-family: $secondaryFontExtraBold;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: .5rem;
        }

        .configuration_price_label {
            font-size: 1.2vw;
        }
    }

    .container_submits {
        order: 4;
        display: flex;
        justify-content: center;
        column-gap: 1rem;
        margin-top: 1rem;

        >.form_submit {
            width: 50%;
        }

        .button {
            line-height: 1;
            padding: .5rem;
        }

        #addToWishlistButton {
            min-height: 50px;
            border: 2px solid $primaryColor;
            font-size: .75rem;
        }
    }

    .container-header {
        position: relative;
        cursor: pointer;
    }

    .menu-header {
        position: relative;
        z-index: 1;
        margin-bottom: 1rem;
        pointer-events: none;

        .chevron {
            display: none;
        }
    }

    .menu .title-menu {
        font-weight: 700;
    }

    .title-menu {
        color: #333;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    .bi-chevron-right {
        position: absolute;
        right: 0;
        top: 50%;
        width: 20px;
        height: 20px;
        -moz-transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear;
        transform: translateY(-50%);
    }

    .bi-chevron-down.down {
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    /* ===== NEW MENU WINDOW ===== */

    .bi-chevron-left {
        position: absolute;
        left: 5px;
        top: 50%;
        transform: rotate(180deg) translateY(50%);
        width: 10px;
        height: 10px;
    }

    .chevron {
        content: "";
        display: block;
        @include maskIconPath('../svg/vector_spritesheet.svg');
        @include maskIconCoord(8, 5, 7);
        background-color: black;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
    }

    .menu-second .title-menu {
        margin-left: 30px;
        cursor: pointer;
    }

    /* nb: specific integration, small columns and needs to have 3 items per row */
    .holder_elements {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        &::after {
            content: "";
            display: block;
            flex-grow: 1;
        }
    }

    .img-wrapper.category {
        display: flex;
        flex-wrap: wrap;
    }

    .img-content,
    .holder_element {

        // A third of max-width minus margins (2*30px = each side)
        width: calc((100% / 3) - 2.2vw);
        margin: 0 1.1vw 1rem;
        position: relative;
        cursor: pointer;

        &.create_your_own {

            // Hide txt for preconfig mode "créez votre pergola" element
            .text-img {
                display: none;
            }
        }

        .img_container {
            position: relative;
        }

        img {
            background-color: white;
            padding: 2px;
        }

        &.no_stock {
            pointer-events: none;
            color: $textlightGrey;

            .img_container::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(255, 255, 255, .6);
            }

            .text-img,
            .content_price {
                color: rgba(0, 0, 0, .2);
            }
        }

        &.selected {
            img {
                border: 2px solid $green;
                padding: 0;
            }
        }
    }

    .img-content:hover {
        img {
            border: 2px solid $green;
            padding: 0;
        }
    }

    .img-content img {
        width: 100%;
        height: auto;
    }

    .text-img {
        padding: 10px 5px 5px;
        font-family: $secondaryFontBold;
        font-weight: 500;
        font-size: .8rem;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    .content_price {
        font-size: 1.25rem;
        font-weight: 500;
        padding: 0 5px;
    }

    .category-wrapper {
        border: 1px solid lightgray;
        border-radius: 5px;
        padding: 10px;
        margin: 5px;
    }

    /* ==== EXPORT THE CONFIG ==== */
    .export-btn {
        position: absolute;
        bottom: 5vh;
        width: 220px;
        padding: 8px;
        margin-left: calc((67vw - 220px) / 2);
        background-color: #363636;
        border: 1px solid #363636;
        color: #fff;
        cursor: pointer;
        border-radius: 5px;
        transition: all 0.5s ease-in-out;
    }

    .export-btn:hover,
    .export-btn:focus {
        transform: scale(0.9);
        font-weight: 600;
        background-color: #fff;
        border: 1px solid #363636;
        color: #363636;
    }

    .popup-container {
        position: absolute;
        top: 10vh;
        left: calc((100vw - 50vw) / 2);
        min-width: 30vw;
        width: 50vw;
        z-index: 10001;
    }

    .popup {
        padding: 0 10px 20px 10px;
        background: #fff;
        box-shadow: 1px 2px 6px grey;
        border-radius: 5px;
    }

    .round {
        position: absolute;
        top: 2vh;
        right: 2vw;
        font-size: 22px;
        border-radius: 50%;
    }

    .close {
        font-size: 20px;
        font-weight: bold;
        text-decoration: none;
        color: rgba(0, 128, 128, 0.8);
        margin: 3px;
        cursor: pointer;
    }

    .close:hover {
        color: #333;
        font-weight: bold;
    }

    .popup-container .dataImg {
        max-height: 200px;
        max-width: 100%;
    }

    .popup-container ul {
        list-style-type: none;
    }

    .popup-container li,
    .popup-container h6 {
        font-size: $fs_txt;
    }

    .popup-container h6 {
        margin-top: 3px;
        margin-bottom: 0;
    }

    .popup-container ul:last-child {
        margin-bottom: 12px;
    }

    /* ==== CONFIG ID POPUP ==== */
    .wrapper-id {
        width: 100px;
    }

    .input-id {
        margin-top: 80px;
        width: 100%;
        height: 150px;
        border: none;
    }

    /* ==== CONFIG ID FORM ==== */
    .form-load-config {
        position: absolute;
        top: 10vh;
        left: 0;
        width: 50vw;
        margin: 0 10px;
        visibility: hidden;
    }

    .form-load-config textarea {
        width: 100%;
        margin-bottom: 10px;
        height: 80px;
    }

    .form-load-config input {
        width: 100%;
    }

    /* ==== RESPONSIVE ==== */
    @media screen and (max-width: 1450px) {
        .title {
            font-size: 1.25rem;
        }
    }

    @media screen and (max-width: 1280px) {
        .title {
            font-size: 1.15rem;
        }

        .menu_title {
            font-size: .875rem;
        }

        .img-content,
        .holder_element {
            margin: 0 2vw 1rem;
            width: calc(50% - 4vw);
        }
    }

    @media screen and (max-width: 1200px) {
        .menu_title {
            font-size: .75rem;
        }
    }

    @media screen and (max-width: 1100px) {

        .container_submits {
            flex-wrap: wrap;
            column-gap: unset;

            .button {
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: 1rem;
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {

        .img-content,
        .holder_element {
            margin: 0 6vw 1rem;
            width: calc(100% - 12vw);
        }
    }

    @media screen and (max-height: 800px) {

        .holder_menus,
        .holder_preconfig_menu {
            height: 82vh;
        }

        .menu-second .menu-content {
            height: 90%;
        }

        .img-content,
        .holder_element {
            width: calc((100% / 3) - 3.2vw);
            margin: 0 1.6vw 1rem;
        }

        @media screen and (max-width: 1300px) {

            .img-content,
            .holder_element {
                margin: 0 3vw 1rem;
                width: calc(50% - 6vw);
            }
        }

        @media screen and (max-width: 1200px) {

            .img-content,
            .holder_element {
                margin: 0 2vw 1rem;
                width: calc(50% - 4vw);
            }
        }

        @media screen and (max-width: 1100px) {
            .container_submits {

                .add_to_bask_btn,
                #addToWishlistButton {
                    min-height: 40px;
                    height: 40px;
                    font-size: .875rem;
                }
            }
        }

        @media screen and (max-width: 1024px) {

            .img-content,
            .holder_element {
                margin: 0 6vw 1rem;
                width: calc(100% - 12vw);
            }
        }
    }
}





/*******************************************/
/*** CONFIGURATEUR PANIER ******************/
/*******************************************/
// TUNNEL COMMON STYLE
.cart.step_2,
.customer.cart,
.checkout,
.in_tunnel {
    .configurator_inspi_list_product {
        position: relative;
        margin: 1rem 0;
        padding: 2rem 0;
        border-top: 1px solid $primaryColor;
        border-bottom: 1px solid $primaryColor;

        .recap_configurator_list_title {
            position: absolute;
            top: 0;
            left: 0;
            transform: translateY(-50%);
            background-color: $white;
            font-size: .875rem;
            padding-right: 1rem;
        }

        .cart_product_title {
            font-weight: 500;

            &.line_product_desc {
                a {
                    font-size: .8125rem;
                }
            }
        }
    }

    .recap_configuration {
        position: relative;
        display: flex;
        align-items: flex-start;

        >.cart_product_pic {
            display: flex;
            align-items: center;
        }

        >.qte_modify {

            .qteWrapper {

                // Label
                .quantity {
                    order: 1;
                }

                // - symbole
                .lessQte {
                    order: 2;
                }

                // quantity number
                .selected_qte {
                    order: 3;
                }

                // + symbole
                .moreQte {
                    order: 4;
                }
            }
        }

        >.pricetag {
            position: absolute;
            right: 0;
            bottom: 0;
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: baseline;

            sup {
                align-self: flex-start;
            }
        }

        .cart_product_modify {
            display: none;
        }

        .title_configuration_inspi {
            margin-bottom: .5rem;
        }
    }

    // Override style for only recap_configuration --> Bigger image container
    .wrapper_panier .right_side #cart_total .bill_line.total_produit_dropdown .recap_configuration .cart_product_pic {
        position: relative;
        width: 130px;
        height: 130px;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: unset;
            width: auto;
            height: 130px;
        }
    }
}

// PAGE PANIER
body.cart.step_1 .wrapper_panier .left_side .cart_product_line.isConfig a.product_del {
    top: 0;
}

.cart.step_1 {
    .warning_basket_product_configuration {
        color: $errorRed;
        font-size: .75rem;
        margin-bottom: 1rem;
    }

    .configurator_inspi_list_product {
        position: relative;
        margin: 1rem 0;
        padding: 2rem 0;
        border-top: 1px solid $primaryColor;
        border-bottom: 1px solid $primaryColor;

        // Hide cart_product_modify on configurator list
        .product_del,
        .cart_product_modify .qteWrapper {
            display: none;
        }


        .recap_configurator_list_title {
            position: absolute;
            top: 0;
            left: 0;
            transform: translateY(-50%);
            background-color: $white;
            font-size: $fs_txt;
            padding-right: 1rem;
        }

        .cart_product_title {
            font-weight: 500;
        }

        .cart_product_line {
            .cart_product_pic {
                width: 95px;
                height: 95px;
            }

            .cart_product_desc {
                border-right: 0;
                width: calc(100% - 128px);
            }

            .wrapper_cart_product_price {
                right: 0;
                min-width: unset;
            }
        }
    }

    .configuration_product {
        width: 100%;

        .dropdown_cart_unit_price {
            display: none;
        }

        .cart_product_modify {
            margin-top: 1rem;
            text-transform: uppercase;
            text-decoration: underline;
            display: inline-block;
            cursor: pointer;
        }

        .recap_configuration {
            position: relative;
            display: flex;
            align-items: center;

            >.cart_product_pic {
                display: flex;
                align-items: center;
            }
        }

        .cart_product_total_price {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-grow: 1;
            text-align: right;
            max-width: unset;
            width: auto;
            padding-right: 7%;
            margin: 0;
        }
    }
}

/*******************************************/
/*** CONFIGURATEUR WISHLIST ***************/
/******************************************/
.cart_main_table.wishlist {
    .configurator_modify {
        position: absolute;
        top: 100%;
        left: 0;
        text-transform: uppercase;
        text-decoration: underline;
        margin-top: 1rem;
    }
}