header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 100;
    transition: .3s ease-in-out;

    &:hover {
        z-index: 150;
    }

    &.sticky {
        position: fixed;
        top: 0;
        bottom: 100%;
        transition: transform 0.6s ease-in-out;
        background-color: $white;
    }
}

.head_top {
    display: flex;
    justify-content: space-between;
    width: 98%;
    max-width: 1690px;
    height: 75px;
    margin: 0 auto;
}

/**
 * HEADER PROMO SLIDER
 */
.promo_bar {
    position: relative;
    z-index: 666;

    .close_promo {
        display: none;
    }

    &.preheader_bandeau {
        z-index: 9;
    }
}

#sliderBandoHeader {
    height: 33px;

    ul li {
        position: relative;
        height: 33px;
        text-align: center;

        div {
            height: 100%;
            line-height: 33px;
            font-size: 13px;
            letter-spacing: 0.05em;
        }

        img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        span {
            font-weight: 500;
        }
    }

    strong {
        font-weight: 500;
    }
}

.preheader_buttons {
    height: 25px;
    background-color: $whitesmoke;
}

.preheader-buttons__container {
    width: 100%;
    height: 25px;
    position: absolute;
    top: 0;
    right: 84px;
    z-index: 2;
    justify-content: flex-end;
    text-transform: uppercase;
    text-decoration: none;
    max-width: fit-content;
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    color: $white;
    backdrop-filter: blur(3px);

    @media screen and (min-width: 1024px) and (max-width: 1366px) {
        right: 34px;
    }

    li {
        margin: 0 25px 0 0;
        white-space: nowrap;

        &.lasthr {
            margin-right: 25px;
        }
    }

    #switchLangTrig {
        padding-right: 24px;
    }
}

.preHeader_item {

    justify-content: center;
    height: 40px;
    padding: 0 0px 0 0;
    width: 100%;
    max-width: 190px;
    color: $cmsPageTitle;
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;

    @media screen and (min-width: 1024px) and (max-width: 1470px) {
        max-width: 140px;
    }

    &.order,
    .help {
        max-width: 185px;
    }
}

.contact {
    max-width: 185px;

    @media screen and (min-width: 1024px) and (max-width: 1470px) {
        max-width: 140px;
    }
}

.preHeader_item-hr {

    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    padding: 0;
}

.lasthr {
    margin-right: 12px;
}

.preheader_lines {

    border: none;
    border-left: 1px solid $grey16;
    height: 100%;
    width: 1px;
}

body.de,
body.en,
body.es {
    .preHeader_item-hr.lang {
        .preheader_lines {
            display: none;
        }
    }
}

/**
 * HEADER LOGO
 */
.hesperide {
    align-self: center;
    max-width: 190px;
    width: 100%;
    height: 100%;
    background: url("../svg/hesperide_2022.svg") left center no-repeat;
    background-size: 100% auto;
    font-size: 0;
    margin-right: 50px;

    @media screen and(max-width: 1300px) {
        max-width: 150px;
        margin-right: 20px;
    }
}

.background_topnav {
    background: $white;
    border-bottom: 1px solid #F8F8F8;
}

/**
 * HEADER SEARCH
 */
.search_wrapper {
    flex: 1;
    z-index: 12;
    display: flex;
    justify-content: center;
    align-items: center;

    .block_top_links {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        line-height: 2;
        padding-top: 2px;
        white-space: nowrap;

        .top_link {
            font-weight: 600;
            font-size: 13px;
            letter-spacing: .02rem;
            color: $black;
            text-transform: uppercase;
            margin: 0 1rem 0 0;

            @media screen and(max-width: 1200px) {
                font-size: 11px;
                margin: 0 .5rem 0 0;
            }

            &:first-child {
                color: $blue;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

#form_recherche {
    position: relative;
    display: block;
    height: 44px;
    width: 100%;
    max-width: 453px;
    margin-bottom: 1em;
    box-sizing: border-box;
    margin-left: 21%;

    @media screen and(max-width: 1600px) {
        margin-left: 6%;
        margin-right: 1rem;
    }

    @media screen and(max-width: 1200px) {
        margin-right: 1rem;
    }

    .results_list {
        top: 44px;
        left: 0;
        width: 100%;
        height: fit-content;
        box-sizing: border-box;

        .search_option {
            max-width: 1360px;
            margin: 0 auto;
        }
    }

    .close_it {
        display: block;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        font-size: 16px;
        color: $secondaryColor;
        opacity: 1;
        z-index: 5;
        cursor: pointer;
        transition: 0.3s all 0.3s ease-in-out;

        &:hover {
            color: $black;
        }
    }

    #search_home {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(180deg, rgba(251, 251, 251, 0) 0%, rgba(251, 251, 251, 0.16) 100%);
        padding: 0;
        opacity: 1;
        color: $primaryColor;
        transition: all 0.3s ease-in-out;
        border: none;
        line-height: 2.1;
        letter-spacing: 0.05em;
        text-indent: 0em !important;
        border-bottom: solid 1px #8A8279;
        font-family: $primaryFont;

        &::-ms-clear {
            display: none;
        }

        &::placeholder {
            font-size: 0.9rem;
            font-style: normal;
            letter-spacing: 0.05em;
            padding-left: 0rem !important;
            line-height: 2.1;
            text-indent: 0em !important;
            text-align: left !important;
            color: #CBCACA;

            @media screen and (max-width: 1190px) {
                padding-left: 0;
                font-size: 0.7rem;
                text-indent: 0em;
            }

            @-moz-document url-prefix() {
                line-height: 3.4;
            }
        }
    }

    .magnifyingBtn {
        background-size: contain;
        background-image: url('../svg/loupe_search.svg');
        width: 25px;
        height: 25px;
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        right: 2%;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0px !important;
    }

    .magnifyingBtnGreen {
        background-size: contain;
        background-image: url('../svg/loupe_search_green.svg');
        width: 25px;
        height: 25px;
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        right: 2%;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0px !important;
    }

    label {
        cursor: pointer;
    }

    &.searching {
        #search_home {
            margin: 0rem 0.2rem 0 0;
            border: none;
        }
    }
}

.options_wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.wrapper_top_links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    .rollover_container {
        height: 100%;

        .hover_content_holder {
            top: 100px;
            right: 0;
            left: unset;
            width: 496px;
            box-sizing: border-box;
            padding: 2rem 3rem;
            position: fixed;
            z-index: 9999;
            background-color: $white;
            transition: all .3s ease-in-out;
            border-top: 0;
        }
    }

    #right_part_account {
        position: relative;
        height: 100%;
        z-index: 11;

        .hover_content_holder {
            top: 100px;
            left: 100%;
            opacity: 0;
            width: 255px;
            height: fit-content;
            box-sizing: border-box;
            padding: 34px 36px;
            position: fixed;
            z-index: -1;
            background-color: $white;
            transition: all .2s ease-in-out;
            border-radius: 0px 0px 10px 10px;
            box-shadow: 0 3px 12px rgba(203, 202, 202, .5);
            border-top: 0;
        }
    }
}

.Header_item-hr {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    padding: 0;
    width: 20px;
}

.Header_item-hr_lang {
    @extend .Header_item-hr;
    margin: 0 2rem;
}

.header_lines {
    border: none;
    border-left: 1px solid #73848c;
    height: 100%;
    width: 1px;
    opacity: 0.3;
}

.headerRollover {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 68px;
    height: 100%;
    box-sizing: border-box;
    padding-top: 14px;

    .hover_content_holder.cart_box {
        opacity: 0;

        &#show_top_wish {
            width: 523px;
        }

        &#show_top_wish,
        &#show_top_cart {
            right: -800px;
            height: 100vh;
            top: 0;
            z-index: 9999;
            transition: all .3s ease-in-out;

            // For larger screens, wider than 1920px
            @media screen and (min-width: 1921px) {
                right: -1350px;
                width: unset;
                padding-right: calc(((100vw - 1920px) / 2) + 30px); // +30px to account for already existing padding
            }
        }
    }

    &#garanty_top {
        width: 120px;
        margin-right: 0.5rem;
    }

    &#distrib_top {
        width: 108px;
    }

    &#right_part_wishlist {
        width: 90px;
    }

    &#right_part_cart {
        .wishlistBtn {
            display: none;
        }
    }

    .account_name_V2 {

        display: none;
    }

    .account_name_V2:hover {

        display: flex;
        background-color: $green;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        z-index: 5;
        cursor: pointer;
    }

    &#right_part_account {
        width: 102px;

        &:hover {
           .account_name_V2 {
                display: block;
                position: absolute;
                background-color: $white;
                background-image: none;
                width: 32px;
                height: 32px;
                z-index: 10;
                top: 12px;

                span {
                    display: flex;
                    background-color: $green;
                    width: 32px;
                    height: 32px;
                    text-transform: uppercase;
                    justify-content: center;
                    align-items: center;
                    border-radius: 25px;

                    &:before {
                        // large white square behind the circle to hide the icon still displaying for guest users
                        // for SW-14117
                        content: ' ';
                        position: absolute;
                        width: 32px;
                        height: 32px;
                        background-color: $white;
                        border-radius:0;
                        z-index: -1;
                    }
                }
            }
        }

        div.account_name_V2:hover~a.bgSprite.bfr.account:before {
            display: none;
        }

        &:hover .hover_content_holder {
            z-index: 11;
            left: calc(100% - 255px);
            opacity: 1;
            transition: all .2s ease-in-out;

            // For resolutions > 1920
            @media screen and(min-width: 1920px) {
                // initial position - rest of total available space
                left: calc((100% - 435px) - calc((100% - 1920px) / 2));
            }
        }
    }

    &#right_part_wishlist,
    &#right_part_cart {

        &:hover,
        &.hover {
            .amounts_bubble {
                background-color: #c4d600;
                color: $black;
            }

            .hover_content_holder.cart_box {
                opacity: 1 !important; // important to handle inline script used in animation
                right: 0;
            }
        }

        .bgSprite.bfr.cross {
            position: absolute;
            top: 0;
            right: 0;
            height: 16px;
            width: 16px;
            font-size: 0;
            cursor: pointer;
            background: url(../svg/lightbox_close.svg) center no-repeat;

            &::before {
                display: none;
            }
        }
    }

    &#right_part_wishlist {
        .cta_add_to_basket {
            position: absolute;
            right: 10px;
            bottom: 25px;
            cursor: pointer;
        }

        .remove_top_wishlist {
            top: 0;
        }

        .bgSprite.bfr.addcart {
            position: relative;

            &::before {
                background-position: 80% 0;
                width: 24px;
                height: 25px;
            }

            img {
                position: absolute;
                top: -16%;
                right: -18%;
            }
        }
    }

    &#account_top {
        width: 102px;

        .accountInitials {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -70%);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background-color: $primaryColor;
            font-size: 10px;
            font-weight: 600;
            color: $white;
            letter-spacing: .1em;
            text-transform: uppercase;
            text-decoration: none;
            transition: background-color .3s ease-in-out;
        }

        &.hover .accountInitials {
            background-color: #848383;
        }
    }

    .rolloverTxt {
        width: 32px;
        height: 32px;

        &:before {
            opacity: 0.7;
            transition: opacity .3s ease-in-out;
        }

        &:after {
            position: absolute;
            top: 49px;
            left: 50%;
            transform: translateX(-50%);
            content: attr(data-label);
            color: #8A8279;
            letter-spacing: .05rem;
            text-transform: uppercase;
            text-decoration: none;
            white-space: nowrap;
            opacity: 0.7;
            transition: opacity .3s ease-in-out;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
        }

        .nbProd {
            position: absolute;
            top: 32px;
            left: 56%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            padding-top: .1rem;
            box-sizing: border-box;
            background-color: $black;
            font-size: 9px;
            font-weight: 800;
            color: $white;
        }
    }

    &:hover {
        .rolloverTxt {
            &:before {
                opacity: 1;
                transition: opacity .3s ease-in-out;
            }

            &:after {
                opacity: 1;
                transition: opacity .3s ease-in-out;
            }
        }

        .garanty {
            &:before {
                width: 32px;
                height: 32px;
                @include bgIconPath('../svg/spritesheet.svg');
                @include bgIconCoord(7, 6, 6)
            }
        }

        .distributors {
            &:before {
                width: 32px;
                height: 32px;
                @include bgIconPath('../svg/spritesheet.svg');
                @include bgIconCoord(8, 6, 6)
            }
        }
    }

    &.hover {
        .rolloverTxt {
            &:before {
                opacity: 1;
                transition: opacity .3s ease-in-out;
            }

            &:after {
                opacity: 1;
                transition: opacity .3s ease-in-out;
            }
        }
    }
}

.hover_content_holder .usrname h1 {
    font-family: $eymenpro;
    font-weight: 300;
    font-size: 17px;
    color: #8A8279;
    margin-bottom: 10px;
    letter-spacing: 0.05em;
}

.wrapper_menu_compte .item {
    font-family: $primaryFont;
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
    margin-top: 14px;
    letter-spacing: 0.05em;

    &.actif {
        font-weight: 600;
    }
}

.line_account {
    width: 100%;
    height: 0.5px;
    background-color: #8a8279;
    border: none;
}

#btn-disconnect {
    width: 100%;
    font-family: $primaryFont;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    text-transform: uppercase;
    color: #fff;
    background-color: #2b2b2b;
    margin-top: 35px;

    &~.loader {
        display: none;
        position: absolute;
        width: 100%;
        height: 51px;
        top: 0;
        left: 0;
        transform: translateY(69%);
    }
}

/**
MAIN SPRITESHEET USING A SINGLE CLASS
exemple :<div class="bgSprite bfr search"></div> will display the search icon in a :before content
 */

.bgSprite {
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
        content: '';
        width: 100%;
        height: 100%;
    }

    @include spriter('../svg/spritesheet.svg', 6, 6, (1: 'garanty', 2: 'distributors', 3: 'wish_head', 4: 'account', 5: 'cart'));
}



.results_list,
.dropdown-menu {
    position: absolute;
    z-index: 5;
    right: 0;
    background: $white;

    li {
        display: flex;
        justify-content: space-between;
        line-height: 2;
        color: $cmsPageTitle;
        cursor: pointer;
        padding: 5px 16px;
        transition: all 0.3s ease-in-out;
        font-weight: 400;

        span {
            text-transform: initial;
        }

        &:hover {
            background: $white;
            color: $blue2;
        }
    }

    &.empty {
        display: none;
    }
}

.close_it {
    display: none;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

/**
 * HEADER LANG
 */

.switchLang {
    position: relative;
    max-width: 80px;
    width: 100%;
    padding: 0.5em 0.5em 0.5em 0;
    margin-top: 0.5em;
    padding-bottom: 1.05em;

    .currentLang {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        font-weight: 600;
        cursor: pointer;
        color: $cmsPageTitle;

        @media screen and(max-width: 1200px) {
            font-size: 11px;
        }

        &:before {
            content: '';
            width: 20px;
            height: 20px;
            margin-right: 6px;
            border-radius: 50px;
            overflow: hidden;
            background-size: cover!important;
        }

        &.flagFR {
            position: relative;

            &:before {
                background: transparent url('../svg/country/fr.svg') center center no-repeat;
            }
        }

        &.flagES {
            position: relative;

            &:before {
                background: transparent url('../svg/country/es.svg') center center no-repeat;
            }
        }

        &.flagEU {
            position: relative;

            &:before {
                background: transparent url('../svg/country/eu.svg') center center no-repeat;
            }
        }

        // keep it just in case
        &.flagEN {
            position: relative;

            &:before {
                background: transparent url('../svg/country/en.svg') center center no-repeat;
            }
        }

        &.flagDE {
            position: relative;

            &:before {
                background: transparent url('../svg/country/de.svg') center center no-repeat;
            }
        }

        &.flagIT {
            position: relative;

            &:before {
                background: transparent url('../svg/country/it.svg') center center no-repeat;
            }
        }
    }



    .switchLangWrapper {
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 5px;
        left: 0em;
        max-width: 80px;
        width: fit-content;
        padding: 0em 0em 0.5em 0.5em;
        transform: translateY(100%);
        border-radius: 15px;
        border-top: none !important;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: 5px 5px 10px -5px rgba(0, 0, 0, 0.25);
        color: $black;
        background: $white;
        border-bottom: none;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        z-index: 1000;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            text-decoration: none;
            text-align: center;
            position: relative;
            width: fit-content;
            padding-bottom: 1.2em;
            opacity: 0.7;
            padding-right: 19px;

            &::before {
                content: '';
                width: 18px;
                height: 18px;
                margin-right: 6px;
                background-size: cover!important;
            }

            &.lang_es {
                &:before {
                    background: transparent url('../svg/country/es.svg') center center no-repeat;
                    border-radius: 50px;
                }

                &:hover {
                    opacity: 1;
                }
            }

            &.lang_fr {
                &:before {
                    background: transparent url('../svg/country/fr.svg') center center no-repeat;
                    border-radius: 50px;
                    ;
                }

                &:hover {
                    opacity: 1;
                }
            }

            &.lang_eu {
                &:before {
                    background: transparent url('../svg/country/eu.svg') center center no-repeat;
                    border-radius: 50px;
                    ;
                }

                &:hover {
                    opacity: 1;
                }
            }

            // keep it just in case
            // &.lang_en {
            //     &:before {
            //         background: transparent url('../svg/country/en.svg') center center no-repeat;
            //         border-radius: 50px;
            //         ;
            //     }

            //     &:hover {
            //         opacity: 1;
            //     }
            // }

            &.lang_de {
                &:before {
                    background: transparent url('../svg/country/de.svg') center center no-repeat;
                    border-radius: 50px;
                    ;
                }
            }

            &.lang_it {
                &:before {
                    background: transparent url('../svg/country/it.svg') center center no-repeat;
                    border-radius: 50px;
                }
            }

            &:hover {
                opacity: 1;
            }
        }

        a:first-child {
            padding-top: 0.5em;
        }

        a:last-child {
            padding-bottom: 0;
        }
    }
}

.activeLangWrap {
    background-color: $white;
    border-radius: 15px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    span {
        color: $black !important;
    }
}

.amounts_bubble {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #8A8279;
    width: 17px;
    height: 17px;
    border-radius: 20px;
    color: #fff;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
    font-size: 11px;

    &.wishlist {
        right: 21px;

        &.basketEmpty {
            display: none;
        }
    }

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: scale(0.9) translate(-50%, -50%);
    }
}

/**
 * HEADER ACCOUNT + CART
 */
.account_wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 5%;

    @media screen and(max-width: 1200px) {
        margin-left: .5rem;
    }
}

.my_account_btn {

    position: relative;

    .login_box {
        display: none;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        box-shadow: 5px 5px 10px -5px rgba(0, 0, 0, 0.25);
        z-index: 20;
    }

    &:hover .login_box {
        display: block;
    }
}

.header_connexion {
    display: inline-block;
    line-height: 3.3;
    padding: 0 0.667em;
    font-size: 0.85em;
    text-transform: uppercase;
    cursor: pointer;

    .picto {
        display: inline-block;
        background: transparent url('../svg/social_media.svg') no-repeat;
        background-position: 25% 37%;
        background-size: 500%;
        width: 50px;
        height: 50px;

        @media screen and (max-width: 1300px) {
            width: 40px;
            height: 40px;
        }
    }
}

#wrapper_infos_client {
    table {
        width: 100%;
        background-color: #fff;
        border-top: none;

        &.content {
            padding-top: 10px;
            width: 265px;
            background-color: #fff;
            text-align: left;
        }

        tr {
            td {
                font-size: 12px;
                color: #333333;

                a {
                    font-size: 12px;
                    margin-left: 0;
                    text-decoration: none;

                    &.top_wishlist {
                        display: block;
                        width: 164px;
                        height: 29px;
                        border: 1px solid #e8e7e5;
                        line-height: 29px;
                        background: #f9f9f9 url("../img/coeur_compte.png") no-repeat 17px 10px;
                        color: #918d86;
                        margin: 11px 0 0 30px;
                        font-size: 11px;
                        font-weight: normal;
                        text-transform: uppercase;
                        letter-spacing: 0.05em;
                        padding-left: 36px;

                        &:hover {
                            color: #ffffff;
                            background: #c4d600 url("../img/coeur_compte_blanc.png") no-repeat 17px 10px;
                        }
                    }

                    &.lien_top {
                        font-size: 11px;
                        color: #918d86;
                        text-decoration: none;
                        text-transform: uppercase;
                        font-weight: 300;
                        padding-left: 32px;
                        letter-spacing: 0.03em;

                        &:hover {
                            color: #000;
                        }
                    }
                }

                &.rose {
                    padding: 20px 31px 1px 32px;
                    font-size: 11px;
                    color: #414040;
                    text-transform: uppercase;
                    letter-spacing: 0.05em;
                    font-weight: 700;
                }
            }
        }
    }

    .topMenuConnexionBottom {
        width: 100%;
        height: 63px;
        background: #fff;
        border-top: 1px solid #f4f7d2;
        color: #ccd641;

        &.deconnect_block {
            width: 100%;
            height: 59px;
            background: #fff;
            border: 0;
            color: #848383;
            font-size: 11px;
            font-weight: 300;
            text-transform: uppercase;
            letter-spacing: 0.06em;
        }

        span {
            text-transform: uppercase;
            font-size: 12px !important;
            font-weight: normal;
            font-style: normal;
        }
    }
}

.mini_cart {
    display: flex;
    align-items: flex-end;

    .amounts {
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        align-items: baseline;
        margin-right: 4px;

        .counter {
            line-height: 1.3;
            font-size: 0.77em;
            text-transform: uppercase;
        }

        .subtotal {
            position: relative;
            width: 82px;
            line-height: 1.15;
            padding-right: 6px;

            .pricetag {
                display: inline-block;
                width: 100%;
                text-align: center;
                border: 1px solid #c4d600;
                border-right: none;
                color: #c4d600;
            }

            &:after {
                position: absolute;
                top: 0;
                right: 0;
                width: 6px;
                height: 100%;
                background-image: url("../img/header/box_arrow_r.png");
                content: '';
            }

            @media screen and (max-width: 1367px) {
                width: 67px;
            }
        }
    }

    #cart_length {
        position: absolute;
        left: 0;
        bottom: 100%;
        text-align: center;
        font-size: .8rem;
        font-weight: 600;
        letter-spacing: 0.65px;

        &:first-letter {
            font-weight: 700;
        }

        &+.subtotal {
            line-height: 1.25;

            #cart_unit {
                font-size: 1.45rem;
            }
        }
    }

    .mini_cart_icn {
        width: 40px;
        height: 40px;

        @media screen and (max-width: 1300px) {
            width: 30px;
            height: 30px;
        }
    }
}

/**
 * HEADER STICKY MODE
 */
.sticky {
    .head_top {
        height: 75px;
    }

    .hesperide {
        height: 28px;
        background-size: auto 100%;
    }

    .search_wrapper {
        align-self: center;
        height: 2em;
        flex-direction: row;

        .block_top_links {
            display: none;
        }
    }

    #form_recherche {
        height: 30px;

        #search_home {
            &::placeholder {
                @-moz-document url-prefix() {
                    line-height: 2.4;
                }
            }
        }
    }

    .mini_cart {
        .amounts {
            align-items: baseline;

            &:after {
                display: block;
                font-size: 1.6em;
                color: #c4d600;
                text-transform: uppercase;
                content: 'Total :';
            }

            .counter {
                font-size: 0.8em;
                color: #898989;

                &:after,
                &:before {
                    content: '(';
                    display: inline;
                    vertical-align: text-bottom;
                }

                &:after {
                    content: ' )';
                }
            }

            .subtotal {
                width: auto;
                min-width: 50px;
                padding: 0 15px 0 5px;

                .pricetag {
                    border: none;
                    text-align: right;
                    color: #373737;
                }

                &:after {
                    display: none;
                }
            }

        }
    }

    .my_account_btn,
    #cart_length,
    .mini_cart_icn {
        display: none;
    }
}

.hover_content_holder.cart_box {
    opacity: 0;

    a:not(.button) {
        color: $primaryColor;
        text-decoration: none;
    }

    .added_to_basket {
        color: $primaryColor;
        margin-bottom: 13px;
        color: black;
        font-size: 13px;
        font-weight: 400;

        &:before {
            display: inline-block;
            vertical-align: middle;
            width: 25px;
            height: 25px;
            background-image: url(../img/octicon_check.png);
            margin: 0 10px 1px 0;
            content: '';
        }
    }

    .wrap_title {
        font-size: 22px;
        font-weight: 800;
        letter-spacing: .2em;
        text-transform: uppercase;
    }

    #cart_list {
        height: calc(100vh - 330px);
        padding-right: 1em;

        .product-link:last-child {
            border-bottom: 0;
        }
    }

    .product-link {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        border-bottom: 1px solid #8A8279;
    }

    .cart_thumb_holder {
        position: relative;

        img {
            width: 100%;
            height: auto;
            vertical-align: bottom;
        }
    }

    .wrap_top_info {
        margin-bottom: .5rem;

        .title {
            font-size: 13px;
            font-weight: 600;
            letter-spacing: .05rem;
            margin-bottom: .2rem;
        }

        .color {
            font-size: 11px;
            font-weight: 400;
            letter-spacing: .05rem;
        }

        .pricetag {
            font-size: 18px;
            font-weight: 600;
            margin-top: .5rem;
        }
    }

    .sub_quantity,
    .sub_taille {
        font-size: 10px;
        font-weight: 600;
        letter-spacing: .05rem;
        line-height: 1.5;

        label {
            font-weight: 400;
        }
    }

    #topBasketToWish,
    #topWishTobasket,
    .remove_top_wishlist {
        position: absolute;
        right: -.2rem;
        font-size: 0;
        cursor: pointer;
    }

    #topBasketToWish,
    #topWishTobasket {
        bottom: 1.1rem;
        width: 30px;
        height: 30px;
    }

    #topWishTobasket {
        background-color: $primaryColor;

        &.loading {
            background: $primaryColor url(../svg/three_dots.svg) no-repeat center;
            background-size: 80% auto;

            &:before {
                display: none;
            }
        }
    }

    .remove_top_wishlist {
        top: 1.1rem;
        width: 16px;
        height: 16px;
    }
}

/////////////// V2 hover panier //////////////////
.firefox {
    #top_wrapper .hover_content_holder.cart_box {
        .cart_product_listing {
            scrollbar-color: $grey3 $grey16;
            scrollbar-width: thin;
        }
    }
}
#top_wrapper .hover_content_holder.cart_box {
    padding: 14px 30px 61px 30px;
    box-shadow: none;

    .addToWishlistButton {
        display: none;
    }


    .os-viewport-native-scrollbars-invisible {
        padding-right: 1rem;
    }

    .titre_panier {
        font-family: $primaryFont;
        font-weight: $medium;
        font-size: 20px;
        line-height: 275%;
        text-transform: uppercase;
        color: $grey3;
        margin-bottom: 10px;
        text-align: center;

        span {
            font-weight: $regular;
            font-size: 14px;
            line-height: 162%;
            text-transform: none;
        }
    }

    .cart_product_listing {
        max-height: calc(100vh - 300px);
        padding-right: 15px;
        overflow-y: auto;
        overflow-x: hidden;

        // For larger screens, wider than 1920px
        @media screen and (min-width: 1921px) {
            min-width: 451px;
        }

        /* Scrollbar webkit */
        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background-color: $grey16;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $grey3;

            &:hover {
                background-color: $grey3;
            }
        }

        &::-moz-scrollbar-button,
        &::-webkit-scrollbar-button {
            width: 0px;
        }

        &::-moz-scrollbar-button:decrement,
        &::-moz-scrollbar-button:increment,
        &::-webkit-scrollbar-button:decrement,
        &::-webkit-scrollbar-button:increment {
            width: 0px;
        }

        li a {
            height: auto;
        }
    }

    .product-link {
        width: 100%;
        height: 100%;
        align-items: stretch;
        margin: 1.31rem 0rem;
        padding: 0px 0px 26px 0px;

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        .addToWishlistButton {
            position: absolute;
            bottom: 26.4px;
            right: 0;
            height: 19px;
            width: 21px;

            img {
                width: 100%;
                height: auto;
            }
        }

        .cart_thumb_holder {
            margin-left: 0%;

            a {
                height: 120px;
                width: 120px;
            }
        }

        .cart_detail_box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 120px;
            max-width: 253px;
            margin-left: 19px;
            position: relative;
        }
    }

    .cart_product_category {
        font-family: $primaryFont;
        font-weight: $semibold;
        font-size: 14px;
        line-height: 17px;
        color: $grey3;
        max-width: 100%;
        margin-top: 0;

        a {
            max-width: 100%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .cart_product_name {
        font-family: $primaryFont;
        font-size: 13px;
        line-height: 17px;
        color: $grey3;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .cart_product_prix {
        font-family: $primaryFont;
        font-weight: $semibold;
        font-size: 30px;
        color: $grey3;
        margin-top: 0;

        .pricetag {
            &.discounted {
                color: $redPromoPrice;
            }
        }
    }

    .f_left {
        float: none;
    }

    .cart_product_size {
        font-family: $primaryFont;
        font-weight: $semibold;
        font-size: 13px;
        color: $grey3;
        text-transform: lowercase;

        &::first-letter {
            text-transform: uppercase;
        }

        span {
            font-weight: normal;
            text-transform: none;

            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    .cart_product_qty {
        font-family: $primaryFont;
        font-weight: $semibold;
        font-size: 13px;
        color: $grey3;
        text-transform: capitalize;

        span {
            font-weight: normal;
            text-transform: none;

            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    .cart_product_num_txt {
        display: flex;
        align-items: center;
        position: relative;
        height: 40px;
        padding-top: 14px;

        .title_total {
            font-family: $primaryFont;
            font-weight: $semibold;
            font-size: 14px;
            line-height: 17px;
            text-transform: uppercase;
            color: $grey3;
            padding-top: 0;
        }

        .panier_total_top {
            position: absolute;
            right: 0%;
            font-family: $primaryFont;
            font-weight: $semibold;
            font-size: 30px;
            line-height: 113%;
            color: $grey3;
            min-width: 250px;
            text-align: right;
            padding-right: 5%;

            sup {
                font-family: $primaryFont;
                font-weight: $semibold;
                font-size: 12px;
                line-height: 113%;
                color: $grey3;
            }
            .cents {
                font-size: 12px;
                position: relative;
                left: -4px;
            }
        }


    }

    .cart_btm_btn {
        margin: 1.375rem auto 1.5rem auto;
        height: 50px;
        width: 244px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $grey3;
        border: 1px solid $grey3;
        transition: background-color .3s ease;

        .btn_container {
            height: 100%;
        }

        .go_to_cart,
        .button.wishlist {
            font-family: $primaryFont;
            font-weight: $regular;
            font-size: 17px;
            text-transform: uppercase;
            letter-spacing: normal;
            line-height: 20px;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

        }

        &.loading {
            background: $white url(../svg/three_dots_black.svg) no-repeat center;
            background-size: 15% auto;

            .go_to_cart {
                font-size: 0;
            }
        }

        &:hover {
            background-color: $white;

            .go_to_cart,
            .button.wishlist {
                color: $grey3;
            }
        }
    }

    .div_poursuite_achat {
        display: flex;
        justify-content: center;

        span {
            margin-right: 13px;
            display: flex;
            align-items: center;

            img {
                height: 8px;
            }
        }

        .poursuivre_achat {
            font-family: $primaryFont;
            font-weight: $medium;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.05em;
            text-decoration-line: underline;
            color: $black;
        }
    }

    .remove_prod {
        position: absolute;
        top: -1%;
        right: 0%;
        cursor: pointer;
        width: 14px;
        height: 14px;

        img {
            width: 100%;
            height: auto;
        }
    }
}

body.it {
    #top_wrapper {
        .wrapper_top_links {
            #right_part_wishlist {
               width: 115px;
            }
        }
    }
}

body.en,
body.de {
    #top_wrapper {
        .wrapper_top_links {
            #right_part_cart {
                width: 90px;

                #cart_top {
                    .amounts_bubble {
                        right: 21px;
                    }
                }
            }
        }
    }
}

body.de {
    #top_wrapper {
        .wrapper_top_links {
            #right_part_wishlist {
                width: 100px;
            }

            #distrib_top {
                .bgSprite.distributors {
                    &:after {
                        @media screen and (min-width: 1024px) and (max-width: 1180px) {
                            white-space: wrap;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}