.main_menu_wrapper {
    position: relative;
    width: 100%;
    height: 33px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    border-bottom: 1px solid #EFEFEF;
}

.main_menu {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-family: $primaryFont;

    .menu_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        z-index: 10;
        height: 2rem;
        column-gap: 0.75rem;

        div#menu_ {
            display: none;
        }

        .main_menu_itm {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: max-content;
            height: 100%;
            text-align: center;

            .block_lnk:after {
                background: none repeat scroll 0 0 transparent;
                bottom: 0;
                content: "";
                display: block;
                height: 2px;
                left: 50%;
                bottom: 0;
                position: absolute;
                background: #c4d600;
                transition: width 0.4s ease 0s, left 0.4s ease 0s, opacity 0.4s ease 0s;
                width: 0;
                opacity: 0;
            }

            &.current .block_lnk:after,
            &:hover .block_lnk:after {
                width: 100%;
                left: 0;
                opacity: 1;
            }

            &:first-child {
                .block_lnk {
                    &:before {
                        display: none;
                    }
                }
            }
        }

        .block_lnk {
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: auto;
            height: 100%;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: .04rem;
            color: #2B2B2B;
            text-transform: uppercase;
            transition: color .2s ease-in-out;

            h2 {
                display: inline;
                font-size: 13px;
                font-weight: 600;
            }

            @media screen and (max-width: 1200px) {
                font-size: 12px;

                h2 {
                    font-size: 12px;
                }
            }
        }
    }
}

#menu_inspiration {
    cursor: pointer;
}

.inspiration_link h2 {
    color: #DEB120;
}

.submenu_wrapper {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    min-height: 260px;
    background-color: #fff;
    background-clip: content-box;
    padding-top: 1px;
    color: #777;
    z-index: 10;
}

.menu_push_container {
    width: 98%;
    max-width: 1690px;
    background-color: #fff;
    padding: 13px 0 15px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    &:not(.swiper-container-horizontal) {
        .push_rayon+.push_rayon {
            margin-left: 3%;
        }
    }
}


.menu_img.push_rayon {
    max-width: fit-content;
}

.submenu {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 98%;
    max-width: 1682px;
    background-color: #fff;
    padding: 0 0 0.9em;
    margin: 0 auto;

    .ss_menu_categ_wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-right: auto;
        margin-left: 0;
        padding-top: 2.15em;
    }

    .menu_img {
        padding-top: 0.9em;
    }

    .ss_cat_name>span {

        font-family: $eymenpro;
        font-weight: $light;
        color: $cmsPageTitle;
        font-size: 17px;
        line-height: 21px;
        letter-spacing: 0.05em;
    }

    .ss_cat {
        width: fit-content;
        margin-bottom: 1.25em;
        box-sizing: border-box;
        margin-right: 69px;
        max-width: 220px;

        >li {
            line-height: 1.5;
            text-align: left;
            font-size: 12.75px;
            color: #777;
            text-transform: none;

            &:first-child {
                line-height: 1;
                margin: 0 0 1em;
                text-align: left;
                font-weight: 600;
                color: #bfcb1c;
                text-transform: none;
            }

            .ss_cat_content a span {
                position: relative;

                &:hover {
                    color: #2dc6c6;

                    &::before {
                        content: "";
                        position: absolute;
                        left: -10px;
                        top: 50%;
                        transform: translateY(-50%);
                        display: block;
                        width: 2px;
                        height: 16px;
                        background-color: #C4D600;
                    }
                }
            }
        }
    }
}

.ss_cat_content {
    font-size: 13px;
    margin-bottom: 0.5rem;
    color: $grey3;
    font-weight: 400;

    li {
        margin-bottom: 0.5rem;
    }
}

.produit_gondole {
    width: 13.35%;
    margin-left: auto;

    .menu_tg_holder {
        width: 100%;

        .rayon_img_container {
            position: relative;
            width: 100%;
            padding-top: 100%;

            .rayon_img_content {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                text-align: center;
            }
        }

        img {
            vertical-align: top;
            width: 100%;
            height: 100%;
        }

        .menu_tg_caption {
            justify-content: space-between;
            align-items: center;
            height: auto;
            line-height: 1;
            margin-top: 5px;
        }

        .menu_tg_txt {
            white-space: normal;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1;
            text-align: left;
            font-size: 13px;
            text-transform: initial;
        }

        .menu_tg_price {
            width: 105px;
            text-align: left;
            font-size: 24px;
            white-space: nowrap;

            sup {
                font-family: inherit;
                font-size: 0.5em;
            }

            .cents {
                font-family: inherit;
                margin-left: -0.5em;
                font-size: 0.5em;
                vertical-align: baseline;
            }
        }
    }
}

.topMenuConnexionBottom {
    width: 290px;
    border-radius: 0 0 5px 5px;
    background-color: #eee;
    vertical-align: middle;
    height: 32px;
}

a.topMenuConnexionBottomInside {
    font-size: 12px;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
}

.topMenuConnexionBottomInside {
    line-height: normal;
    color: #848383;
    font-size: 12px;
    display: block;
    width: 229px;
    margin: 0 auto !important;
    border: 2px solid #C4D600;
    line-height: 35px;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.pt .topMenuConnexionBottomInside {
    padding: 11px 0 11px 15px;
}

.topMenuConnexionBottomInside.btn_deconnect {
    background: none;
    padding: 0 !important;
    width: 199px;
}

.topMenuConnexionBottomInside.btn_deconnect a#btn-disconnect {
    font-size: 11px;
    font-weight: 300;
    color: #848383;
    text-align: center;
    display: block;
    height: 43px;
    line-height: 43px;
    font-style: normal;
}

.loader_connexion {
    font-size: 11px;
    font-weight: 300;
    text-transform: uppercase;
    height: 43px;
    line-height: 43px;
    color: #848383;
}

.loader_connexion img {
    vertical-align: middle;
    margin: 0 0 0 15px;
}

.menu_gondole {
    background-color: #f5f5f5;
    overflow: hidden;
    *width: 180px;
}

.menu_gondole2 {
    background-color: #fff;
    overflow: hidden;
    *width: 180px;
    height: 343px;
}

.menu_gondole2 .pic_gondole {
    height: auto;
    margin-top: 55px;
}

.menu_gondole2 .wrapper_infos_product_home {
    margin-top: 39px;
}

.menu_gondole2:hover,
.menu_gondole:hover {
    overflow: hidden;
    cursor: pointer;
}

.tiret_top {
    display: block;
    float: left;
}

.lien_top {
    width: 97%;
    display: block;
    float: left;
    margin-left: 15px;
}

.TopMenuConnexionOn tr td.orange {
    padding: 5px 0 0 17px;
    text-transform: uppercase;
    color: #666 !important;
    font-weight: bold;
}

.TopMenuConnexionOn a {
    color: #666;
    text-decoration: none;
    font-size: 12px;
}

.TopMenuConnexionOn a:hover {
    color: #666;
    text-decoration: underline;
}

#middle #content {
    width: 940px;
    margin: 0 auto;
    background: transparent url("../img/degrade_content.jpg") repeat-y;
}

div.coup_de_coeur {
    margin: 18px 0 0;
}

#top_menu ul#main_menu .coup_de_coeur img {
    margin: 10px 0 0;
}

#top_menu ul#main_menu .coup_de_coeur .refs_coup_de_coeur {
    width: 153px;
    padding: 10px;
    background-color: #f5f5f5;
    overflow: hidden;
    font-family: arial;
    font-size: 11x;
    color: #333;
}

#top_menu ul#main_menu .coup_de_coeur .refs_coup_de_coeur p.info_coup_coeur {
    color: #666;
}

#top_menu ul#main_menu .coup_de_coeur .refs_coup_de_coeur .info_coup_coeur a {
    font-size: 10px;
    color: #F84992;
    text-decoration: underline;
}

#top_menu ul#main_menu .coup_de_coeur .refs_coup_de_coeur .info_coup_coeur a:hover {
    text-decoration: none;
}

#top_menu ul#main_menu .coup_de_coeur .refs_coup_de_coeur div.prix_produit {
    margin: 0 0 0 18px;
}

#top_menu ul#main_menu .coup_de_coeur .refs_coup_de_coeur sup {
    font-size: 12px;
}

#top_menu ul#main_menu .coup_de_coeur .refs_coup_de_coeur span {
    font-size: 12px;
}

#top_menu ul#main_menu .coup_de_coeur .refs_coup_de_coeur p.prix_barre {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    text-decoration: line-through;
}

#top_menu ul#main_menu .coup_de_coeur .refs_coup_de_coeur p.prix_site {
    font-size: 23px;
    font-weight: bold;
    color: #FF6346;
}

#top_menu ul#main_menu .coup_de_coeur:hover {
    cursor: pointer;
}

.code_promo_menu {
    height: 28px;
    position: absolute;
    right: 60px;
    display: block;
    color: #f90000;
    font-family: arial;
    font-size: 26px;
    font-weight: bold;
    z-index: 1;
}

.code_promo_menu.noel {
    top: 340px;
    right: 66px;
    *top: 337px;
    *right: 64px;
}

.code_promo_menu.meubles {
    right: 75px;
    top: 235px;
    *top: 230px;
}

.img_menus {
    z-index: 0;
}

.lost_password {
    font-family: arial;
    font-size: 10px;
    color: #666;
}

.lost_password:hover {
    text-decoration: none;
}

#slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 938px;
    height: 450px;
}

ul:not(.list-reset-style) {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

ul.header_menu li {
    list-style: none;
    float: left;
    height: 50px;
    font-size: 10px;
    text-transform: uppercase;
    position: relative;
    background: none;
    -webkit-transition: background 400ms ease;
    -moz-transition: background 400ms ease;
    -o-transition: background 400ms ease;
    -ms-transition: background 400ms ease;
    transition: background 400ms ease;
}

.cart_product_num_txt .title_total {
    padding-top: 11px;
    font-family: 'Lato', sans-serif;
    font-size: 11px;
    font-weight: normal;
    letter-spacing: 0;
    color: #000;
}

div.cart_btm_btn {
    margin: 0 0 0 30px;
    text-align: center;
    width: 224px;
    height: 36px;
    border-top: 1px solid #ccd641;
    background: #000000;
}

ul.header_menu li.cart a {
    color: #fff;
}

div.cart_btm_btn a.go_to_cart {
    font-family: 'Lato', sans-serif;
    font-size: 11px;
    margin: 0;
    padding: 0;
    color: #FFF;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 36px;
    letter-spacing: 0.06em;
}

ul.header_menu li a {
    color: #9e9e9e;
    display: block;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    line-height: 49px;
    padding: 0 18px;
    text-decoration: none;
}

ul.cart_product_listing li {
    padding: 16px 6px 15px 13px;
    line-height: normal;
    width: auto;
    height: 67px;
    text-align: left;
    text-transform: none;
    float: none;
    background: none;
}

ul.cart_product_listing li a {
    color: #000;
    display: block;
    height: 100%;
    line-height: normal;
    padding: 0;
}

sup.currency_style {
    font-size: 8px;
}

sup.currency_style.currency_total_basket {
    font-size: 15px;
}

.decimal_sect.panier_centimes {
    font-size: 20px;
}

.decimal_sect {
    font-size: 12px;
}

/* La marque rollover menu */
#wrapper_top_menu #menu_1 .submenu_wrapper {

    #nos_metiers,
    #nos_crea_hesp {
        display: none;
    }

    .ss_menu_categ_wrapper {
        width: 80%;
    }

    .ss_cat {
        height: 142px;
        width: calc((100% / 2) - 1.5rem);
    }

    .img_display {
        width: 285px;
        height: 188px;
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        margin-left: 1rem;
        transition: width .2s ease-in-out;

        @media only screen and (max-width: 1024px) {
            display: none;
        }

        .img_ctn {
            max-width: 246px;
            width: 100%;
            height: 188px;
            background-size: cover;
            background-position: center;
            transition: background 0.15s ease-in-out;
            position: relative;

            .txt_img_menu {
                position: absolute;
                bottom: -40px;
                text-align: center;
                color: black;
            }

            &.img_01 {
                margin-right: 1rem;
            }
        }

        .la_marque_1 {
            background-image: url(../img/la_marques_menu/la_marque_1.jpg);
        }

        .la_marque_2 {
            background-image: url(../img/la_marques_menu/la_marque_2.jpg);
        }

        .nos_metier_1 {
            background-image: url(../img/la_marques_menu/nos_metier_1.jpg);
        }

        .nos_metier_2 {
            background-image: url(../img/la_marques_menu/nos_metier_2.jpg);
        }

        .nos_creation_1 {
            background-image: url(../img/la_marques_menu/nos_creation_1.jpg);
        }

        .nos_creation_2 {
            background-image: url(../img/la_marques_menu/nos_creation_2.jpg);
        }
    }
}

#site_head_wrap {

    #wrapper_top_menu {
        width: 98%;
        max-width: 1690px;
    }

    #menu_inspiration {

        .menu_img {
            display: flex;
            justify-content: flex-start;
            width: 100%;
        }

        .cms_page_content {
            width: 32%;
            margin-right: 2.526vw;

            .previsu_cms_page {
                width: 100%;
            }

            .cms_page_title {
                margin-bottom: 1.375rem;
                font-family: $eymenpro;
                font-weight: $light;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.15em;
                text-transform: uppercase;
                color: $cmsPageTitle;

                @media screen and (max-width: 1250px) {
                    font-size: 12px;
                    letter-spacing: 0;
                }

                @media screen and (max-width: 1550px) {
                    min-height: 13px;
                    letter-spacing: 0;
                }
            }

            img {
                width: 100%;
                max-width: none;
                height: auto;
            }

            .cms_page_previsu_description {
                margin-top: 1.813rem;
                font-family: $primaryFont;
                font-size: $semibold;
                line-height: 16.8px;
                letter-spacing: 5%;
                color: $black;
            }

            .cms_page_previsu_footer {
                font-family: $primaryFont;
                font-weight: $regular;
                font-size: 13px;
                line-height: 16.8px;
                letter-spacing: 0.05em;
                color: $black;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;
            }

            &:last-of-type {
                margin-right: 0vw;
            }
        }
    }
}

.see_all {
    max-width: 90px;
    text-decoration: underline !important;
    text-underline-offset: auto;
    color: #000000 !important;

    &::after {
        display: none !important;
    }
}

.submenu .ss_cat>.see_all>a {
    max-width: 61px;
    line-height: 1.5;
    text-align: left;
    font-size: 13px;
    color: #2B2B2B;
    text-transform: none;
}
