/**
 * GENERAL STYLE (CATEGORY)
 * */
.pricetag {
    display: inline-block;
    font-size: 25px;
    font-weight: 700;
    white-space: nowrap;

    sup,
    .cents,
    .no_cents {
        font-size: .4em;
        font-weight: 500;
    }

    .cents,
    .no_cents {
        margin-left: -.5em;
    }

    &.discounted_price {
        color: $shiraz;

        .price {
            display: block !important;
        }
    }

    +.pricetag {
        position: relative;
        font-size: 20px;
        font-weight: 600;
        padding: 0 2px;
        margin-left: 5px;
        color: #808080;

        &:after {
            display: block;
            position: absolute;
            top: 55%;
            left: 0;
            transform: translateY(-50%);
            width: 100%;
            height: 1px;
            background-color: #808080;
            content: '';
        }
    }

    +div:not(.pricetag) {
        display: inline-block;
        line-height: 1;
        font-size: 12px;
        color: #666;

        &.old-price {
            width: 100%;
            font-size: 11px;
            font-weight: 400;
            letter-spacing: 0;
            white-space: nowrap;
            color: #8a8279;

            &::first-letter {
                text-transform: uppercase;
            }

            .price {
                display: block !important;
            }

            .cents {
                font-size: 9px;
                margin-left: 0;
            }
        }
    }
}

.old-price {
    font-size: 11px;
    line-height: 1;
    white-space: nowrap;
    color: #8a8279;
}

.no_cents {
    display: none !important;
}

/**
 * PRODUCT PAGE
 * */
.product_info .pricetag {
    font-size: 40px;
    line-height: 1;

    +.pricetag {
        margin-left: 10px;
        font-size: 27px;
    }
}