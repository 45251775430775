/**
 * ENTRETIRN PRODUIT
 */
.entretien_content {
    position: relative;
    width: 100%;
    margin: 0 auto;
}

#content_wrapper.entretien_produits {
    margin-top: 2.5rem;
}

.page_conseils_content #wrapper_company .big_title_charte.sub_title,
.page_conseils_content #wrapper_company h2.big_title_charte.sub_title {
    display: block;
    position: absolute;
    top: -72px;
    left: 50%;
    transform: translateX(-50%);
    height: 40px;
    line-height: 40px;
    margin: 0;
    font-size: 26px;
    color: #000;
    letter-spacing: 0;
    z-index: 10;
    padding-bottom: 0;
}

.page_conseils_content .big_title_charte.sub_title:before {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5px;
    background-color: #000;
    content: '';
}

.entretien_descriptif {
    padding: 53px 2%;
    background: #f3f3f3;
    margin-bottom: 0;
    width: 50%;
    display: inline-block;
    box-sizing: border-box;
    font-size: 13px;
    color: #585858;
}

.bloc_liste_entretien {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
}

.bloc_liste_entretien .entretien_descriptif.white_bg {
    background: #fff;
}

.entretien_main_pic {
    width: 52%;
    margin: 0;
    float: left;
    position: relative;
}

span.entretien_main_pic img {
    width: 101.35%;
    position: absolute;
}

.entretien_main_detail {
    width: 37%;
    background: none;
    box-shadow: none;
    padding: 0 6.5% 0 0;
    float: right;
    margin-bottom: 65px;

    .conseil_mobilier_jardin {
        color: $green;
        font-size: 15px;
        padding-top: 30px;
        padding-bottom: 0;
        text-transform: uppercase;
        letter-spacing: .05em;
        font-weight: 400;
        line-height: 17px;
    }
}

.entretien_top_detail {
    padding: 0;
    box-shadow: none;
    font-size: 15px;
    text-align: justify;
    background: none;
    border: none;

    span {
        font-weight: normal;
        color: #585858;
    }
}

.main_box_entretien {
    padding: 8px 0 75px;

    @include queriesWshop(1500px, max) {
        min-height: 400px;
        padding: 8px 0 0;
    }

    @include queriesWshop(1199px, max) {
        min-height: 400px;
    }
}

p.conseil_abri span {
    background: url("../img/icone_abri.jpg") left top no-repeat;
    font-size: 14px;
    font-weight: normal;
    min-height: 44px;
    padding-left: 57px;
}

p.conseil_mobilier_jardin {
    font-size: 15px;
}

p.titre_conseil_entretien {
    font-size: 14px;
    text-transform: uppercase;
    background: url("../img/icone_conseil_entretien.png") 0 0 no-repeat;
}

.bloc_entretien_decrip_left {
    width: 56%;
    margin-right: 1vw;

    .description_matiere {
        margin-top: 22px;
    }
}

.bloc_entretien_decrip_right {
    width: 15.7vw;
    line-height: 17px;
    float: left;

    span {
        font-weight: normal;
    }
}

.matiere_plus {
    padding-top: 20px;
}

.bloc_entretien_decrip_right ul li,
.matiere_plus ul li {
    background: url("../img/bullet_point.png") 0 7px no-repeat;
    font-size: 13px;
    font-weight: normal;
}

.brief_matiere {
    width: 73.9%;
    padding-top: 0;
    display: inline-block;

    h3 {
        font-weight: normal;
        margin: 0;
        text-transform: uppercase;
        font-size: 1.54em;
    }
}

p.conseil_abri,
p.conseil_housse {
    padding: 8px 10px 5px;
}

p.conseil_abri span {
    padding-top: 6px;
}

.entretien_descriptif .txt_normal a {
    color: $green;
    text-decoration: underline;
}

.contenu p,
div.elements {
    width: 68%;
}

.subcontenu .subelements {
    width: 100%;
}

.contenu.open p,
.elements.open {
    padding-top: 14px;
    padding-bottom: 40px;
    border-top: 1px solid $green;
}

.contenu p,
.faq_content,
.faq_content .elements,
.faq_content .elements.open .subelements {
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
}

.contenu.open p,
.faq_content.show,
.faq_content.show .elements .subcontenu.open .subelements,
.faq_content.show .elements.open {
    opacity: 1;
    /*max-height: 500px;*/
    height: 100%;
    display: block;
    padding-bottom: 10px;
    width: 88%;
}

.faq_content.show .elements .subcontenu.open,
.faq_content.show .elements .subcontenu.open .subelements {
    width: 100%;
}

.faq_content li {
    background: url("../img/bullet_vert.png") 2px 6px no-repeat;
    transition: all 0.2s ease-in-out;
    padding-left: 24px;
    line-height: 18px;
    font-size: 15px;
    width: 100%;
    box-sizing: border-box;
}

.faq_content.show li.contenu.open,
.faq_content.show li.subcontenu.open {
    background: url("../img/puce_down.jpg") 0 6px no-repeat;
    transition: all 0.2s ease-in-out;
}

.faq_content li h4,
.faq_content li .h4Title {
    font-size: 15px;

    transition: all 0.5s ease-in-out;
    text-transform: uppercase;
    border: 0;
    cursor: pointer;
}

.faq_content li h4.cont_text,
.faq_content li .h4Title.cont_text {
    cursor: pointer;
}

.faq_content li.open h4,
.faq_content li.open .h4Title {

    color: $green;
    padding-bottom: 11px;
    transition: all 0.2s ease-in-out;
}

.faq_content li h4.conttext_style {

    transition: all 0.2s ease-in-out;
    text-align: left;
}

.faq_content li.open .subelements,
.faq_content li.open h4.conttext_style,
.faq_content li.open .h4Title.conttext_style {

    color: #000;
    transition: all 0.2s ease-in-out;
    text-align: left;
}

.faq_content .contenu .sizing p a {
    text-decoration: underline;
}

.faq_content.show li.contenu.open,
.faq_content.show li.subcontenu.open h4,
.faq_content.show li.subcontenu.open .h4Title {
    color: $green;
    transition: all 0.2s ease-in-out;
}

.faq_content.show li.subcontenu.open {
    transition: all 0.2s ease-in-out;
}

.contenu.open p,
.elements.open,
.elements.open .subcontenu.open .subelements {
    padding-top: 14px;
    padding-bottom: 20px;
    border-top: 1px solid $green;
    color: #000;
}

.picto_faq {
    padding: 19px 0;
    display: block;
    width: 100%;
    float: left;
    cursor: pointer;
}

.picto_faq h3,
.picto_faq .h3Title {
    display: inline-block;
    font-size: 18px;
    padding-left: 43px;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
}

.picto_faq h3.centerize_V,
.picto_faq .h3Tile.centerize_V {
    position: relative;
    top: 12px;
}

.picto_faq.active img.f_right {
    transform: rotate(180deg);
    transition: all 0.5s ease-in-out;
}

.picto_faq.active h3,
.picto_faq.active .h3Tile {
    color: $green;
    transition: all 0.2s ease-in-out;
}

.picto_faq img.f_right {
    transform: rotate(0deg);
    transition: all 0.5s ease-in-out;
}