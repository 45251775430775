#content_wrapper_garantie {
    max-width: 1350px;
    width: 100%;
    height: 100%;
    margin: auto;
}

.bloc_garantie_hesperide {
    max-width: 665px;
    width: 49%;
    position: absolute;
    right: 28px;
    top: 2px;
}

.bloc_garantie_left {
    width: 100%;
}

.liste_garantie {
    display: flex;
}

.img_stickers {

    display: flex;
    justify-content: center;
    align-items: center;
}

.description_garantie_container {

    display: block;
    max-width: 460px;
}

.main_title_garantie {
    margin-bottom: 37px !important;
    position: relative;
    text-transform: uppercase;
    font-size: 25px;

    &::after {
        position: absolute;
        content: "";
        width: 55px;
        height: 3px;
        background-color: #000;
        bottom: -18px;
        left: 0;
    }
}

.entretien_content.garantie_hesperide .btn_ligne {
    display: flex;
    width: 100%;
    justify-content: center;

    .btn_vert {
        position: relative;

        &::before {
            content: '';
            background-color: #ececec;
            width: calc(100% - 21.8vw);
            height: 1px;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }
    }
}

.es .entretien_content.garantie_hesperide .btn_ligne {
    margin-top: 4rem;
}

.entretien_content.garantie_hesperide .btn_ligne .ligne {
    width: 74.3%;
    background: url("../img/ligne.jpg") left top repeat-x;
    height: 64px;
    display: block;
}

.entretien_content.garantie_hesperide .btn_ligne a.btn_activation_garantie {
    background: $green;
    border: $green solid 1px;
    color: $white;
    text-align: center;
    width: 16.4vw;
    height: 50px;
    position: relative;
    line-height: 52px;
    text-indent: 0;
    font-size: .9vw;
    font-weight: 300;

    @media screen and(max-width: 1024px) {
        font-size: 0.9vw;
    }
}

.entretien_content.garantie_hesperide .btn_ligne a.btn_activation_garantie:hover {
    background: $white;
    color: $black;
    border: $black solid 1px;
}

.garantie_hesperide .liste_garantie {
    margin-top: 0;
}

.garantie_hesperide .liste_garantie {
    border-top: 1px solid $green;
    padding: 25.5px 0 0 0;
}

.es .garantie_hesperide .liste_garantie {
    padding: 30px 0 40px;
}

.garantie_hesperide span.picto_garantie {
    width: 154px;
    max-height: 65px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 1170px) {
        width: 119px;
    }
}

.garantie_hesperide span.picto_garantie img {
    width: 49%;
    max-width: 68px;
}

.garantie_hesperide span.picto_garantie.es_picto img {
    width: 60%;
    max-width: 83px;
}

.garantie_hesperide .liste_garantie ul li.remplacement_gratuit span.picto_garantie,
.garantie_hesperide .liste_garantie ul li.service_consommateur_dispo span.picto_garantie {
    padding-top: 0;
}

.garantie_hesperide .shadow_box_container {
    padding-left: 0;
}

.garantie_hesperide .bloc_engagement_hesperide {
    max-width: 627px;
    width: 49%;

    .garantie_auto {
        font-size: 11px;
        line-height: 13px;
        margin-top: 2.4rem;
        color: $black;
    }
}

.garantie_hesperide div.description_garantie {
    width: 100%;
    word-spacing: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px !important;
    background: 0 0;
    ul{
        margin-left: 20px;
        margin-bottom: 20px;
        li{
            list-style: disc;
            padding-bottom: 3px;
        }
    }
}

.garantie_hesperide p.description_garantie a {
    text-decoration: none;
}

.garantie_hesperide li:first-child div.description_garantie,
.garantie_hesperide li:last-child div.description_garantie {
    padding: 18px 0;
}

.garantie_hesperide li:nth-child(2) div.description_garantie {
    padding: 11px 0;
}

.garantie_hesperide p {
    font-size: 15px;
}

.garantie_hesperide strong a {
    position: relative;
}

.garantie_hesperide strong a:before {
    position: absolute;
    content: '';
    width: 8px;
    height: 10px;
    bottom: 1px;
    left: 150px;
}

.garantie_hesperide .shadow_holder_box {
    position: relative;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.details_garantie_hesperide {
    top: -95px;
    left: 26px;
}

.details_garantie_hesperide:first-child {
    margin-right: 0rem;
}

.details_garantie_hesperide img {
    width: 100%;
}

.page_garantie_content .details_conditions .fond_grey p {
    font-size: 13px;
    padding-bottom: 18px;
}

.page_garantie_content .details_conditions p {
    line-height: 16px !important;
}

.page_garantie_content .details_conditions sup {
    font-size: 10px;
    position: relative;
    top: 2px;
}

.page_garantie_content #wrapper_company .details_conditions .big_title_charte.sub_title {
    color: $grey3;
    letter-spacing: 0;
    font-size: 14px;
    margin-top: 0;
    word-spacing: 2px;
    margin-bottom: 16px;
    padding-top: 38px;
    padding-bottom: 0;
    &.nopadding{
        padding-top: 0;
    }

}

.page_garantie_content #wrapper_company .details_conditions h3,
.page_garantie_content #wrapper_company .details_conditions .h3Title {
    color: $grey3;
    text-transform: uppercase;

    background: none;
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 400;
    margin-top: 0;

}


.page_garantie_content .details_conditions h4,
#middle_box_content.page_garantie_content .details_conditions .h4Title {
    text-transform: uppercase;
    color: #000;
    padding-bottom: 13px !important;
    padding-top: 5px;
    font-size: 15px;
}

.page_garantie_content .details_conditions strong {
    color: $green;
    text-transform: uppercase;
}

.details_conditions {
    text-align: left;
    margin-top: 0px;

    .fond_grey {
        padding: 0 3% 0% 3%;
        background: #f7f7f7;
    }

    .leftPadding{ padding-left: 5px;}

    .noPaddingbottom{ padding-bottom: 0!important;}

    .pbottom_30{ padding-bottom: 30px!important;}


}

.description_garantie strong {
    font-size: 14px;
    font-weight: 700;
}

.subtitlegarantie {
    color: $green;
    letter-spacing: 0;
    font-size: 17px;
    word-spacing: 2px;
    font-weight: 600;
    margin-top: 10px;
}

.es .subtitlegarantie {
    margin-top: 25px;
}

.voirdetail {
    color: $black;
    font-size: 11px;
}

.detail_engagement {
    padding: 0;
    box-shadow: none;
}

#wrapper_company .detail_engagement p.txt_garantie_intro {
    font-size: 15px;
    line-height: 18px;
    padding: 0px 0 23.5px;
    width: 100%;
    background: none;
}

.es #wrapper_company .detail_engagement p.txt_garantie_intro {
    padding: 15px 0 35px;
}

.txt_garantie_intro sup {
    position: relative;
    top: 6px;
    font-size: 15px;
    left: 3px;
    padding-right: 2px;
}

.bloc_activation_garantie {
    margin: 0;
    width: 100%;
    display: inline-block;
}

.top_bloc_activation_garantie {
    width: 45%;
    margin-top: 15px;
}

.cgv_V2 a, .fond_grey p a {
    color: $grey3 !important;
    text-decoration: underline;
}