#footer_wrapper {
    .footer_rassu_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 60px 1rem 60px;

        .garanty_icon {
            &:after {
                background: url(../img/footer/garanty_icon.svg) no-repeat;
                content: '';
                position: absolute;
                top: 0rem;
                left: 1.5rem;
                width: 65px;
                height: 65px;

                @media screen and(max-width: 1024px) {
                    left: 1rem;
                }
            }
        }

        .client_service_icon {
            &:after {
                background: url(../img/footer/client_help_icon.svg) no-repeat;
                content: '';
                position: absolute;
                top: 0rem;
                left: 1.5rem;
                width: 65px;
                height: 65px;

                @media screen and(max-width: 1024px) {
                    top: -0.5rem;
                    left: 0.5rem;
                }
            }
        }

        .rassu_sprite {
            &:after {
                content: '';
                position: absolute;
                top: -0.5rem;
                left: 0;
                width: 65px;
                height: 65px;
                mask-image: url(../img/footer/rassu_spritesheet.svg);
                mask-size: 200% auto;
                mask-position: 0 100%;
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0rem;
                left: 0;
                width: 65px;
                height: 65px;
            }

            &.paiement {
                &:after {
                    @include maskIconCoord(1, 2, 2);
                }

                &.afternoel:after {
                    @include bgIconPath('../img/footer/rassu_spritesheet.svg');
                    @include bgIconCoord(1, 2, 2);
                }
            }

            &.paiement.noel:after,
            &.paiement.picto:after {
                @include bgIconPath('../img/footer/rassu_spritesheet.svg');
                @include bgIconCoord(1, 2, 2);
                display: none;
            }

            &.paiement.noel:before {
                background-image: url(../img/footer/liv_xmas.jpg);
            }

            &.paiement.picto:before {
                background: url(../img/footer/rapid_delivery_icon.svg) no-repeat;

                @media screen and(max-width: 1024px) {
                    left: 0.5rem;
                }
            }

            &.paiement.picto.avantnoel:before {
                background-color: $xmasgreen;
            }

            &.paiement.delivery:before {
                mask-image: url(../img/footer/Fichier_1.svg);
                mask-size: 100% auto;
                width: 50px;
                height: 50px;
                top: -0.5rem;
            }

            &.livraison:after {
                width: 75px;
                height: 75px;
                @include maskIconCoord(2, 2, 2);
                top: -0.8rem;
                left: -1.5rem;
            }

            &.livraison.noel2:after,
            &.livraison.picto2:after {
                width: 75px;
                height: 75px;
                @include maskIconCoord(2, 2, 2);
                top: -0.8rem;
                display: none;
            }

            &.livraison.noel2:before {
                background-image: url(../img/footer/retour_picto.jpg);
            }

            &.livraison.picto2:before {
                background-image: url(../img/footer/retour_facile_3.svg);
                left: -0.5rem;
                top: 0;
                background-repeat: no-repeat;

                @media screen and(max-width: 1024px) {
                    top: -0.5rem;
                }
            }

            &.garantie {
                padding-left: 67px;

                &:after {
                    @include maskIconCoord(4, 2, 2);
                    left: -0.1rem;
                }
            }

            &.service {
                &:after {
                    @include maskIconCoord(3, 2, 2);
                }

                &.afternoel:after {
                    background-color: $green;
                }
            }

        }

        .rassu_sprite.noel_colour {
            &:after {
                content: '';
                position: absolute;
                top: -0.5rem;
                left: 0;
                width: 65px;
                height: 65px;
                mask-image: url(../img/footer/rassu_spritesheet.svg);
                background-color: $xmasgreen;
                mask-size: 200% auto;
                mask-position: 0 100%;
            }

            &.garantie {
                &:after {
                    @include maskIconCoord(4, 2, 2);
                }
            }
        }

        .rassu_sprite {
            &.afternoel:after {
                background-color: $green;
            }
        }

        .rassurance {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            max-width: 1500px;
            margin: 0 auto;

            .bloc.paiement.picto {
                @media screen and(max-width: 1280px) {
                    padding-left: 90px !important;

                    &.livraison {
                        padding-left: 85px;
                    }
                }
            }

            .bloc {
                position: relative;
                padding-left: 85px;
                margin-right: 1rem;

                &.livraison {
                    padding-left: 85px;
                }

                @media screen and(max-width: 1280px) {
                    padding-left: 65px;

                    &.livraison {
                        padding-left: 70px;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }

                img {
                    margin-top: 6px;
                    width: auto;
                    height: 18px;

                    &.reassu_alma {
                        height: 15px;
                    }
                }

                .title,
                .title h2,
                .title h3 {
                    font-size: 22px;
                    text-transform: uppercase;
                    font-weight: 600;
                    text-align: center;

                    @media screen and(max-width: 1024px) {
                        font-size: 16px;
                    }

                    &.title_secure {
                        text-align: left;
                    }
                }

                p {
                    max-width: 228px;
                    line-height: 1.2;
                    margin-top: 1px;
                    font-size: 13px;
                    font-weight: 400;
                    text-align: center;

                    &.nous_contacter {
                        &.close {
                            max-width: 260px;
                        }
                    }

                    .cout_appel {
                        font-size: 9px;

                        &.close {
                            font-size: 11px;
                        }
                    }

                }

                .text_secure {
                    text-align: left;
                }

                .mini,
                .nous_contacter {
                    span {
                        position: relative;
                        color: #999999;

                        &:after {
                            content: '';
                            width: 100%;
                            height: 1px;
                            background-color: $contactGrey;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                        }
                    }
                }

                .attention_paiement_virement {
                    font-family: Tahoma, serif;
                    font-size: 0.55em;
                }
            }

            .bloc.paiement.picto {
                padding-left: 82px;
            }
        }
    }


    #footer_about {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: auto auto;
        row-gap: 20px;
        column-gap: 80px;
        align-items: flex-start;
        justify-content: center;

        background-color: $lightGrey3;
        padding-top: 20px;
        padding-bottom: 20px;


        #favourite_brand_fr {
            grid-column: 1 / 2;
            grid-row: 1 / 2;


            @at-root body:not(.fr) & {
                grid-row: 1 / -1;
            }

            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            img {
                object-fit: contain;
            }
        }

        .capital_palmares {
            grid-column: 1 / 2;
            grid-row: 2 / -1;
        }

        .verified_reviews {
            grid-column: 3 / -1;
            grid-row: 1 / -1;

            @at-root body:not(.fr) & {
                grid-row: 1 / 2;
            }

            height: 100%;
            justify-content: center;
            display: flex;
            align-items: center;
            text-align: center;

            & > a {
                width: 120px;

                @media only screen and (max-width: 1160px) {
                    width: 80px;
                }
            }
        }

        .evaluation {
            grid-column: 3 / -1;
            grid-row: 1 / -1;

            .wrap_rating {
                padding: 0 16px;
            }
        }

        #footer_about_wrapper {
            grid-column: 2 / 3;
            grid-row: 1 / -1;

            display: flex;
            flex-direction: row;
            gap: 80px;

            .title {
                display: block;
                color: $darkJungle;
                font-family: $primaryFont;
                font-size: 16px;
                font-weight: 600;
                line-height: 21px;
                margin-bottom: 12px;
            }
            .satellites,
            .satelittes {
                color: $monsoon;
                font-family: $primaryFont;
                font-size: 13px;
                font-weight: 400;
                line-height: 25px;
            }
        }
    }

    .footer_partner_signup {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 90px;
        padding-top: 30px;
        padding-bottom: 30px;

        .logo_rolandgarros > a {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 35px;

            hr {
                width: 2px;
                height: 110px;
                background-color: $grey94;
                border: none;
            }

        }

        .home-module-title {
            display: none;
        }

        .w-newsletter-form {
            display: flex;
            justify-content: space-between;
            width: 344px;
            margin-top: 20px;


            .w-form-line {
                margin-bottom: 0;
                width: calc(100% - 58px);

                &.w-submit {
                    position: relative;
                    width: 48px;
                    height: 48px;
                }
            }

            .w-input-element:not([type='radio']):not([type='checkbox']),
            input[type='email'].w-input-element {
                height: 44px;
                border: 1px solid $grey8;
                border-radius: 0;
            }

            .w-submit-button,
            button.w-submit-button {
                height: 44px;
                width: 44px;
                background-color: $white;
                border: 1px solid $grey8;
                color: $black;
                font-family: $primaryFont;
                font-weight: 600;
                display: flex;
                justify-content: center;

                &.w-loader {
                    background: $grey8 url(../svg/three_dots.svg) center no-repeat;
                    background-size: 50% auto;
                }
            }

            .w-has-error:not(.w-dropdown) .w-input-element:not([type='radio']):not([type='checkbox']),
            .w-has-error:not(.w-dropdown) input[type='email'].w-input-element {
                border: 1px solid $errorRed;
                box-shadow: none;
            }

            .w-input-note.w-input-error {
                color: $errorRed;
            }
        }

        .copy_social_media {
            margin-top: 1.5rem;

            .social_btn {
                width: 35px;
                height: 35px;
                margin-right: 20px;
                @include bgIconPath("../svg/social_media.svg");

                &.facebook {
                    @include bgIconCoord(1, 5, 2);
                }

                &.instagram {
                    @include bgIconCoord(2, 5, 2);
                }

                &.youtube {
                    @include bgIconCoord(3, 5, 2);
                }

                &.pinterest {
                    @include bgIconCoord(5, 5, 2);
                }
            }
        }
    }

    .footer_products_sections {
        display: flex;
        width: 100%;
        padding: 30px 0;
        border-top: 1px solid $grey16;

        .swiper-container {
            width: 100%;
            max-width: 100%;
            overflow: hidden;
            margin: 0 30px;
        }

        .wrapper {
            display: flex;
            justify-content: space-between;
            width: 98%;
            max-width: 1487px;
            margin: 0 auto;
            height: fit-content;

            ul {
                width: 170px !important;
                box-sizing: content-box;
            }
        }

        .product_section_header {
            margin-bottom: 12px;

            color: $darkJungle;
            font-family: $primaryFont;
            font-size: 16px;
            font-weight: 600;
            line-height: 21px;
            cursor: pointer;
        }

        li:not(.product_section_header) {
            margin-bottom: 12px;

            a {
                color: $monsoon;
                text-decoration: none;
                font-family: $primaryFont;
                font-size: 13px;
                font-weight: 400;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .footer_products_sections_prev,
        .footer_products_sections_next,
        .footer_products_sections_progress {
            display: none;
        }
    }

    #footer_payment_methods {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $lightGrey3;
        color: $darkJungle;
        padding: 20px 60px 15px 60px;
        gap: 20px;


        .payment_header {
            font-family: $primaryFont;
            font-size: 16px;
            font-weight: 600;
            line-height: 21px;
        }

        .payment_row {
            display: flex;
            flex-direction: row;
            justify-items: space-between;
            align-items: center;
            gap: 12px;

            .bank_picto {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                border: 1px solid $cmsPageTitle;
                width: 44px;
                height: 30px;


                &.bank_picto_cb {
                    img {
                        // border radius depending on the padding and border radius of the .bank_picto parent
                        // so outer border radius (8px) minus the corresponding border thickness (1px) and the padding (3px)
                        border-radius: calc(8px - 1px - 3px);
                    }
                }

                &.bank_picto_alma {
                    display: flex;
                    flex-direction: row;
                    gap: 5px;
                    border-color: $almaOrange;
                    background-color: $almaOrange;;
                    width: 81px;
                }

                &.bank_picto_bridge {
                    width: 64px;
                }
            }
        }

    }

    .legal {
        display: flex;
        align-items: center;
        background-color: $cmsPageTitle;
        color: $white;
        height: 80px;
        width: 100%;
        font-family: $primaryFont;
        font-weight: 500;
        font-size: 13px;
        line-height: 21px;

        .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 98%;
            max-width: 1679px;
            margin: 0 auto;

        }

        .links {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 50%;

            ul {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                justify-content: flex-start;
                flex-wrap: wrap;

                li:not(:last-child)::after {
                    content: '-';
                    margin: 0 3px;
                }
            }

            #mention_legales_popup {
                a {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }


        .meta {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            height: fit-content;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 5px;

                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;

                    &:after {
                        content: "";
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-image: url(../svg/wshop.svg);
                        background-size: contain;
                        background-repeat: no-repeat;
                        width: 50px;
                        height: 16px;
                        margin-left: 6px;
                    }
                }
            }
        }
    }
}

body.de,
body.en,
body.es {
    .evaluation {
        grid-row: 2 / -1!important;
    }
}