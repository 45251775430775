body.category,
body.search_in {
    * {
        box-sizing: border-box;
    }

    #site_head_wrap {
        padding-bottom: 38px;

        &.sticky.on {
            padding-bottom: 0;
        }
    }

    .sticky .breadcrumbs_wrapper {
        display: block;
    }


    .page_wrapper {
        #site_global_wrap {
            width: 100vw;
            max-width: 100%;

            .page_rayon {
                .bandeau_categ {
                    position: relative;
                    margin-top: 10px;
                    min-height: 300px;
                    background-size: cover;
                    background-position: right bottom;
                    background-repeat: no-repeat;
                    display: flex;
                    align-items: center;

                    img {
                        width: 100%;
                        height: auto;
                    }

                    .text_wrapper {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        padding-left: 5.786%;
                        font-family: $eymenpro;
                        font-size: 16px;
                        line-height: 20.18px;
                        font-weight: 300;
                        color: $cmsPageTitle;
                        width: calc(100% - 3rem);
                        max-width: 550px;

                        @media only screen and (max-width: 1680px) {
                            // the padding below prevents title element accents (generated via the meta tag tool) from displaying beyond 1680px
                            padding: 1rem;
                            margin-left: 1rem;
                            background-color: rgba(255, 255, 255, 0.85);
                            max-width: 460px;
                        }

                        .seo_subtitle {
                            font-family: $eymenpro;
                            font-style: normal;
                            font-weight: $light;
                            font-size: 40px;
                            line-height: 50px;
                            text-align: center;
                            text-transform: uppercase;
                            letter-spacing: 0.05em;
                            color: $grey3;
                            position: relative;
                            bottom: 6px;
                            font-stretch: condensed;
                        }

                        .seo_text {
                            font-family: $eymenpro;
                            font-style: normal;
                            font-weight: $light;
                            font-size: 16px;
                            line-height: 20px;
                            text-align: center;
                            padding: 0.25rem 3.5rem 0;
                            font-stretch: condensed;
                        }
                    }
                }

                .selected_options {
                    width: calc(100% - 70px);
                    padding: 28px 0;
                    border-bottom: 1px solid $lightGreyBorder;
                    margin: 0 auto;

                    .bandeau_menu_filter {
                        width: 100%;
                        display: flex;
                        justify-content: center;

                        .form_itm {
                            display: inline-flex;
                            width: 120px;

                            .menuitem_name {
                                padding-bottom: 4px;
                            }

                            &:not(:last-child) {
                                margin-right: 45px;
                            }

                            a {
                                display: inline-flex;
                                flex-direction: column;
                                align-items: center;
                                width: 120px;

                                .img_wrapper {
                                    width: 100px;
                                    height: 95px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    img {
                                        display: block;
                                        width: 100%;
                                        height: auto;
                                        transition: all .2s ease-in-out;
                                    }
                                }

                                .menuitem_name {
                                    text-align: center;
                                    overflow: hidden;
                                    position: relative;
                                    z-index: 2;

                                    .txt {
                                        font-family: $eymenpro;
                                        font-size: 16px;
                                        line-height: 20.18px;
                                        font-weight: 300;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 3;
                                        -webkit-box-orient: vertical;
                                        overflow: hidden;
                                        white-space: pre-wrap;
                                    }
                                }

                                &:hover {
                                    .img_wrapper {
                                        img {
                                            transform: scale(0.9);
                                            transition: all .5s ease-in-out;
                                        }
                                    }
                                }
                            }

                            &.current {
                                .menuitem_name {
                                    border-bottom: 2px solid $green;
                                }
                            }
                        }
                    }

                    .swiper_cate_filtre {
                        width: 100%;

                        .district_name {
                            display: block !important;
                            font-family: $eymenpro;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 40px;
                            line-height: 50px;
                            display: flex;
                            align-items: center;
                            text-align: center;
                            letter-spacing: 0.05em;
                            text-transform: uppercase;
                            color: $cmsPageTitle;
                        }

                        .bandeau_menu_filter {
                            justify-content: inherit;
                        }
                    }

                    .swiper_n_btn_wrapper {
                        position: relative;
                        width: 880px;
                        max-width: 100%;
                        margin: 0 auto;
                        padding: 0 60px;

                        .swiper_cate_filtre {
                            .bandeau_menu_filter {
                                display: flex;
                                justify-content: center;
                            }
                        }

                        .swiper_cate_filtre_button_next,
                        .swiper_cate_filtre_button_prev {
                            background-image: url(../svg/spritesheet.svg);
                            background-repeat: no-repeat;
                            background-size: 600% auto;
                            background-position: 20% 40%;
                            width: 46px;
                            height: 28px;
                            position: absolute;
                            left: 0;
                            transform: rotate(180deg);
                            top: 55%;
                            cursor: pointer;

                            &.swiper-button-disabled {
                                opacity: 0.35;
                            }
                        }

                        .swiper_cate_filtre_button_next {
                            left: auto;
                            right: 0;
                            transform: inherit;
                        }

                        &.has_swiper {

                            .swiper_cate_filtre_button_next,
                            .swiper_cate_filtre_button_prev {
                                display: block !important;
                            }

                            .swiper_cate_filtre {
                                .bandeau_menu_filter {
                                    justify-content: inherit;
                                }
                            }
                        }
                    }
                }

                #choix_recherche {
                    display: none;
                }

                #content_obj {
                    width: 100%;

                    #scroll_items {
                        width: 100%;
                    }

                    .district_def,
                    .rayon_desc_summary {
                        width: calc(100% - 80px);
                        max-width: 1680px;
                        font-family: $primaryFont;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 14px;
                        color: $black;
                        margin: 50px auto;

                        h1 {
                            font-family: $primaryFont;
                            font-size: 24px;
                            margin-bottom: 7px;
                        }

                        h2 {
                            font-family: $primaryFont;
                            font-size: 18px;
                            margin-bottom: 5px;
                        }

                        h3 {
                            font-family: $primaryFont;
                            font-size: 14px;
                            margin-bottom: 3px;
                        }

                        ul {
                            list-style: disc;
                            padding-left: 40px;
                            margin-bottom: 5px;

                            li {
                                list-style: disc !important;
                                margin-bottom: 2px;
                            }
                        }

                        a {
                            text-decoration: underline;
                        }

                        strong {
                            a {
                                font-weight: 500;
                            }
                        }
                    }
                }
            }

            .total_articles {
                padding: 26px 40px 16px;
            }
        }
    }

    &.sticky_h {
        .wrapper_nav_rayon {
            .nav_left {
                #catFiltersSwiper {
                    #filter_sticky {
                        height: calc(100vh - 150px);
                        top: 150px;

                        .filter_wrapper_container {
                            height: calc(100vh - 450px);
                        }
                    }
                }
            }
        }

        .breadcrumbs_wrapper {
            display: none;
        }
    }

    .page_recherche {
        .eclat_img {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;

            &.eclat_img_1 {
                top: auto;
                left: auto;
                bottom: 13px;
                right: 11px;
            }
        }
    }
}

.fullpage-loader {
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 300px;
        font-size: 16px;
        font-weight: 500;
    }
}

.items_wrapper.series_special {
    display: block
}

.items_wrapper.series_special:after {
    display: block;
    clear: both;
    content: ''
}

/* Liste produit rayon/recherche */
#list_item {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 35px;
    box-sizing: border-box;

    .item {
        width: 25%;
        position: relative;

        &:not(.img_push):hover {
            border-color: $lightGreyBorder;

            .rolloverimgContainer {
                .block_lnk {
                    img {
                        &+img {
                            opacity: 1;
                            transition: opacity 0.2s ease-in-out;
                        }
                    }
                }
            }

            .couleur_produit,
            .couleur_produit_search {
                opacity: 1;
            }
        }

        @media only screen and (max-width: 1060px) {
            width: 50%;
        }

        // Category page product video
        .wrap_video {
            position: relative;
            display: block;
            margin-bottom: 15px;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            overflow: hidden;

            iframe {
                position: absolute;
                z-index: 0;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                border: 0;
            }
        }

        .rolloverimgContainer,
        .rayon_img_container {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;
            margin-bottom: 21px;
            width: 100%;
            max-width: 440px;
            padding-top: calc(440 / 440 * 100%);

            .overlay {
                display: none;
            }

            .rayon_img_content {
                position: relative;
                display: block;
            }

            >img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
                z-index: 1;

                &+img {
                    opacity: 0;
                    transition: opacity 0.5s ease-in-out;
                    z-index: -1;

                }
            }

            .mark {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;

                img {
                    width: auto;
                    height: auto;
                }
            }

            .eclat_horiz {
                position: absolute;
                bottom: 13px;
                right: 11px;
                z-index: 2;

                img {
                    width: auto;
                    height: auto;
                }
            }

            .couleur_produit,
            .couleur_produit_search {
                display: flex;
                align-items: center;
                position: absolute;
                bottom: 13px;
                left: 15px;
                opacity: 0;
                transition: opacity 0.5s ease-in-out;
                z-index: 3;

                .img_couleur_horiz {
                    display: inline-block;
                    width: 39px;
                    height: 39px;
                    margin-right: 6px;
                    background: $lightGreyBorder;
                    border: 1px solid #e7e7e5;
                    background-size: cover;
                    position: relative;

                    &:hover {
                        &:after {
                            position: absolute;
                            bottom: -5px;
                            left: 0;
                            content: "";
                            display: block;
                            width: 35px;
                            height: 2px;
                            background-color: $cmsPageTitle;
                            transition: all .3s ease;
                        }
                    }
                }

                .nb_products_left {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    padding: 7px;
                    background: rgba(255, 255, 255, 0.85);
                    min-width: 35px;
                    height: 35px;
                    border: 1px solid #8C857C;
                    white-space: nowrap;
                    color: $darkJungle;
                }
            }
        }

        /* recherche */
        .block_lnk {
            position: relative;

            .cover_type_img {
                margin-bottom: 21px;
                position: relative;
                overflow: hidden;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            // Cacher l'éclat "Nouveauté" si l'eclat "Out Of Stock" est présent
            .stock_depleted~.seen_on_tv {
                display: none;
            }

            .seen_on_tv,
            .mark {
                position: absolute;
                top: 0;
                left: 0;
            }


            &:hover {
                .couleur_produit_search {
                    opacity: 1;
                }

                .rolloverimgContainer {
                    >img {
                        z-index: -1;

                        &+img {
                            opacity: 1;
                            transition: opacity 0.5s ease-in-out;
                            z-index: 1;
                        }
                    }
                }
            }
        }

        .iframe_container {
            position: relative;
            height: 0;
            padding-bottom: 100%;
            margin-bottom: 21px;

            iframe,
            .link_product_iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
                border: 0;
                z-index: -1;
            }
        }

        .item_name,
        .item_feat {
            margin-bottom: 4px;
        }

        .wrapper_description {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }

        .wrapper_price,
        .price {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: auto;
            text-align: right;

            .pricetag {
                font-size: 24px;
                font-family: $primaryFont;
                text-align: right;
                display: block;
                width: auto;
                color: $redPromoPrice;

                &:only-child {
                    color: $grey3;
                }

                &+.pricetag {
                    font-size: 16px;
                    color: $monsoon;
                    display: inline-block;
                    width: fit-content;

                    &:after {
                        content: '';
                        height: 1px;
                        background-color: $monsoon;
                        width: calc(100%);
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto;
                    }

                    span {
                        &.cents {
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        /*PUSH*/
        &.img_push {
            .imgWrapper {
                max-width: 440px;
            }

            .block_lnk {
                display: block;
                width: 100%;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        /* Achat express */
        .bloc_add_color {
            display: none !important;
        }

        .ligne_form {
            &.productSizeFieldset {
                display: none !important;
            }
        }

        .wrap_rolloverproduit {
            width: 100%;

            .wrap_rolloverproduit_container {
                form {
                    .rollover_left {
                        .addToWishlistButton {
                            position: absolute;
                            top: 22px;
                            right: 21px;
                            z-index: 3;
                            display: block;

                            span {
                                display: none;
                            }

                            &::before {
                                content: '';
                                width: 20px;
                                height: 20px;
                                background-image: url(../svg/spritesheet.svg);
                                background-size: 600% auto;
                                background-position: 40% 0;
                                display: inline-block;
                            }

                            &.in_wishlist,
                            &.existToWishlistButton {
                                &::before {
                                    background-position: 40% 40%;
                                }
                            }

                            &:hover {
                                &::before {
                                    background-position: 40% 40%;
                                }
                            }
                        }

                        .productColorFieldset {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.wrapperProdVertical {
    padding: 8px;
    border: 1px solid $white;
    margin-bottom: 45px;

    &:hover {
        border-color: $lightGreyBorder;
    }

    .rolloverimgContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        margin-bottom: 21px;
        width: 100%;
        max-width: 440px;
        padding-top: calc(440 / 440 * 100%);

        >img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            z-index: 1;
        }
    }

    // Lazyload logic
    .ill_img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
            opacity: 1;
        }

        &+.ill_img {
            opacity: 0;

            img {
                opacity: 0;
                transition: opacity .3s ease-in-out;
            }
        }
    }

    &:hover {
        .ill_img+.ill_img {
            opacity: 1;

            img {
                opacity: 1;
            }
        }
    }

    .item_name {
        font-family: $primaryFont;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: $darkJungle;
        display: block;
    }

    .item_feat {
        width: 91%;
        font-family: $primaryFont;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: $darkJungle;
        display: inline-block;
    }

    .wrapper_price,
    .price {

        .pricetag {
            font-family: $primaryFont;
            display: block;
            width: auto;
            color: $redPromoPrice;

            &+.pricetag {
                color: $monsoon;
                display: inline-block;

                &:after {
                    content: '';
                    height: 1px;
                    background-color: $monsoon;
                    width: calc(100% - 11px);
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 11px;
                    margin: auto;
                }

                span {
                    &.cents {
                        text-decoration: none;
                    }
                }
            }

            &:only-child {
                color: $grey3;
            }
        }
    }


    .item_ratings {
        .netreviews_reviews_rate {
            color: $jauneAvisV;
            font-size: 17px;

            &+.netreviews_reviews_rate {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 21px;
                color: $darkJungle;
            }
        }
    }
}

/* FILTRES */
.wrapper_nav_rayon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1920px;
    margin: 0 auto;
    padding: 26px 40px 16px;
    box-sizing: border-box;

    .nav_left {
        width: calc(100% - 400px);

        #catFiltersSwiper {

            .filter_name_wrapper {
                display: flex;
                flex-wrap: wrap;

                .filter_wrapper {
                    margin: 0 10px 10px 0;
                    width: auto;
                    padding: 0 !important;

                    @media only screen and (max-width: 1360px) {
                        margin: 0 7px 10px 0;
                    }

                    .filter_name {
                        font-family: $primaryFont;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 40px;
                        text-align: center;
                        color: $darkJungle;
                        display: inline-block;
                        padding: 0 16px;
                        height: 40px;
                        background-color: $lightGrey3;
                        border-radius: 5px;
                        text-transform: none;
                        transition: all ease-in-out 0.5s;
                        width: auto;
                        cursor: pointer;
                        background-image: inherit;

                        @media only screen and (max-width: 1360px) {
                            font-size: 11px;
                            padding: 0 10px;
                        }

                        @media only screen and (max-width: 1240px) {
                            padding: 0 8px;
                        }

                        &:hover {
                            background-color: $lightGreyBorder;
                        }

                        &::after {
                            display: none;
                        }

                        &+.selected_options {
                            display: none;
                        }

                        &.filter_all_popup {
                            .ico {
                                display: inline-block;
                                width: 19px;
                                height: 19px;
                                background-image: url(../svg/spritesheet.svg);
                                background-repeat: no-repeat;
                                background-size: 600% auto;
                                background-position: 60% 20%;
                                margin-right: 6px;
                                position: relative;
                                top: 3px;
                            }
                        }
                    }
                }
            }

            /* POPUP */
            #filter_sticky {
                width: 450px;
                height: calc(100vh - 188px);
                position: fixed;
                right: 0;
                top: 188px;
                background-color: $white;
                padding: 27px 0 29px;
                box-sizing: border-box;
                display: block;
                transform: translateX(100%);
                opacity: 0;
                transition: opacity, transform ease-in-out .35s;
                z-index: -1;

                &.actif {
                    transform: translateX(0%);
                    opacity: 1;
                    z-index: 30;
                }

                .filter_title {
                    margin-bottom: 6px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 86px;
                    padding: 0 24px 0 30px;
                    border-bottom: 1px solid $white1;

                    span {
                        font-family: $eymenpro;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 36px;
                        line-height: 45px;
                        color: $cmsPageTitle;
                        position: relative;
                    }

                    .close_filter {
                        display: inline-block;
                        width: 13px;
                        height: 13px;
                        background-image: url(../svg/spritesheet.svg);
                        background-repeat: no-repeat;
                        background-size: 600% auto;
                        background-position: 80% 20%;
                        margin-right: 6px;
                        position: relative;
                        top: -10px;
                        cursor: pointer;
                    }
                }

                .filter_wrapper_container {
                    padding: 0 40px 0 29px;
                    width: calc(100% - 28px);
                    height: calc(100vh - 478px);
                    overflow: auto;
                    overflow-y: scroll;
                    scrollbar-color: $green $grey16;
                    scrollbar-width: thin;

                    /* Scrollbar webkit */
                    &::-webkit-scrollbar {
                        width: 4px;
                    }

                    &::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 4px $grey16;
                        border-radius: 10px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: $green;
                        border-radius: 10px;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        background: $green;
                    }

                    /* Scrollbar webkit end */

                    .filter_wrapper {
                        padding: 0;
                        margin: 0;

                        .filter_name {
                            font-family: $primaryFont;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 15px;
                            line-height: 30px;
                            letter-spacing: 0.1em;
                            padding: 19px 0 9px;
                            color: $black;
                            text-transform: inherit;
                            width: 100%;
                            position: relative;
                            cursor: pointer;
                            border-bottom: 1px solid $grey16;

                            &:after {
                                content: '+';
                                display: block;
                                position: absolute;
                                bottom: 0;
                                top: 0;
                                right: 0;
                                margin: auto;
                                font-family: $primaryFont;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 24px;
                                line-height: 58px;
                                text-align: center;
                                letter-spacing: 0.1em;
                                color: $black;
                                background-image: inherit;
                                width: 18px;
                                height: 100%;
                            }

                            &.actif {
                                cursor: default;

                                &:after {
                                    content: '-';
                                }
                            }
                        }

                        .selected_options {
                            max-height: 0;
                            overflow: hidden;
                            transition: all ease-in-out 0.5s;
                            padding: 0;
                            width: 100%;
                            border-bottom: none;

                            &.to_check {
                                padding: 0 50px;
                            }

                            &.actif {
                                max-height: inherit;
                                padding: 10px 0;
                                transition: all ease-in-out 0.5s;
                                display: block !important;

                                &.to_check {
                                    padding: 13.5px 50px;
                                }
                            }
                        }

                        .form_itm {
                            &.check:not(.color) {
                                &>input[type=checkbox] {
                                    &+label {
                                        font-family: $primaryFont;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 12px;
                                        line-height: 18px;
                                        color: $darkJungle;
                                        display: flex;
                                        align-items: center;

                                        &:before {
                                            content: '';
                                            width: 15px;
                                            height: 15px;
                                            border: 1px solid $cmsPageTitle;
                                            border-radius: 0;
                                        }
                                    }

                                    &:checked {
                                        &+label {
                                            &:before {
                                                background-image: url(../svg/spritesheet.svg);
                                                background-repeat: no-repeat;
                                                background-size: 650% auto;
                                                background-position: 0% 40%;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        /* PRIX */
                        &#filter_price,
                        &#filter_caract_price {
                            .filter_checkbox_hide.to_hide {
                                height: 0;
                            }

                            .selected_options {
                                &.actif {
                                    padding-top: 30px;
                                    min-height: 80px;

                                    #slider-range {
                                        display: block;
                                    }
                                }

                                #slider-range,
                                .vue-slider {
                                    display: none;
                                }

                                &.price_search {
                                    .vue-slider {
                                        display: block;
                                    }
                                }

                                .vue-slider {
                                    max-width: calc(100% - 4rem);
                                    margin: 0 auto;

                                    .vue-slider-rail {
                                        border-top: 2px solid $nobel;

                                        .vue-slider-process {
                                            border-top: 2px solid $grey3;
                                            top: -2px !important;
                                            height: 2px !important;
                                        }
                                    }

                                    .vue-slider-rail {
                                        .vue-slider-dot-tooltip-inner {
                                            background: $black;
                                            border-color: $black;

                                            .vue-slider-dot-tooltip-text {
                                                position: relative;
                                                cursor: pointer;

                                                &:after {
                                                    content: '€';
                                                    position: relative;
                                                    width: 10px;
                                                    height: 10px;
                                                    display: inline-block;
                                                }
                                            }
                                        }
                                    }

                                    .vue-slider-dot-handle {
                                        cursor: pointer;
                                        width: 100%;
                                        height: 100%;
                                        border-radius: 50%;
                                        background-color: $black;
                                        box-sizing: border-box;
                                        box-shadow: inherit;
                                    }
                                }

                                #slider-range {
                                    max-width: calc(100% - 4rem);
                                    margin: 0 0 0 6%;
                                    padding: 5px 0px;
                                    width: auto;
                                    height: 4px;
                                    background-color: transparent;
                                    border-left: none;
                                    border-bottom: none;
                                    border-right: none;
                                    border-top: 2px solid $nobel;

                                    .ui-slider-range {
                                        top: -2px;
                                        height: 2px;

                                        +span {
                                            span {
                                                left: -0.8rem;
                                            }

                                            +span {
                                                span {
                                                    left: -1rem;
                                                }
                                            }
                                        }
                                    }

                                    .ui-slider-handle {
                                        top: -6px;
                                        width: 10px;
                                        height: 10px;
                                        background-color: $black;

                                        .handler_price {
                                            position: relative;
                                            bottom: -1.5rem;
                                            border: 1px solid $black;
                                            border-radius: 20%;
                                            padding: 0.1rem;
                                            background-color: $black;
                                            color: white;
                                            cursor: pointer;
                                            font-size: 14px;
                                            white-space: nowrap;
                                            text-align: center;
                                            font-family: 'Fira Sans', sans-serif;
                                            font-weight: normal;

                                            &::after {
                                                content: "";
                                                position: absolute;
                                                bottom: 100%;
                                                left: 50%;
                                                transform: translate(-50%, 0);
                                                height: 0;
                                                width: 0;
                                                border-color: transparent;
                                                border-bottom-color: transparent;
                                                border-style: solid;
                                                border-width: 5px;
                                                border-bottom-color: inherit;
                                            }
                                        }
                                    }
                                }

                                .price_input_wrapper {
                                    padding-top: 0;
                                    width: 100%;
                                    height: 162px;

                                    .price_input {
                                        display: inline-block;
                                        position: relative;
                                        left: -13px;

                                        .price_input_part {
                                            height: 51px;

                                            &:after {
                                                display: none !important;
                                            }

                                            label {
                                                font-family: $primaryFont;
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: 12px;
                                                line-height: 18px;
                                                color: $cmsPageTitle;
                                                text-align: center;
                                                margin-bottom: 10px;
                                                position: relative;
                                                width: 100%;
                                                bottom: inherit;
                                                display: block;
                                            }

                                            input {
                                                height: 50px;
                                                border-radius: 4px;
                                                border: 1px solid $cmsPageTitle;
                                                width: 72px;
                                                font-family: $primaryFont;
                                                font-style: normal;
                                                font-weight: 700;
                                                font-size: 16px;
                                                line-height: 31px;
                                                text-align: center;
                                                letter-spacing: 0.1em;
                                                color: $black;
                                            }

                                            &#prix_min {
                                                input {
                                                    width: 90px;
                                                }
                                            }

                                            &.sep {
                                                margin: 0 9px;
                                            }
                                        }
                                    }

                                    .btn_wrapper {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        position: relative;
                                        left: -8px;

                                        .price_input_reset {
                                            font-family: $primaryFont;
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 12px;
                                            line-height: 18px;
                                            text-align: center;
                                            text-decoration-line: underline;
                                            color: $darkJungle;
                                            height: auto;
                                            background-color: $white;
                                            margin-right: 43px;
                                            height: auto;
                                            text-indent: inherit;
                                            cursor: pointer;
                                        }

                                        .price_input_validation {
                                            padding: 5px 10px;
                                            font-family: $primaryFont;
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 12px;
                                            line-height: 18px;
                                            text-align: center;
                                            color: $white;
                                            display: inline-block;
                                            background: $middleGrey;
                                            height: auto;
                                            text-indent: inherit;
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }

                        /* COLOR */
                        &#filter_color {
                            .selected_options {
                                padding: 0 34px;
                                display: flex;
                                justify-content: space-between;
                                flex-wrap: wrap;

                                &.actif {
                                    padding: 16px 34px;
                                    display: flex !important;
                                }

                                .form_itm {
                                    position: relative;
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 5px;
                                    padding-left: 2px;
                                    width: 50%;

                                    input {
                                        cursor: pointer;
                                        opacity: 0;
                                        z-index: 2;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 100%;
                                        height: 100%;
                                        margin: 0;

                                        &+.color_picto {
                                            width: 30px;
                                            height: 30px;
                                            display: block;
                                            border-radius: 50%;
                                            border: 1px solid $white;
                                            box-shadow: inset 0 0 0 5px $white;
                                            position: relative;

                                            &:before {
                                                content: '';
                                                position: absolute;
                                                top: 0;
                                                bottom: 0;
                                                left: 0;
                                                right: 0;
                                                width: 18px;
                                                height: 18px;
                                                border-radius: 50%;
                                                border: 1px solid $grey16;
                                                margin: auto;
                                                background-color: transparent;

                                            }

                                            &:after {
                                                content: '';
                                                position: absolute;
                                                top: 0;
                                                bottom: 0;
                                                left: -2px;
                                                width: 30px;
                                                height: 30px;
                                                border-radius: 50%;
                                                border: 1px solid $white;
                                                margin: auto;
                                                background-color: transparent;
                                                box-shadow: inset 0 0 0 2px $white;
                                            }
                                        }

                                        &:checked+.color_picto {
                                            &:after {
                                                border: 1px solid $grey16;
                                            }

                                            &+label {
                                                font-weight: 600;
                                            }
                                        }
                                    }

                                    label {
                                        font-family: $primaryFont;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 13px;
                                        letter-spacing: 0.1em;
                                        color: $black;
                                    }
                                }
                            }
                        }

                        /* Filtre nombre */
                        &#filter_caract_nombre {
                            .filter_wrapper {
                                .selected_options {
                                    &.actif {
                                        padding: 13.5px 50px;
                                    }

                                    .wrap_filter {
                                        .form_itm {
                                            margin-bottom: 14px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .os-scrollbar-vertical {
                        right: 26px;
                        background: $grey16;
                        border-radius: 4px;
                        width: 4px;

                        .os-scrollbar-track {
                            .os-scrollbar-handle {
                                background: $green !important;
                                border-radius: 4px;
                                width: 4px;
                                right: -2px;
                            }
                        }
                    }
                }

                .holder_submits {
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    padding-top: 85px;

                    .form_submit {
                        width: 177px;
                        text-align: center;

                        button,
                        .button {
                            width: 100%;
                            height: 45px;
                            border: 1px solid $grey3;
                            background-color: $grey3;
                            border-radius: 0;
                            cursor: pointer;
                            transition: all ease-in-out .5s;
                            text-align: center;

                            span {
                                color: $white;
                                font-family: $primaryFont;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 17px;
                                text-align: center;
                                text-transform: uppercase;
                            }

                            &:hover {
                                background-color: $white;

                                span {
                                    color: $primaryColor;
                                }
                            }
                        }

                        &.initFilters {
                            margin-left: 15px;

                            .cta {
                                width: 177px;
                                height: 45px;
                                border: 1px solid $grey3;
                                background-color: $white;
                                border-radius: 2px;
                                cursor: pointer;
                                text-align: center;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                transition: all ease-in-out .5s;

                                span {
                                    color: $grey3;
                                    font-family: $primaryFont;
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: 14px;
                                    line-height: 17px;
                                    text-align: center;
                                    text-transform: uppercase;
                                }

                                &:hover {
                                    background-color: $grey3;

                                    span {
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .nav_right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 400px;

        .nb_products {
            font-family: $primaryFont;
            font-weight: 600;
            text-transform: none;
            font-size: 13px;
            line-height: 21px;
            text-align: center;
            display: inline-block;
            width: auto;
            margin-right: 10px;
        }

        .choix_tri {
            display: flex;
            align-items: center;

            .trier_par {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: $black;
                display: flex;
                align-items: center;

                &:before {
                    content: '';
                    width: 16px;
                    height: 14px;
                    background: url(../svg/choix_tri.svg) center center no-repeat;
                    background-size: cover;
                    position: relative;
                    display: block;
                    margin-right: 5px;
                }
            }

            .select_wrapper {
                position: relative;
                margin-left: 4px;

                #choice_tri {
                    height: auto;
                    text-indent: 0;
                    background-color: transparent;
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: $black;
                    cursor: pointer;
                    padding-right: 25px;
                }

                &:after {
                    content: '';
                    width: 10px;
                    height: 6px;
                    background: url(../svg/ddown.svg) center left no-repeat;
                    background-size: cover;
                    position: absolute;
                    right: 8px;
                    top: 6px;
                    cursor: pointer;
                    z-index: -1;
                }
            }
        }
    }
}

#block_selected_filter {
    display: none !important;
}

.pager_wrapper.bot_pager {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;


    .pagination {
        display: inline-block;
        position: relative;
        min-width: 125px;
        background-color: #FFF;
        z-index: 10;

        a,
        span {
            font-size: 14px;
            display: inline-block;
            vertical-align: middle;
            line-height: 1;
            padding: 3px;
            margin: 0 7px;

            @media screen and (max-width: 1170px) {
                margin: 0 4px;
            }
        }

        // Active element
        span:not(.hellip) {
            font-size: 17px;
            color: #FFF;
            background-color: #c4d600;
            margin-top: -2px;
        }

        a.precedent,
        a.suivant {
            background: transparent url('../img/back_btn_arrow.png') center center no-repeat;
            font-size: 0;
            width: 14px;
            height: 14px;
        }

        a.suivant {
            transform: rotate(180deg);
        }
    }

    .pagination {
        display: none; // WP-22759 Infinite scroll : keep pagination style but hide it
    }

    .nbProdDisplayed {
        font-size: 13px;
    }



    .see_all_product {
        position: relative;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        color: $white;
        background-color: $grey3;
        min-width: 140px;
        min-height: 39px;
        margin: 0;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background: url(../svg/three_dots.svg) center center/18% no-repeat $grey3;
            z-index: -1;
            opacity: 0;
        }

        &.loading {
            &::before {
                z-index: 1;
                opacity: 1;
            }
        }
    }
}

.loadedGauge {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 105px;
    margin: 1.5rem auto 1.5rem;
    border-radius: 32px;
    background-color: lightgrey;

    span {
        display: block;
        height: 3px;
        background-color: $secondaryColor;
        border-radius: 32px;
        margin: 0 !important;
    }
}

.top_pager {
    cursor: pointer;

    .pagination {
        padding-bottom: 0px;
        margin-right: 7px;
        cursor: pointer;
    }

}

.see_all,
.see_all_product {
    &:after {
        content: "";
        width: 100%;
        height: 1px;
        display: block;
        background-color: #2B2B2B;
        display: none;
    }
}

.district_def,
.rayon_desc_summary {
    font-family: $primaryFont;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    color: $black;
    text-align: justify;
    padding-top: 20px;

    .hidden {
        display: none;
    }

    .readmore {
        display: block;
        margin-top: 5px;
        cursor: pointer;
        text-decoration: underline;
        text-underline-offset: 2px;
    }

    h2 {
        font-family: $primaryFont;
        font-size: 18px;

        &+br {
            display: none;
        }
    }
}

.rayon_desc_summary {
    p+a.readmore {
        margin: 3px 0 0 0;
        display: block;
    }
}

.total_articles {
    font-family: 'Arial', sans-serif;
    font-weight: 700;
    font-size: 11px;
    color: #808080;
    text-transform: uppercase
}

.total_articles .cat_articles {
    display: inline;
    font-size: inherit;
    color: #A9C21F
}

.no_results_help_text_wrapper {
    display: flex;
    justify-content: space-between;
    text-align: center;
    gap: 40px;
}

.no_results_wrapper {
    width: 100%;
    background: url(../img/smiley_neutral.svg) 1px 15px no-repeat;
    padding: 15px 0 35px 55px;
    box-sizing: border-box
}

.no_results_wrapper .no_results_ttl {
    line-height: 1.5;
    margin-bottom: 15px;
    font-size: 30px;
    text-transform: uppercase;
    text-decoration: underline;
    text-underline-position: under;
}

.no_results_wrapper .no_results_ttl strong {
    color: #BDCA1C;
    text-transform: none
}

.no_results_wrapper .search_help_title {
    margin-bottom: 10px;
    font-size: 18px;
    color: #c4d600;
    text-transform: uppercase;
    text-align: left;
}

.no_results_wrapper .search_help_list {
    width: 100%;
    max-width: 600px;
    text-align: left;
}

.no_result_img {
    margin-top: 20px;

    img {
        width: 100%;
    }
}

.no_results_wrapper .search_help_itm {
    line-height: 25px;
    padding-left: 20px;
    font-size: 15px;
    text-indent: -15px
}

.no_results_wrapper .search_help_itm:before {
    display: inline;
    vertical-align: -0.15em;
    line-height: 0;
    font-size: 2.5em;
    color: #c4d600;
    content: '·'
}

.page_rayon.no_results .home.full_w_sldr_wrapper .full_w_sldr_title {
    top: auto;
    bottom: 100%
}

@media screen and (max-width: 1360px) {

    .page_rayon.no_results {
        background-position-x: 150%
    }
}

@media screen and (max-width: 1280px) {

    .page_rayon.no_results {
        background-image: none
    }
}

.product_highlights {
    max-width: 100%;
    max-height: 890px;
    padding: 3% 0 0;
    margin-bottom: 0;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;

    .item:nth-child(-n+8) {
        display: block;
    }

    .item {
        display: none;
        position: relative;
        float: left;
        width: 25%;
        padding-bottom: 39px;
        margin-bottom: 1.5%;
        text-align: center;

        .eclat_gondole_home {
            @media screen and (max-width: 1024px) {
                right: -27%;
            }
        }

        .eclat_gondole_home_left {
            @media screen and (max-width: 1024px) {
                left: -7%;
            }
        }

        .wrapper_infos {
            text-align: left;
            position: static;
            min-height: 39px;
            line-height: 1;
            padding: 10px 13% 0;

            .wrapper_price {
                white-space: nowrap;
                text-align: left;
                min-width: 90px;
            }

            .title {
                text-align: left;
                line-height: 1.2;
                padding-top: 0px;
            }
        }

        .btn_buy {
            top: calc(50% - (39px / 2));
        }
    }

    .item:not(:first-child):not(:nth-child(5)):before {
        display: block;
        position: absolute;
        top: 40%;
        left: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 1px;
        height: 75px;
        background-color: #dadada;
        content: '';
    }


}

.wrapper_category {

    .wrapper_visu_category_super {

        width: 100%;
        height: auto;
        float: left;
        overflow: hidden;
        text-align: right;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        img {
            width: 100%;
            height: auto;
            margin-bottom: 0.5rem;
        }
    }

    .column_1 {
        width: 100%;

    }

    .catalogue {
        position: relative;
        width: 826px;
        height: 267px;
        margin: 2rem auto;
        background: transparent 100% 50% no-repeat;
        background-image: url(../img/boutique/consulte_catalogue_2021.png), linear-gradient(to right, rgba(160, 160, 160, 1) 50%, rgba(122, 122, 122, 0) 50%);

        .catalogue_text {
            position: relative;
            margin-left: 8%;
            margin-top: 4.5em;

            .btn_buy {
                position: relative;
                top: auto;
                left: auto;
                height: 37px;
                width: 88px;
                background-color: transparent;
                color: #C4D600;
                display: block;
                transform: inherit;
                margin-top: 30px;

                span {
                    line-height: 37px;
                }
            }

            .btn_buy:not(.loader):after {
                line-height: 37px;
            }

            .c-catalogue {
                line-height: 1.2;
                text-transform: uppercase;
                color: $white;
                background-image: none;
                font-size: 23px;


                span {

                    font-size: 27px;
                }
            }

            .bouton_voir {
                color: $green;
                border: 2px solid $green;
                text-transform: uppercase;
                font-size: 0.85vw;
                font-weight: 600;
                padding: 5px 34px;
                width: 6%;
            }
        }
    }
}

body.fr,
body.en {
    .wrapper_nav_rayon {
        .nav_left {
            width: calc(100% - 290px);
        }

        .nav_right {
            width: 40%;
            max-width: 290px;
        }
    }
}


// Fix category filter price height

body.category {
    #filter_caract_price {

        .filters_dropdown {

            .filter_name {
                padding: 0px 0 9px !important;
                border-bottom: none !important;

                &::after {
                    transform: translateY(-45%) !important;
                }
            }
        }
    }

    #filter_price {
        .filter_name {
            border-bottom: none !important;
        }
    }

    .page_rayon {
        .wrapper_nav_rayon .nav_left .filter_name_wrapper {
            #caract_price {
                display: none;
            }
        }
    }
}

body.category .page_wrapper #site_global_wrap .page_rayon .selected_options .swiper_n_btn_wrapper {
    padding: 0 49px;
}

body.category,
body.customer {
    .wrapper_bandeau.bandoBottom {
        height: auto;

        .txt_content_container {
            width: calc(100% - 80px);
            max-width: 1680px;
            margin: 70px auto 0 auto;

            .txt_content,
            .rayon_desc_summary {
                font-family: $primaryFont;
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                color: $black;
                margin: 50px auto;
                padding-top: 0;

                h1 {
                    font-family: $primaryFont;
                    font-size: 24px;
                    margin-bottom: 7px;
                }

                h2 {
                    font-family: $primaryFont;
                    font-size: 18px;
                    margin-bottom: 5px;
                }

                h3 {
                    font-family: $primaryFont;
                    font-size: 14px;
                    margin-bottom: 3px;
                }

                ul {
                    list-style: disc;
                    padding-left: 40px;
                    margin-bottom: 5px;

                    li {
                        list-style: disc !important;
                        margin-bottom: 2px;
                    }
                }

                a {
                    text-decoration: underline;
                }

                strong {
                    a {
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.lienSeo {
    margin: 30px auto 0 auto;
    max-width: 1220px;

    .textSeo_bloc_liens {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px 10px;
        flex-wrap: wrap;
        width: auto;

        .textSeo_bloc_list_items {
            a {
                font-family: $primaryFont;
                padding: 0px 20px;
                border-radius: 5px;
                background: $lightGrey3;
                color: $grey3;
                font-size: 12px;
                font-weight: 400;
                line-height: 40px;
                letter-spacing: 0.32px;
                text-decoration: none;
                transition: background .3s ease-in-out;
                display: block;

                &:hover {
                    background: $lightGreyBorder;
                }
            }
        }
    }
}