:root {
    --apple-pay-button-width: 100%;
    --apple-pay-button-height: 50px;
    --apple-pay-button-padding: 6px;
    --apple-pay-button-box-sizing: border-box;
    --apple-pay-button-border-radius: 0;
}

apple-pay-button#applepay_express_checkout {
    margin-top: 10px;
}

body.body_login {
    #ariane,
    .main_ariane,
    .title.my_account {
        display: none;
    }

    #site_global_wrap {
        padding-top: 170px !important;
    }

    &.hasBandeauHeader #site_global_wrap {
        padding-top: 200px !important;
    }

    &.hasBandeauHeader.in_tunnel #site_global_wrap {
        padding-top: 0 !important;
    }

    .newlogin_page {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 98%;
        max-width: 1350px;
        margin: 0 auto;
        padding-top: 0;
    }
}

body.cart {
    #ariane,
    .main_ariane {
        display: none;
    }

    .page_wrapper {
        min-height: calc(100vh - 120px);
    }

    &.step_1 {
        #cart_link_back {
            display: flex;
        }

        &.right_side {
            margin-top: 0;
        }

        #cart_total {
            margin-top: 9px;
        }

        .footer_rassu_wrapper {
            display: none !important;
        }
    }

    &.body_login {
        #cart_logo_back {
            display: none;
        }

        #cart_link_back2 {
            position: absolute;
            top: 150%;
            left: 0;
            display: flex;
        }
    }

    &:not(.step_1) {
        #cart_logo_back {
            display: block;
        }

        .footer_rassu_wrapper,
        #footer_about,
        .footer_partner_signup,
        .footer_products_sections,
        #footer_payment_methods {
            display: none !important;
        }
    }

    .empty_cart {
        font-size: 17px;
        text-align: center;
        margin: 15px 0;

        .emptyBasketButton {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            height: 50px;
            padding: 0 3.7em;
            background-color: #2B2B2B;
            border: 1px solid #2B2B2B;
            color: white;
            box-sizing: border-box;
            margin: 19px auto;
            transition: background-color .3s ease-in-out, color .3s ease-in-out;

            &:hover {
                background-color: white;
                color: #2B2B2B;
            }
        }
    }
}

body.checkout {
    display: flex;
    flex-direction: column;

    #cart_logo_back {
        display: block;
    }

    .footer_rassu_wrapper,
    #footer_about,
    .footer_partner_signup,
    .footer_products_sections,
    #footer_payment_methods {
        display: none !important;
    }

    .wrapper_tunnel {
        min-height: calc(100vh - 120px);
    }
}

/*
 * Cart steps
 */
.tunnel_track_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.tunnel_track {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 44%;
    max-width: 736px;
    margin-left: 4%;

    @media screen and (max-width: 1280px) {
        margin-left: 1%;
    }

    &:before {
        content:'';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #8A8279;
    }
}

.cart.step_1 {
    .tunnel_track {
        margin-left: 5.7%;
    }
}

.tunnel_step {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    background-color: #D9D9D9;

    &:after {
        content: attr(data-num);
        font-size: 20px;
        font-weight: 600;
        color: #fff;
    }

    &.on {
        background-color: #8A8279;

        span {
            color: #8A8279;
        }
    }

    span {
        position: absolute;
        top: 128%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 13px;
        font-weight: 600;
        color: #D9D9D9;
        text-transform: uppercase;
        text-align: center;
        white-space: nowrap;
    }

    &.passed:after {
        content: '';
        display: block;
        width: 22px;
        height: 22px;
        @include maskIconPath('../svg/spritesheet.svg');
        @include maskIconCoord(13, 6, 6);
        background-color: #8A8279;
    }

    &.loading {
        background: #D9D9D9 url(../svg/three_dots_black.svg) no-repeat center;
        background-size: 50% auto;

        &:after {
            display: none !important;
        }
    }
}

#cart_link_back,
#cart_link_back2 {
    display: none;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .04rem;

    span {
        text-decoration: underline;
    }

    &:before {
        content: '';
        display: block;
        width: 16px;
        height: 10px;
        background: transparent url(../svg/arrow.svg) no-repeat center center;
        background-size: contain;
        transform: rotate(90deg);
        margin: 0 .3rem 0 -.5rem;
    }

    &:hover span {
        text-decoration: none;
    }
}

#cart_logo_back {
    display: none;
    width: 192px;
    height: 40px;
    background: transparent url(../svg/hesperide_2022.svg) no-repeat center center;
    background-size: contain;

    @media screen and (max-width: 1280px) {
        width: 150px;
        height: 32px;
    }
}

#main_cart_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 82%;
    max-width: 1360px;
    min-height: 320px;
    margin: 0 auto 40px;
    opacity: 0;
    transition: opacity .3s ease-in-out;

    &.displayed {
        opacity: 1;
    }

    @media screen and (max-width: 1366px) {
        width: 98%;
        max-width: 98%;
    }
}

.elem_tunnel {
    &.left_side {
        display: flex;
        flex-direction: column;
        width: calc(100% - 400px);
        max-width: 929px;
        margin-top: 65px;
    }

    &.right_side {
        width: 360px;
    }

    .cart_out_of_stock_table {
        order: 1;
    }

    .cart_main_title.basket {
        order: 2;
    }

    .cart_main_table.basket {
        order: 3;
    }

    .cart_main_title.wishlist {
        order: 4;
    }

    .cart_main_table.wishlist {
        order: 5;
    }

    .buyTogetherWrapper {
        order: 6;
    }
}

body.cart.body_login .elem_tunnel {
    margin-top: -45px;
}

#tunnel_right_col {
    position: sticky;
    top: 40px;
    margin-top: -45px;
    display: flex;
    flex-direction: column;

    #cart_total {
        order: 1;
    }

    .info_transporteur {
        order: 2;
        display: none;
    }

    .addresses_recap {
        order: 3;
    }

    .cart_faq {
        order: 4;
    }

    #alma-widget {
        display: none;
    }
}

body.cart.step_1 {
    #tunnel_right_col {
        top: 150px;
        margin-top: 0;
    }
}

/*
 * Cart left side
 */
.cart_main_title {
    display: block !important;
    font-size: 25px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: .3rem;

    &.empty,
    &.empty_cart {
        display: none !important;
    }

    .nb_products {
        display: none;
    }
}

.dropdown_cart {
    margin-bottom: 25px;

    .precoSubtitle {
        display: none;
    }

    .precoDeliveryDate {
        font-size: 13px;
    }
}

.cart_list_header {
    display: none;
}

.product_no_sfs_warning {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: #8A8279;
    font-size: 13px;
    color: white;
    text-align: center;
    margin-bottom: 23px;
}

.pushBasketWrapper {
    width: 100%;
    margin-bottom: 23px;

    img {
        width: 100%;
        height: auto;
        vertical-align: top;
    }
}

.pushBasketWrapperText {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 40px;
    background-color: #8A8279;
    font-size: 13px;
    color: white;
    text-align: center;
    margin-bottom: 23px;
}

/*
 * Cart product item
 */
.cart_main_table.basket {
    display: block !important;

    &.empty_cart {
        display: none !important;
    }

    .cart_product_line {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: wrap;
        padding: 25px 0;
        border-bottom:1px solid #8A8279;

        .product_sfs_warning_txt {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 40px;
            background-color: #8A8279;
            font-size: 13px;
            color: white;
        }

        .dragOverlay {
            display: none;
        }

        // Change padding for the first occurence
        &:nth-of-type(2) {
            padding-top: 0;

            .product_del {
                top: 0;
            }
        }

        &:last-of-type {
            border-bottom: none;
        }

        .cart_product_pic {
            position: relative;
            width: 140px;

            img {
                width: 100%;
                height: auto;
                vertical-align: top;
            }
        }

        .cart_product_desc {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            width: calc(100% - 140px);
            padding-left: 16px;
            box-sizing: border-box;
        }

        .wrapper_cart_product_desc {
            display: flex;
            flex-direction: column;

            .wrap_titre_prix {
                order: 1;
            }

            .cart_product_sizecol {
                &.color {
                    order: 2;
                    margin-bottom: 3px;
                }

                &.size {
                    order: 3;
                }

                &.quantity {
                    display: none;
                }

                &.color,
                &.size {
                    font-size: 13px;

                    label {
                        font-weight: 600;
                        margin-right: .2rem;
                    }
                }
            }

            .cart_product_nostock {
                order: 4;
                margin-top: 10px;

                p {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-size: 11px;

                    &:before {
                        content: '';
                        display: block;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background-color: #A50034;
                        margin-right: .2rem;
                    }
                }
            }

            .product_stock_label {
                display: flex !important;
                justify-content: flex-start;
                align-items: center;
                order: 4;
                font-size: 11px;
                margin-top: 10px;

                &:before {
                    content: '';
                    display: block;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #63AB07;
                    margin-right: .2rem;
                }
            }
        }

        .cart_product_brand,
        .product_towish {
            display: none;
        }

        .cart_product_title {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 3px;
        }

        .cart_product_subtitle {
            font-size: 13px;
            margin-bottom: 13px;
        }

        .product_quantity_change {
            position: relative;
            width: 94px;
            height: 30px;

            .load_qte {
                position: absolute;
                z-index: 1;
                top: 54%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .qteWrapper {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            width: 94px;
            height: 30px;
            border: 1px solid #2B2B2B;

            &::before {
                content: '-';
                position: absolute;
                top: 1px;
                left: 1px;
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                color: #D9D9D9;
            }

            &::after {
                content: '+';
                position: absolute;
                top: 1px;
                right: 1px;
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                top: 1px;
                color: #D9D9D9;
            }

            button {
                position: absolute;
                z-index: 2;
                top: 0;
                appearance: none;
                width: 30px;
                height: 30px;
                line-height: inherit;
                border: none;
                background: white;
                cursor: pointer;

                &.lessQte {
                    left: 0;
                }

                &.moreQte {
                    right: 0;
                }
            }

            .selected_qte {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                width: calc(100% - 60px);
                height: 30px;
                background-color: white;
                font-size: 11px;
                text-align: center;
                line-height: inherit;
            }
        }

        .max_stock {
            display: block !important;
            position: absolute;
            top: 50%;
            left: 112%;
            transform: translateY(-50%);
            font-size: 12px;
            color: #8A8279 !important;
            white-space: nowrap;
        }

        .wrapper_cart_product_price {
            position: absolute;
            bottom: 26px;
            right: 0;
            display: flex;
            justify-content: flex-end;
            align-items: baseline;
            flex-wrap: wrap;

            .basketEcoTaxe {
                display: flex !important;
                justify-content: flex-end;
                width: 100%;
                font-size: 10px;
                    color: #8A8279;

                span {
                    color: #2b2b2b;
                }
            }

            .tunel_txt_ecoparticipation {
                display: none;
            }

            .pricetag {
                font-size: 30px;

                &.offered {
                    font-size: 18px;
                }
            }

            .pricetag.is_discounted {
                color: #A50034;
            }

            .pricetag+.pricetag {
                font-size: 23px;
                color: #8A8279;
            }
        }

        .eclat,
        .eclat_rect {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;

            img {
                max-width: 4rem;
                height: auto;
                vertical-align: top;
            }
        }

        .eclat_rect {
            display: none !important;
        }

        .eclat_rond {
            width: 100%;
            text-align: right;
            margin-bottom: .5rem;

            img {
                max-width: 5rem;
                height: auto;
                vertical-align: top;
            }
        }

        .product_del {
            position: absolute;
            top: 25px;
            right: 0;
            font-size: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 21px;
            height: 21px;
            background-color: #2B2B2B;

            &:before {
                content: '';
                display: block;
                width: 11px;
                height: 11px;
                background-image: none;
                @include maskIconPath('../svg/spritesheet.svg');
                @include maskIconCoord(11, 6, 6);
                background-color: white;
            }
        }
    }
}

.cart_product_line.out_of_stock {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom:1px solid #8A8279;

    .product_sfs_warning_txt {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
        background-color: #8A8279;
        font-size: 13px;
        color: white;
        margin-bottom: 23px;

        br {
            display: none;
        }
    }

    .dragOverlay {
        display: none;
    }

    // Change padding for the first occurence
    &:nth-of-type(2) {
        padding-top: 0;

        .product_del {
            display: none;
        }
    }

    &:last-of-type {
        border-bottom: none;
    }

    .cart_product_pic {
        position: relative;
        width: 16%;
        max-width: 140px;
        opacity: .5;

        .cart_product_img {
            width: 100%;
            height: auto;
            vertical-align: top;
        }

        .eclat {
            position: absolute;
            top: 0;
            left: 0;

            img {
                max-width: 4rem;
                vertical-align: top;
            }
        }
    }

    .cart_product_desc {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: calc(100% - 16%);
        padding-left: 16px;
        box-sizing: border-box;
    }

    .wrapper_cart_product_desc {
        display: flex;
        flex-direction: column;

        .wrap_titre_prix {
            order: 1;
            opacity: .5;
        }

        .cart_product_sizecol {
            opacity: .5;

            &.color {
                order: 2;
                margin-bottom: 3px;
            }

            &.size {
                order: 3;
            }

            &.quantity {
                display: none;
            }

            &.color,
            &.size {
                font-size: 13px;

                label {
                    font-weight: 600;
                    margin-right: .2rem;
                }
            }
        }

        .cart_product_nostock {
            position: absolute;
            bottom: 20px;

            p {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 17px;
                text-transform: uppercase;
                color: #A50034;
            }
        }
    }

    .cart_product_brand,
    .product_towish {
        display: none;
    }

    .cart_product_title {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 3px;
    }

    .cart_product_subtitle {
        font-size: 13px;
        margin-bottom: 22px;
    }

    .product_quantity_change {
        display: none;
    }

    .wrapper_cart_product_price {
        position: absolute;
        bottom: 26px;
        right: 0;
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        flex-wrap: wrap;

        .basketEcoTaxe {
            display: flex !important;
            justify-content: flex-end;
            width: 100%;
            font-size: 10px;
            color: #2b2b2b;

            span {
                color: #8A8279;
            }
        }

        .tunel_txt_ecoparticipation {
            display: none;
        }

        .pricetag {
            font-size: 30px;

            &.offered {
                font-size: 18px;
            }
        }

        .pricetag.is_discounted {
            color: #A50034;
        }

        .pricetag+.pricetag {
            font-size: 23px;
            color: #8A8279;
        }
    }

    .eclat_rect {
        display: none !important;
    }

    .eclat_rond {
        width: 100%;
        text-align: right;
        margin-bottom: .5rem;

        img {
            max-width: 5rem;
            height: auto;
            vertical-align: top;
        }
    }

    .product_del {
        display: none;
    }
}

/*
 * Cart right side summary
 */
.share_cart {
    position: absolute;
    z-index: 1;
    top: -46px;
    width: 100%;
    height: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .share_cart_header {
        font-size: 13px;
        font-weight: 500;
        text-decoration: underline;
        letter-spacing: .04rem;

        &:hover {
            text-decoration: none;
        }
    }

    .share_cart_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background-color: #E1E1E1;
        cursor: pointer;

        &:before {
            content: '';
            display: block;
            width: 28px;
            height: 28px;
            @include maskIconPath('../svg/spritesheet.svg');
            @include maskIconCoord(17, 6, 6);
            background-color: #2B2B2B;
        }

        &:active {
            transform: translateY(1px);
        }
    }
}

#cart_total {
    display: flex;
    flex-direction: column;
    padding: 35px;
    box-sizing: border-box;
    background-color: rgba(136, 136, 136, 0.1);

    .recap_cart_title {
        order: 1;
    }

    .bill_line {
        &.total_produit {
            order: 2;
        }

        &.used_cp {
            order: 4;
        }

        &.promo {
            order: 5;
        }

        &.total_produit.toggle {
            order: 2;
            cursor: pointer;

            label {
                cursor: pointer;

                &:after {
                    content: '+';
                    font-size: 18px;
                    font-weight: 600;
                    line-height: -.1rem;
                    margin-left: .5rem;
                }
            }

            &.open label:after {
                content: '-';
            }
        }

        &.discount {
            order: 7;
        }

        &.total_produit_dropdown {
            order: 3;
            display: none;
        }

        &.hide_shippingfees {
            order: 8;
        }

        &.coupon {
            order: 9;

            .w-coupon-form {
                margin-top: 21px;
            }
        }

        &.total {
            order: 10;
        }

        &.total_realised_economy {
            order: 11;
        }
    }

    .formPanier_container {
        order: 12;
        margin-top: 16px;
    }

    .recap_cart_title {
        font-size: 17px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 4px;
    }
}

.bill_line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #2B2B2B;

    .product_stock_label {
        display: none;
    }

    &.total_produit,
    &.used_cp,
    &.promo,
    &.hide_shippingfees,
    &.discount {
        font-size: 13px;
    }

    &.total_produit {
        margin-bottom: 7px;

        .nbProductsTxt {
            display: none;
        }

        .pricetag {
            font-size: 20px;
            font-weight: 600;
        }
    }

    &.used_cp {
        margin-bottom: 7px;

        .code_promo {
            font-size: 14px;
            font-weight: 600;
        }
    }

    &.promo {
        margin-bottom: 7px;

        .pricetag {
            font-size: 20px;
            font-weight: 600;
        }
    }

    &.discount {
        .pricetag {
            font-size: 20px;
        }
    }

    &.hide_shippingfees {
        .pricetag {
            font-size: 13px;
            font-weight: 400;
        }
    }

    &.total {
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid #2B2B2B;

        label {
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
        }

        .pricetag {
            font-size: 30px;
            font-weight: 600;
        }
    }

    &.total_realised_economy {
        display: flex !important;
        justify-content: flex-end;
        font-size: 14px;
        line-height: 1;
        color: #63AB07;

        label {
            margin-right: .2rem;
        }

        .price_tag {
            sup.currency {
                font-size: 9px;
            }

            span.cents {
                font-size: 10px;
                margin-left: -.1rem;
            }
        }
    }

    &.total_produit_dropdown {
        flex-direction: column;
        padding: 8px .5em 0 0;
        max-height: 426px;
        overflow-x: hidden;
        overflow-y: auto;
        margin-bottom: 1rem;
    }

    .product_line_basket {
        display: flex;
        margin-bottom: 15px;
    }

    .cart_product_pic {
        width: 45%;
        max-width: 130px;

        img {
            width: 100%;
            height: auto;
            vertical-align: top;
        }
    }

    .cart_product_desc {
        width: 55%;
        padding-left: 1rem;
        box-sizing: border-box;
    }

    .wrapper_cart_product_desc {
        display: flex;
        flex-direction: column;

        .pricetag {
            font-size: 20px;

            &.is_discounted {
                color: #A50034;
            }

            +.pricetag {
                font-size: 18px;
                color: #8A8279;
            }
        }
    }

    .wrap_titre_prix {
        order: 1;
    }

    .cart_product_sizecol {
        font-size: 10px;

        label {
            font-weight: 600;
            margin-right: .2rem;
        }

        &.size {
            order: 3;
        }

        &.color {
            order: 2;
        }

        &.quantity {
            order: 4;
        }
    }

    .wrapper_cart_product_price {
        margin: 4px 0 6px;
    }

    .line_product_desc {
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

        &.cart_product_brand {
            display: none;
        }

        &.cart_product_title  {
            font-size: 12px;
            font-weight: 600;
        }

        &.cart_product_subtitle {
            font-size: 11px;
        }
    }

    .cart_product_modify {
        display: none;
    }

    #fdp_txt {
        display: flex;
        align-items: baseline;

        .fdp_sign {
            display: none;
        }

        .unit {
            order: 1;
        }

        .cents {
            order: 3;
            margin-left: -.3rem;
        }

        .currency {
            order: 2;
            align-self:  flex-start;
            margin-top: .2rem;
        }
    }
}

body:not(.cart.step_1) {
    .formPanier_container {
        display: none;
    }
}

#btn_cmd_valid {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: #2B2B2B;
    border: 1px solid #2B2B2B;
    font-family: $primaryFont;
    font-size: 17px;
    color: white;
    text-transform: uppercase;
    transition: all .3s ease-in-out;

    &:hover {
        background-color: white;
        color: #2B2B2B;
    }
}

.cartLoader {
    display: none;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid #2B2B2B;
    background-color: white;

    span {
        font-size: 0;

        &:before {
            content: '';
            display: block;
            width: 30px;
            height: 30px;
            mask-image: url(../svg/three_dots.svg);
            mask-repeat: no-repeat;
            mask-size: 100%;
            mask-position: center;
            background-color: #2B2B2B;
        }
    }

    &.loading {
        display: flex;
    }
}

.w-coupon-form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .w-form-line {
        width: calc(100% - 73px);

        &.w-submit {
            top: 0;
            transform: none;
            width: 60px;
            max-width: 60px;
            height: 40px;

            .w-submit-button {
                width: 100%;
                height: 100%;
                border: 1px solid black;
                background-color: white;
                font-size: 12px;
                line-height: inherit;
                color: #2B2B2B;

                &:hover {
                    background-color: #2B2B2B;
                    color: white;
                }

                &.w-loader {
                    background-color: #2B2B2B;

                    span {
                        font-size: 0;
                    }
                }
            }
        }
    }

    .w-has-error {
        margin-bottom: 1.4rem;
    }

    .w-input-element:not([type=radio]):not([type=checkbox]),
    input[type=text].w-input-element {
        height: 40px;
        padding: 0 .7rem;
        background-color: white;
        border: 1px solid black;
    }

    .w-form-line .w-input-element:not([type=radio]):not([type=checkbox]),
    .w-form-line input[type=text].w-input-element {
        border-radius: 0;
    }

    .w-input-label {
        text-transform: uppercase;
        color: #868686;
        left: .7rem;
    }

    .w-input-note {
        top: 76%;
        margin: 0;
        font-family: $primaryFont;
        font-size: 13px;
        color: #D9000C !important;
    }

    .w-has-error:not(.w-dropdown) .w-input-element:not([type=radio]):not([type=checkbox]),
    .w-has-error:not(.w-dropdown) input[type=text].w-input-element {
        border-color: initial !important;
        box-shadow: initial !important;
    }

    .w-nonempty:not(.no-label) .w-input-element:not([type=radio]):not([type=checkbox]),
    .w-nonempty:not(.no-label) input[type=text].w-input-element {
        padding-top: 0 !important;
    }

    .w-nonempty .w-input-label {
        display: none !important;
    }
}

.copied-text-notification {
    position: absolute;
    right: 0;
    top: -10px;
    left: 0;
    transform: translateY(calc(100% + 10px));
    z-index: 1000;
    animation: slideIn 0.3s forwards;
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    margin: 10px auto;
    background-color: #2B2B2B;
    padding: 10px 0;
    width: 100%;
    min-width: 30ch;
    color: white;
    font-family: $primaryFont;
    font-size: 15px;
    font-weight: 400;
    text-align: center;

    &.slide-out {
        animation: slideOut 0.3s forwards;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(calc(100% + 10px));
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideOut {
    from {
        transform: translateY(0);
        opacity: 1;
    }

    to {
        transform: translateY(calc(100% + 10px));
        opacity: 0;
    }
}

.cart_faq {
    margin-top: 17px;

    h3 {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 50px;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        padding-left: 40px;
        box-sizing: border-box;

        span {
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%) rotate(90deg);
                width: 18px;
                height: 18px;
                @include maskIconPath('../svg/spritesheet.svg');
                @include maskIconCoord(14, 6, 6);
                background-color: #2B2B2B;
            }
        }
    }

    .toggleWrapper.active h3 span:after {
        transform: translateY(-50%) rotate(-90deg);
    }
}

.toggleWrapper {
    border-bottom: 1px solid #D9D9D9;
    cursor: pointer;

    &:nth-child(1) {
        h3 span:before {
            width: 30px;
            height: 30px;
            @include maskIconPath('../svg/spritesheet.svg');
            @include maskIconCoord(19, 6, 6);
            background-color: #2B2B2B;
        }
    }

    &:nth-child(2) {
        h3 span:before {
            width: 25px;
            height: 25px;
            @include maskIconPath('../svg/spritesheet.svg');
            @include maskIconCoord(20, 6, 6);
            background-color: #2B2B2B;
            margin-left: 2px;
        }
    }

    &:nth-child(3) {
        h3 span:before {
            width: 30px;
            height: 30px;
            @include maskIconPath('../svg/spritesheet.svg');
            @include maskIconCoord(19, 6, 6);
            background-color: #2B2B2B;
            transform: translateY(-50%) scaleX(-1);
        }
    }

    &.secured {
        h3 span:before {
            width: 25px;
            height: 25px;
            @include maskIconPath('../svg/spritesheet.svg');
            @include maskIconCoord(18, 6, 6);
            background-color: #2B2B2B;
            margin-left: 2px;
        }

        .toggleContent {
            text-align: center;
            padding: 0 0 1rem 0;

            img {
                margin: 0 15px 10px 5px;
            }
        }
    }

    .toggleContent {
        display: none;
        box-sizing: border-box;
        font-size: 13px;
        line-height: 1.2;
        padding: 0 0 1rem 2.4rem;

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

/*
 * Wishlist
 */
.cart_main_title.wishlist,
.cart_main_title.buyTogether {
    font-size: 17px;
    font-weight: 700;
    margin: 24px 0 16px;
    padding-bottom: 10px;
    border-bottom: 1px solid #D9D9D9;
}

.cart_main_table.wishlist {
    display: block !important;
    width: 100%;
    padding: 0 28px;
    box-sizing: border-box;

    &.empty {
        display: none !important;
    }

    .wishlist_persis_info {
        display: none;
    }

    .cart_product_brand,
    .cart_product_sizecol,
    .remove_wish,
    .dragHandle,
    .dragOverlay {
        display: none;
    }

    .cart_product_line {
        display: flex;
        flex-direction: column;
        max-width: 190px;
        padding: 10px 10px 20px;
        box-sizing: content-box;
        border: 1px solid white;

        &:hover {
            border: 1px solid #D9D9D9;
        }
    }

    .cart_product_pic {
        position: relative;
        max-width: 190px;

        img {
            width: 100%;
            height: auto;
            vertical-align: top;
        }

        .eclat {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;

            img {
                max-width: 4rem;
                height: auto;
                vertical-align: top;
            }
        }
    }

    .cart_product_title,
    .cart_product_subtitle {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 11rem;
    }

    .cart_product_title {
        font-size: 14px;
        font-weight: 600;
        color: black;
        margin: 12px 0 1px;
    }

    .cart_product_subtitle {
        font-size: 13px;
        color: #8A8279;
        margin-bottom: 7px;
    }

    .pricetag {
        font-size: 30px;
        color: #2B2B2B;

        &.discounted {
            color: #A50034;
        }

        &.is_discounted {
            font-size: 20px;
            color: #8A8279;
        }
    }

    a.wishToBasket {
        position: absolute;
        bottom: 10px;
        right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: clamp(35px, fs-vw(40, 1920),  40px);
        height: clamp(35px, fs-vw(40, 1920),  40px);
        border-radius: 50%;
        background-color: #2B2B2B;
        font-size: 0;

        &:before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background-image: none;
            @include maskIconPath('../svg/spritesheet.svg');
            @include maskIconCoord(26, 6, 6);
            background-color: white;
        }

        &.processing {
            cursor: default;

            &:before {
                width: 30px;
                height: 30px;
                mask-image: url(../svg/loader.svg);
                mask-repeat: no-repeat;
                mask-size: 100%;
                mask-position: center;
            }
        }
    }

    .expressAlertStock {
        display: none !important;
    }

    .tunnel_wish_nostock {
        position: absolute;
        bottom: 4px;
        right: 10px;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 10px;
        text-transform: uppercase;
        color: $cmsPageTitle;


        /* &:before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #A50034;
            margin-right: .2rem;
        } */
    }

    .cartWishPrev,
    .cartWishNext {
        position: absolute;
        z-index: 1;
        top: 0;
        display: block;
        width: 28px;
        height: 100%;
        background-color: white;
        cursor: pointer;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 48%;
            width: 18px;
            height: 18px;
        }

        &.swiper-button-disabled {
            display: none;
        }
    }

    .cartWishPrev {
        left: -5px;

        &:before {
            left: 0;
            @include maskIconPath('../svg/spritesheet.svg');
            @include maskIconCoord(14, 6, 6);
            background-color: #2B2B2B;
            transform: rotate(180deg);
        }
    }

    .cartWishNext {
        right: 0;

        &:before {
            right: -5px;
            @include maskIconPath('../svg/spritesheet.svg');
            @include maskIconCoord(14, 6, 6);
            background-color: #2B2B2B;
        }
    }
}

/*
 * Frequently buy together slider
 */
#buyTogetherSlider {
    width: 100%;
    padding: 0 28px;
    box-sizing: border-box;

    .addToWishlistButton,
    .ligne_form {
        display: none;
    }

    .wrapperProdVertical {
        position: relative;
        display: flex;
        flex-direction: column;
        max-width: 190px;
        padding: 10px;
        box-sizing: content-box;
        border: 1px solid white;
        margin-bottom: 0;

        &:hover {
            border: 1px solid #D9D9D9;
        }
    }

    .fp_img_container {
        position: relative;
    }

    .eclat_rond {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;

        img {
            max-width: 4rem;
            height: auto;
            vertical-align: top;
        }
    }

    .item_name,
    .item_feat {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 11rem;
    }

    .item_name {
        font-size: 14px;
        font-weight: 600;
        color: black;
        margin: 12px 0 1px;
    }

    .item_feat {
        font-size: 13px;
        color: #8A8279;
        margin-bottom: 7px;
    }

    .wrapper_price {
        display: flex;
        align-items: baseline;
        font-size: 30px;

        .old-price {
            position: absolute;
            top: 90%;
        }
    }

    .pricetag {
        font-size: 30px;

        +.pricetag {
            font-size: 20px;
        }
    }

    .form_submit {
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 40px;
        height: 40px;

        .w-submit-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: clamp(35px, fs-vw(40, 1920),  40px);
            height: clamp(35px, fs-vw(40, 1920),  40px);
            border-radius: 50%;
            background-color: #2B2B2B;
            font-size: 0;

            &:before {
                content: '';
                display: block;
                width: 12px;
                height: 12px;
                background-image: none;
                @include maskIconPath('../svg/spritesheet.svg');
                @include maskIconCoord(26, 6, 6);
                background-color: white;
            }
        }

        .loader {
            display: none;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            padding: 0;
            line-height: 1;
            background-color: #2B2B2B;

            &:before {
                content: '';
                display: block;
                width: 30px;
                height: 30px;
                mask-image: url(../svg/loader.svg);
                mask-repeat: no-repeat;
                mask-size: 100%;
                mask-position: center;
                background-color: white;
            }

            span {
                font-size: 0;
            }
        }

        &.loading .loader {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .buyTogetherPrev,
    .buyTogetherNext {
        position: absolute;
        z-index: 1;
        top: 0;
        display: block;
        width: 28px;
        height: 100%;
        background-color: white;
        cursor: pointer;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 48%;
            width: 18px;
            height: 18px;
        }

        &.swiper-button-disabled {
            display: none;
        }
    }

    .buyTogetherPrev {
        left: -5px;

        &:before {
            left: 0;
            @include maskIconPath('../svg/spritesheet.svg');
            @include maskIconCoord(14, 6, 6);
            background-color: #2B2B2B;
            transform: rotate(180deg);
        }
    }

    .buyTogetherNext {
        right: 0;

        &:before {
            right: -5px;
            @include maskIconPath('../svg/spritesheet.svg');
            @include maskIconCoord(14, 6, 6);
            background-color: #2B2B2B;
        }
    }
}

/*
 * Delete product from cart lightbox
 */
.confirmDeleteProductBasket {
    top: 50% !important;
    left: 50% !important;
    right: initial !important;
    width: 560px !important;
    margin: 0 !important;
    transform: translate(-50%, -50%) !important;
    flex-wrap: wrap;
    justify-content: center;

    .confirmDeleteTxt {
        display: none;
    }

    .confirmDeleteTitle {
        display: block;
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    button,
    .product_towish {
        display: inline-flex !important;
        justify-content: center;
        align-items: center;
        width: 45%;
        height: 50px;
        font-family: $primaryFont;
        font-size: 17px;
        text-transform: uppercase;
        cursor: pointer;
    }

    button {
        background-color: #2B2B2B;
        color: white;

        &.loading {
            background: #2B2B2B url(../svg/three_dots.svg) no-repeat center;
            background-size: auto 22%;
            color: transparent;
        }
    }

    .product_towish {
        border: 1px solid #2B2B2B;
        color: #2B2B2B;
        margin-left: 10px;

        &:before {
            display: none;
        }

        &.processing {
            pointer-events: none;
            background: white url(../svg/three_dots_black.svg) no-repeat center;
            background-size: auto 22%;
            color: transparent;
        }
    }

    .cancelDeleteFromCart {
        display: block;
        font-size: 13px;
        color: #999999;
        text-align: center;
        letter-spacing: .03rem;
        text-decoration: underline;
        margin-top: 20px;

        &:hover {
            text-decoration: none;
        }
    }
}

.de .confirmDeleteProductBasket {
    .product_towish,
    button {
        width: fit-content;
        padding: 0 2.4rem;
    }
}

.it .confirmDeleteProductBasket {
    .product_towish,
    button {
        width: fit-content;
        padding: 0 2.4rem;
    }
}

/*
 * Login // Moslty _app styles overrides
 */
.newlogin_page {
    .wrapper_bandeau_connexion {
        position: relative;
        width: 35%;
        max-width: 469px;
        aspect-ratio: 469 / 600;

        .ill_img {
            display: block;
            position: relative;
            width: 100%;
            height: 0;
            padding-top: calc(600 / 469 * 100%);
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
        }
    }

    .login_wrapper {
        width: calc(100% - 39%);
        max-width: 817px;
    }

    .w-newlogin-form {
        width: 100%;
    }

    .login_bloc {
        width: 100%;

        &:first-of-type {
            border-bottom: 0;
            padding-bottom: 0;
        }

        &:last-of-type {
            margin-top: 10px;
            padding-top: 20px;
            border-top: 1px solid #D9D9D9;
        }

        .title h3 {
            font-family: $primaryFont;
            font-size: 17px;
            letter-spacing: initial;
            margin-bottom: 14px;
        }

        .title:not(.my_account) .subtitle {
            font-size: 13px;
        }
    }

    .login_bloc .title:not(.my_account) {
        margin-bottom: 17px;
    }

    .email-form-line:not(:has(.w-input-error)) {
        margin-bottom: 13px;
    }

    .w-input.w-email-input.w-has-error {
        margin-bottom: .6rem;

        + .w-input-note.w-input-error {
            top: 80%;
        }
    }

    .radio-create-account {
        margin-bottom: 18px;

        .w-radio-group {
            flex-direction: column;
        }

        .w-radio-input+.w-radio-input {
            margin-left: 0;
        }

        .w-radio-input {
            height: auto;

            &:first-child {
                margin-bottom: 4px;
            }

            .w-input-element+.w-input-label {
                font-size: 13px;
                color: black;

                span {
                    margin-left: 1em;
                }

                &:before {
                    width: 20px;
                    height: 20px;
                    border-color: #B1B1B1;
                }
            }
        }

        .w-radio-input .w-input-element:checked+.w-input-label:before {
            background-color: #FFFFFF;
            border-color: #C4D600;
            box-shadow: inset 0 0 0 .4rem #C4D600;
        }
    }

    .toggleTxtDisplay,
    .toggleTxtHide {
        font-size: 13px;
        font-weight: 500;
        letter-spacing: .05rem;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    .toggleTxtDisplay {
        display: block !important;
    }

    .toggleTxtHide {
        display: none !important;
    }

    .w-visibility-toggle {
        width: fit-content;
        height: initial;
        background: none;
        padding: .5em 1em;
        cursor: pointer;

        &.w-active {
            .toggleTxtDisplay {
                display: none !important;
            }

            .toggleTxtHide {
                display: block !important;
            }
        }
    }

    .w-newlogin-form .optin_container.column>.w-form-line {
        width: auto;
        justify-content: flex-start;
    }

    .w-newlogin-form .optin_container {
        width: auto;
        align-self: flex-start;
    }

    .w-newlogin-form .optin_container.column {
        flex-direction: row;
        justify-content: flex-start;
    }

    .w-lines-group {
        width: auto;
        margin: 0 0 20px !important;
        align-self: flex-start;
    }

    .intro {
        display: none !important;
    }

    .w-input-element:not([type=radio]):not([type=checkbox]),
    input[type=email].w-input-element {
        height: 52px;
        padding: 0 .875rem;
        border-color: #2B2B2B;
        border-radius: 0;
    }

    .w-form-line.w-submit {
        order: inherit !important;
        max-width: 286px !important;
        margin-bottom: 6px !important;

        .w-submit-button,
        button.w-submit-button {
            width: 100% !important;
            height: 50px;
            line-height: 1;
        }
    }

    .login_bloc .title h4 {
        font-family: $primaryFont;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: initial;
    }
}

.w-newlogin-form,
.w-lostpass-form {
    .w-submit-button.w-loader,
    button.w-submit-button.w-loader {
        background: white url(../svg/three_dots_black.svg) center no-repeat;
        background-size: auto 22%;
    }
}

.optin-consent-line {
    margin-bottom: 0 !important;

    .w-radio-group .w-radio-input+.w-radio-input {
        display: none;
    }

    .w-radio-input .w-input-element+.w-input-label {
        font-size: 13px;
        color: black;

        &:before {
            width: 15px;
            height: 15px;
            border-color: #B1B1B1;
            border-radius: 0;
        }
    }
}

.knowMoreOptin {
    margin-left: .5rem !important;

    a {
        font-size: 13px;
        color: #8A8279;
        text-decoration: underline;
        white-space: nowrap;

        &:hover {
            text-decoration: none;
        }
    }
}

#deny-optin {
    .w-radio-input .w-input-element+.w-input-label::before {
        width: 15px !important;
        height: 15px !important;
        border-color: #B1B1B1 !important;
        border-radius: 0 !important;
        margin-right: .5rem;
    }

    .w-input-label {
        font-size: 13px !important;
        color: #636363 !important;
    }
}

.w-password-input~.w-class-pwd-creation {
    gap: 8px;

    ul {
        gap: 8px;

        .w-pwd-req:before {
            background-color: #63AB07;
        }
    }
}

.w-class-pwd-creation {
    font-size: 13px;
    color: #2B2B2B;
    padding-top: 20px;

    h3 {
        font-size: 13px;
        font-weight: 400;
        color: #2B2B2B;
    }

    .w-pwd-req {
        color: #63AB07;

        &.w-pwd-req-error {
            color: #B72C00;
        }
    }

    ul + h3,
    progress {
        display: none;
    }

    progress + p {
        display: none;
    }
}

.loginBenefits {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 13px 0 -11px;
    padding: 20px 0 23px;
    background-color: white;
    border-top: 1px solid #D9D9D9;

    h3 {
        font-size: 13px;
        font-weight: 400;
    }

    .benefitsWrapper {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 30px;

        div {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: fit-content;
            max-width: 160px;
            padding-top: 35px;
            text-align: center;

            p {
                font-size: 13px;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 3px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            &.tracking {
                p:before {
                    width: 35px;
                    height: 35px;
                    @include maskIconPath('../svg/spritesheet.svg');
                    @include maskIconCoord(19, 6, 6);
                    background-color: #2B2B2B;
                }
            }

            &.finding {
                p:before {
                    width: 34px;
                    height: 34px;
                    @include maskIconPath('../svg/spritesheet.svg');
                    @include maskIconCoord(21, 6, 6);
                    background-color: #2B2B2B;
                }
            }

            &.timing {
                p:before {
                    width: 32px;
                    height: 32px;
                    @include maskIconPath('../svg/spritesheet.svg');
                    @include maskIconCoord(22, 6, 6);
                    background-color: #2B2B2B;
                }
            }
        }
    }
}

.w-oneclick-connect {
    justify-content: center;
    align-items: flex-start;
    gap: 50px;
    margin-top: -6px;

    .w-social-btn {
        width: 31px;
        height: 31px;
        background-color: transparent;

        >div {
            width: 100%;
            height: 100%;
        }
    }

    #googleConnect {
        width: 30px;
        height: 30px;
        background: transparent;
        margin: 0;

        #googleConnectLabel:before {
            width: 100%;
            height: 100%;
        }
    }
}

.body_login.cart {
    .wrapper_bandeau_connexion {
        display: none;
    }

    .newlogin_page {
        align-items: flex-start;
        width: 82%;
        max-width: 1360px;
        margin: 0 auto;

        @media screen and(max-width: 1366px) {
            width: 98%;
            max-width: 98%;
        }

        .login_bloc {
            width: 100%;

            .title h3 {
                font-family: $primaryFont;
                font-size: 17px;
                letter-spacing: initial;
                margin-bottom: 14px;
            }

            .title:not(.my_account) .subtitle {
                font-size: 13px;
            }
        }
    }

    .login_wrapper {
        width: 69%;
        max-width: 929px;
        margin-top: 66px;
    }
}

.pwd-reset-line {
    width: auto !important;
    align-self: flex-end !important;
    margin-bottom: -18px !important;

    .w-pwd-reset {
        font-size: 13px !important;
        color: #2B2B2B !important;

        &:hover {
            text-decoration: none;
        }
    }
}

.nbDenyOptin,
.nbDenyOptinSimple {
    display: block !important;
    font-size: 12px;
    color: #636363;
    margin: 20px 0 10px;
    width: 100%;
    text-align: start;

    br {
        display: none;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

#deny-optin {
    .nbThanksDenyOptin {
        display: none !important;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

/*
 * Cart delivery
 */
body.cart_delivery_choice {
    .elem_tunnel.left_side {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .bill_line #montant_txt {
        span {
            display: flex;
            align-items: baseline;

            .unit,
            .cents,
            .currency {
                display: block;
            }

            .unit{
                order: 1;
            }

            .cents {
                order: 3;
            }

            .currency {
                order: 2;
                align-self: flex-start;
                margin-top: .3rem;
            }
        }
    }
}

/*
 * Guest address
 */
body.cart {
    &.step_2_0 {
        .elem_tunnel.left_side {
            margin-top: 76px;
        }

        .wrapper_form_adresse #adresseForm .w-has-tel .errormsgadd {
            font-family: $primaryFont;
            color: #636363;
        }

        .cart_delivery_legal_note {
            display: block !important;
            margin-top: 24px;
            font-size: 12px;
            color: #636363;

            a {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .delivery_section_title {
        position: absolute;
        top: -35px;
        left: 0;
        width: 100%;
        font-size: 17px;
        font-weight: 600;
        text-transform: uppercase;
        white-space: nowrap;

        &.delivery_mode {
            position: relative;
            top: initial;
            margin: 20px 0 14px;
        }
    }

    #adresseForm {
        .delivery_section_title {
            position: relative;
            top: initial;
            margin-bottom: 6px;
        }

        .subtitle_delivery_information,
        .subtitle_bill_information {
            display: block !important;
            font-size: 13px;
            margin-bottom: 21px;
        }

        .w-form-line .noaddr {
            top: 30%;
        }

        .w-input-group.accountCreate {
            display: none;
        }

        input[type="checkbox"] {
            display: none;

            + .w-input-label {
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 13px;
                color: #2B2B2B;

                &::before {
                    content: '';
                    display: block;
                    width: 26px;
                    height: 15px;
                    background-color: #E5E5E5;
                    border-radius: 13px;
                    transition: background-color .2s ease-in-out;
                    border: none;
                    box-shadow: none;
                }

                &:after {
                    content: '';
                    display: block;
                    width: 13px;
                    height: 13px;
                    position: absolute;
                    top: 1px;
                    left: 1px;
                    background-color: white;
                    border-radius: 50%;
                    transition: left .2s ease-in-out;
                }
            }

            &:checked + .w-input-label {
                &:before {
                    background-color: #707070;
                }

                &:after {
                    left: 12px;
                }
            }
        }
    }
}

#adresseForm {
    .w-submit-button,
    button.w-submit-button {
        height: 50px;
    }
}

.additionalAddressLine {
    width: 49% !important;
}

.delivery_lightbox {
    position: relative;
    .additionalAddressLine {
        width: 100% !important;
        display: flex !important;

        + .additionalAddressLine {
            margin-left: 0;
        }
    }
}

.w-form-line.notice {
    font-size: 12px;
    color: #636363;
}

.wrapper_delivery_choice {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 14px;
    margin: 47px 0 0;
}

.delivery_choice_btn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: all .3s ease-in-out;
    cursor: pointer;
    width: 180px;
    height: 100px;
    background-color: $white;
    border: 1px solid #2B2B2B;
    box-sizing: border-box;
    color: #2B2B2B;

    &:hover,
    &.selected {
        background-color: #2B2B2B;
        color: white;
    }

    .delivery_choice_btn_label {
        position: relative;
        font-size: 17px;
        text-transform: uppercase;
        margin-top: 45px;
        text-align: center;

        &:before {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            width: 40px;
            height: 40px;
            @include maskIconPath('../svg/spritesheet.svg');
            background-color: #2B2B2B;
        }
    }

    &:hover .delivery_choice_btn_label:before,
    &.selected .delivery_choice_btn_label:before {
        background-color: white;
    }

    /**
        * Delivery types icons
        */
    &[data-type='home_delivery'] {
        .delivery_choice_btn_label:before {
            @include maskIconCoord(19, 6, 6);
        }
    }

    &[data-type='relay_delivery'] {
        .delivery_choice_btn_label:before {
            @include maskIconCoord(24, 6, 6);
        }
    }

    &[data-type='store_delivery'] {
        .delivery_choice_btn_label:before {
            @include maskIconCoord(23, 6, 6);
        }
    }

    .delivery_choice_btn_price {
        font-size: 12px;

        span {
            font-size: 18px;
        }

        sup,
        .cents,
        .currency {
            font-size: 9px;
        }

        .cents {
            margin-left: -.2rem;
        }
    }

    &.home_delivery_btn .delivery_choice_btn_price .currency {
        position: relative;
        top: -.1rem;
    }

    &.disabled {
        pointer-events: none;
        display: inline-flex;
        width: 180px;
        height: 100px;
        background-color: rgba(43, 43, 43, .15);
        border-color: transparent;
        color: white;
        cursor: default;
        margin: 47px 0 0 15px;

        .delivery_choice_label {
            font-size: 12px;
            text-align: center;
            margin-top: .4rem;
        }

        &:hover {
            background-color: rgba(43, 43, 43, .15);
            border-color: rgba(43, 43, 43, .15);
            color: white;
        }

        .delivery_choice_btn_label:before {
            background-color: white;
        }
    }
}

.delivery_choice {
    display: none;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    opacity: 0;
    transition: all .3s ease-in-out;
    cursor: pointer;
    margin: 0 0 15px 0;
    border: 1px solid rgba(217, 217, 217, 1);
    padding: 19px 27px 18px 32px;

    input.choix_livraison {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 1;
        cursor: pointer;
        width: 100%;
        height: 100%;
    }

    .checkbox {
        position: absolute;
        top: 50%;
        left: -7px;
        transform: translateY(-50%);
        box-shadow: inset 0 0 0 15px white;
        border: 1px solid rgba(217, 217, 217, 1);
        border-radius: 50%;
        background: #2B2B2B;
        width: 15px;
        height: 15px;
        transition: all .3s ease-in-out;
    }

    &.selected {
        display: flex;
        opacity: 1;
        transition: all .3s ease-in-out;
    }

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &:hover,
    &.active {
        border-color: #2B2B2B;

        .checkbox {
            border-color: #2B2B2B;
            box-shadow: inset 0 0 0 2px white;
            transition: all .3s ease-in-out;
        }
    }

    .transporteur_type,
    .transporteur_id {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .transporteur_clickandgo_schedule {
        display: none;
    }

    .transporteur_delay {
        display: flex;
        flex-direction: column-reverse;
        font-size: 13px;
        margin-top: 3px;

        strong {
            display: block;
            color: #636363;
            font-weight: 400;
            line-height: 1.3;
            margin-top: 7px;

            span {
                display: block;
            }
        }
    }

    .warning_point_relais {
        margin: 2px 0 6px;
        font-weight: 300;
        font-size: 15px;
    }

    .shop_name {
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 15px 0 8px;

        +.adresse {
            font-size: 13px;
            line-height: 1.2;
        }
    }

    .adresse {
        margin: -2px 0 6px;
        font-weight: 400;
        font-size: 15px;
    }

    .horaire_today,
    .close_today {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 13px;
        margin-bottom: 5px;

        > span {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-right: 5px;

            &:before {
                content: '';
                display: block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                margin-right: 5px;
            }
        }
    }

    .horaire_today {
        > span {
            color: #63AB07;

            &:before {
                background-color: #63AB07;
            }
        }
    }

    .close_today {
        > span {
            color: #A50034;

            &:before {
                background-color: #A50034;
            }
        }
    }

    .bloc_horaires {
        position: relative;
        z-index: 2;
        margin-bottom: 10px;

        .title_horraire {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: fit-content;
            font-size: 13px;
            font-weight: 400;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }

            &:after {
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                @include maskIconPath('../svg/spritesheet.svg');
                @include maskIconCoord(26, 6, 6);
                background-color: #2B2B2B;
                margin: 2px 0 0 5px;
            }

            &.opened {
                &:after {
                    width: 8px;
                    height: 8px;
                    @include maskIconCoord(27, 6, 6);
                    margin: 2px 0 0 6px;
                }

                + .horaire {
                    display: block;
                }
            }
        }

        .horaire {
            display: none;
            font-size: 13px;
            margin: 10px 0;

            p {
                display: flex;
                line-height: 1.3;

                .title_week {
                    width: 100px;
                }
            }
        }
    }

    .show_delivery_info {
        position: relative;
        z-index: 2;
        font-size: 13px;
        font-weight: 500;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    .price_tag,
    .fdp_offered {
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .price_tag {
        display: flex;
        align-items: baseline;

        span {
            display: flex;
            align-items: baseline;
        }

        .unit,
        .cents,
        .currency {
            display: block;
        }

        .unit {
            order: 1;
        }

        .cents {
            order: 3;
            font-size: .4em;
            margin-left: -.3rem;
        }

        sup,
        .currency {
            order: 2;
            font-size: .5em;
            align-self: flex-start;
            margin-top: .3rem;
        }
    }
}

.addresses_recap {
    margin: 20px 0 -15px;
    border-top: 1px solid #999999;
    border-bottom: 1px solid #999999;

    .bgSprite {
        display: none;
    }

    .address {
        position: relative;
        padding: 15px 0;
        font-size: 13px;

        &#delivery_address_recap_domicile,
        &#delivery_address_recap_other {
            border-bottom: 1px solid #999999;
        }
    }

    .addr_label {
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: .04rem;
        margin-bottom: 5px;
    }

    .addr_line {
        line-height: 1.2;

        &.phone {
            display: block !important;
        }
    }

    .modlink {
        position: absolute;
        bottom: 15px;
        right: 0;
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }
}

.cgv_acceptance_mirror {
    position: relative;
    margin: 6px 0 0;

    div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 21px;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    #fake_accept_cgv {
        display: block;
        width: 14px;
        height: 14px;
        border: 1px solid #2B2B2B;
        box-sizing: border-box;
        background: #2B2B2B;
        box-shadow: inset 0 0 0 12px white;
        transition: all .3s ease-in-out;
        cursor: pointer;

        &.not_accepted {
            border-color: #D9000C;

            + p {
                color: #D9000C;
            }

            + p +.cgv_acceptance_error {
                display: block;
            }
        }

        &.accepted {
            box-shadow: inset 0 0 0 2px white;
        }
    }

    p {
        font-size: 12px;
        color: #2B2B2B;
        margin-left: 10px;
    }

    .cgv_acceptance_error {
        display: none;
        position: absolute;
        top: 16px;
        left: 15px;
        font-size: 12px;
        color: #D9000C;
    }

    #fake_btn_cmd_valid {
        display: flex;
        justify-content: center;
        align-items: center;
        width: max-content;
        height: 50px;
        padding: 0 1.3rem;
        background-color: #2B2B2B;
        border: 1px solid #2B2B2B;
        font-family: $primaryFont;
        font-size: 17px;
        color: white;
        text-transform: uppercase;
        cursor: pointer;
        transition: background-color .2s ease-in-out, color .2s ease-in-out;

        &:hover {
            background-color: white;
            color: #2B2B2B;
        }

        &.loading {
            cursor: default;
            background: white url(../svg/three_dots_black.svg) no-repeat center center;
            background-size: auto 25%;
            color: transparent;
        }
    }
}

#delivery_home_choice {
    display: block;
    top: 0;
    right: -500px;
    left: auto;
    height: 100vh;
    width: 500px;
    padding: 30px;
    box-sizing: border-box;
    cursor: default;
    transition: right .3s ease-in-out;

    &.actif {
        right: 0;
        z-index: 501;
    }

    .lightbox_delivery_title {
        font-size: 17px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .wrapper_select_adr {
        width: 100%;
        height: 86vh;
        padding-right: 5%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .addresses_recap {
        margin: 0;
        border: 0;

        section {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        .addr_line {
            span {
                display: block;

                &.civility {
                    display: initial;
                }
            }
        }
    }

    .hidden_elem_liv > .wrapper_select_adr {
        padding-bottom: 0;
    }

    .address {
        position: relative;
        padding: 18px;
        border: 1px solid #D9D9D9;
        box-sizing: border-box;
        cursor: pointer;

        &:last-child {
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            height: 50px;
            padding: 0;
            border: 1px solid #2B2B2B;
            background-color: white;
            font-family: $primaryFont;
            font-size: 17px;
            transition: background-color .3s ease-in-out, color .3s ease-in-out;

            &:hover {
                background-color: #2B2B2B;
                color: white;
            }

            .infos {
                padding: 0;
                font-size: 17px;
                text-transform: uppercase;
            }

            .addr_line.full_address {
                margin-bottom: 0;
            }
        }

        .modlink {
            right: 18px;
            bottom: 18px;
        }

        .add_addr {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &.loading {
                background: #2B2B2B url(../svg/three_dots.svg) no-repeat center;
                background-size: auto 24%;
            }
        }

        &:hover {
            background-color: rgba(0, 0, 0, .05);
        }

        &.processing {
            color: transparent;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, .05) url(../svg/three_dots_black.svg) no-repeat center center;
                background-size: auto 22%;
                cursor: default;
            }
        }
    }

    .notice {
        display: none;
    }

    .w-has-tel {
        .w-input-container:first-child {
            display: none;
        }

        .w-input-container+.w-input-container {
            margin-left: 0;
        }

        .errormsgadd {
            font-size: 11px;
        }
    }

    .w-input-note {
        margin: .1rem 0 0;
    }

    .w-form-line.w-submit {
        max-width: 100%;
    }

    .w-submit-button,
    button.w-submit-button {
        width: 100%;
        height: 50px;
        font-family: $primaryFont;
        font-size: 17px;
    }
}

.lightbox.delivery_lightbox {
    display: block;
    top: 0;
    right: -500px;
    left: auto;
    height: 100vh;
    width: 500px;
    padding: 30px;
    box-sizing: border-box;
    cursor: default;
    transition: right .3s ease-in-out;

    &.actif {
        right: 0;
        z-index: 501;
    }

    .lightbox_delivery_title {
        font-size: 17px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .delivery_form_title {
        display: none;
    }

    .wrapper_relais_top {
        position: relative;
        z-index: 5;
    }

    .map_canvas {
        width: 100%;
        height: 210px;
        margin: 20px 0;
    }

    .sidebar-right {
        height: 58vh;
        margin-top: 20px;
        width: 96%;
        padding: 0 4% 0 0;
    }

    .kp {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 20px 0 10px;
        border-top: 1px solid #2B2B2B;
        font-size: 13px;

        &:first-child {
            padding-top: 0;
            border-top: 0;
        }
    }

    .clickandgo_withdrawal,
    .clickandgo_schedule,
    .phone {
        display: none;
    }

    .name {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        order: 1;
        font-weight: 600;
        text-transform: uppercase;
    }

    .distance {
        font-weight: 600;
        text-transform: uppercase;
        font-style: italic;
        margin-left: .3rem;

        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }
    }

    .address {
        order: 2;
        width: 100%;
        margin: 6px 0;
    }

    .todayHours {
        order: 3;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 7px;
    }

    .puce_store {
        &_open,
        &_closed {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        &_open{
            .mag_open_status {
                color: #63AB07;
                margin-right: .3rem;
            }

            &:before {
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                background-color: #63AB07;
                border-radius: 50%;
                margin-right: .3rem;
            }
        }

        &_closed {
            .mag_open_status {
                color: #A50034;
                margin-right: .3rem;
            }

            &:before {
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                background-color: #A50034;
                border-radius: 50%;
                margin-right: .3rem;
            }
        }
    }

    .btn_toggle_hours {
        order: 4;
        position: relative;
        display: flex;
        align-items: center;
        width: fit-content;
        margin-bottom: 10px;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }

        &:after {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            @include maskIconPath('../svg/spritesheet.svg');
            @include maskIconCoord(26, 6, 6);
            background-color: #2B2B2B;
            margin: 2px 0 0 5px;
        }

        &.open:after {
            width: 8px;
            height: 8px;
            @include maskIconCoord(27, 6, 6);
            margin: 2px 0 0 6px;
        }
    }

    .openingHours {
        order: 5;
        display: none;

        p {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            width: calc(100% - 150px);

            span {
                width: 65%;
            }
        }

        &.open {
            display: block;
        }
    }

    &.opened {
        .btn_toggle_hours:after {
            content: '-';
        }

        .openingHours {
            display: block;
        }
    }

    .btn_container {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 140px;
        height: 50px;

        a.relayLink {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            font-size: 17px;
            background-color: white;
            color: #2B2B2B;
            text-transform: uppercase;
            border: 1px solid #2B2B2B;
            transition: background-color .3s ease-in-out, color .3s ease-in-out;

            &:hover {
                background-color: #2B2B2B;
                color: white;
            }
        }
    }
}

/*
 * Customer edit address lightbox
*/
#lightboxCustomerAddress {
    display: block;
    top: 0;
    right: -500px;
    left: auto;
    height: 100vh;
    width: 500px;
    padding: 30px;
    box-sizing: border-box;
    cursor: default;
    transition: right .3s ease-in-out;

    &.actif {
        right: 0;
        z-index: 501;
    }

    .popup_container {
        width: 105%;
        height: 86vh;
        padding-right: 5%;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

/*
 * Special case for account pages
 */
body.client_addresses {
    #lightboxCustomerAddress {
        .popup_container {
            height: 90vh;
        }
    }
}