.btn {
    cursor: pointer;
}
.button {
    opacity: 1;
    font-size: 15px;
    &.cfg_print{
        display: flex;
        align-items: center;
        line-height: 50px;
        padding-left: 0;
        &:before {
            display: inline-block;
            width: 30px;
            height: 25px;
            background-image: url(../svg/icn_print.svg);
            margin-right: 5px;
            content: '';
        }
    }
    &.primary.btn_send_info{
        background: $grey3;
        border: 1px solid $grey3;
        color: $white;
        &:hover {
            background: $white;
            border: 1px solid $grey3;
            color: $grey3;
        }
    }
    &.secondary.btn_to_store{
        background: $white;
        border: 1px solid $grey3;
        color: $grey3;
        &:hover {
            background: $grey3;
            border: 1px solid $grey3;
            color: $white;
        }
    }
}
.btn.white + .loader {
    background: #fff;
    border: 1px solid $black;
    color: $black;
}
.btn, .loader {
    text-align: center;
    line-height: 45px;
    box-sizing: border-box;
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: 600;
        text-transform: capitalize;
    }
}
