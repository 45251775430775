/******************************************************************************/
/* ACCOUNT GENERAL LAYOUT */
/******************************************************************************/
.moncompte #appContainer .page_wrapper #site_global_wrap,
.in_tunnel #appContainer .page_wrapper #site_global_wrap {

    .w-input-container {
        @include ie() {
            .w-input .w-input-element {
                line-height: 1 !important;
            }
        }
    }

    .w-input-element:not([type=radio]):not([type=checkbox]) {
        border: 1px solid $grey3;
        border-radius: 0;
    }
}

.wrapper_moncompte {
    width: 100%;
    min-width: 990px;
    margin: 0 auto;

    .wrapper_menu_compte {
        display: flex;
        justify-content: flex-start;
        margin-top: 83px;
        padding-bottom: 14.5px;
        padding-left: 1.5px;
        border-bottom: 1px solid $grey3;
        color: $darkenGrey;
        color: $grey3;
        font-weight: $light;
        font-size: 14px;
        line-height: 17px;
        font-family: $primaryFont;
        letter-spacing: 0.05em;
        text-transform: uppercase;

        .usrname {
            position: absolute;
            top: 23px;
            left: 0;
            min-width: max-content;
            line-height: initial;

            p {
                color: $cmsPageTitle;
                font-weight: $light;
                font-size: 28px;
                line-height: 35px;
                font-family: $eymenpro;
                letter-spacing: 0.05em;
                text-transform: none;
            }
        }
    }

    .left-container {
        width: 35.64vw;

        @media screen and (max-width: 1250px) {
            width: 25.64vw;
        }

        .moncompte_myinfos_img_holder {
            width: 100%;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    h1 {
        font-weight: $bold;
        font-size: 2rem;
        text-transform: uppercase;
    }

    h2 {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 3.6rem 0 0 0;
        padding-bottom: 1rem;
        color: $darkenGrey;
        font-weight: 900;
        text-transform: uppercase;

        @-moz-document url-prefix() {
            font-weight: 100;
        }
    }

    strong {
        font-weight: 600;
    }

    .update_success {
        margin-bottom: 20px;
    }

    .conteneur_adresse {

        @media only screen and (max-width: 1410px) {
            width: 94%;
        }
    }

    .flexAccount {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 100%;
        max-width: 63.737vw;
        min-height: 63vh;
        padding-left: 3.263vw;

        @media screen and (max-width: 1250px) {
            max-width: 65vw;
            padding-left: 2.263vw;
        }

        .accountLeft {
            box-sizing: border-box;
            position: relative;
            max-height: 900px;
            margin-bottom: 28.5px;

            .item {
                margin-right: 3.474vw;
                color: $grey3;
                font-weight: $light;
                font-size: 14px;
                line-height: 17px;
                font-family: $primaryFont;
                letter-spacing: 0.05em;
                text-transform: uppercase;

                @media screen and (max-width: 1550px) {
                    margin-right: 1.4vw;
                    font-size: 12px;
                }

                &::nth-of-type(1) {
                    margin-top: 2rem;
                }

                &.actif {
                    font-weight: $semibold;
                }
            }
        }

        .wrapper_moncompte_main {
            box-sizing: border-box;

            .w-login-form {
                width: 98%;
            }

            form#clientForm,
            .contain_padd {
                @media screen and (max-width: 1250px) {
                    width: 96%;
                }
            }

            .wrapper_form {
                form {
                    margin: 0;

                    #iconErrclientTel {
                        display: none;
                    }

                    .w-form-line {
                        margin-bottom: 1.8rem;

                        .w-radio-group .w-radio-input:nth-of-type(3) {
                            margin-left: 38px;
                        }

                        .w-input-container {

                            .w-text-input {

                                .w-input-element {
                                    height: 3.35em;
                                }
                            }

                            .w-date-input {
                                .w-input-element {
                                    padding-top: 0.6rem;
                                }
                            }
                        }

                        &.w-submit,
                        &.w-loader {
                            .w-submit-button {
                                width: 15rem;
                            }

                            span {
                                font-weight: $regular;
                                font-size: 17px;
                                font-family: $primaryFont;
                            }
                        }

                        &.w-loader {
                            width: 123px !important;
                            max-width: none;
                        }

                        &:last-of-type:not(.w-submit) {
                            margin-bottom: 1.55rem;
                        }
                    }
                }
            }

            #telephone {
                width: 100%;
            }

            .table_header {
                border: 1px solid $tableGrey;

                td:nth-child(-n+1) {
                    border-right: 1px solid $tableGrey;
                }
            }

            .contain_padd {
                margin-bottom: 1.2rem;

                a {
                    color: $darkenGrey;
                    font-weight: $extrabold;
                    text-decoration: underline;
                }

                span {
                    color: $darkenGrey;
                    font-weight: $regular;
                    font-size: 14px;
                    line-height: 1.4;
                }
            }

            .intro {
                font-size: .8rem;

                span.nb_products {
                    text-transform: lowercase;
                }
            }

            .customChecker {
                display: flex;
                justify-content: space-between;
                margin-right: 4rem;
                padding: 0 0.2rem;
            }

            .formRow {
                display: block;

                .input_container+.input_container {
                    margin-left: 0;
                }

                .input_container {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    .wrapper_inputs {
                        display: flex;
                        width: calc(100% - 160px);
                    }
                }

                label {
                    &:not(.txt_civilite) {
                        display: inline-block;
                        width: 160px;
                        font-size: .8rem;
                    }

                    &.txt_civilite {
                        margin-right: 1.5rem;
                        float: left;
                        font-size: .8rem;
                    }
                }

                input {
                    width: calc(100% - 160px);
                    height: 35px;
                    background: $white;
                    text-indent: 12px;
                }

                #clientTel {
                    width: 100%;
                    max-width: 260px;
                    height: 35px;
                    border: 1px solid #ecece8;
                    font-size: 12px;
                    text-indent: 3px;
                }

                select {
                    -webkit-appearance: menulist;
                    -webkit-rtl-ordering: logical;
                    box-sizing: border-box;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 0 0 0 .5rem;
                    border: 1px solid #ecece8;
                    background-color: white;
                    color: black;
                    font-size: 12px;
                    line-height: 2;
                    text-indent: 2px;
                    white-space: pre;
                    cursor: default;
                }

                .date_select {
                    width: calc(100% - 160px);
                    height: 100%;

                    .form_itm {
                        width: 15%;
                        margin-right: .6rem;
                    }

                    &.country {
                        width: 160px;
                    }
                }
            }

            .input_container_newsletter {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;

                .btn_container {
                    text-align: right;

                    .btn_placer {
                        display: inline-block;
                        position: relative;
                        width: 140px;
                        height: 50px;
                        padding: 5px;
                        font-size: 15px;

                        #btn_infos_2 {
                            span {
                                top: -42px;
                            }
                        }
                    }

                    .btn_buy {
                        top: 4rem;
                        right: 0;

                        span {
                            z-index: 1;
                        }
                    }

                }

                label {
                    width: 100%;
                }

                .customChecker {
                    justify-content: left;
                    width: 100%;
                    margin-right: 0
                }

                .wrapper_labels {
                    display: flex;
                    width: 33%;

                    .changeSubscribe {
                        margin-left: 6%;
                    }
                }
            }

            &.wrapper_content_mes_infos {
                .formRow .date_select {
                    display: flex;
                    position: relative;

                    .form_itm {
                        position: static;
                        width: 15%;
                        margin-right: 1rem;

                        &.select {
                            select.inputErr {
                                border: 1px solid red;
                            }
                        }
                    }
                }
            }

            &.wrapper_content_wishlist {
                .intro {
                    .connect {
                        display: none;
                    }
                }
            }
        }
    }

    table {
        width: 100%;
        color: $lightBlack;

        th {
            border: 1px solid #e9e9e9;
            font-weight: $regular;
            font-size: .82rem;
            line-height: 2.6;
        }

        tr {
            td {
                box-sizing: border-box;
                padding: 15px 20px;
                color: $darkenGrey;
                font-weight: 800;
                font-size: 13px;

                &.message {
                    padding: 1rem 0 1rem 2rem;

                    .recap_msg {
                        span {
                            display: block;
                            text-transform: uppercase;
                        }
                    }
                }

                a {
                    color: $black;
                    text-decoration: none;

                    &.btn_detail {
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                .btn_ouvrir_message {
                    text-decoration: underline;
                    cursor: pointer;

                    &:hover {
                        text-decoration: none;
                    }
                }

                &.wrapper_message_detail {
                    padding: 0;
                    border-top: 0px;

                    .message_detail {
                        margin-bottom: 1rem;
                    }
                }

                &.nothing {
                    padding: 2rem 0;
                }
            }
        }
    }

    #contact_form {
        table tr td {
            border: none;
        }
    }

    .addrType {
        width: fit-content;
    }
}

/******************************************************************************/
/* MY INFORMATIONS */
.mes_infos {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $fadeGrey;

    .input_container {
        display: flex;
        position: relative;
        height: 32px;
    }

    .changeSubscribe {
        font-size: .9rem;

        a {
            color: $black;
            text-decoration: none;

            span {
                position: relative;
                text-decoration: underline;
            }

            &:hover {
                span {
                    text-decoration: none;
                }
            }
        }
    }

    .btn_submit {
        position: relative;
        width: 100%;
        height: 100%;

        &.loading {
            .loader {
                display: block;
            }
        }
    }

    .date_select .more_info {
        width: 45%;
        font-size: 0.8em;
    }
}

/******************************************************************************/
/* MES DISTRIBUTEURS FAVORIS*/
/******************************************************************************/
.wrapperMagDetails {

    .conteneur_adresse {
        @media screen and (max-width:1024px) {
            width: 85%;
        }
    }

    .content.store_content {
        display: flex;
        position: relative;
        margin: 0 0 1.3rem 0;
        padding: 1.5rem;
        border: 1px solid $fadeGrey;

        .wrapperImgMag {
            width: 40%;
            height: auto;

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }

        .wrapperInfosMag {
            .btn_buy {
                right: 0;
                left: inherit;
            }
        }

        .wrap_info_add {
            width: 39%;
            margin-left: 3.5rem;
            padding-top: 29px;

            @media screen and (max-width:1024px) {
                width: 36%;
                margin-left: 2.4rem;
            }

            div {
                padding-bottom: 1rem;
            }
        }

        .wrapperInfosMag {
            &:before {
                position: absolute;
                left: 43%;
                width: 20px;
                height: 20px;
                content: '';
            }

            strong {
                text-transform: uppercase;
            }

            p {
                color: $darkenGrey;
                font-weight: 500;
            }
        }

        .name:before {
            background: url('../img/mag_sprite.png') no-repeat left;
        }

        .opening:before {
            width: 21px;
            background: url('../img/mag_sprite.png') no-repeat center;
        }

        .tel:before {
            width: 19px;
            background: url('../img/mag_sprite.png') no-repeat right;
        }
    }
}

/******************************************************************************/
/* NEWSLETTER INSCRIPTION/DESINSCRIPTION */
/******************************************************************************/
.newsletter {
    .main_menu {
        position: relative;
    }

    .breadcrumbs_wrapper {
        position: absolute;
        top: 100%;
        left: 0;
        width: auto;
        max-width: none;
        transform: none;
    }

    &.hasBandeauHeader {
        .breadcrumbs_wrapper {
            top: unset;
        }
    }

    .wrapper_moncompte {
        display: flex;
        margin-bottom: 15px;
    }
}

.content_newsletter {

    #subscriptionForm {
        .w-email-input .w-input-element {
            border-radius: 0;
        }

        .w-email-input .w-input-label {
            font-family: $primaryFont;
        }

        .optin-select-line .w-input-label::before {
            border: none;
        }

        .w-form-line.w-submit {
            width: 123px;

            button {
                width: 100%;
            }
        }

        .w-submit span,
        .w-loader span {
            font-weight: $regular;
            font-size: 17px;
            font-family: $primaryFont;
        }
    }

    .intro {
        margin: 0 0 1rem 0;
    }

    .wrapper_form {
        margin: 0 0 6rem 0;

        .input_container:not(.checkbox_container) {
            height: 34px;
            margin: 0 0 .5rem 0;

            .content_news_suppr {
                height: 34px;
            }
        }

        .customChecker label span {
            line-height: 1.3;
        }

        .btn_container {
            width: 264px;
        }
    }

    .wrapperConfirmation {
        margin: 0 0 300px 0;

        h3 {
            margin: 1rem 0;
            font-size: 1rem;
        }

        p {
            font-size: .8rem;
        }
    }

    &.add {
        .wrapper_form .btn_container {
            margin: 1rem 0 0 0;
        }
    }

    &.delete {
        .input_container:not(.checkbox_container) {
            max-width: 650px;
            margin: 0 0 1rem 0;

            .content_news_suppr {
                height: 34px;
            }
        }

        .checkbox_container {
            margin: 0 0 1.5rem 0;
        }
    }
}

body.en {
    &.newsletter,
    &.customer {
        .w-form-line.w-submit {
            min-width: 15rem !important;
        }
    }
}

.content_newsletter {
    .w-form-line.w-submit {
        min-width: 245px!important;

        button {
            span {
                text-wrap: nowrap;
            }
        }
    }
}

/* NEWSLETTER PREFERENCES */

.content_newsletter.news_pref {
    h2 {
        margin: 0 0 0;
    }

    .contain_padd {
        margin-bottom: 1.2rem;
        margin-left: 0 !important;

        .sub_title:last-of-type {
            display: block;
            margin-top: 10px;
        }
    }

    .newsletter_option {
        margin-bottom: 15px;

        label {
            display: block;
            font-size: 13px;
            cursor: pointer;

            strong {
                font-weight: 600;
                font-size: 14px;
            }
        }
    }

    &+.contact_right.f_left {
        position: absolute;
        top: 90px;
        right: 0;
        width: 35%;
    }
}

/******************************************************************************/
/* MES COMMANDES */
/******************************************************************************/

.wrapper_mes_commandes {

    .clearfix {
        width: auto;
        margin-right: 2.368vw;
    }

    .conteneur_adresse {
        .content td {
            border: 0;
        }
    }

    .wrapper_menu_tab {
        display: flex;
        align-items: center;
        margin: .5rem 0 0 0;

        @media screen and (max-width: 1200px) {
            display: block;
        }

        .filtres_commandes {

            @media screen and (max-width: 1200px) {
                margin-bottom: 2rem;
            }
        }

        select#year {
            width: 261px;
            height: 3rem;
            padding-left: 43px;
            border: 2px solid #8C857B;
            background: $white url('../img/arrow_show_btm.png') no-repeat;
            background-position: 84% 50%;
            background-color: $white;
            color: $grey3;
            font-weight: $bold;
            font-size: 14px;
            line-height: 17px;
            font-family: $primaryFont;
            letter-spacing: 0.05em;
            text-indent: 0;
            text-transform: uppercase;
            cursor: pointer;
        }

        .date_select {
            width: 260px;
            height: 100%;
            margin: 0 2rem 0 0;
            border-color: $black;

            @include queriesWshop(1200px, max) {
                margin: 0;
            }
        }

        #com_onglet {
            display: flex;
            align-items: stretch;
            justify-content: center;

            li {
                position: relative;
                flex: 1;

                a,
                .no_cmd {
                    border-bottom: 1px solid $black;
                }
            }

            .nb_order {

                &::before {
                    content: "(";
                }

                &::after {
                    content: ")";
                }
            }

            .label_order {
                text-transform: none;
                white-space: nowrap;
            }

            .no_cmd,
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                margin: 0 1rem -2px 1rem;
                color: $black;
                font-size: .9rem;

                @include queriesWshop(1200px, max) {
                    padding: 0 .5rem;
                }

                .nb_order {
                    height: 20px;
                    margin: 0 0 0 .5rem;
                    color: $black;
                    font-size: .8em;
                    line-height: 22px;
                    text-align: center;

                    @include ie {
                        margin: 0 0 4px 8px;
                        line-height: 24px;
                    }
                }
            }
        }
    }

    .content {
        .facture {
            position: relative;

            a {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                &:before {
                    display: block;
                    width: 9px;
                    height: 11px;
                    margin: 0 .2rem 0 .6rem;
                    background: url('../img/telechargez_commande.png') no-repeat left;
                    content: '';
                }
            }
        }
    }

    #orders_by_type {
        margin: 39px 0 0 0;

        table {
            border: none;

            th {
                border: none;
                color: $cmsPageTitle;
                font-weight: $bold;
                font-size: 13px;
                line-height: 16px;
                font-family: $primaryFont;
                letter-spacing: 0.05em;
                text-align: left;
                text-transform: uppercase;

                &:first-child {
                    padding-left: 1rem;
                    box-sizing: border-box;
                }
            }

            td {
                color: #2B2B2B;
                font-weight: $regular;
                font-size: 13px;
                line-height: 16px;
                font-family: $primaryFont;
                text-align: left;

                &:first-child {
                    padding-left: 1rem;
                    box-sizing: border-box;
                }

                &.montant {
                    font-size: 18px;

                    sup,
                    .cents {
                        font-size: .5em;
                    }
                }

                .btn.shipup_cta {
                    display: flex;
                    background-color: transparent;
                    color: #2B2B2B;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: normal;
                    font-family: $primaryFont;
                    letter-spacing: 0.65px;
                    text-align: left;
                    text-decoration-line: underline;
                    text-indent: initial;
                    cursor: pointer;
                }
            }

            span.transporteur {
                color: $errorRed;
            }
        }

        .etat {

            .transporteur {

                @media screen and (max-width: 1500px) {
                    display: block;
                    width: fit-content;
                    padding-right: 3px;
                    font-size: 11px;
                }

            }

            .transporteur.livraison {
                color: $cmsPageTitle;
            }
        }

        .btn_detail {
            position: relative;
            padding: 10px 24px;
            color: #2B2B2B;
            font-weight: $medium;
            font-size: 13px;
            line-height: 16px;
            font-family: $primaryFont;
            letter-spacing: 0.05em;
            text-decoration: underline;
        }
    }

    .wrapper_msg_bounce {
        width: 90%;
        max-width: 984px;
        margin-left: 1rem;

        h3 {
            margin-bottom: 0.5rem;
            color: $green;
        }

        a {
            font-weight: 600;
        }
    }
}

.es .wrapper_mes_commandes .wrapper_menu_tab select#year {
    padding-left: 35px;
    font-size: 13px;
}

.de .wrapper_mes_commandes .wrapper_menu_tab select#year {
    padding-left: 34px;
    font-size: 12px;
}

body.de {

    .wrapper_mes_commandes .wrapper_menu_tab #com_onglet .no_cmd,
    .wrapper_mes_commandes .wrapper_menu_tab #com_onglet a {
        font-size: .8rem;
    }

    .wrapper_mes_commandes .wrapper_menu_tab #com_onglet .label_order {
        white-space: initial;
    }
}

.under_menu_text {
    margin-bottom: 1rem;
}

/******************************************************************************/
/* MES COMMANDES DETAILS*/
/******************************************************************************/
.order {

    .wrapper_moncompte {
        display: flex;
    }

    .accountLeft h1 {
        display: none;
    }

    .usrname p {
        display: block;
    }

    .breadcrumbs_wrapper {
        max-width: 1690px;
    }

    .wrapper_content_cmd_detail {

        .cmd_table {

            .sub_total_cmd {
                text-transform: uppercase;
            }

            thead {
                height: 23px;
                background-color: #F3F3F3;
            }

            th {
                border: none;
                color: #8A8279;
                font-weight: $bold;
                font-size: 13px;
                line-height: 16px;
                font-family: $primaryFont;
                letter-spacing: 0.05em;
                text-transform: uppercase;
            }
        }
    }

    .holder_content {
        display: flex;

        @media screen and (max-width: 1800px) {
            display: block;
            max-width: 725px;
        }
    }

    .holder_content .wrapper_left {
        width: 725px;
        max-width: 100%;
        margin-right: 3.368vw;

        .go_back {
            display: flex;
            align-items: center;
            margin-bottom: 38px;
            color: $black;
            font-weight: $regular;
            font-size: 12px;
            line-height: 14px;
            font-family: $primaryFont;

            .prev_cmd_detail {
                width: 4px;
                height: auto;
                margin-right: .5rem;
            }
        }

        .wrapper_infos_cmd {

            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2rem;

            .infos_cmd {
                position: relative;
            }

            .cmd_number {
                margin-bottom: 1rem;
                color: $black;
                font-size: 14px;
                line-height: 17px;
                font-family: $primaryFont;
                text-transform: uppercase;

                span {
                    font-weight: $semibold;
                }
            }

            .cmd_date,
            .cmd_status,
            .num_suivi {
                margin-bottom: .3rem;
                color: $black;
                font-weight: $semibold;
                font-size: 14px;
                line-height: 17px;
                font-family: $primaryFont;

                &::first-letter {
                    text-transform: uppercase;
                }

                span {
                    font-weight: 100;
                }
            }

            .cmd_cta {
                position: absolute;
                top: 20px;
                right: 1%;
                width: 236px;
                height: 50px;
                background-color: $grey3;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                span {
                    font-weight: $regular;
                    font-size: 17px;
                    font-family: $primaryFont;
                    text-transform: uppercase;
                }
            }

            .btn.shipup_cta {
                display: flex;
                align-items: center;
                justify-content: center;
                width: fit-content;
                padding: 15px 25px;
                background-color: #2b2b2b;
                color: #fff;
                font-weight: 600;
                font-size: 17px;
                line-height: normal;
                font-family: $primaryFont;
                text-align: center;
                text-indent: initial;
                text-transform: uppercase;

                &:hover {
                    border: 1px solid #2b2b2b;
                    background-color: #fff;
                    color: #2b2b2b;
                }
            }
        }

        .cmd_table {
            border-bottom: 1px solid #8C857B;

            &:last-of-type {
                border: none;
            }

            thead {
                background: #F3F3F3;
            }

            td {
                padding-top: 26px;
            }

            th {
                border: none;
                color: #8A8279;
                font-weight: $bold;
                font-size: 13px;
                line-height: 16px;
                font-family: $primaryFont;
                letter-spacing: 0.05em;
                text-transform: uppercase;
            }

            .cmd_img_info {
                display: flex;
                max-width: 334px;
                padding-right: 0;
                padding-left: 0;
            }

            .cart_thumb_holder {
                width: 140px;
                min-width: 140px;
                height: 140px;
                margin-right: 17px;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .cart_thumb_info {

                .sstitre {
                    margin: 1rem 0rem 0rem 0rem;
                    color: $grey3;
                    font-weight: $semibold;
                    font-size: 14px;
                    line-height: 17px;
                    font-family: $primaryFont;
                }

                .sub_title {
                    font-weight: $thin;
                }

                .sub_couleur.sub,
                .sub_taille.sub {
                    display: none;
                }

                .download-icon {
                    margin: 0;
                }

                .pdf_download {
                    margin-top: .3rem;
                    font-weight: $medium;
                    font-size: 13px;
                }
            }

            .crossed,
            .cmd_tot_price .pricetag {
                color: $grey3;
                font-weight: $semibold;
                font-size: 30px;
                font-family: $primaryFont;
            }
        }
    }

    .holder_content .wrapper_right {

        .cmd_detail_top {
            min-width: 18.947vw;
            margin-bottom: 5px;
            padding: 30px 36px 36px 37px;
            background-color: rgba(136, 136, 136, 0.1);
        }

        .titre {
            margin-bottom: 5px;
            color: $grey3;
            font-weight: $bold;
            font-size: 17px;
            line-height: 26px;
            font-family: $primaryFont;
            letter-spacing: 0.05em;
            text-transform: uppercase;
        }

        .clearfix {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 19px;

            label {
                color: $grey3;
                font-weight: $regular;
                font-size: 14px;

                &.montant_total {
                    font-weight: $semibold;
                    font-size: 14px;
                    text-transform: uppercase;
                }
            }

            .pricetag {
                font-weight: $semibold;
                font-size: 30px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            &::after {
                display: none;
            }

            &.montant_total_wrap {
                margin-top: 15px;
                padding-top: 13px;
                border-top: 1px solid $grey3;
            }
        }

        .wrapper_delivery_addr {
            width: 185px;
            height: 132px;
            margin: 32px 0 0 36px;
            color: #000000;
            font-size: 14px;
            font-family: $primaryFont;

            .addr_title {
                margin-bottom: 1rem;
                font-weight: $semibold;
                text-transform: uppercase;
            }
        }

        .wrapper_cmd_retour {
            margin-bottom: 25px;

            #comment_retourner_cmd {
                width: 100%;
                margin-top: 0;
                padding: 0;
                background-color: $white;
            }

            .w-submit-button.dark.down_bill {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 100%;
                max-height: 50px;
                margin-bottom: 25px;
                background-color: $white;
                color: $grey3;
                font-weight: $regular;
                font-size: 17px;
                line-height: 20px;
                font-family: $primaryFont;
                text-transform: uppercase;
            }

            .intro {
                max-width: 253px;
                margin: auto;
                margin-top: 10px;
                color: $black;
                font-weight: $regular;
                font-size: 13px;
                line-height: 16px;
                font-family: $primaryFont;
                text-align: center;

                strong {
                    text-decoration: underline;
                }
            }

            a.see_remb {
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                max-height: 50px;
                background-color: #2b2b2b;
                color: $white;
                font-weight: 600;
                font-size: 17px;
                line-height: normal;
                font-family: $primaryFont;
                text-align: center;
                text-indent: initial;
                text-transform: uppercase;

                &:hover {
                    background-color: $white;
                    color: #2b2b2b;
                    font-weight: 400;
                }
            }
        }

        .cmd_faq p {
            color: $grey3;
            font-weight: $regular;
            font-size: 13px;
            line-height: 16px;
            text-align: center;

            a {
                text-decoration: underline;
            }
        }
    }
}

.wrapper_moncompte {

    #orders_by_type {

        .content tr {
            border-bottom: 1px solid #8C857B;
        }
    }

    .details_commandes_infos {
        border: none;

        .mes_commandes_details_box {

            label {
                color: $black;
                font-weight: $semibold;
                font-size: 14px;
                line-height: 17px;
                font-family: $primaryFont;
                text-transform: none;
            }

            span {
                color: $black;
                font-weight: $regular;
            }

            #statut {
                letter-spacing: 0;
                text-transform: none;
            }
        }

        .title.camion {
            background: none;
            color: #000000;
            font-weight: $semibold;
            font-size: 14px;
            line-height: 162%;
            font-family: $primaryFont;
            letter-spacing: 0;
        }

        .details_commande_livraison_box span#nom {
            margin-bottom: 1.5rem;
        }

        .details_commande_livraison_box span#nom,
        .details_commande_livraison_box address {
            color: #000000;
            font-weight: $regular;
            font-size: 13px;
            line-height: 15.6px;
        }

        .title.commande {
            background: none;
            color: $black;
            font-weight: $semibold;
            font-size: 14px;
            line-height: 17px;
            font-family: $primaryFont;
            letter-spacing: 0;
            text-transform: uppercase;
        }

        td {
            border: none !important;
        }
    }

    #content_compte #resume_commande {

        td,
        tr,
        th {
            border: none !important;
        }

        .title_resume_cmd {
            background-color: $white;

            th {
                padding: 0 0 0 10px;
                font-size: 0.7rem;
                line-height: 1.4;
            }
        }

        .ligne_panier {
            border-top: 1px solid $tableGrey;
            border-bottom: 1px solid $tableGrey;

            .img_panier {
                width: 100%;
                height: auto;
            }
        }
    }

    #wrapper_bottom_cmd_holder {
        width: 96%;

        table tr td {
            border: none;
        }

        #comment_retourner_cmd {
            width: 45%;
            background-color: $white;

            h3 {

                margin-bottom: 0.5rem;
                color: $green;
                font-size: 15px;
            }

            p {
                font-size: 11px;
            }

            .comment_retourner_cmd_txt {
                margin-bottom: 0.5rem;

                a {
                    text-decoration: underline;
                }
            }
        }

        .details_commandes_montant_box {
            width: 360px;
            margin: 1.9rem 0 0;
            background: rgba(136, 136, 136, 0.1);

            td {
                padding-right: 10px !important;
                font-size: 10px;

                &:last-child {
                    font-size: 12px;
                }
            }

            tr {
                &:last-child td {
                    font-size: 13px;
                }
            }
        }
    }
}

/******************************************************************************/
/* CONTACT */
/******************************************************************************/

.wrapper_content_contact {
    .contact_form_holder {
        margin: 0;

        .selectStyled {
            box-sizing: border-box;
            width: 100%;
            padding: 0.5rem;
            border: 1px solid $fadeGrey;

            select {
                width: 100%;
                height: 37px;
            }

            .clone {
                width: 100% !important;
                max-width: 97%;
                padding-right: 3%;
                overflow: hidden;
                background: url('../img/select_arrow_large.png') no-repeat right;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

/******************************************************************************/
/* MESSAGES */
/******************************************************************************/

.wrapper_moncompte {
    table tr td {

        .btn_ouvrir_message,
        .actif {
            height: 25px;
            padding: 0 11px;
            text-decoration: none;
        }
    }

    .selectStyled select {
        height: 37px;
    }

    .btn_ouvrir_message {
        margin-left: 16px;
        border: 2px solid $green;
        background: $white;
        color: $darkenGrey;
        text-decoration: none;
        text-transform: capitalize;
        cursor: pointer;
    }

    .btn_ouvrir_message.actif {
        background: #c0cd1c;
    }

    .details_msg_content {
        &.class_addline {
            background: none;
        }

        &:nth-child(2n+3) {
            margin: 1rem 0;
            border-left: 2px solid #f2f2f2;
        }

        &:nth-child(even) {
            padding: 0.5rem;
            background-color: #f2f2f2;

            .infos_message {
                background-color: #f2f2f2;
            }
        }

        &:nth-child(n+2) {
            margin-left: 2rem;
        }

        &:nth-child(n+3) {
            margin-left: 4rem;
        }

        &:nth-child(n+4) {
            margin-left: 5rem;
        }

        &:nth-child(n+5) {
            margin-left: 6rem;
        }

        &:nth-child(n+6) {
            margin-left: 7rem;
        }
    }

    textarea.message_text_box {
        background-color: $white;
    }

    .content_messages {
        .form_submit.newMsg {
            width: 100%;
            margin: 0 0 2rem 0;

            a {
                display: block;
            }

            .button {
                padding: 0;
                text-align: right;

                span {
                    width: auto;
                    padding: 14px 24px;
                    border: 2px solid $green;
                }
            }
        }

        .wrapper_message {
            margin: 0 0 4rem 0;

            .envoyer_reponse {
                width: auto;
                margin: 1rem 0 0;
                padding: 11px 30px;
                border: 2px solid $green;
                line-height: 1;
            }

            form#data {
                border-top: 1px solid $tableGrey;
                border-bottom: 1px solid $tableGrey;

                .reponse_message {
                    padding: 1rem 1rem 0;

                    p {
                        border-bottom: 1px solid $tableGrey;
                        font-weight: $extrabold;
                        font-size: 14px;
                    }

                    textarea.message_text_box {
                        padding-left: 0;
                        border: 0;
                        text-indent: 0;
                    }
                }
            }

            table {
                border-top: 1px solid $tableGrey;

                tr {

                    th {
                        padding-left: 1rem;
                        border-right: 1px solid $tableGrey;
                        border-left: 1px solid $tableGrey;
                    }

                    .details_msg_content {
                        margin-right: 2rem;
                        padding: 1rem 1.5rem 2rem 1rem;

                        .infos_message {
                            border-bottom: 1px solid $tableGrey;

                            p {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                line-height: 1.8;

                                label {
                                    width: 60px;
                                }
                            }
                        }

                        .message_details_holder {
                            margin: .5rem 0 0 0;
                        }
                    }
                }
            }

        }

        .wrapper_msg_bounce {
            margin-bottom: 1.5rem;

            h3 {
                margin-bottom: 0.5rem;
                color: $green;
            }

            a {
                font-weight: 600;
            }
        }
    }

    .table_mes_messages {
        tr th {
            padding-left: 0 !important;
        }

        .read {

            .enveloppe,
            .open_msg {
                margin: 0 auto;
                padding: 0;

                span:before {
                    display: block;
                    width: 33px;
                    height: 32px;
                    background: url('../img/btn_ouvrir_message.png') no-repeat top;
                    content: "";
                }
            }

            .open_msg {
                span:before {
                    background: url('../img/btn_ouvrir_message.png') no-repeat bottom;
                }
            }
        }

        .list_msg_grey {
            .enveloppe {
                span:before {
                    display: block;
                    width: 100%;
                    height: 33px;
                    background: url('../img/btn_ouvrir_message.png') no-repeat center;
                    content: "";
                }
            }

            .open_msg {
                span:before {
                    display: block;
                    width: 100%;
                    height: 33px;
                    background: url('../img/btn_ouvrir_message.png') no-repeat bottom;
                    content: "";
                }
            }
        }
    }
}

.answerTo .w-reply-form {

    .filepj1,
    .imagepj1 {
        display: none;
    }

    .file-input-row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 1rem;

        .w-form-line.column {
            width: auto;
            margin-bottom: 0;
        }
    }

    .file-list {
        margin-bottom: .8rem;
        color: $primaryColor;

        .rowList {
            display: flex;
            position: relative;
            align-items: center;
            width: 80%;

            .closePj {
                position: absolute;
                top: 0;
                left: 40%;
                width: 12px;
                height: 12px;
                margin-left: 2rem;
                background: transparent;

                &::before {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 12px;
                    height: 12px;
                    padding: 0.2rem;
                    border-radius: 50%;
                    background-color: $secondaryColor;
                    content: "x";
                    color: white;
                    line-height: 0;
                }
            }

            &+.rowList {
                margin-top: 1rem;
            }

        }

        span {
            position: relative;
            width: 100%;
            max-width: 30%;
            padding: 0 .5rem 0 0;
            overflow: hidden;
            background-color: rgba($secondaryColor, .3);
            font-weight: 400;
            text-indent: .5rem;
            text-overflow: ellipsis;
            white-space: nowrap;

        }
    }

    .ajout_img_label,
    .uploadedFile,
    .uploadedImage {
        display: block;
    }

    .ajout_pj_label,
    .ajout_img_label {
        width: 30px;
        height: 30px;
        margin: 0 .2rem;
        padding: 0.2rem;
        border-radius: 50%;
        background-image: url(../svg/link.svg);
        background-position: center;
        background-size: 28px;
        background-repeat: no-repeat;
        background-color: grey;
        color: transparent;
        font-size: 0px;
        opacity: 1;
        transition: all .5s ease-in-out;

    }

    .ajout_pj_label {
        margin-bottom: 0;
    }

    .ajout_img_label {
        background-image: url(../svg/camera.svg);
    }

    .ajout_img_label {
        display: none;
    }

}

@media screen and (max-width:1024px),
(height: 1024px) and (width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape),
(width: 1024px) and (height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
    .answerTo .w-reply-form .file-input-row {

        .ajout_img_label {
            display: flex;
        }

    }

}

/******************************************************************************/
/* MES PRODUITS GARANTIES */
/******************************************************************************/

.wrapper_content_warranty {

    h2 {
        display: none;
    }

    .contain_padd {

        .firstPart {
            margin-bottom: 1rem;
            color: $grey3;
            font-weight: $semibold;
            font-size: 13px;
            font-family: $primaryFont;
        }

        .halfPart {
            margin-bottom: 1rem;
            font-size: 13px;
        }

        .secondPart {
            font-size: 13px;
        }

        ul {
            margin-bottom: 1rem;
            padding-left: 0.5rem;
            color: $grey3;
            font-weight: $regular;
            font-size: 13px;
            font-family: $primaryFont;
            list-style: inherit;
            list-style-position: inside;
            list-style-type: disc;
        }
    }

    .conteneur_adresse {
        @media screen and (max-width:1024px) {
            width: 85%;
        }

        table.tableau_garantie tr.tab_decriptif_garantie td.last {
            border: 1px solid #e9e9e9;
        }
    }

    .mon_compte_btm_link {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        text-align: center;

        a {
            display: inline-block;
            height: 50px;
            margin-left: 0;
            padding: 15px 30px;
            border: none;
            background: $grey3;
            color: $white;
            font-weight: $regular;
            font-size: 17px;
            line-height: initial !important;
            font-family: $primaryFont;
            letter-spacing: 0;
            text-transform: uppercase;

            .btn_savoir_plus {
                margin-bottom: 2rem;
                margin-left: 0;
            }

            &:last-of-type {
                background: $green;
            }

            &:nth-child(1) {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &.btn_buy {
                position: relative;
                top: auto;
                right: auto;
                bottom: auto;
                left: auto;
                transform: none;
            }

            &:after {
                box-sizing: border-box;
                padding: .85rem .25rem;
                line-height: 1;
            }

            &:hover {
                &:after {
                    right: 0;
                }
            }

            &:first-of-type {
                margin: 0 2rem 2rem 0;
            }
        }

        .btn_buy:not(.loader):after {
            line-height: initial !important;
        }
    }
}

.customer.es {
    .wrapper_content_warranty {
        .mon_compte_btm_link {
            a {
                padding: .85rem .25rem;
                font-size: 11px;
            }
        }
    }
}

/******************************************************************************/
/* MA LISTE D'ENVIES */
/******************************************************************************/

.wrapper_moncompte {

    .conteneur_adresse {

        .wishlist_container {
            width: 100%;
        }

        .wrapper_totaux {
            .wrapper_btn_add {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;

                .input_container {
                    a {
                        position: absolute;
                        top: 1.5%;
                        right: 0;
                        width: 100%;
                        height: 48px;
                        padding: 0;
                        background-color: $black;
                        line-height: 48px;

                        span {
                            color: $white;
                        }
                    }
                }

                #nrArticlesSelected {

                    #total_price {
                        position: relative;
                        font-size: 30px;
                        text-align: center;

                        .devise,
                        .decimal_price {
                            font-size: 12px !important;
                        }

                        &.bloc_price {
                            min-width: 25%;

                        }
                    }

                    #blocCount,
                    .pricetag {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .pricetag {
                        width: fit-content;
                        height: 100%;
                        padding: 0 0.5rem;

                    }
                }
            }

            .form_submit {
                width: 48%;

                .button {
                    @media screen and (max-width:1280px) {
                        font-size: .75rem;
                    }
                }
            }
        }

        .wish_liste_product {
            display: flex;
            flex-wrap: wrap;
            max-width: 100%;
            margin-bottom: 2rem;

            @media screen and (min-width: 1880px) {
                width: 100%;
            }

            .product_ctn {

                .bgSprite.bfr {
                    position: relative;
                }

                .bgSprite.bfr::before {
                    @include bgIconCoord(5, 6, 6);
                    width: 24px;
                    height: 25px;
                }

                .bgSprite.bfr::after {
                    @include bgIconCoord(7, 6, 6);
                    position: absolute;
                    width: 14px;
                    height: 15px;
                }

                .bgSprite.bfr img {
                    position: absolute;
                    top: -16%;
                    right: -18%;
                }

                &.f_left {
                    float: none;
                }

                .customChecker {
                    z-index: 1;
                    position: absolute;
                    top: 46%;
                    left: -10px;

                    .checkbox_select_product {
                        width: 14.58px;
                        height: 14.95px;
                        margin: 0;
                        border: 1px solid #B1B1B1;
                        background-color: $white;
                        content: '';
                        cursor: pointer;

                        &:checked {
                            background-color: $black;
                            box-shadow: inset 0 0 0 1px $white;
                        }
                    }

                    label:before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 36px;
                        height: 24px;
                        content: '';
                        cursor: pointer;
                    }
                }

                .wrapper_product {
                    display: flex;
                    position: relative;
                    height: 100%;
                    margin: 0;
                    padding-left: 17px;
                    border: 1px solid $fadeGrey;

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .photo_product {
                        width: 140px;
                        height: 140px;

                        .wrapper_img {
                            width: 100%;
                            height: 100%;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    .product_desc {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        margin-top: 41px;
                        padding-left: 17px;

                        a {
                            display: flex;
                            flex-direction: column;
                            justify-content: left;
                        }

                        .wrap_txt {
                            .title {
                                span {

                                    font-weight: $extrabold;
                                    font-size: 15px;
                                    text-transform: initial;
                                }
                            }
                        }

                        .wrapper_details {
                            width: 100%;

                            p {
                                color: $darkenGrey;
                                font-size: 12px;
                                text-transform: initial;
                            }
                        }

                        .bottom_content_price {
                            .bloc_price {
                                .pricetag {
                                    color: $grey3;
                                    font-weight: $semibold;
                                    font-size: 30px;
                                    font-family: $primaryFont;
                                }

                                .pricetag:nth-of-type(2) {
                                    color: #8A8279;
                                    font-weight: $semibold;
                                    font-size: 23px;
                                    font-family: $primaryFont;
                                }
                            }

                            a.remove {
                                &:before {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    width: 16px;
                                    height: 16px;
                                    margin: 12px 15px;
                                    background: url("../img/cross1.svg") no-repeat center;
                                    content: '';
                                }

                                span {
                                    display: none;
                                }
                            }
                        }

                    }
                }
            }

            .configurator_modify {
                position: absolute;
                right: 2rem;
                bottom: 2rem;
                text-transform: uppercase;
            }
        }
    }
}

.wishlist {
    #shad {
        &.actif {
            display: block;
        }
    }

    #sendwishlistbox {

        .wrapper_scrollable {
            overflow-x: hidden;
            overflow-y: auto;
            max-height: 94vh;
            padding-right: 10px;
        }

        #sendfriend_form_froms {
            margin-top: 25px;
        }

        #sendfriend_form_to .w_form_line {
            margin-top: 45px;
        }

        #sendfriend_form_to .w_form_line:nth-of-type(2) {
            margin-top: 20px;
        }

        button.close_pop.close {
            background-color: white;
            z-index: 10000;
        }

        .popup_title {
            border-bottom: none;
        }

        input,
        textarea {
            border-radius: 0;
        }

        .w-form-line {
            margin-bottom: 16px;
        }

        .subtitle {
            color: $grey3;
            font-weight: $regular;
            font-size: 13px;
            line-height: 16px;
            font-family: $primaryFont;
            text-transform: none;
        }

        #title_destinataire,
        #title_de_la_part,
        #title_message {
            margin-bottom: 8px;
            font-size: 13px;
            color: $cmsPageTitle;
            font-weight: 700;
            line-height: 16px;
            font-family: $primaryFont;
            text-transform: none;
        }

        #title_destinataire,
        #title_message {
            text-transform: lowercase;

            &::first-letter {
                text-transform: uppercase;
            }
        }

        #title_message {
            display: block !important;
        }

        .w-input.w-checkbox-input {
            color: $grey3;
            font-weight: $regular;
            font-size: 13px;
            line-height: 16px;
            font-family: $primaryFont;
        }

        .w-checkbox-input .w-input-label span {
            display: flex;
            align-items: center;
            color: $grey3;
            font-weight: $regular;
            font-size: 13px;
            line-height: 16px;
            font-family: $primaryFont
        }

        #recaptcha {

            &.g-recaptcha {
                padding-top: 0;
                padding-bottom: 24px;
            }
        }

        #sendfriend_form_buttons .w-btn-wrapper {
            width: auto;

            .w-submit-button {
                font-family: $primaryFont;
                font-size: 17px;
                width: 244px;
                line-height: 1;
            }

            .w-submit-button.w-loader {
                position: absolute;
                left: 0px;
                width: 244px;
            }
        }

        .mentions {
            margin-top: 20px;
            font-size: 12px;
            line-height: normal;
            color: $mediundarkgray1;

            a {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }

            .champ_obli {
                margin-bottom: clamp(10px, fs-vw(20,1920), 20px);
            }

            p:not(.champ_obli) {
                line-height: 1.2;
            }
        }
    }

    #abox.lightbox {

        .txt_alert {
            text-align: center;
        }

        .close_pop.close:before {
            position: absolute;
            top: 0;
            right: 0;
            width: 20px;
            height: 16px;
            margin: 0.5rem;
            background: url(../img/wishlist_btn.png) no-repeat center;
            content: '';
        }
    }

    .lightbox.actif {
        display: block;
    }

    .flexAccount {

        @media screen and (max-width: 1250px) {
            max-width: none;
            padding-left: 2.263vw;
        }
    }

    .conteneur_adresse {
        width: 100%;
    }

    .wishlist_container {
        position: relative;
        width: 100%;

        @media screen and (min-width: 1880px) {
            width: 102% !important;
        }
    }

    .cta_add_to_basket {
        position: absolute;
        right: 2%;
        bottom: 3%;
        cursor: pointer;
    }

    .wrapper_moncompte {
        display: flex;
    }

    .accountLeft {
        max-width: 1147px;
        margin-bottom: 25.5px;

        h1 {
            display: none;
        }

        .usrname p {
            display: block;
        }
    }

    .wrapper_content_wishlist {

        .wrapper_btn_add {
            position: relative;
            max-width: 1147px;
            height: 50px;
        }

        .wishlist_container_bloc_1 {
            display: flex;

            #selectAll {
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 218px;
                height: 50px;
                max-height: 50px;
                margin-right: 20px;
                border: 1px solid $black;

                &.f_left {
                    float: none;
                }

                span {
                    border: none;
                    color: $grey3;
                    font-weight: $regular;
                    font-size: 17px;
                    font-family: $primaryFont;
                    text-transform: uppercase;
                }
            }

            .form_submit.input_container {
                width: 211px;
                height: 50px;
                margin-right: 20px;

                #btn_add_wishlist {
                    height: 50px;
                    background: $grey3;
                    font-weight: $regular;
                    font-size: 17px;
                    font-family: $primaryFont;

                    #bloc_btn_loader {
                        background-color: #F4F0ED;
                    }
                }
            }

            .share_wl {
                display: flex;
                align-items: flex-end;
                width: 207px;
                height: 50px;

                a {
                    top: 51%;

                    &::before {
                        top: -2px;
                    }
                }

                p {
                    color: $grey3;
                    font-weight: $medium;
                    font-size: 13px;
                    line-height: 16px;
                    font-family: $primaryFont;
                    letter-spacing: 0.05em;
                    text-decoration-line: underline;
                }
            }
        }

        #nrArticlesSelected {
            display: flex;
            align-items: flex-end;
            max-height: 50px;

            #blocCount {

                .wishlist_count {
                    margin: 0 .3rem -1px 0;
                }

                span {
                    border: none;
                }
            }

            .txt {
                min-width: fit-content;
                color: $grey3;
                font-weight: $regular;
                font-size: 13px;
                font-family: $primaryFont;
            }
        }
    }

    .wrapper_totaux {

        @media screen and (max-width: 1450px) {
            margin-bottom: 50px;
        }
    }

    .product_ctn {
        position: relative;
        width: 29.474vw;
        max-width: 560px;
        height: 173px;
        margin: 32px 25px 0 0;
        margin-right: 25px;

        @media screen and (max-width: 1500px) {
            width: 560px;
        }
    }

    .wrapper_moncompte .conteneur_adresse .wrapper_totaux .wrapper_btn_add #nrArticlesSelected #total_price.bloc_price {
        box-sizing: border-box;

        &:has(#total_price) {
            &.pricetag {
                line-height: 0.7rem;
            }
        }

        &.pricetag {
            line-height: 1.3rem;
            align-items: flex-end;

            sup {
                margin-bottom: 6px;
            }
        }
    }

    .wrapper_content_wishlist {
        .product_desc {
            .wrapper_price  {
                .pricetag.discounted_price {
                    color: $shiraz!important;
                }
            }
        }
    }

    .wrapper_product {
        .wrapper_infos_produit {
            .texte_produit {
                .sub_title_produit {
                    margin: .8rem 0 .7rem;
                }
            }

            .conteneur_prix {
                .noir.discounted_price {
                    color: $shiraz;
                }
            }
        }
    }
}

//Contact Form - Optin
#service {
    .wrapper_optin_container {
        position: relative;
    }

    p.info_site_choice {
        padding: 0px;
        border: none;
        font-weight: 400;
        font-size: 13px;
    }

    .wrapper_optin {
        height: 20px;

        p {
            width: auto;
            margin-top: 0px;
            padding-top: 0px;
            border: none;
            font-weight: 400;
            font-size: 13px;

            &:lang(fr) {
                min-width: 70px;
            }

            &:lang(es) {
                min-width: 142px;
            }
        }

        label {
            font-size: 13px;
        }
    }

    .div_optin {

        .img_error~.img_error,
        .valid_form {
            top: 50% !important;
        }
    }

    .img_error,
    .valid_form {
        top: 34% !important;
    }
}

.rPwd {
    position: relative;

    .unmask_container {
        position: absolute;
        top: 0;
        right: 5px;
        bottom: 0;
        min-width: 23px;
        height: 100%;
        margin: auto;
    }

    .unmask {
        position: absolute;
        right: 10px;
        cursor: pointer;

        &.table {
            right: 0;
        }

        &.see {
            width: 23px;
            height: 100%;
            background-image: url(../svg/eye_open.svg);
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    &.unsee {
        background-image: url(../svg/eye_shut.svg);
    }
}

.addr_form_cluster {
    .input_telephone {
        .wrapper_inputs {

            .img_error,
            .valid_form {
                right: -2rem;
            }
        }
    }
}

// hesperide v2
.customer .breadcrumbs_wrapper,
.wishlist .breadcrumbs_wrapper {
    max-width: 1690px;
}

.customer #site_global_wrap .wrapper_moncompte {
    display: flex;
}

body #site_global_wrap>div {
    margin-top: 0 !important;
}

.customer.moncompte {

    #wrapper_top_menu {
        position: relative;
    }

    .breadcrumbs_wrapper {
        position: absolute;
        top: 100%;
        left: 0%;
        width: auto;
        max-width: none;
        transform: none;

        .breadcrumbs {
            line-height: 5;
        }
    }

    &.hasBandeauHeader {
        .breadcrumbs_wrapper {
            top: unset;
        }
    }

    .wrapper_moncompte_main.wrapper_content_mes_infos {

        .w-radio-group .w-input-label span {
            color: $black;
            font-weight: $regular;
            font-size: 13px;
            line-height: 16px;
            font-family: $primaryFont;
        }

        form .w-input-label span {
            color: #868686;
            font-weight: $regular;
            font-size: 14px;
            font-family: $primaryFont;
        }

        .w-tel-input::after {
            position: absolute;
            top: 9px;
            left: 52px;
            width: 1px;
            height: 32px;
            background-color: rgba(136, 136, 136, .5);
            content: "";
        }

        .selected-flag {
            border-right: none;
        }
    }
}

.pdf_download {
    display: inline-block;
    margin: 1.5rem 0 0;

    p {
        margin-bottom: 1.2rem;
        font-weight: 600;
        font-size: 15px;
        line-height: 1;
        text-transform: uppercase;
    }

    .download-icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-left: 1rem;
        transform: translateY(5px);
        background: url('../img/download.png') no-repeat 50% 50%;
    }
}

/* Temporary fix pour faire ISO MCC. Need to clean and correct the vue.js for verification of numbers confirmation logo*/
body.customer {
    &.moncompte .wrapper_form {
        #iconValidclientTel {
            display: none;
        }
    }

    #breadcrumbs,
    .accountBannerTop,
    #adresseForm #iconValidtelephone {
        display: none;
    }

    .wrapper_content_mes_infos .errormsgadd {
        +.w-input-note.w-input-error {
            top: 91%;
        }
    }

    &.body_login {
        .nbDenyOptinSimple {
            display: none !important;
        }
    }
}

body {
    &.client_myinfos,
    &.client_addresses {
        .wrapper_adresses_title {
            display: none;
        }

        .additionalAddressLine {
            width: 100% !important;

            + .additionalAddressLine {
                margin-left: 0;
            }
        }

        .wrapper_moncompte {
            position: relative;
            flex-direction: column;
            padding-left: 31.5%;
            box-sizing: border-box;
            min-height: 650px;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 28%;
                max-width: 469px;
                aspect-ratio: 469/600;
                background: transparent url('../img/my-infos-visuel.jpg') no-repeat center;
                background-size: 100% auto;
            }
        }

        .left-container {
            position: relative;
            width: 100%;
            margin-bottom: 28.5px;

            .item {
                margin-right: 3.474vw;
                color: $grey3;
                font-weight: $light;
                font-size: 14px;
                line-height: 17px;
                font-family: $primaryFont;
                letter-spacing: 0.05em;
                text-transform: uppercase;

                @media screen and (max-width: 1550px) {
                    margin-right: 1.4vw;
                    font-size: 12px;
                }

                &::nth-of-type(1) {
                    margin-top: 2rem;
                }

                &.actif {
                    font-weight: $semibold;
                }
            }
        }

        .w-login-form {
            width: 100%;
        }

        .w-form-line.mail-pass {
            align-items: flex-start;
            flex-wrap: wrap;
        }

        .w-info-form {
            .w-input-container {
                width: calc(50% - .5rem);

                &.mail-1,
                &.pass-1 {
                    margin-bottom: 23px;
                }

                &.mail-2 {
                    margin-left: 0;
                }
            }
        }

        .conteneur_adresse {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 20px;

            .adresse {
                position: relative;
                width: 280px;
                height: 190px;
                padding: 30px;
                box-sizing: border-box;
                background-color: rgba(138,130,121,.1);
            }

            .addr_type {
                font-size: 13px;
                font-weight: 600;
                color: #000;
                letter-spacing: .05em;
                text-transform: uppercase;
                margin-bottom: 1rem;
            }

            .addr_line {
                font-size: 13px;
                line-height: 1.2;
            }

            a.modify {
                position: absolute;
                bottom: 30px;
                left: 30px;
                font-size: 14px;
                font-weight: 500;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }

            a.delete {
                font-size: 0;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 1rem;
                    right: 1rem;
                    width: 12px;
                    height: 12px;
                    @include maskIconPath('../svg/spritesheet.svg');
                    @include maskIconCoord(11, 6, 6);
                    background-color: #2B2B2B;
                }
            }
        }

        a.add_new_address {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            height: 50px;
            padding: 0 3rem;
            font-size: 17px;
            text-transform: uppercase;
            background-color: #2B2B2B;
            color: white;
            border: 1px solid #2B2B2B;
            margin-top: 20px;
            transition: background-color .3s ease-in-out, color .3s ease-in-out;

            &:hover {
                background-color: white;
                color: #2B2B2B;
            }
        }

        .w-submit-button,
        button.w-submit-button {
            width: auto;
            height: 50px;
            padding: 0 3rem;
        }

        .w-submit-button.w-loader,
        button.w-submit-button.w-loader {
            width: 100%;
            border: 1px solid #2B2B2B;
            background: white url(../svg/three_dots_black.svg) no-repeat center;
            background-size: auto 15%;
        }
    }
}

#lightboxCustomerAddress {
    .delivery_section_title {
        font-size: 17px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    #adresseForm {
        height: 93vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .w-form-line.w-submit {
        width: fit-content;
    }
}

#adresseForm {
    .elem_global {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
    }

    .w-form-line.add-complementary-line {
        display: none;
    }

    .w-input-group {
        position: relative;
        z-index: 2;
        width: 100%;
    }

    .w-submit-button,
    button.w-submit-button {
        width: 100%;
    }

    .w-submit-button.w-loader,
    button.w-submit-button.w-loader {
        background: white url(../svg/three_dots_black.svg) center no-repeat;
        background-size: auto 22%;
    }
}

#popup_savoir_plus {
    width: 500px !important;
    right: -500px !important;
    font-size: 13px;
    padding: 33px;

    &.actif {
        right: 0 !important;
    }

    #title_popup_savoir_plus {
        display: none;
    }

    span {
        display: block;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        color: $grey3;
    }

    #content_savoir_plus {
        margin-bottom: 1rem;
    }
}

// woosmap compte (now common to all languages)
body {
    .addrAutoComplete {
        z-index: 6;
        position: relative;

        .autocomplete-results {
            box-sizing: border-box;
            z-index: 6;
            position: absolute;
            width: 100%;
            overflow: hidden;
            border-top: 1px solid #d9d9d9;
            border-radius: 2px;
            background-color: $white;
            box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
            font-family: Arial, sans-serif;

            li {
                display: flex;
                padding: 0.5rem 0 0.5rem 0.8rem;

                &:hover {
                    background: #e9e9e9;
                    cursor: pointer;
                }
            }
        }

        .autocomplete-icon.icon-localities {
            display: block;
            width: 15px;
            height: 19px;
            margin-right: 6px;
            background-image: url("https://images.woosmap.com/icons/locality.svg");
            background-repeat: no-repeat;
        }
    }
}

// fin woosmap compte