/**
 * MODULE 1 : SLIDER ET 2 PUSH
 */
.home_top,
.module-slider_image {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .main_display_wrapper {
        position: relative;
        width: 68.2%;
        max-width: 1150px;

        @media screen and(max-width: 1300px) {
            margin: 0 auto;
            width: 100%;
        }

        .home_slider_legend {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 65px;
            height: 98px;

            @media screen and(max-width: 1200px) {
                padding: 0 5px;
            }

            .title {
                display: inline-block;
                vertical-align: middle;
                margin-right: 25px;
                font-size: 25px;
                line-height: 1;
                white-space: nowrap;

                strong {
                    font-weight: 600;
                    font-size: 29px;
                }
            }

            .contents {
                display: inline-block;
                vertical-align: middle;
                box-sizing: border-box;
                border-left: 5px solid #2B2B2B;
                padding-left: 10px;
                font-size: 0.85em;
                text-align: justify;

                p {
                    line-height: 1.4;
                }

                strong {
                    font-weight: 600;
                }
            }

            a:hover {
                text-decoration: underline;
            }

        }
    }

    #home_slider,
    .sliderCover_module {
        position: relative;

        .swiper-slide {
            .slide_video {
                position: relative;
                top: 0;
                left: 0;
                z-index: 1;
                padding-bottom: calc(600 / 1090 * 100%);
                width: 100%;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            .cta_video_redirect {
                display: block !important;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
            }
        }

        img.home_img {
            vertical-align: top;
            width: 100%;
            height: auto;
        }

        .swiper-button-next,
        .swiper-button-prev {
            display: inline-block;
            position: absolute;
            top: 52%;
            right: 50px;
            background: transparent url('../img/arrow_new.png') center center no-repeat;
            background-size: contain;
            width: 19px;
            height: 32px;

            &:after,
            &:before {
                display: none;
            }

            &.swiper-button-disabled {
                opacity: 0.5;
            }
        }

        .swiper-button-prev {
            right: auto;
            left: 50px;
            transform: rotate(180deg);
        }
    }

    .home_spotlights {
        display: flex;
        flex-direction: column;
        width: 29.1%;
        max-width: 490px;

        @media screen and(max-width: 1300px) {
            flex-direction: row;
            justify-content: space-between;
            margin: 0 auto;
            width: 100%;
            max-width: 1090px;
        }

        .spotlight_thumb {
            margin-bottom: 38px;

            .slide_video {
                a {
                    display: block;
                    position: relative;
                    padding-bottom: calc((330 / 465) * 100%);
                    width: 100%;
                    height: 100%;

                    @media screen and (max-width: 1200px) {
                        padding-top: 38px;
                        padding-bottom: 0;
                    }
                }

                iframe {
                    position: absolute;
                    z-index: -1;

                    @media screen and (min-width: 1300px) {
                        width: 100%;
                        height: 100%;
                    }

                    @media screen and (max-width: 1300px) {
                        position: relative;
                    }
                }
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

/**
 * MODULE 2 : BANDEAU
 */
.encart_bas {
    position: relative;
    margin: 0 auto 38px;
    background-size: contain;
    width: 100%;
    font-family: $primaryFont;

    @media screen and(max-width: 1300px) {
        margin: 0 auto 38px;
        max-width: 1090px;
    }

    @media screen and(max-width: 1200px) {
        margin: 0 auto 25px;
    }

    img {
        vertical-align: bottom;
        width: 100%;
        height: auto;
    }

    .titre {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        transform: translateY(-50%);
        margin: 0 auto;
        text-align: center;

        div {
            color: $white;
            font-size: 23px;
            line-height: 1;
            text-align: center;

            &:first-child {
                margin-bottom: 2px;
            }

            strong {
                font-weight: 600;
                font-size: 27px;
            }
        }
    }
}

/**
 * MODULE 3 : 2 PUSHS
 */
.related_topics {
    display: flex;
    justify-content: space-between;
    margin-bottom: 38px;

    @media screen and(max-width: 1300px) {
        margin: 0 auto;
        max-width: 1090px;
    }

    .store_locator.homepage {
        .storeloc_form {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0;
            background-color: transparent;
            padding: 0;
            width: 340px;
            text-align: left;

            .autocomplete_addr {
                width: 80%;
                text-indent: 0.6em;

                &::placeholder {
                    font-style: normal;
                }
            }

            .send_form.form_itm {
                background-color: transparent;
                width: 3.2rem;
                height: 3em;

                .button.valid {
                    border: 2px solid white;
                    background-color: transparent;
                }

                .button.loader {
                    width: 10rem;
                }
            }
        }
    }

    .related_thumb {
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        width: 49%;
        max-width: 826px;
    }

    .related_txt_wrapper {
        display: flex;
        height: 267px;

        .send_form.form_itm {
            .button.loader {
                border: 2px solid #fff;

                span {
                    line-height: 1.1;
                }
            }
        }
    }

    .related_txt {
        position: relative;
        transition: margin 0.2s ease-in-out;
        margin-top: 3em;
        margin-left: 15%;
    }

    .related_txt .title {
        color: #fff;
        font-weight: 300;
        font-size: 23px;
        line-height: .9;

        strong {
            font-weight: 600;
            font-size: 27px;
        }
    }

    .related_txt .contents {
        margin: .5rem 0 1rem;
        width: 225px;
        color: #FFF;
        font-weight: 300;
        font-size: 13px;
        line-height: 16px;
    }

    .related_txt .btn_buy {
        display: inline-block;
        position: relative;
        top: auto;
        left: auto;
        transform: translate(0, 0);
        background-color: rgba(0, 0, 0, 0);
        color: #FFF;
    }
}

/**
 * MODULE 4 : TG
 */
.home.full_w_sldr_wrapper,
.product_highlights {
    position: relative;
    margin-bottom: 38px;
    width: 100%;

    .eclat_gondole_home {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
    }

    .eclat_gondole_home_left {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        @media screen and (max-width: 1024px) {
            left: -14px;
        }
    }

    .cadre1_horiz {
        .tg_img_container {
            position: relative;
            margin: 0 12.4%;
            padding-top: 75.2%;
            width: 75.2%;

            .tg_img_content {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                color: white;
                font-size: 20px;
                text-align: center;

                &>img {
                    -o-object-fit: cover;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

.home.full_w_sldr_wrapper {
    width: 100%;

    @media screen and(max-width: 1300px) {
        margin: 0 auto 38px;
        max-width: 1090px;
    }

    .full_w_sldr_title {
        position: relative;
        font-weight: 300;
        font-size: 20px;

        strong {
            font-weight: 600;
        }
    }

    .old-price {
        width: 100%;
    }
}

.home.full_w_sldr_wrapper .full_w_sldr_title {
    display: inline-block;
    padding-right: 9.25%;
    line-height: 47px;

    &:after {
        -webkit-transition: height 0.15s linear;
        display: block;
        position: absolute;
        top: 2.8rem;
        left: 0;
        transition: height 0.15s linear;
        background-color: $black;
        width: 100%;
        height: 1px;
        content: '';
    }
}

.home.full_w_sldr_wrapper .slider {
    display: flex;
    width: 100%;
    overflow: hidden;
}

.homepage .full_w_sldr_wrapper .slider .swiper-slide {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    transition: opacity .3s ease;
    padding-top: 30px;
    width: 25%;
    min-width: 25%;

    &.replacing {
        opacity: 0;
        transition: opacity .3s ease;
    }

    a.item {
        width: 100%;
    }
}

.homepage .full_w_sldr_wrapper .slider .swiper-slide:not(:first-child):before {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: #DADADA;
    width: 1px;
    height: 75px;
    content: '';
}

.home.full_w_sldr_wrapper .item>img {
    -webkit-transform: translateZ(0);
    -webkit-transition: opacity 0.15s linear;
    vertical-align: top;
    transform: translateZ(0);
    transition: opacity 0.15s linear;
    margin: auto;
    width: 15.6vw;
    height: 15.6vw;
    object-fit: cover;
}

.home.full_w_sldr_wrapper .item:hover>img {
    opacity: 0.7;
}

.home.full_w_sldr_wrapper .item .wrapper_infos {
    position: static;
    box-sizing: border-box;
    padding: 5px 12% 0;
    width: 100%;

    .title {
        display: block;
        font-size: 15px;
        line-height: 18px;
    }

    .subtitle {
        font-size: 12px;
        color: $cmsPageTitle;
    }
}

.our_choice_container.slider .item .wrapper_infos .price {
    display: inline-block;
    width: auto;
}

.home.full_w_sldr_wrapper .item .wrapper_infos {
    display: flex;
    flex-wrap: wrap;

    .subtitle {
        width: 100%;
    }

    .item_ratings {
        .netreviews_reviews_rate {
            color: $jauneAvisV;
            font-size: 17px;

            &+.netreviews_reviews_rate {
                color: $darkJungle;
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 21px;
                font-family: $primaryFont;
            }
        }
    }

    .wrapper_price {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        order: 1;
        width: 100%;

        .old-price {
            width: 100%;
        }
    }
}

.home.full_w_sldr_wrapper .item .rollover {
    -webkit-transition: opacity 0.2s linear;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: space-around;
    align-items: center;
    opacity: 0;
    transition: opacity 0.2s linear;
    width: 100%;
    height: 100%;
}

.home.full_w_sldr_wrapper .rollover .rollover_contents_wrapper {
    margin-top: -25px;
}

.rollover_contents_wrapper .colors_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 113px;
}

.home.full_w_sldr_wrapper .rollover .btn_buy {
    position: relative;
    top: auto;
    left: auto;
    transform: translate(0, 0);
    width: 111px;
}

.home.full_w_sldr_wrapper .item:hover .rollover {
    opacity: 1;
}

.fp_img_content {
    img {
        width: 100%;
        height: auto;
    }

    &.is_video {
        position: relative;
        padding-top: calc(1350/1080*100%);
        overflow: hidden;

        video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: auto;
        }
    }
}

.cart_product_line {
    .fp_img_content {
        img {
            width: auto;
            height: auto;
        }
    }
}

/**
 * MODULE 5 : 3 PUSHS
 */
.blog_highlight {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 38px;
    max-height: 400px;

    img {
        vertical-align: top;
        width: 100%;
        height: auto;
    }

    .wp-mise-en-avant-left {
        position: relative;
        width: 31.7%;
        max-width: 506px;
    }

    .wp-mise-en-avant-right {
        display: flex;
        flex: 1;
        align-items: flex-start;

        &>.block_lnk:first-child,
        &>.videoWrapper:first-child {
            margin: 0 3.7%;
        }

        .block_lnk {
            width: 50%;
            max-width: 506px;
        }

        .spotlight_thumb {
            position: relative;
        }
    }

    .videoWrapper {
        padding-top: 0;
        width: 29.945%;
        overflow: hidden;

        .slide_video {
            display: block;
            position: relative;
            padding-bottom: calc((400 / 506) * 100%);
            width: 100%;
            height: 0;

            a {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                &.cta_video_redirect {
                    display: block !important;
                }
            }

            iframe {
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: -1;
                margin: auto;
            }
        }

        &:has(.daily_video) {
            width: 31.716%;
            max-width: inherit;

            .slide_video {
                padding-bottom: calc((400 / 536) * 100%);
            }
        }
    }
}

/**
MODULE THREE IMG
**/
.home-module.three-images {

    .insideImg {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        margin-bottom: 2.9rem;

        .home-module-link {
            display: block;
            position: relative;
        }

        .home-column {
            display: block;
            position: relative;
        }

        img {
            width: 100%;
            height: auto;
        }

        h3 {
            display: flex;
            position: absolute;
            top: 8%;
            left: 0;
            justify-content: center;
            z-index: 1;
            width: 100%;
        }

        .home-module-cta {
            display: flex;
            position: absolute;
            bottom: 8%;
            justify-content: center;
            width: 100%;

            span {
                background-color: #fff;
                padding: .75rem 1.75rem;
                font-weight: 800;
                font-family: $secondaryFont;
                text-transform: uppercase;
            }
        }
    }

    .home-column {
        width: calc(100% / 3);
    }

    .ill_img {
        padding-top: calc(249 / 509 * 100%); // In order to keep the aspect ratio on resize
    }
}

/**
 * PAGE INACTIVE
 */
.home-module.images_signup_module {

    .home-module-title {
        font-weight: 700;
        font-size: 2.438rem;
        line-height: 1;
    }

    .home-module-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 45px auto;
        width: 98%;
        max-width: 1470px;
    }

    .home-column {
        width: 48%;

        &:first-child {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

        .home-module-text {
            margin: 18px 0;
            font-weight: 300;
            font-size: .938rem;
            line-height: 1.4;
        }

        .w-newsletter-form {
            width: 100%;
            max-width: 590px;

            .w-input-element:not([type=radio]):not([type=checkbox]),
            .w-newsletter-form input[type=email].w-input-element {
                border-radius: 0;
            }

            .w-form-line.w-submit {
                position: relative;
                margin: 0 auto;
                padding: 0;
                width: 210px;

                .w-submit-button {
                    box-sizing: border-box;
                    width: 100%;
                }
            }

            .w-input-note.w-input-error {
                width: 100%;
            }
        }

        &.visualSide {
            position: relative;
            max-width: 720px;

            img {
                vertical-align: top;
                width: 100%;
                height: auto;
            }
        }
    }
}


/**
  * MULTI CATEGORIE
  */
.home-module.multiple-categ {
    .home-module-title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90px;
        font-weight: 700;
        font-size: 30px;
    }

    .home-columns-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0 auto;
        width: 98%;
        max-width: 1284px;

        a {
            position: relative;
            margin-bottom: 2rem;
            width: 31.2%;
            max-width: 400px;

            img {
                vertical-align: top;
                width: 100%;
                height: auto;
            }
        }
    }
}

/************************
*****version ES
************************/
.site_3 {
    .search_wrapper {
        width: 85%;
    }

    .lang_switch {
        display: none;

    }

    .search_wrapper .block_top_links .top_link.blog:after {
        display: none;
    }
}

.ccards_list.es {
    display: none;
}

body.es {
    .block_top_links {
        justify-content: flex-start;

        .lang_switch {
            margin-left: 1.6rem;

            &:before {
                background: none;
            }

        }
    }
}

#search_shop_errs {
    display: inline-block;
    padding: .5rem;
    width: 70%;
    color: #e74c3c;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
}

.mise-en-avant__bloc {
    width: 29.945%;
    max-width: 506px;
    height: 100%;
}

.videoWrapper {
    position: relative;
    padding-top: calc(506 / 400 * 20%);
    width: 29.945%;
    max-width: 506px;
    height: 100%;
    max-height: 400px;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        border: none;
        width: 100%;
        height: 100%;
        max-height: 400px;
    }
}

.wp-mise-en-avant-right_wrapper {
    display: flex;
    height: 100%;
}

div.blog_highlight>a:nth-child(2) {
    margin: 0 2%;
}

body.homepage {
    #flowboxbtn_voirplus {
        display: flex;
        justify-content: center;
        background-color: $white;
        padding-top: 14px;
        padding-bottom: 30px;
        width: 100%;

        .flowboxbtn_voirplus {
            background-color: $grey3;
            padding: 15px 30px;
            color: $white;
            font-weight: 400;
            font-size: 17px;
            line-height: 20px;
        }
    }
}

// NOUVEAUX MODULES

.module-slider_image {
    margin: .5rem 0;
    max-width: 1680px;
    max-height: 600px;

    .main_display_wrapper {
        width: 65%;
        max-width: 1090px;

        .sliderCover_module {
            .slide_video {
                position: relative;
                top: 0;
                left: 0;
                z-index: 1;
                padding-bottom: calc(600 / 1090 * 100%);
                width: 100%;
                overflow: hidden;
            }

            .swiper-pagination_module_home {
                display: flex;
                position: absolute;
                justify-content: center;
                z-index: 10;

                .swiper-pagination-bullet {
                    opacity: .5;
                    margin-right: 10px;
                    background: #E7E2DE;
                }

                .swiper-pagination-bullet-active {
                    opacity: 1;
                    background: $white;
                }
            }
        }
    }

    .home_spotlights {
        width: 34.6%;
        max-width: 580px;

        .slide_video {
            position: relative;
            top: 0;
            left: 0;
            z-index: 1;
            padding-bottom: calc(600 / 580 * 100%);
            width: 100%;
        }
    }
}

.image-image-text.image__text {
    margin: 25px 0;
    max-width: 1680px;
    max-height: 80px;

    .spotlight_img {
        width: 100%;
        height: auto;
    }

    .slide_video {
        position: relative;
        padding-bottom: 80px;
        width: 100%;
        height: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .cta_video_redirect {
        display: block !important;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
    }

    .wrapperTxt {
        padding: 0 24px;
        width: 100%;

        a {
            text-decoration: underline;
        }
    }
}

.module_text_seo {
    margin: 1rem auto 2rem;
    max-width: 1380px;

    .page-module-title span,
    .paragraph_seo span {
        display: block;
        width: 100%;
        font-weight: initial;

        div {
            font-weight: initial;
        }
    }
}

// Home modules lazyload
.home-module {
    &.multiple-categ {
        .ill_img {
            padding-top: calc(450 / 400 * 100%);
        }
    }
}