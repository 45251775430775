/**
 *	Ensures compatibility with modern Internet Explorer
 */

input, select, textarea, button {
	height: 3em
}

select::-ms-expand {
	display: none
}

.page_wrapper {
	display: block
}

.modalbox .form_submit .button.pop_cancel {
	height: auto
}
