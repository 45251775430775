.store_locator #site_global_wrap {
    display: flex;
    justify-content: center;
    padding-top: 150px;


    *,
    ::before,
    ::after {
        box-sizing: border-box;
    }

    button,
    input,
    select,
    textarea {
        line-height: normal;
        text-indent: 0px;
    }

    #breadcrumbs {
        display: none;
    }

    .filtres_wrapper {
        display: none;
    }

    .wrapper_retailers_map {
        grid-column-gap: clamp(10px, 4vw, 62px);
        display: grid;
        grid-template-rows: 75px 75px 490px;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "title title"
            "forms map"
            "shops map";
        max-width: 1350px;

        #retailers_map {
            grid-area: map;
        }

        .img-loader-container {
            grid-area: shops;
            background-image: url('../svg/three_dots_black.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 30%;
            background-color: $white;
            z-index: 3;

            img {
                display: none;
            }
        }

        .storeloc_form {
            grid-area: forms;
            width: 100%;
            height: 49px;

            .wrap_fields {
                display: flex;
                align-items: center;
                width: 100%;
                height: 49px;
            }

            .country_select.w-dropdown {
                display: flex;
                position: relative;
                width: 150px;
                height: 100%;
                margin-right: 8px;
                border: 1px solid $grey3;
                border-radius: 0px;

                &::after {
                    display: inline-block;
                    top: 56%;
                    width: 14px;
                    height: 14px;
                    translate: -50% -50%;
                    rotate: 90deg;
                    border: 0;
                    background: url('../img/svg/arrow_more.svg') 0 0 no-repeat;
                    background-size: contain;
                    content: '';
                    pointer-events: none;
                }
            }

            #search_pays {
                width: 100%;
                padding-left: 20px;
                background-color: transparent;
            }

            .field_address {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                max-width: 310px;
                height: 100%;
                border: 1px solid #CFCFCF;

                .magnifying_glass {
                    width: 20%;
                    min-width: 20px;
                    max-width: 60px;
                    height: 100%;
                    background: url('../img/svg/store_search_line.svg') $white center no-repeat;
                    cursor: pointer;
                }

                #search_cp {
                    width: calc(100% - 49px);
                    height: 100%;
                    padding-left: 10px;
                    background-color: transparent;

                    &::placeholder {
                        visibility: hidden;
                    }
                }
            }

            .ou {
                margin: 0 clamp(5px, 1vw, 23px);
                color: $black;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 16px;
                text-transform: lowercase;
            }

            #btn_geoloc {
                width: 11vw;
                min-width: 120px;
                max-width: 200px;
                height: 48px;
                background: $grey3;
                color: $white;
                font-style: normal;
                font-weight: 400;
                font-size: clamp(12px, 1vw, 17px);
                font-family: $primaryFont;
                text-transform: uppercase;
            }
        }

        .section-list-store {
            grid-area: shops;

            .search-result {
                display: flex;
                margin-bottom: 17px;
            }

            #store-nbr {
                display: block;
                text-indent: 5px;
            }

            .store-container {
                .liste_distributeur {
                    max-height: 475px;
                    padding-right: 10px;

                    .elem_liste_distributeur {
                        position: relative;
                        height: 80px;
                        margin-bottom: 20px;
                        padding-bottom: 20px;
                        border-bottom: $cmsPageTitle 1px solid;

                        &:last-child {
                            border-bottom: none;
                        }

                        &.selected_store {
                            background: $grey16;
                        }

                        .nom_store {
                            display: flex;
                            margin-bottom: 5px;
                            color: #2B2B2B;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: normal;
                            font-family: $primaryFont;
                        }

                        .store_infos {
                            max-width: 39%;
                            font-weight: 400;
                            font-size: 13px;
                            text-transform: initial;
                        }

                        .horaire_today {
                            display: flex;
                            position: absolute;
                            bottom: 20px;
                            left: 40%;
                            flex-wrap: wrap;
                            align-items: center;
                            width: 245px;
                            font-size: 13px;
                            max-width: 35%;

                            .puce.store_open {
                                display: block;
                                width: fit-content;
                                width: 6%;
                                margin-left: 0;
                                color: $validGreen;
                                text-transform: capitalize;

                                &::before {
                                    display: inline-block;
                                    position: relative;
                                    top: -1px;
                                    margin-right: 5px;
                                    content: '•';
                                    color: $validGreen;
                                    font-size: 40px;
                                    font-family: sans-serif;
                                    vertical-align: middle;
                                }
                            }

                            .puce.store_closed {
                                color: $errorRed;
                                text-transform: capitalize;

                                &::before {
                                    display: inline-block;
                                    position: relative;
                                    top: 0;
                                    margin-right: 1px;
                                    content: '•';
                                    color: $errorRed;
                                    font-size: 40px;
                                    font-family: sans-serif;
                                    vertical-align: middle;
                                }
                            }

                            .mag_open_status {
                                width: 89%;
                                color: $validGreen;
                                font-weight: 400;
                                font-size: 13px;
                                text-transform: initial;
                            }
                        }

                        .favorite_store_button {
                            display: none;
                        }

                        .button.secondary {
                            display: flex;
                            position: absolute;
                            right: 0;
                            bottom: 20px;
                            justify-content: flex-end;
                            width: fit-content;
                            text-decoration: underline;
                            text-underline-offset: 4px;
                            text-transform: none;
                            transition: background-color .1ms ease;

                            &::after {
                                display: inline-block;
                                position: relative;
                                top: 3px;
                                right: -2px;
                                width: 10px;
                                height: 10px;
                                background-image: url(../svg/spritesheet.svg);
                                background-position: 102% 21%;
                                background-size: 600% auto;
                                background-repeat: no-repeat;
                                content: '';
                            }
                        }

                        .store_infos_ambassadeur {
                            display: inline-block;
                            position: absolute;
                            right: 21px;
                            bottom: 45px;
                            width: 70px;
                            height: 35px;
                            background-image: url('../img/svg/ambassadeur-petit.svg');
                            background-size: contain;
                            background-repeat: no-repeat;
                            content: '';
                        }

                        .store_infos_referent {
                            display: inline-block;
                            position: absolute;
                            right: 24px;
                            bottom: 45px;
                            width: 51px;
                            height: 34px;
                            background-image: url('../img/svg/referent-petit.svg');
                            background-size: contain;
                            background-repeat: no-repeat;
                            content: '';
                        }

                        .store_infos_essentiel {
                            display: inline-block;
                            position: absolute;
                            right: 31px;
                            bottom: 45px;
                            width: 45px;
                            height: 35px;
                            background-image: url('../img/svg/essentiel-petit.svg');
                            background-size: contain;
                            background-repeat: no-repeat;
                            content: '';
                        }
                    }
                }

                .list-store {
                    max-height: 510px;
                    overflow-y: auto;
                    overflow-x: hidden;
                    padding-right: 10px;

                    .elem-list-store {
                        position: relative;
                        margin-bottom: 20px;
                        padding-bottom: 20px;
                        border-bottom: $cmsPageTitle 1px solid;
                        display: flex;
                        align-items: center;

                        &:last-child {
                            border-bottom: none;
                        }

                        &.selected_store {
                            background: $grey16;
                        }

                        .nom_store {
                            .title {
                                display: flex;
                                margin-bottom: 5px;
                                color: #2B2B2B;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: normal;
                                font-family: $primaryFont;
                            }
                        }

                        .content_store {
                            max-width: 50%;
                            font-weight: 400;
                            font-size: 13px;
                            text-transform: initial;
                        }

                        .horaire_today {
                            display: block;
                            position: absolute;
                            bottom: 25px;
                            left: 40%;
                            flex-wrap: wrap;
                            align-items: center;
                            width: 200px;
                            font-size: 13px;

                            &.puce_store_open {
                                .mag_open_status {
                                    display: block;
                                    width: fit-content;
                                    width: 6%;
                                    margin-left: 0;
                                    color: $validGreen;
                                    text-transform: capitalize;
                                    height: 35px;

                                    &::before {
                                        position: relative;
                                        margin-right: 5px;
                                        content: '•';
                                        color: $validGreen;
                                        font-size: 40px;
                                        font-family: sans-serif;
                                        vertical-align: middle;
                                    }
                                }
                            }

                            &.puce_store_closed {
                                .mag_open_status {
                                    color: $errorRed;
                                    text-transform: capitalize;

                                    &::before {
                                        position: relative;
                                        margin-right: 1px;
                                        content: '•';
                                        color: $errorRed;
                                        font-size: 40px;
                                        font-family: sans-serif;
                                        vertical-align: middle;
                                    }

                                    span:last-of-type {
                                        margin-top: 30px;
                                        position: absolute;
                                        left: 0;
                                    }
                                }
                            }

                            .mag_open_status {
                                width: 89%;
                                color: $validGreen;
                                font-weight: 400;
                                font-size: 13px;
                                text-transform: initial;
                            }
                        }

                        .favorite_store_button {
                            display: none;
                        }

                        .store_more {
                            a {
                                display: flex;
                                position: absolute;
                                right: 0;
                                bottom: 20px;
                                justify-content: flex-end;
                                width: fit-content;
                                text-decoration: underline;
                                text-underline-offset: 4px;
                                text-transform: none;
                                transition: background-color .1ms ease;

                                &::after {
                                    display: inline-block;
                                    position: relative;
                                    top: 3px;
                                    right: -2px;
                                    width: 10px;
                                    height: 10px;
                                    background-image: url(../svg/spritesheet.svg);
                                    background-position: 102% 21%;
                                    background-size: 600% auto;
                                    background-repeat: no-repeat;
                                    content: '';
                                }
                            }
                        }
                    }
                }

                #scrollContent {
                    .os-scrollbar-track {
                        background-color: rgba(0, 0, 0, .1);
                    }
                }
            }
        }
    }

    .info_popup {
        min-width: 275px;
        padding: 12px;
        font-family: $primaryFont;

        .name {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;

            span {
                font-weight: 400;
                font-size: clamp(12px, .729vw, 14px);
            }
        }

        .adresse,
        .coord {
            font-weight: 400;
            font-size: 13px;
        }

        .horaire_info {
            margin: 1em 0 0;
            line-height: .9;

            p {
                width: 96%;
                font-weight: 600;

                span {
                    float: right;
                    font-weight: 400;
                }
            }

            .currently_open {
                color: $validGreen;
            }

            .currently_closed {
                color: $errorRed;
            }
        }

        .horaire_current {
            padding: 10px 0;
            color: $errorRed;
            font-weight: 400;
            font-size: 13px;
            line-height: 1;
            font-family: $primaryFont;

            .puce {
                &:before {
                    display: inline-block;
                    position: relative;
                    top: -1px;
                    content: '•';
                    font-size: 40px !important;
                    font-family: sans-serif;
                    vertical-align: middle;
                }

                &.store_open {
                    margin-left: 0;
                    font-family: $primaryFont;

                    &:before,
                    +.mag_open_status {
                        color: $validGreen;
                        font-weight: 400;
                        font-size: 13px;
                    }

                    +.mag_open_status+span {
                        display: block;
                        color: $grey3;
                        font-weight: 400;
                        font-size: 13px;
                    }
                }

                &.store_closed {
                    font-family: $primaryFont;
                }
            }

            .description {
                overflow: hidden;
            }

            .store_img {
                position: relative;
                overflow: hidden;

                .swiper-button-next,
                .swiper-button-prev {
                    display: flex;
                    z-index: 10;
                    position: absolute;
                    top: 50%;
                    align-items: center;
                    justify-content: center;
                    width: 5vmin;
                    height: 5vmin;
                    border-radius: 50%;
                    background-image: unset;
                    background-color: rgba(255, 255, 255, .8);
                    cursor: pointer;
                }

                .swiper-button-prev {
                    right: auto;
                    left: 10px;
                    transform: rotate(180deg);
                }
            }

            .swiper-pagination-bullet-active {
                background: $black;
            }
        }

        &.it {
            .elem_liste_distributeur {
                .button {
                    min-width: 115px;
                    margin-right: 0.5%;
                    font-size: clamp(12px, .729vw, 12px);
                    letter-spacing: 0;
                }
            }
        }
    }
}

.product_page #pdvBox.modalbox #store-container {

    #scrollContent {
        max-height: 74vh;
        overflow-y: auto;
        padding-right: 10px;
    }

    .nom_store {
        margin-bottom: 5px;
    }

    .elem-list-store {
        padding-top: 20px;
    }

    .elem-list-store:hover .title {
        opacity: 1;
    }

    .elem-list-store .statut {
        display: none;
    }

    .title {
        display: flex;
        color: $black;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        opacity: 0.8;
        transition-duration: 300ms;

        .distance {
            margin-left: 5px;
            font-weight: 400;
        }
    }

    .detail_store {
        display: grid;
        grid-template-columns: .5fr .5fr;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        text-transform: uppercase;
    }

    .rue_store {
        display: block;
    }

    .tel_store {
        display: block;
    }

    .pays_store {
        display: inline-block;
    }

    .content_store {
        display: flex;
        width: calc(100% - 30px);
        padding-bottom: 20px;
        border-bottom: $cmsPageTitle 1px solid;
    }

    .puce_store_open {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        text-transform: lowercase;

        .mag_open_status {
            display: block;
            width: auto;
            margin-right: 8px;
            text-transform: capitalize;

            &::before {
                display: inline-block;
                position: relative;
                top: -1px;
                margin-right: 5px;
                content: '•';
                color: $validGreen;
                font-size: 40px;
                font-family: sans-serif;
                vertical-align: middle;
            }
        }
    }

    .puce_store_closed {
        color: $errorRed;
        text-transform: capitalize;

        &::before {
            display: inline-block;
            position: relative;
            top: -1px;
            margin-right: 5px;
            content: '•';
            color: $errorRed;
            font-size: 40px;
            font-family: sans-serif;
            vertical-align: middle;
        }
    }

    .store_more {
        display: flex;
        position: relative;
        align-items: center;
        width: fit-content;

        &::after {
            position: absolute;
            bottom: calc(50% - 7px);
            width: calc(100% - 12px);
            height: 1px;
            scale: 1 1;
            background-color: $grey3;
            content: '';
            transition-duration: 300ms;
        }

        &:hover::after {
            scale: 0 1;
        }

        a,
        span {
            display: block;
            width: max-content;
            color: $grey3;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.05em;

            &::after {
                display: inline-block;
                width: 7px;
                height: 9px;
                margin-left: 5px;
                background: url('../img/svg/arrow_more.svg') 0 0 no-repeat;
                content: '';
            }
        }
    }
}

body.de.store_locator {
    .store_locator.details .bloc_store_det {
        display: none;
    }
}

.store_locator.details {
    width: 98%;
    max-width: 1364px;
    margin: 0 auto;
    padding-top: 20px;

    .innerWrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-top: 21px;
    }

    a.go_store {
        display: flex;
        color: $black;
        font-weight: 400;
        font-size: clamp(12px, 0.677vw, 12px);
        text-decoration: none;
        transition: color ease-in 250ms;

        &::before {
            margin-right: .4em;
            content: '<';
        }

        &:hover {
            color: $green;
        }

    }

    .left_bloc {
        width: 57%;
        max-width: 721px;

        .content_info {
            box-sizing: border-box;
            position: relative;
            padding-bottom: 14px;
            border-bottom: 1px solid $cmsPageTitle;

            .store_infos {
                width: 53%;
            }
        }

        .bloc_title {
            margin-bottom: 20px;
            font-weight: 700;
            font-size: clamp(14px, 0.833vw, 17px);
            line-height: 1;
            text-transform: uppercase;
        }
    }

    .right_bloc {
        width: 43.2%;
        max-width: 589px;

        .store_slider_wrapper {
            img {
                width: 100%;
                ;
            }
        }
    }

    #store_detail_map {
        width: 100%;
        height: 400px;
        margin-bottom: 32px;

        .gm-style-mtc {
            button {
                line-height: 1;
                font-family: $primaryFont;
                text-indent: inherit;
            }
        }
    }

    .image {
        img {
            width: 100%;
            height: auto;
            vertical-align: top;
        }
    }


    .title_details {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 18px;
        font-weight: 700;
        font-size: clamp(21px, 1.458vw, 25px);
        line-height: 1;
        text-transform: uppercase;
    }

    .storeAddress {
        min-height: 75px;
        margin-top: 11px;
        font-size: clamp(11px, 0.729vw, 13px);
        line-height: 1.5;
    }

    .store_direction {
        position: absolute;
        top: -10px;
        right: 24px;
        width: 44%;
        min-width: 192px;
        max-width: 314px;

        .btn_send_info,
        .btn_to_store {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 50px;
            margin-bottom: 12px;
            font-size: clamp(14px, 0.833vw, 17px);
            transition: all ease-in-out 250ms;

            span {
                font-family: $primaryFont;
            }
        }

    }

    .content_services {
        box-sizing: border-box;
        padding: 32px 0 34px;
        border-bottom: 1px solid $cmsPageTitle;

        .services_contents {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
        }

        .serviceItem {
            position: relative;
            margin-right: 6%;
            padding: 1em 0;

            &.livraison-a-domicile {
                h3 {
                    &:before {
                        background: url('../img/boutique/livraison-a-domicile.png') top center no-repeat;
                    }
                }
            }

            &.paiement-en-plusieurs-fois {
                h3 {
                    &:before {
                        background: url('../img/svg/picto_payment_card.svg') center center no-repeat;

                    }
                }
            }

            &.parking-gratuit {
                h3 {
                    &:before {
                        background: url('../img/svg/picto_parking.svg') top center no-repeat;
                    }
                }
            }

            &.acces-handicape {
                h3 {
                    &:before {
                        background: url('../img/svg/picto_acces_handicap.svg') top center no-repeat;
                    }
                }
            }

            &.borne-interactive {
                h3 {
                    &:before {
                        background: url('../img/svg/picto_borne.svg') top center no-repeat;
                    }
                }
            }

            &.echange-ou-avoir-selon-conditions {
                h3 {
                    &:before {
                        background: url('../img/boutique/echange-avoir.png') top center no-repeat;
                    }
                }
            }

            &.chapiteau-exterieur-dexposition {
                h3 {
                    &:before {
                        background: url('../img/boutique/chapiteau-exterieur.png') top center no-repeat;
                    }
                }
            }

            &.wifi-gratuit {
                h3 {
                    &:before {
                        background: url('../img/boutique/wifi.png') top center no-repeat;
                    }
                }
            }

            &.ouvert-le-dimanche {
                h3 {
                    &:before {
                        background: url('../img/boutique/ouverture-dimanche.png') top center no-repeat;
                    }
                }
            }

            &.aide-au-montage {
                h3 {
                    &:before {
                        background: url('../img/boutique/montage2.png') top center no-repeat;
                    }
                }
            }

            &.click-collect {
                h3 {
                    &:before {
                        background: url('../img/boutique/click-and-collect.png') top center no-repeat;
                    }
                }
            }

        }

        h3 {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 2.5rem;
            font-weight: 400;
            font-size: clamp(11px, 0.729vw, 13px);
            line-height: 1.5;

            &:before {
                position: absolute;
                left: 0;
                width: 34px;
                height: 30px;
                background-size: contain;
                content: '';
            }
        }
    }

    .schedule {
        box-sizing: border-box;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 27px 0 34px;
        border-bottom: 1px solid $cmsPageTitle;
    }

    .hor_left {
        width: 50.4%;
        max-width: 352px;
    }

    .hor_right {
        width: 44.6%;
        max-width: 314px;
    }

    .hor_right {
        margin-left: 5%;

        .horraire_spe {
            font-size: clamp(11px, 0.729vw, 13px);
            line-height: 1.3;
        }
    }

    .date_time {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        font-size: clamp(11px, 0.729vw, 13px);
        line-height: 1.3;


        span {
            font-weight: 600;

            +span {
                width: 67%;
                font-weight: 400;
                text-align: left;
                white-space: nowrap;
            }
        }

        &.actualday {
            span {
                font-weight: 600;
            }

        }
    }

    .mag_open {
        display: block;
        margin-top: 1rem;
        color: $validGreen;
    }

    .content_descrip {
        padding-top: 27px;
    }

    #descrip_store {
        position: relative;
        overflow: hidden;
        font-style: normal;
        font-weight: 400;
        font-size: clamp(11px, 0.729vw, 13px);
        line-height: 1.5;
        transition: height ease-out 350ms;

        .trigger {
            display: none;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding-top: 15px;
            background: $white;
            font-weight: 500;
            font-size: clamp(12px, 0.677vw, 13px);
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .description {
        overflow: hidden;
    }

    .store_img {
        position: relative;
        overflow: hidden;

        .swiper-button-next,
        .swiper-button-prev {
            display: flex;
            z-index: 10;
            position: absolute;
            top: 50%;
            align-items: center;
            justify-content: center;
            width: 5vmin;
            height: 5vmin;
            border-radius: 50%;
            background-image: unset;
            background-color: rgba(255, 255, 255, .8);
            cursor: pointer;
        }

        .swiper-button-prev {
            right: auto;
            left: 10px;
            transform: rotate(180deg);
        }
    }

    .swiper-pagination-bullet-active {
        background: $black;
    }
}

@media (max-width: 1150px) {
    .store_locator #site_global_wrap {
        >div {
            width: 100%;
        }

        .wrapper_retailers_map {
            grid-template-areas:
                "title title"
                "forms forms"
                "map map"
                "shops shops";
        }

        #retailers_map {
            height: 338px;
        }

        .storeloc_form {
            display: flex;
            justify-content: center;
        }
    }
}

.product_page #pdvBox.modalbox {
    &.deployed {
        z-index: 10002;
    }

    * {
        box-sizing: border-box;
    }

    #retailers_map {
        display: none;
        width: 200px;
        height: 200px;
    }

    .page-title,
    .popup_intro,
    #breadcrumbs,
    .ou,
    #btn_geoloc,
    .filtres_wrapper,
    .search-result {
        display: none !important;
    }

    .img-loader-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 50vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url('../svg/three_dots_black.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 30%;
        background-color: $white;
        z-index: 3;

        img {
            display: none;
        }
    }

    .storeloc_form {
        grid-area: forms;

        .wrap_fields {
            display: flex;
            align-items: center;
            width: 100%;
            height: 49px;
        }

        .country_select.w-dropdown {
            display: flex;
            position: relative;
            width: 225px;
            height: 100%;
            margin-right: 20px;
            border: 1px solid $grey3;
            border-radius: 0px;

            &::after {
                display: inline-block;
                top: 56%;
                width: 14px;
                height: 14px;
                translate: -50% -50%;
                rotate: 90deg;
                border: 0;
                background: url('../img/svg/arrow_more.svg') 0 0 no-repeat;
                background-size: contain;
                content: '';
                pointer-events: none;
            }
        }

        #search_pays {
            width: 100%;
            background-color: transparent;
        }

        .placeholderTxt {
            position: absolute;
            top: 50%;
            left: 1.2em;
            transform: translateY(-50%);
            transition: all .3s ease-in-out;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: $grey87;

            &.inUse {
                font-size: 12px;
                top: 30%;
                font-size: 10px;
            }
        }

        .field_address {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 100%;
            border: 1px solid #CFCFCF;
            position: relative;

            .magnifying_glass {
                width: 20%;
                min-width: 20px;
                max-width: 60px;
                height: calc(100% - 2px);
                background: url('../img/svg/marker_address_mobile.svg') $white center no-repeat;
                cursor: pointer;
            }

            #search_cp {
                width: calc(100% - 49px);
                height: 100%;
                padding-left: 10px;
                background-color: transparent;
                text-indent: 0;
                border-color: #CFCFCF;

                &::placeholder {
                    visibility: visible;
                }
            }
        }
    }

    #store-container {
        height: auto;

        .detail_store {
            display: block;

            .stock_status {
                align-self: center;
                height: fit-content;
            }
        }

        .content_store {
            display: grid;
            grid-template-columns: 1fr 1fr .5fr;
            border-color: #ededed;

            &:hover {
                cursor: pointer;
            }
        }

        .stock_status {
            margin-top: 10px;

            &.not_in_stock {
                color: $errorRed;
                font-weight: 600;
            }

            &.in_stock {
                color: $validGreen;
                font-weight: 600;
            }
        }

        .col-2 {
            margin-top: 10px;
        }
    }

    #list-store {
        max-height: 78vh;
    }
}

body.satellite_store_details {
    #content_pdvBox_new {
        .formulaire {

            .w-input.w-text-input,
            .w-input.w-textarea,
            #wrapper_optin {
                .inputErr {
                    border-color: $red;
                }
            }
        }
    }
}


// WOOSMAP STORE LOC (now common to all languages)

body.fr.store_locator #site_global_wrap {
    .wrapper_retailers_map {
        grid-column-gap: clamp(10px, 4vw, 62px);
        display: grid;
        grid-template-rows: 250px 75px 75px 565px;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "locator_header locator_header"
            "forms map"
            "filter map"
            "shops map";
        width: 100%;
        max-width: 1440px;
        margin-top: 3rem;
    }
}

body.store_locator #site_global_wrap {
    .wrapper_retailers_map {
        grid-column-gap: clamp(10px, 4vw, 62px);
        display: grid;
        grid-template-rows: 75px 75px 75px 485px;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "locator_header locator_header"
            "forms map"
            "shops map"
            "shops map";
        width: 100%;
        max-width: 1440px;
        margin: 3rem auto 0;

        #retailers_map {
            grid-area: map;
        }

        .page-title {
            grid-area: locator_header;
            align-self: flex-start;
            justify-self: center;
            height: fit-content;
            font-weight: 700;
            font-size: clamp(15px, 2vw, 30px);
            line-height: 1.5;
            text-align: center;
            text-transform: uppercase;
        }

        .locator_header {
            display: flex;
            grid-area: locator_header;
            justify-content: space-between;

            .locator_heading--left {
                display: flex;
                flex-direction: column;
                width: 47%;
                padding-top: 1rem;

                .page-title-store {
                    align-self: flex-start;
                    margin-bottom: 1rem;
                    color: $grey3;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 25px;
                    line-height: normal;
                    font-family: $primaryFont;
                    text-align: left;
                    text-transform: uppercase;
                }

                .description {
                    color: $grey3;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: normal;
                    font-family: $primaryFont;
                }
            }

            .locator_heading--right {
                width: 48%;

                .list-type {
                    display: flex;
                    justify-content: space-between;

                    .column-type {
                        width: 29%;

                        .description {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            width: 100%;
                            color: #2B2B2B;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 16px;
                            font-family: $primaryFont;
                            text-align: justify;

                            & .ambassadeur {
                                display: block;
                                width: 114px;
                                height: 65px;
                                margin: auto auto 10px auto;
                                background-image: url("../img/svg/ambassadeur-grand.svg");
                                background-size: contain;
                                background-repeat: no-repeat;
                            }


                            & .referent {
                                display: block;
                                width: 73px;
                                height: 65px;
                                margin: auto auto 10px auto;
                                background-image: url("../img/svg/referent-grand.svg");
                                background-size: contain;
                                background-repeat: no-repeat;
                            }

                            & .essentiels {
                                display: block;
                                width: 77px;
                                height: 65px;
                                margin: auto auto 10px auto;
                                background-image: url("../img/svg/essentiel-grand.svg");
                                background-size: contain;
                                background-repeat: no-repeat;
                            }
                        }
                    }
                }
            }
        }

        .storeloc_form {
            grid-area: forms;
            width: 100%;
            height: 49px;

            .wrap_fields {
                display: flex;
                align-items: center;
                width: 100%;
                height: 49px;
            }

            .country_select.w-dropdown {
                display: flex;
                position: relative;
                width: 120px;
                height: 100%;
                margin-right: 8px;
                border: 1px solid $grey3;
                border-radius: 0px;

                &::after {
                    display: inline-block;
                    top: 56%;
                    width: 14px;
                    height: 14px;
                    translate: -50% -50%;
                    rotate: 90deg;
                    border: 0;
                    background: url('../img/svg/arrow_more.svg') 0 0 no-repeat;
                    background-size: contain;
                    content: '';
                    pointer-events: none;
                }
            }

            #search_pays {
                width: 100%;
                padding-left: 20px;
                background-color: transparent;
            }

            .field_address {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                max-width: 422px;
                height: 100%;
                border: 1px solid $grey3;

                .magnifying_glass {
                    width: 20%;
                    min-width: 20px;
                    max-width: 60px;
                    height: 100%;
                    background: url('../img/svg/store_search_line.svg') $white center no-repeat;
                    cursor: pointer;
                }

                #search_cp {
                    width: calc(100% - 49px);
                    height: 100%;
                    padding-left: 10px;
                    background-color: transparent;

                    &::placeholder {
                        visibility: hidden;
                    }
                }
            }

            .ou {
                margin: 0 clamp(5px, 1vw, 23px);
                color: $black;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 16px;
                text-transform: lowercase;
            }

            .send_form.form_itm {
                width: 100%;

                .btn_container {
                    width: 100%;
                }
            }

            #btn_geoloc {
                width: 100%;
                max-width: 230px;
                height: 48px;
                background: $grey3;
                color: $white;
                font-weight: 400;
                font-size: clamp(12px, 1vw, 17px);
                font-family: $primaryFont;
                text-transform: uppercase;
                cursor: pointer;
            }
        }

        .filter_storeloc {
            display: none;
        }

        .section-list-store {
            grid-area: shops;

            .element_count {
                margin: 0 5px;
                font-weight: 500;
            }

            .search-result {
                display: flex;
                margin-bottom: 17px;
            }

            #store-nbr {
                display: block;
                text-indent: 5px;
            }
        }
    }

    #voir_plus {
        z-index: 1;
    }
}

body.satellite_store_details {

    .left_bloc .content_info {

        padding-bottom: 25px;

        .store_infos {
            display: flex;
            flex-wrap: wrap;
            width: 53%;

            .nom_mag {
                width: 100%;

                .title_details {
                    margin-bottom: 25px;
                }
            }

            .store_infos_ambassadeur {
                display: flex;
                align-self: center;
                width: 114px;
                height: 65px;
                margin-right: 1rem;
                background-image: url("../img/svg/ambassadeur-grand.svg");
                background-size: contain;
                background-repeat: no-repeat;
            }

            .store_infos_referent {
                display: flex;
                align-self: center;
                width: 85px;
                height: 65px;
                margin-right: 1rem;
                background-image: url("../img/svg/referent-grand.svg");
                background-size: contain;
                background-repeat: no-repeat;
            }

            .store_infos_essentiel {
                display: flex;
                align-self: center;
                width: 85px;
                height: 65px;
                margin-right: 1rem;
                background-image: url("../img/svg/essentiel-grand.svg");
                background-size: contain;
                background-repeat: no-repeat;
            }

            .storeAddress {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 7rem;
                margin-top: 0;
                padding-left: 1rem;
                border-left: 1px solid black;
            }
        }

        .store_direction.btn_container {
            position: absolute;
            top: 60px;
            right: 0;
            width: 44%;
            min-width: unset;
            max-width: 220px;
        }
    }

    .mag_opened {
        display: block;
        width: 100%;
        font-weight: 500;
        font-size: 13px;
        text-transform: initial;
        color: #63ab07;
        margin-top: 1rem;
    }
}

body.product_page #pdvBox.modalbox #store-container,
body.store_locator #site_global_wrap #store-container {

    #liste_distributeur {
        padding-right: 10px;

        .elem_liste_distributeur {
            position: relative;
            height: 80px;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: $cmsPageTitle 1px solid;

            &:last-child {
                border-bottom: none;
            }

            &.selected_store {
                background: $grey16;
            }

            .nom_store {
                display: flex;
                margin-bottom: 5px;
                color: #2B2B2B;
                font-weight: 600;
                font-size: 14px;
                line-height: normal;
                font-family: $primaryFont;

                &+ span {
                    display: none;
                }
            }

            .store_infos {
                max-width: 39%;
                font-weight: 400;
                font-size: 13px;
                text-transform: initial;
            }

            .horaire_today {
                display: flex;
                position: absolute;
                bottom: 20px;
                left: 40%;
                flex-wrap: wrap;
                align-items: center;
                width: 245px;
                font-size: 13px;

                .puce.store_open {
                    display: block;
                    width: fit-content;
                    width: 6%;
                    margin-left: 0;
                    color: $validGreen;
                    text-transform: capitalize;

                    &::before {
                        display: inline-block;
                        position: relative;
                        top: -1px;
                        margin-right: 5px;
                        content: '•';
                        color: $validGreen;
                        font-size: 40px;
                        font-family: sans-serif;
                        vertical-align: middle;
                    }
                }

                .puce.store_closed {
                    color: $errorRed;
                    text-transform: capitalize;

                    &::before {
                        display: inline-block;
                        position: relative;
                        top: 0;
                        margin-right: 1px;
                        content: '•';
                        color: $errorRed;
                        font-size: 40px;
                        font-family: sans-serif;
                        vertical-align: middle;
                    }
                }

                .mag_open_status {
                    width: 89%;
                    color: $validGreen;
                    font-weight: 400;
                    font-size: 13px;
                    text-transform: initial;
                }
            }

            .favorite_store_button {
                display: none;
            }

            .button.secondary {
                display: flex;
                position: absolute;
                right: 0;
                bottom: 20px;
                justify-content: flex-end;
                width: fit-content;
                text-decoration: underline;
                text-underline-offset: 4px;
                text-transform: none;
                transition: background-color .1ms ease;


                &::after {
                    display: inline-block;
                    position: relative;
                    top: 3px;
                    right: -2px;
                    width: 10px;
                    height: 10px;
                    background-image: url(../svg/spritesheet.svg);
                    background-position: 102% 21%;
                    background-size: 600% auto;
                    background-repeat: no-repeat;
                    content: '';

                }
            }
        }
    }
}

body.product_page #pdvBox.modalbox #content_pdvBox {

    .locator_header {
        display: none;
    }

    .filter_storeloc {
        display: none;
    }

    .storeloc_form .autocomplete-results {
        top: 95%;
        left: 0;
        width: 100%;
        max-width: 100%;
    }

    #liste_distributeur {
        padding-right: 0;

        .elem_liste_distributeur {
            max-height: 173px !important;
            height: fit-content;
            display: flex;
            flex-direction: column;
            padding-top: 20px;
            padding-right: 200px;
            margin-bottom: 0;

            .store_infos_essentiel,
            .store_infos_referent,
            .store_infos_ambassadeur {
                display: none !important;
            }

            .store_phone {
                margin-top: 6px;
            }

            .store_infos {
                max-width: none;
            }

            .horaire_today {
                position: initial !important;
                margin-top: 10px;
                order: 4;
                width: auto;

                .puce.store_open {
                    display: block;
                    color: $validGreen;
                    text-transform: capitalize;
                    width: auto;

                    &::before {
                        display: inline-block;
                        position: relative;
                        top: -1px;
                        margin-right: 5px;
                        content: '•';
                        color: $validGreen;
                        font-size: 40px;
                        font-family: sans-serif;
                        vertical-align: middle;
                    }
                }

                .puce.store_closed {
                    display: flex;
                    align-items: center;
                    color: $errorRed;
                    text-transform: capitalize;
                    width: auto;
                    height: 15px;

                    &::before {
                        display: inline-block;
                        position: relative;
                        top: 0;
                        margin-right: 5px;
                        content: '•';
                        color: $errorRed;
                        font-size: 40px;
                        font-family: sans-serif;
                        vertical-align: middle;
                    }
                }

                .mag_open_status {
                    width: auto;
                    color: $grey3;
                    margin-right: 5px;
                }
            }

            .stock_status {
                display: flex !important;
                order: 5;
                width: auto;
                font-weight: 400;

                &.not_in_stock {
                    color: $errorRed;

                    &::before {
                        display: inline-block;
                        position: relative;
                        margin-left: 2px;
                        margin-right: 4px;
                        content: '•';
                        color: $errorRed;
                        font-size: 25px;
                        font-family: sans-serif;
                        vertical-align: middle;
                    }
                }

                &.in_stock {
                    color: $validGreen;

                    &::before {
                        display: inline-block;
                        position: relative;
                        margin-left: 2px;
                        margin-right: 4px;
                        content: '•';
                        color: $validGreen;
                        font-size: 25px;
                        font-family: sans-serif;
                        vertical-align: middle;
                    }
                }
            }

            .button.secondary {
                display: flex;
                width: 190px;
                height: 50px;
                padding: 15px 30px;
                top: calc(50% - 25px);
                bottom: unset;
                justify-content: center;
                align-items: center;
                background-color: $grey3;
                font-size: 17px;
                text-decoration: none;
                text-transform: uppercase;
                color: $white;

                &::after {
                    display: none;
                }
            }
        }
    }

    #voir_plus {
        display: none !important;
    }
}

body #site_global_wrap .wrapper_retailers_map .storeloc_form .field_address {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    max-width: 430px;
}

body .wrapper_retailers_map {
    width: 1440px;
    max-width: 1440px;

    .storeloc_form {

        .country_select.w-dropdown {
            display: none !important;
        }

        .autocomplete-icon.icon-localities {
            width: 15px;
            height: 19px;
            margin-right: 6px;
            background-image: url("https://images.woosmap.com/icons/locality.svg");
            background-repeat: no-repeat;
        }


        .autocomplete-results {
            box-sizing: border-box;
            z-index: 99;

            position: absolute;
            top: 102%;
            left: -1px;
            width: 101%;
            max-width: 422px;
            margin: 0px;
            padding: 0px;
            border-top-style: initial;
            border-image: initial;
            border-top-color: initial;
            background-color: rgb(255, 255, 255);
            box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
            list-style-type: none;

            li {
                display: flex;
                align-items: center;
                padding: 2px 8px;
                overflow: hidden;
                border-top: 1px solid #e6e6e6;
                color: #515151;
                font-size: .875rem;
                font-size: 11px;
                line-height: 2.72727em;
                text-align: left;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: default;

                &:hover {
                    background-color: #fafafa;
                    cursor: pointer;
                }
            }
        }

    }
}


body.firefox {
    .wrapper_retailers_map {
        scrollbar-color: $grey3 transparent;
        scrollbar-width: thin;
    }

}

#pdvBox.modalbox {
    .wrapper_retailers_map {
        width: 100%;
        max-width: 100%;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: $fadeGrey;
        }

        &::-webkit-scrollbar-thumb {
            background: $grey3;
        }

        // to avoid ugly default scrollbar styling on Chrome...
        // https://frontendmasters.com/blog/heads-up-on-custom-scrollbars-chrome-is-supporting-the-standard-now-which-overrides-the-old-pseudo-elements/
        // but our current sass version doesn't support @supports yet, so we can't use it here (yet!)
        /*
        TODO: uncomment when we can use @supports
        @supports not selector(::-webkit-scrollbar) {
                scrollbar-color: $grey3 transparent;
                scrollbar-width: thin;
        }
        */

    }

    #search_cp {
        //on outrepasse style inline
        width: calc(100% - 49px) !important;
    }
}

body.store_locator #site_global_wrap {

    .woosmap-map-pane {

        span,
        div {
            -ms-overflow-style: none;
            box-sizing: unset !important;
            scrollbar-width: none;
        }


        div::-webkit-scrollbar {
            display: none;
        }

        .info_popup {
            padding-bottom: 5px;
        }

        .adresse {
            p {
                display: none;
            }
        }

        .horaire_info {
            margin: 1em 0 0;
            line-height: 1.5;

            .horaire_title {
                display: none;
            }

            p {
                width: 96%;
                font-weight: 600;

                span {
                    float: right;
                    font-weight: 400;
                }
            }
        }

        .currently_open {
            color: $validGreen;
            font-weight: 500;
            text-transform: capitalize;
        }

        .currently_closed {
            color: $errorRed;
            font-weight: 500;
        }

        .savoir_plus {

            font-weight: 400;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    #liste_distributeur_wrapper {
        .os-padding {
            .os-content {
                &::-webkit-scrollbar {
                    display: none;
                }

                -ms-overflow-style: none;
                /* IE and Edge */
                scrollbar-width: none;
                /* Firefox */
            }
        }

        #liste_distributeur {
            max-height: 510px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 2px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 3px;
                background: $grey16;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 3px;
                background: $grey3;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        }
    }

    &>div {
        width: 100%;
    }
}

// FR exception - show store filters
body.fr.store_locator #site_global_wrap .wrapper_retailers_map .filter_storeloc {
    display: flex;
    grid-area: filter;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 49px;

    .filtres_storeloc_text {
        //style inline
        display: flex !important;
    }

    .filtres_storeloc {
        display: flex;
        align-items: center;
        margin: 0 20px;

        .checkbox {
            display: none;
        }

        input[type="checkbox"] {
            display: grid !important;
            appearance: none;
            place-content: center;
            width: 16px;
            height: 16px;
            margin: 0;
            border: 1px solid currentColor;
            border-radius: 0.15em;
            background-color: #fff;
            color: currentColor;
            font: inherit;
            cursor: pointer;
        }

        input[type="checkbox"]::before {
            width: 10px;
            height: 10px;
            transform: scale(0);
            box-shadow: inset 2em 2em black;
            content: "";
            transition: 120ms transform ease-in-out;
        }

        input[type="checkbox"]:checked::before {
            transform: scale(1);
        }

        .radio_filtre {
            margin-left: 10px;
        }
    }
}

// FR exception - show store filter images
body.fr.store_locator #site_global_wrap #store-container #liste_distributeur .elem_liste_distributeur {
    .store_infos_ambassadeur {
        display: inline-block;
        position: absolute;
        right: 21px;
        bottom: 45px;
        width: 70px;
        height: 35px;
        background-image: url('../img/svg/ambassadeur-petit.svg');
        background-size: contain;
        background-repeat: no-repeat;
        content: '';
    }
    
    .store_infos_referent {
        display: inline-block;
        position: absolute;
        right: 24px;
        bottom: 45px;
        width: 51px;
        height: 34px;
        background-image: url('../img/svg/referent-petit.svg');
        background-size: contain;
        background-repeat: no-repeat;
        content: '';
    }
    
    .store_infos_essentiel {
        display: inline-block;
        position: absolute;
        right: 31px;
        bottom: 45px;
        width: 45px;
        height: 35px;
        background-image: url('../img/svg/essentiel-petit.svg');
        background-size: contain;
        background-repeat: no-repeat;
        content: '';
    }
}

// WOOSMAP STORE LOC END