/* ANIMATIONS */
@-webkit-keyframes revolution {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes revolution {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0);
    }
}

#_lm_pix {
    height: 0px;

    img {
        width: 0px !important;
        height: 0px !important;
    }
}

/* GENERAL */
html {
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    font-family: $primaryFont;
    font-size: 15px;
    color: $grey3;
    -webkit-backface-visibility: hidden;
}

main {
    height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

img {
    vertical-align: middle;
}

fieldset {
    padding: 0;
    border: none;
    margin: auto;
}

button,
input,
select,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    line-height: 3;
    background-color: $white1;
    padding: 0;
    border: none;
    border-radius: 0;
    font-weight: 400;
    font-size: 1em;
}

::-webkit-input-placeholder {
    font-style: normal;
    color: $lightcharcoal;
}

:-moz-placeholder {
    font-style: normal;
    color: $lightcharcoal;
}

::-moz-placeholder {
    font-style: normal;
    color: $lightcharcoal;
}

:-ms-input-placeholder {
    font-style: normal;
    color: $lightcharcoal;
}

a:focus,
button:focus,
input:focus,
select:focus,
textarea:focus {
    outline: none;
}

ul:not(.list-reset-style) {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.in_tunnel header {
    display: none;
}

.modal_shade {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 100;
}

.modal_shade.ng-shade {
    display: block;
}

.modal_shade.ng-enter {
    opacity: 0;
    transition: opacity 0.2s linear;
}

.modal_shade.ng-enter-active {
    opacity: 1;
}

.modal_shade.ng-leave {
    opacity: 1;
    transition: opacity 0.2s linear 0.3s;
}

.modal_shade.ng-leave-active {
    opacity: 0;
}

.copy_social_media {
    display: flex;
    justify-content: flex-start;
    margin-top: 1.5rem;

    .social_btn {
        width: 28px;
        height: 28px;
        background-image: url("../svg/social_media.svg");
        background-size: 500%;
        font-size: 0;
        cursor: pointer;
        margin-right: 0.8rem;

        &.facebook {
            background-position: 0 0;

            /*TARGET ONLY IE*/
            @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
                background-position: 0 15%;
            }
        }

        &.instagram {
            background-position: 25% 0;

            /*TARGET ONLY IE*/
            @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
                background-position: 25% 15%;
            }
        }

        &.youtube {
            background-position: 50% 0;

            /*TARGET ONLY IE*/
            @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
                background-position: 50% 15%;
            }
        }

        &.pinterest {
            background-position: 100% 0;

            /*TARGET ONLY IE*/
            @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
                background-position: 100% 15%;
            }
        }

        &.blog {
            background-position: 75% 0;

            /*TARGET ONLY IE*/
            @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
                background-position: 75% 15%;
            }
        }

        &.email {
            background-position: 50% 100%;
        }
    }
}

.it .social_btn.blog {
    display: none;
}

.copy_social_media .block_lnk {
    display: block;
    width: 100%;
    height: 100%;
}

fieldset.d_row {
    display: block;
}

#adresseForm>fieldset.d_row:first-of-type {
    width: 800px;
    padding-bottom: 20px;
    border-bottom: 1px solid $grey95;
    margin: 0 0 15px;
}

#adresseForm>fieldset.d_row:first-of-type .form_itm {
    margin: 0;
}

.d_row:after {
    display: block;
    clear: both;
    content: '';
}

.d_row .form_row {
    float: left;
}

.form_itm {
    position: relative;
}

.d_row .form_itm,
.form_itm.multiple .form_itm {
    display: inline-block;
    vertical-align: middle;
}

.form_itm.multiple .checkbox_wrapper {
    display: inline-flex;
    justify-content: space-between;
    vertical-align: middle;
    width: 220px;
}

.d_row .form_row .form_itm {
    display: block;
}

#adresseForm .form_row .form_itm,
#adresseForm fieldset>.form_itm:not(.form_row) {
    margin-bottom: 15px;
}

#adresseForm .form_itm .labelErr,
#adresseForm .form_itm .labelForm {
    display: inline-block;
    vertical-align: middle;
    width: 220px;
    padding-right: 10px;
    text-align: right;
    box-sizing: border-box;
}

#adresseForm .form_itm .labelErr {
    color: $carnelain;
}

#adresseForm .form_itm .inputErr,
#adresseForm .form_itm .inputFormSite {
    padding: 0 0.5em;
    text-indent: 0;
    box-sizing: border-box;
}

#adresseForm .form_itm .inputFormSite {
    width: 100%;
    font-size: 11px;
}

#adresseForm .date_select {
    .inputErr {
        border: 1px solid $red;
    }
}

#adresseForm .form_itm .intl-tel-input .inputErr,
#adresseForm .form_itm .intl-tel-input .inputFormSite {
    padding-left: 52px;
}

#adresseForm .form_itm .selectInputWrapper {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 220px;
    height: 47px;
    line-height: 47px;
    background-color: $white1;
}

#adresseForm .form_itm .selectInputWrapper:before {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 47px;
    height: 47px;
    background: url("../img/arrow_show_btm.png") center center no-repeat;
    content: '';
}

#adresseForm .form_itm .selectInputWrapper .selectInput {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    padding: 0 0.5em;
    text-indent: 0;
    cursor: pointer;
}

#adresseForm .form_submit .btn_light {
    display: block;
    float: right;
    width: 220px;
    height: 47px;
    line-height: 47px;
    text-align: center;
    background-color: $btnBgColor;
    padding: 0;
    margin: 0 0 15px;
    text-indent: 0;
}

#adresseForm .img_error {
    right: 0.5rem;
}

.form_itm.check .radio_input {
    display: none;
}

.form_itm.check .radio_input+label {
    display: inline-block;
    vertical-align: middle;
    height: 17px;
    line-height: 17px;

    font-size: 15px;
    color: $nero;
    cursor: pointer;
    user-select: none;
}

.form_itm.check .radio_input+label span {
    display: inline-block;
    vertical-align: middle;
}

.form_itm.check .radio_input+label:before {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    background-clip: content-box;
    padding: 2px;
    border: 2px solid $green;
    border-radius: 8px;
    box-sizing: border-box;
    transition: background-color 0.2s linear;
    content: '';
}

.form_itm.check .radio_input:checked+label:before {
    background-color: $black;
}

.form_submit {
    position: relative;
    width: 100%;
}

.button {
    width: auto;
    text-align: center;
    padding: 0 1em;

    font-size: 0.85em;
    text-indent: 0;
    text-transform: uppercase;
    cursor: pointer;
}

.button.default {
    background-color: $green;
    color: $white;
}

.button.borders {
    border: 2px solid $green;
    background-color: transparent;
    box-sizing: border-box;
    font-size: 12px;
    color: $grey3;
}

.button.valid {
    background-color: $black;
    color: $white;
}

.button.fwd {
    height: 50px;
    line-height: 50px;
    background-color: $grey3;
    font-size: 15px;
    color: $white;
}

.button.next_step {
    position: relative;
    float: right;
    height: 30px;
    line-height: 26px;
    background-color: $eveningprime;
    padding: 0 36px;
    border: 2px solid $eveningprime;
    margin-top: 21px;

    font-size: 10px;
    color: $black;
    transition: background-color, border-color;
    transition-duration: 0.2s;
    transition-timing-function: linear;
}

.button.next_step[disabled] {
    background-color: transparent;
    border-color: $gray16;
    color: $zambezi;
    cursor: not-allowed;
}

.button.next_step:after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 5px;
    height: 8px;
    background-image: url("../img/detail_msg_puce.png");
    transition: opacity 0.2s linear;
    content: '';
}

.button.next_step[disabled]:after {
    opacity: 0.6;
}

.form_submit.full .button {
    text-align: center;
    width: 100%;
    padding: 0;
}

.data_treatment {
    color: $mediundarkgray1;
    font-size: 12px;
    margin-top: 18px;

    a {
        text-decoration: underline;
    }
}

.form_footnote {

    font-size: 11px;
    color: $lightcharcoal;
}

.sticky {
    position: relative;
}

.page_wrapper {
    width: 100%;
    box-sizing: border-box;
}

#orders_by_type .page_wrapper {
    padding-top: 0;
}

.main_wrapper {
    width: 98%;
    max-width: 1690px;
    margin: auto;

    &.lamarque {
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
            padding-left: 10px;
        }

        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
            padding-left: 15px;
        }
    }
}

.main_wrapper.special {
    position: relative;
}

.hidden {
    display: none;
}

.slider {
    overflow: hidden;

    .item {
        .fp_img_container {
            position: relative;
            width: 100%;
            height: 0;
            padding-top: calc(440 / 440 * 100%);

            .fp_img_content {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                .assoc_other_views {
                    vertical-align: top;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

.swiper_container {
    position: relative;

    .swiper-button-next,
    .swiper-button-prev {
        width: 12px;
        height: 21px;
        background: transparent;

        &:before {
            top: 8px;
        }

        &:after {
            top: 72%;
        }
    }

    .swiper-button-next {
        top: 45%;
    }
}

.btn_buy {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 41px;
    line-height: 41px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.65);
    padding: 0 18px;
    border: 2px solid $green;
    box-sizing: border-box;
    font-size: 13px;
    color: $eclipse;
    text-transform: uppercase;
    text-indent: 0;
    overflow: hidden;
    cursor: pointer;
}

.btn_buy:not(.loader):after {
    display: block;
    position: absolute;
    top: 0;
    right: 100%;
    width: 100%;
    height: 100%;
    line-height: 37px;
    background-color: $green;
    color: $white;
    -webkit-transition: right 0.2s ease-in-out;
    transition: right 0.2s ease-in-out;
    content: attr(data-text);
}

.customer .wrapper_content_warranty {

    .btn_buy:not(.loader):after {
        display: none !important;
    }

    .btn_buy:hover {
        background-color: #fff;
        color: $darkgray;
        border: 1px solid $grey3;
    }
}

.btn_buy:not(.loader):hover:after {
    right: 0;
}

.btn_buy span {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    width: 100%;
    height: 100%;
    line-height: 37px;
    text-align: center;
    -webkit-transition: top 0.2s linear;
    transition: top 0.2s linear;
}

.btn_buy:not(.loader):hover span {
    top: 100%;
}

.wrapper_moncompte .btn_buy:not(.loader):hover span {
    top: 0;
}

.btn_buy+.loader {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

#completeDiv {
    box-sizing: border-box;
}

.tracker {
    position: absolute;
    padding: 1px;
    box-sizing: border-box;
}

.tracker.Y {
    top: 50%;
    right: -10px;
    width: 7px;
}

.store_locator .liste_recherche_distributeur .tracker.Y {
    display: block;
}

.tracker.X {
    bottom: 0;
    left: 50%;
    height: 7px;
    margin: 5px 0;
}

.track {
    position: relative;
    width: 3px;
    background: $black;
}

.gm-style .info_popup>img {
    max-width: 220px !important;
}

/* SPRITESHEETS */
.vector_sprite.pseudo:after,
.vector_sprite.pseudo:before,
.vector_sprite:not(.pseudo) {
    background-image: url("../img/icns_spritesheet.svg");
    background-size: 300%;
}

.vector_sprite.pseudo.after:after,
.vector_sprite.pseudo.before:before {
    vertical-align: middle;
    width: 25px;
    height: 25px;
    background-position: inherit;
    content: '';
    display: none;
}

.vector_sprite.geoloc {
    background-position: 0 0;
}

.vector_sprite.waranty {
    background-position: 0 50%;
}

.vector_sprite.power {
    background-position: 0 100%;
}

.vector_sprite.catalog {
    background-position: 50% 0;
}

.vector_sprite.blog {
    background-position: 50% 50%;
}

.vector_sprite.wish {
    background-position: 50% 100%;
}

.vector_sprite.search {
    background-position: 100% 0;
}

.vector_sprite.geoloc_alt {
    background-position: 100% 50%;
}

.vector_sprite.crosshair {
    background-position: 100% 100%;
}

.flags_sprite {
    a {
        display: flex;
        align-items: center;

        &:before {
            content: '';
            display: block;
        }
    }

    &.france a:before {
        background: url("../img/flag-france.svg") no-repeat center/cover;
    }

    &.switzerland a:before {
        background: url("../img/flag-switzerland.svg") no-repeat center/cover;
    }

    &.portugal a:before {
        background: url("../img/flag-portugal.svg") no-repeat center/cover;
    }

    &.reunion a:before {
        background: url("../img/flag-reunion.svg") no-repeat center/cover;
    }
}

/* BREADCRUMBS (fil d'Ariane) */
.breadcrumbs_wrapper {
    width: 98%;
    max-width: 1690px;
    position: absolute;
    bottom: -44px;

    .breadcrumbs {
        width: 100%;
        line-height: 4;
        font-size: 10px;
        font-weight: 400;
    }

    .breadcrumb {
        display: inline-block;
        margin-left: .5em;
        color: $nobel;
        letter-spacing: .02rem;

        &:first-child {
            margin-left: 0;
        }

        &.current {
            color: $black;
        }

        &:not(.current):after {
            display: inline-block;
            width: 1.5em;
            text-align: right;
            content: '/';
        }
    }
}

.sticky .breadcrumbs_wrapper {
    display: none;
}

/* LEFT MENU */
.filters {
    position: relative;
    width: 240px;
    color: $grey2;

    .district_name {
        position: relative;
        display: table;
        line-height: 1;
        padding: 22px 0 20px;
        margin: 0 0 .8em;
        font-size: 25px;
        font-weight: 700;

        &:after {
            position: absolute;
            top: 95%;
            left: 0;
            width: 20%;
            height: 3px;
            background-color: $grey3;
            content: '';
        }
    }
}

.filters .fullpage-loader {
    min-height: 55vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {

        font-weight: 900;
        font-size: 12 / 16 * 1rem;
        color: $darkgray;
        letter-spacing: 2px;
        text-transform: uppercase;
    }

    &:before {
        display: block;
        width: 2.5em;
        height: 2.5em;
        background: transparent url(../svg/loader_custom_l.svg) center no-repeat;
        background-size: cover;
        margin-right: 0.75em;
        animation: revolution 1.6s steps(16, start) infinite;
        content: '';
    }
}

.filters .sub_categ {

    font-size: 0.95em;
    line-height: 1;
    padding-left: 0.7rem;
    font-weight: 400;
    display: none;
}

.menuitem.actif {
    .sub_categ {
        display: block;
    }
}

.filters .sub_categ .sub_cat_name {
    display: block;
    margin-bottom: 0.5em;
    text-decoration: none;
    color: $black;

}

.filters .sub_categ .bloc_itm .itm_name {
    display: block;
    padding-left: 1em;
    margin-bottom: 0.45em;
    color: $discreetgrey;

}

.filters .sub_categ .bloc_itm .itm_name.btn_select,
.filters .sub_categ .bloc_itm .itm_name:hover {
    color: $grey2;
}

.filters .sub_categ .bloc_itm .itm_name:last-child {
    margin-bottom: 0.65em;
}

.filter_wrapper {
    padding-top: 2em;
}

.filter_wrapper .filter_name {
    position: relative;
    font-size: 13px;
    font-weight: 700;
    color: $pantone;
    text-transform: uppercase;
    letter-spacing: .02rem;
}

.filter_wrapper.collapsible .filter_name {
    cursor: pointer;

    &:after {
        display: block;
        position: absolute;
        top: 50%;
        right: -16px;
        transform: rotateZ(0);
        width: 8px;
        height: 8px;
        background-image: url(../img/collapsible_marker.gif);
        margin-top: -4px;
        content: '';
    }
}

.filter_wrapper.hoverable .filter_name {
    cursor: pointer;

    &:after {
        display: block;
        position: absolute;
        top: 50%;
        right: -16px;
        transform: rotateZ(-90deg);
        width: 8px;
        height: 8px;
        background-image: url(../img/collapsible_marker.gif);
        margin-top: -4px;
        content: '';
    }
}

.filter_wrapper.hoverable:hover .filter_name::after {
    transform: rotateZ(0);
}

.filter_wrapper.collapsible.collapsed .filter_name:after {
    -webkit-transform: rotateZ(-90deg);
    transform: rotateZ(-90deg);
}

.filter_wrapper .selected_options {
    margin-top: 0;
}

#filter_price.filter_wrapper .selected_options {
    margin-top: 0;
}

.filter_wrapper.colapsible .selected_options {
    display: none;
}

.filter_wrapper .selected_options .filter_hint {
    clear: both;
    line-height: 0.85;
    text-align: justify;
    text-align-last: justify;
    width: 165px;
    margin: 1em 0 0;
    font-size: 12px;
}

.filter_wrapper .rng_slider {
    width: 200px;
    height: 1px;
    background: $gainsboro;
}

.filter_wrapper .rng_slider .ui-widget-header {
    background-color: $black;
}

.filter_wrapper .rng_slider .ui-slider-handle {
    top: -7px;
    width: 14px;
    height: 14px;
    background-color: $greenyellow;
    border: 1px solid transparent;
    border-radius: 50%;
}

.filter_wrapper .rng_slider .ui-slider-handle:active,
.filter_wrapper .rng_slider .ui-slider-handle:hover {
    border-color: $greendark;
}

.price_input_wrapper {
    width: 220px;
    height: 33px;
    text-align: center;
    padding-top: 20px;
}

.price_input_wrapper .price_input {
    display: inline-block;
}

.price_input .price_input_part {
    display: inline-block;
    position: relative;
    height: 20px;
    text-align: left;
    margin-top: 13px;
}

.price_input .price_input_part:not(.sep):after {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 5px;
    line-height: 20px;
    font-size: 10px;
    color: $discreetgrey;
    content: attr(data-currency);
}

.price_input .price_input_part label {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 64px;
    height: 13px;
    font-size: 10px;
    color: $discreetgrey;
}

.price_input .price_input_part input {
    width: 54px;
    height: 18px;
    line-height: 18px;
    text-align: left;
    padding: 0 4px;
    background-color: $white;
    border: 1px solid $lightshadegrey;

    font-size: 10px;
    color: $grey2;
    text-indent: 0;
}

.price_input .price_input_validation {
    width: 20px;
    line-height: 18px;
    text-align: center;
    background-color: $greenyellow;
    border: 1px solid $lightshadegrey;

    font-size: 9px;
    color: $white;
    text-transform: uppercase;
    text-indent: 0;
    cursor: pointer;
}

.filter_wrapper .form_itm.check>input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.filter_wrapper .form_itm.check>input[type="checkbox"]+label {
    display: block;
    margin-bottom: 5px;

    font-size: 14px;
    color: $discreetgrey;
    cursor: pointer;
}

.filter_wrapper .form_itm.check>input[type="checkbox"]+span+label {
    display: inline-block;
    margin-bottom: 5px;

    font-size: 14px;
    color: $discreetgrey;
    cursor: pointer;
}

.filter_wrapper .form_itm.check>input[type="checkbox"]:hover+label,
.filter_wrapper .form_itm.check>input[type="checkbox"]:checked+label,
.filter_wrapper .form_itm.check>input[type="checkbox"]:hover+span+label,
.filter_wrapper .form_itm.check>input[type="checkbox"]:checked+span+label {
    color: $black;
}

.filter_wrapper .form_itm.check>input[type="checkbox"]+label:before,
.filter_wrapper .form_itm.check>input[type="checkbox"]+span {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-clip: content-box;
    border: 1px solid $white;
    margin-right: 10px;
    border-radius: 50%;
    content: '';
}

.filter_wrapper .form_itm.check>input[type="checkbox"][value="1"]+span {
    border-color: $gray70;
}

.filter_wrapper .form_itm.check:not(.color)>input[type="checkbox"]+label:before {
    border-color: $gray70;
}

.filter_wrapper .form_itm.check>input[type="checkbox"]:hover+label:before,
.filter_wrapper .form_itm.check>input[type="checkbox"]:checked+label:before,
.filter_wrapper .form_itm.check>input[type="checkbox"]:hover+span,
.filter_wrapper .form_itm.check>input[type="checkbox"]:checked+span {
    border-color: $black;
    border-radius: 50%;
}

.filter_wrapper .form_itm.check:not(.color)>input[type="checkbox"]:checked+label:before {
    background: url("../img/filter_check.gif") center center;
}

#filter_sticky {

    .form_itm.check,
    .filter_wrapper .form_itm.check>input[type=checkbox]+label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 5px;
    }

    .filter_wrapper .form_itm.check>input[type=checkbox]+span+label {
        margin-bottom: 0;
    }

    .filter_wrapper .form_itm.check>input[type="checkbox"]+label:before,
    .filter_wrapper .form_itm.check>input[type="checkbox"]+span {
        width: 10px;
        height: 10px;
    }
}

.algolia_footer,
.site_bottom{
    .evaluation {
        position: relative;
        display: flex;
        justify-content: center;

        .wrap_rating {
            display: inline-block;
            padding: 12px 16px;
            width: fit-content;
            margin: auto;
            margin-bottom: 10px;

            .read_reviews {
                margin-top: 4px;
                line-height: 1.17;
                text-align: center;
                color: rgba(17, 17, 17, 0.5);
                font-size: 10px;
                text-decoration: underline;
            }

            .customer_reviews {
                margin-top: 4px;
                text-align: center;
            }

            .reviews_content {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .google_logo {
            display: block;
            background: url("../img/google_logo.svg");
            background-repeat: no-repeat;
            width: 24px;
            height: 24px;
            margin: 0 auto;
        }

        .media_title {
            font-size: 12px;
            color: $darkgray1;
            text-transform: uppercase;
        }

        .rating_stars {
            display: inline-block;
            vertical-align: middle;
            width: 77px;
            height: 10px;
            background: url("../img/etoiles_evaluation.png");
            overflow: hidden;
        }

        .rating_stars.none {
            background-position: 0 100%;
        }

        .rating_stars.one {
            background-position: 0 80%;
        }

        .rating_stars.two {
            background-position: 0 60%;
        }

        .rating_stars.three {
            background-position: 0 40%;
        }

        .rating_stars.four {
            background-position: 0 20%;
        }

        .rating_stars.five {
            background-position: 0 0;
        }

        .evaluation_rating {
            display: inline-block;
            font-weight: 700;
            line-height: normal;
            font-size: 20px;
            margin-right: 13px;
            color: black;
            word-break: normal !important;
        }
        .rating_txt {
            font-weight: 700;
            font-size: 10px;
            color: $darkgray1;
        }
        .rating_txt span:not(.txt_nombre) {
            display: block;
        }
    }
}

@media screen and (max-width: 1280px) {
    .submenu .ss_menu_categ_wrapper {
        width: 80%;
    }

    .submenu .bloc_composez+.produit_gondole {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    .sticky {
        position: static;
    }

    .filter_hint {
        display: none;
    }

    .form_itm.check.color {
        margin-bottom: .4rem;
    }

    .filter_wrapper .form_itm.check>input[type=checkbox]+label:before,
    .filter_wrapper .form_itm.check>input[type=checkbox]+span {
        width: 24px;
        height: 24px;
    }

    .filter_wrapper .form_itm.check>input[type=checkbox]+span+label {
        vertical-align: .5rem;
    }

    .filter_wrapper .form_itm.check>input[type="checkbox"]+label {
        line-height: 24px;
        margin-bottom: 12px;
    }

    .filter_wrapper .form_itm.check>input[type="checkbox"]+label:before {
        vertical-align: middle;
        width: 24px;
        height: 24px;
    }

    .filter_wrapper .form_itm.check>input[type="checkbox"]+label:hover {
        color: $discreetgrey;
    }

    .filter_wrapper .form_itm.check>input[type="checkbox"]+label:hover:before {
        outline: none;
    }

    .filter_wrapper .form_itm.check>input[type="checkbox"]:checked+label {
        color: $black;
    }

    .filter_wrapper .form_itm.check>input[type="checkbox"]:checked+label:before {
        outline: 1px solid $grey3;
    }

    .footer_rassu_wrapper .rassurance .bloc:before {
        right: 110%;
    }
}

@media screen and (max-width: 810px) {
    #zoom_box_wrapper .main_vis .img_large {
        width: 712px !important;
    }
}

.product_recall img {
    width: 40%;
}

.footer_recall_produits {
    position: absolute;
    background: $white;
    left: 27%;
    padding: 50px 85px 21px;
    margin: 9.5% 0 0;
    top: 0 !important;
}

.product_recall {
    width: 100%;
    margin-bottom: 47px;
}

.footer_recall_produits .product_text {
    display: inline-block;
    vertical-align: top;
    padding-left: 4%;
}

/* MISC */
.modalbox {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $white;
    box-shadow: 0 0 20px $darkgray2;
    z-index: 600;
}

.modalbox.ng-modbox .modbox_contents.configurator_add {
    display: flex;
    padding: 0 30px 30px;
    box-sizing: border-box;
}

.ng-modbox .configurator_add .visual_holder {
    position: relative;
    width: 50%;
    overflow: hidden;
}

.ng-modbox .configurator_add .visual_holder>img {
    vertical-align: top;
    width: 100%;
    height: auto;
}

.ng-modbox .configurator_add .visual_holder>img:not(.background) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ng-modbox .configurator_add .items_holder {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-left: 30px;
    box-sizing: border-box;
}

.ng-modbox .configurator_add .items_holder .configuration_item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.ng-modbox .configurator_add .items_holder .configuration_item.total {
    align-items: center;
    margin: auto 0 0;
}

.configuration_item .item_infos {
    width: 65%;

    color: $grey2;
    font-size: 15px;
    text-transform: lowercase;
}

.configuration_item.total .item_infos {
    text-align: right;
    font-size: 18px;
    text-transform: uppercase;
}

.configuration_item .item_infos .name {
    display: block;
    text-transform: capitalize;
}

.configuration_item .item_infos .feat {
    display: block;
    font-size: 0.85em;
}

.configuration_item .price_tag {
    width: 35%;
    text-align: right;
    font-size: 22px;
    color: $grey2;
}

.configuration_item.total .price_tag {
    font-size: 30px;
}

.configuration_item .price_tag sup {
    font-size: 0.5em;
}

.configuration_item .price_tag .cents {
    margin-left: -0.5em;
    font-size: 0.5em;
}

.modalbox.errbox {
    max-width: 480px;
}

.modalbox.errbox .modbox_contents {
    text-align: center;
    padding: 0 15%;
    margin-bottom: 30px;
}

.modalbox.ng-modbox {
    display: block;
}

.modalbox.ng-enter {
    opacity: 0;
    transition: opacity 0.3s linear 0.25s;
}

.modalbox.ng-enter-active {
    opacity: 1;
}

.modalbox.ng-leave {
    opacity: 1;
    transition: opacity 0.25s linear;
}

.modalbox.ng-leave-active {
    opacity: 0;
}

.modalbox.add_to_cart {
    width: 696px;
    height: 490px;
    transform: none;
    margin: -245px 0 0 -348px;
}

#popup_3xcb .close_pop,
.modalbox .close_pop {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    background: transparent url("../img/btn_close_popup.png");
    font-size: 0;
    cursor: pointer;
}

.modalbox .popup_title {
    position: static;
    width: 100%;
    height: 59px;
    line-height: 59px;
    text-align: center;
    text-indent: 0;
    border-bottom: 1px solid $gray91;
    margin-bottom: 30px;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.075em;
}

body.en .modalbox .popup_title {
    letter-spacing: 0;
}

.modalbox.look .popup_title {
    margin: 0;
}

.modalbox.look .modal_itm_visu>img {
    vertical-align: top;
    width: 100%;
    height: auto;
}

.modalbox .add_to_cart_name {
    line-height: 25px;
    margin-bottom: 10px;

    font-size: 23px;
    color: $grey3;
}

.modalbox .itm_infos {
    display: flex;
    padding: 0 35px;
}

.modalbox.look .itm_infos {
    flex-wrap: wrap;
    padding: 0;
}

.modalbox .itm_infos .modal_itm_visu {
    align-items: center;
    flex: 1;
}

.modalbox .itm_infos .modal_itm_visu>img {
    vertical-align: top;
    width: 100%;
    height: auto;
}

.modalbox .itm_infos .modal_itm_desc {
    flex: 1;
    padding-left: 20px;
}

.modalbox.look .itm_infos .modal_itm_desc {
    display: flex;
    align-items: center;
    flex: 0 1 auto;
    width: 50%;
    padding: 1.5% 0;
    border-bottom: 1px solid $gray92;
    box-sizing: border-box;
}

.modalbox.look .itm_infos .modal_itm_desc:nth-of-type(odd) {
    border-right: 1px solid $gray92;
}

.modalbox.look .itm_infos .modal_itm_desc .itm_visu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.modalbox.look .itm_infos .modal_itm_desc .itm_visu>img {
    vertical-align: top;
    width: auto;
    height: auto;
    max-width: 80%;
    max-height: 80%;
}

.modalbox.look .itm_infos .modal_itm_desc .itm_info {
    width: 70%;
}

.modalbox.look .itm_infos .modal_itm_desc .itm_info .add_to_cart_name {
    line-height: 15px;
    padding: 0 1em;
    margin: 0;

    font-size: 13px;
    color: $judgegray;
    text-transform: uppercase;
}

.modalbox.look .itm_infos .modal_itm_desc .itm_info .add_to_cart_name .add_to_cart_quantity {
    text-transform: none;
}

.modalbox.look .itm_infos .modal_itm_desc .itm_info .add_to_cart_price {
    line-height: 25px;
    padding-left: 15px;
    margin: 0;
    font-size: 20px;
}

.modalbox.look .look_total {
    text-align: right;
    padding: 5px 15px;

    font-size: 20px;
    color: $judgegray;
}

.modalbox.look .look_total .pricetag {
    font-size: 30px;
    color: $grey2;
}

.modalbox .modal_itm_desc .add_to_cart_feat {
    line-height: 18px;
    margin-bottom: 10px;
    font-size: 14px;
    color: $mediumdarkgrey;
    letter-spacing: 0.08em;
}

.modalbox .modal_itm_desc .add_to_cart_price {
    line-height: 53px;
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 40px;
    color: $grey2;

    sup,
    .cents {
        font-size: .45em;
        font-weight: 400;
    }

    .cents {
        margin-left: -.5em;
    }
}

.modalbox .modal_itm_desc .add_to_cart_qty {
    line-height: 16px;

    font-weight: 400;
    font-size: 13px;
    color: $eerieblack;
    text-transform: uppercase;
    letter-spacing: 0.03em;
}

.modalbox .form_submit {
    width: auto;
    text-align: center;
    background-color: $guyabano;
    padding: 35px;
}

.modalbox .form_submit .button {
    display: inline-block;
    width: 46%;
    height: 49px;
    line-height: 50px;
    text-align: center;
    padding: 0;
    font-weight: 700;
    font-size: 14px;
    color: $white;
    text-transform: uppercase;
    letter-spacing: .04rem;
}

.modalbox .form_submit .button.pop_cancel {
    background-color: $mediundarkgray1;
}

.modalbox .form_submit .button.pop_valid {
    background-color: $btnBgColor;
    margin-left: 12px;
}

#zoom_box_wrapper .main_vis .img_large {
    vertical-align: top;
    width: 47vw;
    height: 47vw;
    max-width: 1200px;
    max-height: 1200px;
}

#zoom_box_wrapper .more_vis {
    height: 98%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    background-color: rgba(255, 255, 255, 0.45);
    padding: 10px;
    box-sizing: border-box;
}

#zoom_box_wrapper .more_vis .content_img_visu {
    margin-bottom: 10px;
    width: 100% !important;
    height: auto;
}

.store_open {
    line-height: 12px;
    margin-left: 30px;
    color: $applegreen;
}

.store_close {
    line-height: 12px;
    margin-left: 30px;
    color: $mediumpink;
}

/**
 *  Ensures compatibility with older browsers
 */
/* HEADER */
.hesperide {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
}

@media screen and (max-width: 1679px) {
    .slider .prev_assocs_slider {
        right: calc(100% - 34px);
    }

    .slider .next_assocs_slider {
        left: calc(100% - 34px);
    }
}

/* VERSION SUISSE */
body.site_3 {
    header {
        background-color: $footerGrey;
        border-bottom: 1px solid $gainsboro2;
    }

    .head_top {
        height: 75px;
    }

    .sticky .head_top {
        background-color: $footerGrey;
    }

    .page_wrapper {
        padding-top: 80px;
    }

    .search_wrapper {
        max-width: 100%;

        .block_top_links {
            line-height: auto;
            height: 75px;
            align-items: center;
            padding: 0;

            .top_link {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                font-size: 13px;
                color: $nero;
                margin: 0;
                padding: 0 4%;
                position: relative;

                &:hover span {
                    text-decoration: none;
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 7px;
                    background-color: $gray82;
                }

                &:before {
                    width: 20px;
                    height: 20px;
                }

                &:last-child {

                    &:before,
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    .boutique.series {
        .serie_lnk {
            padding-bottom: 15%;
        }
    }

    .sticky .search_wrapper {
        margin-left: 0;
        height: 100%;

        .block_top_links {
            display: flex;
        }
    }
}

.is_stuck>* {
    -webkit-transform: translateZ(0);
}

@media only screen and (max-width: 770px) {
    .breadcrumbs_wrapper {
        left: -10px;
    }
}

body.super_boutik {
    main.page_wrapper {
        overflow: hidden;
        position: relative;
    }

    .boutique.series {
        padding-top: 0.35rem;
        margin-bottom: 4.55rem !important;

        .series_col {
            width: 48%;

            &.left {
                margin-right: 4.5%;
            }

            .serie_lnk {
                width: 100%;
                padding-bottom: 8%;
            }
        }

        .txt_super_home {
            float: none;
            background-color: $white1;
            padding: 46px 38px;
            width: auto;
            margin-bottom: 28px;

            p {
                width: 100%;
                max-width: 98%;
                font-size: .75rem;
                line-height: 21px;

            }

            h1 {
                line-height: 50px;
                margin: 0 0 1.5rem;
                font-size: 30px;


                &::before {
                    content: '';
                }
            }
        }

        .serie_lnk {
            width: 100%;
            display: block;
            clear: both;
            float: left;
            padding-bottom: 6.8%;
        }

        .menu_right {
            position: absolute;
            top: 0;
            right: -23px;
            padding-top: 94px;
            border-right: 7px solid $grey3;
            height: 100%;
            max-height: 65vh;
            overflow-y: scroll;
            overflow-x: hidden;
            z-index: 2;
        }
    }

    .backToTop {
        width: 8rem;
        height: 6.25rem;
        background: $white;
        z-index: 99;
        right: 0.5rem !important;

        &:after {
            left: 41.5% !important;
        }
    }

    .breadcrumbs_wrapper {
        position: relative;
    }
}

#wrapper_top_menu {
    .menu_img img {
        width: auto;
        max-height: 460px;
        height: 100%;
    }

    #menu_1 {
        .menu_img {
            a {
                width: 500px;
                height: 186px;
                overflow: hidden;
                position: relative;
                display: block;
            }

            img {
                height: auto;
                width: 100%;
                max-width: 100%;
                top: 50%;
                left: 50%;
                position: relative;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.xmas_delivery {
    margin: 1rem 0;
    display: flex;
    align-items: flex-start;
    column-gap: .5rem;
    font-size: 0.84rem;
    color: $xmasgreen;

    &::before {
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        background-image: url('../svg/xmas_delivery.svg');
        margin-top: -1px;
    }
}

.product_page .xmas_delivery {
    margin: -1rem 0 2rem;
}
