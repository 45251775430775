.footer_sitemap #site_global_wrap .big_title_charte {
    margin-top: 30px;
}

body.footer_sitemap {
    // For ADV pages
    #middle_box_site_map_satellite_content {
        .satellite_content {
            column-count: 2;

            .sitemap_section_wrapper {
                break-inside: avoid;
                padding-top: 30px;

                .big_title_charte {
                    margin-top: 0;
                }

                .sitemap_section {
                    width: 100%;

                    .new_sitemap_border {
                        margin-bottom: 0;

                        .new_sitemap_border {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

.valid_form,
.img_error {
    position: absolute;
    z-index: 5;
    margin: 0;
    right: -2rem;
    top: 50%;
    transform: translateY(-50%);
}

#lostpwdTopSucces2 .btn_noir {
    border: 0;
    background: #636363;
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 3px 21px 4px 21px;
    margin: 0 5px 0 25px !important
}

#lostpwdTopSucces2 .btn_violet {
    border: 0;
    background: #171717;
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 3px 21px 4px 21px;
}

.titre_recevoir_mot_de_passe {
    display: block;
    margin: 14px 0 0 25px
}

.lightbox_mdp_oublie {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    background: #636363;
    border: 0
}

.btn_violet.detail_moncompte {
    padding: 3px 12px 4px;
    margin: 0 auto
}

.btn_gris {
    background: url(../img/bg_gris.jpg) repeat-x left top;
    border: 1px solid #777
}

.btn_noir.inscription_mdp_oublier {
    background: #000;
    color: #FFF;
    font-weight: 400;
    height: 16px;
    line-height: 16px
}

#connectBt {
    height: 23px
}

.btn_fermer {
    position: absolute;
    right: 0;
    top: 3%;
    color: #313131;
    display: block;
    height: 19px;
    padding-top: 10px;
    text-align: center;
    text-decoration: none;
    width: 56px;
    cursor: pointer;
    background: url("../img/btn_close_popup.png") no-repeat 50% 50%;
}

#lostpwdTopSuccesTitle td {
    color: #000;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.05em
}

#contactMessage {
    width: 403px;
    height: 145px;
    overflow-y: hidden;
}

table.tooltip {
    color: #FFFFFF;
    font-family: arial;
    font-size: 12px;
    position: absolute;
    text-align: left;
    width: 300px
}

table.tooltip td.content_tooltip {
    background: url("../img/bg_content_tooltip.png") repeat-x scroll left bottom #FFFFFF;
    border: 1px solid #D9D9D9;
    color: $textlightGrey;
    min-width: 100px
}

table.tooltip tr td.content_tooltip div#erreur_msg {
    padding: 10px 15px;
    font-family: arial;
    font-size: 12px;
    color: #444;
    text-transform: none;
    text-indent: 0 !important;
}

table.tooltip tr td.content_tooltip div#erreur_msg p.title_toolltip {
    color: #c4d600;
    font-weight: bold
}

#cart_total #bloc_info_offert tr {
    height: auto;
}

.error_text {
    display: inline-block;
    position: relative;
    text-indent: 0;
}

.visu_gamme_assoc {
    margin: 30px 1px 0 0;
    border: 1px solid #ccc
}

#shad,
#shad2,
#shad_popup_confirm_inscription,
#shad_popup_confirm_reinitialisation_mdp,
#shad_zoom {
    background-color: #000000;
    display: none;
    height: 100%;
    left: 0;
    opacity: 0.4;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 500;
}

.popup_title {
    font-size: 18px;
    height: 59px;
    line-height: 59px;
    text-indent: 35px;
    width: 100%;
    border-bottom: 1px solid #e8e8e8;
    text-transform: uppercase;
    position: relative;
    font-weight: 600
}

#popup_confirm_reinitialisation_mdp .popup_title {
    font-size: 14px
}

.popup_title span {
    color: #A8BC21
}

#wrapper_content_popup_ajout_panier {
    padding: 30px 35px
}

#visu_popup_ajout_panier {
    width: 291px;
    height: 194px;
    overflow: hidden
}

#btns_popup_ajout_panier .btn_noir,
#btns_popup_ajout_panier .btn_violet,
.bloc_adresse_livraison .btn_violet,
.bloc_adresse_livraison .btn_noir {
    line-height: 20px;
    height: 17px
}

#infos_popup_ajout_panier {
    margin: 0 0 0 20px;
    line-height: 16px;
    width: 314px
}

.title_product_ajout_panier {
    font-size: 14px
}

.subtitle_product_ajout_panier {
    padding: 0 0 10px 0;
    font-size: 11px;
    font-weight: 700;
    color: #404040;
    letter-spacing: 0.08em;
    text-transform: uppercase
}

#btns_popup_ajout_panier {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#btns_popup_ajout_panier p#votre_panier_contient {
    padding: 12px 0 15px 0
}

#sendfriendbox.look #content_sendfriendbox #sendfriend_prodinfos {
    max-height: 380px;
    position: relative;
    margin-right: 20px;

    .achat_look {
        position: absolute;
        bottom: 0;
        right: 35px;
        background: $white;
    }
}

#sendfriendbox.look {
    padding-bottom: 0
}

#sendfriendbox.look .visuel {
    width: 100%;
    position: relative;
    display: block;
    clear: both;
    float: none
}

#sendfriendbox.look .visuel img {
    width: 100%
}

#sendfriendbox.look .look_item {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 10px 1%;
    box-sizing: border-box;
}

#sendfriendbox.look .product_ctn_right {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 700px;
    overflow-y: auto;
}

#sendfriendbox.look .img_product_look {
    width: 30%;
    position: relative;
}

#sendfriendbox.look .img_product_look img {
    width: 100%
}

#sendfriendbox.look .info_product_container {
    width: 69%;
    margin: 0 0 0 1%;

    .top_info_product {
        margin: .2rem 0 1rem;
    }

    .qty_selected {
        margin-top: 0.5rem;
        line-height: 16px;
        font-weight: 400;
        font-size: 13px;
        color: #1f1f1f;
        text-transform: uppercase;
        letter-spacing: .03em;
    }

    .ref_selected {
        font-size: 13px;
        margin-top: .5rem;
    }
}

#sendfriendbox.look .prod_title {
    font-size: 20px;
    font-weight: 400;
}

#sendfriendbox.look .prod_stitle {
    font-size: 12px;
}

#sendfriendbox.look .price {
    font-size: 20px
}

#sendfriendbox.look .price sup {
    font-size: 10px;
    vertical-align: 8px
}

#sendfriendbox.look .price span {
    font-size: 10px;
    margin: 0 0 0 -4px
}

#sendfriendbox.look .price_look {
    margin: 10px 10px 0 0;
    font-size: 20px
}

#sendfriendbox.look .price_look .price {
    display: inline-block;
    font-size: 30px
}

#sendfriendbox.look .price_look .price sup {
    font-size: 14px;
    vertical-align: 12px
}

#sendfriendbox.look .price_look .price span {
    font-size: 14px;
    margin: 0 0 0 -6px
}

#sendfriendbox #wrapper_sendfriend_photo {
    width: 291px;
    margin-right: 20px;
    float: left
}

#sendfriendbox p#product_title {
    line-height: 25px;
    margin: 0 0 15px;
    font-size: 28px;
    text-transform: uppercase
}

#sendfriendbox p#product_name {
    line-height: 18px;
    margin: 0;

    font-size: 14px;
    color: #404040;
    text-transform: uppercase
}

#sendfriendbox #sendfriend_form_to {
    margin: 0;
    border-top: 3px solid #F8F8F8;
    padding: 20px 75px 0;
}

#sendfriendbox #sendfriend_form_froms {
    padding: 20px 75px 0;
    width: 100%;
    margin: 1rem 0 0 0;
    box-sizing: border-box;
}

.formContent {
    input {
        box-sizing: border-box;
    }

    .form_title {
        margin-bottom: .2rem;
    }
}

#sendfriend_form_buttons {
    justify-content: center;
    margin: 0 auto;
    float: none;
}

#sendfriendbox #product_sendfriend_nok {
    font-size: 16px;
    color: #ccc;
    font-weight: bold
}

#wrapper_vgt {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 12px;
    color: #000;
    z-index: 2
}

#wrapper_vgt img {
    margin: 0 10px 0 0;
    border: 1px solid #ccc
}

.backtotop {
    background: url("../img/ui.totop.jpg") no-repeat scroll left bottom rgba(0, 0, 0, 0);
    border: medium none;
    bottom: 0;
    float: right;
    height: 61px;
    overflow: hidden;
    right: 50%;
    text-decoration: none;
    text-indent: 100%;
    width: 61px;
    z-index: 11;
    filter: alpha(opacity=60);
    opacity: 0.6;
    cursor: pointer
}

body.home .backtotop {
    display: none !important
}

#content_wrapper .banner_home {
    text-align: center;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0
}

#content_wrapper .banner_home.back_img {
    height: 684px;
    overflow: hidden;
    position: relative;
    width: 100%
}

#content_wrapper .banner_home.back_img .img_slide {
    margin-top: -60px;
    position: absolute;
    left: 50%;
    display: none
}

#content_wrapper .banner_home.back_img .img_slide.active {
    display: block
}

#content_wrapper .active_link {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0
}

#content_wrapper .banner_home.product .wrapper_box {
    position: relative;
    width: 100%
}

#content_wrapper .banner_home.product .box_text {
    position: absolute;
    left: 50%;
    margin-left: -480px;
    top: 0
}

.box_txt_03 {
    top: 260px !important
}

#content_wrapper .next,
#content_wrapper .prev {
    z-index: 2;
    top: 50%;
    cursor: pointer;
    margin-top: -20px
}

#content_wrapper .next.main_slider:hover {
    background: url('../img/btn_next_home_hover.png') no-repeat
}

#content_wrapper .prev.main_slider:hover {
    background: url('../img/btn_prev_home_hover.png') no-repeat
}

#content_wrapper .banner_home.back_img .elemPage {
    display: inline
}

#content_wrapper .banner_home.back_img .elem_img {
    width: 998px;
    position: relative;
    height: 525px
}

#content_wrapper .banner_home.back_img .elem_img .main_img {
    position: relative;
    z-index: 1
}

#content_wrapper .banner_home.back_img .elem_img .box_text {
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -500px;
    z-index: 2
}

#content_wrapper .banner_home.back_img .elem_img .wrapper_promo {
    position: absolute;
    left: 50%;
    top: 0;
    width: 960px;
    margin-left: -481px;
    z-index: 15
}

#content_wrapper .banner_home.back_img .elem_img .wrapper_promo .code_promo {
    position: absolute;
    right: 250px;
    top: 153px;
    font-size: 25px;
    font-family: arial;
    font-weight: bold;
    color: #29205e
}

#content_wrapper .banner_home.back_img .elemMini {
    overflow: hidden;
    width: 100px;
    height: 80px;
    float: left
}

#content_wrapper .banner_home.back_img #page,
#content_wrapper .banner_home.back_img #miniImg {
    position: absolute;
    left: 50%;
    float: left
}

#content_wrapper .banner_home.back_img #page {
    top: 0;
    width: 100px;
    height: 20px;
    background: #000;
    z-index: 99999
}

#content_wrapper .banner_home.back_img #miniImg {
    bottom: 0
}

.product_banner_caption a:hover {
    text-decoration: underline
}

.product_banner_caption a {
    color: #fff;
    font-family: arial;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none
}

#content_wrapper .bandeau_fpd_home,
#content_wrapper .bandeau_eclat_home {
    position: absolute;
    top: -3px;
    right: -3px;
    color: #fff;
    font-family: arial;
    font-weight: bold;
    font-size: 10px;
    text-transform: uppercase;
    line-height: 15px;
    z-index: 10
}

#content_wrapper #slider_coup_de_coeur .bandeau_eclat_home {
    top: 32px;
    width: auto;
    height: auto
}

#content_wrapper #slider_coup_de_coeur .bandeau_eclat_home>img {
    width: 80px
}

#content_wrapper .bandeau_eclat_home {
    top: 2px;
    right: 2px
}

#content_wrapper .bandeau_eclat_home img {
    width: 70px;
    height: auto
}

#content_wrapper .bandeau_fpd_home img {
    width: 66px;
    height: auto
}

#title_autres_articles {
    height: 39px;
    line-height: 39px;
    background: #000005;
    color: #c4d600;
    font-size: 17px;
    text-indent: 42px;
    font-weight: normal
}

#title_autres_articles span {
    color: #c8c8c8;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: lighter
}

#main_slider {
    width: 970px;
    height: 462px
}

#slider_home_2 {
    clear: both;
    margin: 2px 0 20px 0;
    overflow: hidden;
    position: relative;
    height: 370px
}

.slider-container {
    position: absolute;
    left: 20px;
    bottom: 0;
    margin: 0;
    padding: 0
}

.ui-slider-1 {
    background: url(../img/fond_scroll_home.gif) scroll repeat-x;
    position: relative;
    left: 0;
    margin: 0;
    height: 16px;
    cursor: pointer;
    z-index: 1
}

.portfolio-scroll-left {
    cursor: pointer;
    position: absolute;
    bottom: 2px
}

.portfolio-scroll-right {
    cursor: pointer;
    position: absolute;
    bottom: 2px;
    right: 0
}

#main_slider2 {
    list-style-type: none;
    position: absolute;
    width: 20000px
}

.slider_product_wrapper {
    width: 242px;
    height: 340px;
    position: relative
}

.slider_product_wrapper:hover {
    cursor: pointer
}

#content_obj .slider_product_wrapper {
    width: 249px;
    height: 345px;
    margin: 0 0 14px 3px
}

#content_obj .slider_product_wrapper:hover {
    cursor: pointer
}

.couleur_produit img {
    margin: 0 4px 0 0;
    width: 10px;
    height: 10px
}

.violet {
    color: #757575;
}

.conteneur_prix .prix_barre {
    color: $textlightGrey;
    font-size: 18px;
    margin-top: 12px;
    text-decoration: line-through;
}

#bloc_wishlist .conteneur_prix .prix_barre {
    font-size: 21px;
    text-decoration: line-through;
}

.wl_public .conteneur_prix .prix_barre {
    font-size: 21px;
    font-weight: 600;
    font-size: 23px;
    color: #8a8279;
    text-decoration: none;
    position: relative;

    &::after {
        position: absolute;
        top: 55%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        height: 2px;
        background-color: grey;
        content: '';
    }
}

.visu_product_slider {
    width: 240px;
    height: 240px
}

.visu_product_slider_hover {
    position: absolute;
    width: 236px;
    height: 236px;
    border: 3px solid #D4E94B;
    margin: -247px 0 0 0;
    *margin: 0 0 0 -242px;
    display: none
}

#content_obj .visu_product_slider_hover {
    position: absolute;
    width: 243px;
    height: 243px;
    border: 3px solid #D4E94B;
    margin: -254px 0 0 0;
    *margin: 0 0 0 -253px;
    display: none
}

#wrapper_resultat #loading {
    font-size: 20px;
    font-family: arial;
    color: $textlightGrey;
    line-height: 15px;
    text-align: center
}

.eclat_fdp_offerts {
    position: absolute;
    right: -7px;
    top: -5px;
    z-index: 11
}

.wrapper_gamme .eclat_fdp_offerts {
    position: absolute;
    right: -9px;
    top: -8px
}

.visu_product_slider img {
    border: 1px solid #ccc
}

.visu_product_slider .eclat_fdp_offerts img,
.visu_product_slider .eclat_vu_tv img {
    border: 0px none
}

.prix_product_slider {
    width: 242px;
    height: 57px;
    background: transparent url(../img/fond_prix_slider_home.png) no-repeat;
    position: absolute;
    bottom: 52px;
    line-height: 15px
}

.prix_product_slider {
    width: 249px;
    height: 57px;
    background: transparent url(../img/fond_prix_rayon.png) no-repeat;
    position: absolute;
    bottom: 52px;
    line-height: 15px
}

.prix_product_slider .prix_product {
    font-size: 19px;
    color: #000;
    font-weight: bold;
    text-align: center;
    padding: 22px 0 0 0;
    width: 100%;
    height: auto
}

.prix_product_unique {
    font-size: 19px;
    color: #000;
    font-weight: bold;
    text-align: center;
    padding: 30px 0 0 0
}

.prix_product_unique span {
    font-size: 15px
}

.prix_barre_slider {
    font-family: arial, sans-serif;
    font-size: 11px;
    color: #000;
    text-align: center
}

.eclat_promo_slider {
    position: absolute;
    bottom: 1px;
    *bottom: 2px;
    right: 2px
}

.infos_product_slider {
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0px;
    background: #444
}

.infos_product_slider .title_product {
    font-family: arial, sans-serif;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
    text-indent: 10px;
    padding: 10px 0 0 0;
    height: 17px;
    overflow: hidden
}

.infos_product_slider .infos_product {
    font-family: arial, sans-serif;
    font-size: 11px;
    color: #fff;
    text-indent: 10px
}

ul.main_slider2_content li {
    list-style-type: none;
    display: inline
}

.navigation_rayon {
    clear: both;
    overflow: hidden;
    position: relative;
    width: 740px
}

.right_navigation {
    margin-bottom: 5px;
    vertical-align: top
}

.right_navigation a {
    color: #6C6762;
    font-size: 11px;
    height: 20px;
    margin: 0 1px;
    padding: 2px 2px 2px 3px;
    text-decoration: none
}

.right_navigation a img {
    margin: 0;
    padding: 0;
    margin: 0 0 0 -5px
}

.right_navigation a.pagination_select {
    background-color: #ACC117;
    color: #FFFFFF;
    font-size: 11px;
    border-top: 1px solid #819217;
    border-right: 1px solid #a9bb25;
    border-left: 1px solid #a9bb25
}

span.nb_prod_numeric {
    font-size: 11px;
    font-family: arial;
    font-weight: bold;
    color: #808080
}

.middle_navigation {
    margin-left: 145px;
    color: #808080;
    font-family: arial;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase
}

span#nb_prod_numeric .btn_page,
span#nb_prod_numeric .btn_page_off {
    margin: 0 5px;
    color: #808080;
    float: left
}

span#nb_prod_numeric .btn_page_off:hover {
    text-decoration: underline
}

span#nb_prod_numeric .btn_page {
    color: #ACC117
}

span#nb_prod_numeric .btn_page_off {
    cursor: pointer
}

#autres_vues img {
    display: block
}

.content_marque_visu {
    width: 100%;
    height: 100%;
    background: #fff
}

.btn_video_produit {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    right: 3px;
    bottom: 0
}

span#selectqteProd,
span#selectcouleurProd,
span#selecttailleProd {
    width: 140px
}

#identification .title {
    font-size: 20px;
    color: #c4d600
}

#identification .btn_violet {
    height: 22px
}

span.red {
    color: #c4d600
}

#middle_box_content #mes_err_contact .red {
    color: #c00;
    font-weight: bold;
}

#is_not_client_form .txt_civilite {
    line-height: 22px
}

td.dashed {
    border-bottom: 1px solid #c4d600
}

#clientNewsletterLabel {
    margin: 14px 0 0 0
}

#clientNewsletterLabel a {
    font-family: arial;
    font-size: 11px;
    color: #333;
    text-decoration: underline
}

#clientNewsletterLabel span.infos_newsletter {
    font-weight: 700;
    color: #7e7e7e;
    text-transform: none;
    font-size: 11px;
}

.boutons_panier .btn_violet,
.boutons_panier .btn_noir {
    height: 22px;
    line-height: 24px;
    font-size: 600;
}

.inscriptions_txt_infos {
    font-weight: 700;
    font-size: 11px;
    color: #8e8e8e;
    text-transform: none;
    letter-spacing: 0.03em;
}

.inscriptions_txt_infos span {
    color: #c4d600;
}

#newsletterForm>table {
    width: 100%;
}

#inscription_news .labelFormSite {
    text-transform: uppercase
}

#submit_newsletter .btn_violet,
.btn_valider_questionnaire[name="addProfil"] {
    background: #c4d600;
    color: #fff;
    font-weight: normal;
    border: 0;
    line-height: 1;
    padding: 11.5px 0px;
    height: 3em;
    width: 100%;
}

.btn_valider_questionnaire[name="addProfil"] {
    display: inline-block;
    width: auto;
    text-indent: 0;
    font-size: 13px;
    padding: 0 3.5em
}

.news_select .selectStyled {
    margin-right: 10px
}

.btn_violet.news_confirmation_btn {
    background: #bbd03a;
    color: #fff !important;
    font-weight: normal;
    border: 0;
    height: 36px !important;
    line-height: 35px;
    width: 170px !important;

    font-size: 14px;
}

#txt_inscription_conf>span {
    color: #bbd03a;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 21px;
    margin-top: 200px;
    display: block;
}

#txt_inscription_conf {
    color: #bbd03a;
    font-weight: normal;
    letter-spacing: 0.06em;
    font-size: 14px
}

#newsletterForm input {
    border: 1px solid #ecece8;
    color: #414243;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    height: 47px;
    line-height: 27px;
    margin-bottom: 0;
    text-indent: 4px;
}

#newsletterMailLabel.labelFormSite {
    padding: 0 !important
}

#newsletterForm #newsletterMailLabel {
    display: block;
    font-size: 12px;
    text-transform: uppercase
}

#newsletterMailLabel {
    margin: 14px 0 0 0
}

#newsletterForm label {
    text-transform: uppercase
}

#inscription_news p {
    line-height: 20px;
    font-size: 13px;
    font-weight: normal
}

.news_imgerror_fix {
    position: relative
}

.btn_violet.btn_desinscript_news {
    background: #bbd03a;
    color: #fff !important;
    font-weight: 700;
    border: 0;
    line-height: 35px;
    margin-bottom: 10px !important;
    height: auto;
    padding: 4px 20px;
    border-top: 0;
    width: auto;

    font-size: 14px;
    text-indent: 0;
}

#confirmation_inscription p {
    color: #343434;
    font-weight: normal;
    letter-spacing: 0.06em;
    font-size: 14px
}

.btn_violet.btn_desinscript_acces {
    width: 170px !important;
    background: #bbd03a;
    color: #fff !important;
    font-weight: normal;
    border: 0;
    height: 36px !important;
    line-height: 35px;

    font-size: 14px;
}

#sendfriendbox,
#errbox {
    display: none
}

.bandeau_fpd {
    position: absolute;
    top: -13px;
    right: -13px
}

.prix_site_gamme_assoc_barre {
    position: relative;
    background: url(../img/ligne_barre.gif) repeat-x center center;
    float: right
}

.stock_delivery_statu .product_statu img {
    margin: 4px 13px -2px -2px
}

.stock_delivery_statu strong {
    text-transform: uppercase
}

#wrapper_prod_tarif .prix_ttc {
    width: 81px;
    text-align: right;
    font-size: 10px;
    color: #A5A5A5;
    margin-bottom: 15px
}

.webkit #wrapper_eco {
    padding: 2px 0;
    background: url(../img/back_eco.png) repeat-y top left
}

.eco_realise,
.eco_realise_fdp {
    font-size: 11px;
    width: 100%;
    height: 20px;
    color: #999;
    font-family: tahoma, serif
}

.eco_realise strong,
.eco_realise_fdp strong {
    font-size: 11px
}

.eco_realise_fdp {
    color: #bdcf2d
}

#wrapper_prod_tarif .social_network {
    text-align: center;
    width: 197px;
    margin-top: 19px
}

#wrapper_prod_tarif .social_network div {
    cursor: pointer;
    display: inline-block
}

#wrapper_prod_tarif .social_network .link_fb {
    margin: 0 5px 0px 0
}

#wrapper_prod_tarif .ombre_bottom {
    position: absolute;
    bottom: -25px;
    right: -45px;
    width: 449px;
    height: 47px;
    background: url(../img/ombre_bottom_prod_tarif.png) no-repeat top center;
    z-index: -1
}

#popup_3xcb {
    display: none;
    width: 520px;
    height: 684px;
    position: fixed;
    top: 37%;
    left: 50%;
    margin: -240px 0 0 -260px;
    background: white;
    font-size: 13px;
    color: #53514F;
    text-transform: none;
    z-index: 5000;
    -moz-box-shadow: 0px 0px 20px #111;
    -webkit-box-shadow: 0px 0px 20px #111;
    box-shadow: 0px 0px 20px #111;
    font-weight: 300
}

.pt #popup_3xcb {
    display: none;
    width: 520px;
    height: 440px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -220px 0 0 -260px;
    background: white;
    font-size: 13px;
    color: #53514F;
    z-index: 5000;
    -moz-box-shadow: 0px 0px 20px #111;
    -webkit-box-shadow: 0px 0px 20px #111;
    box-shadow: 0px 0px 20px #111;
    font-weight: 300
}

#wrapper_content_popup_3xcb {
    overflow-y: auto;
    max-height: 623px;
}

#wrapper_content_popup_3xcb ul {
    padding-left: 15px;
    width: 300px
}

#wrapper_content_popup_3xcb p {
    font-size: 13px;
    font-weight: 300;
    margin: 10px 20px
}

#wrapper_content_popup_3xcb p.small {
    font-size: 12px;
    font-style: italic;
    color: #4e4e4e;
    margin: 20px
}

#wrapper_content_popup_3xcb div.f_left p {
    margin: 0;
    padding: 30px 0 12px
}

#wrapper_garanti_delivery .container_g_l {
    padding-top: 8px;
    float: left;
    position: relative
}

#wrapper_garanti_delivery .container_g_l:nth-child(1) {
    width: 96px
}

#wrapper_garanti_delivery .container_g_l:nth-child(2) {
    width: 99px;
    padding-top: 8px
}

#wrapper_garanti_delivery .container_g_l:nth-child(3) {
    width: 80px
}

#wrapper_garanti_delivery .container_g_l .garantie {
    position: absolute;
    left: 5px;
    top: 0
}

#wrapper_garanti_delivery .big,
#wrapper_garanti_delivery .little {
    display: block;
}

#wrapper_garanti_delivery .big {
    font-size: 11px;
    color: #fff
}

#wrapper_garanti_delivery .little {
    font-size: 8px;
    font-weight: bold
}

.green {
    font-size: 13px;
    color: #C9E123;
    font-weight: bold
}

#wrapper_prod_infos .green {
    font-size: 13px;
    color: #C9E123;
    font-weight: bold
}

span.prix_marche_constat {
    font-size: 11px;
    color: #737373;
    font-weight: normal
}

#liste_qte select.inputForm {
    width: 197px;
    margin: auto;
    padding-left: 1px
}

.btn_violet_big {
    background: #733171 url(../img/back_add_basket.jpg) repeat-x left top;
    border: 1px solid #6b2e69;
    color: #fff;
    padding: 6px 0;
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;
    border-top: 1px solid #161616;
    border-bottom: 1px solid #676568
}

#product_stock {
    font-size: 11px;
    color: #737373
}

#frais_ports_offerts {
    margin: 15px 0 0 10px
}

#verifier_dimensions_portes {
    overflow: hidden;
    padding: 0 45px 0 0;
    color: #737373;
    font-size: 11px;
    line-height: 20px
}

#bloc_fdp_garantie {
    padding: 10px 0;
    overflow: hidden;
    background: transparent url(../img/separation_horiz.png) repeat-x;
    clear: both
}

#bloc_fdp_garantie #fdp_offerts {
    padding: 0 0 0 30px
}

#bloc_fdp_garantie #garantie {
    padding: 0 0 0 50px
}

#bloc_fdp_garantie #livre {
    padding: 0 0 0 30px
}

#bloc_garantie {
    padding: 10px 0;
    overflow: hidden;
    background: transparent url(../img/separation_horiz.png) repeat-x;
    clear: both
}

#bloc_garantie #garantie {
    padding: 0 0 0 100px
}

#bloc_garantie #livre {
    padding: 0 0 0 100px
}

.bloc_ami {
    width: 435px;
    height: 39px;
    position: relative;
    margin: 4px 0 0 10px;
    border: 1px solid #ccc;
    background: transparent url(../img/separation_share_product.gif) no-repeat center top
}

.bloc_ami a {
    font-size: 12px;
    color: #000;
    text-decoration: none
}

.bloc_ami #send_friend {
    width: 217px;
    text-align: center;
    padding: 10px 0 0 0
}

#ongletCaracteristiques {
    width: 437px;
    position: relative;
    margin: 4px 0 0 10px
}

#ongletCaracteristiques .btn_tabs_align {
    width: 145px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #ccc;
    border-bottom: 0px none;
    font-size: 14px;
    color: #53514F;
    font-style: italic
}

#ongletCaracteristiques .onglet_texte {
    border: 1px solid #ccc;
    font-size: 11px
}

#ongletSavoirPlus {
    width: 510px;
    overflow: auto;
    position: absolute;
    bottom: 0;
    background: transparent url(../img/separation_horiz.png) repeat-x
}

#ongletSavoirPlus .container_tab {
    border: 0px none
}

#ongletAssociations {
    padding: 20px 0 10px 0
}

.photo_produit_gamme {
    padding: 30px 0 0 0
}

.photo_produit_gamme img {
    border: 1px solid #ccc
}

.container_tab {
    border: none;
    font-size: 12px;
    color: #53514F;
    overflow: hidden
}

.container_tab .txt_tab {
    color: #979695;
    letter-spacing: 0.04em;
    font-size: 11px
}

.container_tab .txt_tab strong {
    color: #282828
}

#global_carac {
    padding-bottom: 20px;
    border-bottom: 1px dotted #bdd924
}

.wrapper_carac {
    float: left;
    width: 270px
}

#structure_carac {
    margin-right: 0
}

.wrapper_carac .title_carac {
    padding: 10px 0 0 8px;
    text-transform: uppercase;
    font-family: tahoma, serif;
    color: #090909;
    font-weight: bold;
    font-size: 12px
}

.wrapper_carac .content_carac {
    font-family: arial, serif;
    font-size: 11px;
    padding: 10px 0
}

.wrapper_carac .content_carac .label_caract {
    width: 90px;
    color: #333;
    font-weight: bold
}

#structure_carac .content_carac .label_caract {
    width: 100px;
    color: #333
}

.wrapper_carac .content_carac .value_caract {
    color: #53514f
}

.wrapper_carac .content_carac .line_caract td {
    padding: 5px 8px
}

.info_dim {
    font-size: 9px;
    font-style: italic;
    margin-left: 10px;
    margin-top: 5px
}

#reference_fiche_produit {
    float: right;
    font-size: 11px;
    text-transform: uppercase;
    font-family: arial, serif;
    color: #999;
    margin-top: -7px;
    padding-right: 45px
}

.container_tab .separateur_gamme {
    clear: both;
    padding: 0;
    border-bottom: 1px solid #ccc;
    height: 10px;
    margin: 0 auto 12px auto;
    width: 959px
}

.cofinoga_3x,
.un_euro {
    padding: 16px 0 0 0
}

.un_euro {
    padding: 12px 0 0 0
}

.title_associations {
    font-family: arial;
    font-size: 11.5px;
    color: #fff;
    width: 960px;
    background: #000005;
    line-height: 27px;
    text-indent: 5px;
    margin: 0 auto 10px auto;
    border-top: 2px solid #bdd923;
    text-indent: 20px;
    text-transform: uppercase
}

.eclat_nb {
    position: absolute;
    right: 20px;
    top: 147px
}

.prix_site_gamme_assoc_barre,
.product_sale_price_barre {
    margin-left: 12px;
    font-size: 20px;
    color: #777777;
    line-height: 33px;
    float: left
}

.product_sale_price_barre {
    line-height: 58px;
    margin-left: 10px;
    vertical-align: bottom
}

.prix_site_gamme_assoc .euro {
    position: absolute;
    margin: 10px 0 0 4px;
    font-family: arial;
    font-size: 20px
}

.prix_site_gamme_assoc .euro_centimes {
    position: absolute;
    font-family: arial;
    font-size: 16px;
    margin: 10px 0 0 -6px
}

.prix_site_gamme_assoc .centimes {
    font-size: 20px;
    margin: 0 0 0 -6px
}

.prix_barre {
    font-size: 11px;
    color: #737373;
    font-weight: 300
}

#eclat_product_reduc {
    position: absolute;
    top: 130px
}

.thanks_trust,
.thanks_trust_ord,
.thanks_trust_thanks {
    font-size: 15px;
    font-weight: bold;
    display: block;
    color: #c4d600;
    margin: 10px 0 10px 20px
}

#bloc_erreur_order .thanks_trust {
    font-weight: normal !important;
    margin: 10px 0;
    text-align: center
}

.thanks_trust_ord {
    margin-left: 45px
}

.thanks_trust_thanks {
    margin-top: 25px;
    margin-left: 25px
}

.lien_banque {
    text-decoration: none;
    color: #000
}

h1.title {
    font-size: 20px;
    color: #c4d600;
    padding-bottom: 20px
}

#wrapper_moncompte_main #menu_compte {
    width: 220px;
    background: #f9f9f9;
    font-size: 13px;
    color: #333;
    padding-bottom: 5px
}

#wrapper_moncompte_main #menu_compte div {
    padding: 2px 0 15px 0
}

#wrapper_moncompte_main #menu_compte span {
    display: block;
    height: 22px;
    line-height: 22px;
    text-indent: 10px;
    font-weight: bold;
    background: #eee;
    margin: 0 0 15px 0;
    border-bottom: 1px solid #ccc
}

#wrapper_moncompte_main #menu_compte ul {
    margin: 0 0 0 10px
}

#wrapper_moncompte_main #menu_compte ul li {
    height: 20px;
    line-height: 20px;
    list-style-type: none
}

#wrapper_moncompte_main #menu_compte a {
    text-decoration: none;
    font-size: 13px;
    color: #333
}

#content_compte_panier {
    width: 800px;
    margin: 0 auto 20px auto
}

#content_compte_panier .btn_noir,
#conteneur_adresse .btn_violet.btn_light,
#content_compte .btn_noir {
    width: 185px;
    height: 30px !important;
    line-height: 30px !important;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.04em;
    padding: 0 !important;
    border: 0;
    font-size: 12px !important;
    background: #c4d600;
    font-weight: 700
}

#conteneur_adresse .btn_violet.btn_light {
    color: #000 !important;
    background: #DAEB00
}

a.where_to_find {
    position: absolute;
    left: 10px;
    bottom: 5px;
    font-size: 13px;
}

#wrapper_new_adresse input.inputFormSite,
textarea.inputFormSite,
#wrapper_new_adresse input.inputForm {
    height: 24px;
    line-height: 100%
}

#wrapper_new_adresse span.radio {
    background: url("../img/rad_add.png") no-repeat 0 0;
    width: 18px;
    height: 18px;
    padding-right: 2px
}

#wrapper_new_adresse .selectStyled .clone {
    border: 1px solid #ecece8;
    height: 24px;
    line-height: 24px;
    border-radius: 0;
    width: 100% !important
}

#wrapper_new_adresse .selectStyled select {
    height: 47px;
    width: 100%;
}

#wrapper_new_adresse #envoyer .btn_violet.btn_light {
    width: 117px !important;
    height: 33px !important;
    color: #000;
    font-size: 10px;
    border: 0;
    border-top: 1px solid #c0cd01;
    letter-spacing: 0.04em;
    text-indent: 0;
    background: url("../img/arrow_black.gif") no-repeat 97px 8px #daeb00;
    font-weight: 700
}

#wrapper_new_adresse .checkbox {
    width: 21px;
    height: 21px;
    background: url(../img/rad_add.png) 0 0 no-repeat;
    padding: 0;
    margin-left: 10px
}

#wrapper_new_adresse .date_naissance_select {
    display: inline-block;
    width: 220px;
    margin-top: 8px;
}

#wrapper_new_adresse .date_naissance_select .select_day {
    width: 31%;
}

#wrapper_new_adresse .date_naissance_select .select_month {
    width: 31%;
    margin: 0 3%;
}

#wrapper_new_adresse .date_naissance_select .select_year {
    width: 31%;
}

#wrapper_new_adresse .selectStyled.select_day .clone,
#wrapper_new_adresse .selectStyled.select_month .clone,
#wrapper_new_adresse .selectStyled.select_year .clone {
    background-position: 195%;
}

#wrapper_new_adresse .activation_form_holder.votre_achat tr#bloc_date_achat .selectStyled.select_year .clone {
    width: 150px !important;
    background-position: 95% center !important;
}

#client_noaddr {
    position: absolute;
    right: 40px;
    top: 0;
    line-height: 33px;
    cursor: pointer;
    display: none;
    color: red;
    text-decoration: underline
}

.tunnel_btn_retour {
    background: url("../img/bloc_adresse_icon_arrow_vert_left.png") no-repeat scroll 20px 10px #000;
    border: 0 none;
    color: #fff;
    font-size: 10px !important;
    height: 28px !important;
    letter-spacing: 0.04em;
    line-height: 28px !important;
    padding: 0 !important;
    text-align: center;
    text-transform: uppercase;
    width: 185px
}

#content_compte_panier #conteneur_adresse .bloc_info {
    width: auto
}

#content_compte .btn_noir {
    height: 14px;
    line-height: 15px
}

#wrapper_moncompte_main #content_compte h3.mes_commande_title {
    background: url("../img/mes_commande_titre.png") no-repeat top right;
    margin-top: 10px !important;
    color: #282828 !important;
    letter-spacing: 0.12em !important;
    height: 25px;
    padding-right: 33px !important
}

#orders_by_type {
    width: 100%;
    background: #f9f9f9;
    margin: 0 0 50px 0
}

#orders_by_type table {
    width: 100%;
    margin: 0 auto;
    border: 1px solid #CCCCCC
}

#orders_by_type table th {
    font-weight: 300;
    font-size: 14px;
    color: #414243;
    background: #fff;
    height: 44px;
    line-height: 44px;
    border-bottom: 1px solid #deddda;
    text-transform: uppercase;
    border-top: 1px solid #c4d600
}

#orders_by_type table td {
    font-size: 12px;
    color: #414243;
    padding: 20px 0;
    border-bottom: 1px solid #efefef;
    font-weight: 700
}

#orders_by_type table td.last {
    text-align: center
}

#orders_by_type table td.price {
    font-size: 20px;
    color: #414243;
    font-weight: 700
}

#orders_by_type table td.price sup {
    font-size: 10px;
    vertical-align: 8px
}

#orders_by_type table td.price span {
    font-size: 10px;
    margin: 0 0 0 -6px
}

#orders_by_type table td.price sup.chf {
    vertical-align: baseline;
}

#orders_by_type table td.price span.chf {
    margin: 0 0 0 0;
}

#orders_by_type table td.separation_vertical a {
    color: #333333;
    text-decoration: none
}

#orders_by_type table td.separation_vertical a:hover {
    text-decoration: underline
}

.btn_violet.detail_moncompte.mon_compte_detail_btn {
    width: 114px;
    height: 28px;
    font-weight: 700;
    font-size: 10px;
    color: #010101;
    border: 0;
    line-height: 28px;
    background: #e8e8e8 url("../img/mes_commande_btnplus.png") no-repeat 97px 10px;
    letter-spacing: 0.05em;
    padding: 0;
    box-sizing: border-box;
    padding-right: 9px
}

.btn_violet.detail_moncompte.mon_compte_detail_btn.actif {
    width: 136px;
    height: 28px;
    font-size: 10px;
    color: #010101;
    border: 0;
    line-height: 28px;
    background: #c0cd1c url("../img/details_commande_fermer.png") no-repeat 118px 13px;
    letter-spacing: 0.05em;
    padding: 0;
    padding-right: 11px;
    box-sizing: border-box
}

.mes_commandes_etat {
    font-size: 11px;
    color: #232323;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.06em
}

.transport_type {
    font-size: 11px;
    color: #c4d600;
    font-weight: 700;
    text-transform: none;
    letter-spacing: 0.06em
}

.mes_commandes_facture {
    background: url("../img/mes_commande_facture.png") no-repeat scroll 16px 27px #fff !important;
    font-size: 12px !important;
    font-weight: normal
}

.mes_commandes_facture a {
    color: #414243;
    font-weight: 700;
    text-decoration: none
}

.separation_vertical.price .main_prix .prix_unite {
    font-size: 19px;
    color: #414243;
    font-weight: 700
}

.euro_mes_commandes {
    font-size: 10px;
    color: #414243;
    font-weight: 700
}

.msg_ordernumber {
    padding-left: 5px;
    display: inline-block;
    width: 33px !important
}

.order_detail {
    padding: 0 !important
}

#wrapper_moncompte_main.order_detail_content {
    padding: 0;
    background: #FFF
}

.details_commandes_infos {
    border: 1px solid #f5f5f5;
}

.details_commandes_infos table {
    border: 0 !important
}

.details_commandes_infos table td {
    padding: 0 !important;
    border-bottom: 0 !important
}

#orders_by_type .title.commande {
    background: #fff url("../img/detail_commande_infos.png") no-repeat 0 4px;
    padding-left: 27px !important;
    color: #000;
    font-size: 14px;
    letter-spacing: 0.12em;
    text-transform: uppercase;

    strong {
        font-family: $primaryFont;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: #000000;
    }
}

.mes_commandes_details_box {
    padding: 6px 0 0 0;
    font-size: 12px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #414243;
}

.mes_commandes_details_box a {
    color: #b5cf23
}

#orders_by_type .title.camion {
    background: #fff url("../img/details_commande_livraison.png") no-repeat 0 6px;
    padding-left: 27px !important;
    color: #000;
    font-size: 14px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
}

#orders_by_type .title.info {
    padding-left: 27px !important;
    color: #000;
    font-size: 14px;
    letter-spacing: 0.12em
}

.details_commande_livraison_box {
    padding: 6px 0 0 27px;
    font-size: 12px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #414243
}

.details_commande_livraison_box address {
    font-style: normal;
    text-transform: none;
    color: #737373
}

.details_commande_livraison_box span#nom {
    font-size: 12px;
    font-weight: normal;
    color: #414243
}

.content_img_panier.mes_commandes_panier {
    overflow: visible;
    width: 148px
}

.details_commande_table_infos {
    margin: 31px 29px 31px 0;
}

.details_commande_table_infos .title_article {
    font-weight: bold;
    color: #c0cd1c;
    font-size: 13px;
    margin-bottom: 10px;
}

#orders_by_type .details_commande_table_infos table th {
    border-top: 0 !important;
    letter-spacing: 0.05em;
    font-weight: 700;
    text-transform: uppercase;
    color: #414243;
    font-size: 11px;
    height: 20px;
    line-height: 20px
}

.details_commande_table_infos table {
    border-right: 0 !important;
    border-left: 0 !important;
    border-top: 0 !important
}

#content_compte #resume_commande .ligne_panier.table_commande_list td {
    font-size: 15px;
    font-weight: normal;
    color: #000;
    margin-top: 10px;
    border: 1px solid #e9e9e9;
}

#content_compte #resume_commande .ligne_panier.table_commande_list td sup,
#content_compte #resume_commande .ligne_panier.table_commande_list td .cents {
    font-size: 8px
}

#content_compte #resume_commande .ligne_panier.table_commande_list td sup {
    vertical-align: 6px
}

#content_compte #resume_commande .ligne_panier.table_commande_list td .cents {
    margin: 0 0 0 -2px;
    font-size: 11px;
}

.ligne_panier.table_commande_list .basket_titreobjet {
    font-size: 15px;
    letter-spacing: 0.06em;
    font-weight: normal;
    color: #000;
}

.ligne_panier.table_commande_list .basket_titreobjet_details {
    font-size: 11px;
    letter-spacing: 0.03em;
    font-weight: normal;
    color: #8e8e8e
}

.ligne_panier.table_commande_list .line_product_desc_preorder {
    color: red;
}

.details_commandes_montant_box {
    margin: 0 29px 43px 0
}

.details_commandes_montant_box table td {
    padding: 0 !important;
    border-bottom: 0 !important;
    font-size: 11px;
    font-weight: normal;
    text-transform: uppercase;
    color: #8e8e8e;
    letter-spacing: 0.03em
}

.details_commandes_montant_box table {
    border: 0 !important
}

.details_commandes_montant_box table .montant_commande,
.total_montant_value {
    text-indent: 30px;
    background: #f4f4f4 !important;
    font-size: 15px !important
}

.details_commandes_montant_box table .montant_commande sup,
.details_commandes_montant_box table .montant_commande .cents {
    font-size: 8px
}

.details_commandes_montant_box table .montant_commande sup {
    vertical-align: 6px
}

.details_commandes_montant_box table .montant_commande .cents {
    margin: 0 0 0 -4px;
    font-size: 11px;
}

#orders_by_type table td .montant_total_mes_commandes {
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    color: #080808;
    letter-spacing: 0.12em
}

#orders_by_type table td .montant_total_mes_commandes.total_montant_value {
    text-indent: 30px;
    background: #f4f4f4 !important;
    font-size: 18px !important;
    letter-spacing: 0.03em !important
}

#orders_by_type table td .montant_total_mes_commandes.total_montant_value sup,
#orders_by_type table td .montant_total_mes_commandes.total_montant_value .cents {
    font-size: 9px
}

#orders_by_type table td .montant_total_mes_commandes.total_montant_value sup {
    vertical-align: 7px
}

#orders_by_type table td .montant_total_mes_commandes.total_montant_value sup.chf {
    vertical-align: baseline;
}

#orders_by_type table td .montant_total_mes_commandes.total_montant_value .cents {
    margin: 0 0 0 -4px;
}

.montant_total_mes_commandes.total_montant_value .cents {
    font-size: 10px !important;
}

.order_detail_content #comment_retourner_cmd {
    background: #fff;
    margin: 12px 0 0 21px;
    padding: 0 !important;
    width: 510px
}

.order_detail_content #comment_retourner_cmd p {
    font-size: 12px;
    font-weight: normal;
    color: #8e8e8e;
    padding-bottom: 5px
}

.order_detail_content #btn_comment_retourner_cmd a {
    background: #c0cd1c;
    border: 0 none;
    color: #000000;
    float: left;
    font-size: 10px;
    font-weight: normal;
    height: 29px;
    letter-spacing: 0.06em;
    line-height: 29px;
    margin-right: 10px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none
}

.order_detail_content #comment_retourner_cmd p strong {
    color: #5e5e5e
}

input.btn_rose.btn_cmd_retour {
    width: 233pxl;
    height: 29px;
    background: #c0cd1c;
    color: #000000;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 0.06em;
    text-align: center;
    line-height: 29px;
    border: 0;
    font-size: 10px;
    float: left;
    margin-right: 10px
}

.cmd_retour_txt {
    margin-top: 10px
}

#content_compte #wrapper_bottom_cmd_holder {
    margin: -14px 0 43px 0
}

#content_compte #wrapper_bottom_cmd {
    width: 495px;
    padding-left: 25px
}

#orders_by_type .order_detail {
    box-shadow: 0 0 17px -3px #ccc;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    margin-left: -1px;
    margin-right: -1px
}

.liv_tab_choix>div {
    width: 106px
}

.warning_point_relais {
    display: inline-block;
    padding: 10px 20px 20px 0;
    font-size: 13px
}

#wrapper_messages {
    width: 100%;
    padding: 0 0 30px 0;
    clear: both
}

#wrapper_messages table {
    width: 100%;
    margin: 0 auto;
    border: 1px solid #deddda
}

#wrapper_messages table th {
    font-weight: 100;
    font-size: 14px;
    color: #414243;
    background: #fff;
    height: 44px;
    line-height: 44px;
    border-bottom: 1px solid #deddda;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 0.12em;
    border-top: 1px solid #c4d600
}

#wrapper_messages table th.fourth_head_title {
    text-indent: 28px;
    text-align: left
}

#wrapper_messages table th.fifth_head_title {
    text-align: left
}

#wrapper_messages table td {
    font-size: 11px;
    font-weight: normal !important;
    color: #282828;
    letter-spacing: 0.05em;
    background: #fbfbfb;
    padding: 12px 0;
    border-bottom: 1px solid #deddda
}

.msg_service_content {
    text-indent: 28px
}

.detail_message_header {
    height: 49px
}

.btn_ouvrir_message {
    float: right;
    margin-right: 10px;
    width: 80px;
    height: 28px;
    background: #e8e8e8 url("../img/detail_msg_puce.png") no-repeat 85% center;
    font-weight: 700;
    font-size: 10px;
    color: #010101;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    cursor: pointer
}

.detail_msg_list {
    line-height: 28px;
    font-size: 11px;
    font-weight: normal !important;
    color: #282828;
    letter-spacing: 0.05em
}

.service_client_off {
    font-size: 11px;
    font-weight: normal !important;
    color: #282828;
    letter-spacing: 0.05em
}

.btn_ouvrir_message.actif {
    width: 75px;
    height: 28px;
    background: #c0cd1c url("../img/detail_msg_puce.png") no-repeat 63px 10px
}

#wrapper_form_infos {
    padding: 10px;
    margin: 0 0 20px 0;
    font-family: arial, sans-serif;
    font-size: 12px;
    color: #333
}

table.message_no_border td {
    height: 26px
}

.expediteur_ext {
    color: #c4d600;
    font-size: 12px;
    text-align: left
}

span.msg_vgt_red {
    background-color: #FFD9D9;
    color: #000000;
    font-size: 12px;
    height: 19px;
    line-height: 19px;
    padding: 3px 10px
}

span.msg_vgt_green {
    background-color: #DDFFDD;
    color: #000000;
    font-size: 12px;
    height: 19px;
    line-height: 19px;
    padding: 3px 10px
}

#conteneur_adresse {
    width: 100%;
    overflow: hidden;
    padding: 0;
    font-size: 12px;
    padding-bottom: 50px;
    padding-top: 20px
}

#conteneur_adresse a {
    font-weight: 400;
    font-size: 10px;
    color: #c4d600;
    text-transform: uppercase;
    text-decoration: none
}

#conteneur_adresse a:hover {
    text-decoration: none
}

#conteneur_adresse .bloc_info {
    height: 25px;
    line-height: 25px;
    margin: 0 auto;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.06em
}

#conteneur_adresse .bloc_info a {
    margin-right: 10px
}

#conteneur_adresse .main_information {
    overflow: hidden;
    padding: 0 0 0 0px;
    margin: 0;
    font-weight: 700;
    color: #636363;
    font-size: 12px;
    letter-spacing: 0.08em
}

#content_compte #resume_commande .title_resume_cmd {
    background-color: #D9D9D9;
    color: $textlightGrey;
    font-weight: bold;
    height: 29px;
    text-align: center
}

#content_compte #resume_commande .ligne_panier {
    background: #eee;
    border-top: 1px solid #fff;
    height: 120px
}

#content_compte #resume_commande .ligne_panier td {
    padding: 5px 12px;
    background: #fff
}

#content_compte #resume_commande .ligne_panier td.first {
    padding: 10px 0 10px 1px !important;
    background: #fff;
    border-right: 0 !important
}

.title.commande {
    background: #fff url("../img/detail_commande_infos.png") no-repeat 0 4px;
    color: #000;
    font-size: 14px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
}

.title.camion {
    background: #fff url("../img/details_commande_livraison.png") no-repeat 0 6px;
    padding-left: 27px !important;
    color: #000;
    font-size: 14px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
}

#wrapper_messages .message_border.bg_gris td,
#wrapper_messages .message_border.bg_gris td .detail_msg_list {
    font-size: 11px;
    color: #000;
    font-weight: 700
}

.message_detail.moncompte_message_list {
    padding: 23px 276px 33px 30px !important;
    background: #fff !important
}

.message_detail.moncompte_message_list .envoyer_reponse {
    width: 113px;
    height: 28px;
    font-size: 10px;
    text-transform: uppercase;
    color: #010101;
    text-align: center;
    line-height: 28px;
    letter-spacing: 0.05em;
    text-decoration: none;
    margin-left: 16px
}

.message_detail.moncompte_message_list .annuler_reponse {
    width: 113px;
    height: 28px;
    background: #e8e8e8;
    font-size: 10px;
    text-transform: uppercase;
    color: #010101;
    text-align: center;
    line-height: 28px;
    letter-spacing: 0.05em;
    text-decoration: none
}

.message_detail.moncompte_message_list .infos_message {
    font-size: 11px;
    font-weight: normal;
    color: #595959
}

.message_detail.moncompte_message_list .infos_message p {
    padding-bottom: 21px
}

.message_detail.moncompte_message_list .infos_message label {
    text-transform: uppercase;
    display: inline-block;
    width: 93px;
    padding-left: 40px
}

.message_detail.moncompte_message_list .detail_message,
.message_detail.moncompte_message_list .detail_message span {
    font-size: 11px !important;
    font-weight: normal;
    color: #595959
}

.message_detail.moncompte_message_list .detail_message {
    padding-left: 40px;
    padding-bottom: 17px
}

.message_detail.moncompte_message_list .detail_message label {
    font-size: 11px;
    text-transform: uppercase
}

#wrapper_messages .details_msg_content {
    padding-left: 32px
}

#wrapper_messages .reponse_message {
    padding-left: 32px
}

#wrapper_messages .reponse_message p {
    font-size: 11px;
    display: block;
    font-weight: normal;
    text-transform: uppercase;
    padding-bottom: 12px;
    color: $textlightGrey;
    letter-spacing: 0.06em;
    padding-top: 28px
}

textarea.message_text_box {
    width: 100%;
    border: 1px solid #ededed;
    min-height: 92px;
    resize: none;
    padding-left: 5px
}

.message_details_btn_bloc {
    margin-top: 12px
}

.btn-deplier {
    background: url("../img/detail_msg_down_btn.png") no-repeat 0 0;
    display: block;
    width: 24px;
    height: 24px;
    float: right;
    padding-right: 74px
}

.btn-deplier.deplier-opened {
    background: url("../img/detail_msg_top_btn.png") no-repeat 0 0;
    display: block;
    width: 24px;
    height: 24px;
    float: right
}

.detail_msg_label {
    float: left;
    width: 95px
}

.message_details_holder {
    float: left;
    width: 335px
}

.wrapper.details_msg_content.class_addline {
    background: url("../img/detail_message_top_puce.png") no-repeat 30px 0;
    padding-top: 25px
}

.left_menu_obj .txt_menu {
    color: #909090;
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 0.05em
}

.bloc_obj .left_menu_obj .txt_menu {
    padding-bottom: 5px;
    width: auto;
    height: auto;
    background: none
}

.left_menu_obj .txt_menu:hover {
    cursor: pointer;
    color: #000
}

.bloc_cat {
    clear: both;
    display: block;
    width: 100%;
    height: 21px;
    line-height: 22px;
    background: none;
    font-size: 12px;
    color: #282828;
    border-bottom: 0;
    margin: 0 0 2px 0;
    cursor: pointer;
    font-weight: normal;
    position: relative
}

.bloc_cat_actif {
    clear: both;
    display: block;
    width: 100%;
    height: 21px;
    line-height: 22px;
    background: none;
    font-size: 12px;
    color: #282828;
    border-bottom: 0;
    margin: 0 0 2px 0;
    cursor: pointer;
    font-weight: normal
}

.bloc_cat .txt_menu_cat {
    white-space: nowrap
}

.txt_menu_cat span {
    float: left
}

.txt_menu_cat .cat_name {
    display: block;
    text-transform: uppercase
}

.txt_menu_cat .nbr_obj {
    text-indent: 5px
}

body .show_title {
    background: #333;
    color: #fff;
    width: auto;
    white-space: nowrap;
    padding: 5px 10px
}

.show_title .arrow_title {
    position: absolute;
    left: 10px;
    top: -5px
}

#bloc_cat1,
#bloc_cat2 {
    overflow: hidden
}

#slider_prix {
    height: 138px
}

.selected_options {
    margin-top: 35px
}

.selected_options.select_colors {
    margin-top: 11px
}

.selected_options.select_colors .btn_select div:first-child {
    border: 1px solid #000;
    width: 8px;
    height: 8px
}

.selected_options.select_tailles {
    margin-top: 13px
}

#bloc_couleur .selected_options {
    padding: 0 0 0 17px
}

#bloc_taille {
    padding-bottom: 20px
}

.txt_page {
    color: #635E58;
    font-size: 13px;
    padding: 0 8px 0 0
}

.prix_min_text {
    font-weight: normal;
    font-size: 13px;
    color: #909090;
    letter-spacing: 0.06em;
    float: left;
    padding-top: 17px;
    padding-bottom: 3px
}

.prix_min_text.max_right {
    padding-left: 40px
}

.lien_ss_cat.bold {
    font-weight: normal;
    color: #282828
}

.lien_ss_cat.bold:hover {
    color: #000
}

.lien_ss_cat {
    font-weight: normal;
    color: #282828
}

.lien_ss_cat:hover {
    color: #000
}

#list_item .loader {
    text-align: center;
    padding: 50px 0
}

#list_item .loader img {
    vertical-align: middle
}

#list_item .eclat_product_epuise,
#list_item .eclat_product_nouveaute {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1
}

#wrapper_contact {
    font-size: 13px;
    color: #333
}

#wrapper_contact .selectStyled select {
    height: 26px
}

#wrapper_contact p.articles {
    width: 980px;
    margin: 15px auto;
    font-size: 13px;
    text-align: justify;
    font-weight: 400
}

#wrapper_contact p.articles a {
    color: #c4d600
}

#wrapper_contact_cgvborder .bg_vert {
    width: 960px;
    margin: 0 auto;
    background: #c4d600;
    padding: 10px
}

#wrapper_contact_cgvborder .bg_vert p {
    width: 940px;
}

#wrapper_contact_cgvborder .bg_vert a {
    color: #000
}

#wrapper_contact_cgvborder .bg_vert a:hover {
    text-decoration: none
}

.b_purple {
    font-size: 12px;
    color: #7030A0;
    font-weight: bold
}

.s_grey {
    font-size: 10px;
    color: #7E7E7E;
    font-style: italic
}

table#bloc_info_offert td.content_tooltip {
    background: #ffffff url(../img/bg_content_tooltip.png) repeat-x bottom left;
    border: 1px solid #d9d9d9;
    min-width: 100px;
    color: $textlightGrey
}

#field p:first-child {
    margin-top: 22px;
    padding-top: 20px;
    border-top: 1px dotted #777;
}

.contact_form_holder .user_icon {
    background: url(../img/user_icon.png) no-repeat 0 0;
    display: inline-block;
    width: 13px;
    height: 17px;
}

.below_form_text {
    margin-left: 130px
}

#contact_form {
    margin-top: 30px
}

#contact_form table {
    width: 100%;

    td {
        position: relative;
        padding-bottom: 10px;

        .img_error,
        .valid_form {
            top: 40%;
        }
    }
}

#contact_form table td label {
    margin-top: 0;
    text-indent: 0
}

#field_photo span#uploadBoxContent {
    color: #474747;
    float: none;
    font-size: 11px;
    font-style: italic;
    line-height: 25px;
    padding-left: 8px
}

.product_file {
    display: block;
    padding-bottom: 4px
}

#wrapper_sitemap {
    width: 700px;
    background: #f9f9f9;
    margin: 20px auto 40px auto;
    font-size: 13px;
    color: #333
}

#wrapper_sitemap h2 {
    width: 100%;
    height: 38px;
    line-height: 38px;
    background: #1f1f1f;
    border-bottom: 4px solid #CBE423;
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
    text-indent: 15px;
    margin: 10px 0
}

#bloc_cb h2 {
    margin: 0 0 1em 0
}

#bloc_cb input[type="image"],
#bloc_3x input[type="image"] {
    height: auto
}

#bloc_cb_directlink {
    font-size: 12px;
    color: #6C6C6C;
}

#image_3d_scured {
    max-width: 100%;
    height: auto;
    padding: 1rem 0 .5rem;
}

.controle_num input[type=text] {
    float: left;
}

.titu_info {
    display: block;
    font-family: tahoma;
    font-size: 10px;
    color: #adadad
}

#controle_num {
    float: left
}

.checkbox_save_card {
    float: left;
    display: block;
    margin-right: 5px
}

.save_card {
    float: left;
    font-size: 11px;
    color: #5c5c5c;
    letter-spacing: 0.04em;
    white-space: nowrap
}

.show_what_is_it {
    display: none;
    position: absolute;
    height: auto;
    bottom: 15px;
    left: 25px;
    padding-left: 5px
}

.show_what_is_it .arrow {
    display: inline-block;
    float: left;
    background: url(../img/arrow_left_info_bank.png) right center no-repeat;
    width: 12px;
    position: relative;
    z-index: 10
}

.show_what_is_it .text {
    float: left;
    background: #fff;
    padding: 5px;
    color: #444;
    width: 150px;
    position: relative;
    z-index: 5;
    -moz-box-shadow: 0px 0px 2px 2px #aaa;
    -webkit-box-shadow: 0px 0px 2px 2px #aaa;
    box-shadow: 0px 0px 2px 2px #aaa
}

#wrapper_recap_montant_secur {
    margin-bottom: 1rem;
    padding: 1rem 1.5rem;
    display: none;

    .recap_montant_secur {
        .title {

            font-size: 13 / 16 * 1rem;
            line-height: 1.2;
            color: $primaryColor;
            text-align: center;
            margin: .5rem auto;
            width: 70%;
        }

        span.tunel_paiement_montant {

            font-size: 30 / 16 * 1rem;
            line-height: 1;
            color: $primaryColor;
            font-weight: normal;
            display: block;
            text-align: center;
        }

        span.currency_unit {
            display: inline;
            font-size: .5em;
            margin-left: -2px;
            vertical-align: 10px;
            line-height: normal;
        }

        .tunel_paiement_right_description {
            font-size: 12 / 16 * 1rem;
            line-height: 1.2;
            margin: .3rem 0 1rem;
            text-align: center;
            padding: 0 1rem;
            color: #808080;
        }
    }
}

#wrapper_payement_secur .text img {
    margin-left: 5px;
    margin-bottom: -2px;
}

#wrapper_need_help .info_com {
    font-size: 9px;
}

#wrapper_virement .iban,
#wrapper_virement .rib {
    margin-top: 10px;
}

#wrapper_virement .iban {
    margin-bottom: 10px;
}

#wrapper_virement .element {
    float: left;
    margin-right: 19px;
}

#wrapper_virement .element .title {
    font-size: 11px;
    padding-bottom: 2px;
}

#wrapper_virement .rib .input {
    float: left;
    margin-right: 17px;
}

#wrapper_virement .input {
    background: #f6f6f6;
    color: $textlightGrey;
}

#wrapper_virement .iban .element {
    float: none;
}

.alias_preview {
    width: 138px;
    font-size: 12px;
    line-height: 19px;
}

.alias_expiration {
    line-height: 19px;
    font-size: 12px;
}

.supprimer_alias {
    line-height: 19px;
    font-size: 11px;
    color: red;
}

.ajouter_alias {
    margin: 0 0 0 8px;
    color: #5F5F5F;
}

.eclat_product_gamme_reduc {
    position: absolute;
    top: 22px;
    left: 100px;
}

.eclat_product_gamme {
    position: absolute;
    top: 105px;
}

#popup_confirm_inscription {
    position: absolute;
    width: 600px;
    height: 180px;
    top: 50%;
    left: 50%;
    margin: -90px 0 0 -300px;
    background: #fff;
    -moz-box-shadow: 0px 0px 20px #111;
    -webkit-box-shadow: 0px 0px 20px #111;
    box-shadow: 0px 0px 20px #111;
    font-size: 12px;
    color: #53514F;
    display: none;
    z-index: 5000;
}

#popup_confirm_inscription p.txt {
    text-align: center;
    padding: 25px 0 0 0;
}

#popup_confirm_reinitialisation_mdp {
    position: absolute;
    width: 600px;
    height: 180px;
    top: 50%;
    left: 50%;
    margin: -90px 0 0 -300px;
    background: #fff;
    -moz-box-shadow: 0px 0px 20px #111;
    -webkit-box-shadow: 0px 0px 20px #111;
    box-shadow: 0px 0px 20px #111;
    font-size: 12px;
    color: #53514F;
    display: none;
    z-index: 5000;
}

#popup_confirm_reinitialisation_mdp p.txt {
    text-align: center;
    padding: 25px 0 0 0
}

#lightbox_codepromo {
    position: absolute;
    width: 600px;
    height: 180px;
    top: 50%;
    left: 50%;
    margin: -90px 0 0 -300px;
    background: #fff;
    -moz-box-shadow: 0px 0px 20px #111;
    -webkit-box-shadow: 0px 0px 20px #111;
    box-shadow: 0px 0px 20px #111;
    font-size: 12px;
    color: #53514F;
    display: none;
    z-index: 5000;
}

#lightbox_codepromo p.txt {
    text-align: center;
    padding: 25px 0 0 0;
    font-size: 13px
}

#lightbox_livraison,
#modbox_error {
    position: absolute;
    width: 600px;
    height: 180px;
    top: 50%;
    left: 50%;
    margin: -90px 0 0 -300px;
    background: #fff;
    -moz-box-shadow: 0px 0px 20px #111;
    -webkit-box-shadow: 0px 0px 20px #111;
    box-shadow: 0px 0px 20px #111;
    font-size: 12px;
    color: #53514F;
    display: none;
    z-index: 10000
}

#modbox_error .btn_noir {
    height: 14px
}

#lightbox_livraison p.txt {
    text-align: center;
    padding: 25px 0 0 0;
    font-size: 13px
}

.pointer {
    cursor: pointer
}

.btn_vert {
    color: #c4d600
}

.btn_vert a {
    color: #c4d600
}

#wrapper_charte_qualite {
    margin: 20px auto 40px
}

#wrapper_charte_qualite .bloc_garantie .visu {
    float: left;
    padding: 0 25px 20px 0;
    margin-left: -5px
}

#wrapper_charte_qualite .bloc_garantie .description {
    float: left;
    margin-top: 50px;
    width: 455px;
    font-size: 15px;
    padding: 10px;
    box-shadow: 0 0 3px #ccc;
    text-align: justify
}

#wrapper_charte_qualite .bloc_garantie .bloc_garantie_bottom {
    float: left;
    width: 470px
}

#wrapper_charte_qualite .bloc_garantie .bloc_garantie_bottom.first {
    padding-right: 20px
}

#wrapper_charte_qualite .bloc_garantie .bloc_garantie_bottom .texte {
    padding: 10px;
    margin: 10px 0;
    background-color: #eee;
    height: 270px;
    font-size: 13px;
    line-height: 17px;
    text-align: justify
}

#wrapper_charte_qualite .bloc_garantie .bloc_garantie_bottom.hesperide_engagement {
    padding: 0 0 0 20px;
    width: 415px
}

#wrapper_charte_qualite .bloc_garantie .bloc_garantie_bottom.hesperide_engagement .title {
    font-size: 20px;
    color: #B5CF23;
    margin-top: 15px
}

#wrapper_charte_qualite .bloc_garantie .bloc_garantie_bottom.hesperide_engagement .texte {
    background: transparent;
    padding: 0;
    margin: 20px 0 0;
}

#wrapper_charte_qualite .bloc_garantie .bloc_garantie_bottom.hesperide_engagement .liste {
    margin: 26px 30px;
    padding: 0
}

#wrapper_charte_qualite .bloc_garantie .bloc_garantie_bottom.hesperide_engagement .liste li {
    margin-bottom: 20px;
    list-style-image: url('../img/point_liste_charte.png')
}

#wrapper_charte_qualite .bloc_garantie .bloc_garantie_bottom.hesperide_engagement .liste strong {
    font-weight: normal
}

#wrapper_charte_qualite .bloc_garantie .bloc_garantie_bottom.hesperide_engagement .liste .purple {
    color: #B5CF23;
    text-decoration: none;
}

#wrapper_charte_qualite .bloc_garantie .bloc_garantie_bottom.hesperide_engagement .condition {
    color: #585858;
    font-family: arial
}

#wrapper_charte_qualite .bloc_garantie .bloc_garantie_bottom .texte p {
    padding-bottom: 15px
}

#wrapper_charte_qualite .bloc_garantie .bloc_garantie_bottom .service_conso span {
    font-size: 10px
}

#wrapper_charte_qualite .bloc_charte .visu {
    float: left;
    padding: 0 30px 20px 0
}

#wrapper_charte_qualite .bloc_charte .description {
    float: left;
    width: 590px;
    font-size: 15px;
    margin: 30px 0;
    padding: 10px;
    box-shadow: 0 0 3px #ccc;
    text-align: justify
}

#wrapper_charte_qualite .bloc_charte .description strong {
    font-weight: normal
}

#wrapper_charte_qualite .bloc_charte .title {
    color: #c4d600;
    font-size: 15px;
    padding-bottom: 10px;
}

#wrapper_charte_qualite .bloc_charte .info {
    padding: 15px 10px 15px 60px;
    margin-left: 348px;
    color: #585858;
    background: #eee url(../img/charte/vgt_parapluie_charte.gif) 10px center no-repeat
}

#wrapper_charte_qualite #bloc_matieres .title {
    font-size: 20px;
    color: #c4d600;
    padding-bottom: 10px
}

#wrapper_charte_qualite #bloc_matieres div.bloc_txt {
    background: #f9f9f9
}

#wrapper_charte_qualite #bloc_matieres div.bloc_txt .bloc_txt_bloc {
    background: #eee
}

#wrapper_charte_qualite #bloc_matieres p {
    font-size: 13px;
    color: #585858;
    padding: 20px 30px
}

#wrapper_charte_qualite #bloc_matieres p.none {
    font-size: 13px;
    color: #585858;
    padding: 0
}

#wrapper_charte_qualite #bloc_matieres ul {
    margin: 10px 0 0 12px;
    font-size: 13px;
    color: #585858
}

#wrapper_charte_qualite #bloc_matieres ul li {
    line-height: 18px;
    list-style-image: url(../img/charte/list-style.gif)
}

#wrapper_charte_qualite #bloc_matieres {
    padding: 20px 0 0 0
}

#wrapper_charte_qualite #bloc_matieres .conseils {
    font-style: italic;
    padding: 0 15px 10px 15px
}

#wrapper_charte_qualite #bloc_matieres .conseils img {
    float: left
}

#wrapper_charte_qualite #bloc_matieres .conseils span {
    font-size: 14px;
    display: block;
    padding: 22px 0 5px 42px;
    font-weight: bold;
    font-style: normal;
    color: #686868
}

#wrapper_charte_qualite #bloc_matieres .title_bloc {
    color: #333333;
    font-size: 20px;
    display: inline
}

.big_title_charte.no_bg {
    padding-left: 0;
    background: none
}

#middle_box_content {
    font-weight: normal;
    width: 980px;
    margin: 0 auto
}

#wrapper_contact_cgvborder {
    font-weight: 400
}

#middle_box_content p {
    font-weight: 400
}

#wrapper_contact_cgvborder ul {
    width: 980px;
    list-style-type: circle;
    list-style-position: outside;
    margin: 0 auto
}

.big_title_charte.sub_title {
    font-size: 14px;
    color: #c4d600;
    font-weight: 700;
    letter-spacing: 0.2em;
    padding-top: 32px;
}

.big_title_charte.sub_title.titre_garantie_hesperide {
    width: auto;
    padding: 30px 0 5px 0;
    margin: 0 0 10px 0
}

.fond_middle_cadre.texte_nom a {
    color: #c4d600
}

.fond_middle_cadre.texte_nom a.dk_label {
    color: #000 !important
}

.fond_middle_cadre.texte_nom .dk_options_inner a {
    color: #000 !important
}

.fond_middle_cadre.texte_nom .dk_options_inner a:hover {
    color: #fff !important
}

.big_title_charte.fournisseur {
    background: url("../img/ico_medal_mauve.png") left 1px transparent no-repeat
}

.big_title_charte img {
    position: absolute;
    bottom: 0;
    left: 0
}

#plus_one {
    float: left;
    margin: 12px 0 0 42px
}

.obligatoire {
    font-family: arial;
    font-size: 10px;
    color: #888
}

#wrapper_zoombox .wrapper_vgt img {
    border: 1px solid #ddd;
    margin-top: 10px;
    display: block;
    max-width: 80px
}

#wrapper_zoombox {
    position: relative
}

#wrapper_zoombox .wrapper_vgt {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    height: 100%;
    max-height: 883px;
    background: url(../img/side_zoom_thumb_bg.png) 0 0 repeat;
    padding-left: 10px;
    padding-right: 10px
}

.selectStyled select {
    display: block;
    height: 47px;
    line-height: 23px;
    opacity: 0;
    position: absolute;
    float: left;
    cursor: pointer;
}

.selectStyled select#couleurProd {
    margin: 0
}

.selectStyled {
    float: left;
    position: relative
}

.ligne_form .prod_listes .selectStyled {
    margin-right: 0px
}

.selectStyled .clone {
    -moz-border-bottom-colors: none;
    -moz-border-image: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: url("../img/select_arrow.png") no-repeat scroll 108% center #FFFFFF;
    color: #000000;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    height: 21px;
    line-height: 21px;
    margin-bottom: 0;
    text-indent: 5px;
    width: 100%;
    float: left;
    text-align: left;
}

.border-left {
    border-left: 1px solid #ededed
}

#popup_comment_fb {
    position: absolute;
    position: fixed;
    top: 50px;
    left: 50%;
    z-index: 9999;
    text-align: left;
    margin: 0 0 0 -350px;
    background-color: #ffffff;
    color: $textlightGrey;
    font-size: 12px;
    width: 720px;
    height: auto;
    display: none;
    -moz-box-shadow: 0px 0px 20px #111;
    -webkit-box-shadow: 0px 0px 20px #111;
    box-shadow: 0px 0px 20px #111;
    padding-bottom: 15px
}

#popup_comment_fb .popup_title span {
    color: #fff;
    font-weight: 300
}

#popup_comment_fb .popup_title span.vert {
    color: #A8BC21;
}

#popup_comment_fb iframe {
    padding-left: 10px
}

.title_adresse span {
    color: #8e8e8e
}

.prod_listes .selectStyled .clone,
.prod_listes .selectStyled .inputForm {
    width: 197px !important
}

#modbox_error,
#modboxpromo {
    position: absolute;
    _position: absolute;
    left: 50%;
    z-index: 9999;
    text-align: left;
    margin: 0 0 0 -200px;
    padding: 10px 0 18px 20px;
    background-color: #FFF;
    color: #616161;
    font-size: 13px;
    width: 492px;
    display: none
}

#modboxpromo {
    width: 50%;
    position: fixed;
    z-index: 100;
    top: 50%;
    background: #fff;
    border-bottom: solid 1px #fff;
    max-width: 492px;
    height: 150px;
    margin: 0;
    transform: translate(-50%, -50%);
    padding: 0;

    div {
        &.texte {
            margin: 7% auto 5%;
            text-align: center;
            font-size: 15px;
            font-family: $primaryFont;
        }
    }

    #btn_cmd_valid {
        display: block;
        position: absolute;
        top: 0.5rem;
        right: 0rem;
        width: 18px;
        height: 18px;
        cursor: pointer;
        background-color: transparent;
        color: transparent;
        border: none;

        &::before {
            position: absolute;
            content: ' ';
            width: 20px;
            height: 20px;
            mask-image: url(../svg/vector_spritesheet.svg);
            mask-repeat: no-repeat;
            mask-size: 500% 700%;
            mask-position: 0 16.66667%;
            background-color: #C4D600;
        }
    }

    .modboxBtnClose {
        display: flex !important;
        max-width: 150px;
        margin: 0 auto;
        width: 100%;
        height: 2.8125rem;
        background: #171717;
        border: 1px solid #171717;
        font-size: 15px;
        color: #fff;
        cursor: pointer;
        justify-content: center;
        align-items: center;
    }
}

.table_basket p.details {
    color: $textlightGrey;
    font-weight: normal;
    margin-top: 5px
}

table#transporteur tr {
    height: 20px
}

#suivi_colissimo {
    background-color: #F5F5F5;
    border-collapse: collapse;
    margin: 10px 0 16px;
    width: 734px
}

#suivi_colissimo thead {
    background-color: #DFDFDF
}

#suivi_colissimo thead th {
    height: 35px;
    text-transform: uppercase
}

#suivi_colissimo tbody {
    height: 50px;
    border-bottom: 20px solid #F5F5F5;
    border-top: 20px solid #F5F5F5
}

#suivi_colissimo tbody td.first {
    width: 88px
}

#suivi_colissimo tbody td.mid {
    border-bottom: 1px solid #CCCCCC;
    padding-left: 20px;
    text-align: left;
    width: 300px
}

#suivi_colissimo tbody td {
    height: 50px;
    text-align: center
}

#completeDiv strong {
    font-weight: 700;
    color: #333333
}

.par_piece {
    margin: 18px 0 0;
}

#recuppanier {
    width: 675px;
    position: absolute;
    top: 100px;
    left: 50%;
    margin: 0 0 0 -340px;
    background: #fff;
    font-size: 12px;
    color: #53514F;
    z-index: 5000;
    padding: 20px;
    -moz-box-shadow: 0px 0px 20px #111;
    -webkit-box-shadow: 0px 0px 20px #111;
    box-shadow: 0px 0px 20px #111
}

#recuppanier .txt_recup {
    margin: 20px auto;
    font-size: 14px;
    text-align: center
}

#recuppanier .btn_violet {
    margin: 0 auto 20px;
    width: 100px;
    line-height: 26px
}

#banner_search_shop {
    width: 955px;
    margin: 70px auto 0 auto;
}

#wrapper_search_shop {
    width: 987px;
    margin: 0px auto 0 auto
}

#wrapper_search_shop #content {
    width: 987px;
    overflow: hidden
}

img#cadre_top {
    width: 955px;
    margin: 0 auto -5px auto
}

#wrapper_content_search_shop {
    padding: 20px 60px 40px 30px;
    overflow: hidden
}

img#cadre_bottom {
    width: 955px;
    margin: 0 auto
}

#wrapper_content_search_shop h1#title {
    font-size: 41px;
    color: #00367B;
    font-weight: normal;
    margin: 0 0 28px 0;
    position: relative
}

#wrapper_content_search_shop h1#title span {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url(../img/trans.png) repeat-x
}

table#search_select {
    width: 290px;
    margin: 4px 0 0 0;
    font-size: 10px
}

table#search_select tr {
    height: 25px
}

table#search_select tr td {
    vertical-align: top
}

select#search_km {
    width: 75px;
    height: 19px;
    font-size: 12px;
    padding: 0 0 0 1px;
    border: 1px solid #ccc
}

select#search_city {
    width: 187px;
    height: 19px;
    font-size: 12px;
    margin: 0 0 0 -10px;
    *margin: 0;
    padding: 0 0 0 1px;
    border: 1px solid #ccc
}

div#google_map_1 {
    height: 522px
}

div#google_map_2 {
    height: 525px;
    width: 583px
}

#resultats_recherche {
    width: 265px;
    height: 524px;
    overflow: auto;
    border-left: 1px solid #ccc;
    padding: 0 0 0 22px
}

#resultats_recherche div.bloc {
    width: 220px;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #ccc
}

#resultats_recherche div.bloc p.title_magasin {
    font-family: arial;
    font-size: 12px;
    font-weight: bold;
    color: #E9568E;
    margin: 10px 0 0 0
}

#resultats_recherche div.bloc p.adresse_horaires {
    font-family: arial;
    font-size: 11px;
    font-weight: bold;
    color: $textlightGrey;
    margin: 10px 0 0 0
}

#resultats_recherche div.bloc p {
    font-family: arial;
    font-size: 11px;
    color: $textlightGrey
}

p.title_magasin {
    font-family: arial;
    font-size: 12px;
    font-weight: bold;
    color: #E9568E;
    margin: 10px 0 0 0
}

p.adresse_horaires {
    font-family: arial;
    font-size: 11px;
    font-weight: bold;
    color: $textlightGrey;
    margin: 10px 0 0 0
}

.img_couleur {
    width: 12px;
    height: 12px;
    margin: 0 2px 0;
    border: 1px solid #dedede
}

span.side_left_bg {
    background: url(../img/side_left_bg.png) top left no-repeat;
    width: 7px;
    height: 533px;
    position: absolute;
    left: -30px;
    top: 0;
    display: block
}

span.side_right_bg {
    background: url(../img/side_right_bg.png) top left no-repeat;
    width: 7px;
    height: 533px;
    position: absolute;
    right: -30px;
    top: 0;
    display: block
}

.titre_entretien {
    padding-bottom: 6px;
    margin-bottom: 25px;
    padding-top: 7px
}

.titre_entretien h1 {
    font-size: 30px;
    color: #8e8e8e;
    text-transform: uppercase;
    font-weight: 300;
    margin: 30px auto;
    width: 980px
}

.entretien_main_pic {
    display: inline-block;
    margin: 30px 0 0 0
}

.entretien_main_detail {
    float: right;
    width: 589px
}

.entretien_top_detail {
    background: #fcfdfd;
    border: 3px solid #fff;
    padding: 8px 10px;
    font-size: 14px;
    color: #585858;
    box-shadow: 0 0 1px 2px #eee;
    line-height: 18px;
    font-weight: normal
}

.entretien_top_detail span {
    color: #000;
    font-weight: 700
}

p.conseil_mobilier_jardin {
    color: #c4d600;
    font-size: 15px;
    padding-top: 30px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: 700
}

p.conseil_abri,
p.conseil_housse {
    background: #eee;
    color: #585858;
    font-size: 13px;
    margin-top: 10px;
    padding: 8px 10px
}

p.conseil_housse {
    margin-top: 0;
    padding: 0 10px 10px 10px
}

p.conseil_abri span {
    background: url(../img/icone_abri.jpg) left 8px no-repeat;
    display: block;
    padding-left: 50px;
    height: auto !important;
    min-height: 38px;
    padding-top: 0;
    font-size: 12px
}

p.conseil_housse span {
    background: url(../img/icone_fauteuil.jpg) left 18px no-repeat;
    display: block;
    padding-left: 50px;
    height: auto !important;
    min-height: 32px;
    padding-top: 10px;
    font-size: 12px
}

.bloc_liste_entretien {
    margin-top: 25px
}

#wrapper_company h2.titre_zoom_matiere {
    color: #c4d600;
    font-size: 20px;
    padding-bottom: 8px;
    padding-left: 0;
    text-transform: none
}

.entretien_descriptif {
    padding: 15px 10px;
    background: #eee;
    margin-bottom: 9px;
    position: relative
}

.bloc_entretien_decrip_left {
    width: 450px;
    float: left
}

.bloc_entretien_decrip_right {
    width: 450px;
    float: right
}

span.picto_matiere {
    display: block;
    width: 100px;
    text-align: center;
    float: left
}

.brief_matiere {
    width: 340px;
    float: right;
    padding-top: 7px
}

.brief_matiere h3 {
    font-size: 20px;
    color: #333;
    font-weight: 700;
}

.brief_matiere p {
    color: #585858;
    font-size: 12px
}

.matiere_plus {
    padding-top: 18px;
    padding-left: 20px
}

p.txt_plus {
    background: url(../img/icone_plus.png) 0 0 no-repeat;
    padding-left: 20px;
    font-size: 15px;
    color: #686868;
    line-height: 20px;
    font-weight: bold
}

.matiere_plus ul {
    padding: 0;
    margin: 0
}

.matiere_plus ul li,
.bloc_entretien_decrip_right ul li {
    list-style: none;
    background: url(../img/bullet_point.png) 0 7px no-repeat;
    padding-left: 13px;
    color: #585858;
    font-size: 12px;
    line-height: 20px
}

p.titre_conseil_entretien {
    background: url(../img/icone_conseil_entretien.jpg) 0 0 no-repeat;
    padding-left: 45px;
    padding-top: 26px;
    font-size: 15px;
    color: #686868;
    margin-bottom: 8px;
    font-weight: bold
}

p.txt_normal {
    color: #585858;
    font-size: 12px;
    line-height: 18px
}

.bloc_entretien_decrip_left.full_width {
    width: auto;
    float: none;
    height: auto !important;
    min-height: 105px
}

.bloc_entretien_decrip_left.full_width .brief_matiere {
    width: auto;
    float: left;
    padding-left: 10px
}

span.image_parasol {
    position: absolute;
    bottom: -3px;
    right: 55px
}

.brief_matiere.matiere_last {
    padding-top: 0
}

.brief_matiere.matiere_last p {
    background: url("../img/icone_conseil_entretien.jpg") no-repeat bottom left;
    padding-left: 49px
}

span.conseil_titre_btm {
    display: block;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 1px;
    padding-top: 5px
}

.bloc_entretien_decrip_left.full_width {
    min-height: 96px
}

.social_btm_bloc {
    background: #f3f3f3;
    padding: 10px 0;
    font-size: 9px;
    text-transform: uppercase;
    font-family: tahoma, serif;
    color: #646464;
    font-weight: bold
}

.social_holder {
    width: 720px;
    margin: 0 auto
}

span.txt_social {
    display: block;
    padding-right: 30px;
    float: left;
    line-height: 50px
}

span.facebook_btm {
    display: block;
    float: left;
    padding-right: 30px;
    border-right: 1px solid #e6e6e6;
    height: 37px;
    padding-top: 13px
}

span.youtube_btm {
    display: block;
    float: left;
    padding-left: 30px;
    height: 39px;
    padding-top: 11px
}

.product_title_holder {
    width: 490px;
    float: left;
    padding-top: 15px
}

span.eclat_selection {
    float: right;
    width: 72px;
    padding-bottom: 5px
}

.titre_entretien.titre_qui_sommes_nous h1 {
    padding-left: 0px;
    font-weight: 300;
    font-size: 30px;
    color: #8e8e8e;
    width: 863px;
    margin: 10px auto 0 auto
}

.title.titre_entretien.titre_qui_sommes_nous {
    width: 980px;
    margin: 0 auto;
    padding: 30px 0 15px 0
}

.inner_core_bloc h2 {
    margin-top: 30px
}

#wrapper_company h2 {
    color: #c4d600;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: unset;
    padding-top: 23px;
}

#wrapper_company.press_container {
    display: flex;
    max-width: 1360px;
    margin: 0 auto;
}

.espace_presse .main_wrapper #content_wrapper {
    margin-top: 31px;
}

.qui_sommes_nous .inner_core_bloc {
    width: 937px;
    margin: 0 auto
}

.qui_sommes_nous .inner_core_bloc p {
    font-size: 13px;
    color: #585858;
    line-height: 18px;
    font-weight: 400;
    text-align: justify
}

.qui_sommes_nous .inner_core_bloc p a {
    color: #585858;
    text-decoration: underline;
}

.qui_sommes_nous .inner_core_bloc p a:hover {
    text-decoration: none;
}

.shadow_box {
    margin-bottom: 27px
}

.shadow_box_container {
    padding-left: 4px
}

.bloc_marque_hesperide {
    padding: 0px 37px
}

.bloc_marque_left {
    float: left;
    width: 416px;
    margin-top: 23px
}

.bloc_marque_right {
    float: right;
    width: 416px;
    margin-top: 23px
}

.retrouvez_hesperide_fb {
    background: #f7f7f7;
    margin-top: 20px;
    text-align: center;
}

.bloc_exigence_qualite {
    padding: 4px;
    background: url(../img/fauteuil.jpg) top right no-repeat;
    min-height: 259px;
    position: relative;
    margin: 0 33px
}

.bloc_exigence_qualite h2 {
    margin: 0;
    padding: 0
}

p.txt_exigence_qualite {
    padding: 10px 0 0 0
}

.exigence_qualite_container {
    margin-bottom: 15px
}

.exigence_qualite_container .shadow_box {
    margin-bottom: 0
}

.qui_sommes_nous .inner_core_bloc p.voir_detail {
    font-size: 9px;
    text-align: right;
    padding: 240px 0 0 0
}

.qui_sommes_nous .inner_core_bloc p.voir_detail a {
    text-decoration: underline;
    color: #585858
}

.distributeurs_partenaires_container {
    position: relative;
    margin: 0 13px
}

.distributeurs_partenaires_container.sans_marques_specialistes {
    position: relative;
    margin: 0 0 77px 0
}

.txt_bot_distrib {
    width: 600px
}

.marques_specialistes_container {
    margin-top: 77px
}

.marques_specialistes_container h2 {
    border-bottom: 1px solid #f4f4f4;
    width: 863px;
    margin-left: 35px
}

#wrapper_company h2.title_marques_specialistes {
    padding-left: 0px
}

.distributeurs_partenaires_container .shadow_box {
    margin-bottom: 12px
}

.distributeurs_partenaires_container p.txt_distributeur_btm {
    color: #becb1c;
    font-weight: 600;
    font-size: 13px;
    padding-top: 0
}

a.link_garantie {
    display: block;
    text-decoration: none;
    padding-top: 1px
}

.bloc_marque_specialiste {
    float: left;
    margin: 21px 0 23px 32px;
    width: 368px;
    background: #fcfcfc;
    padding: 0 25px;
    padding-bottom: 18px;
    text-align: center
}

.bloc_marque_specialiste p {
    min-height: 73px
}

.bloc_marque_specialiste.hesperide,
.bloc_marque_specialiste.neka {
    padding-top: 23px
}

.bloc_marque_specialiste.neka img {
    padding-bottom: 11px
}

.bloc_marque_specialiste.intex img {
    padding-bottom: 11px;
    padding-top: 10px
}

.bloc_marque_specialiste p.marques_voir_produits {
    min-height: 0;
    background: #c4d600;
    width: 195px;
    margin: 0 174px;
    line-height: 25px
}

.bloc_marque_specialiste p.marques_voir_produits a {
    color: #ffffff;
    display: block;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 10px
}

.entretien_content.garantie_hesperide {
    width: 100%;
    padding: 0 0 50px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.bloc_engagement_hesperide {
    width: 515px;
    margin: 0 0 40px;
}

.bloc_engagement_hesperide .shadow_box {
    margin-bottom: 0
}

.bloc_garantie_hesperide {
    margin: 0 auto;
}

.bloc_garantie_hesperide .shadow_box {
    margin-bottom: 0
}

.detail_engagement {
    padding: 10px 23px 20px 22px;
    -webkit-box-shadow: 0px 0px 5px #f0f0f0;
    -moz-box-shadow: 0px 0px 5px #f0f0f0;
    box-shadow: 0px 0px 5px #f0f0f0
}

#wrapper_company .detail_engagement h2 {
    padding-left: 0;
    padding-bottom: 0;
    text-transform: none;
    padding-bottom: 4px
}

#wrapper_company .detail_engagement p.txt_garantie_intro {
    font-size: 13px;
    padding-bottom: 22px
}

.liste_garantie {
    margin-top: 20px;
    font-size: 13px;
}

.liste_garantie a {
    color: #585858
}

.liste_garantie ul {
    list-style: none;
    padding: 0;
    margin: 0
}

.liste_garantie ul li {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-bottom: 30px
}

span.picto_garantie {
    float: left;
    width: 70px
}

p.description_garantie {
    background: url("../img/bullet_vert.png") 0 6px no-repeat;
    padding-left: 15px;
    width: 377px;
    float: right
}

p.description_garantie sup {
    font-size: 9px
}

p.description_garantie a {
    text-decoration: underline;
    color: #585858
}

.liste_garantie ul li.remplacement_gratuit span.picto_garantie {
    padding-top: 12px
}

.liste_garantie ul li.service_consommateur_dispo span.picto_garantie {
    padding-top: 8px
}

a.btn_activation_garantie {
    display: block;
    background: url("../img/btn_activation_garantie.png") 0 0 no-repeat;
    width: 342px;
    height: 59px;
    font-size: 15px;
    text-transform: uppercase;
    line-height: 65px;
    text-decoration: none;
    color: #000;
    text-indent: 15px;
    text-shadow: 0 1px 0 #FFFFFF;
    font-weight: 700;
    margin: 0 0 25px 0;
}

p.condition_garantie {
    font-size: 11px;
    color: #585858;
    padding-top: 10px
}

p.condition_garantie sup {
    font-size: 9px
}

.details_garantie_hesperide {
    position: relative
}

span.picto_garantie_side {
    bottom: -34px;
    right: -20px;
    position: absolute
}

.details_conditions {
    margin-top: 26px
}

#wrapper_company .details_conditions h2 {
    text-align: left;
    font-size: 14px
}

.details_conditions h3 {
    color: #B5CF23;
    background: url("../img/titre_entretien_bg.gif") repeat-x left bottom;
    font-weight: normal;
    padding-bottom: 2px;
    margin-bottom: 8px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 18px
}

.details_conditions p {
    font-size: 14px;
    line-height: 17px;
}

.titre_entretien.titre_presse {
    background: url(../img/border_btm_bg_mauve.jpg) bottom left repeat-x
}

.titre_entretien.titre_presse h1 {
    background: url(../img/presse_titre_icone.jpg) 0 4px no-repeat;
    padding-left: 30px
}

.presse_banner {
    width: 471px;
    height: 405px;
    float: left;
    border-left: 1px solid #f1f1f1;
    border-right: 1px solid #f1f1f1;
    border-top: 1px solid #f1f1f1;
    background: url(../img/presse_bloc_btm_shadow.jpg) bottom left repeat-x #fff;
    text-align: center;
    padding-top: 14px;
    margin-right: 9px
}

.presse_actu p {
    color: #585858;
    font-size: 13px;
    text-align: justify;
    line-height: 17px;
    font-weight: 300
}

.presse_actu p span {
    color: #000;
    font-weight: normal
}

h2.presse_contact_titre {
    padding-top: 26px
}

.bloc_service_pressse ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bloc_service_pressse ul li {
    list-style: none;
    padding: 0;
    margin: 0;
    border-right: 0px solid #f2f2f2;
    float: left;

    &.presse_rcpa {
        margin-bottom: -13px;
    }
}

.bloc_service_pressse ul li.presse_contact_mail {
    margin-top: 15px;
    text-align: center;
    font-size: 14px;
    color: $grey3;
    font-weight: 400;
}

.presse_contact_mail span {
    color: #676767;
}

.fond_middle_cadre .presse_contact_mail span a {
    color: #676767;
    text-decoration: underline;
    font-size: 16px;
}

.bloc_service_pressse ul li.last {
    border-right: 0px
}

.presse_service_add {
    padding-top: 25px;
    text-align: center;
    width: 214px;
    font-size: 14px;
    line-height: 1.4;
    color: $grey3;
    font-weight: 400;
}

.presse_medias {
    background: #fff;
    margin-bottom: 15px;
}

.presse_medias table td {
    height: 120px;
    vertical-align: middle;
    text-align: center;
}

.presse_medias table td.corner_top_left {
    width: 211px;
    background: url(../img/media_tab_top_left.jpg) bottom right no-repeat
}

.presse_medias table td.corner_btm_left {
    background: url(../img/media_tab_btm_left.jpg) top right no-repeat
}

.presse_medias table td.corner_top_right {
    width: 211px;
    background: url(../img/media_tab_top_right.jpg) bottom left no-repeat
}

.presse_medias table td.corner_btm_right {
    background: url(../img/media_tab_top_right.jpg) top left no-repeat
}

.presse_medias table td.tab_middle {
    width: 235px
}

.presse_medias table td.corner_middle_top_left {
    background: url(../img/media_tab_middle_top_left.jpg) bottom right no-repeat
}

.presse_medias table td.corner_middle_btm_left {
    background: url(../img/media_tab_middle_btm_left.jpg) top right no-repeat
}

.presse_medias table td.corner_center_left {
    background: url(../img/media_tab_repeat.jpg) top right repeat-y
}

.wrapper_map_distributeur form {
    background: #f7f7f7;
    padding: 30px 0
}

.wrapper_map_distributeur .intro_distributeur {
    font-size: 13px;
    color: #000;
    padding: 0 30px 30px 30px
}

.wrapper_map_distributeur .wrapper_inputs {
    height: 40px;
    margin: 0 0 12px 0
}

.wrapper_map_distributeur .elem_map_dist {
    float: left;
    margin: 0 0 0 30px
}

.wrapper_map_distributeur .elem_map_dist.choix_departement {
    margin-right: 0
}

.wrapper_map_distributeur .choix_rayon {
    margin: 0 0 0 5px
}

.elem_map_dist label {
    cursor: default;
    font-family: arial;
    text-transform: uppercase;
    float: left;
    padding: 0 12px 0 0;
    font-size: 13px;
    color: #000;
    line-height: 40px
}

.elem_map_dist.choix_rayon label {
    text-transform: none;
    background: #fff;
    height: 38px;
    line-height: 38px;
    border: 1px solid #ebebeb;
    padding: 0 10px;
}

.elem_map_dist input[type=text] {
    border: none;
    height: 18px;
    border-radius: 2px;
    box-shadow: inset -1px 1px 2px #ccc
}

.elem_map_dist input[type=text].adresse {
    width: 192px
}

.elem_map_dist input[type=text].code_postal {
    width: 170px
}

.elem_map_dist input[type=text].ville {
    width: 255px
}

.elem_map_dist .selectStyled {
    height: 40px
}

.elem_map_dist .selectStyled .clone {
    background: url(../img/arrow_show_btm.png) no-repeat 95% center #fff !important;
    font-style: normal !important;
    height: 38px;
    font-size: 13px;
    line-height: 38px;
    border: 1px solid #ededed;
}

.elem_map_dist .selectStyled select {
    width: 100%
}

.wrapper_type_dist {
    border-top: 1px solid #fff;
    padding: 10px 0 0 0;
    margin: 30px 0 0 0
}

.wrapper_type_dist label {
    display: block;
    cursor: default;
    font-size: 13px;
    color: #000;
    line-height: 40px;
    text-transform: uppercase;
    margin: 0 0 0 30px
}

.wrapper_type_dist .f_left {
    height: 78px;
    width: 217px;
    line-height: 78px;
    border: 1px solid #e1e1e1;
    margin: 0 0 0 30px
}

.wrapper_type_dist .f_left label {
    display: inline-block;
    width: 50%;
    line-height: 12px;
    vertical-align: middle;
    margin: 0;
    font-size: 11px
}

.wrapper_type_dist .f_left .checkbox {
    margin: 34px 0 0 -5px;
}

.dist_ico {
    width: 24px;
    height: 28px;
    display: inline-block;
    background: transparent url(../img/distrib_h.png) no-repeat left top;
    vertical-align: middle;
    margin: 0 10px 0 40px;
}

.dist_ico.premium_oui {
    background-position: center top
}

.dist_ico.premium_multi {
    background-position: right top
}

.wrapper_embed_map_distributeur {
    height: 730px
}

.wrapper_embed_map_distributeur .embed_map_distributeur {
    width: 977px;
    height: 100%;
    float: left;
    border: 1px solid #ccc
}

.wrapper_embed_map_distributeur .liste_distributeur {
    overflow: auto
}

.wrapper_embed_map_distributeur .no_result {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #B5CF23;
    display: block;
    text-align: center;
    text-transform: uppercase;
    margin-top: 20px
}

.wrapper_embed_map_distributeur .info_popup {
    font-family: arial;
    font-size: 13px;
    color: #000
}

.wrapper_embed_map_distributeur .info_popup .name {
    color: #c4d600;
    text-transform: none;
    margin-bottom: 4px;
    font-weight: bold
}

.wrapper_embed_map_distributeur .info_popup .adresse {
    margin-bottom: 10px
}

.wrapper_embed_map_distributeur .info_popup .site {
    color: #000;
    margin-bottom: 15px;
    display: block;
    text-decoration: none
}

.horaire_title {
    margin-bottom: 10px;
    font-weight: bold;
}

.info_popup {
    td {
        width: 100%;
        line-height: 1.5;
        border-bottom: none !important;
    }
    .details {
        font-weight: bold;
        text-decoration: underline;
    }
}

.selectStyled .clone {
    border-color: #d1d1d1;
    border-radius: 2px
}

.selectStyled.select_pays .clone {
    width: 200px !important
}

.elem_map_dist input.adresse[type="text"] {
    width: 270px
}

.elem_map_dist.choix_departement .selectStyled .clone {
    width: 170px !important
}

.elem_map_dist.choix_ville .selectStyled .clone {
    width: 110px !important
}

.elem_map_dist.choix_distributeur .selectStyled .clone {
    width: 270px !important
}

.elem_map_dist.choix_rayon .selectStyled .clone {
    width: 100px !important
}

.elem_map_dist.btn_recherche {
    margin: 0;
    float: left;
    margin: 18px 0 0 0
}

.elem_map_dist.btn_search {
    width: 169px;
    height: 46px;
    border-radius: 0;
    background: #000;
    font-size: 13px;
    color: #fff;
    text-transform: uppercase;
    text-shadow: none;
    border: 0;
    text-align: center
}

.autocomplete {
    display: none;
    width: 435px;
    padding: 10px 15px;
    background: #fff;
    cursor: pointer;
    list-style-type: none;
    height: 100px;
    overflow: auto;
    position: absolute;
    top: 48px;
    z-index: 5;
    border: 1px solid #DDD;
    border-top: none;
}

.autocomplete li {
    padding: 2px 0
}

.autocomplete li:hover {
    background: #C6DF2B
}

#popup_inscription_home {
    position: fixed;
    _position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10001;
    text-align: left;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: -385px;
    margin-top: -240px;
    padding: 0px;
    background: url(../img/popup_bg.jpg) no-repeat center center #fff;
    color: #616161;
    font-size: 11px;
    width: 787px;
    height: 483px;
    display: none;

    font-size: 11px;
    color: $textlightGrey;
    -moz-box-shadow: 0px 0px 20px #111;
    -webkit-box-shadow: 0px 0px 20px #111;
    box-shadow: 0px 0px 20px #111;
}

#visu_popup_inscription_home {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0
}

#fermer_popup_inscription_home {
    position: absolute;
    margin: 1px 0 0 737px;
    cursor: pointer
}

form#inscription_popup_home input.inputErr {
    margin-top: 0
}

#conf_inscription_news {
    margin-left: 335px;
}

#title_popup_inscription_home {
    margin-top: 16px;
    font-size: 20px;
    color: #000;
    text-transform: uppercase;
    text-align: center;
    line-height: 30px;
    color: #000;

    font-size: 23px;
}

#conf_inscription_news {
    opacity: 0;
    margin-left: 0;
    text-align: center;
    background-color: #f3f3f3;
    width: 87.5%;
    height: 12rem;
    margin-left: 3.2%;
    text-align: left;
    padding: 1.35rem;
    transition: opacity 1s ease-in-out;

    #title_popup_inscription_home {
        margin-top: 16px;
        font-size: 20px;
        color: #000;
        text-transform: uppercase;
        text-align: left;
        line-height: 30px;
        color: #000;

        font-size: 23px;
        padding-left: 26px;
    }

    &[style=""],
    &[style=" "] {
        opacity: 1;
        display: block !important;

        .title_popup_inscription_home {
            display: block !important;
            font-size: 22px;
            text-transform: uppercase;
            display: block;
            font-weight: 900 !important;
        }

        #intro {
            text-align: left;
            padding-top: 1rem;
            width: 100% !important;

            .adwordsBtn {
                margin-top: 1rem;
                width: 180px;
                height: 43px;
                border: 1px solid black;
                text-indent: 0;
                background-color: white;
                display: block;
            }
        }
    }
}

#title_popup_inscription_home strong {
    color: #000;

    font-size: 23px;
}

#title_popup_inscription_home span {
    font-size: 16px;
    text-transform: none;
    padding-left: 4px;

    margin-top: -7px;
    display: block;
}

p#intro {
    margin: 10px 0 0 330px;
    text-align: left;
    font-size: 14px
}

p#intro span {
    font-size: 12px
}

input.pop_btn_ok {
    background: #B5CF23;
    border: 0 none;
    color: #FFFFFF;
    cursor: pointer;
    float: left;
    height: 31px;
    margin-left: 4px;
    text-align: center;
    width: 47px;
    font-size: 17px;
    text-transform: uppercase
}

#separation_popup_inscription_home {
    width: 400px;
    overflow: hidden;
    margin: 8px 0 0 300px
}

.pop_txt_listing {
    padding-left: 35px;
    padding-top: 6px;
    bottom: 9px;
    position: absolute;
}

#popup_inscription_home ul {
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 0px;
    line-height: 9px;
}

#popup_inscription_home ul li {
    list-style: none;
    margin: 0;
    padding: 0;
    color: #585858;
    font-size: 16px;
    background: url(../img/popup_green_arrow.png) no-repeat 0 4px;
    padding-left: 18px;

    line-height: 20px;
}

.contact_txt_holder p {
    color: #585858;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.05em
}

p.no_service_client {
    padding-bottom: 20px;
    margin-bottom: 20px;
    background: url("../img/sep_contact.jpg") no-repeat center bottom
}

#middle_box_content .contact_txt_holder p {
    font-weight: 600
}

.contact_txt_holder p span {
    color: #000
}

p.no_service_client span.contact_num {
    font-size: 14px
}

.contact_form_holder {
    padding-top: 20px;
    width: 100%;
    max-width: 725px;
    margin: 0 auto
}

.contact_form_holder a {
    color: #333333
}

#wrapper_contact .selectStyled .clone {
    width: 600px;
    height: 25px;
    line-height: 24px;
    background: url("../img/select_arrow.jpg") no-repeat scroll 100% center #FFFFFF !important
}

#list_question {
    overflow: hidden;
    padding-top: 10px;
}

.product_top_title {
    float: left;
    width: 506px
}

.img_collection {
    margin: 10px 0 10px 0
}

.bloc_ss_cat_collection .left_menu_unselect {
    margin-left: 18px
}

#left_menu_cat_collection {
    margin-top: 2px;
    overflow: hidden;
    padding-bottom: 15px
}

#content_wrapper.product_page_holder {
    background: url("../img/intex_bg.jpg") no-repeat top center #FFFFFF;
    min-height: 0 !important
}

.intex_banner {
    width: 942px;
    height: 300px;
    position: relative
}

span.product_banner_holder {
    position: absolute;
    top: 0;
    left: 0;
    display: block
}

.product_side_menu {
    width: 381px;
    height: 300px;
    background: url(../img/product_page_side_bg.png) top left repeat;
    position: relative
}

span.product_page_logo {
    padding-left: 30px;
    padding-top: 24px;
    display: block
}

ul.product_page_menu {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-top: 25px;
    width: 209px
}

ul.product_page_menu li {
    list-style: none;
    padding: 0;
    margin: 0;
    color: #424242;
    font-size: 12px;
    text-transform: uppercase
}

ul.product_page_menu li a {
    padding: 8px 0;
    padding-left: 30px;
    display: block;
    color: #424242;
    text-decoration: none
}

ul.product_page_menu li.active a,
ul.product_page_menu li a:hover {
    color: #fff;
    background: #24688f;
    font-weight: 700
}

.product_banner_caption {
    position: absolute;
    right: 0;
    bottom: 0px;
    height: 86px;
    width: 517px;
    background: url(../img/product_banner_cpation_bg.png) top left repeat-x;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 12px;
    color: #e0e0e0;
    font-size: 12px
}

.product_banner_caption span {
    font-size: 15px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase
}

.product_page_btm {
    margin-top: 30px
}

.product_page_side_box {
    float: left;
    width: 472px
}

.product_teaser_ad_big {
    width: 469px;
    border: 1px solid #e8e8e8;
    height: 313px;
    position: relative;
    margin-right: 1px;
    margin-bottom: 1px
}

.product_teaser_ad_big:last-child {
    margin-bottom: 0;
    margin-top: 1px
}

.product_teaser_ad_big img {
    display: block;
    margin: 0 auto;
    width: 469px;
    height: 313px
}

.teaser_caption {
    height: 50px;
    background: url(../img/teaser_caption_bg.png) top left repeat;
    position: absolute;
    bottom: 0;
    left: 0
}

.product_teaser_ad_small_01 {
    width: 233px;
    height: 155px;
    float: left;
    border: 1px solid #e8e8e8;
    position: relative;
    margin-right: 1px
}

.product_teaser_ad_small_01 img {
    display: block;
    width: 233px;
    height: 155px;
    margin: 0 auto
}

.product_teaser_ad_small_01 .teaser_caption {
    width: 223px;
    height: 40px;
    padding-left: 10px;
    padding-top: 10px
}

.product_teaser_ad_small_01.ad_small_02 .teaser_caption {
    width: 223px
}

.product_teaser_ad_small_01 p.caption_title {
    color: #000;
    text-transform: uppercase;
    font-size: 12px;
    float: left;
    line-height: 15px;
}

.product_teaser_ad_small_01 p.caption_title span {
    text-transform: none;
    font-size: 11px;
    font-weight: normal;
}

.product_teaser_ad_small_01 .teaser_caption p.caption_price {
    float: right;
    margin-right: 10px
}

.product_teaser_ad_small_01 span.normal_price {
    float: none;
    color: #24688f;
    font-size: 15px;
    font-weight: bold;
    display: block;
    text-align: right;
    line-height: 6px
}

.product_teaser_ad_small_01 span.main_centimes {
    font-size: 12px
}

.product_teaser_ad_small_01 span.normal_price sup {
    font-size: 8px;
    font-weight: normal;
    padding-left: 3px
}

.product_teaser_ad_small_01 span.cutthrough_price {
    float: none;
    display: block;
    padding-left: 0;
    font-size: 13px;
    color: $textlightGrey;
    font-weight: normal;
    padding-top: 0px;
    text-align: right
}

.product_teaser_ad_small_01 span.cutthrough_price sup {
    font-size: 8px;
    padding-left: 3px
}

#content_wrapper.product_page_holder.neka_page {
    background: url("../img/neka_bg.jpg") no-repeat top center #FFFFFF;
    min-height: 0 !important
}

.neka_page ul.product_page_menu li.active a,
.neka_page ul.product_page_menu li a:hover {
    background: #D03A3B
}

.neka_page span.normal_price {
    color: #d03a3a
}

p#intro {
    margin: 0;
    text-align: center;
    width: 560px;
    margin: 0 auto;
    color: #585858
}

p#intro span.felicitation_top_txt {
    display: block;
    border-bottom: 0px solid #DFDFDF;
    padding-bottom: 0px;
    margin-bottom: 0px;
    display: block;
    font-size: 11px;
    padding-top: 0px;
    text-align: left;
    padding-left: 27px;

    margin-top: -3px;
}

p#intro span.merci_confiance_txt {
    display: block;
    font-size: 11px;
    text-align: left;
    padding-left: 26px;
}

.pop_acceder_boutique {
    width: 265px;
    height: 30px;
    line-height: 30px;
    background: #171717;
    text-align: center;
    margin: 6px 0 0 25px;
    text-transform: uppercase;
    cursor: pointer;
    color: #fff;
    text-indent: 0em;

    float: left;
}

@-moz-document url-prefix() {
    .pop_acceder_boutique {
        margin: 3px 0 0 25px;
    }
}

#content_wrapper.page_produit_garantis {
    overflow: visible
}

.mon_compte_content_holder {
    padding-top: 8px;
    position: relative
}

.titre_entretien.titre_mon_compte h1 {
    background: url("../img/icone_mon_compte.png") no-repeat left top;
    padding-top: 3px;
    padding-left: 27px
}

#wrapper_moncompte_main #menu_compte {
    margin-left: 0
}

#wrapper_moncompte_main #content_compte.page_inscript_news {
    width: 100%;
    margin: 0;
    display: inline-block;
}

.compte_core_holder {
    width: 621px;
    padding: 20px 41px 37px
}

.compte_core_holder p {
    line-height: 17px;
    color: #585858
}

.compte_core_holder p a {
    text-decoration: underline;
    color: #585858
}

table.tableau_garantie {
    width: 94%;

    font-size: 13px;
    margin: 0;
    border-collapse: collapse;
}

.mon_compte_btm_link {
    padding-top: 12px;
    padding-bottom: 15px
}

.mon_compte_btm_link a {
    line-height: 28px;
    height: 28px;
    color: #010101;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.08em;
    font-weight: 700;
    padding-right: 25px;
    padding-left: 14px;
    background: url("../img/detail_msg_puce_02.png") no-repeat right 10px #e8e8e8;
    text-decoration: none;
    float: right;
    margin-left: 17px
}

a.contcater_service_client {
    background-color: #c0cd1c;
    margin-right: 32px
}

.rayon_banner {
    width: 960px;
    padding-top: 8px;
    margin-bottom: 10px
}

.rayon_banner img {
    display: block
}

.rayon_banner_left {
    width: 300px;
    padding-top: 50px;
    padding-left: 50px;
    float: left
}

.rayon_banner_left p {
    font-family: Arial, Helvetica, sans-serif;
    color: #565656;
    font-size: 12px;
    padding-top: 14px
}

.rayon_banner_left p strong {
    color: #494949
}

.rayon_banner_right {
    float: right
}

#wrapper_messages input {
    float: right;
    margin: 10px 0 0 0
}

input.tab_rad {
    float: left;
    margin-right: 4px;
    margin-top: 3px
}

span.rad-txt {
    float: left;
    padding-right: 12px
}

ol.contact_reponse_listing {
    margin: 0;
    padding: 0;
    margin-left: 130px
}

ol.contact_reponse_listing li {
    margin: 0;
    padding: 0;
    margin-left: 19px;
    margin-bottom: 15px
}

#field .left_pad {
    padding-left: 130px
}

td.no_garantie,
td.no_garantie a {
    color: #cc0000
}

#contact_form label.labelFormSite,
#contact_form label {
    font-style: normal;
    font-weight: normal;
    margin-right: 10px;
    padding: 0;
    margin-top: 6px;
    display: inline-block;
    color: #333;
    font-size: 14px;
    white-space: nowrap;
}

.msg_erreur_formulaire {
    color: #cc0000;
    width: 110px
}

.msg_erreur_formulaire a {
    color: #cc0000;
    text-decoration: underline
}

.dk_container {
    background: #fff;
    font-size: 12px;
    font-family: arial;
    border-radius: 2px;
    width: 590px;
    color: #000;
    float: left
}

.dk_toggle {
    border: 1px solid #D1D1D1;
    padding: 0;
    text-shadow: #fff 1px 1px 0;
    border-radius: 2px;
    position: relative;
    width: 564px !important;
    padding-right: 25px;
    height: 26px;
    line-height: 24px;
    text-indent: 5px;
    background: url("../img/select_arrow.jpg") 562px 0 no-repeat;
    display: block;
    overflow: hidden;
    zoom: 1
}

.dk_toggle:after {
    position: absolute;
    width: 10px;
    height: 10px
}

.dk_options a {
    background-color: #fff;
    padding: 0;
    text-indent: 6px
}

.dk_options a:hover,
.dk_options:focus,
.dk_options {
    background-color: #0A246A;
    color: #fff
}

.dk_options_inner {
    border: 1px solid #000;
    color: #000;
    border-left: 2px solid #B2B2B2;
    border-top: 1px solid #B2B2B2;
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
}

.tab_decriptif_garantie.with_border_rgt span {
    font-weight: 700;
}

.dk_open {
    position: relative
}

.dk_open .dk_options {
    display: block
}

.dk_open .dk_label {
    color: inherit
}

.dk_options {
    display: none;
    margin-top: -1px;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 10
}

.dk_options a,
.dk_options a:link,
.dk_options a:visited {
    display: block
}

.tablet .dk_options_inner li,
.mobile .dk_options_inner li {
    line-height: 45px !important;
    border-bottom: 1px solid #ccc
}

.dk_container select {
    position: absolute;
    top: -99999em;
    visibility: hidden;
    width: 590px;
    height: 26px
}

.title.titre_entretien.titre_choix_livraison {
    margin-bottom: 20px
}

.titre_choix_livraison h1 {
    background: url("../img/icone_choix_livraison.png") no-repeat 0 2px;
    padding-left: 32px
}

.livraison_content_holder {
    width: 998px;
    margin: 0 auto;
    margin-bottom: 33px
}

.navigation_rayon {
    margin-left: 12px
}

#garantieDateAchatErreur {
    color: #cc0000
}

#garantieDateAchatErreur a {
    color: #cc0000;
    text-decoration: underline
}

.activation_form_holder .autocomplete_produit {
    min-height: 21px;
    max-height: 106px;
    height: auto;
    float: left
}

.row_look .eclat {
    position: absolute;
    top: 0;
    left: 0;
}

.row_look .eclat img {
    width: 34px;
}

.ecalt_rect_place {
    display: inline-block;
    padding-right: 7px;
    padding-top: 50px
}

.login_bloc_holder {
    background: #f9f9f9;
    border: 1px solid #f4f4f4;
    padding: 50px 0;
    position: relative
}

#identification {
    width: 832px;
    margin: 0 auto
}

#identification.bloc_inscription .bloc_form_inscription {
    min-height: 0
}

#identification.bloc_inscription .bloc_btn_inscription {
    margin-top: 3px
}

.notclient_right_shadow {
    left: 473px;
    position: absolute;
    top: 50px
}

.notclient_bottom_shadow {
    left: 86px;
    position: absolute;
    bottom: 174px
}

.notclient_left_shadow {
    left: 70px;
    position: absolute;
    top: 50px
}

.client_left_shadow {
    left: -66px;
    position: absolute;
    top: -108px
}

.client_right_shadow {
    right: -74px;
    position: absolute;
    top: -108px
}

td.btm_form_holder input.inputFormSite {
    margin-top: 0;
    width: 265px
}

.selection_ou {
    width: 38px;
    height: 21px;
    border: 1px solid #e8ecab;
    line-height: 21px;
    text-align: center;
    margin: 0 auto;
    font-weight: 300;
    font-size: 15px;
    color: #414243;
    text-transform: uppercase
}

.login_facebook_connexion {
    padding-bottom: 20px
}

#choix_relais_kiala {
    background: #f5f5f5
}

#popup_numero_retour {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
    text-align: left;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: -250px;
    margin-top: -200px;
    padding: 0px;
    background-color: #ffffff;
    color: $textlightGrey;
    font-size: 12px;
    width: 500px;
    height: 300px;
    display: none;
    box-shadow: 0px 0px 20px #111
}

.mory_transp {
    margin-top: 50px
}

#popup_numero_retour p {
    padding: 0 50px 10px 50px
}

#popup_numero_retour select {
    border: 1px solid #cccccc
}

#fermer_popup_numero_retour {
    margin: 10px 0 0 400px;
    overflow: hidden
}

p.mory_retour {
    text-align: justify
}

p.mory_retour a {
    color: #a29c9c
}

div.printPdfStyle {
    margin-top: 20px
}

p.links_comment_retourner_cmd {
    text-align: left;
    padding: 0;
    margin: 0
}

p.links_comment_retourner_cmd a {
    color: #8e8e8e;
    font-size: 10px;
    letter-spacing: 0.06em;
    text-align: left;
    text-decoration: none;
    display: block;
    line-height: 12px
}

p.links_comment_retourner_cmd a:hover {
    text-decoration: none
}

.rose_cmd_exp {
    color: #c4d600;
    font-size: 20px;
    text-align: center;
    font-weight: bold
}

#wrapper_moncompte_detailscommande #suivi_colissimo_title {
    margin: 35px 70px 10px 100px;
    clear: both
}

#wrapper_moncompte_detailscommande #suivi_colissimo_title #colissimo {
    color: #FC0
}

#wrapper_moncompte_detailscommande #suivi_colissimo {
    width: 790px;
    background-color: #F5F5F5;
    border-collapse: collapse;
    margin: 10px 70px 0 100px
}

#wrapper_moncompte_detailscommande #suivi_colissimo caption {
    text-align: left;
    margin-bottom: 10px
}

#wrapper_moncompte_detailscommande #suivi_colissimo caption a,
#wrapper_moncompte_detailscommande #commande span.colissimo a {
    color: #333;
    text-decoration: underline
}

#wrapper_moncompte_detailscommande #suivi_colissimo caption a:hover,
#wrapper_moncompte_detailscommande #commande span.colissimo a:hover {
    color: #EA6195;
    text-decoration: none
}

#wrapper_moncompte_detailscommande #suivi_colissimo thead {
    background-color: #DFDFDF
}

#wrapper_moncompte_detailscommande #suivi_colissimo thead th {
    height: 35px;
    text-transform: uppercase
}

#wrapper_moncompte_detailscommande #suivi_colissimo tbody {
    border-top: 20px solid #F5F5F5;
    border-bottom: 20px solid #F5F5F5;
    height: 50px;
}

#wrapper_moncompte_detailscommande #suivi_colissimo tbody td {
    height: 50px;
    text-align: center
}

.title.titre_entretien.titre_modif_motDePasse {
    padding-bottom: 3px
}

.title.titre_entretien.titre_modif_motDePasse h1 {
    background: none;
    padding-left: 0
}

#wrapper_catalogues a {
    text-decoration: none
}

#wrapper_catalogues .wrapper_catalogue {
    width: 288px;
    border: 1px solid #BDD924;
    text-align: center
}

#wrapper_catalogues .wrapper_catalogue .title_catalogue {
    color: #fff;
    background: #BDD924;
    text-align: center;
    font-size: 24px
}

p.catalogue_sstitle.catalogue_erratum_msg a {
    color: #c4d600;
    text-decoration: underline;
}

.bloc_encart_droite_haut {
    position: relative;
    height: 134px
}

.bloc_encart_droite_haut img {
    position: absolute
}

.bloc_encart_droite_bas {
    margin-top: 12px
}

.grey {
    color: #CC0000
}

#comment_retourner_cmd {
    width: 320px;
    float: left;
    background-color: #EEE;
    margin-top: 10px;
    padding: 10px
}

body.has_bandeau {
    position: absolute;
    top: 40px
}

.bandeau_top_wrapper {
    position: fixed;
    top: 0;
    line-height: 40px;
    width: 100%;
    text-align: center;
    background: url('../img/home/bg_bandeau_top.jpg') repeat-x;
    z-index: 50
}

.bandeau_top {
    vertical-align: middle
}

.bandeau_cp {
    position: absolute;
    top: 0px;
    left: 50px;
    margin-left: 0px
}

#lightbox_stock_alert {
    display: none;
    width: 696px;
    height: 534px;
    position: fixed;
    z-index: 1000;
    left: 50%;
    top: 50%;
    margin: -267px 0 0 -348px;
    background: #fff;
    -webkit-box-shadow: 0px 0px 6px 0px #333;
    box-shadow: 0px 0px 6px 0px #333;

    .close {
        width: 20px;
        height: 20px;
        background: transparent url(../img/btn_close_popup.png) no-repeat center center;
        position: absolute;
        top: -9px;
        right: -9px;
        cursor: pointer;
        z-index: 100
    }
}

#lightbox_stock_alert .visu {
    margin: 44px 0 0 -40px
}

#lightbox_stock_alert .infos {
    background: #f8f8f8;
    padding: 23px 35px;
    min-height: 259px
}

#lightbox_stock_alert .infos .infosWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

#lightbox_stock_alert .infos h1 {
    line-height: 25px;
    margin: 0 0 4px;
    font-size: 28px;
}

#lightbox_stock_alert .infos h2 {
    line-height: 18px;
    margin: 0;

    font-size: 14px;
    color: #404040;
}

#lightbox_stock_alert .item_price {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

#lightbox_stock_alert .price_tag {
    font-family: Arial, sans-serif;
    font-weight: 700;
    font-size: 40px;
    color: #282828;
    line-height: 1.2;
}

#lightbox_stock_alert .item_price .price_tag+.price_tag {
    position: relative;
    padding: 0 8px;
    margin-left: 8px;
    font-size: 32px;
    color: grey;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 55%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        height: 2px;
        background-color: grey;
    }
}

#lightbox_stock_alert .price_tag sup,
#lightbox_stock_alert .price_tag span {
    font-size: .4em;
}

#lightbox_stock_alert .price_tag sup {
    vertical-align: 1rem;
}

#lightbox_stock_alert .price_tag span {
    margin: 0 0 0 -.5rem;
}

#lightbox_stock_alert p.ajout_panier_value {
    text-align: center;
}

#lightbox_stock_alert .infos .color {
    line-height: 18px;
    margin: 0;

    font-size: 14px;
    color: #404040;
    text-transform: uppercase
}

#lightbox_stock_alert .infos .price {
    font-family: Arial, sans-serif;
    font-weight: 700;
    font-size: 40px;
    color: #282828
}

#lightbox_stock_alert .infos .price sup {
    font-family: inherit;
    font-size: 0.5em;
    color: inherit
}

#lightbox_stock_alert .infos .price .cents {
    margin-left: -0.5em;
    font-family: inherit;
    font-size: 0.5em;
    color: inherit
}

#lightbox_stock_alert #ligne_taille,
#lightbox_stock_alert #ligne_couleur {
    width: 100%;
    margin: 0 0 20px 0;
    text-transform: uppercase;
    font-size: 14px
}

#lightbox_stock_alert #ligne_taille p,
#lightbox_stock_alert #ligne_couleur p {
    margin: 0 0 5px 0
}

#lightbox_stock_alert .form {
    position: relative;
    padding: 23px 35px
}

#lightbox_stock_alert .wrapper_form,
#lightbox_stock_alert .wrapper_confirmation {
    width: auto;
    margin: 8px auto 0 auto;
    padding-bottom: 8px
}

#lightbox_stock_alert .wrapper_confirmation {
    display: none
}

p.sub_title_alerte_stock {
    padding-bottom: 14px;
    font-size: 16px;
    color: #1F1F1F;
    letter-spacing: 0.04em
}

#lightbox_stock_alert .formItem_container {
    width: fit-content;
    float: left;
    position: relative
}

#lightbox_stock_alert .formItem_container label {
    float: left;
    color: #757575;
    font-size: 13px;
    letter-spacing: 0.03em;
    width: fit-content;
    line-height: 28px;
    margin-right: 15px;
}

#lightbox_stock_alert .formItem_container input[type="text"] {
    width: 295px;
    height: 25px;
    line-height: 25px;
    border: 1px solid #e1e0da;
    float: left;
    margin: 0
}

.alerte_container_img {
    margin-right: 19px;

    img {
        height: auto;
        width: 259px;
    }
}

.block_info {
    width: calc(100% - 285px);
}

p.txt_demande_prise {
    width: 385px;
    color: #1f1f1f;
    font-size: 16px !important;
    letter-spacing: 0.04em;
    float: left;
    line-height: 15px;
    font-weight: 700 !important
}

p.txt_demande_prise span {
    color: #757575;
    font-size: 13px
}

#lightbox_stock_alert .wrapper_confirmation p {
    padding: 0;
    text-align: left;
    font-size: 13px
}

#lightbox_stock_alert .wrapper_confirmation p strong {
    display: block;
    margin: 0 0 5px 0
}

#lightbox_stock_alert .wrapper_form,
#lightbox_stock_alert .wrapper_erreur {
    width: auto;
    margin: 0
}

#lightbox_stock_alert .wrapper_erreur {
    display: none
}

#lightbox_stock_alert .wrapper_erreur p {
    padding: 80px 0 0 20px;
    text-align: left;
    font-family: arial;
    font-size: 13px
}

#lightbox_stock_alert .wrapper_erreur p strong {
    display: block;
    margin: 0 0 5px 0
}

#lightbox_stock_alert .title_form {
    position: static;
    width: 100%;
    height: 59px;
    line-height: 59px;
    text-align: left;
    text-indent: 1.5em;
    border-bottom: 1px solid #E8E8E8;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.075em
}

#lightbox_stock_alert label {
    font-family: arial;
    font-size: 12px
}

#lightbox_stock_alert input[type="text"] {
    width: 255px;
    height: 27px
}

#lightbox_stock_alert .cgv_alerte_stock {
    font-size: 12px;
    color: #c7c7c7;
    width: 275px;
    margin: 8px 0 0 50px;
    letter-spacing: 1px
}

#lightbox_stock_alert .cgv_alerte_stock span {
    color: #8d8d8d
}

#lightbox_stock_alert .cgv_alerte_stock span.orange {
    color: #FB6601
}

#lightbox_stock_alert .wrapper_validation {
    float: left;
    margin-left: 1rem;
    width: fit-content;

    button {
        height: 27px;
        line-height: 29px;
    }
}

#lightbox_stock_alert .wrapper_validation p {
    padding: 22px 0;
    text-align: center;
    font-family: arial;
    font-size: 12px
}

.hm_blog {
    border-top: 1px solid #c2c2c2;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e5e5e5;
    margin-top: 18px;
    height: 244px
}

span.hm_blog_img_holder {
    float: left;
    width: 410px;
    position: relative;
    margin-right: 30px
}

span.hm_blog_img_holder img {
    display: block
}

.hm_blog_detail {
    float: left;
    padding-top: 20px;
    padding-right: 35px;
    width: 520px
}

p.blog_temps {
    float: left
}

p.blog_a_lire {
    float: right
}

span.blog_date {
    float: left;
    font-size: 43px;
    color: #000;
    padding-right: 4px;
    border-right: 1px solid #e1e1e1;
    line-height: 30px;
    font-weight: 700
}

span.blog_mois {
    float: left;
    padding-left: 5px;
    font-size: 19px;
    color: #000;
    letter-spacing: 0.08em;
    line-height: 15px;
    font-weight: 300
}

span.blog_annee {
    font-size: 12px;
    letter-spacing: 0.2em
}

.blog_a_lire {
    font-size: 11px;
    text-transform: uppercase;
    padding-top: 8px;
    letter-spacing: 0.04em;
    font-weight: 300
}

.blog_a_lire span {
    color: #000;
    font-weight: 700
}

.hm_blog_detail h3 {
    color: #4e4e4e;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 300;
    padding-top: 9px;
    letter-spacing: 0.06em;
    background: url("../img/hm_blog_title_bg.jpg") bottom left no-repeat;
    padding-bottom: 10px
}

.hm_blog_detail_article {
    margin-top: 4px
}

.article_box {
    width: 234px;
    float: left;
    font-size: 13px;
    color: #696969;
    text-align: justify;
    padding-right: 27px;
    background: url("../img/blog_article_sep.jpg") right 10px no-repeat;
    line-height: 16px
}

.article_box.article_right {
    float: right;
    padding-right: 0;
    background: none
}

.article_box span {
    color: #000;
    font-size: 16px
}

.hm_blog_detail_article .media_lien {
    font-size: 10px !important;
    letter-spacing: 0.04em;
    float: right
}

.hm_blog_detail_article .media_lien a {
    text-decoration: none;
    color: #938c86
}

.hm_blog_detail_article .media_lien a:hover {
    color: #000
}

#page {
    z-index: 20;
    bottom: 15px;
    position: absolute
}

#page ul {
    width: 998px;
    text-align: center
}

#page ul li {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url("../img/general_sprite.png") -63px -39px no-repeat;
    text-indent: -9999px;
    margin: 0 3px
}

#page ul li.activPage {
    background: url("../img/general_sprite.png") -49px -39px no-repeat
}

#liste_qte .clone,
#ligne_couleur .clone,
.liste_qte.right_element_prod .clone,
#ligne_taille .prod_listes.right_element_prod .clone {
    width: 132px !important;
    line-height: 27px;
    height: 27px;
    text-align: right;
    box-sizing: border-box;
    padding-right: 36px;
    background-position: 100% center !important;
    border-radius: 0;
    border-color: #ecece8;
    font-size: 11px;
    overflow: hidden
}

#liste_qte .selectStyled select,
#ligne_couleur .selectStyled select,
.liste_qte.right_element_prod .selectStyled select,
#ligne_taille .selectStyled select {
    width: 132px !important;
    height: 27px
}

.fiche_produit_droit {
    float: right;
    width: 465px
}

.stock_delivery_statu {
    margin-left: 10px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 15px;
    padding-bottom: 15px;
    text-transform: uppercase;
    color: #8f8f8f;
    font-size: 9px;
    font-weight: 700;
    letter-spacing: 0.04em
}

.stock_delivery_statu.last {
    border: 0;
    margin-bottom: 0
}

.stock_delivery_statu img {
    float: left
}

.bloc_complet_delivery {
    height: 131px
}

#form_colisage {
    float: left;
    width: 315px
}

#form_colisage #ligne_couleur p.choix {
    color: #757575;
    float: left;
    font-size: 11px;
    line-height: 27px;
    margin-bottom: 7px;
    text-transform: uppercase;
    width: 70px
}

p.alert_disponibilite_pop {
    color: #000;
    border: 1px solid #e6eba9;
    line-height: 20px;
    height: 21px;
    width: 100%;
    box-sizing: border-box;
    color: #000;
    text-transform: uppercase;
    text-align: center;
    font-size: 9px;
    background: url("../img/icon_alerte_stock_pdt.png") 82% 3px no-repeat #c0cd1c;
    padding-right: 10px;
    cursor: pointer;
    font-weight: 700;
    margin: 9px 0
}

.pop_btn_fermer {
    position: absolute;
    top: -10px;
    right: -10px;
    line-height: 0;
    text-indent: 0
}

.send_frd_pdt_details {
    float: right;
    width: 314px
}

.send_frd_pdt_details #product_description {
    font-size: 11px;
    color: #979695
}

.send_frd_pdt_details .product_saleprice {
    font-family: Arial, sans-serif;
    font-weight: 700;
    font-size: 40px;
    color: #282828
}

.send_frd_pdt_details .product_saleprice sup {
    font-family: inherit;
    font-size: 0.5em;
    color: inherit
}

.send_frd_pdt_details .product_saleprice .cents {
    margin-left: -0.5em;
    font-family: inherit;
    font-size: 0.5em;
    color: inherit
}

.send_frd_pdt_details .product_discounted_price {
    margin: 17px 0 0 20px;
    text-decoration: line-through;
    float: left;
    font-size: 20px;
}

.send_frd_pdt_details .product_discounted_price sup,
.send_frd_pdt_details .product_discounted_price span {
    font-size: 10px !important;
}

.send_frd_pdt_details .product_discounted_price sup {
    vertical-align: baseline;
}

#title_destinataire.form_title,
#title_de_la_part.form_title {
    font-size: 16px;
    color: #000;
    text-transform: uppercase;
}

#sendfriend_form_to .w-textarea textarea {
    overflow: hidden;

    .inputFormSite {
        margin: 0;
        width: 412px
    }
}

#sendfriend_form_to input.inputFormSite,
#sendfriend_form_froms input.inputFormSite {
    margin: 0;
    width: 412px;
    margin-bottom: 20px
}


span.plusieur_add {
    font-size: 10px;
    color: #a4a4a4;
    margin-bottom: 20px;
    display: block
}

p.send_frd_btm_txt {
    color: #414243;
    font-size: 12px;
    padding-left: 22px;
    float: left
}

p.send_frd_btm_txt>span {
    color: #979695
}

#content_sendfriendbox {
    position: relative;
    padding-bottom: 29px;
}

p.send_frd_btm_txt span.red {
    font-size: 12px;
    color: #787878
}

#visu_popup_ajout_panier img {
    top: 0 !important;
    width: 291px !important;
    height: 194px !important
}

#wrapper_content_popup_ajout_panier p.add_to_cart_title {
    color: #636363;
    font-size: 35px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 38px;
    padding-bottom: 10px
}

p.btn_ajout_panier {
    padding: 35px 35px 35px 0;
    background: #f8f8f8;
    margin: 0 -35px
}

.look p.btn_ajout_panier {
    margin: 20px 0 0 0
}

p.btn_ajout_panier a.btn_noir {
    width: 236px;
    height: 48px !important;
    line-height: 48px !important;
    background: #636363;
    text-transform: uppercase;
    padding: 0;
    letter-spacing: 0.12em;
    border: 0;
    border-top: 1px solid #c0cd1c;
    text-align: center;
    margin-right: 12px;
    font-size: 10px;
    font-weight: 700
}

p.btn_ajout_panier a.btn_violet {
    width: 203px;
    height: 48px !important;
    line-height: 48px !important;
    text-transform: uppercase;
    padding: 0;
    letter-spacing: 0.12em;
    border: 0;
    border-top: 1px solid #c0cd1c;
    text-align: center;
    background: url("../img/add_to_cart_bg.jpg") no-repeat scroll right top #171717;
    padding-right: 33px;
    font-size: 10px;
    font-weight: 700
}

.ajout_top_holder {
    margin-bottom: 20px
}

#zoomBox #fermer_zoombox.btn_noir {
    position: absolute;
    background: none;
    width: 20px;
    height: 20px;
    border: 0;
    line-height: 0;
    background: url("../img/btn_close_popup.png") no-repeat 0 0;
    top: -10px;
    right: -10px;
    padding: 0
}

.price_ajout_panier {
    color: #282828;
    font-size: 40px;
    position: relative;
    line-height: 42px;
    font-weight: 300
}

.price_ajout_panier span.device {
    font-family: arial;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    top: -6px
}

p.ajout_panier_qty {
    text-transform: uppercase;
    color: #1f1f1f;
    font-size: 13px;
    letter-spacing: 0.03em;
    padding-top: 12px;
    padding-bottom: 15px
}

p.ajout_panier_label {
    height: 29px;
    line-height: 29px;

    font-weight: 400;
    font-size: 11px;
    color: #535353;
    text-transform: uppercase
}

p.ajout_panier_value {
    float: left;
    width: fit-content;
    height: 27px;
    border: 1px solid #ecece8;
    line-height: 27px;
    padding-right: 8px;
    padding-left: 8px;
    text-align: right;
    color: #1f1f1f;
    font-size: 13px;
    margin-bottom: 7px;
    background: #fff
}

a.close_pop_ajout_panier {
    position: absolute;
    width: 20px;
    height: 20px;
    background: url("../img/btn_close_popup.png") no-repeat 0 0;
    top: -10px;
    right: -10px
}

.product_detail_tab_holder.tab_specificite .container_tab {
    color: #979695;
    font-size: 11px;
    letter-spacing: 0.04em
}

.product_detail_tab_holder.tab_specificite strong {
    color: #282828;
    display: block;
    padding-bottom: 2px
}

.product_detail_tab_holder.tab_specificite table {
    padding-bottom: 8px;
    display: block
}

.product_detail_tab_holder.tab_specificite table td {
    min-width: 150px;
    padding-bottom: 2px
}

.obj_gamme .name {
    display: block;
    color: #282828;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 14px;
    padding-bottom: 2px
}

.obj_gamme .description {
    display: block;
    font-size: 11px;
    font-size: 12px;
    color: #949494
}

#zoomBox #bloc_photo_zoom {
    width: 1200px;
    max-width: 100%;
    height: auto
}

.new_gamme .eclat_nb {
    right: 0px;
    top: 168px
}

#content_compte_panier {
    width: auto;
    padding-top: 15px;
    position: relative
}

#content_compte_panier #conteneur_adresse {
    padding-bottom: 0px
}

#content_compte_panier #wrapper_new_adresse #envoyer .btn_violet.btn_light {
    position: absolute;
    right: 4px
}

#content_compte_panier table.addForm {
    width: 100% !important
}

#content_compte_panier table.addForm .main_information {
    padding: 0 !important
}

#content_compte_panier table.addForm h2.titre_carnet_adresse {
    padding-left: 28px
}

a.panier_btn_ajout_adresse {
    margin-right: 28px
}

.panier_btn_retour_add {
    margin-left: 28px;
    margin-top: 15px
}

#middle_boxin_site_map {
    width: 980px;
    margin: 0 auto;
    font-weight: 300;
    font-size: 12px
}

#middle_boxin_site_map .big_title_charte {
    color: #c4d600;
    text-transform: uppercase;
    border: 0;
    font-size: 14px
}

#content_wrapper_myinfos {
    background: #fff;
    border: 1px solid #e7e7e7;
    padding-top: 10px;
    padding-bottom: 20px;
    margin-bottom: 14px;
}

.account_details_holder {
    padding-top: 25px
}

.account_details_holder table td.tab_info_perso td {
    height: 29px;
    vertical-align: middle;
    padding-bottom: 6px
}

.titre_info_perso {
    color: #282828;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    line-height: 35px;
    height: 35px;
    padding-left: 20px;
    background: #f8f8f8
}

#clientForm .tab_info_perso input {
    width: auto;
    margin: 0;
    border: 1px solid #f1f0ee;
    background: #f9f9f9;
    text-indent: 8px;
    width: 234px;
    line-height: 100%
}

#clientForm .tab_info_perso input:focus,
#clientForm .tab_info_perso textarea:focus {
    background-color: #FAFCEB !important
}

#clientForm .tab_info_perso textarea {
    width: 234px;
    border: 1px solid #f1f0ee;
    background: #f9f9f9;
    line-height: 27px;
    height: 27px;
    margin: 0;
    text-indent: 8px
}

#clientForm .detail {
    font-size: 12px;
    color: #999;
}

.tab_info_detail_client {
    font-size: 13px;
    color: #414243;
    border: 1px solid #f1f0ee;
    background: #f9f9f9;
    line-height: 27px;
    height: 27px;
    width: 165px;
    padding: 0 15px
}

.tab_info_detail_client a {
    text-decoration: underline;
    color: #414243
}

.tab_info_perso.tab_right .tab_info_detail_client {
    width: 222px
}

span.integer_carnet {
    background: #fff;
    width: 19px;
    height: 19px;
    border: 2px solid #c4d600;
    display: inline-block;
    border-radius: 50%;
    line-height: 19px;
    text-align: center;
    font-size: 10px;
    margin-right: 8px
}

#content_wrapper_myinfos .tab_info_perso .civilite_box label {
    width: auto;
    display: inline
}

#content_wrapper_myinfos .info_modif_tab_details label {
    font-size: 13px;
    color: #414243;
    text-transform: none;
    cursor: pointer;
}

.client_dob .selectStyled {
    margin-right: 8px
}

.compte_info_tab .client_dob .selectStyled {
    width: 67px;
    border: 1px solid #f1f0ee;
    background: #f9f9f9;
}

.compte_info_tab .client_dob .selectStyled .clone {
    background: url(../img/select_arrow.png) no-repeat scroll 195% center #f9f9f9;
}

.compte_info_tab .client_dob .selectStyled.select_year {
    width: 80px;
}

.compte_info_tab .client_dob .selectStyled.select_year .clone {
    background: url(../img/select_arrow.png) no-repeat scroll 164% center #f9f9f9;
}

.tab_info_perso.modif_tab_right label {
    width: 155px !important
}

#wrapper_adresses #wrapper_moncompte_main {
    background: none
}

#wrapper_adresses #wrapper_moncompte_main #content_compte {
    width: 92%;
    margin: 0 auto;
    float: none !important
}

.mon_compte_top_bloc {
    height: 111px;
    cursor: pointer
}

.mon_compte_icon_holder {
    height: 111px;
    width: 60px;
    background: #f8f8f8;
    float: left
}

.mon_compte_top_right {
    padding-left: 80px;
    padding-top: 20px;
    padding-right: 12px
}

.mon_compte_top_right .btn_address {
    float: right
}

.mon_compte_top_right p {
    font-size: 12px;
    color: #5c5c5c
}

.mon_compte_right_intro {
    float: left;
    width: 660px
}

#bloc_adresses #wrapper_moncompte_main {
    padding-top: 0
}

.mon_compte_ajout_add_tab {
    padding: 30px 0 0 15px;
}

.bloc_adresse_livraison {
    padding: 12px 28px;
    background: #fbfbfb
}

.bloc_adresse_livraison.even {
    background: #fff
}

#conteneur_adresse .bloc_info .title_adresse {
    margin-right: 10px;
    font-size: 17px;
    font-weight: 300;
    color: #000;
}

.mon_bloc_info_garanti {
    padding-left: 80px;
    padding-top: 8px;
    padding-right: 50px;
    font-size: 12px;
    color: #282828
}

.mon_bloc_info_garanti a {
    color: #282828
}

.mon_bloc_info_garanti span {
    font-weight: 700;
    font-size: 13px
}

.tableau_garanti_holder {
    padding: 0
}

.with_border_rgt td {
    border-right: 1px solid #ebeaea
}

.with_border_rgt td.last {
    border: 0
}

#wrapper_new_adresse td {
    padding-bottom: 10px !important
}

span.texte-ajout-adresse {
    color: #8e8e8e;
    margin-left: 82px
}

#wrapper_new_adresse tr.top_titre_add td {
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 18px !important
}

#wrapper_new_adresse td input {
    margin: 0;
    text-indent: 18px;
}

#wrapper_new_adresse td.left_label label {
    float: right;
    line-height: 12px
}

#wrapper_new_adresse td.left_label {
    text-align: right;
    padding-right: 8px
}

#wrapper_new_adresse td.tab_no_pad {
    padding-bottom: 0 !important
}

#wrapper_new_adresse label.txt_civilite {
    text-transform: none !important
}

#wrapper_new_adresse .activation_form_holder label.txt_civilite {
    margin: 0 25px 0 10px !important;
}

.wishlist_details_bloc {
    width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 7px;
    padding-bottom: 29px
}

#wrapper_moncompte_main #content_compte .wishlist_details_bloc .title_produit {
    color: #282828;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: normal;
    padding: 0;
    margin: 0
}

#wrapper_moncompte_main #content_compte .wishlist_details_bloc .sub_title_produit {
    color: #949494;
}

.wish_liste_product {
    margin-right: -56px;
}

.wishlist_details_bloc .product_link {
    border: 1px solid #f0f0f0;
    min-height: 285px;
    width: 243px;
    margin-right: 52px;
    margin-bottom: 29px;
    position: relative
}

.wishlist_details_bloc .product_link a.remove {
    position: absolute;
    top: -12px;
    right: -12px;
    width: 24px;
    height: 24px;
    background: url("../img/icon_wishlist.png") 0 -48px no-repeat
}

.general_main_wrapper.wl_public {
    max-width: 960px;
}

.wishlist_details_bloc .product_link a.check,
.wl_public .product_link a.check {
    position: absolute;
    top: -11px;
    left: -11px;
    width: 24px;
    height: 24px;
    background: url("../img/icon_wishlist.png") 0 -24px no-repeat
}

.wishlist_details_bloc .product_link a.check.unchecked,
.wl_public .product_link a.check.unchecked {
    background: url("../img/icon_wishlist.png") 0 0 no-repeat
}

.wishlist_details_bloc .product_link a.check.unchecked:hover,
.wl_public .product_link a.check.unchecked:hover {
    background: url("../img/icon_wishlist.png") 0 -24px no-repeat
}

.share_wl {
    position: relative;

    span {
        display: none;
    }

    .mail_share:before {
        content: '';
        background: url('../svg/mail_share.svg') no-repeat 0 0;
        background-size: 100% auto;
        width: 26px;
        height: 26px;
        position: absolute;
        right: 0;
    }

    .facebook:before {
        content: '';
        background: url('../img/logo_fb.png') no-repeat 0 0;
        background-size: 100%;
        width: 26px;
        height: 26px;
        position: absolute;
        right: 28px;
    }
}

.share_wl a {
    position: absolute;
    top: 0;
    right: 0;
}

.share_wl a .icon {
    float: left;
    padding-right: 8px
}

.share_wl a.mail_share {
    color: #b5c114
}

.wishlist_details_bloc .conteneur_prix {
    font-size: 31px;
    float: none;
    color: #757575;
    font-weight: 300;
    border-top: 1px solid #f8f8f8;
    position: absolute;
    bottom: 0;
    width: 215px;
    margin-top: 6px
}

.wishlist_details_bloc .conteneur_prix .noir span,
.wishlist_details_bloc .conteneur_prix .prix_barre span {
    font-size: 14px;
    margin-left: 3px
}

.wishlist_details_bloc .conteneur_prix .noir sup,
.wishlist_details_bloc .conteneur_prix .prix_barre sup {
    font-size: 14px;
    position: absolute;
    top: 4px;
    margin-left: 3px
}

.wishlist_details_bloc .conteneur_prix .noir span.chf,
.wishlist_details_bloc .conteneur_prix .prix_barre span.chf {
    margin-left: 0;
}

.wishlist_details_bloc .conteneur_prix .noir sup.chf,
.wishlist_details_bloc .conteneur_prix .prix_barre sup.chf {
    font-size: 14px;
    position: relative;
    top: 0;
    vertical-align: baseline;
    margin-left: 3px
}

.wishlist_details_bloc .conteneur_prix .decimal_price {
    font-size: 12px;
    margin-left: 3px
}

.wishlist_details_bloc .wrapper_infos_produit {
    padding: 0 14px;
    padding-top: 5px
}

.selection_pdt_wishlist {
    float: left
}

.tout_selectione {
    margin-right: 1px
}

.tout_selectione a {
    background: #e8e8e8;
    display: block;
    width: 184px;
    height: 36px;
    line-height: 36px;
    font-size: 10px;
    text-transform: uppercase;
    color: #000;
    text-align: center;
    letter-spacing: 0.12em;
    text-decoration: none;
    font-weight: 700
}

.article_selectione_nombre {
    height: 50px;
    line-height: 34px;
    width: 197px;
    text-align: center;
    font-size: 12px;
    color: #666;
    border: none;
    letter-spacing: .06em;
    display: flex;
    align-items: center;

    &.public {
        width: auto;

        .wish_price {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            padding: 0;
            margin: 0 0 0 5px;
        }
    }
}

.wish_price {
    background: #fafafa;
    height: 40px;
    margin-top: 6px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    box-sizing: border-box;
    padding: 0 16px;
    color: #414243;
    position: relative
}

.wish_price #total_price {
    float: right;
    font-size: 26px
}

.wish_price #total_price span.devise {
    font-size: 14px;
    position: absolute;
    margin-left: 3px;
    top: 3px;
    line-height: 19px
}

.wishlist_details_bloc .conteneur_prix .prix_barre .devise {
    font-size: 10px;
    top: 13px
}

.wish_price #total_price .decimal_price {
    font-size: 11px;
    margin-left: 3px
}

a.wishpage_wishlistAddToCart {
    display: block;
    background: #2b2b2b;
    height: 50px;
    width: 235px;
    cursor: pointer;
    margin-bottom: 1rem;

    span {
        width: 100%;
        display: block;
        color: #fff;
        font-family: $primaryFont;
        font-weight: 400;
        font-size: 17px;
        text-align: center;
        text-transform: uppercase;
        line-height: 48px;
    }
}

.f_right {
    float: none;
}

#selectAll {

    background-color: $white;
    border: 1px solid #000;
    font-size: 17px;
    font-weight: 400;
    height: 50px;
    max-height: 50px;
    width: 235px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-right: 20px;
    margin-bottom: 2rem;

    span {
        font-family: $primaryFont;
        font-weight: 400;
        font-size: 17px;
        color: #2b2b2b;
        border: none;
        text-transform: uppercase;
    }
}

#sendwishlistbox {
    background: #fff;
    width: 696px;
    z-index: 999;
    margin-left: -348px;
    left: 50%;
    position: absolute;
    top: 10%;
    padding-bottom: 70px;
    box-shadow: 0 0 20px #111
}

#content_sendwishlist .popup_subtitle {
    text-align: left;
    text-indent: 44px;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    color: #121212;
    float: none;
    margin: 0;
    letter-spacing: 0.15em;
    padding-top: 22px;
    padding-bottom: 4px
}

.choose_theme .choice_elem .visu .img {
    width: 172px;
    height: 136px;
    display: inline-block;
    margin-top: 60px;
    border: 3px solid #fff
}

.choose_theme .choice_elem.select .visu .img {
    border: 3px solid #c0cd1c
}

.choice_1 .visu .img {
    background: url(../img/wish_idee_cadeau.png) no-repeat top left
}

.choice_2 .visu .img {
    background: url(../img/wish_idee_shopping.png) no-repeat top left
}

.choose_theme .choice_elem {
    width: 303px;
    cursor: pointer;
    float: left;
    height: 275px;
    background: #f2f2f2;
    position: relative
}

.choose_theme .choice_elem.choice_2 {
    background: #f8f8f8
}

.choice_elem .text {
    text-align: center;
    color: #121212;
    font-size: 13px;
    text-transform: uppercase;
}

.choice_elem .select_btn {
    width: 17px;
    height: 17px;
    background: url(../img/wish_choix_pop.png) no-repeat top left;
    margin-right: 7px;
    position: absolute;
    top: 53px;
    left: 55px
}

.choice_elem.select .select_btn {
    background-position: 0 -17px
}

.choice_elem.select .text .txt_title {
    color: #131313
}

.choice_elem .visu {
    text-align: center
}

.choose_theme {
    padding-left: 44px
}

#content_sendwishlist .form_elem .popup_subtitle {
    font-size: 16px;
    color: #000;
    padding-bottom: 10px;
    text-indent: 0;
    font-weight: 300
}

#content_sendwishlist .form_elem {
    padding-left: 78px
}

#content_sendwishlist .form_elem label {
    width: 112px;
    float: left;
    color: #787878;
    font-size: 11px;
    text-transform: uppercase;
    line-height: 26px;
    letter-spacing: 0.06em;
    text-align: right;
    padding-right: 8px;
    font-weight: 300
}

#content_sendwishlist .form_elem .line {
    padding-bottom: 15px
}

#content_sendwishlist .form_elem input {
    width: 410px;
    height: 26px;
    line-height: 26px;
    border: 1px solid #eeeeee;
    text-indent: 5px
}

#content_sendwishlist .form_elem textarea {
    width: 410px;
    height: 93px;
    border: 1px solid #eeeeee
}

.send_wish_btn_holder {
    margin-right: 86px;
    width: 143px;
    height: 48px
}

#content_sendwishlist .send_elem .send_btn {
    width: 143px;
    line-height: 48px;
    height: 48px;
    background: url(../img/ico_send_to_frd_bg.png) no-repeat right #1f1f1f;
    border-top: 1px solid #c4d600;
    text-transform: uppercase;
    color: #fff;
    font-size: 10px;
    text-align: center;
    letter-spacing: 0.12em;
    padding-right: 20px;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 700
}

#content_sendwishlist .send_elem .g-recaptcha {
    justify-content: flex-start;
}

#content_sendwishlist .send_elem .info_oblig {
    font-size: 12px;
    color: #787878;
    padding-left: 78px;
    letter-spacing: 0.06em;
    padding-top: 10px
}

.wishlist_recevoir_copie_mail label {
    padding-left: 10px;
    color: #414243;
    cursor: pointer;
}

.wishlist_recevoir_copie_mail input {
    margin-top: 1px
}

#content_sendwishlist .send_elem .info_oblig span.info_txt {
    display: block;
    padding-left: 21px;
    padding-top: 2px
}

#content_sendwishlist p.txt_err_sendfriend {
    padding-left: 78px;
    font-size: 12px;
    color: #f00;
    padding-bottom: 10px
}

span.wish_multiple_emails {
    display: block;
    color: #a4a4a4;
    font-size: 10px;
    letter-spacing: 0.04em;
    padding-left: 121px;
    padding-top: 1px
}

#bloc_wishlist .no_stock {
    position: absolute;
    top: 1px;
    left: 0
}

.wl_public_sub_tilte {
    padding: 25px 0
}

.wl_public_sub_tilte h2 {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.005em;
    text-transform: uppercase;
    padding-bottom: 8px
}

.wl_public_sub_tilte p {
    color: #5c5c5c;
    font-size: 12px
}

.wl_public .product_link {
    border: 1px solid #f0f0f0;
    margin-bottom: 29px;
    margin-right: 52px;
    min-height: 340px;
    position: relative;
    width: 243px
}

.wl_public .conteneur_prix {
    bottom: 0;
    float: none;
    font-weight: 600;
    font-size: 30px;
    color: #2b2b2b;
    margin-top: 6px;
    position: absolute;
    right: 0;
    width: fit-content;

    sup {
        font-size: .45em;
        font-weight: 500;
    }
}

.wl_public .conteneur_prix span.cents {
    font-size: .45em;
    font-weight: 500;
}

.wl_public .wrapper_infos_produit {
    padding: 5px 14px 0
}

.wl_public .wrapper_infos_produit .title_produit {
    color: #282828;
    font-size: 13px;
    letter-spacing: normal;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.wl_public .wrapper_infos_produit .sub_title_produit {
    color: #525051;
    font-size: 12px;
    text-transform: initial;
}

.wl_public a.remove {
    background: url("../img/icon_wishlist.png") no-repeat 0 -48px;
    height: 24px;
    position: absolute;
    right: -12px;
    top: -12px;
    width: 24px
}

.wl_public .conteneur_prix .devise {
    font-size: 14px;
    margin-left: 3px;
    position: absolute;
    top: 4px
}

.select_colors .couleur_check {
    background-image: none !important;
    width: 9px;
    height: 9px
}

.select_colors .couleur_check:hover {
    border: 1px solid #000
}

.select_colors .couleur_1 .couleur_check {
    background-color: #FFFFFF !important;
    border: 1px solid #b3b3b3;
    height: 8px;
    width: 8px
}

.select_colors .couleur_2 .couleur_check {
    background-color: #222222 !important
}

.select_colors .couleur_3 .couleur_check {
    background-color: #F3DCA2 !important
}

.select_colors .couleur_4 .couleur_check {
    background-color: #0099CC !important
}

.select_colors .couleur_5 .couleur_check {
    background-color: $textlightGrey !important
}

.select_colors .couleur_6 .couleur_check {
    background-color: #FFE400 !important
}

.select_colors .couleur_7 .couleur_check {
    background-color: #737306 !important
}

.select_colors .couleur_8 .couleur_check {
    background-color: #75600d !important
}

.select_colors .couleur_9 .couleur_check {
    background-color: #FF8400 !important
}

.select_colors .couleur_10 .couleur_check {
    background-color: #FF5E9F !important
}

.select_colors .couleur_11 .couleur_check {
    background-color: #fa0d29 !important
}

.select_colors .couleur_12 .couleur_check {
    background-color: #17db78 !important
}

.select_colors .couleur_13 .couleur_check {
    background-color: #7800FF !important
}

.select_colors .couleur_14 .couleur_check {
    background: url("../img/picto/color_14.jpg") repeat scroll center center transparent
}

.select_colors .couleur_16 .couleur_check {
    background: url("../img/color_or.jpg") repeat scroll 0 0 transparent
}

.remove_prod.f_right {
    cursor: pointer
}

.login_form_picto_holder {
    position: relative
}

.bloc_obj .left_menu_obj.btn_select,
.bloc_obj .left_menu_obj.btn_select_off {
    float: none;
    height: auto;
    width: auto
}

.btn_select_off:hover .couleur_check,
.btn_select .couleur_check {
    border: 1px solid #000;
    width: 8px;
    height: 8px
}

.btn_select_off:hover .txt_menu,
.btn_select .txt_menu,
.btn_select .txt_menu a {
    color: #000
}

.btn_select .txt_menu a {
    color: #000 !important
}

.wrapper_toTop {
    bottom: 0;
    left: 50%;
    margin: 0 0 0 -370px;
    position: absolute;
    width: 938px;
    z-index: 1
}

.content_toTop {
    margin: 0 0 0 34px
}

#alertbox1 {
    width: 478px;
    height: 246px;
    position: absolute;
    background: #fff;
    top: 300px;
    margin: -123px 0 0 -239px;
    z-index: 99999;
    left: 50%;
    top: 41%
}

#alertbox1 .txt_alert {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 131px;
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #535353;
    letter-spacing: 0.13em
}

#alertbox1 .wrapper_btn {
    height: 115px;
    padding: 31px 0 0 0;
    box-sizing: border-box;
    background: #f4f4f4
}

#alertbox1 .btn_alert {
    width: 187px;
    height: 48px;
    display: block;
    margin: 0 auto;
    background: #000;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    border-top: 1px solid #c4d600;
    text-decoration: none;
    line-height: 48px;
    text-align: center
}

.look_detail #alertbox1,
.fiche_produit #alertbox1 {
    width: 478px;
    padding: 0;
    z-index: 10001
}

.look_detail #alertbox1 .txt_alert,
.fiche_produit #alertbox1 .txt_alert {
    padding: 47px 35px 24px;
    border-top: none;
    height: auto;
    text-align: center;
    background: #f4f4f4;
    border-bottom: 1px solid #e7e7e7;
    line-height: normal
}

.look_detail #alertbox1 .txt_alert .light_title,
.fiche_produit #alertbox1 .txt_alert .light_title {
    text-transform: uppercase;
    letter-spacing: 0.01em;
    font-size: 18px;
    color: #535353;
    margin: 0 0 15px 0;
    font-weight: 700
}

.look_detail #alertbox1 .txt_alert p,
.fiche_produit #alertbox1 .txt_alert p {
    font-size: 13px;
    letter-spacing: 0.02em;
    color: #757575
}

.look_detail #alertbox1 .wrapper_btn,
.fiche_produit #alertbox1 .wrapper_btn {
    padding: 36px 24px 35px;
    width: auto;
    height: auto
}

.look_detail #alertbox1 .wrapper_btn a,
.fiche_produit #alertbox1 .wrapper_btn a {
    width: 187px;
    text-align: center;
    padding: 13px 0;
    text-decoration: none;
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 18px;
    height: 36px
}

.look_detail #alertbox1 .wrapper_btn a.btn_connect,
.fiche_produit #alertbox1 .wrapper_btn a.btn_connect {
    background: #414243
}

.look_detail #alertbox1 .wrapper_btn a.btn_close,
.fiche_produit #alertbox1 .wrapper_btn a.btn_close {
    background: #b2b2b2
}

.look_detail #alertbox1 .rosette,
.fiche_produit #alertbox1 .rosette {
    background: url(../img/rosette.jpg) no-repeat top center;
    height: 12px;
    margin-top: -1px
}

#scroll_loader,
#filter_loader {
    display: block;
    text-align: center;
}

.page_resultats .bloc_ss_cat {
    overflow: visible
}

#orders_by_type .order_detail #content_wrapper,
#orders_by_type .order_detail #content_wrapper #content_compte {
    width: auto !important
}

.tipsy {
    font-size: 10px;
    position: absolute;
    padding: 5px;
    z-index: 100000;
    margin-left: 12px
}

.tipsy-inner {
    background-color: #fff;
    color: #FFF;
    width: 232px;
    padding: 10px 14px;
    text-align: left;
    border: 1px solid #a6a6a6;
    box-sizing: border-box;
    color: #918d86;
    font-size: 12px
}

.tipsy-inner p.title_toolltip {
    text-transform: uppercase;
    color: #ff4266
}

.tipsy-arrow {
    position: absolute;
    background: url(../img/tipsy_arrow.png) no-repeat top center;
    width: 10px;
    height: 16px
}

.tipsy-arrow-n {
    border-bottom-color: #000
}

.tipsy-arrow-s {
    border-top-color: #000
}

.tipsy-arrow-e {
    border-left-color: #000
}

.tipsy-n .tipsy-arrow {
    top: 0px;
    left: 50%;
    margin-left: -5px;
    border-bottom-style: solid;
    border-top: none;
    border-left-color: transparent;
    border-right-color: transparent
}

.tipsy-nw .tipsy-arrow {
    top: 0;
    left: 10px;
    border-bottom-style: solid;
    border-top: none;
    border-left-color: transparent;
    border-right-color: transparent
}

.tipsy-ne .tipsy-arrow {
    top: 0;
    right: 10px;
    border-bottom-style: solid;
    border-top: none;
    border-left-color: transparent;
    border-right-color: transparent
}

.tipsy-s .tipsy-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-top-style: solid;
    border-bottom: none;
    border-left-color: transparent;
    border-right-color: transparent
}

.tipsy-sw .tipsy-arrow {
    bottom: 0;
    left: 10px;
    border-top-style: solid;
    border-bottom: none;
    border-left-color: transparent;
    border-right-color: transparent
}

.tipsy-se .tipsy-arrow {
    bottom: 0;
    right: 10px;
    border-top-style: solid;
    border-bottom: none;
    border-left-color: transparent;
    border-right-color: transparent
}

.tipsy-e .tipsy-arrow {
    right: 0;
    top: 50%;
    margin-top: -5px;
    border-left-style: solid;
    border-right: none;
    border-top-color: transparent;
    border-bottom-color: transparent
}

.tipsy-w .tipsy-arrow {
    left: -4px;
    top: 50%;
    margin-top: -8px
}

#left_menu_cat .btn_select div:first-child,
#left_menu_cat .btn_select_off div:first-child {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0)
}

#wrapper_messages .message_border {
    cursor: pointer
}

span.txt_avantage_paypal {
    color: #000000;
    letter-spacing: 0.1em;
    font-size: 11px;
    text-decoration: underline;
    text-transform: none
}

#bloc_paypal .show_what_is_it .text {
    width: 520px;
    min-height: 358px;
    padding: 9px 20px;
    padding-bottom: 12px;
    border: 1px solid #D5D5D5;
    background: url(../img/popover_bg_paypal.jpg) top left repeat-x #E9E9E9;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 1px 0 7px -1px #aaa;
    text-transform: none;
    font-family: Tahoma, Geneva, sans-serif;
    letter-spacing: normal;
    z-index: 99
}

#bloc_paypal .show_what_is_it .arrow {
    float: none;
    height: 16px !important;
    width: 26px;
    background: url("../img/arrow_left_info_bank_paypal.png") no-repeat right center;
    position: absolute;
    left: 100px;
    bottom: -15px
}

.popover_paypal_intro {
    padding-top: 8px;
    line-height: normal;
    padding-bottom: 13px
}

p.paypal_intro_title {
    color: #757575;
    font-size: 16px;
    padding-bottom: 5px
}

p.paypal_intro_detail {
    color: #757575;
    font-size: 11px
}

ul.paypal_features_list li {
    list-style: none;
    padding-left: 55px;
    padding-bottom: 20px;
    letter-spacing: normal;
    line-height: normal
}

ul.paypal_features_list li.last {
    padding-bottom: 0
}

ul.paypal_features_list li.paypal_securite {
    background: url("../img/paypal_icone_securite.png") no-repeat top left
}

ul.paypal_features_list li.paypal_simple {
    background: url("../img/paypal_icone_simple.png") no-repeat top left
}

ul.paypal_features_list li.paypal_gartuit {
    background: url("../img/paypal_icone_gratuit.png") no-repeat top left
}

.login_bloc_holder td.btm_form_holder input.inputErr,
.login_bloc_holder td.btm_form_holder .inputErr,
.login_bloc_holder td.btm_form_holder .inputForm {
    width: 265px;
    margin-top: 0
}

.details_commandes_montant_box table .montant_commande.detail_commande_tab_promo {
    background: #fff !important
}

#conteneur_adresse .tunnel_btn_retour.panier_btn_retour_add {
    background: url("../img/arrow_grey_bg.png") no-repeat 20px 10px #636363;
    color: #fff
}

.message_detail_pj {
    float: left;
    padding-top: 21px
}

.message_detail_pj .objet>label {
    display: inline-block;
    width: 93px
}

.ajout_pj_label {
    float: left;
    padding-left: 32px;
    color: $textlightGrey;
    font-size: 11px;
    font-weight: normal;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    margin-bottom: 10px
}

.message_details_btn_bloc input.message_file {
    margin: 0 !important
}

.mes_err_top_info {
    margin-bottom: 15px;
    padding: 5px;
    border: 1px solid $textlightGrey
}

.btn_modif_mot_passe {
    display: block;
    border: 0;
    background: #171717;
    color: #fff;
    float: none;

    font-size: 10px;
    height: 31px !important;
    letter-spacing: 0.18em;
    line-height: 31px !important;
    margin: 0 auto;
    text-align: center;
    width: 100px;
    margin-top: 10px;
    cursor: pointer;
    font-weight: 700;
    text-decoration: none;
    text-indent: 0
}

ul.cookie_list_02 {
    padding-top: 15px;
    padding-left: 25px;
    padding-bottom: 15px
}

ul.cookie_list_02.list_3 {
    padding-bottom: 0
}

ul.cookie_list_02 li {
    list-style: disc
}

#prix_par_piece {
    clear: both;
    margin: 0 0 5px
}

.restock_date {
    text-align: left;
    margin-bottom: 5px;

    font-size: 13px;
    color: #FF6460;
    margin: 10px 0 0 0;
}

#cookies_block_footer {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    height: 130px;
    text-align: center;
    background-color: #F9F9F9;
    padding-top: 30px;
    border-top: 4px solid #c4d600;
    font-size: 12px;
    box-sizing: border-box;
    z-index: 9999
}

.cookies_bloc_txt {
    width: 989px;
    margin: 0 auto;
    position: relative
}

.cookies_txt {
    display: inline-block;
    width: 923px;
    line-height: 20px
}

.cookies_btn {
    background: #000;
    color: #fff;
    width: 47px;
    height: 30px;
    border-radius: 0;
    line-height: 30px;
    text-align: center;
    display: block;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 0;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase
}

#page_cookies_info p {
    margin: 0;
    text-align: justify
}

#page_cookies_info ul {
    margin: 0;
    text-align: justify;
    margin-left: 34px;
    padding-bottom: 10px
}

#page_cookies_info h2 {
    color: #000;
    font-size: 20px;
    font-weight: normal;
    margin: 0;
    text-transform: uppercase;
    font-weight: 700
}

.wrapper_page_cookie {
    padding-bottom: 75px
}

.wrapper_page_cookie>span {
    display: block;
    margin: 0;
    font-weight: 700;
    font-size: 14px;
    padding-bottom: 12px
}

.wrapper_page_cookie li {
    padding: 0 0 8px;
    vertical-align: middle;
    white-space: normal;
    color: #000;
    text-decoration: none;
    font-size: 13px
}

.cookie_small_info {
    font-size: 14px;
    color: #000;
    padding-bottom: 4px
}

.cookie_en_savoir {
    font-weight: 700;
    font-size: 14px;
    color: #000;
    text-transform: uppercase;
    padding-top: 5px;
    display: inline;
    border-bottom: 2px solid #000;
    padding-bottom: 1px
}

.cookie_en_savoir a {
    color: #000;
    text-decoration: none
}

.eco_diff {
    margin-bottom: 5px;

    font-size: 14px;
    color: #808080
}

.prix_par_piece_rayon {
    font-size: 12px;
    margin: 18px 0 0 0;
    font-weight: 300
}

.prix_par_piece_ranges {
    font-size: 12px;
    margin: 17px 0 0 15px;
    font-weight: 300;
    color: #777777
}

.wrapper_inscription_privilege {
    width: 100%;
    border: 1px solid #f4f4f4;
    padding: 40px 0 0 0
}

.titre_privilege_1,
.titre_privilege_2 {
    width: 831px;
    margin: 0 auto;
    font-size: 16px;
    color: #c4d600;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700
}

.titre_privilege_2 {
    font-size: 15px;
    margin: 30px auto 0 auto
}

.content_privilege_1 {
    width: 870px;
    height: 234px;
    margin: 28px auto 0 auto;
    background: transparent url(../img/privilege/bg_header_inscription_privilege.jpg) no-repeat left top
}

.content_privilege_1 .liste_offres_txt {
    font-weight: 300;
    font-size: 23px;
    color: #000;
    text-transform: uppercase;
    margin: 0 0 0 460px;
    padding: 14px 0 0 0
}

.content_privilege_1 .liste_offres_txt span {
    font-weight: 300;
    font-size: 13px;
    color: #8b8b8b;
    text-transform: none;
    display: block
}

.content_privilege_1 .liste_offres_txt p {
    padding: 0 0 18px 0
}

.content_privilege_1 .liste_offres_txt p.second {
    padding: 0 0 28px 0
}

.content_privilege_1 .conditions {
    padding: 0 0 0 402px
}

.content_privilege_1 .conditions a {
    font-size: 12px;
    color: #8b8b8b;
    text-decoration: underline
}

.content_privilege_1 .conditions a:hover {
    text-decoration: none
}

.txt_privilege_1 {
    width: 831px;
    margin: 0 auto;
    padding: 10px 0 0 0;
    font-size: 13px;
    color: #585858;
    line-height: 16px
}

.wrapper_form_privilege {
    width: 831px;
    margin: 20px auto 0 auto;
    background: #f9f9f9;
    padding: 35px 0 15px 0
}

.wrapper_form_privilege .line {
    width: 736px;
    margin: 0 auto 15px auto
}

.wrapper_form_privilege .line.last {
    margin: 0 auto
}

.wrapper_form_privilege .line .section {
    width: 345px;
    line-height: 27px
}

.wrapper_form_privilege .line label {
    width: 95px;
    text-align: right;
    display: block;
    float: left;
    margin: 0 5px 0 0;
    text-transform: uppercase
}

.wrapper_form_privilege .line input {
    width: 243px;
    height: 27px;
    border: 1px solid #ededed;
    text-indent: 10px
}

.wrapper_form_privilege #demande_carte {
    width: 243px;
    line-height: 33px;
    height: 33px;
    text-align: center;
    background: #171717;
    color: #fff;
    text-transform: uppercase;
    font-size: 10px;
    display: block;
    text-decoration: none;
    float: right;
    border-top: 1px solid #c4d600;
    letter-spacing: 0.12em;
    cursor: pointer;
    font-weight: 700
}

#popup_confirm_privilege {
    position: fixed;
    width: 570px;
    height: 262px;
    top: 50%;
    left: 50%;
    margin: -131px 0 0 -285px;
    background: #fff;
    -moz-box-shadow: 0px 0px 20px #111;
    -webkit-box-shadow: 0px 0px 20px #111;
    box-shadow: 0px 0px 20px #111;
    font-size: 13px;
    color: #121212;
    display: none;
    z-index: 5000
}

#popup_confirm_privilege .title {
    font-size: 15px;
    color: #4e4e4e;
    text-align: center;
    line-height: 94px;
    border-bottom: 1px solid #e8e8e8;
    width: 420px;
    margin: 0 auto;
    letter-spacing: 1px
}

#popup_confirm_privilege p {
    margin: 30px 0;
    text-align: center;
    letter-spacing: 1px
}

#popup_confirm_privilege a {
    width: 143px;
    line-height: 48px;
    height: 48px;
    text-align: center;
    background: #171717;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    display: block;
    text-decoration: none;
    border-top: 1px solid #c4d600;
    letter-spacing: 0.12em;
    margin: 0 auto
}

#wrapper_carte_privilege .mon_compte_top_right p a {
    font-size: 12px;
    color: #5c5c5c
}

.bloc_carte_privilege {
    display: none;
    width: 825px;
    margin: 0 auto;
    padding: 10px 0 20px 0
}

.txt_acrivation_carte {
    position: relative;
    font-size: 17px;
    color: #494847;
    letter-spacing: 1px
}

.txt_acrivation_carte img {
    vertical-align: -4px;
    margin: 0 0 0 4px
}

.rollover_info_carte {
    width: 184px;
    height: 117px;
    position: absolute;
    left: 422px;
    bottom: -7px;
    display: none
}

.wrapper_activation {
    width: 574px;
    padding: 20px 0 10px 0;
    background: #fbfbfb;
    margin: 18px 0 0 0
}

.wrapper_activation label {
    font-size: 12px;
    line-height: 30px;
    color: $textlightGrey;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0 0 0 38px
}

.wrapper_activation #num_carte_privilege {
    width: 333px;
    height: 38px;
    border: 1px solid #ededed;
    margin: 0 0 0 38px
}

.wrapper_activation #num_carte_privilege.error {
    border: 1px solid red
}

.wrapper_activation #submit_num_carte {
    width: 139px;
    line-height: 39px;
    height: 39px;
    text-align: center;
    background: #171717;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    display: block;
    text-decoration: none;
    border: none;
    border-top: 1px solid #c4d600;
    letter-spacing: 0.12em;
    cursor: pointer;
    margin: 0 38px 0 0
}

.wrapper_activation p {
    font-family: arial;
    font-size: 11px;
    color: #585858;
    margin: 10px 0 0 38px
}

.intro_recap_carte .visu {
    margin: 0 0 0 30px
}

.intro_recap_carte .txt {
    font-weight: 300;
    font-size: 14px;
    color: #000;
    margin: 30px 0 0 80px
}

.intro_recap_carte .txt a:hover {
    text-decoration: none
}

.recap_carte_privilege .title {
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
    color: #c4d600;
    margin: 30px 0 15px 0
}

.recap_carte_privilege .title span {
    display: block;
    float: left;
    width: 27px;
    height: 27px;
    background: transparent url(../img/privilege/icos.gif) no-repeat left top;
    margin: -5px 10px 0 0
}

.recap_carte_privilege .bloc_offres {
    padding: 0 0 44px 0;
    border-bottom: 1px solid #dadada
}

.recap_carte_privilege .bloc_connaitre .title span {
    background-position: left bottom
}

.recap_carte_privilege .bloc_offres table {
    width: 100%;
    border-collapse: collapse
}

.recap_carte_privilege .bloc_offres table .header {
    border: 1px solid #dedede
}

.recap_carte_privilege .bloc_offres table .header td {
    height: 25px;
    background: #fbfbfb;
    font-size: 13px;
    color: #000;
    text-transform: lowercase;
    text-transform: capitalize
}

.recap_carte_privilege .bloc_offres table .content td {
    height: 54px;
    font-size: 12px;
    color: #000;
    border: 1px solid #e7e7e7
}

.recap_carte_privilege .bloc_offres table .content td span {
    font-size: 15px;
    display: block
}

.recap_carte_privilege .bloc_offres table .content td.first {
    padding: 0 0 0 15px
}

.recap_carte_privilege .bloc_offres a {
    font-size: 12px;
    color: #000;
    margin: 10px 0 0 0
}

.recap_carte_privilege .bloc_connaitre .intro {
    font-weight: 300;
    font-size: 13px;
    color: #494847;
    letter-spacing: 1px;
    margin: 0 0 20px 0
}

#connaitre_form .champs {
    width: 335px
}

#connaitre_form .line {
    height: 42px
}

.recap_carte_privilege .bloc_connaitre label {
    font-size: 12px;
    color: $textlightGrey;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: block;
    width: 210px;
    line-height: 25px
}

.recap_carte_privilege .bloc_connaitre .champs label {
    font-weight: 300;
    font-size: 12px;
    color: $textlightGrey;
    letter-spacing: 1px;
    text-transform: none;
    display: block;
    float: left;
    width: 75px;
    line-height: 28px;
    margin: 0 0 0 8px
}

.recap_carte_privilege .bloc_connaitre select {
    width: 218px;
    height: 25px;
    color: $textlightGrey
}

.recap_carte_privilege .bloc_connaitre .clone {
    width: 218px;
    height: 25px;
    line-height: 25px;
    border: 1px solid #f1f1f1;
    background: #fff url(../img/select_arrow_standar.jpg) no-repeat 200px center;
    text-align: center;
    font-size: 12px;
    color: $textlightGrey
}

#connaitre_form .checkbox {
    margin: 8px 0 0 0
}

#send_connaitre_form,
#retour_boutiques {
    width: 139px;
    line-height: 39px;
    height: 39px;
    text-align: center;
    background: #171717;
    color: #fff;
    text-transform: uppercase;
    font-size: 10px;
    display: block;
    text-decoration: none;
    border: none;
    letter-spacing: 0.12em;
    text-indent: 0;
    cursor: pointer;
    margin: 50px 0 0 0;
    font-weight: 700
}

#retour_boutiques {
    width: 239px;
    margin: 20px auto 40px auto
}

#mes_err_fieldset,
#mes_err {
    display: none;
    width: 640px;
    margin: 20px auto;
    font-size: 12px;
    color: #000;
    letter-spacing: 1px;
    padding: 10px
}

#mes_err_fieldset legend,
#mes_err legend {
    color: red;
    font-size: 13px
}

#satisfaction_intro {
    width: 820px;
    margin: 20px auto 60px auto;
    font-size: 13px;
    color: #000;
    line-height: 18px;
    font-size: 13px;
    color: #000;
    line-height: 20px
}

#satisfaction_intro h3 {
    font-size: 15px;
    text-transform: uppercase;
    color: #c4d600;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 20px 0 30px 0
}

h2.titre.satisfaction span.ico {
    background-position: -198px 0
}

.wrapper_satisaction h3 {
    font-weight: 700;
    color: #000;
    font-size: 12px;
    text-transform: uppercase
}

.cadre_question {
    width: 780px;
    margin: 0 auto 20px auto
}

.cadre_question td {
    height: 38px
}

.subtitles_questionnaire {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    height: 36px;
    background: transparent url(../img/filet_question_satisfaction.gif) repeat-x left center
}

.subtitles_questionnaire span {
    background: #f9f9f9;
    padding: 8px 50px;
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    color: #c4d600
}

.cadre_question.satisfaction_title_08 {
    display: none
}

.cadre_question.satisfaction_title_08 tr td.graduationForm,
.cadre_question.satisfaction_title_11 tr td.graduationForm {
    position: relative;
    float: left;
    left: 40px;
    width: 486px;
    height: 23px;
    background-repeat: no-repeat
}

.satisfaction_smiley {
    position: relative;
    left: 33px
}

.satisfaction_smiley.libmiddle {
    position: relative;
    left: 221px
}

.satisfaction_smiley.libright {
    position: relative;
    left: 408px
}

#slider_serviceclient,
#slider_recommandation {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    border: medium none;
    cursor: pointer;
    height: 37px;
    left: 41px;
    position: relative;
    top: 4px;
    width: 420px
}

#slider_serviceclient a,
#slider_recommandation a {
    background-color: transparent;
    border: medium none;
    border-radius: 0;
    cursor: pointer;
    height: 37px;
    margin-left: -13px;
    width: 30px
}

#satisfactionForm .label_radio {
    margin: 0 35px 0 5px;
    display: block;
    font-size: 14px;
    color: #000
}

.btn_valider_questionnaire {
    width: 164px;
    line-height: 35px;
    height: 35px;
    text-align: center;
    background: #171717;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    display: block;
    text-decoration: none;
    border: none;
    border-top: 1px solid #c4d600;
    letter-spacing: 0.12em;
    cursor: pointer;
    margin: 30px auto
}

.wrapper_satisaction #mes_err {
    width: 640px;
    margin: 10 auto 20px auto
}

.erreur_tab.satisfaction li {
    list-style: none;
    color: #F00;
    background: transparent url(../img/picto_panneau.png) no-repeat left 2px;
    text-indent: 26px;
    line-height: 25px
}

.erreur_txt.satisfaction {
    margin: 0 0 20px
}

.script_market {
    position: fixed;
    height: 1px;
    width: 1px
}

div.wrapper_form_privilege div.errors {
    font-weight: bold;
    color: red;
    padding-left: 25px;
    margin-bottom: 25px;
    text-align: center
}

p.already_asked {
    text-align: center;
    font-weight: bold;
    margin: 20px 0
}

.garantie_num_cristal {
    vertical-align: -18px;
    margin: 0 0 0 10px;
}

.garantie_num_tel_PT {
    display: none
}

.pt .choix_login span {
    display: block;
    padding-top: 6px
}

.pt .detail_msg_title {
    background: url("../img/detail_msg_titre.png") no-repeat scroll 240px 1px;
    width: 272px
}

.pt a#ajax_perso_edit_form {
    background: url("../img/edit_compte_info_icon.png") no-repeat 239px 11px #171717;
    padding-left: 10px;
    width: 245px;
    text-align: left
}

.pt .btn_violet.detail_moncompte.mon_compte_detail_btn {
    background: url("../img/mes_commande_btnplus.png") no-repeat 133px 10px #e8e8e8;
    width: 148px
}

.pt .btn_violet.detail_moncompte.mon_compte_detail_btn.actif {
    width: 148px;
    height: 28px;
    font-size: 10px;
    color: #010101;
    border: 0;
    line-height: 28px;
    background: #c0cd1c url("../img/details_commande_fermer.png") no-repeat 133px 13px;
    letter-spacing: 0.05em;
    padding: 0;
    padding-right: 11px;
    box-sizing: border-box
}

.pt .tunel_tab_btn .boutons_panier a.btn_violet {
    background: url("../img/add_to_cart_bg.jpg") no-repeat 187px top #171717;
    padding-right: 15px
}

.pt .garantie_num_cristal {
    display: none
}

.pt .garantie_num_tel_PT {
    display: block;
    padding-top: 4px;
    font-size: 15px
}

.pt .btn_violet.btn_desinscript_news {
    width: 275px
}

.pt .newsletter_info_oblig {
    padding-left: 150px !important
}

.pt #bloc_cb_directlink #bt_valider input {
    background: url("../img/arrow_bullet_green.png") no-repeat scroll 192px 13px #000 !important;
    width: 211px !important
}

.pt #tease_3xcb p {
    float: right !important
}

.pt #tease_3xcb {
    width: 210px !important
}

.pt .login_facebook_connexion {
    text-align: center !important
}

.txt_privilege_2 {
    color: #F11818;
    font-weight: bold
}

#wrapper_resultat .btnLoadExtend,
#wrapper_resultat .scrollExtend-loading {
    background-color: #c4d600;
    color: #fff;
    height: 40px;
    line-height: 40px;
    text-decoration: none;
    font-size: 20px;
    cursor: pointer;
    text-transform: uppercase;
    display: block;
    text-align: center;
    width: 320px;
    position: absolute;
    bottom: 15px;
    left: 50%;
    margin-left: -50px
}

#wrapper_resultat .btnLoadExtend {
    animation: blind 0.5s linear 10s infinite alternate
}

#wrapper_resultat .scrollExtend-loading img {
    vertical-align: -6px;
    margin: 0 0 0 5px
}

.loading_top .scrollExtend-loading {
    top: 95px
}

.loading_top .container_products {
    padding-top: 100px
}

.loading_top .container_products_search {
    padding-top: 100px;
    margin-top: 60px
}

.jScrollPaneContainer {
    position: relative;
    overflow: hidden;
    z-index: 1
}

.jScrollPaneTrack {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 0;
    height: 100%;
    background: #737373
}

.jScrollPaneDrag {
    position: absolute;
    background: #333;
    cursor: pointer;
    overflow: hidden
}

.jScrollPaneDragTop {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden
}

.jScrollPaneDragBottom {
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden
}

a.jScrollArrowUp {
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    text-indent: -2000px;
    overflow: hidden;
    background-color: #000005;
    height: 9px
}

a.jScrollArrowDown {
    display: block;
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    text-indent: -2000px;
    overflow: hidden;
    background-color: #000005;
    height: 9px
}

.product_tab_container .jScrollPaneDrag {
    background: #ededed
}

.product_tab_container .jScrollPaneTrack {
    background: #f9f9f9
}

.checkbox,
.radio {
    width: 10px;
    height: 10px;
    padding: 0;
    background: url(../img/checkboxes.gif) no-repeat;
    display: block;
    float: left
}

.radio {
    width: 18px;
    height: 18px;
    background: url(../img/round_check_box.gif) no-repeat;
    padding: 0 10px 0 0;
}

#satisfactionForm .radio {
    width: 21px;
    height: 21px;
    background: url(../img/check_satisfaction.gif) no-repeat;
    margin: -3px 0 0 0
}

#is_not_client_form span.radio {
    margin: 4px 0 0 0;
    *margin: 1px 0 0 0
}

#is_not_client_form .selectStyled {
    margin-top: 10px
}

#is_not_client_form .selectStyled select {
    width: 100px;
    height: 25px
}

#is_not_client_form .selectStyled .clone {
    border-color: #AAADB2 #E3E3EB #E2E9EF;
    border-radius: 3px 3px 3px 3px;
    border-style: solid;
    border-width: 1px;
    color: $textlightGrey;

    font-size: 13px;
    font-weight: normal;
    height: 25px;
    line-height: 25px;
    margin-bottom: 0;
    text-indent: 4px;
    width: 100px
}

#newsletterForm .select {
    width: 55px
}

#newsletterForm label.txt_civilite {
    display: inline-block;
}

#wrapper_form_infos label#clientCiviliteLabel {
    padding-top: 2px
}

#newsletterForm table tr.separateur td,
#wrapper_form_infos table tr.separateur td {
    width: auto
}

#newsletterForm table,
#wrapper_form_infos table {
    display: inline-table
}

#newsletterForm table tr.separateur,
#wrapper_form_infos table tr.separateur {
    height: 13px
}

#wrapper_form_infos table tr td div {
    display: inline-block
}

#wrapper_form_infos label.txt_civilite {
    display: inline-block;
    margin-top: 0
}

#selectform_service {
    width: 500px;
    background: #fff url(../img/fleche_select.gif) no-repeat 98% center
}

input.inputFormSite,
textarea.inputFormSite,
input.inputForm {
    color: #414243;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    height: 27px;
    line-height: 100%;
    text-indent: 4px;
    border: 1px solid #ecece8
}

input.inputErr,
textarea.inputErr,
.file-wrapper1.inputErr {
    border: 1px solid red;
}

#contact_form input.inputForm,
#contact_form input.inputFormSite,
#contact_form .product_file {
    width: 100%;
}

#contact_form .product_file {
    text-indent: 0;
}

#bloc_lost_password_top .inputForm {
    color: #414243;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    height: 27px;
    line-height: 27px;
    margin-bottom: 0;
    margin-top: 10px;
    text-indent: 4px;
    border: 1px solid #ecece8;
    width: 200px;
    margin-left: 25px;
    margin-bottom: 20px
}

#sendfriendbox input.inputFormSite,
#sendfriendbox textarea.inputFormSite {
    width: 460px
}

sup.euro_barre {
    font-size: 10px;
    margin: 0 0 0 2px;
    vertical-align: 6px
}

.prix_barre_centime {
    font-size: 9px;
    margin-left: -6px
}

.content_datepicker {
    position: relative
}

#form_connexion_top input.inputErr,
#form_connexion_top .inputErr {
    background: none repeat scroll 0 0 #FFFFFF;
    border-color: #e90000;
    border-style: solid;
    border-width: 1px;
    color: $textlightGrey;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    height: 27px;
    line-height: 27px;
    margin-bottom: 0;
    margin-top: 0px;
    text-indent: 4px;
    margin-left: 30px
}

#newsletterForm input.inputErr,
#newsletterForm .inputErr {
    box-shadow: 0 0 0 1px #ff114f !important;
    color: $textlightGrey;
    font-weight: normal;
    background-color: #fffada
}

.btn_violet.connexion_btn {
    width: 32px !important;
    height: 29px !important;
    background: #171717;
    color: #fff;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 0 !important;
    border: 0 !important;
    display: block;
    margin-right: 30px
}

.btn_violet,
.btn_gris,
.btn_noir {
    display: block;
    font-size: 12px;
    background: #171717;
    height: 22px;
    color: #fff;
    padding: 3px 24px 4px 24px;
    cursor: pointer;
    text-transform: uppercase;
    line-height: 22px;
    text-decoration: none;
}

#ligthbox_send_store_information {
    display: none;
    width: 696px;
    height: 170px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -238px 0 0 -348px;
    background: #fff;
    font-size: 12px;
    color: #53514F;
    z-index: 5000;
    -moz-box-shadow: 0px 0px 20px #111;
    -webkit-box-shadow: 0px 0px 20px #111;
    box-shadow: 0px 0px 20px #111
}

#bloc_couleur {
    padding-bottom: 27px
}

#bloc_taille .selected_options {
    padding-left: 15px
}

.form_select {
    float: left;
    margin: 0 20px 0 0
}

#middle_box_legal_mention .big_title_charte.sub_title {
    padding: 30px 0 10px 0;
    margin: 0 0 10px 0
}

#middle_box_legal_mention {
    min-width: 990px;
    font-weight: 400;
    padding: 0 0 50px 0
}

div#separe_form {
    height: 6px
}

div#separe_form2 {
    height: 10px
}

#type_recherche_cp {
    width: 300px
}

input#search {
    margin: 0 10px 0 0;
    display: inline
}

#filtres {
    width: 550px;
    padding: 0 0 0 20px
}

#filtres .marge {
    margin: 0 0 0 10px
}

.bloc_service_pressse ul .presse_service_add {
    position: relative;
}

.presse_rcpa {
    text-align: center;
}

.presse_rcpa img {
    width: auto !important;
    height: 6rem;
    padding: 0 1rem;
}

.presse_contact_mail span a {
    color: #676767;
    text-decoration: underline
}

.wrapper_type_dist .bloc_btn_loader {
    margin: 18px 0 0 30px;
    width: 169px;
    color: #8E9F1A;
    font-size: 12px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border: none;
}

.selectStyled .clone {
    border-color: #d1d1d1;
    border-radius: 0
}

#logo_home_popup {
    margin: 9px 0 0 32px;
    text-align: left
}

form#form_lightbox_adwords {
    margin-top: 6px;
    margin-left: 37px;
    position: relative;
}

form#form_lightbox_adwords div.form_submit {
    position: unset;
}

form#form_lightbox_adwords table.tooltip {
    bottom: 30px;
}

form#form_lightbox_adwords .form_submit button {
    background-color: transparent;
}

form#form_lightbox_adwords .form_submit button span {
    display: none;
}

form#form_lightbox_adwords .form_submit button::after {
    content: "";
    background: #ffffff url("../img/btn_ok_black.png") no-repeat;
    width: 47px;
    height: 31px;
    position: absolute;
    top: 0;
    right: 35px;
}

form#form_lightbox_adwords .img_error,
.valid_form {
    right: 6rem;
}

form#form_lightbox_adwords label {
    display: none;
}

form#form_lightbox_adwords input#email_inscription_home {
    width: 334px;
    height: 27px;
    border: 0;
    background: #fff;
    border-top: 1px solid #e8e8e8;
    border-left: 1px solid #f1f1f1;
    padding: 1px 2px;
    line-height: 17px;
    float: left;
    text-indent: 10px;
    color: #000;

    font-size: 15px;
    margin-right: 1px;
}

form#email_inscription_home input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */

    font-style: normal;
    font-size: 15px;
    color: #000;
}

form#email_inscription_home input::-moz-placeholder {
    /* Firefox 19+ */

    font-style: normal;
    font-size: 15px;
    color: #000;
}

form#email_inscription_home input:-ms-input-placeholder {
    /* IE 10+ */

    font-style: normal;
    font-size: 15px;
    color: #000;
}

form#email_inscription_home input:-moz-placeholder {
    /* Firefox 18- */

    font-style: normal;
    font-size: 15px;
    color: #000;
}

.pop_txt_listing p {
    font-size: 17px;
    color: #000;
    padding-bottom: 10px;

}

#popup_inscription_home ul li span {
    color: #000;
    font-weight: bold
}

#popup_inscription_home ul li strong {
    color: #ff2e64
}

.title.titre_entretien.titre_activation_garantie {
    margin-bottom: 15px
}

.bloc_activation_garantie {
    padding: 0;
    width: 980px;
    margin: 0 auto
}

.bloc_activation_garantie #clientForm {
    margin: 0 auto
}

.top_bloc_activation_garantie {
    margin-bottom: 20px
}

.top_bloc_activation_garantie p {
    margin-bottom: 10px
}

.top_bloc_activation_garantie p:last-child {
    margin-bottom: 0
}

span.icone_activation_garantie {
    float: left;
    width: 159px
}

.top_bloc_activation_garantie p {
    float: right;
    width: 705px;
    font-size: 13px;
    color: #585858;
    line-height: 18px;
    font-weight: 300
}

.top_bloc_activation_garantie p a {
    font-size: 12px;
    color: #444444;
    text-decoration: underline
}

p.garantie_form_title {
    color: #c4d600;
    font-size: 18px;
    padding-bottom: 2px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.04em;
    border-bottom: 1px solid #cbe42d;
    margin: 0 0 20px 0;
}

.activation_form_holder {
    margin-bottom: 35px
}

.activation_form_holder table {
    width: 85%
}

.activation_form_holder label {
    width: 175px;
    display: block;
    color: #444444
}

.activation_form_holder .inputFormSite,
.activation_form_holder .inputForm {
    margin-top: 0px !important;
    width: 467px;
    color: $textlightGrey !important;
    border: 1px solid #ecece8;
    text-indent: 4px;
    float: left;
    height: 25px;
    line-height: 25px;
}

.activation_form_holder .inputErr {
    width: 467px;
    float: left;
    margin-top: 0
}

.activation_form_holder table td {
    padding-top: 20px;
}

.activation_form_holder table td.form_txt label {
    padding-top: 4px
}

.activation_form_holder .intl-tel-input {
    float: left;
    width: 467px;
}

.intl-tel-input input.inputFormSite,
.intl-tel-input input.inputErr {
    float: none;
}

table td.form_txt {
    padding-bottom: 7px;
    vertical-align: top;
    width: 200px
}

.activation_form_holder table td .formItem_container label {
    float: left;
    width: auto !important;
    margin-right: 15px
}

.activation_form_holder span.red {
    color: #444444
}

.activation_form_holder .selectStyled .clone {
    border-color: #AAADB2 #E3E3EB #E2E9EF #AAADB2;
    border-image: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    color: #474747 !important;
    font-style: normal !important;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    height: 25px;
    line-height: 25px;
    margin-bottom: 0;
    text-indent: 15px;
    width: 467px;
    background: url("../img/select_arrow.jpg") no-repeat scroll 98% center #FFFFFF
}

.activation_form_holder .selectStyled select {
    width: 467px;
    line-height: 25px;
    height: 25px
}

.activation_form_holder.votre_achat .selectStyled {
    margin-right: 10px;
}

.activation_form_holder.votre_achat .selectStyled .clone {
    background: url("../img/select_arrow.jpg") no-repeat scroll 95% center #FFFFFF;
    width: 135px;
    color: #8c8c8c !important;
    font-style: italic !important;
    background-position: 106px center !important;
    border: 1px solid #ecece8;
    border-radius: 0
}

.activation_form_holder.votre_achat .selectStyled select {
    width: 135px
}

.activation_form_holder.votre_achat .selectStyled .valid_form {
    display: none;
}

.cnnxion_new_client_table {
    box-shadow: 2px 3px 14px -2px
}

td.btm_form_holder {
    padding: 41px 60px 61px 58px;
    background: #FFF;
    border: 1px solid #e9e9e9
}

#bloc_login label.deja_client_label {
    text-transform: uppercase
}

td.btm_form_holder input.inputErr,
td.btm_form_holder .inputErr,
td.btm_form_holder .inputForm {
    width: 288px;
    margin-top: 0
}

td.btm_form_holder span.red {
    color: #444444
}

.bloc_btn_inscription #clientNewsletterLabel {
    width: 267px
}

.activation_garantie_holder .shadow_box {
    margin-bottom: 0
}

span.mot_rechercher {
    color: #000
}

span#uploadBoxContent {
    color: #474747;
    float: left;
    font-size: 11px;
    font-style: italic;
    line-height: 15px;
    padding: 7px 0 0 8px;
}

input.btn_add_produit_garantie {
    background: #c4d600;
    height: auto;
    width: auto;
    text-indent: 0;
    font-size: 14px;

    padding: 11.5px 20px;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    line-height: 22px;
    text-decoration: none;
    border: none;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
}

input.btn_activation_garantie {
    background: #000;
    height: auto;
    color: #fff;
    text-align: center;
    display: block;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    width: 320px;
    letter-spacing: 0.1em;

    padding: 11.5px 0px;
    line-height: 22px;
}

input#uploadbutton {
    width: auto;
    float: left;
    background: #c4d600;
    height: auto;
    text-indent: 0;
    font-size: 14px;

    padding: 11.5px 20px;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    line-height: 22px;
    text-decoration: none;
    border: none;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.file-wrapper {
    position: relative;
    display: inline-block;
    overflow: hidden;
    cursor: pointer
}

.file-wrapper input {
    position: absolute;
    top: 0;
    left: 10px;
    filter: alpha(opacity=1);
    opacity: 0.01;
    -moz-opacity: 0.01;
    cursor: pointer;
    padding-left: 88px;
    width: auto !important
}

.file-wrapper .type_file_button {
    color: #fff;
    display: block;
    cursor: pointer;
    background: url("../img/button_parcourir.jpg") no-repeat scroll left top;
    width: 82px;
    height: 22px;
    float: left
}

.file-holder {
    line-height: 25px
}

#copyright {
    padding-top: 0;
}

#copyright .banner_home {
    margin-top: 0
}

#content_wrapper.contact_page {
    overflow: visible;
    min-height: 576px
}

.title.titre_entretien.titre_contact h1 {
    background: none;
    padding-left: 0
}

.product_teaser_ad_big p.caption_title {
    color: #000;
    text-transform: uppercase;
    font-size: 14px;
    float: left;
    line-height: 15px;
    font-weight: 700
}

.product_teaser_ad_big p.caption_title span {
    text-transform: none;
    font-size: 12px;
    font-weight: normal;
}

.product_teaser_ad_big .teaser_caption p.caption_price {
    float: right;
    margin-right: 15px
}

span.normal_price {
    float: left;
    color: #24688f;
    font-size: 30px;
    font-weight: bold
}

span.main_centimes {
    font-size: 15px
}

span.normal_price sup {
    font-size: 15px;
    font-weight: normal;
    padding-left: 6px
}

span.cutthrough_price {
    float: right;
    padding-left: 4px;
    font-size: 20px;
    color: $textlightGrey;
    font-weight: normal;
    padding-top: 9px;
    text-decoration: line-through
}

span.cutthrough_price span {
    text-decoration: line-through
}

span.cutthrough_price sup {
    font-size: 12px
}

form#inscription_popup_home #loader_popup {
    margin-top: -11px !important;
    margin-right: 6px !important;
}

table.tableau_garantie td {
    padding: 8px 10px;
    box-sizing: border-box
}

table.tableau_garantie thead tr td {
    text-align: left;
    font-weight: 700;
    height: 28px;
    letter-spacing: 0;
    color: $darkenGrey;
    padding: 8px 20px;
}

table.tableau_garantie tr td {
    border: 1px solid $tableGrey;
}

table.tableau_garantie tr.tab_decriptif_garantie td.last {
    background: none
}

table.tableau_garantie tr.tab_decriptif_garantie td.tab_sep {
    background: #fafafa;
    padding: 0;
    height: 10px
}

table.tableau_garantie tr.tab_decriptif_garantie td.tab_sep_02 {
    background: #fafafa;
    padding: 0;
    height: 10px;
    border-bottom: 5px solid #fff
}

table.tableau_garantie tr.tab_decriptif_garantie span.ref {
    display: block;
    padding-top: 1px;
    font-weight: 700
}

table.tableau_garantie tr.tab_decriptif_garantie a {
    font-size: 11px;
    color: #232624;
    text-decoration: underline
}

#lightbox_stock_alert input.btn_element {
    width: 207px;
    margin: 0 auto;
    height: 48px !important;
    line-height: 48px !important;
    background: url("../img/arrow_bullet_green.png") 186px 20px no-repeat #171717;
    color: #fff;
    text-align: center;
    font-size: 10px;
    float: none;
    padding: 0;
    border: 0px;
    letter-spacing: 0.18em;
    border-top: 1px solid #c4d600;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 700
}

p.txt_envoie_email {
    color: #757575;
    letter-spacing: 0.03em;
    padding-top: 5px;
    font-size: 13px;
    max-width: 350px;
}

.send_frd_pdt_details .product_sale_price_barre {
    line-height: normal
}

#sendfriend_form_buttons input.btn_violet {
    width: 143px;
    height: 48px;
    line-height: 48px;
    color: #fff;
    background: url("../img/ico_send_to_frd_bg.png") right 18px no-repeat #1f1f1f;
    font-size: 10px;
    text-transform: uppercase;
    padding: 0;
    padding-right: 40px;
    letter-spacing: 0.04em;
    border: 0;
    border-top: 1px solid #c0cd1c;
    font-weight: 700
}

#sendfriendbox .labelForm {
    width: 115px;
    padding-right: 5px
}

#sendfriendbox #sendfriend_prodinfos {
    padding: 22px 35px;
    overflow: hidden;
    min-height: 266px;
}

#sixth_sendfriend_form_froms {
    padding-left: 122px
}

#sendfriendbox .form_itm.check input[type="checkbox"] {
    display: none
}

#sendfriendbox .form_itm.check input[type="checkbox"]+label {
    cursor: pointer
}

#sendfriendbox .form_itm.check input[type="checkbox"]+label>span {
    display: inline-block;
    vertical-align: middle;
    height: 13px;
    line-height: 13px;

    font-weight: 400;
    font-size: 12px;
    color: #414243
}

#sendfriendbox .form_itm.check input[type="checkbox"]+label:before {
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    background-image: url(../img/checkboxes.gif);
    margin-right: 0.25em;
    content: ''
}

#sendfriendbox .form_itm.check input[type="checkbox"]:checked+label:before {
    background-position: left bottom
}

#bloc_cb_directlink .bank_choix_btn {
    margin-top: 4px;
    margin-bottom: 4px
}

form#service #contact_form .btn_violet {
    width: 190px !important;
    background: #c0cf00;
    color: #fff;
    border: 0;
    line-height: 40px;
    height: 40px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    font-weight: 700;
    font-size: 11px;
    padding: 0;
    margin-top: 15px;
    text-indent: 0;
}

.client_dob .clone,
.news_select .clone {
    border: 0;
    border-radius: 0;
    line-height: 27px;
    height: 27px;
    width: 100% !important;
}

.client_dob .clone:active {
    background: url("../img/select_arrow.jpg") no-repeat scroll 95% center #FAFCEB !important
}

.client_dob .selectStyled select,
.news_select .selectStyled select,
#newsletterForm .selectStyled #newsletterFavoriteStore {
    line-height: 27px;
    width: 100%;
    cursor: pointer
}

#newsletterForm .fix_wrap {
    display: inline-block;
    position: relative;
    margin-bottom: 29px;

    input#newsletterMail {
        width: 100%;
        margin-bottom: 0;
    }
}

.wishlist_details_bloc .conteneur_prix span.cent {
    font-size: 14px;
    margin-left: 3px
}

.wishlist_details_bloc .conteneur_prix .devise {
    font-size: 14px;
    position: absolute;
    top: 4px;
    margin-left: 3px
}

.select_colors .couleur_14 .couleur_check {
    background-image: url(../img/picto/color_14.jpg) !important;
    background-size: 100%;
}

.btn_select .txt_menu a {
    color: #000 !important;
    text-decoration: underline !important;
}

#bloc_paypal .what_is_it {
    padding: 0;
    margin: 5px 0 10px 0
}

#bloc_paypal .show_what_is_it {
    bottom: 25px !important;
    left: -34px !important
}

@-webkit-keyframes blind {
    0% {
        background: #c4d600
    }

    10% {
        background: #c4d600
    }

    90% {
        background: #000
    }

    100% {
        background: #000
    }
}

@-moz-keyframes blind {
    0% {
        background: #c4d600
    }

    10% {
        background: #c4d600
    }

    90% {
        background: #000
    }

    100% {
        background: #000
    }
}

@-o-keyframes blind {
    0% {
        background: #c4d600
    }

    10% {
        background: #c4d600
    }

    90% {
        background: #000
    }

    100% {
        background: #000
    }
}

@keyframes blind {
    0% {
        background: #c4d600
    }

    10% {
        background: #c4d600
    }

    90% {
        background: #000
    }

    100% {
        background: #000
    }
}

span#uploadBoxContent1,
.file-wrapper1 em {
    font-size: 12px
}

#contact_form {

    .jour,
    .hour {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    input[type=checkbox] {
        display: none;
    }

    .form_item input[type="checkbox"]+label {
        display: block;
        font-size: 14px;
        color: #909090;
        cursor: pointer;
        width: 50%;
        margin: 0 0 5px 0;

        &:before {
            display: inline-block;
            width: 16px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            background: #F4F4F4;
            margin: 0 10px 0 0;
            vertical-align: -3px;
            content: '';
        }
    }

    .form_item input[type="checkbox"]:checked+label:before {
        vertical-align: 1px;
        content: '✔';
    }
}

#contact_form .selectStyled {
    float: left;
    position: relative;
    margin-bottom: 5px;
    margin-top: 5px !important
}

#contact_form .formItem_container .selectStyled {
    margin-bottom: 5px;
    margin-right: 10px
}

#contact_form input#contactNom {
    margin: 0
}

#contact_form .formItem_container .selectStyled:nth-child(3) .clone {
    width: 100px !important
}

#contact_form .file-wrapper11 {
    margin-top: 5px
}

#contact_form .file-wrapper11 span#uploadBoxContent {
    width: 100%
}

#contact_form textarea#contactAdresse {
    margin: 5px 0 2px !important
}

tr#bloc_date_achat .selectStyled .clone {
    width: 102px !important
}

#wrapper_contact #contact_form .selectStyled select {
    height: 26px;
    width: 100%
}

#mention_legales_pop_up .popup_title {
    float: none
}

.pop_mention_detail {
    line-height: 18px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: $grey3;
}

#content_mention_legales_pop_up {
    .pop_mention_detail {
        padding: 19px;
        font-family: $primaryFont;
        font-size: 13px;
        font-weight: 400;
        line-height: normal;

        p {
            margin-left: 0;
        }
    }
}

.pop_mention_title {
    margin-left: 0;
    color: $grey3;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 15px;
}

a.btn_retour {
    color: #313131;
    display: block;
    padding: 2px 0;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 11px;
    background: url(../img/retourbg.png) no-repeat 0 0;
    position: absolute;
    width: 58px;
    height: 14px;
    top: 0;
    z-index: 1;
}

.actu_left {
    float: left;
    width: 40%;
}

.actu_left img {
    width: 100%;
}

.actu_right {
    float: right;
    width: 60%;
    max-width: 715px;
    margin-top: -87px;
    margin-bottom: 0px;
}

.presse_actu strong {
    font-weight: 700;
}

h2.big_title_charte.no_bg.main_title.actualite {
    padding-top: 21px;
}

.autres li {
    display: inline-block;
    margin-right: 3.8%;
    position: relative;
    margin-top: 1%;
}

.autres li:last-child {
    margin-right: 0%;
}

.autres li:after {
    position: absolute;
    content: "";
    height: 27px;
    width: 1px;
    background: #f2f2f2;
    top: 17px;
    right: -24px;
}

.autres li.last:after {
    display: none;
}

h3.big_title_charte.no_bg.sub_title.ligne {
    position: relative;
    width: 99.4%;
    padding-top: 57px;
    margin-bottom: 2px;
}

h3.big_title_charte.no_bg.sub_title.ligne:after {
    position: absolute;
    content: "";
    height: 1px;
    width: 83%;
    background: #f2f2f2;
    top: 69px;
    right: 0;
}

.nous_parler {
    font-size: 14px;
    color: #c4d600;
    font-weight: 700;
    letter-spacing: .2em;
}

.autres.clearfix {
    margin-bottom: 40px;
}

.presse_contact_mail a {
    text-decoration: underline;
    color: #000 !important;
}

.presse_contact_mail .padTop {
    padding-top: 15px;
}

.inner_bloc_left,
.marque_bloc_right {
    font-size: 15px;
    color: #585858;
}

@media screen and (min-width: 1020px) {
    .actu_left {
        float: left;
    }

    h3.big_title_charte.no_bg.sub_title.ligne:after {
        width: 68%;
    }
}


@media screen and (max-width:1094px) {
    #main_slider_next {
        right: 0;
        background: rgba(255, 255, 255, 0.8)
    }

    #main_slider_prev {
        left: 0;
        background: rgba(255, 255, 255, 0.8)
    }

    .backtotop {
        background: url("../img/ui.totop.jpg") no-repeat scroll left top rgba(0, 0, 0, 0)
    }

    .btn_select div:first-child,
    .btn_select_off div:first-child {
        vertical-align: middle;
        width: 24px !important;
        height: 24px !important;
        margin-bottom: 6px;
        background: transparent url(../img/checkboxes_big.gif) no-repeat 0 bottom !important;
        background-size: 100% !important;
        box-sizing: border-box
    }

    .btn_select_off div:first-child {
        background-position: 0 top !important
    }

    .btn_select div.txt_menu,
    .btn_select_off div.txt_menu {
        vertical-align: middle;
        width: auto !important;
        height: 24px !important;
        line-height: 24px;
        background: none !important;
        margin-top: 2px
    }

    .btn_select:last-child div.txt_menu,
    .btn_select_off:last-child div.txt_menu {
        margin-bottom: 0
    }

    .select_colors .btn_select div:first-child,
    .select_colors .btn_select_off div:first-child {
        background-image: none !important
    }

    .btn_expand_off,
    .btn_select_off div:first-child {
        background-position: 0 top
    }

    .left_menu_obj .txt_menu {
        font-size: 13px;
        line-height: 1.8em
    }

    .select_colors .couleur_check {
        width: 18px;
        height: 18px
    }

    .select_colors .couleur_1 .couleur_check {
        height: 16px;
        width: 16px
    }

    .btn_select_off:hover .couleur_check,
    .btn_select .couleur_check {
        width: 16px;
        height: 16px
    }

    .selected_options.select_colors .btn_select div:first-child {
        width: 16px;
        height: 16px
    }

    .picto_couleur {
        width: 24px !important;
        height: 24px !important
    }

    .picto_couleur img {
        width: 24px !important;
        height: 24px !important
    }

    .picto_couleur.actif {
        width: 28px !important;
        height: 28px !important;
        margin: 0
    }

    .picto_couleur.actif img {
        width: 28px !important;
        height: 28px !important
    }
}

#connexion_to {
    background: url("../img/general_sprite.png") no-repeat scroll -149px 0 transparent;
    color: #cccccd;
    cursor: pointer;
    font-size: 11px;
    height: 57px;
    position: absolute;
    right: 0;
    top: 0;
    width: 58px
}

#connexion_to:hover {
    background: transparent url(../img/general_sprite.png) no-repeat -149px -57px;
    border-left: 1px solid #c4d600
}

span.blog_bloc {
    background-position: -69px -151px
}

div.eclat_product_nouveaute {
    position: absolute;
    z-index: 10
}

div.eclat_product_epuise {
    position: absolute
}

.container_ads {
    position: absolute;
    top: -10px;
    left: 0;
    width: 1px;
    height: 1px
}

#content_sendwishlist #sendfriend_alert_mail {
    padding-top: 20px;
    text-align: center
}

.act_garantie_txt {
    padding: 0px !important
}

.google_form_wrapper,
.google_form_wrapper iframe {
    width: 998px;
    margin: 0 auto
}

.google_form_wrapper iframe {
    height: 650px;
    padding: 40px 0;
    background: #fcfcfc
}

.pop-up-bullet {
    margin-left: 20px
}

.pop-up-bullet li {
    list-style-type: square
}

.wp-latest-article .entry-date {
    font-size: 40px
}

.wp-latest-article .entry-month {
    font-weight: 300;
    font-size: 18px
}

.wp-latest-article .entry-year {
    font-weight: 300;
    font-size: 12px
}

.wp-latest-article .wp-title {
    font-size: 22px;
    font-weight: 300
}

.wp-latest-article .lireplus_lien {
    font-weight: 700;
}

.height-limit {
    height: 344px;
    overflow: hidden;
    margin-left: 48px;
}

.wp-mise-en-avant-left .post-thumbnail img {
    float: right;
    display: block;
    height: auto;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
}

.wp-latest-article .wp_lien {
    float: none;
    transform: none;
    text-decoration: none;
    text-transform: uppercase;

    font-size: 11px;
    margin-bottom: 25px;
}

.wp-latest-article .wp_lien a {
    color: #515151;

    font-size: 35px;
    font-weight: 700;
    text-decoration: none;
}

.wp-latest-article .month-year {
    width: 60px;
    float: left;
    text-transform: uppercase;
}

.wp-latest-article .wp_lien .lien_under {
    display: inline-block;
    width: 30px;
    border-bottom: 2px solid #000;
    position: relative;
    top: 8px;
    left: -33px;
}

.wp-latest-article .wp-date {
    float: left;
    margin-left: 65px;
}

.wp-latest-article .entry-date {
    border: 0px;
    color: #515151;

    float: left;
    line-height: 33px;
    font-size: 42px;
    font-weight: bold;
    padding-right: 5px;
}

.wp-latest-article #primary .month-year {
    width: 60px;
    float: left;
    font-size: 13px;
    text-transform: uppercase;
}

.wp-latest-article .entry-month {
    color: #515151;

    font-size: 20px;
    line-height: 16px;
    font-weight: bold;
}

.wp-latest-article .entry-year {
    color: #8a8a8a;

    font-size: 14px;
    letter-spacing: 1px;
    font-weight: bold;
}

.wp-latest-article .wp-title {

    font-size: 18px;
    font-weight: normal;
    line-height: 21px;
    color: #b2b2b2;
    width: 430px;
    text-transform: uppercase;
    float: left;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 65px;
    margin-right: -1em;
    padding-right: 1em;
}

.wp-latest-article .first-article-summary {
    float: none;
    margin-top: 4px;
    line-height: normal;
    width: 62%;
    font-size: 12px;
    margin-left: 65px;
    overflow: hidden;
    clear: both;
}

.wp-latest-article .left {
    padding: 0 25px 0 0;
}

.left {
    float: left;
    text-align: justify;
}

.main_mise_en_avant .btn_blog_buy {
    padding: 0px 15px;
    display: inline-block;
    border: 2px solid #c4d600;
    margin-top: 20px;
    margin-left: 65px;
    position: relative;
}

.btn_blog_buy {
    position: relative;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    text-decoration: none;
    display: inline-block;
    width: 80px;
    height: 31px;
    line-height: 31px;
    text-align: center;
    border: 2px solid #c4d600;
    font-size: 12px;
    color: #333;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
}

.btn_blog_buy::before,
.btn_blog_buy>span {
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    -webkit-transition-timing-function: cubic-bezier(.75, 0, .125, 1);
    transition-timing-function: cubic-bezier(.75, 0, .125, 1);
    display: block;
}

.btn_blog_buy::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #c4d600;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}

.btn_blog_buy:hover::before {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.btn_blog_buy:hover>span {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
}

@media screen and (min-width: 1200px) {
    .actu_left {
        float: left;
        width: 40%;
    }

    .actu_right {
        float: right;
        width: 60%;
        max-width: 715px;
        margin-top: -87px;
        margin-bottom: 0px;
    }

    h3.big_title_charte.no_bg.sub_title.ligne:after {
        width: 75%;
    }
}

@media screen and (max-width: 1280px) {
    .height-limit {
        height: auto;
        overflow: hidden;
        margin-left: 0;
    }
}


input.readonly {
    background: #eee;
}

@media screen and (max-width: 1220px) {
    #zoomBox #fermer_zoombox.btn_noir {
        top: 10px;
        right: 10px
    }
}

@media screen and (min-width: 1500px) {
    h3.big_title_charte.no_bg.sub_title.ligne:after {
        width: 81%;
    }
}

#send_mail_alert_stock.button.fwd {
    width: 207px
}

.home.pt.site_4 #loader_popup {
    margin-right: 28px !important;
}

.home.fr.site_1 #loader_popup,
.home.fr.site_3 #loader_popup {
    margin-right: 53px !important;
}

.g-recaptcha {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0;
}

#shad.actif {
    display: block;
}