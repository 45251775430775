/**
 * CMS GENERAL STYLES
 */
body.cms_category,
body.cms_subcategory,
body.cms_page,
.cms_page_new {

    .ancre_type:target {
        padding-top: 10em;
        margin-top: -10em;
    }

    .header_cms_index {
        h1.title {
            font-size: 40px;
            font-weight: 600;
            color: $black;
            text-align: center;
        }

        .block_description {
            font-size: 17px;
            font-weight: 300;
            text-align: center;
            margin-bottom: 25px;
        }
    }

    .header_cms_image_article {
        margin: 3rem 0 4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a,
        .header_cms_text_article {
            width: 49%;
            height: 100%;
        }

        a {
            position: relative;
            display: block;

            img {
                width: 100%;
                height: auto;
                vertical-align: top;
            }

            &.btn_buy {
                top: auto;
                left: auto;
                transform: none;
                width: 160px;
            }
        }

        .header_cms_text_article {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            text-align: left;
            height: 100%;

            .title,
            .subtitle {
                font-weight: 600;
                color: $textGrey;
                text-transform: uppercase;
                margin-bottom: 0.5rem;
            }

            .title {
                font-size: 40px;
                text-transform: uppercase;
            }

            .subtitle {
                font-size: 25px;
                width: 50%;
                min-width: 340px;
            }

            .description {
                font-size: 17px;
                font-weight: 300;
                margin-bottom: 0.6rem;
            }
        }

        .cta {
            cursor: pointer;
        }
    }

    section#site_global_wrap {
        padding-top: 158px;
    }

    &.hasBandeauHeader section#site_global_wrap {
        padding-top: 192px;
    }

    .breadcrumbs_wrapper,
    .itm_back_btn {
        display: none;
    }

    .breadcrumbs {
        display: flex;
        height: 40px;
        font-size: 10px;
        font-weight: 400;
    }

    .breadcrumb {
        display: flex;
        align-items: center;
        margin-left: 0.5em;
        color: $nobel;
        text-transform: uppercase;
        letter-spacing: 0.02rem;

        &:first-child {
            margin-left: 0;
        }

        &:not(.current)::after {
            content: '/';
            display: block;
            width: 1.5em;
            text-align: right;
        }
    }

    .cta {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $black;
        width: 270px;
        height: 50px;
        border: 0 none;
        font-family: $primaryFont;
        font-size: 0.9375rem;
        color: $white;
        text-transform: uppercase;
        margin: 15px auto 0;
        border: 1px solid $black;

        &:hover {
            color: $black;
            background: $white;
        }
    }

    .cms-page-module-cta {
        div {
            display: block;
            width: 100%;
            max-width: 1280px;
            margin: 0 auto;
            height: auto;

            span,
            a {
                width: 100%;
                max-width: 270px;
                height: 50px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-family: $primaryFont;
                font-size: 0.9375rem;
                color: var(--color, $primaryFont);
                background-color: var(--background-color, $black);
                border: 1px solid var(--background-color, $black);

                &:hover {
                    color: var(--background-color, $black);
                    background-color: var(--color, $primaryFont);
                }
            }
        }
    }

    .cms-page-module-text {
        a {
            text-decoration: underline;
        }

        ul {
            list-style-type: disc;
            list-style-position: inside;
            margin-left: 1.5rem;
        }
    }
}

body.cms_subcategory.search_in,
body.cms_category.search_in {

    .page_wrapper #site_global_wrap {
        width: 98%;
        max-width: 1690px;
        margin: auto;
    }
}

.bandeau_header_rubrique img {
    width: 100%
}

/**
 * CMS HOME
 */
#cms_subcategory_menu {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $primaryFont;
    font-weight: 600;
    text-transform: uppercase;
    margin: 50px 0;

    label {
        font-size: 18px;
    }

    ul {
        display: flex;
        justify-content: center;
        align-items: center;

        li {
            margin: 0 1em;

            &.selected {
                background-color: $mustardgreen;
                border-radius: 20px;
            }
        }
    }

    a {
        position: relative;
        display: inline-flex;
        align-items: center;
        height: 33px;
        border: 2px solid $mustardgreen;
        border-radius: 20px;
        font-size: 17px;
        padding: 0 1.5em;
        box-sizing: border-box;
        cursor: pointer;
        overflow: hidden;

        span {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            font-size: 17px;
            opacity: 1;
            transition: all 0.2s ease-in-out;

            @media screen and (max-width: 1200px) {
                font-size: 12px;
            }
        }

        &:after {
            content: attr(data-text);
            display: inline-flex;
            border: none;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            right: 100%;
            width: 100%;
            height: 100%;
            background-color: $mustardgreen;
            color: $white;
            opacity: 0;
            transition: all 0.2s ease-in-out;
        }

        &:hover {
            background-color: $mustardgreen;

            span {
                top: 100%;
                opacity: 0;
                width: 100%;
                height: 33px;
            }

            &:after {
                right: 0;
                opacity: 1;
            }
        }
    }
}

.cms_category {
    #cms_subcategory_menu {
        .csc_home {
            background-color: $mustardgreen;
            border-radius: 20px;
        }
    }
}

#cms_page_btn_back {
    .itm_back_btn {
        display: flex !important;
        width: 7%;
    }
}

#content_previsu_cms_page {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    .previsu_cms_page {
        position: relative;
        width: 32%;
        max-width: 506px;
        margin: 0 0.8% 2.5em;

        img {
            width: 100%;
            height: auto;
            vertical-align: top;
        }
    }

    .cms_page_previsu_description {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 62%;
        padding: 1.5em 2em;
        font-size: 18px;
        font-weight: 500;
        color: $textGrey;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.95);

        h3 {
            font-size: 18px;
            font-weight: 500;

            @media screen and (max-width: 1200px) {
                font-size: 16px;
            }
        }
    }
}

.cms-page-module {
    margin: 0 auto 56px;

    /**
     * COVER
     */
    &.cover-module {
        width: 100%;
        max-width: 1600px;

        img {
            width: 100%;
            height: auto;
            vertical-align: top;
        }
        iframe {
            max-width: 100%;
            aspect-ratio: 32 / 9;
        }
    }

    /**
     * TEXT
     */
    &.text-module {
        max-width: 1318px;

        @media screen and (max-width: 1024px) {
            width: 85%;
        }

        .cms-page-module-title {
            font-size: 40px;
            font-weight: 600;
            margin-bottom: 16px;
            text-transform: uppercase;
        }

        .cms-page-module-text {
            font-size: 17px;
            line-height: 1.2;
        }
    }

    /**
     * 2 IMAGES + TEXTE
     */
    &.text-image-2 {
        max-width: 1318px;
        display: flex;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        gap: 0 100px;

        .img_wrapper {
            display: flex;
            width: 68%;
            justify-content: space-between;
            align-items: center;
            margin: 0;

            .imgWrapper {
                width: 49%;
            }

            img {
                width: 100%;
                height: auto;
                vertical-align: top;
            }
        }

        .cms-page-module-contents {
            width: 49.5%;
        }

        .cms-page-module-title,
        .cms-page-module-text {
            width: 100%;
        }

        .cms-page-module-title {
            font-size: 25px;
            font-weight: 500;
            margin-bottom: 30px;
        }


        .cms-page-module-text {
            font-size: 17px;
            line-height: 1.2;
        }
    }

    /**
     * TEXT + VIDEO
     */
    &.text-video,
    &.text-video-2 {
        .cms-page-module-title {
            font-size: 25px;
            font-weight: 500;
            margin-bottom: 30px;
        }

        .cms-page-module-text {
            font-size: 17px;
            line-height: 1.2;
        }

        .imgWrapper,
        .videoWrapper {
            margin: 55px auto 0;
        }

        img {
            width: 100%;
            height: auto;
            vertical-align: top;
        }

        // Dailymotion video
        .slide_video {
            position: relative;
            aspect-ratio: 1220 / 622;
            max-width: 1220px;
            margin: 0 auto;
        }

        .block_redirect,
        iframe {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
        }

        .block_redirect {
            z-index: 2;
        }
    }

    &.text-video {
        max-width: 1318px;

        .imgWrapper,
        .videoWrapper {
            max-width: 1220px;
        }

        .videoWrapper {
            position: relative;
            padding-bottom: calc(622 / 1220 * 100%);
        }
    }

    &.text-video-2 {
        .cms-page-module-title {
            width: 1318px;
            margin: 0 auto 30px;
        }

        .cms-page-module-text {
            width: 1318px;
            margin: 0 auto;
        }

        .imgWrapper,
        .videoWrapper {
            max-width: 1600px;
        }

        .videoWrapper {
            position: relative;
            padding-bottom: calc(622 / 1604 * 100%);
        }
    }

    /**
     * IMAGE + TEXT / TEXT + IMAGE
     */
    &.text-image,
    &.image-text {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: stretch;
        max-width: 1600px;
        padding: 26px;
        box-sizing: border-box;

        .wrapperImg {
            width: 49%;
            max-width: 744px;

            img {
                width: 100%;
                height: auto;
                vertical-align: top;
            }
        }

        .wrapperTxt {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 37%;
            margin: 0 auto;

            @media screen and (max-width: 1024px) {
                width: 40%;
            }
        }

        .cms-page-module-title {
            font-size: 25px;
            font-weight: 500;
            margin-bottom: 30px;
        }

        .cms-page-module-text {
            font-size: 17px;
            line-height: 1.2;
        }

        .cta {
            margin: 35px auto 0;
        }
    }

    &.text-image {

        &.text-left,
        &.text-right {
            flex-direction: row;
            max-width: 1077px;
            padding: 0;
            margin-bottom: 40px;
            position: relative;

            .wrapperImg {
                width: 100%;
                max-width: 1077px;
            }

            .videoWrapper {
                display: flex;
                width: 100%;
                height: 0;
                padding-bottom: calc((250 / 1077) * 100%);
                position: relative;

                iframe {
                    display: flex;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }
            }

            .wrapperTxt {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: rgba(255, 255, 255, 0.8);
                width: auto;
                min-width: 280px;
                height: 196px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 27px;
                z-index: 2;
                padding: 36px 10px;
                box-sizing: border-box;

                .cms-page-module-title {
                    font-family: $primaryFont;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 29px;
                    text-align: center;
                    width: 90%;
                    margin-bottom: 0;
                }

                .cms-page-module-text {
                    font-family: $primaryFont;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    text-align: center;
                    width: 90%;
                }
            }
        }

        &.text-right {
            .wrapperTxt {
                min-width: 311px;
                left: auto;
                right: 27px;
            }
        }
    }

    &.image-text {
        flex-direction: row;
    }

    /**
     * PRODUCT LIST + TEXTE
     */
    &.products_list_text {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        background-color: $white;
        padding: 3em 0;

        .leftWrapper,
        .rightWrapper {
            width: 50%;
            box-sizing: border-box;
        }

        .leftWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 3em;
        }

        .title {
            font-size: 25px;
            font-weight: 600;
        }

        .text {
            font-size: 17px;
            line-height: 1.2;
            margin: 2em 0 3em;
            text-align: left;
        }

        .rightWrapper {
            margin-right: 3em;
        }

        .swiper-slide {
            width: 48%;
            background-color: $white;

            &+.swiper-slide {
                margin-left: 4%;
            }

            &:hover .rollover {
                opacity: 1;
            }
        }

        .wrapper_infos {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            margin: 0.5em 0;
            padding: 0 1em;

            .title {
                order: -2;
                width: 70%;
                font-size: 14px;
                font-weight: 600;
                line-height: 1.1;
            }

            .wrapper_price {
                order: -1;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .pricetag+.pricetag::after {
                    width: 100%;
                }
            }

            .pricetag {
                width: fit-content;
                font-size: 20px;
                font-weight: 600;
            }

            .item_ratings {
                .netreviews_reviews_rate {
                    color: $jauneAvisV;

                    +.netreviews_reviews_rate {
                        color: $black;
                    }
                }
            }
        }

        .rollover {
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }
    }

    /**
     * PRODUCT LIST
     */
    &.products_list:not(.products_list_2) {
        position: relative;
        width: 98%;
        max-width: 1680px;
        margin: 0 auto;

        .title {
            display: inline-block;
            color: $black;
            font-size: 17px;
            line-height: 1.2;

            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background-color: $black;
            }
        }

        .productsWrapper {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 2em;
            padding-bottom: 10em;
        }

        .item_tg {
            width: 25%;
            padding: 0 3%;
            box-sizing: border-box;
            opacity: 1;
            transition: opacity .3s ease;

            &.replacing {
                opacity: 0;
                transition: opacity .3s ease;
            }

            &+.item_tg:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 25%;
                background-color: $grey86;
            }

            &:first-of-type,
            &:last-of-type {
                max-width: 22%;
            }

            &:first-of-type:last-of-type {
                max-width: 19%;
            }

            &:first-of-type {
                padding-left: 0;
            }

            &:last-of-type {
                padding-right: 0;
            }
        }

        a.item {
            position: relative;
            display: block;

            &:hover {
                .rollover {
                    display: none;
                }
            }

            .wrapper_infos {
                position: absolute;
                top: 100%;
                left: 0;

                .item_ratings {
                    .netreviews_reviews_rate {
                        color: $jauneAvisV;

                        +.netreviews_reviews_rate {
                            color: $black;
                        }
                    }
                }
            }

            .title {
                width: 100%;
                font-size: 14px;
                font-weight: 500;
                margin: 0.3rem 0 0 0;

                &:after {
                    display: none;
                }
            }

            .pricetag {
                font-size: 20px;
            }

            .rollover {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
            }

            .btn_buy {
                position: relative;
            }

            &:hover .rollover {
                opacity: 1;
            }
        }
    }

    /**
     * ANCIENS POSTS
     */
    &.old-post {
        .title {
            font-size: 31px;
            font-weight: 300;
            text-align: center;
            margin-bottom: 30px;

            strong {
                font-weight: 600;
            }
        }

        .oldPostWrapper .swiper-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }

        a.previsu_cms_page {
            display: block;
            width: 32%;
            max-width: 506px;
            position: relative;

            img {
                width: 100%;
                height: auto;
                vertical-align: top;
            }

            .cms_page_previsu_description {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 62%;
                padding: 1.5em 2em;
                font-size: 18px;
                font-weight: 500;
                color: $textGrey;
                text-align: center;
                background-color: rgba(255, 255, 255, 0.95);

                h3 {
                    font-size: 18px;
                    font-weight: 500;

                    @media screen and (max-width: 1200px) {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    /**
     * ARTICLE SHARE
     */
    &.socialMedia {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .title {
            display: inline-block;
            font-size: 20px;
            color: $black;
            line-height: 2.1;
            text-transform: uppercase;

            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background-color: $black;
            }
        }

        ul {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: 1.5rem;
        }

        .social_btn {
            width: 40px;
            height: 40px;
            margin: 0 0.4rem;
            font-size: 0;
            cursor: pointer;
            @include bgIconPath('../svg/social_media.svg');

            &.facebook {
                @include bgIconCoord(1, 5, 3);
            }

            &.insta {
                @include bgIconCoord(2, 5, 3);
            }

            &.pinterest {
                @include bgIconCoord(5, 5, 3);
            }
        }
    }

    .cta {
        width: fit-content;
        padding: 0 1.5em;
    }
}

.cms_page_new .picture-container {
    img {
        width: 100%;
    }

    .slide_video {
        display: flex;
        position: relative;
        height: fit-content;
        width: 100%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        a {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 2;
            cursor: pointer;
            display: block !important;
        }
    }
}

body.cms_page_new {
    &.hasBandeauHeader {
        .cms-page-module.products_list.products_list_2 .left_part {
            position: sticky;
            top: 135px;
        }
    }

    .main_wrapper {
        width: 100%;
        max-width: 1920px;
        margin: auto;
    }

    .categoryNav,
    .navigationTop {
        width: 98%;
        max-width: 1690px;
        margin: auto;
    }

    .cms_page_content {
        width: 100%;
        min-width: 1007px;

        &:has(.images_signup_module) {
            .socialMedia {
                display: none !important;
            }
        }
    }

    /* MODULE Title*/
    .cms-page-module-body.line-text.module-title {
        width: 98%;
        margin: 20px auto;
        max-width: 1680px;
        font-size: 25px;
        color: $black;

        @media screen and(max-width: 1680px) {
            padding: 0 1.9rem;
            box-sizing: border-box;
        }

        @media screen and(max-width: 1290px) {
            padding: 0 1.5rem;
            box-sizing: border-box;
        }

        .cms-page-module-title {
            span {
                font-family: $primaryFont;
            }
        }
    }

    /* MODULE sub-title*/
    .cms-page-module-body.line-text.module-subtitle {
        width: 98%;
        margin: 0 auto;
        max-width: 1680px;

        @media screen and(max-width: 1680px) {
            padding: 0 1.9rem;
            box-sizing: border-box;
        }

        @media screen and(max-width: 1290px) {
            padding: 0 1.5rem;
            box-sizing: border-box;
        }

        .cms-page-module-subtitle {
            margin-bottom: 1rem;
            font-size: 21px;
            font-family: $primaryFont;
            font-size: 21px;
            font-style: normal;
            font-weight: $regular;
            line-height: normal;
            margin-top: -3px;
        }
    }

    /* MODULE TEXTE */
    .cms-page-module-body.line-text.module-text {
        width: 100%;
        max-width: 1680px;
        margin: 0 auto;

        @media screen and(max-width: 1680px) {
            padding: 0 1.9rem;
            box-sizing: border-box;
        }

        @media screen and(max-width: 1290px) {
            padding: 0 1.5rem;
            box-sizing: border-box;
        }

        .cms-page-module-text {
            margin: 0 0 1.25rem;

            div span {
                line-height: 20px;
                font-family: $primaryFont;
                font-size: 15px;
                font-weight: $regular;
                width: 100%;
            }

            a {
                text-decoration: none;
            }
        }
    }

    /* MODULE CTA */
    .cms-page-module-body.line-text.module-cta {
        width: 100%;
        max-width: 1280px;
        margin: 20px auto 60px;

        @media screen and(max-width: 1290px) {
            padding: 0 1.5rem;
            box-sizing: border-box;
        }
    }

    /* MODULE IMAGE */
    .cms-page-module-body.image-2.simple-image {
        margin-top: 1.6rem;
        margin-bottom: 1.5rem;

        @media screen and(max-width: 1220px) {
            padding: 0 1.5rem;
            box-sizing: border-box;
        }

        .picture-container {
            display: flex;
            justify-content: center;

            img {
                width: 100%;
                height: auto;
            }

            .slide_video {
                aspect-ratio: 1220/622;
                max-width: 1220px;
            }
        }
    }

    /* MODULE IMAGE FULL*/
    .cms-page-module-body.image-2.banner-image {
        margin: 2rem 0;

        .picture-container {
            img {
                width: 100%;
                height: auto;
            }

            .slide_video {
                width: 100%;
                height: 0;
                padding-bottom: 35.55%;
                max-width: 1920px;
            }
        }
    }

    /* MODULE CATEGORIES */
    .cms-page-module-body.categories {
        width: 98%;
        max-width: 1680px;
        margin: 1.6rem auto 3.8rem;
        position: relative;

        @media screen and (max-width: 1768px) {
            width: 95%;
        }

        @media screen and (max-width: 1700px) {
            box-sizing: border-box;
        }

        .swiperCategories {
            margin-right: 30px;
            position: unset;

            .picture-container {
                img {
                    width: 100%;
                    height: auto;
                }

                .slide_video {
                    padding-bottom: 64.5%;
                    width: 100%;
                    height: 0;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                    }
                }
            }

            .cms-page-module-text {
                font-family: $primaryFont;
                font-size: 18px;
                font-style: normal;
                line-height: normal;
                margin-top: 20px;
                text-align: center;

                a {
                    text-decoration: none;
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                background-image: unset;
                top: 0;
                margin: auto;
                right: auto;
                left: auto;
                background-color: $white;
                height: calc(100% - 20px);

                &:before {
                    width: 10px;
                    height: 18px;
                    background-size: 100%;
                    background-image: url('../svg/arrow_adv.svg');
                }

                &:after {
                    position: absolute;
                    content: '';
                    top: 100%;
                    width: 100%;
                    height: 40%;
                    background-color: $white;
                }
            }

            .swiper-button-next {
                right: 0;

                &.swiper-button-disabled {
                    display: none;
                }
            }

            .swiper-button-prev {
                left: 0;

                &:before {
                    transform: rotate(181deg);
                }

                &.swiper-button-disabled {
                    display: none;
                }
            }

            &.no-arrow {

                .swiper-button-prev,
                .swiper-button-next {
                    display: none;
                }
            }
        }
    }

    /* MODULE 1 IMAGE 1077x250 */
    .cms-page-module-body.image-2.simple-image-2 {
        margin-bottom: 0.9rem;

        .picture-container {
            max-width: 1077px;
            margin: 0 auto;

            img {
                width: 100%;
                height: auto;
            }

            .slide_video {
                padding-bottom: 23.25%;
                max-width: 1077px;
            }
        }
    }

    /* MODULE IMAGE + TEXTE + LISTE PRODUITS */
    .cms-page-module.products_list.products_list_2 {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        column-gap: 4rem;
        width: 100%;

        .left_part {
            position: sticky;
            top: 110px;
            width: 50%;
            max-width: 680px;
            height: 100%;

            .cms-page-module-img {
                width: 100%;
                height: 100%;
            }
        }

        .right_part {
            position: relative;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 33%;
            margin-top: 55px;

            .text_part {
                .home-column-title {
                    font-size: 35px;
                }

                .home-column-txt {
                    margin-top: 1rem;
                    line-height: 21px;
                    font-size: 15px;
                }
            }

            .content_products {
                display: flex;
                justify-content: space-between;
                height: auto;
                margin-top: 20px;
                margin-bottom: 30px;

                .cms-page-column {
                    width: calc(50% - 15px);
                    margin-bottom: 20px;

                    .swiper-slide.item_tg {
                        width: 100%;
                        padding: 0;

                        .item {
                            &:hover {
                                .rollover {
                                    display: none;
                                }
                            }

                            .tg_img_container {
                                .tg_img_content {
                                    .eclat_gondole_home {
                                        left: 0;
                                        right: unset;
                                        text-align: left;
                                    }
                                }
                            }

                            .wrapper_infos {
                                width: 100%;

                                .title {
                                    span {
                                        margin: 1rem 0 0 0;
                                        color: $black;
                                        font-family: $primaryFont;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: normal;
                                        width: 85%;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        display: block;
                                        white-space: nowrap;
                                    }

                                    &:after {
                                        content: '';
                                        display: block;
                                        width: 100%;
                                        height: 1px;
                                        background-color: $black;
                                        margin-top: 8px;
                                    }
                                }

                                p {
                                    color: $cmsPageTitle;
                                    font-family: $primaryFont;
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    margin-top: 8px;
                                    width: 85%;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    display: block;
                                    white-space: nowrap;
                                }

                                .wrapper_price {
                                    .pricetag {
                                        font-size: 25px;
                                        line-height: 28px;
                                        font-family: $primaryFont;
                                        text-align: right;
                                        display: inline-block;
                                        width: auto;
                                        color: $redPromoPrice;

                                        &+.pricetag {
                                            font-size: 23px;
                                            line-height: 26px;
                                            color: $monsoon;
                                            display: inline-block;
                                            margin-left: 10px;

                                            &:after {
                                                content: '';
                                                height: 1px;
                                                background-color: $monsoon;
                                                width: calc(100% - 11px);
                                                position: absolute;
                                                top: 0;
                                                bottom: 0;
                                                right: 11px;
                                                margin: auto;
                                            }

                                            span {
                                                &.cents {
                                                    text-decoration: none;
                                                }
                                            }
                                        }

                                        &:only-child {
                                            color: $grey3;
                                        }
                                    }
                                }

                                .item_ratings {
                                    .netreviews_reviews_rate {
                                        color: $jauneAvisV;

                                        +.netreviews_reviews_rate {
                                            color: $black;
                                        }
                                    }
                                }
                            }

                            .rollover {
                                display: none;

                                .btn_buy {
                                    top: 0%;
                                    left: 0;
                                    transform: none;
                                }
                            }
                        }
                    }
                }
            }

            .wrap_navigation {
                position: absolute;
                bottom: -35px;
                height: 30px;
                width: 100%;
                margin-bottom: 45px;
            }

            .cta {
                background-color: $grey3;
                margin: 10px 0 0 0;

                &:hover {
                    background-color: $white;
                }
            }
        }

        .cms-page-column {
            width: 50%;
            float: left;
            box-sizing: border-box;
        }

        .swiper-slide .cms-page-column:nth-child(odd) {
            margin-right: 30px;
        }

        .swiper-pagination {
            top: unset;
            bottom: 0;
            height: 2px;
            width: calc(100% - 84px);
            background-color: $grey16;
            border-radius: 0;
            margin-top: 20px;

            .swiper-pagination-progressbar-fill {
                background-color: $grey3;
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            top: unset;
            bottom: -9px;
            width: 23px;
            height: 23px;
            background-image: none;
            mask-image: url('../svg/spritesheet.svg');
                @include maskIconCoord(12, 6, 6);
                background-color: $black;

            &::before {
                display: none;
            }

            &.rightarrow {
                right: -2px;
            }

            &.leftarrow {
                left: auto;
                right: 30px;
                transform: translateY(0.12rem) rotate(180deg);
            }
        }
    }

    .cms-page-module.socialMedia {
        padding: 0 5%;
    }

    // TG MODULE

    .tg-module {
        position: relative;
        max-width: 1680px;
        width: calc(100% - 80px);

        & .full_w_sldr_title {

            font-size: 25px;
            font-weight: 500;
            margin-bottom: 25px;

            .cms-page-module-sous-title {
                font-size: 21px;
                font-weight: 400;
                margin-bottom: 10px;
            }

            &:after {
                display: none;
            }
        }

        .swiper-slide {
            max-width: 373px;

            .wrapper_infos {
                margin-top: 1rem;

                h3 {
                    width: 100%;
                    font-size: 14px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    border-bottom: 1px solid #2b2b2b;
                    padding-bottom: 0.5rem;
                    margin-bottom: 0.5rem;
                }

                .item_ratings {
                    .netreviews_reviews_rate {
                        color: $jauneAvisV;

                        +.netreviews_reviews_rate {
                            color: $black;
                        }
                    }
                }
            }

            .btn_buy {
                opacity: 0;
            }

            &:hover {
                .btn_buy {
                    opacity: 1;
                }
            }
        }

        .swiper-module-prev,
        .swiper-module-next {
            background-image: url("../img/swiper-arrow.png");
            width: 10px;
            height: 18px;
            background-size: 100% auto;
            cursor: pointer;
        }

        .swiper-module-prev {
            position: absolute;
            left: -1.5rem;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
        }

        .swiper-module-next {
            position: absolute;
            right: -1.5rem;
            top: 50%;
            z-index: 10;
            transform: rotate(180deg) translateY(-50%);
        }

        .swiper-button-disabled {
            opacity: 0;
        }
    }

    .lienSeo {
        margin: 30px auto 30px auto;
        max-width: 1220px;
    }
}