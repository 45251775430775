.facilitiWrapper {
    width: 98%;
    max-width: 1332px;
    margin: 0 auto;

    .wrapperBtn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 34px;

        a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: .02rem;
            text-transform: uppercase;
            text-decoration: none;
            padding: .5rem 1rem;
            border: 1px solid #eeeeee;
            cursor: pointer;

            &:before {
                content: '';
                display: block;
                width: 29px;
                height: 30px;
                background: url('../img/faciliti/faciliti.png') -172px -41px;
                margin-right: .5rem;
            }
        }
    }

    h2 {
        font-size: 32px;
    }

    h3 {
        font-size: 28px;
        text-transform: uppercase;
        color: #ccd500;
    }

    .wrapperVideo {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 34px 0;

        iframe {
            @media screen and(max-width: 1300px) {
                width: 700px;
            }

            @media screen and(max-width: 1200px) {
                width: 450px;
            }
        }

        .rightCol {
            width: calc(100% - 880px);

            @media screen and(max-width: 1300px) {
                width: calc(100% - 780px);
            }

            @media screen and(max-width: 1200px) {
                width: calc(100% - 530px);
            }
        }

        label {
            position: relative;
            display: block;
            font-size: 20px;
            font-weight: 600;

            &:before {
                content: '';
                width: 39px;
                height: 41px;
                background: url('../img/faciliti/faciliti.png') -172px -0;
                position: absolute;
                top: 0;
                left: -50px;
            }

            &:last-of-type {
                margin-top: 28px;
            }
        }

        p {
            font-size: 16px;
            color: #909090;
            line-height: 1.4;
            margin-top: .5rem;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            margin-top: 1rem;

            li {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 50%;
                font-size: 16px;
                color: #909090;
                margin-bottom: .5rem;

                &:before {
                    content: '';
                    width: 40px;
                    height: 40px;
                    background-image: url('../img/faciliti/faciliti.png');
                    background-repeat: no-repeat;
                    margin-right: .5rem;
                }

                &:nth-child(1):before {
                    background-position: -132px -40px;
                }

                &:nth-child(2):before {
                    background-position: -0 -66px;
                }

                &:nth-child(3):before {
                    background-position: -40px -66px;
                }

                &:nth-child(4):before {
                    background-position: -132px -0;
                }

                &:nth-child(5):before {
                    background-position: -0 -106px;
                }

                &:nth-child(6):before {
                    background-position: -40px -106px;
                }

                &:nth-child(7):before {
                    background-position: -80px -66px;
                }
            }
        }
    }

    .wrapperHowTo {
        position: relative;
        background-color: #fbfceb;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 80px 0 65px;

        h3 {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            justify-content: center;
            align-items: flex-start;
            height: 46px;
            background-color: #fff;
            white-space: nowrap;
            padding: 0 3rem;
        }

        section {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            text-align: center;
            width: 30%;

            label {
                display: block;
                font-size: 24px;
                font-weight: 400;
                text-transform: uppercase;
                line-height: 1.3;
                margin-bottom: .8rem;

                &:before {
                    content: '';
                    display: block;
                    width: 66px;
                    height: 66px;
                    background-image: url('../img/faciliti/faciliti.png');
                    background-repeat: no-repeat;
                    margin: 0 auto 1.5rem;
                }
            }

            &:nth-of-type(1) {
                cursor: pointer;

                label,
                p {
                    cursor: pointer;
                }

                label::before {
                    background-position: -0 -0;
                    cursor: pointer;
                }
            }

            &:nth-of-type(2) {
                margin: 0 2%;

                label::before {
                    background-position: -66px -0;
                }
            }

            &:nth-of-type(3) label::before {
                width: 183px;
                height: 38px;
                background: url('../svg/hesperide_2022.svg') no-repeat center;
                background-size: contain;
                margin: 1.4rem auto 1.85rem;
            }

            p {
                font-size: 16px;
                color: #909090;
                line-height: 1.4;
                text-align: center;
            }
        }
    }
}