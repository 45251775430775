$countryCodes: (1: 'AC',
    2: 'AD',
    3: 'AE',
    4: 'AF',
    5: 'AG',
    6: 'AI',
    7: 'AL',
    8: 'AM',
    9: 'AO',
    10: 'AQ',
    11: 'AR',
    12: 'AS',
    13: 'AT',
    14: 'AU',
    15: 'AW',
    16: 'AX',
    17: 'AZ',
    18: 'BA',
    19: 'BB',
    20: 'BD',
    21: 'BE',
    22: 'BF',
    23: 'BG',
    24: 'BH',
    25: 'BI',
    26: 'BJ',
    27: 'BL',
    28: 'BM',
    29: 'BN',
    30: 'BO',
    31: 'BQ',
    32: 'BR',
    33: 'BS',
    34: 'BT',
    35: 'BV',
    36: 'BW',
    37: 'BY',
    38: 'BZ',
    39: 'CA',
    40: 'CC',
    41: 'CD',
    42: 'CF',
    43: 'CG',
    44: 'CH',
    45: 'CI',
    46: 'CK',
    47: 'CL',
    48: 'CM',
    49: 'CN',
    50: 'CO',
    51: 'CP',
    52: 'CR',
    53: 'CU',
    54: 'CV',
    55: 'CW',
    56: 'CX',
    57: 'CY',
    58: 'CZ',
    59: 'DE',
    60: 'DG',
    61: 'DJ',
    62: 'DK',
    63: 'DM',
    64: 'DO',
    65: 'DZ',
    66: 'EA',
    67: 'EC',
    68: 'EE',
    69: 'EG',
    70: 'EH',
    71: 'ER',
    72: 'ES',
    73: 'ET',
    74: 'EU',
    75: 'FI',
    76: 'FJ',
    77: 'FK',
    78: 'FM',
    79: 'FO',
    80: 'FR',
    81: 'GA',
    82: 'GB',
    83: 'GD',
    84: 'GE',
    85: 'GF',
    86: 'GG',
    87: 'GH',
    88: 'GI',
    89: 'GL',
    90: 'GM',
    91: 'GN',
    92: 'GP',
    93: 'GQ',
    94: 'GR',
    95: 'GS',
    96: 'GT',
    97: 'GU',
    98: 'GW',
    99: 'GY',
    100: 'HK',
    101: 'HM',
    102: 'HN',
    103: 'HR',
    104: 'HT',
    105: 'HU',
    106: 'IC',
    107: 'ID',
    108: 'IE',
    109: 'IL',
    110: 'IM',
    111: 'IN',
    112: 'IO',
    113: 'IQ',
    114: 'IR',
    115: 'IS',
    116: 'IT',
    117: 'JE',
    118: 'JM',
    119: 'JO',
    120: 'JP',
    121: 'KE',
    122: 'KG',
    123: 'KH',
    124: 'KI',
    125: 'KM',
    126: 'KN',
    127: 'KP',
    128: 'KR',
    129: 'KW',
    130: 'KY',
    131: 'KZ',
    132: 'LA',
    133: 'LB',
    134: 'LC',
    135: 'LI',
    136: 'LK',
    137: 'LR',
    138: 'LS',
    139: 'LT',
    140: 'LU',
    141: 'LV',
    142: 'LY',
    143: 'MA',
    144: 'MC',
    145: 'MD',
    146: 'ME',
    147: 'MF',
    148: 'MG',
    149: 'MH',
    150: 'MK',
    151: 'ML',
    152: 'MM',
    153: 'MN',
    154: 'MO',
    155: 'MP',
    156: 'MQ',
    157: 'MR',
    158: 'MS',
    159: 'MT',
    160: 'MU',
    161: 'MV',
    162: 'MW',
    163: 'MX',
    164: 'MY',
    165: 'MZ',
    166: 'NA',
    167: 'NC',
    168: 'NE',
    169: 'NF',
    170: 'NG',
    171: 'NI',
    172: 'NL',
    173: 'NO',
    174: 'NP',
    175: 'NR',
    176: 'NU',
    177: 'NZ',
    178: 'OM',
    179: 'PA',
    180: 'PE',
    181: 'PF',
    182: 'PG',
    183: 'PH',
    184: 'PK',
    185: 'PL',
    186: 'PM',
    187: 'PN',
    188: 'PR',
    189: 'PS',
    190: 'PT',
    191: 'PW',
    192: 'PY',
    193: 'QA',
    194: 'RE',
    195: 'RO',
    196: 'RS',
    197: 'RU',
    198: 'RW',
    199: 'SA',
    200: 'SB',
    201: 'SC',
    202: 'SD',
    203: 'SE',
    204: 'SG',
    205: 'SH',
    206: 'SI',
    207: 'SJ',
    208: 'SK',
    209: 'SL',
    210: 'SM',
    211: 'SN',
    212: 'SO',
    213: 'SR',
    214: 'SS',
    215: 'ST',
    216: 'SV',
    217: 'SX',
    218: 'SY',
    219: 'SZ',
    220: 'TA',
    221: 'TC',
    222: 'TD',
    223: 'TF',
    224: 'TG',
    225: 'TH',
    226: 'TJ',
    227: 'TK',
    228: 'TL',
    229: 'TM',
    230: 'TN',
    231: 'TO',
    232: 'TR',
    233: 'TT',
    234: 'TV',
    235: 'TW',
    236: 'TZ',
    237: 'UA',
    238: 'UG',
    239: 'UM',
    240: 'US',
    241: 'UY',
    242: 'UZ',
    243: 'VA',
    244: 'VC',
    245: 'VE',
    246: 'VG',
    247: 'VI',
    248: 'VN',
    249: 'VU',
    250: 'WF',
    251: 'WS',
    252: 'XK',
    253: 'YE',
    254: 'YT',
    255: 'ZA',
    256: 'ZM',
    257: 'ZW'
);

$iconsMap: (1: 'fb_connect', 2:'fb_connect_b', 3: 'tt_connect');

// Animations
@keyframes fadeInFromRight {
    0% {
        transform: translateX(25%);
        opacity: 0;
    }

    25% {
        opacity: 0;
    }

    75% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

// Sprites
.has-flag-icn {
    @include spriter('../img/icons/flags.png', 23, 12, $countryCodes);
}

// Layout
.w-lines-group {
    position: relative;
    width: 100%;
    margin-bottom: 30 / 16 * 1rem;

    .w-form-line {
        margin-bottom: 4 / 16 * 1rem;
    }
}

.w-input-group {
    margin-bottom: 30 / 16 * 1rem;

    .w-group-label {
        font-family: Arial, sans-serif;
        font-weight: 400;
        font-size: 14 / 16 * 1rem;
        margin-bottom: 15 / 14 * 1em;
    }
}

.w-fieldset-label {
    font-family: $primaryFont;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #2B2B2B;
    margin-bottom: 0.5rem;
}

.w-line-label {
    width: 1 / 4 * 100%;
    font-family: Arial, sans-serif;
    font-weight: 400;
    font-size: 11 / 16 * 1rem;
    color: #aaa;
    letter-spacing: 0.5 / 11 * 1em;
}

.w-line-label:lang(es) {
    width: 40%;
}

.v-toggle {
    max-height: 0;
    overflow: hidden;
    box-sizing: border-box;

    .w-form-line:first-of-type {
        margin-top: 30 / 16 * 1rem;
    }
}

// Password validator
.w-class-pwd-creation {
    gap: 3px!important;

    h3 {
        font-weight: 400;
        font-size: 13px;
        line-height: 15.6px;
        color: $grey3;
        margin-bottom: 4px;

        &:not(:first-child) {
            display: none;

            & + progress {
                display: none;

                & + p {
                    display: none;
                }
            }
        }
    }

    // List of rules
    ul {
        gap: 7px!important;

        .w-pwd-req {
            font-size: 13px;
            color: $pwValid;
            gap: 11px!important;

            &:before {
                mask-size: 13.5px!important;
                background-color: $pwValid!important;
                width: 12px!important;
                height: 12px!important;
            }

            &.w-pwd-req-error {
                color: $pwError;

                &:before {
                    background-color: $pwError!important;
                }
            }

            &#length {
                order: 1;
            }

            &#capital {
                order: 2;
            }

            &#letter {
                order: 3;
            }

            &#number {
                order: 4;
            }
        }
    }
}

.w-form-line {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    margin-bottom: 23px;
    animation: fadeIn 0.35s ease-out 0s 1 forwards;

    &.w-has-tel {
        z-index: 5;
    }

    &.w-submit {
        max-width: 254 / 16 * 1rem;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &.sameBillAddress {
        width: fit-content;
    }

     // We don't show instructions on password confirmation field
     &.pwd-form-line {
        & + .w-form-line {
            .w-password-input,
            .w-password-input.w-nonempty {
                & ~ .w-class-pwd-creation {
                    display: none!important;
                }
            }
        }
    }

    // Once the password input is not empty, we show the password creation instructions and don't hide it again
    &:has(.w-class-pwd-creation) {
        .w-password-input {
            &.w-nonempty {
                & ~ .w-class-pwd-creation {
                    opacity: 1;
                    visibility: visible;
                    display: flex !important;
                }
            }
        }

        // Text error is hidden per client demands, error in input is shown with red border
        .w-pw-form-error {
            display: none!important;
        }
    }
}

.w-input-container {
    position: relative;
    width: 100%;

    &+& {
        margin-left: 1rem;
    }
}

.w-input {
    display: block;
    position: relative;
    width: 100%;

    &.w-tel-input {
        .selected-flag {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 38 / 16 * 1rem;
            height: 70%;
            padding: 0 0.5rem;
            border-right: 1px solid #d9d9d9;
            cursor: pointer;
            box-sizing: content-box;

            span {
                display: none;
            }

            &::before {
                display: block;
                width: 22 / 16 * 1rem;
                height: 15 / 16 * 1rem;
                content: '';
            }

            &::after {
                display: block;
                width: 0;
                height: 0;
                border-left: 0.35em solid transparent;
                border-right: 0.35em solid transparent;
                border-top: 0.35em solid #000;
                margin-left: 4 / 16 * 1rem;
                content: '';
            }
        }

        .country-list {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            max-height: 200px;
            background-color: #fff;
            border: 1px solid #d9d9d9;
            z-index: 5;
            overflow: auto;

            .country {
                display: flex;
                background-color: #fff;
                padding: 0.5em 0.5rem;
                font-family: Arial, sans-serif;
                font-weight: 400;
                font-size: 13 / 16 * 1rem;
                transition: background-color 0.2s linear;

                span {
                    margin: 0 0.75em;
                }

                &::before {
                    display: block;
                    width: 22 / 16 * 1rem;
                    height: 15 / 16 * 1rem;
                    content: '';
                }

                &::after {
                    display: inline;
                    color: #999;
                    content: attr(data-dial-code);
                }

                &:hover {
                    background-color: #f2f2f2;
                }
            }

            &.active {
                display: block;
            }
        }

        .w-input-element:not([type=radio]):not([type=checkbox]),
        input[type=email].w-input-element,
        // TODO:
        input[type=password].w-input-element,
        // Get rid
        input[type=text].w-input-element,
        // of these
        textarea.w-input-element {
            // override rules
            padding-left: 62 / 14 * 1em;
        }

        .w-input-label {
            left: 62 / 16 * 1rem;
        }
    }

    select:focus {
        outline: none;
    }
}

.w-input-element:not([type=radio]):not([type=checkbox]),
input[type=email].w-input-element,
// TODO:
input[type=password].w-input-element,
// Get rid
input[type=text].w-input-element,
// of these
textarea.w-input-element {
    // override rules
    appearance: none;
    vertical-align: top;
    width: 100%;
    height: 48 / 14 * 1em;
    background-color: transparent;
    padding: 0 (12 / 14 * 1em);
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    box-shadow: 0 0 0 0 transparent;
    font-family: Arial, sans-serif;
    font-weight: 400;
    font-size: 14 / 16 * 1rem;
    transition-property: padding, border-color, box-shadow;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;

    @include ie() {
        line-height: 1;
    }

    &::placeholder {
        transition: opacity 0.2s linear;
    }

    &[type=date] {
        &::-webkit-clear-button {
            appearance: none;
            display: block;
            position: absolute;
            top: 50%;
            right: 2.25rem;
            transform: translateY(-50%);
            width: 0.75rem;
            height: 0.75rem;
            background: transparent url(../img/close_btn.jpg) center no-repeat;
            background-size: contain;
            margin: 0;
            cursor: pointer;
        }

        &::-webkit-inner-spin-button {
            appearance: none;
            display: none;
        }

        &::-webkit-calendar-picker-indicator {
            appearance: none;
            display: block;
            position: absolute;
            top: 50%;
            right: 0.9rem;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            padding: 0;
            border-left: 0.35rem solid transparent;
            border-right: 0.35rem solid transparent;
            border-top: 0.35rem solid #000;
            margin: 0;
            cursor: pointer;

            &:focus-visible {
                outline: none;
            }
        }
    }

    .w-input:not(.w-dropdown) & {
        &:focus {
            border-color: #999999;
            box-shadow: 0 0 0 1px #999999;

            &::placeholder {
                opacity: 0;
            }
        }
    }

    .w-has-error:not(.w-dropdown) & {
        border-color: #ff6d6d;
        box-shadow: 0 0 0 1px #ff6d6d;
    }

    .w-nonempty:not(.no-label) &,
    .w-date-input & {
        padding-top: 1.15em;
    }

    .w-textarea & {
        line-height: #{(16 / 14)};
        height: 112 / 14 * 1em;
        padding: 1em (12 / 14 * 1em);
        resize: none;

        +.w-input-label {
            top: 0;
            transform: translateY(100%);
        }
    }

    .w-textarea.w-nonempty &,
    .w-textarea.w-nonempty:not(.no-label) & {
        padding-top: 23 / 14 * 1em;

        +.w-input-label {
            transform: translateY(55%);
        }
    }
}

.w-input-label {
    position: absolute;
    top: 50%;
    left: 14 / 16 * 1rem;
    transform: translateY(-50%);
    font-family: $primaryFont;
    font-weight: 400;
    font-size: 14 / 16 * 1rem;
    color: #bbbbbb;
    opacity: 1;
    user-select: none;
    transition: transform 0.2s ease-out, font-size 0.2s ease-out;

    .w-nonempty &,
    .w-date-input & {
        transform: translateY(-115%);
        font-size: 12 / 16 * 1rem;
        opacity: 1;
        transition: transform 0.2s ease-out, font-size 0.2s ease-out, opacity 0.2s linear;
    }

    .w-input:not(.w-nonempty):not(.w-date-input) .w-input-element:-internal-autofill-previewed+& {
        opacity: 0;
        transition: transform 0.2s ease-out, font-size 0.2s ease-out, opacity 0s linear;
    }
}

.w-visibility-toggle {
    // Turn password toggle button into text
    &.w-no-bg {
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        width: auto;
        font-size: 13px;
        letter-spacing: 0.65px;
        text-decoration: underline;

        span {
            line-height: normal;
        }
    }
}


.w-input-note {
    display: none;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    font-family: Arial, sans-serif;
    font-weight: 400;
    font-size: 12 / 16 * 1rem;
    line-height: 1.5;
    margin: (4 / 12 * 1em) 0 (8 / 12 * 1em);

    &:first-of-type {
        display: block;
    }

    &.w-input-error {
        color: #ff6d6d;
        animation: fadeInFromRight 0.35s ease-out 0s 1 forwards;
        width: 23rem;
    }

    &.w-input-valid {
        color: #52bf52;
        animation: fadeInFromRight 0.35s ease-out 0s 1 forwards;
    }
}

.w-radio-group {
    display: flex;

    &.w-has-error .w-input-element+.w-input-label {
        color: #ff6d6d;

        &::before {
            border-color: #ff6d6d;
            box-shadow: 0 0 0 1px #ff6d6d;
        }
    }
}

.w-checkbox-input,
.w-radio-input {
    display: flex;
    align-items: center;
    position: relative;
    width: auto;
    height: 1em;
    border: none;

    &+& {
        margin-left: 1em;
    }

    .w-input-element {
        position: absolute;
        top: 0;
        left: 0;
        width: 1em;
        height: 1em;
        opacity: 0;

        +.w-input-label {
            display: flex;
            align-items: center;
            position: relative;
            top: auto;
            left: auto;
            transform: none;
            font-size: 12 / 16 * 1rem;
            cursor: pointer;
            user-select: none;

            span {
                margin-left: 0.5em;

                &.multiline {
                    display: flex;
                    flex-direction: column;
                }

                span {
                    margin-left: 0;
                }
            }

            &::before {
                display: block;
                width: 1em;
                height: 1em;
                background-color: #fff;
                border: 1px solid #e4e4e4;
                box-shadow: inset 0 0 0 0.5em #ffffff;
                transition: 0.2s ease-out;
                transition-property: background-color, box-shadow;
                content: '';
            }
        }

        &:checked+.w-input-label::before {
            background-color: #3c3c3c;
            box-shadow: inset 0 0 0 0.125rem #fff;

            @include ie {
                box-shadow: inset 0 0 0 0.18rem #fff !important;
            }
        }
    }
}

.w-radio-input .w-input-element+.w-input-label::before {
    border-radius: 50%;
}

.w-dropdown {
    position: relative;
    height: 3.2em;
    border: 1px solid #2B2B2B;
    border-radius: 0;
    box-sizing: border-box;

    &.w-focused {
        border-color: black;
        box-shadow: 0 0 0 1px black;
    }

    &::after {
        right: 1rem;
    }
}

.w-btn-wrapper {
    position: relative;
    width: 100%;

    &+& {
        margin-left: 1.25rem;
    }
}

.lightbox {
    .w-submit-button {
        &.w-loader {
            &.loading {
                width: 37px;
            }
        }
    }
}

.w-submit-button,
button.w-submit-button {
    appearance: none;
    width: 100%;
    height: clamp(40px, fs-vw(50,1920), 50px);
    line-height: clamp(40px, fs-vw(50,1920), 50px);
    background-color: $grey3;
    padding: 0;
    border: 1px solid $grey3;
    border-radius: 0;
    font-family: $primaryFont;
    font-weight: 400;
    font-size: 17px;
    color: $white;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.2s ease-out;

    &:not(.w-loader):hover,
    &:not(.w-loader):focus {
        background-color: $white;
        color: $grey3;
    }

    &.w-loader {
        position: absolute;
        top: 0;
        left: 0;
        cursor: default;
        background: $grey3 url(../svg/three_dots.svg) center no-repeat;
        background-size: auto 15%;
        opacity: 0;
        z-index: -5;
        transition: opacity 0.199s ease-out, z-index 0.001s linear 0.199s;
        width: 100%;

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            background: $grey3 url("../img/three_dots.gif") center no-repeat;
            background-size: auto 70%;
        }

        span {
            display: none;
        }

        &.loading {
            opacity: 1;
            z-index: 5;
            transition: opacity 0.199s ease-out 0.001s, z-index 0.001s linear;
            width: 100%;
        }
    }
}

//
//  Address Autocomplete (override Google)
// *********************************************************

.pac-container {
    font-family: Arial, sans-serif;
    font-weight: 400;

    .pac-item {
        display: flex;
        align-items: center;
        line-height: (30 / 11 * 14) / 14 * 1em;
        font-size: 14 / 16 * 1rem;
    }

    .pac-icon {
        margin-top: 0;
    }
}

//
//  Formulaire d'info client
// *********************************************************

.w-info-form {
    padding-bottom: 80 / 16 * 1rem;

    .w-radio-group {
        .w-radio-input:nth-child(2) {
            display: none;
        }
    }
}

//
//  Formulaire d'adresse
// *********************************************************

.w-address-form {
    padding-bottom: 40 / 16 * 1rem;

    .w-radio-group {
        .w-radio-input:nth-child(2) {
            display: none;
        }
    }
}

.w-form-line .noaddr {
    position: absolute;
    right: 1.5rem;
    top: -50%;
    transform: translateY(-50%);
    font-family: Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: $black;
    text-decoration: underline;
    animation: fadeIn 0.35s ease-out 0s 1 forwards;
}

.elem_global {
    margin-bottom: 30 / 16 * 1rem;
}

//
//  Formulaire de contact
// *********************************************************

.w-contact-form {
    padding-bottom: 80 / 16 * 1rem;
}

//
//  Formulaire de partage à un ami (par mail)
// *********************************************************

.w-share-form {
    padding-bottom: 40 / 16 * 1rem;

    .w-submit {
        margin: auto;
    }
}

//
//  Formulaire d'alerte réassort
// *********************************************************

.w-alertestock-form {
    width: 338px;
    margin: 0 auto (10 / 16 * 1rem);

    .w-submit {
        max-width: none;
    }
}

//
//  Formulaire d'inscription à la newsletter
// *********************************************************

.w-newsletter-form {
    position: relative;
    width: 20rem;

    .w-input-element:not([type=radio]):not([type=checkbox]),
    input[type=email].w-input-element {
        padding-right: 60 / 16 * 1rem;
        border-radius: 5px 0 0 5px;
    }

    .w-form-line.w-submit {
        position: absolute;
        top: 0;
        right: 0;

        & {
            width: 48 / 16 * 1rem;
            height: 48 / 16 * 1rem;
        }

        .w-submit-button {
            width: auto;
            min-width: 48 / 16 * 1rem;
            height: 48 / 16 * 1rem;
            padding: 0 5px;
        }
    }
}

//
//  Formulaire d'inscription à la newsletter (lightbox)
// *********************************************************

.w-adwords-form,
.lightbox#popup_inscription_home #formu_inscription_news form.w-adwords-form,
.lightbox#exit_overlay_OVI #formu_inscription_news form.w-adwords-form {
    text-align: left;

    .w-form-line.w-submit {
        margin: 0 auto;
    }
}

//
//  Formulaire de création d'un nouveau mot de passe
// *********************************************************

.w-newpass-form {
    text-align: left;

    .w-form-line.w-submit {
        max-width: none;
    }
}

//
//  Text formating classes
// *********************************************************

.is-capitalized {
    text-transform: capitalize;
}

.is-uppercased {
    text-transform: uppercase;
}

//
//  IE Fix
// *********************************************************

html.ie {
    .form-msg:not(.active) {
        display: none;
    }
}

/* Retirer l'espace des form.msg quand inactif */
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .form-msg:not(.active) {
        display: none;
    }
}


.w-form {
    input {
        box-sizing: border-box;
        background-color: white;
    }

    button,
    input,
    select,
    textarea {
        text-indent: 0;
    }
}

.w-input-element:not([type=radio]):not([type=checkbox]),
input[type=email].w-input-element,
input[type=password].w-input-element,
input[type=text].w-input-element,
textarea.w-input-element {
    background-color: #fff;
    box-sizing: border-box;
}

.w-info-form,
.w-login-form {

    .w-form-line:has(.w-class-pwd-creation) .pass-2 .w-password-input~.w-class-pwd-creation,
    .w-form-line:has(.w-class-pwd-creation) .pass-2 .w-password-input.w-nonempty~.w-class-pwd-creation {
        display: none !important;
    }
}

.w-login-form {
    width: 84%;
    margin: 0 auto;
}

.w-login-form .w-form-line.w-has-tel .w-date-input input[type=date] {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        display: block;
        width: 10px;
        height: 10px;
        display: none;
        /*width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: $black transparent transparent transparent;
        line-height: 0px;
        _border-color: $black #000000 #000000 #000000;
        _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');*/
    }
}

input[type=date] {
    -moz-appearance: initial // Hack for Firefox Browsers
}

.wrapper_moncompte .update_success {
    margin-bottom: 1.4rem;
}

.w-input.w-tel-input .selected-flag {
    height: 102%;
    left: -1px;
    background-color: transparent;
}

.valid_form,
form#form_lightbox_adwords .img_error {
    right: 1rem;
    top: 50%;
}

.multiline {
    strong {
        font-size: 14px;
        font-family: Lato, sans-serif;
        margin-left: 1rem;
    }

    span {
        display: none;
    }
}

.newsletter {
    .w-login-form,
    .wrapper_moncompte .update_success {
        width: 100%;
        margin-bottom: 1rem;
        color: $green;
    }
}

.w-social-connect .w-social-btn.aft:after,
.w-social-connect .w-social-btn.bfr:before,
.w-social-connect .w-social-btn:not(.bfr):not(.aft) {
    background-size: 400% auto !important;
    background-color: transparent;
}

.wrapper_login_right,
.wrapper_login_left {
    min-height: 550px;

    .w-client-form {
        display: none;
    }

    &.actif {
        .w-client-form {
            display: flex;
        }

        #resetPass {
            .choix_login_btn {
                &::before {
                    background: #fff url("../img/inscription_drop_btn.png") no-repeat;
                }
            }
        }
    }
}

.w-submit-button,
button.w-submit-button {
    background-color: $grey3;
    width: 15rem;
}

.w-fieldset-label,
.w-line-label {
    color: $txtBlack;
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0;
}

.w-checkbox-input .w-input-element+.w-input-label::before,
.w-radio-input .w-input-element+.w-input-label::before {
    border-color: $btnBgColor;
}

.w-pwd-reset {
    text-align: center;
    text-decoration: underline !important;
    color: $txtBlack;
    font-family: Lato, sans-serif;
    font-size: 12px;
    display: flex;
    justify-content: center;
    width: 100%;
    border: none !important;
}

#resetPass {
    .login_form_wrapper {
        width: 100%;
    }
}

body.bankThanks #bank_div {
    .w-newpass-form {
        width: 90%;
        margin: 2rem 0;

        .w-input-label {
            font-size: 14px;
            text-transform: initial;
            margin: 0;
            font-family: Arial, sans-serif;
        }

        .w-input-element:not([type=radio]):not([type=checkbox]),
        input[type=email].w-input-element,
        input[type=password].w-input-element,
        input[type=text].w-input-element,
        textarea.w-input-element {
            border-radius: 0;
        }
    }
}

//
//  Price range slider
// *********************************************************

/* component style */
.vue-slider-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* rail style */
.vue-slider-rail {
    background-color: #ccc;
    border-radius: 15px;
}

/* process style */
.vue-slider-process {
    background-color: #b5cf23;
    border-radius: 15px;
}

/* mark style */
.vue-slider-mark {
    z-index: 4;

    &:first-child .vue-slider-mark-step,
    &:last-child .vue-slider-mark-step {
        display: none;
    }
}

.vue-slider-mark-step {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.16);
}

.vue-slider-mark-label {
    font-size: 14px;
    white-space: nowrap;
}

/* dot style */
.vue-slider-dot-handle {
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #b5cf23;
    box-sizing: border-box;
    box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32);
}

.vue-slider-dot-handle-disabled {
    cursor: not-allowed;
    background-color: #ccc;
}

.vue-slider-dot-tooltip-inner {
    font-size: 14px;
    white-space: nowrap;
    padding: 2px 5px;
    min-width: 20px;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    border-color: #b5cf23;
    background-color: #b5cf23;
    box-sizing: content-box;

    &::after {
        content: "";
        position: absolute;
    }
}

.vue-slider-dot-tooltip-inner-top::after {
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    height: 0;
    width: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px;
    border-top-color: inherit;
}

.vue-slider-dot-tooltip-inner-bottom::after {
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    height: 0;
    width: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px;
    border-bottom-color: inherit;
}

.vue-slider-dot-tooltip-inner-left::after {
    left: 100%;
    top: 50%;
    transform: translate(0, -50%);
    height: 0;
    width: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px;
    border-left-color: inherit;
}

.vue-slider-dot-tooltip-inner-right::after {
    right: 100%;
    top: 50%;
    transform: translate(0, -50%);
    height: 0;
    width: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px;
    border-right-color: inherit;
}

.vue-slider-dot-tooltip-wrapper {
    opacity: 0;
    transition: all 0.3s;
}

.vue-slider-dot-tooltip-wrapper-show {
    opacity: 1;
}

.answerTo {
    #btnSendResponse {
        display: flex;
        justify-content: flex-end;

        button.w-submit-button {
            border: 2px solid #c4d600;
            background-color: #fff;
            color: $primaryColor;
            width: 250px;
        }

        .w-btn-wrapper {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
    }

    .w-form-line.w-submit {
        max-width: 100%;
    }

    .w-submit-button.w-loader,
    button.w-submit-button.w-loader {
        left: unset;

        @include ie() {
            left: auto;
        }

        right: 0;
        position: absolute;
        width: 250px;
    }
}

/* NL - New otpin style */
.w-newspreferences-form .w-checkbox-input .w-input-element {
	@keyframes btnClickr {
		from {
			left: calc(26px - 13px - ((15px - 13px) / 2));
			width: 13px;
		}

		33% {
			left: calc((15px - 13px) / 2);
			width: calc(26px - ((15px - 13px)));
		}

		66% {
			left: calc((15px - 13px) / 2);
			width: calc(26px - ((15px - 13px)));
		}

		to {
			width: 13px;
		}
	}

	@keyframes btnClick {
		from {
			width: 13px;
		}

		33% {
			left: calc((15px - 13px) / 2);
			width: calc(26px - ((15px - 13px)));
		}

		66% {
			left: calc((15px - 13px) / 2);
			width: calc(26px - ((15px - 13px)));
		}

		to {
			left: calc(26px - 13px - ((15px - 13px) / 2));
			width: 13px;
		}
	}
    &+.w-input-label {

        .multiline strong {
            font-family: $primaryFont;
            color: #2b2b2b;
            font-weight: 400;
            padding-left: 26px;
        }

		&::before {
			position: absolute;
			left: 0;
			width: 26px;
			height: 15px;
			display: block;
			background: #E5E5E5;
			border: 0;
			border-radius: 72px;
			box-shadow: none;
			overflow: hidden;
			transition-duration: 400ms;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			left: calc((15px - 13px) / 2);
			width: 13px;
			height: 13px;
			background: $white;
			border-radius: 50%;
			transition: 0.2s;
			border-radius: 13px;
			animation: btnClickr 400ms ease-in-out;
			animation-fill-mode: forwards;
		}
    }
    &:checked+.w-input-label {

        &::before {
            background: #707070;
        }

        &::after {
            animation: btnClick 400ms ease-in-out;
            animation-fill-mode: forwards;
        }
    }

	&+.w-input-label::after {
		animation-duration: 0s;
	}

	&:checked+p.w-input-label::after {
		animation-duration: 0s;
	}

	&+.w-input-label.normal-time::after {
		animation-duration: 400ms;
	}

	&:checked+p.w-input-label.normal-time::after {
		animation-duration: 400ms;
	}
}